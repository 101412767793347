import React, { Component,useState, useEffect} from "react";
import { Grid, Table, Form, Dimmer, Tab } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import classes from "./index.module.css";
import ReactToPrint from 'react-to-print';
import { NotificationManager } from "react-notifications";
import L from "leaflet";  
import {MapContainer,TileLayer,ScaleControl,useMapEvents,MapConsumer} from "react-leaflet";
import icon from "./constants";
//import { addressPoints } from "./addressPoints";
import "leaflet/dist/leaflet.css";
//import map from 'Map';  

//En esta ruta de local podemos extraer las placas del tractor y del remolque: http://localhost:8000///this.getfuntion("get", [], "", "personas/destinatarios_transportistas_incidencias_ter/"+this.props.idmovimiento+"/"+event.data.idEtiqueta, "destinosTransportistas");
//
// }

class BitacoraPdf extends Component {
  constructor(props){
    super(props);

    this.state = {
      hiddenModals  : true,
      searchAlertas : "",
      usuariosLog   : [],
      filas         : [],
      todasLasEntregas         : [],
      entregas      : [],
      arribo        : "",      
      paradas       : [],
      trackPoints   : [],
      distanciaRecorrida : 0,      
      addressPoints : [],
      conteoParadas : 0,
      contadorImagenes:0,
      tiempoTotalRuta:0,
      cartasPorte:[],
      bookings:[],
      destinos:[],
      estadia:[],
      tiempoDetenidoEnRuta:0,
      loading       : true
    };
    //this.trackPoints=[];
    this.img=[4];
    this.img2=null;
    this.img3=null;
    this.filas= [];
    this.contador=0;
    this.tiempoRuta=0;
    //this.estadia=0;  
    this.avgLat=0;
    this.avgLon=0;
    this.c = [];
    this.latitudesDeParadas = [];
    this.distancia_c=0;
    this.detenciones=0;
   // this.conteoParadas=0;
    this.todasLasEntregas=null;  
    //this.cartasPorte=[];
  }
  
  imageUpload = (e) => {
    const file = e.target.files[0];
    this.state.contadorImagenes=1;
    // getBase64(file).then(base64 => {
    //   localStorage["fileBase64"] = base64;
    // });
    try{
      //this.img[this.contador]=URL.createObjectURL(file);
      //this.contador=this.contador+1;
      this.img=URL.createObjectURL(file);
      //this.img=null;
       //document.getElementById("imageFile1").value = "";

    }
   catch{
    console.log("Hubo un error al recuperar la imagen");  
    //this.contador=this.contador+1;
   }


  };

  imageUpload2 = (e) => {
    this.state.contadorImagenes=2;

    const file = e.target.files[0];
    // getBase64(file).then(base64 => {
    //   localStorage["fileBase64"] = base64;
    // });
    try{
      this.img2=URL.createObjectURL(file);
    }
   catch{
    console.log("Hubo un error al recuperar la imagen");  
   }
  };

  imageUpload3 = (e) => {
    const file = e.target.files[0];
    // getBase64(file).then(base64 => {
    //   localStorage["fileBase64"] = base64;
    // });
    try{
      this.img3=URL.createObjectURL(file);
    }
   catch{
    console.log("Hubo un error al recuperar la imagen");  
   }
  };
    componentWillMount = () => { 

      Crud_Catalogos("terrestreMovimientos/tracks/"+this.props.Movimiento.id, "","get","",[],"","","",[]).then((returnVal) => {
        this.setState({  trackPoints:returnVal });//.catch(err=>{console.log(err)});          
      });

      //  Crud_Catalogos("terrestreMovimientos/tracks/380813", "","get","",[],"","","",[]).then((returnVal) => {
      //    this.setState({  trackPoints:returnVal });//.catch(err=>{console.log(err)});          
      //  });


      //  Crud_Catalogos("distanciaRecorridaView/distanciaRecorrida/"+this.props.Movimiento.id, "","get","",[],"","","",[]).then((returnVal) => {
      //   this.setState({ distancia:returnVal });//.catch(err=>{console.log(err)});  
        
      // });

       Crud_Catalogos("entregas/movimiento/"+this.props.Movimiento.id, "","get","",[],"","","",[]).then((returnVal) => {
         this.setState({ todasLasEntregas:returnVal });
         returnVal.map((elemento, index) => {                
                if (!this.state.cartasPorte.includes(elemento.cartaPorte)) {            
                  //this.state.cartasPorte.push(elemento.cartaPorte);  
                  this.setState({ cartasPorte: [...this.state.cartasPorte, elemento.cartaPorte] });
                }
                //bookings

                //destinos
                if (!this.state.destinos.includes(elemento.destino)) {            
                  this.setState({ destinos: [...this.state.destinos, elemento.destino] });
                }

                
                //if (!this.state.estadia.includes(elemento.estadia)) {  
                  let estadia_x = ((new Date(elemento.fechaSalida).getTime()-new Date(elemento.fechaArribo).getTime())/(3600*1000)).toFixed(2);// -elemento.fechaArribo.getTime();
                  elemento.fechaSalida?this.setState({ estadia: [...this.state.estadia, estadia_x+"hrs."] }):
                  this.setState({ estadia: [...this.state.estadia, "Sin captura de fecha de Salida"] });
                  //this.setState({ estadia: [...this.state.estadia, _estadia] });
                  //}
                //}
                //this.setState({arribo:elemento.fechaArribo});
                this.setState({arribo:this.state.todasLasEntregas[0].fechaArribo});
                //console.log(elemento);
                console.log(this.state.todasLasEntregas);
                //console.log("arribo <--entrega",elemento.fechaArribo);
                console.log("salida",elemento.fechaSalida);

              })
             });






         Crud_Catalogos("terrestreEntregasIncidencias/movimiento/"+this.props.Movimiento.id, "","get","",[],"","","",[]).then((returnVal) => {
         this.setState({ paradas:returnVal });//.catch(err=>{console.log(err)});
       });
   }
   
   componentDidMount = () => {   
      console.log(this.props.Movimiento.movimientoGps[0].gps.nombre);
      this.state.conteoParadas=0;
      this.state.paradas = [];
      let  rutaInicio         =  new Date(this.props.Movimiento.rutaInicio);

     let  fechaEntrega   =  new Date(this.props.Movimiento.fechaEntrega);
     //  let  fechaEntrega   =  new Date(this.props.Movimiento.endDate);
      let ttr= fechaEntrega.getTime()-rutaInicio.getTime();
      if(this.props.Movimiento.fechaEntrega){
        if(this.props.Movimiento.rutaInicio){
         ttr=((new Date(this.props.Movimiento.fechaEntrega).getTime()-new Date(this.props.Movimiento.rutaInicio).getTime())/(3600*1000)).toFixed(2);
        }
        else{ttr="Sin captura de Ruta de Inicio";}
      }
      else{
         ttr="Sin captura fecha de Entrega";
      }
      this.setState({tiempoTotalRuta:ttr});
      console.log("fechaEntrega",this.props.Movimiento.fechaEntrega);
      console.log("RutaInicio",this.props.Movimiento.rutaInicio);

     
   }

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "cliente",
    stateVar = "clientes",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if (stateVar === "movimientostodasLasEntregas") {
            return returnVal;
            }
            break;
          case "post":
            break;
          case "put":
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("Error desconocido ....");
        }
      });
  };


render() {
  this.state.conteoParadas=0;
  var position;
  var rendererCP='';
  //var latitudes=[];
  this.latitudesDeParadas=[];
  for(var con=0;con<this.state.paradas.length;con++){//parada, detenida, alimento, wc
     
     if([24,25,26,27,28,29,53,62,63,64,96,97,98].includes(this.state.paradas[con].idIncidencia)){
      console.log("Parada Latitud-->",this.state.paradas[con].latitud);
      console.log("Parada Longitud-->",this.state.paradas[con].longitud);
      this.latitudesDeParadas.push([this.state.paradas[con].latitud,this.state.paradas[con].longitud]);
      this.state.conteoParadas++;
     // console.log(this.latitudesDeParadas);
      //this.state.paradas[con].latitud;

     }
     //console.log(this.state.paradas[con].idIncidencia);
     //position = this.state.paradas[con].incidencia.incidenciaTipo.nombre.toLowerCase().search("detención");
     //  if(position!=-1)
     //   {
     
     //   }
 }

  let  arriboClienteVacio =  new Date(this.props.Movimiento.arriboClienteVacio);
  let  arriboClienteLleno =  new Date(this.props.Movimiento.arriboClienteLleno);
  let  inicioRuta         =  new Date(this.props.Movimiento.inicioRuta);
  let  salidaClienteLleno =  new Date(this.props.Movimiento.salidaClienteLleno);
  let  arriboPatioLinea   =  new Date(this.props.Movimiento.arriboPatioLinea);
  let  salidaClienteVacio   =  new Date(this.props.Movimiento.salidaClienteVacio);

  //  if( Math.abs(this.tiempoRuta) > 1600000000000 ){
  //   this.tiempoRuta = 0;
  //  }
  //  else{
  //   this.tiempoRuta = (this.tiempoRuta/(1000*3600)).toFixed(2) ;
  //  }

  //  if( Math.abs(this.state.tiempoTotalRuta) > 1600000000000 ){
  //   this.tiempoTotalRuta = 0;
  //  }
  //  else{
  //   this.tiempoTotalRuta = (this.tiempoTotalRuta/(1000*3600)).toFixed(2) ;
  //  }

  //  if( Math.abs(this.estadia) > 1600000000000 ){
  //   this.estadia = 0;
  //  }
  //  else{
  //   this.estadia = (this.estadia/(1000*3600)).toFixed(2) ;
  //  }

  this.avgLat=0;
  this.avgLon=0;
  let latAnterior=0;
  let lonAnterior=0;
  let x_distancia=0;
  let tder=0; //tiempo detenido en ruta

  this.state.trackPoints.map((onePoint, index) =>   
    {
     if(onePoint.position.latitude) {
         if(latAnterior==0)
         {latAnterior=onePoint.position.latitude;}
         if(lonAnterior==0)
         {lonAnterior=onePoint.position.longitude;}
     this.avgLat=this.avgLat+onePoint.position.latitude;
     this.avgLon=this.avgLon+onePoint.position.longitude;
     this.c.push([onePoint.position.latitude,onePoint.position.longitude])
     x_distancia=x_distancia +Math.sqrt( (latAnterior-onePoint.position.latitude)*(latAnterior-onePoint.position.latitude)+
                               (lonAnterior-onePoint.position.longitude)*(lonAnterior-onePoint.position.longitude));   
     //console.log(x_distancia);

     
          this.latitudesDeParadas.map((unaParada, index) =>   
          {
            if(Math.sqrt( (unaParada[0]-onePoint.position.latitude)*(unaParada[0]-onePoint.position.latitude)+(unaParada[1]-onePoint.position.longitude)*(unaParada[1]-onePoint.position.longitude) )<0.0001)
            {
              tder=tder+1;
              //console.log("tder",tder,index,unaParada[0],onePoint.position.latitude);
             // console.log("tder",unaParada[1],onePoint.position.longitude);

            }
               //tder=tder+this.c.filter(x => x==unaParada).length;//414123
            
          })
     latAnterior=onePoint.position.latitude;
     lonAnterior=onePoint.position.longitude;
     }
    }   
    )
    this.distancia_c=Math.round(111.1111*x_distancia) ;
    //this.setState({distanciaRecorrida:x_distancia});
    this.avgLat=this.avgLat/this.c.length;
    this.avgLon=this.avgLon/this.c.length;
    //console.log(this.c);


     this.state.tiempoDetenidoEnRuta=(60.0*this.state.tiempoTotalRuta*tder/this.c.length).toFixed(2);
     

   return (
    <div>
    <Grid.Row>
       {(this.props.padre!='BitacoraPdf')?  
            <div>   
                 <BitacoraPdf  ref={(response) => (this.componentRef = response)} padre={'BitacoraPdf'} Movimiento={this.props.Movimiento}  Img={this.props.img}/>                               
                 <ReactToPrint content={() => this.componentRef} trigger={() => <button  className="btn btn-primary"> PDF</button>}/>
            </div>
    :<div> 
      <input type="file"  id={"imageFile"+this.contador} name={"imageFile"+this.contador} onChange={this.imageUpload} />
      <input type="file"  id="imageFile2" name='imageFile2' onChange={this.imageUpload2} />
      <input type="file"  id="imageFile3" name='imageFile3' onChange={this.imageUpload3} />
        <img src="images/head.png" />
        <br/><br/><br/><Grid.Col><h2 align="right">Ciudad de México a {new Date().getDate()} de {Intl.DateTimeFormat('es-ES', { month: 'long'}).format(new Date())} de{new Date().getFullYear()}&nbsp;&nbsp;&nbsp;&nbsp;</h2>    
        <br/><br/>
          <div>          
             &nbsp;&nbsp;&nbsp; Servicio:<b>{/*this.props.Movimiento.idCliente.nombreCorto*/}</b>
            <p>&nbsp;&nbsp;&nbsp; Sky Angel </p> <br/><br/>
            <Table>
                <Table.Body>                           
                  <Table.Row>              
                    <Table.Col><b>Solicitó:</b>&nbsp;&nbsp;{this.props.Movimiento.transportista.contactoNombre}</Table.Col>
                    <Table.Col><b>Solicitado:</b>&nbsp;&nbsp;{new Date(new Date(this.props.Movimiento.creationDate)).getDate()} de {Intl.DateTimeFormat('es-ES', { month: 'long'}).format(new Date(this.props.Movimiento.creationDate))} de {new Date(new Date(this.props.Movimiento.creationDate)).getFullYear()}</Table.Col>                    
                  </Table.Row>                       
                </Table.Body>
              </Table>                          
              <Table  >
                <Table.Body>
                  <Table.Row>
                    <Table.Col> <br/><br/> <br/>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Col><b>Transporte:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.transportista.nombre}</Table.Col>
                  </Table.Row>
                  
                  <Table.Row>
                    <Table.Col><b>Gps:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.movimientoGps[0].gps.nombre}</Table.Col>
                  </Table.Row>
                    </Table.Body>
                      </Table>
              </Table.Col>  
              <Table.Col><br/><br/><br/>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Col><b>Unidad:</b>{this.props.Movimiento.placa} </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col><b>Carta Porte:</b>{
                       
                      //   this.state.patio.map((entrega, index) =>  
                      //    (<li>{entrega.cartaPorte}</li>)
                      //  )

                   this.state.cartasPorte.map((cp, index) =>  
                      (<li>{cp}</li>)                      
                    )

                    /*this.state.patio.remolquePlaca*/} </Table.Col>
                  </Table.Row>                 
                    </Table.Body>
              </Table>
            </Table.Col> </Table.Row></Table.Body>
            </Table>
              <Table >
                <Table.Body>
                <Table.Row>
                    <Table.Col><b>Origen:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.origen }</Table.Col>
                    <Table.Col><b>Destino:</b></Table.Col>
                    <Table.Col>{
                                       this.state.destinos.map((des, index) =>  
                                       (<li>{des}</li>)                      
                                     )
                               }</Table.Col>
                  </Table.Row>                   
                   <Table.Row>
                    <Table.Col><b>Salida a ruta:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.rutaInicio}</Table.Col>
                    <Table.Col><b>Arribo a cliente:</b></Table.Col>
                    <Table.Col>{this.state.arribo}</Table.Col>
                  </Table.Row> 
                  <Table.Row>
                    <Table.Col><b>Tiempo total de ruta:&nbsp;&nbsp;&nbsp;</b> { this.state.tiempoTotalRuta} hrs. </Table.Col>
                    <Table.Col>   </Table.Col>
                    <Table.Col><b>Estadía en cliente:&nbsp;&nbsp;&nbsp; </b>                   
                    {
                         this.state.estadia.map((est, index) =>  
                         (
                         
                          <li>Entrega {index+1}:&nbsp;{est}</li>)
                          )
                    }
                    </Table.Col>
                    <Table.Col></Table.Col>
                  </Table.Row> 
                  <Table.Row> 
                    <Table.Col><b>Detenciones en Ruta</b></Table.Col>
                    <Table.Col> 
                      {this.state.conteoParadas}
                     </Table.Col>
                    <Table.Col><b>Tiempo Detenido en Ruta</b></Table.Col>
                    <Table.Col> {this.state.tiempoDetenidoEnRuta} minutos</Table.Col>
                  </Table.Row>
                  <Table.Row>
                  <Table.Col><b>Distancia recorrida</b>&nbsp;&nbsp;&nbsp;{this.distancia_c}Km</Table.Col>
                    <Table.Col> 
                      {/*this.state.distancia === 0 ? "No disponible":this.state.distancia/1000*/}
                     </Table.Col>
                  </Table.Row>
                  <Table.Row>
                  <Table.Col><b>Evidencia de colocación</b></Table.Col>    
                  </Table.Row>                                 
              </Table.Body>
            </Table>
            <Table>
            <Table.Body>
            <Table.Row>
                    {/* <Table.Col><img src={this.img[0]} alt=""  /></Table.Col> */}
                    
                    <Table.Col>     {



                             this.state.trackPoints.length?           
                             
                               <MapContainer

                               
                                                     //center={[20, -100]}
                                                     zoom={6}
                                                     style={{  height: "400px", width:"350px" }}
                                                     whenReady={(map) => { 
                                                      //L.marker([20, -100], { icon } ).addTo(map.target);        
                                                       L.polyline(this.c).addTo(map.target)
                                                     }} 
                                                     //center={[20, -100]}
                                                     center={[this.avgLat, this.avgLon]}
                                                >    
                                                    <TileLayer      

                                                   url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                                                />     
                     
                                                      
                               </MapContainer>           
                                 :null
                                    }
                    </Table.Col> 
                     {this.state.contadorImagenes == 1 ?  
                     <Table.Col><img src={this.img} alt=""  style={{  height: "25vh", width:"32vh" }}/></Table.Col> 
                      :null}

                       { this.state.contadorImagenes == 2 ?  
                      <>
                        <Table.Col><img src={this.img} alt=""  style={{  height: "25vh", width:"32vh" }}/></Table.Col>                     
                        <Table.Col><img src={this.img2} alt=""  style={{  height: "25vh", width:"32vh" }}/></Table.Col>
                      </>
                    :null}
                    {/* <Table.Col><img src={this.img3} alt=""  style={{  height: "50vh", width:"25vh" }}/></Table.Col> */}


                    <Table.Col>
                    </Table.Col>
                  </Table.Row>
            </Table.Body>      
            </Table>

           
   
            </div>
           {/* <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}




            <div class="footer2">
               <h5 align="center"  >Río Danubio 87, Colonica Cuahutemoc, Delegación Cuahutémoc. C.P. 06500, Ciudad de México</h5>
               <h5 align="center" >Skyangel México•EUA•Centroamérica</h5>
               <h5 align="center">52 76 76 70 | 01 800 750 54 63</h5>
            </div> 
 
          </Grid.Col>



               </div>            
        }





    </Grid.Row>
    
    
    

    </div>





    );   
  } 
}



export default BitacoraPdf;
