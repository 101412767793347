import React, { Component } from "react";
import { Grid, Table, Form, Dimmer } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { AlertasNAll } from '../../../notificaciones/AlertasNAll'
import { from } from "rxjs";

class AlertaNForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      hiddenModals  : true,
      searchAlertas : "",
      usuarios      : [],
      filas         :[],
      loading       : true
    };
    this.filas= [];
  }
  componentWillMount=() =>{
      this.getfuntion("get", [], "", "usuarios", "usuarios");
      this.setState({ loading: true });
  }
  componentDidMount =() => { }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "usuarios") {
              var users={};
              returnVal.map((usr, index) =>{ users[usr.id]=usr; });
              this.setState({ usuarios: users });
              AlertasNAll(this.props.Movimiento).then((res) => {
                console.log(res);
                Object.keys( res ).forEach(
                  (key, indice) =>
                  res[key].map((elemento, index) =>{
                    var userid= elemento.alert.atiendeUsuario;
                    this.filas.push(
                      <Table.Row>
                        <Table.Col> <div className={'fa '+key} style={{ color: elemento.color}} ></div> </Table.Col>
                        <Table.Col> {elemento.alert.id} </Table.Col>
                        <Table.Col> {elemento.alert.latitud} </Table.Col>
                        <Table.Col> {elemento.alert.longitud} </Table.Col>
                        <Table.Col> {elemento.alert.triggerName} </Table.Col>
                        <Table.Col> {elemento.alert.mensaje} </Table.Col>
                        <Table.Col> {elemento.alert.fechaRegistro} </Table.Col>
                        <Table.Col> {elemento.alert.atiendeFecha} </Table.Col>
                        <Table.Col> {<Form.Switch type="Checkbox" checked={ elemento.alert.atendida } />} </Table.Col>
                        <Table.Col> {userid ?users[userid].persona.nombre+" "+ users[userid].persona.aPaterno+" "+ users[userid].persona.aMaterno : ""} </Table.Col>
                      </Table.Row>)
                  })
                );
                this.setState({ loading: false });
                this.setState({filas: this.filas });
              });
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err); });
  }
  render() {
    return (
      <Grid.Row>
        <Grid.Col>
          <Dimmer active={this.state.loading} loader>
            <h2>{ this.state.filas.length+" Alertas" }</h2>    
            {this.state.filas ?
            
              <Table cards={true} striped={true} responsive={true} className="table-vcenter" 
                      headerItems={[ { content: "" }, { content: "id" }, { content: "latitud" }, { content: "longitud" }, { content: "triggerName" }, { content: "mensaje" }, { content: "fecha Registro" }, { content: "fecha de atencion" }, { content: "atendida" }, { content: "Atiende Usuario" } ] } >
                <Table.Body>
                  {this.state.filas}
                </Table.Body>
              </Table>
              : "  No hay alertas Navieras..!!"
            }
          </Dimmer>
        </Grid.Col>
      </Grid.Row>
    );
  }
}
export default AlertaNForm;
