import React, { Component } from "react";
import * as conf from "../config/config";
import SockJsClient from 'react-stomp';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from 'axios';

let instance = axios.create();
const username= 'WN8z55WbAzF?bgy!BW5S9VB9$N#$fmUPx!fDf3=ZJft3mY3c@b';
const password= 'DzuJVh%k&Msvr@=k2a?zZz#4CuZ55awXSYkVm?_a#+a@xQ!pDn';
const user = JSON.parse(sessionStorage.getItem("usuario"));
let connected= false;
class TableroVisivilidadMensajeria extends Component {

  componentWillMount =() =>{
    instance.defaults.headers.common['Authorization'] = 'Basic '+window.btoa(username+":"+password);
    axios.get(conf.socket+"basicauth").then((response)=>{
    }).catch(err => {
      console.log("Error: " + err);
      axios.get(conf.socket+"basicauth").then((response)=>{}).catch(err => {
        console.log("Error: " + err);
      });
    });
  }
  componentDidMount =() => { }

  componentDidUpdate =() =>{ 
    if(this.clientRef.client){
      //console.log("conected: "+this.clientRef.client.connected);
      if( this.clientRef.client.connected !== connected){
          connected= this.clientRef.client.connected;
          if( connected ){
            this.setUserPing();
          }
      }
    }
   }

  setUserPing=()=>{
    this.clientRef.sendMessage(
      "/topic/tableroAsignacion",
      JSON.stringify(
        { "mensaje": "",
          "plataforma": "",
          "corredor": "",
          "vista": "tableroAsignacion",
          "data": {
            id_usuario: user.id,
            lastUpdate: new Date().getTime()
           },
          "accion": "get" }
      )
    );
    setTimeout(() => {
      this.setUserPing();
    }, 10000);
  }

  sendMessage = (msg, data) => {
    /* if(msg.vista == "tableroVisibilidad"){ this.clientRef.sendMessage("/topic/"+data.idPlataforma.id+"-"+data.idCorredor.id, JSON.stringify(msg)); }*/
  }
  getMensage=(metodo)=>{
    switch (metodo) {
      case "post":   return "Nuevo";
      case "put":    return "Actualización";
      case "delete": return "Finalizar";
      default:       return "Ación";
    }
  }
  udate=(mensaje) => {    
    if(mensaje.vista == "tableroVisibilidad"){
      document.getElementById('alerta12').play();
      //NotificationManager.info( mensaje.mensaje, this.getMensage(mensaje.accion) );
      let elementos=  this.props.elementos;
      console.log(elementos);
      let data= JSON.parse(mensaje.data);
      console.log(data);
      if (mensaje.accion !== "post" && data.id_entrega){
        elementos.map((elemento, index) =>{
          switch (mensaje.accion) {
            case "put":
              if(elemento["id_entrega"] == data.id_entrega){
                elementos[index]= data;
              }
              break;
            case "delete":
              if(elemento["id_entrega"] == data.id_entrega){
                elementos.splice(index, 1) }
              break;
            default:  break;
          }
        });
      }      
      if (mensaje.accion === "post"  && data.id_entrega ){ elementos.push(data); }
      this.props.actualizar(elementos);
    }
    if(mensaje.vista === "tableroAsignacion"){
      let usuarios=  {};
      usuarios=  this.props.usuarios;
      let data= mensaje.data;
      if( data ){
        usuarios[data.id_usuario]= data;
        usuarios[data.id_usuario].lastUpdate= new Date().getTime();
      }
      this.props.setUsuarios(usuarios);
    }
  }

  eServidor = () => {
    axios.get(conf.socket+"basicauth").then((response)=>{
      var request = new XMLHttpRequest();
      request.open('GET', conf.socket+'suscribe', true);
      request.send();
      if (request.status === 0) { //console.log("No Esta disponible el Servidor de Mensajes");
        return false;
      }else{
        return true;
      }
    }).catch(err => {
      console.log("Error: " + err);
    });

  }

  render() {
    return (
      <div>
        <SockJsClient url={ conf.socket+'suscribe/' }
            topics={this.props.topic}
            onMessage={(msg) =>{ this.udate(msg); }/** console.log(msg);  */}
            ref={ (client) => { this.clientRef = client;}}/>
    </div>
    );
  }
}
export default TableroVisivilidadMensajeria;
