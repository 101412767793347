import React, { Component } from "react";
import {
  Button,
  Form,
  Icon,
  Grid,
  Table,
  Card,
  Dimmer,
  List,
  Alert,
} from "tabler-react";
import { Crud_Catalogos, Crud_error } from "../../herramientas/Crud_Catalogos";
import BloqueForm from "../Bloques/BloqueForm";
import ModalLayout from "../../components/ModalLayout.react";
import TabsLayout from "../../components/TabsLayout.react";
/** alertas */
import { NotificationManager } from "react-notifications";
/** Formularios */

const AnyReactComponent = ({ text }) => <div>{text}</div>;

//Formato de Fecha
function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}
function myFormato(date) {
  console.log(date);
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}

/**
 * usuario
 */
var user = JSON.parse(sessionStorage.getItem("usuario"));
//import axios from 'axios';

class PanelView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detalles: [],
      updatePanel: false,
      incidencias: [],
      incidenciasAll: [],
      hiddenModals: false,
      movimientoGps: [],
      alertasN: this.props.alertasN,
      alertasT: this.props.alertasT,
      idMovimiento: 0,
      currentTap: 0,
      searchAlertas: "",
      searchIncidencias: "",
      loading: false,
      updateActivity: false,
      detallesCollase: false,
      alertasCollase: false,
      incidenciasCollase: false,
      entregas: [],
      unidad: [],
      incidenciasTerrestre: [],
      incidenTerrestre: [],
      idEntrega: null,
      selectedRow: -1,
      destinatarios: {
        destinatarios3: "",
        destinatarios4: "",
        destinatarios5: "",
        destinatarios6: "",
      },
      movimientoFull: null,
    };
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
    };
    this.incidencias = [];
    this.incidenciasTerrestre = [];
    this.alert = [];
    this.columna = [];
    this.privilegio = [];
    this.plataformaT = "";
    this.funcionalidades = [];
  }
  componentWillMount = () => {};
  componentDidMount = () => {
    let detalles = [];
    if (this.getFuncionalidad("Transportista")) {
      detalles.push({
        title: "Transformer",
        icon: "truck",
        panel: <div>Transportista</div>,
        close: false,
      });
    }
    if (this.getFuncionalidad("Cliente")) {
      detalles.push({
        title: "Clien",
        icon: "user",
        panel: <div>Cliente</div>,
        close: false,
      });
    }
    if (this.getFuncionalidad("Detalles")) {
      detalles.push({
        title: "Cita",
        icon: "watch",
        panel: <div>Cita</div>,
        close: false,
      });
    }
    if (this.getFuncionalidad("Actividad")) {
      detalles.push({
        title: "Act",
        icon: "activity",
        panel: <div>Actividad</div>,
        close: false,
      });
    }
    if (this.getFuncionalidad("Entregas")) {
      detalles.push({
        title: "Entrega",
        icon: "watch",
        panel: <div>Entregas</div>,
        close: false,
      });
    }
    this.setState({
      detalles: (
        <TabsLayout
          tabs={detalles}
          selectedTab={this.state.currentTap}
          selectTab={this.selectedTab}
        />
      ),
    });
  };
  componentDidUpdate = () => {
    if (this.props.updatePanel !== this.state.updatePanel) {
      this.setState({ updatePanel: this.props.updatePanel });
    }
    if (this.funcionalidades !== this.props.funcionalidades) {
      this.setState({ detalles: <div>Sin detalles</div> });
      this.funcionalidades = this.props.funcionalidades;
      let detalles = [];
      if (this.getFuncionalidad("Transportista")) {
        detalles.push({
          title: "Trans",
          icon: "truck",
          panel: <div>Transportista</div>,
          close: false,
        });
      }
      if (this.getFuncionalidad("Cliente")) {
        detalles.push({
          title: "Clien",
          icon: "user",
          panel: <div>Cliente</div>,
          close: false,
        });
      }
      if (this.getFuncionalidad("Detalles")) {
        detalles.push({
          title: "Cita",
          icon: "watch",
          panel: <div>Cita</div>,
          close: false,
        });
      }
      if (this.getFuncionalidad("Actividad")) {
        detalles.push({
          title: "Act",
          icon: "activity",
          panel: <div>Actividad</div>,
          close: false,
        });
      }
      if (this.getFuncionalidad("Entregas")) {
        detalles.push({
          title: "Entrega",
          icon: "watch",
          panel: <div>Entregas</div>,
          close: false,
        });
      }
      this.setState({
        detalles: (
          <TabsLayout
            tabs={detalles}
            selectedTab={this.state.currentTap}
            selectTab={this.selectedTab}
          />
        ),
      });
    }
  };
  searchingAlertas = (event) => {
    this.setState({ searchAlertas: event.target.value });
  };
  searchingIncidencias = (event) => {
    this.setState({ searchIncidencias: event.target.value });
  };
  getFuncionalidad = (elemento) => {
    if (!this.props.funcionalidades) {
      return false;
    }
    for (let item of this.props.funcionalidades) {
      if (item.nombre === elemento) {
        return true;
      }
    }
    return false;
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };

  render() {
    return (
      <div className="m-3">
        <Card>
          <Card.Header>
            <Card.Title>Panel de información</Card.Title>
            <Card.Options>
              <div>
                <div className="row">
                  <div className="col-12">
                    {this.getFuncionalidad("Actualizar") ? (
                      <span className="badge float-right">
                        <Button pill size="sm" outline color="primary">
                          <Icon name="upload" />
                          <Icon name="clock" />
                        </Button>
                      </span>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {this.getFuncionalidad("Arroba Azul") ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Arroba azul"
                      >
                        <label style={{ color: "blue", "font-size": "200%" }}>
                          <Icon name="at-sign" />
                        </label>
                      </span>
                    ) : (
                      <div></div>
                    )}
                    {this.getFuncionalidad("Arroba Roja") ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Arroba Roja"
                      >
                        <label style={{ color: "red", "font-size": "200%" }}>
                          <Icon name="at-sign" />
                        </label>
                      </span>
                    ) : (
                      <div></div>
                    )}
                    {this.getFuncionalidad("Arroba Negra") ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Arroba Negra"
                      >
                        <label style={{ color: "black", "font-size": "200%" }}>
                          <Icon name="at-sign" />
                        </label>
                      </span>
                    ) : (
                      <div></div>
                    )}
                    {this.getFuncionalidad("Arroba Amarilla") ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Arroba Amarilla"
                      >
                        <label style={{ color: "yellow", "font-size": "200%" }}>
                          <Icon name="at-sign" />
                        </label>
                      </span>
                    ) : (
                      <div></div>
                    )}
                    {this.getFuncionalidad("Rastreame") ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Rastrear"
                      >
                        <label style={{ color: "black", "font-size": "200%" }}>
                          <Icon name="map-pin" />
                        </label>
                      </span>
                    ) : (
                      <div></div>
                    )}
                    {this.getFuncionalidad("Reaccion") ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Reación"
                      >
                        <label style={{ color: "black", "font-size": "200%" }}>
                          {" "}
                          <Icon prefix="fa" name="warning" />
                        </label>
                      </span>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <div className="col-12 bg-primary text-light">
              <h4>
                Detalles
                <div
                  className="float-right col-1 right"
                  style={{ "background-color": "rgba(6, 0, 0, 0.2)" }}
                  onClick={() =>
                    this.setState({
                      detallesCollase: !this.state.detallesCollase,
                    })
                  }
                >
                  <Icon
                    name={
                      this.state.detallesCollase ? "chevron-down" : "chevron-up"
                    }
                    className="float-right"
                  />
                </div>
              </h4>
            </div>
            <div
              className="panel panel-default"
              hidden={this.state.detallesCollase}
            >
              <div
                className="col-12 panel-resizable"
                style={{ height: "10vh" }}
              >
                {this.state.detalles.length > 0
                  ? console.log(this.state.detalles)
                  : null}
                {this.props.naviera
                  ? this.props.naviera && !this.state.updatePanel
                    ? this.state.detalles
                    : "Actualizando"
                  : null}
                {this.props.terrestre
                  ? this.props.terrestre && !this.state.updatePanel
                    ? this.state.detalles
                    : "Actualizando"
                  : null}
              </div>
            </div>
            {/*
             * Alertas
             */}

            <div className="col-12 bg-primary text-light">
              <h4>
                Alertas Navieras
                <div
                  className="float-right col-1 right"
                  style={{ "background-color": "rgba(6, 0, 0, 0.2)" }}
                  onClick={() =>
                    this.setState({
                      alertasCollase: !this.state.alertasCollase,
                    })
                  }
                >
                  <Icon
                    name={
                      this.state.alertasCollase ? "chevron-down" : "chevron-up"
                    }
                    className="float-right"
                  />
                </div>
              </h4>
            </div>
            <div
              className="panel panel-default"
              hidden={this.state.alertasCollase}
            >
              <div
                className="col-12 panel-resizable"
                style={{ height: "10vh" }}
              >
                <Grid.Row>
                  <Grid.Col sm={8} lg={12}>
                    <div>
                      <Grid.Row>
                        <Grid.Col sm={7} lg={7}>
                          {this.state.incidencias.length > 0 ? (
                            <Form.Input
                              className="mb-3"
                              icon="search"
                              placeholder="Buscar Alertas..."
                              position="append"
                              onChange={this.searchingAlertas}
                            />
                          ) : null}
                        </Grid.Col>
                        <Grid.Col sm={2} lg={2}>
                          {this.getFuncionalidad("Alertas Atendidas") ? (
                            <Button pill size="sm" outline color="primary">
                              <Icon name="paperclip" />
                            </Button>
                          ) : (
                            <div></div>
                          )}
                        </Grid.Col>
                      </Grid.Row>
                    </div>
                  </Grid.Col>
                </Grid.Row>
                <div>
                  {this.getFuncionalidad("Alertas No Atendidas") ? (
                    <Table
                      cards={true}
                      striped={true}
                      responsive={true}
                      className="table-vcenter"
                      headerItems={[
                        { content: "ID" },
                        { content: "Tiggername" },
                        { content: "Atendido" },
                      ]}
                    >
                      <Table.Body>
                        <Table.Row>
                          <Table.Col> 1 </Table.Col>{" "}
                          <Table.Col> Alerta </Table.Col>
                          <Table.Col>
                            {this.getFuncionalidad("Atender Alertas") ? (
                              <Button pill size="sm" outline color="primary">
                                <div
                                  className={"fa fa-bell"}
                                  style={{ color: "blue" }}
                                ></div>
                              </Button>
                            ) : (
                              <div></div>
                            )}
                          </Table.Col>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>

            {/**
             * Incidencias
             */}
            <div className="col-12 bg-primary text-light">
              <h4>
                Incidencias
                <div
                  className="float-right col-1 right"
                  style={{ "background-color": "rgba(6, 0, 0, 0.2)" }}
                  onClick={() =>
                    this.setState({
                      incidenciasCollase: !this.state.incidenciasCollase,
                    })
                  }
                >
                  <Icon
                    name={
                      this.state.incidenciasCollase
                        ? "chevron-down"
                        : "chevron-up"
                    }
                    className="float-right"
                  />
                </div>
              </h4>
            </div>
            <div
              className="panel panel-default"
              hidden={this.state.incidenciasCollase}
            >
              <div
                className="col-12 panel-resizable"
                style={{ height: "10vh" }}
              >
                <Grid.Row>
                  <Grid.Col sm={8} lg={12}>
                    <div>
                      <Grid.Row>
                        <Grid.Col sm={7} lg={7}>
                          {this.state.incidencias.length > 0 ? (
                            <Form.Input
                              className="mb-3"
                              icon="search"
                              placeholder="Buscar Incidencias..."
                              position="append"
                              onChange={this.searchingIncidencias}
                            />
                          ) : null}
                        </Grid.Col>
                        <Grid.Col sm={2} lg={2}>
                          {this.getFuncionalidad("Incidencias Detallada") ? (
                            <Button pill size="sm" outline color="primary">
                              <Icon name="paperclip" />
                            </Button>
                          ) : (
                            <div></div>
                          )}
                        </Grid.Col>

                        <Grid.Col sm={2} lg={2}>
                          {this.getFuncionalidad("Crear Incidencia") ? (
                            <Button pill size="sm" outline color="primary">
                              <Icon name="plus" />
                            </Button>
                          ) : (
                            <div></div>
                          )}
                        </Grid.Col>
                      </Grid.Row>
                    </div>
                  </Grid.Col>
                </Grid.Row>

                {this.getFuncionalidad("Incidencias Rapida") ? (
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter bg-light"
                    headerItems={[
                      { content: "ID" },
                      { content: "Nombre" },
                      { content: "Comentario" },
                      { content: "Fecha" },
                    ]} //[{ content: "ID" }, { content: "Nombre" }]
                  >
                    <Table.Body>
                      <Table.Row>
                        <Table.Col>1</Table.Col>
                        <Table.Col>Panico</Table.Col>
                        <Table.Col>Prueba</Table.Col>
                        <Table.Col>00/00/0000</Table.Col>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ) : null}
                {this.state.movimiento &&
                this.state.incidenciasTerrestre.length > 0 &&
                this.props.plataformaTipo === "Terrestre" ? (
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter bg-light"
                    headerItems={[
                      { content: "ID" },
                      { content: "Comentario" },
                      { content: "Creado" },
                      { content: "Atencion Fecha" },
                    ]} //[{ content: "ID" }, { content: "Nombre" }]
                  >
                    <Table.Body>
                      {this.props.plataformaTipo === "Terrestre"
                        ? this.state.incidenciasTerrestre
                            .filter((word) =>
                              word.comentario
                                .toUpperCase()
                                .includes(
                                  this.state.searchIncidencias.toUpperCase()
                                )
                            )
                            .sort()
                            .map((elemento, index) => (
                              <Table.Row>
                                <Table.Col>{elemento.idIncidencia}</Table.Col>
                                <Table.Col>{elemento.comentario}</Table.Col>
                                <Table.Col>{elemento.creado}</Table.Col>
                                <Table.Col>{elemento.atencionFecha}</Table.Col>
                              </Table.Row>
                            ))
                        : null}
                    </Table.Body>
                  </Table>
                ) : null}
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <h3>Comentario</h3>
            <p>Esto es un comentario</p>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}
export default PanelView;
