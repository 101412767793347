import React, { useState, useEffect } from 'react';
import { Doughnut, Bar, Polar } from 'react-chartjs-2';

import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import { Grid, Button, Icon, Form, Table, List } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import ModalLayout from "../../components/ModalLayout.react";
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Circle, Polyline} from 'google-maps-react';
const currentUser= JSON.parse(sessionStorage.getItem("currentUser"));


const MapaPage = () => {
  const [ consignatarios, setConsignatarios ] = useState([]);
  const [ pin, setPin]= useState([]);
  const [ geocercas, setGeocercas]= useState([]);
  const [ cv, SetCv]= useState([]);
  const [ consignatario, setConsignatario ] = useState([]);
  const [ open, setOpen]= useState(false);
  const [ hiddenModals, setHiddenModals ]= useState(false);

  const [ year, setYear]= useState(new Date().getFullYear() );
  const [ week, setWeek]= useState(new Date().getWeekNumber() );
  const [ otds, setOtds]= useState( [] );
  const [ transportistas, setTransportistas]= useState([]);
  const [ transportista, setTransportista]= useState({});
  const [ detalle, setDetalle]= useState("r_otd");

  useEffect(() => {
    //alert(process.env.PUBLIC_URL+"/marcadores/truck.svg")

    //document.title = `${plataforma.label}`;
    crud("get", "", "", "tableroMapa/eta", "eta");
  },[]);
  const getData=(e)=>{
    if( year >= 2020 && week > 0 ){
      crud("get", "", "", "terrestreMovimientos/total_mov_x_Tarnportista_x_Semana/" + year+"/"+ week , "mov_tras_x_semana");
      crud("get", "", "", "terrestreMovimientos/total_otd_x_Semana/" + year+"/"+ week , "otd_x_semana");
    }
  }
  const changeWeek=(e)=>{
    setWeek(e.target.value);
  }
  const changueDetalle =(det, valor)=>{
    setDetalle(det);
    if(det === 'r_semana'){
      setWeek(valor);
      setTransportista({});
      crud("get", "", "", "terrestreMovimientos/total_mov_x_Tarnportista_x_Semana/" + year+"/"+ valor , "mov_tras_x_semana");
    }
  }
  const changeCv=(e)=>{
    SetCv(e);
    setOpen(true);
    setOpen(false);
  }
  return (
    <SiteWrapper>
      <div className="mt-3">
        <h5> Tablero de posiciones </h5>
        <Grid.Row>{console.log(consignatario)}
              <Grid.Col>
                <Form.Group label="Seleccione Consignatario / Controles Vehiculares:">
                  <Select value={consignatario}  options={consignatarios} onChange={setConsignatario} isMulti={true } />
                </Form.Group>
              </Grid.Col>              
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <h3>Información del Control Vehicular:  (Seleccione del mapa)</h3>
            <div style={{width: '100%', height: '70vh'}}>
              <Map
                google={window.google}
                style={{width: '100%', height: '100%'}}
                className={'map'}
                zoom={10}
                initialCenter={{
                    lat: 19.430763,
                    lng: -99.1680802
                  }}
                visible={true}
                onReady={true}
              >
                { pin.map((c,index)=>
                  <Polyline
                    path={c.tracks}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2} />
                )}
                { consignatario?
                  consignatario.length?
                    consignatario.map((cp,index)=>
                      cp.data.map((c,index)=>
                        <Marker
                            title={c.placa_gpsc+" - CV: "+c.cont_veic+ " - "+ c.tracks[0].lat+", "+c.tracks[0].lng }
                            name={c.placa_gpsc}
                            position={{lat: c.tracks[0].lat, lng: c.tracks[0].lng}}
                            icon={{
                                url: "marcadores/truck.svg",
                                //anchor: new google.maps.Point(32,32),
                                //scaledSize: new google.maps.Size(64,64)
                              }}
                              onClick={()=> changeCv(c) }
                        />)
                    )
                    :pin.map((c,index)=>
                        <Marker
                            title={c.placa_gpsc+" - CV: "+c.cont_veic+ " - "+ c.tracks[0].lat+", "+c.tracks[0].lng }
                            name={c.placa_gpsc}
                            position={{lat: c.tracks[0].lat, lng: c.tracks[0].lng}}
                            icon={{
                                url: "marcadores/truck.svg" ,
                                //anchor: new google.maps.Point(32,32),
                                //scaledSize: new google.maps.Size(64,64)
                              }}
                              onClick={()=> changeCv(c) }
                        />)
                  :pin.map((c,index)=>
                    <Marker
                        title={c.placa_gpsc+" - CV: "+c.cont_veic+ " - "+ c.tracks[0].lat+", "+c.tracks[0].lng }
                        name={c.placa_gpsc}
                        position={{lat: c.tracks[0].lat, lng: c.tracks[0].lng}}
                        icon={{
                            url: "marcadores/truck.svg",
                            //anchor: new google.maps.Point(32,32),
                            //scaledSize: new google.maps.Size(64,64)
                          }}
                          onClick={()=> changeCv(c) }
                    />)
                }
                { geocercas.map((g,index)=>// geocercas
                  g.type === "Circle"?
                    <Circle
                      radius={g.radius}
                      center={g.vertices[0]}
                      onMouseover={() => console.log('mouseover')}
                      onClick={() => console.log('click')}
                      onMouseout={() => console.log('mouseout')}
                      strokeColor='transparent'
                      strokeOpacity={0}
                      strokeWeight={5}
                      fillColor={g.color}
                      fillOpacity={0.12}
                    />
                  :null
                )}
                {/*
                <InfoWindow onClose={()=>alert("exit")}>
                    <div>
                    <h1>{"Rio danubio"}</h1>
                    </div>
                </InfoWindow>
                */}
              </Map>
            </div>
              
          </Grid.Col>
          <ModalLayout
              tamanio={"50%"}
              title="Modificar"
              name={""}
              open={open}
              setOpen={ setOpen }
              hiddenModal={ hiddenModals }
              Fclose={ setHiddenModals }
              formulario={
                <div>
                  <Form.Group label="Información del Control Vehicular:">
                    <List.Group>
                      <List.GroupItem action icon="hash"> Control Vehicular:         <strong>  { cv? cv.cont_veic: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="truck"> Línea:                    <strong>  { cv? cv.transportista: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="hash"> Económico:                 <strong>  { cv? cv.economico_gpsc: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="credit-card"> Placa:              <strong>  { cv? cv.placa_gpsc: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="map-pin"> Origen:                 <strong>  { cv? cv.origen: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="map-pin"> Destino:                <strong>  { cv? cv.destino: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="clock"> Cita Prog:                <strong>  { cv? cv.cita_prog: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="clock"> Cita Carga:               <strong>  { cv? cv.cita_carga: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="clock"> ETA:                      <strong>  { cv? cv.eta: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="flag"> Estado ETA:                <strong>  { cv? cv.esta_a_tiempo: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="maximize-2"> Distancia Restante:  <strong>  { cv? cv.left_distance: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="flag"> Estatus del embarque:      <strong>  { cv? cv.desc_incidencia: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="clock"> Fecha estatus embarque:   <strong>  { cv? cv.fecha_incidencia: "" } </strong> </List.GroupItem>
                    </List.Group>
                  </Form.Group>
                  <h3>Mostrar destino y posible ruta para el CV: { cv? cv.cve_destino_dhl: "" }</h3>
                  <button type="button" className="btn btn-dark fa fa-map-o" onClick={()=> alert("Detalle") }></button>
                </div>
              }
            />{/*
          <Grid.Col sm={4} sm={4} hidden= {true}>
            <Form.Group label="Información del Control Vehicular:  (Seleccione del mapa)">
              <List.Group>
                <List.GroupItem action icon="hash"> Control Vehicular:         <strong>  { cv? cv.cont_veic: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="truck"> Línea:                    <strong>  { cv? cv.transportista: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="hash"> Económico:                 <strong>  { cv? cv.economico_gpsc: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="credit-card"> Placa:              <strong>  { cv? cv.placa_gpsc: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="map-pin"> Origen:                 <strong>  { cv? cv.origen: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="map-pin"> Destino:                <strong>  { cv? cv.destino: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="clock"> Cita Prog:                <strong>  { cv? cv.cita_prog: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="clock"> Cita Carga:               <strong>  { cv? cv.cita_carga: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="clock"> ETA:                      <strong>  { cv? cv.eta: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="flag"> Estado ETA:                <strong>  { cv? cv.esta_a_tiempo: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="maximize-2"> Distancia Restante:  <strong>  { cv? cv.left_distance: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="flag"> Estatus del embarque:      <strong>  { cv? cv.desc_incidencia: "" } </strong> </List.GroupItem>
                <List.GroupItem action icon="clock"> Fecha estatus embarque:   <strong>  { cv? cv.fecha_incidencia: "" } </strong> </List.GroupItem>
              </List.Group>
            </Form.Group>
          </Grid.Col>*/}
        </Grid.Row>
        
      </div>
    </SiteWrapper>
  );

  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    if (stateVar === "mov_tras_x_semana" && returnVal) {
                    }
                    if( stateVar  === 'eta' && returnVal){
                      const resp= JSON.parse(returnVal.better_result);
                      let consignatariosTem= [];
                      let pinTem= [];
                      let geocercasTemp= [];
                      for(let item of resp){
                        consignatariosTem.push({ value: item.id, label: item.text , data: item.children});
                        for(let c of item.children){
                          pinTem.push(c);
                          for(let g of c.geofence){
                            geocercasTemp.push(g);
                          }
                        }
                      }

                      setPin(pinTem);
                      setConsignatarios(consignatariosTem);
                      setGeocercas(geocercasTemp);
                      console.log(JSON.parse(returnVal.better_result));
                      console.log(pinTem)
                    }
                } break;
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }/*
    Date.prototype.getWeekNumber = function(){
      var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
      var dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
    };*/
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyDnYlMdZpOyilKyWxeE2uXcTiKnq2LRJW4")
  })(MapaPage)
//export default MapaPage;


/*
<Polygon
                    paths={g.vertices}
                    strokeColor={g.color === "NEGRO"? "black": "red"}
                    strokeOpacity={0.8}
                    strokeWeight={2}
                    fillColor={g.color === "NEGRO"? "blue": "green"}
                    fillOpacity={0.35} />
*/