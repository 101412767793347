import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import ModalLayout from "../../../components/ModalLayout.react";
/**
 * Notificaciones
 */
import { NotificationManager } from 'react-notifications';
class Transportistas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Campos de la Entity
      id: "",
      nombre: "",
      nombreCorto: "",
      contactoNombre: "",
      telefono1: "",
      telefono2: "",
      correo: "",
      tipo: 2,
      idTransportistaDhl: "",
      cron: "00:00:00",
      crudbutonEnable: true, // Se desabilita cuando se hace click
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      hiddenModals: false,
      idPlataformaTipo: { value: 0, label: "Selecciona tipo de plataforma" },
      idMovimientoTipo: { value: 0, label: "Selecione tipo de movmiento" },
      idCampoLogistico: { value: 0, label: " Seleccione un campoLogistico" },
      camposLogisticos: [],
      movimientosTipo: [],
      configuracionesTransportistaConsolidado: []
    };
    //this.plataformaId=0;
    this.tipo = [];
    this.transportistaObject = { "id": 0, "nombre": "", "nombreCorto": "", "contactoNombre": "", "telefono1": "", "telefono2": "", "correo": "", "tipo": 2, "cron": "00:00:00", "idTransportistaDhl":"", "gerenciado":"false" }
    this.configuracionTransportistaConsolidado = { "idTransportista": this.props.elementos ? this.props.elementos.id : null, "idMovimientoTipo": 0, "idCampoLogistico": 0 };
    this.configuracion = {};
  }
  isEnable = (Accion) => {
    let borrar = "transportistas_borrar";
    let actualizar = "transportistas_editar";
    let agregar = "transportistas_agregar";
    var privilegios = this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) { if (perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0) { return true } }
      }
      if (Accion === "actualizar") { if (privilegios[i].clave == actualizar) { if (perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0) { return true } } }
      if (Accion === "agregar") { if (privilegios[i].clave == agregar) { if (perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0) { return true } } }
    }
    return false
  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  }
  componentDidMount = () => {
    this.tipo.push({ value: 0, label: "Elije el Tipo" });
    this.tipo.push({ value: 1, label: "Naviero" });
    this.tipo.push({ value: 2, label: "Terrestre" });

    if (this.state.accion !== 'nuevo') {
      this.transportistaObject = this.props.elementos
      this.setState({
        id: this.props.elementos.id ? this.props.elementos.id : "",
        nombre: this.props.elementos.nombre ? this.props.elementos.nombre : "",
        nombreCorto: this.props.elementos.nombreCorto ? this.props.elementos.nombreCorto : "",
        contactoNombre: this.props.elementos.contactoNombre ? this.props.elementos.contactoNombre : "",
        telefono1: this.props.elementos.telefono1 ? this.props.elementos.telefono1 : "",
        telefono2: this.props.elementos.telefono2 ? this.props.elementos.telefono2 : "",
        correo: this.props.elementos.correo ? this.props.elementos.correo : "",
        tipo: this.props.elementos.tipo ? this.props.elementos.tipo : 2,
        idTransportistaDhl: this.props.elementos.idTransportistaDhl ? this.props.elementos.idTransportistaDhl : "",
      });
      this.setState({ cron: this.props.elementos.cron ? this.props.elementos.cron : "00:00:00" });
      if (this.props.elementos.tipo === 1) {
        //this.tipo.push({ value:1, label:"Naviero"});
        this.setState({ tipo: { value: 1, label: "Naviero" } }); //returnVal
      }
      if (this.props.elementos.tipo === 2) {
        //this.tipo.push({ value:2, label:"Terrestre"});
        this.setState({ tipo: { value: 2, label: "Terrestre" } }); //returnVal
      }
      this.getfuntion("get", "", "", "camposLogisticos", "camposLogisticos");
      this.getfuntion("get", "", "", "movimientosTipo", "movimientosTipo");

    } else {
      //this.transportistaObject = {"id":0, "nombre":"", "nombreCorto":"", "domicilio":"", "contacto":"", "telefono":"", "extension":"", "nota":"", "vip":"", "latitud":"", "ongitud":"", "estatus":""};
    }
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas", stateVar = "personas", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "camposLogisticos" && returnVal) {
              let calogs = [{ value: null, label: "Seleccione un campoLogistico" }];
              for (let item of returnVal) {
                calogs.push({ value: item.id, label: item.nombre, data: item });
              }
              this.camposLogisticos = calogs;
              this.setState({ idCampoLogistico: { value: 0, label: "Seleccione un tipo de movimiento" } });
            }

            if (stateVar === "movimientosTipo") {
              let movimientoTipos = [];
              returnVal.map((mt, index) => movimientoTipos.push({ value: mt.id, label: mt.nombre, data: mt }));
              this.setState({ movimientosTipo: movimientoTipos });
              if (this.props.accion == 'nuevo') { this.setState({ idMovimientoTipo: { value: 0, label: "Selecciona un Tipo de movimiento" } }); }
              this.getfuntion("get", "", "", "configuracion_transportista_consolidado/transportista/" + this.props.elementos.id, "configuracion");
            }
            if (stateVar === "configuracion") {
              this.setState({ configuracionesTransportistaConsolidado: returnVal });
              if (this.props.plataforma) {
                this.changeMovimientoTipo({ value: 1, label: "Importacion" });
              }
            }
          }
          break;
        case "post":
          {
            if (stateVar === "configuracion") {
              NotificationManager.info("Guardado ..", "Guardado");
              let confTemp = this.state.configuracionesTransportistaConsolidado;
              confTemp.push(returnVal);
              this.setState({ configuracionesTransportistaConsolidado: confTemp });
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "configuracion") {
              let confTemp = this.state.configuracionesTransportistaConsolidado;
              confTemp.map((item, index) => {
                if (item.idTransportista + "/" + item.idMovimientoTipo + "/" + item.idCampoLogistico === id) {
                  if (returnVal) {
                    confTemp.splice(index, 1);
                    NotificationManager.info("Borrado ..", "Guardado");
                  } else {
                    NotificationManager.info("No se ha podido borrar ..", "Error");
                  }
                  this.setState({ configuracionesTransportistaConsolidado: confTemp });
                }
              });
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }

  enableSave = () => {
    
    if (this.state.nombre != null && this.state.nombreCorto != null && this.state.contactoNombre != null && this.state.telefono1 != null && this.state.correo != null) {
      if (this.state.nombre.length > 0 && this.state.nombreCorto.length > 0 && this.state.contactoNombre.length > 0 && this.state.telefono1.length > 0 && this.state.correo.length > 0) {
        return false;
      } else { return true }
    } else {return true}
  }

  delete() { this.setState({ crudbutonEnable: false }); this.props.getfuntion("delete", "", this.state.id); }
  crud_put() { this.setState({ crudbutonEnable: false }); this.props.getfuntion("put", this.transportistaObject); }
  crud_post() { this.setState({ crudbutonEnable: false }); this.props.getfuntion("post", this.transportistaObject); }
  changeNombre = (event) => {
    this.transportistaObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  }
  changeNombreCorto = (event) => {
    this.transportistaObject.nombreCorto = event.target.value;
    this.setState({ nombreCorto: event.target.value });
  }
  changeContactoNombre = (event) => {
    this.transportistaObject.contactoNombre = event.target.value;
    this.setState({ contactoNombre: event.target.value });
  }
  changeTelefono1 = (event) => {
    this.transportistaObject.telefono1 = event.target.value;
    this.setState({ telefono1: event.target.value });
    
  }
  changeTelefono2 = (event) => {
    this.transportistaObject.telefono2 = event.target.value;
    this.setState({ telefono2: event.target.value });
  }
  changeCorreo = (event) => {
    this.transportistaObject.correo = event.target.value;
    this.setState({ correo: event.target.value });
  }
  changeTipo = (event) => {
    if (event.value === 1) {
      this.setState({ tipo: { value: 1, label: "Naviero" } }); //returnVal
    }
    if (event.value === 2) {
      this.setState({ tipo: { value: 2, label: "Terrestre" } }); //returnVal
    }
    this.transportistaObject.tipo = event.value;
  }
  changeCron = (e) => {
    this.transportistaObject.cron = e.target.value;
    this.setState({ cron: e.target.value });
  }
  changeIdTransportistaDhl = (event) => {
    this.transportistaObject.idTransportistaDhl = event.target.value;
    this.setState({ idTransportistaDhl: event.target.value });
  }

  changeMovimientoTipo = (event) => {
    this.setState({ idMovimientoTipo: event });
    this.configuracionTransportistaConsolidado.idMovimientoTipo = event.value;
    let idMotipoCampoLog = 0;
    if (event.value === 1 || event.value === 3) { idMotipoCampoLog = 1 }
    if (event.value === 2 || event.value === 4) { idMotipoCampoLog = 2 }//Preguntar por campos terrestre
    if (this.props.plataforma) {
      idMotipoCampoLog = this.props.plataforma.plataformaTipo.id === 2 ? 3 : idMotipoCampoLog;
    }
    let camposMapeados = "arribo_cliente_lleno, arribo_cliente_vacio, fecha_arribo, salida_cliente_vacio, salida_cliente_lleno, fecha_salida, arribo_patio_linea, arribo_terminal_vacio_2, arribo_terminal_cargado";
    let camposMapeadosTer = "fecha_arribo, fecha_salida";
    let camposMapeadosTemp = this.props.plataforma.plataformaTipo.id === 2 ? camposMapeadosTer : camposMapeados;
    let calogs = [{ value: 0, label: "Seleccione un campoLogistico" }];
    for (let item of this.camposLogisticos) {
      if (item.data) {
        if (item.data.idMovimientoTipo === idMotipoCampoLog && camposMapeadosTemp.includes(item.data.nombreCampo)) {
          calogs.push(item);
        }
      }
    }
    this.setState({ camposLogisticos: calogs });
  }
  changeCampoLogistico = (e) => {
    this.setState({ idCampoLogistico: e });
    this.configuracionTransportistaConsolidado.idCampoLogistico = e.value;
  }
  getMovimientoTipoLabel = (id) => {
    for (let item of this.state.movimientosTipo) { if (item.value === id) { return item.label; } }
    return "";
  }
  getCampoLogisticoLabel = (id) => {
    for (let item of this.camposLogisticos) { if (item.value === id) { return item.label; } }
    return "";
  }
  validar = () => {
    let invalido = false;
    
    this.state.configuracionesTransportistaConsolidado.map((conf, index) => {
      if (this.state.idMovimientoTipo.value === conf.idMovimientoTipo || //this.state.idCampoLogistico.value === conf.idCampoLogistico ||
        this.state.idMovimientoTipo.value === 0 || this.state.idCampoLogistico.value === 0) {
        invalido = true;
      }
    });
    return invalido;
  }
  saveConfiguracionPlantillaConsolidado = () => {
    this.getfuntion("post", this.configuracionTransportistaConsolidado, "", "configuracion_transportista_consolidado", "configuracion");
  }
  borrarConfiguracionTransportistaConsolidado = (idTransportista, idmTipo, idCamLog) => {
    this.getfuntion("delete", "", idTransportista + "/" + idmTipo + "/" + idCamLog, "configuracion_transportista_consolidado", "configuracion");
  }

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? <h1> {this.props.elementos.nombre} </h1> : null}
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <Card title="Transportista" isCollapsible isCollapsed={false} body={
              <div>
                <Grid.Row>
                  <Grid.Col>
                    <Form.Group label="id">
                      <Form.Input name="id" disabled type="number" value={this.state.id} />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="nombre">
                      <Form.Input name="nombre" type="text" value={this.state.nombre} onChange={this.changeNombre} maxlength="40" />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="nombreCorto">
                      <Form.Input name="nombreCorto" type="text" value={this.state.nombreCorto} onChange={this.changeNombreCorto} maxlength="20" />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col md={8} lg={8}>
                    <Form.Group label="ID transportista DHL">
                      <Form.Input name="idTransportistaDhl" type="text" value={this.state.idTransportistaDhl} onChange={this.changeIdTransportistaDhl} maxlength="40" />
                    </Form.Group>
                  </Grid.Col>

                </Grid.Row>

                <Grid.Row>
                  <Grid.Col md={6} lg={6}>
                    <Form.Group label="Contacto Nombre">
                      <Form.Input name="contactoNombre" type="text" value={this.state.contactoNombre} onChange={this.changeContactoNombre} maxlength="40" />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Telefono 1">
                      <Form.Input name="telefono1" type="text" value={this.state.telefono1} onChange={this.changeTelefono1} maxlength="20" />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Telefono 2">
                      <Form.Input name="telefono2" type="text" value={this.state.telefono2} onChange={this.changeTelefono2} maxlength="20" />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={9} lg={3}>
                    <Form.Group label="Correo">
                      <Form.Input name="correo" type="text" value={this.state.correo} onChange={this.changeCorreo} maxlength="10" />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>

                {/* <Grid.Row>
                  <Grid.Col md={3} lg={3}>
                    <Form.Group label="Tipo">
                      <Select value={this.state.tipo} onChange={this.changeTipo} options={this.tipo} />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Para envio de consolidado establecer el tiempo">
                      <Form.MaskedInput
                        placeholder="HH:MM:SS"
                        value={this.state.cron}
                        onChange={this.changeCron}
                        mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    {this.props.elementos ?
                      <Form.Group label="Para detener envio de consolidado">
                        <ModalLayout tamanio={"60%"} title="Campo logistico" name={
                          <span className="badge"><Button pill size="sm" outline color="primary"><Icon name="edit" /></Button></span>
                        } hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                          formulario={
                            <div>
                              <Grid.Row>
                                <Grid.Col sm={5} md={5}>
                                  <Form.Group label="Tipo de movimiento">
                                    <Select
                                      value={this.state.idMovimientoTipo}
                                      onChange={this.changeMovimientoTipo}
                                      options={this.state.movimientosTipo}
                                      isDisabled={this.props.plataforma ? this.props.plataforma.plataformaTipo.id === 2 ? true : false : false}
                                    />
                                  </Form.Group>
                                </Grid.Col>
                                <Grid.Col sm={5} md={5}>
                                  <Form.Group label="Campo logistico">
                                    <Select
                                      value={this.state.idCampoLogistico}
                                      onChange={this.changeCampoLogistico}
                                      options={this.state.camposLogisticos} isLoading={this.state.camposLogisticos.length == 0 ? true : false}
                                    //isDisabled={ !this.state.isConfig }
                                    />
                                  </Form.Group>
                                </Grid.Col>
                                <Grid.Col sm={2} md={2}>
                                  <span className="badge float-left">
                                    <Button pill size="sm" outline color="primary" onClick={() => this.saveConfiguracionPlantillaConsolidado()} disabled={this.validar()}>
                                      <Icon name="loader" />Guardar
                                    </Button>
                                  </span>
                                </Grid.Col>
                              </Grid.Row>
                              <Table cards={false} striped={false} responsive={true} className="table-vcenter"
                                headerItems={[{ content: "Tipo movimiento" }, { content: "Campo logistico" }, { content: "Acción" }]} style={{ color: "black", "font-size": "80%" }} >
                                <Table.Header></Table.Header>
                                <Table.Body>
                                  {this.state.configuracionesTransportistaConsolidado.map((conf, index) =>
                                    <Table.Row>
                                      <Table.Col> {this.getMovimientoTipoLabel(conf.idMovimientoTipo)} </Table.Col>
                                      <Table.Col> {this.getCampoLogisticoLabel(conf.idCampoLogistico)}</Table.Col>
                                      <Table.Col>
                                        <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={() => this.borrarConfiguracionTransportistaConsolidado(conf.idTransportista, conf.idMovimientoTipo, conf.idCampoLogistico)} > <span className="badge"><Icon link={true} name="trash" /></span> </Button>
                                      </Table.Col>
                                    </Table.Row>)
                                  }
                                </Table.Body>
                              </Table>
                            </div>
                          }
                        />
                      </Form.Group>
                      : null}
                  </Grid.Col>
                </Grid.Row> */}
              </div>}
            />
          </Grid.Col>

        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <div className="float-right">
              {(this.isEnable("actualizar") || this.isEnable("agregar")) ?
                <span className="badge">
                  <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable}
                    onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                    <span className="badge"><Icon link={true} name="save" />Guardar</span>
                  </Button>
                </span>
                : null}
              {this.state.accion === "modificar" && this.isEnable("borrar") ?
                <span className="badge">
                  <Button target="_blank" size="sm" RootComponent="a" color="red" onClick={() => this.delete()} disabled={!this.state.crudbutonEnable}>
                    <span className="badge"><Icon link={true} name="trash" />Borrar</span>
                  </Button>
                </span>
                :
                null
              }
            </div>
          </Grid.Col>
        </Grid.Row>
        {/**
          *   Elementos de plataformas
          */
        }
      </div>
    )
  }
}

export default Transportistas
