// @flow

import React, { Component } from "react";
import { Button,Form,Icon,Grid } from "tabler-react";

class  ReglasForm extends Component {
  constructor(props) {
    super(props);
    this.state = { id: "",
                   nombre: "",
                   accion: this.props.accion, //'nuevo'// modificar, nuevo
                  };
    this.enableSave = this.enableSave.bind(this);
    this.reglasObject=[];
  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.setState({id: this.props.elementos.id});
      this.setState({nombre: this.props.elementos.nombre});
    }
  }

  enableSave =() =>{
    if(this.state.nombre.length > 0 ){
      return false;
    }else{ return true }
  }

  changeName =(event) => {
    this.reglasObject.nombre = event.target.value;
    this.setState({nombre: event.target.value}); 
  }
  
  delete() {
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.props.getfuntion("put", { "id": this.state.id, "nombre": this.state.nombre});
  }
  crud_post() {
    this.props.getfuntion("post",{ "nombre": this.state.nombre});
  }
  render(){ 
    return (
      <div>
        { this.state.accion === "modificar" ? <h1> { this.props.elementos.nombre } </h1> : null }
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="id">
                  <Form.Input name="id" disabled type="number" value={this.state.id} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={9} lg={9}>
                <Form.Group label="Nombre">
                  <Form.Input name="nombre" type="text" value={ this.state.nombre } onChange={this.changeName} maxlength="20" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>       
        <div className="float-right">
          <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" 
                disabled= { this.enableSave() }                 
                onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() }
              >
                <span className="badge"><Icon link={true} name="save" />Guardar</span>
              </Button>
          </span>
          { this.state.accion === "modificar" ? 
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary"                     
                onClick= { () => this.delete() }
              >
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
          :
          null 
          }
        </div>
      </div>
    );
  }
}
export default ReglasForm;
