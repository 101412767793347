import React, { Component } from "react";
import { Button, Form, Icon,Grid, Table, Card } from "tabler-react";
import { Crud_Catalogos } from "../herramientas/Crud_Catalogos";
import TabsLayout  from "../components/TabsLayout.react";
import { NotificationContainer, NotificationManager} from 'react-notifications';
import {password} from '../herramientas/Forms' 

/**
 * Formularios
 */
//import { Usuario, Perfiles, Reporte } from "./Formularios/";

class SeguridadForm extends Component {
  constructor(props) {
    super(props);
              this.state  = {
                             username    : '', 
                             oldPassword    : '',
                             newPassword : '',
                             newPasswordC : ''

              };

              this.passwordObj= {
                "oldPassword"  :  "",
                "newPassword"    :  ""
              } 
              this.mensajePass=[];

                         
  
  }
  selectedTab = (index) =>{
    this.setState({ currentTap:index })
  }


  crud_putPassword() {
    this.getfuntion("put", this.passwordObj, "",  "usuarios/password_expirada?username="+this.state.username, "contrana");
}
       
        
    getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas/plataforma/"+this.state.idPlataforma.value , stateVar = "personas" ) => {
           return Crud_Catalogos(metodo !== "post" && catalogo.includes("personas")? "personas":catalogo , "tracking", metodo, id, obj, "", stateVar  ).then((returnVal) => {
             switch (metodo) {
               case "put":
                 {
                   if (stateVar === "contrana") { 
                    
                    NotificationManager.info(returnVal.message, "Modificación");
                    if(returnVal.status===200){
                      window.location.href="/login";
                    }
                     
                   }
                 }
                 break;
               default:
                 break;
             }
           }).catch(err => { console.log(err) });
      }

  changeUserName = (event) => this.setState({username:event.target.value});

  changeNewPasswordC = (event) => this.setState({newPasswordC:event.target.value});

  changeNewPassword = (event) => {
    this.setState({newPassword:event.target.value});
    this.passwordObj.newPassword=event.target.value;
  }
  changeOldPassword = (event) => {
    this.setState({oldPassword:event.target.value});
    this.passwordObj.oldPassword=event.target.value;
  }


  render() {
    return (
      <div>
        <TabsLayout tabs={ this.tabs } selectedTab= { this.state.currentTap } selectTab= { this.selectedTab } />
        <h2>Su password no ha cambiado desde hace más de 60 días. Actualice su password.</h2>

        <Card>
          <Card.Body>
            <Grid.Row>

            <Grid.Col md={4 } lg={4}>              
             
             <Form.Group label="Nombre de Usuario">             
               <Form.Input name="username" type="text"  value={this.state.username} onChange={this.changeUserName} />
               </Form.Group>
           </Grid.Col>


              <Grid.Col md={4 } lg={4}>              
             
                <Form.Group label="Password Anterior">             
                  <Form.Input name="oldPassword" type="password"  value={this.state.oldPassword}  onChange={this.changeOldPassword} />
                  </Form.Group>
              </Grid.Col>
         
              <Grid.Col md={ 4 } lg={ 4 }>
                <Form.Group label="Nuevo Password">
                  <Form.Input name="newPassword" type="password"  value={this.state.newPassword} onChange={this.changeNewPassword} />
                  <div >{password(this.state.newPassword)}</div>
                 </Form.Group>
              </Grid.Col>          
              <Grid.Col md={4} lg={4}>
                  <Form.Group label="Confirmar Password">
                    <Form.Input name="newPasswordC" type="password"  value={this.state.newPasswordC} onChange={this.changeNewPasswordC} />
                    <div>{this.state.newPasswordC.length?this.state.newPasswordC===this.state.newPassword?"":"Las contraseñas no son iguales":""}</div>
                   </Form.Group>
              </Grid.Col>
              <Table.Col>
                    <Form.Group label="&nbsp;">
                    <Button target="_blank" size="sm" RootComponent="a" color="primary"
                    disabled= { this.habilitarBoton()}
                    onClick={() => this.crud_putPassword() }          >
                    <span className="badge"><Icon link={true} name="refresh-ccw" />{}</span>
                    </Button>     
                    </Form.Group>            
              </Table.Col>
             
             </Grid.Row>
          </Card.Body>
        </Card>   
        
      </div>
    );
  }



habilitarBoton=()=> {
  //return this.passwordObj.newPasswordC !== this.passwordObj.newPassword && (!this.passwordObj.newPassword.length || !this.passwordObj.newPasswordC.length );
  if (!password(this.state.newPassword).length && this.state.newPassword===this.state.newPasswordC){
     return false;
  }
  return true;
}



}
export default SeguridadForm;
