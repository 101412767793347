import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card } from "tabler-react";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../herramientas/Crud_Catalogos";
import { confirmAlert } from "react-confirm-alert";
/**  Date tools  */
import { timeToDateFill } from "../../../herramientas/DateFormat";
import ModalLay from "../../../components/ModalLay/ModalLay";
import OperadoresForm from "../../../Catalogos/Operadores/OperadoresForm";
//import { from } from "rxjs";
var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
const datetime = "yyyy-mm-dd HH:mm:ss";
function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}
function myFormato(date) {
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}

function dateToStringFormat(date1, date2, date3) {
  var stringformat = "";
  var date = "";
  if (date1 != null) {
    date = date2;
  } else {
    date = date3;
  }

  if (date != "" && date instanceof Date) {
    var dd = date.getDate();
    var mm = date.getMonth();
    var mmm = mm + 1;
    var hora = date.getHours();
    var min = date.getMinutes();
    var seg = date.getSeconds();
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mmm < 10) {
      mmm = "0" + mmm;
    }
    stringformat =
      yyyy + "-" + mmm + "-" + dd + " " + hora + ":" + min + ":" + seg;
  }
  return stringformat;
}

class MonitoreoTerrestreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /* Llave Primaria */
      id: 0,
      accion: this.props.accion || "nuevo",
      /* Llaves Foraneas */
      operacionTipo: { value: "id", label: "Selecciona un Tipo Operacion" },
      operacionEstatus: {
        value: "id",
        label: "Selecciona una Operacion Estatus",
      },
      corredor: { value: "id", label: "Selecciona un Corredor" },
      plataforma: { value: "id", label: "Selecciona una Plataforma" },
      transportista: { value: "id", label: "Selecciona un Transportista" },
      operador: { value: "id", label: "Selecciona un Operador" },
      movimientoTipo: { value: 1, label: "Importacion" },
      operacionesTipo: [],
      grupos: [],
      grupo: { value: null, label: "Seleccione una opción" },
      /* Caalogos */
      transportistas: [],
      corredores: [],
      operadores: [],
      /* Campos */
      cartaPorte: "",
      rutaInicio: null,
      origen: "",
      caja: "",
      ruta: "",
      nota: "",
      deRegreso: 0,
      custodia: 0,
      checklist: 0,
      custodiaNombre: "",
      nombreOperador: "",
      comboOperadorEdited: false,
      salvarActivo: true,
      hiddenModal: false,
    };
    this.enableSave = this.enableSave.bind(this);
    this.MovimientosObject = {
      /* Llave Primaria */
      id: 0,
      /* Llaves Foraneas */
      operacionTipo: { id: 0, nombre: "" },
      operacionEstatus: { id: 1, nombre: "SIN GPS" },
      corredor: { id: 0, nombre: "" },
      operador: { id: 1, nombre: "OPERADOR SIN CATALOGO" },
      plataforma: { id: 0, nombre: "" },
      transportista: { id: 0, nombre: "" },
      /* Campos */
      cartaPorte: "",
      custodiaNombre: "",
      nombreOperador: "",
      rutaInicio: null,
      origen: "",
      nota: "",
      deRegreso: 0,
      custodia: 0,
      checklist: 0,
      ruta: "",
      base: "",
      gerente: "",
      grupo: "",
      caja: "",
      estatusRuta: null,
      movimientoTipo: { id: 1, nombre: "Importacion" },
      origenMovimiento: null,
    };
    this.plataforma = [];
    this.operacionTipo = [];
    this.operacionEstatus = [];
  }
  componentDidMount() {
    if (this.state.accion !== "nuevo") {
      //this.getfuntion("get","","","corredores/plataforma/"+this.props.elementos.idPlataforma.id,"corredores");
      this.setState({ id: this.props.elementos.id });
      /* Combos */
      this.setState({
        plataforma: {
          value: this.props.elementos.plataforma.id,
          label: this.props.elementos.plataforma.nombre,
          data: this.props.elementos.plataforma,
        },
      });
      this.setState({
        transportista: {
          value: this.props.elementos.transportista.id,
          label: this.props.elementos.transportista.nombre,
        },
      });
      this.setState({
        operacionTipo: {
          value: this.props.elementos.operacionTipo.id,
          label: this.props.elementos.operacionTipo.nombre,
        },
      });
      this.setState({
        operacionEstatus: {
          value: this.props.elementos.operacionEstatus.id,
          label: this.props.elementos.operacionEstatus.nombre,
        },
      });
      this.setState({
        corredor: {
          value: this.props.elementos.corredor.id,
          label: this.props.elementos.corredor.nombre,
        },
      });
      this.setState({
        operador: {
          value: this.props.elementos.operador.id,
          label: this.props.elementos.operador.nombre,
        },
      });
      this.setState({
        movimientoTipo: {
          value: this.props.elementos.movimientoTipo.id,
          label: this.props.elementos.movimientoTipo.nombre,
        },
      });

      /* Campos */
      this.setState({ cartaPorte: this.props.elementos.cartaPorte });
      this.setState({
        rutaInicio: this.props.elementos.rutaInicio
          ? new Date(this.props.elementos.rutaInicio)
          : null,
      });
      this.setState({ origen: this.props.elementos.origen });
      this.setState({ nota: this.props.elementos.nota });
      this.setState({ deRegreso: this.props.elementos.deRegreso });
      this.setState({ custodia: this.props.elementos.custodia });
      this.setState({ custodiaNombre: this.props.elementos.custodiaNombre });
      this.setState({ nombreOperador: this.props.elementos.nombreOperador });
      this.setState({ checklist: this.props.elementos.checklist });
      this.MovimientosObject = this.props.elementos;
      this.getfuntion(
        "get",
        "",
        "",
        "operacionesTipo/plataforma/" + this.props.elementos.plataforma.id,
        "operacionesTipo"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "transportistas/plataforma/" + this.props.elementos.plataforma.id,
        "transportistas"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "corredores/plataforma/" + this.props.elementos.plataforma.id,
        "corredores"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "operadores/transportista/" + this.props.elementos.transportista.id,
        "operadores"
      );
    } else {
      this.setState({ operacionEstatus: { value: 1, label: "SIN GPS" } });
      this.setState({
        transportista: { value: 0, label: "Elija Transportistas" },
      });
      /* Operaciones Cliente ahora son Corredores */
      this.setState({ corredor: { value: 0, label: "Elija Corredor" } });
      this.setState({
        operador: { value: 1, label: "-1 OPERADOR SIN CATALOGO" },
      });
    }

    /* Plataformas */
    this.plataforma.push({ value: 0, label: "Selecciona una Plataforma" });
    currentUser.map((elemento, index) => {
      if (
        elemento.plataforma.plataformaTipo.nombre.toUpperCase() === "TERRESTRE"
      ) {
        this.plataforma.push({
          value: elemento.plataforma.id,
          label: elemento.plataforma.nombre,
          data: elemento.plataforma,
        });
      }
    });

    /* Operaciones Estatus */
    this.operacionEstatus.push({
      value: 0,
      label: "Selecciona una Operación Estatus ",
    });
    this.props.stor.operacionesEstatus_object.map((operacionEstatus, index) => {
      this.operacionEstatus.push({
        value: operacionEstatus.id,
        label: operacionEstatus.nombre,
        data: operacionEstatus,
      });
    });
  }
  isEnable = (Accion) => {
    let borrar = "corredores_borrar";
    let actualizar = "corredores_editar";
    let agregar = "corredores_agregar";

    let agregarOperador = "operadores_agregar";

    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregarOperador") {
        if (privilegios[i].clave == agregarOperador) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  enableSave = () => {
    if (
      this.state.operacionTipo.value > 0 &&
      this.state.salvarActivo &&
      this.state.corredor.value !== 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  handleSubmit = () => {
    this.setState({ salvarActivo: false });
    if (this.state.accion === "modificar") {
      this.crud_put();
    } else {
      this.crud_post();
    }
    if (this.props.stor.hideModal) {
      this.props.stor.hideModal();
    }
  };

  

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "operadores",
    stateVar = "operadores",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "corredores") {
                let corrTemp = [
                  { value: 0, label: "Elija el Corredor", data: null },
                ];
                returnVal.map((corredor, index) =>
                  corrTemp.push({
                    value: corredor.id,
                    label: corredor.nombre,
                    data: corredor,
                  })
                );
                this.setState({ corredores: corrTemp });
              }
              if (stateVar === "transportistas") {
                let transTemp = [{ value: 0, label: "Elija el Transportista" }];
                returnVal.map((transportista, index) =>
                  transTemp.push({
                    value: transportista.id,
                    label: transportista.nombre,
                    data: transportista,
                  })
                );
                this.setState({ transportistas: transTemp });
              }
              if (stateVar === "operadores") {
                let opeTemp = [{ value: 1, label: "-1 OPERADOR SIN CATALOGO" }];
                returnVal.map((operador, index) =>
                  opeTemp.push({
                    value: operador.id,
                    label: (operador.noEmpleado
                      ? operador.noEmpleado
                      : " ") +
                        operador.nombre +
                        " " +
                        operador.paterno +
                        " " +
                        operador.materno,
                    data: operador,
                  })
                );
                this.setState({ operadores: opeTemp });
              }
              if (stateVar === "operacionesTipo") {
                let oTipoTemp = [];
                returnVal.map((ot, index) =>
                  oTipoTemp.push({ value: ot.id, label: ot.nombre, data: ot })
                );
                this.setState({ operacionesTipo: oTipoTemp });
              }
            }
            break;
          case "post":
            if(stateVar === "operadores"){
              if(returnVal.id){
                let operadoresTemp = [...this.state.operadores];
                  operadoresTemp.push({
                    value: returnVal.id,
                    label: (returnVal.noEmpleado
                      ? returnVal.noEmpleado
                      : " ") +
                        returnVal.nombre +
                        " " +
                        returnVal.paterno +
                        " " +
                        returnVal.materno,
                    data: returnVal,              
                  });
                  this.setState({ operadores: operadoresTemp});
                  this.changeOperador(operadoresTemp[operadoresTemp.length-1]);
                  alert("Operador creado correctamente, ya puedes cerrar el formulario");
              } else {
                alert("No se genero correctamente el Operador");
              }              
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("Error desconocido ....");
        }
      });
  };
  //************ Combos ***********************

  changePlataforma = (event) => {
    let id_plataformaAnt = this.state.plataforma
      ? this.state.plataforma.value
      : "0";
    this.setState({ plataforma: event });
    this.MovimientosObject.plataforma = event.data;

    if (id_plataformaAnt != event.value) {
      this.getfuntion(
        "get",
        "",
        "",
        "transportistas/plataforma/" + event.value,
        "transportistas"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "corredores/plataforma/" + event.value,
        "corredores"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "operacionesTipo/plataforma/" + event.value,
        "operacionesTipo"
      );
    }
  };
  changeMovimientotipo = (event) => {
    this.setState({ movimientoTipo: event });
    this.MovimientosObject.movimientoTipo = event.data;
  };
  changeTransportista = (event) => {
    this.setState({ transportista: event });
    this.MovimientosObject.transportista.id = event.value;
    this.getfuntion(
      "get",
      "",
      "",
      "operadores/transportista/" + event.value,
      "operadores"
    );
  };
  changeOperador = (event) => {
    this.setState({ operador: event });

    if (event.data) {
      this.MovimientosObject.operador = event.data;
    } else {
      this.MovimientosObject.operador = {
        id: 1,
        nombre: "OPERADOR SIN CATALOGO",
      };
    }
  };
  changeOperacionTipo = (event) => {
    this.setState({ operacionTipo: event });
    this.MovimientosObject.operacionTipo = event.data;
    if (event.label === "DHL") {
      this.MovimientosObject.origenMovimiento = "OTM";
    } else {
      this.MovimientosObject.origenMovimiento = null;
    }
  };
  changeOperacionEstatus = (event) => {
    this.setState({ operacionEstatus: event });
    this.MovimientosObject.operacionEstatus = event.data;
  };
  changeCorredor = (event) => {
    this.setState({ corredor: event });
    this.MovimientosObject.corredor = event.data;
  };

  //Campos Normales
  changeCartaPorte = (event) => {
    this.MovimientosObject.cartaPorte = event.target.value;
    this.setState({ cartaPorte: event.target.value });
  };
  changeCustodiaNombre = (event) => {
    this.MovimientosObject.custodiaNombre = event.target.value;
    this.setState({ custodiaNombre: event.target.value });
  };
  changeCustodia = (event) => {
    this.state.custodia === 0
      ? this.setState({ custodia: 1 })
      : this.setState({ custodia: 0 });
    this.state.custodia === 0
      ? (this.MovimientosObject.custodia = 1)
      : (this.MovimientosObject.custodia = 0);
  };
  changeChecklist = (event) => {
    this.state.checklist === 0
      ? this.setState({ checklist: 1 })
      : this.setState({ checklist: 0 });
    this.state.checklist === 0
      ? (this.MovimientosObject.checklist = 1)
      : (this.MovimientosObject.checklist = 0);
  };
  changeDeRegreso = (event) => {
    this.setState({ deRegreso: this.state.deRegreso === 0 ? 1 : 0 });
    this.MovimientosObject.deRegreso = this.state.deRegreso === 0 ? 1 : 0;
  };
  changeNombreOperador = (event) => {
    event.target.value.length > 0
      ? this.setState({ comboOperadorEdited: true })
      : this.setState({ comboOperadorEdited: false });
    this.MovimientosObject.nombreOperador = event.target.value;
    this.setState({ nombreOperador: event.target.value });
  };
  changeOrigen = (event) => {
    this.MovimientosObject.origen = event.target.value;
    this.setState({ origen: event.target.value });
  };
  onChangeRutaInicio = (event) => {
    this.setState({ rutaInicio: event });
    this.MovimientosObject.rutaInicio = event
      ? timeToDateFill(new Date(event).getTime())
      : event;
  };

  changeNota = (event) => {
    this.MovimientosObject.nota = event.target.value;
    this.setState({ nota: event.target.value });
  };

  delete = () =>
    this.props.getfuntion(
      "delete",
      this.MovimientosObject.id,
      this.state.idMovimiento
    );
  crud_put = () => this.props.getfuntion("put", this.MovimientosObject);
  crud_post = () => this.props.getfuntion("post", this.MovimientosObject);
  borrarMovimiento = () => {
    let opEstatus = this.props.stor.operacionesEstatus_object;
    for (let item of opEstatus) {
      if (item.nombre.toUpperCase().includes("ELIMINADO")) {
        this.MovimientosObject.operacionEstatus = item;
      }
    }
    confirmAlert({
      title: "Antención",
      message:
        "¿Esta seguro de eliminar el movimiento(" +
        this.props.elementos.id +
        ")?, El movmiento se borrara de forma definitiva y no podrá recuperarse",
      buttons: [
        {
          label: "Confirmar", //metodo = "get", params = [], id = "", arraytoUpdate = [], catalogoTem = this.catalogo,
          onClick: () => {
            this.props.getfuntion(
              "put",
              this.MovimientosObject,
              "",
              "",
              "terrestreMovimientos"
            );
          },
        },
        {
          label: "Cancelar",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };
  render() {   
    const { selectedOption } = this.state;
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1>Movimiento ID {this.props.elementos.id}</h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="Id">
                  <Form.Input
                    name="id"
                    disabled
                    type="number"
                    value={this.state.id}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col md={4}>
                <Form.Group label="Plataforma">
                  <Select
                    value={this.state.plataforma}
                    onChange={this.changePlataforma}
                    options={this.plataforma}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4}>
                <Form.Group label="Transportistas">
                  <Select
                    value={this.state.transportista}
                    onChange={this.changeTransportista}
                    options={this.state.transportistas}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4}>
                <Form.Group label="Operador">
                <Grid.Row>
                  <Grid.Col>
                  <Select
                    value={this.state.operador}
                    onChange={this.changeOperador}
                    options={this.state.operadores}
                    isDisabled={this.state.comboOperadorEdited}
                  />
                  </Grid.Col>
                  { this.isEnable("agregarOperador") ? (
                    <Grid.Col md={2} lg={2}>
                    {this.isEnable("agregarOperador") && 
                    this.state.plataforma.value && this.state.transportista.value ? (
                      <span className="float-right">
                        <ModalLay
                          tamanio="85%"
                          title={"Nuevo"}
                          name={
                            <span
                              className="fa fa-plus ml-2"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                "Nuevo Operador(Antes de crear valida que no exista)"
                              }
                            ></span>
                          }
                          formulario={OperadoresForm}
                          datosForm={{
                            accion: "nuevo",
                            elementos: this.state.operador.data,
                            getfuntion: this.getfuntion,
                            stor: this.props.stor,
                            idPlataforma: this.state.plataforma.value,
                            idTransportista: this.state.transportista.value
                            //setOperador: this.setOperador
                          }}
                        />
                      </span>
                    ) : null}                 
                  </Grid.Col>
                  ) : null           
                  }
                </Grid.Row>                    
                </Form.Group>
                {/*this.state.operador.value === 1 ? (
                  <Form.Input
                    name="OperadorNombre"
                    value={this.state.nombreOperador}
                    type="text"
                    onChange={this.changeNombreOperador}
                    maxlength="20"
                  />
                ) : (
                  ""
                )*/}
              </Grid.Col>

              <Grid.Col md={4}>
                <Form.Group label="Operación Cliente (Corredores)">
                  <Select
                    value={this.state.corredor}
                    onChange={this.changeCorredor}
                    options={this.state.corredores}
                    isDisabled={
                      !this.isEnable("actualizar") &&
                      this.state.accion !== "nuevo" &&
                      this.props.elementos.corredor
                        ? this.props.elementos.corredor.id !== 11
                          ? true
                          : false
                        : false
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4}>
                <Form.Group label="Operación Estatus">
                  <Select
                    value={this.state.operacionEstatus}
                    onChange={this.changeOperacionEstatus}
                    options={this.operacionEstatus}
                    isDisabled={true}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={3} lg={4}>
                <Form.Group label="Operacion Tipo">
                  <Select
                    value={this.state.operacionTipo}
                    onChange={this.changeOperacionTipo}
                    options={this.state.operacionesTipo}
                  />
                </Form.Group>
              </Grid.Col>
              <Card title="Datos">
                <Grid.Col md={12} xl={12}>
                  <Grid.Row>
                    <Grid.Col md={4}>
                      <Form.Group label="Control vehicular">
                        <Form.Input
                          name="cartaPorte"
                          value={this.state.cartaPorte}
                          type="text"
                          onChange={this.changeCartaPorte}
                          maxlength="20"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group label="Origen">
                        {" "}
                        <Form.Input
                          name="origen"
                          type="text"
                          value={this.state.origen}
                          onChange={this.changeOrigen}
                          maxlength="20"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group label="Ruta Inicio">
                        {" "}
                        <DateTimePicker
                          format="y-MM-dd h:mm:ss a"
                          value={this.state.rutaInicio}
                          onChange={this.onChangeRutaInicio}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={12}>
                      <Form.Group label="Nota">
                        {" "}
                        <Form.Input
                          name="nota"
                          type="text"
                          value={this.state.nota}
                          onChange={this.changeNota}
                          maxlength="30"
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Card>

              {this.state.accion !== "nuevo" ? (
                <Card title="Checks" visible="false">
                  <Grid.Row md={12} xl={12}>
                    <Grid.Col md={4} xl={4}>
                      <Form.Group label="De Regreso">
                        <Form.Switch
                          type="Checkbox"
                          name="toggle"
                          value={this.state.deRegreso}
                          checked={this.state.deRegreso}
                          onChange={this.changeDeRegreso}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4} xl={4}>
                      <Form.Group label="Custodia">
                        <Form.Switch
                          type="Checkbox"
                          name="toggle"
                          value={this.state.custodia}
                          checked={this.state.custodia}
                          onChange={this.changeCustodia}
                        />
                        {this.state.custodia > 0 ? (
                          <Form.Input
                            name="CustodiaNombre"
                            value={this.state.custodiaNombre}
                            type="text"
                            onChange={this.changeCustodiaNombre}
                            maxlength="20"
                          />
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4} xl={4}>
                      <Form.Group label="CheckList">
                        <Form.Switch
                          type="Checkbox"
                          name="toggle"
                          value={this.state.checklist}
                          checked={this.state.checklist}
                          onChange={this.changeChecklist}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                </Card>
              ) : null}
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          <span className="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={this.enableSave()}
              onClick={() => this.handleSubmit()}
            >
              <span className="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
          {this.state.accion === "modificar" && user.id !== 77 ? (
            <span className="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="danger"
                onClick={() => this.borrarMovimiento()}
              >
                {" "}
                <span className="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>{" "}
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default MonitoreoTerrestreForm;
