import React, { useState, useEffect } from "react";
import { Table} from "tabler-react";

export const ContentReportesGenerales = ({ data }) => {
	const [headers, setHeaders] = useState([]);
	const [content, setContent] = useState([]);

	useEffect(() => {
		if (data !== undefined) {
			let bool = true;
			let headers = [];
			let content = [];
			data.content.forEach((row, index) => {
				let columnas = [];
				Object.entries(JSON.parse(row)).forEach(([key, columna]) => {
          if (bool) {
            headers.push(<th>{key}</th>);
          }
          columnas.push(<Table.Col>{columna}</Table.Col>);
				});
				bool = false;
				content.push(<Table.Row>{columnas}</Table.Row>);
			});
			setHeaders(headers);
			setContent(content);
		}
	}, [data]);

	return (
    <>
      <div className="mb-6">
        {content.length > 0 ? (
          <Table
            cards={true}
            striped={true}
            responsive={true}
            className="table-center col-12 table-bordered"
            style={{ color: "black", fontSize: "80%" }}
          >
            <Table.Header className="thead-dark">
              <tr>{headers}</tr>
            </Table.Header>
            <Table.Body>{content}</Table.Body>
          </Table>
        ) : (
          <div className="alert alert-dark mt-6" role="alert">
            No hay resultados que mostrar...
          </div>
        )}
      </div>
      <br/>
    </>
	);
};