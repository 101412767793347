import React, { Component } from "react";
import { Grid, Form, Button, Table } from "tabler-react";
/**
 * SplitterLayout
 */
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import SiteWrapper from "../../SiteWrapper.react";
import EspecificoT from "../../components/EspecificoTerrestre";
import {MonitoreoTerrestreForm} from "../../Monitoristas";
import Panel from "../../Monitoristas/Monitoreo/Panel";
import './MonitoreoPage.react.css';
import * as ACTIONS from '../../store/actions/actions'
import { connect } from 'react-redux';
import PanelMensajeria from '../../socket/PanelMensajeria.react';
import {GpsEstatusTerrestre} from '../../Monitoristas/notificaciones/GpsEstatusTerrestre';
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import {AlertasN}   from '../../Monitoristas/notificaciones/AlertasN';
import {AlertasT}   from '../../Monitoristas/notificaciones/AlertasT';
//import {AlertasTipo}from '../../Monitoristas/notificaciones/AlertasTipo';
import { EtasN }    from '../../Monitoristas/notificaciones/EtasN';
import { timeToDatefull } from "../../herramientas/DateFormat";
import { timeToDateFill } from "../../herramientas/DateFormat";
import Alert from "../../components/Alert/Alert";
import * as conf from "../../config/config";
/**
 * Notificaciones
 */
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadStore from "../../store/LoadStore";
import { from } from "rxjs";
import ModalLayout from "../../components/ModalLayout.react";
import { AlertaTForm, AlertasListar } from "../../Monitoristas/Monitoreo/panelForms/index.js"; //ActualizarHorariosTerrestres
var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser     = JSON.parse(sessionStorage.getItem("currentUser"));
var modulosMonitoreo= JSON.parse(localStorage.getItem('modulosMonitoreo'));
const url = new URL(window.location.href);
const urlback = conf.api_raiz;

var colsN=[];
var colsT=[];
var corredores= [];
var operacionesTipo= [];
var incidencias= [];
var rutasEstatus = [];
let tiempos = [];
class Monitoreo extends Component {
  constructor(props) {
    super(props);
    this.state = {
                   PlataformasPerfiles  : {},
              currentMovimientoNaviero  : {},
            currentMovimientoTerrestre  : {},
                           estatusRuta  : {},
                           headersDash  : [],
                          headersDashT  : [],
                              alertasN  : {},
                           alertasNCat  : {},
                              alertasT  : {},
                           alertasTCat  : {},
                          alertasTCorr  : {},
                           alertasTipo  : {},
                                 etasN  : {},
                             //alertas  : {},
                             columnasN  : [],
                             columnasT  : [],
                   usuariosCorredoresN  : [],
                   usuariosCorredoresT  : [],
                        plataformaTipo  : null,
                           hiddenModal  : false,
                    movimentosNavieros  : [],
                  movimentosTerrestres  : [],
                    gpsEstatusNavieros  : null,
                  gpsEstatusTerrestres  : null,
                  gpsTerrestreReciente  : null,
                   gpsNavieroReciente   : null,
                                topics  : [],
                                topicsT : [],
                                metodo  : "get",
                       MovimientosFull  : {},
                              movPatio  : {},
                              entregas  : "",
                           incidencias  : "",
                     alertasCategorias  : [],
                        tiemposAlertas  : [],
                        temporizadores  : [],
                    alertasCategoriasN  : [],
                    alertasCategoriasT  : [],
                            idToFilter  : 0,
                    alertasCorredoresT  : [],
                       alertasTShowCat  : true,
                               ruta     : "",
                               fecha1: timeToDatefull( new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*7)).getTime() ),
                                fecha2: timeToDatefull(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() ),
    };
    this.funcionalidades=[];
    this.mensaje= { "mensaje": "", "plataforma": "", "corredor": "", "vista": "", "data": null, "accion": "get" };
    this.loadedN= false;
    this.loadedT= false;
    this.movimentosNavieros= {};
  }
  hiddenmodal = () => {
    this.setState({ hiddenModal: !this.state.hiddenModal });
  }
  getPerfiles=()=>{
    //Arma los perfiles del Usuario por cada Plataforma el Perfil
    let perf= {};
    for (let item of currentUser) {
      if(item){
        if(item.perfil && item.plataforma){
          if( perf[item.perfil.id] ){
            if( !perf[item.perfil.id][item.plataforma.plataformaTipo.id] ){
              perf[item.perfil.id][item.plataforma.plataformaTipo.id]= [];
            }
            //perf[item.perfil.id][item.plataforma.plataformaTipo.id]= {perfil: item.perfil, plataforma: item.plataforma, funcionalidades: [] };
          }else{
            perf[item.perfil.id]= {};
            perf[item.perfil.id][item.plataforma.plataformaTipo.id]= [];
          }
          perf[item.perfil.id][item.plataforma.plataformaTipo.id]= {perfil: item.perfil, plataforma: item.plataforma, funcionalidades: [] };
        }
      }
    }
    Object.keys( perf ).forEach((key, indice) => {
      Object.keys( perf[key] ).forEach((key2, indice2) => {
        if(perf[key][key2].plataforma.plataformaTipo){
          if( perf[key][key2].plataforma.plataformaTipo.nombre.toUpperCase() === 'TERRESTRE' ){
            this.getfuntion("get", "", "", "funcionalidades/Monitoreo_Terrestre/"+key, "Monitoreo_Terrestre").then((returnVal) => {
              perf[key][key2].funcionalidades= returnVal;
              let PlataformasPerfiles={};
              for (let item of currentUser) {
                if(item){
                  if(item.perfil && item.plataforma){
                    PlataformasPerfiles[item.plataforma.id]= {perfil: item.perfil, plataforma: item.plataforma, funcionalidades: perf[item.perfil.id][item.plataforma.plataformaTipo.id].funcionalidades };
                  }
                }
              }
              this.setState({ PlataformasPerfiles: PlataformasPerfiles });
              //this.setState({ Monitoreo_Terrestre: perf[currentUser[0].perfil.id][ currentUser[0].plataforma.plataformaTipo.id ].funcionalidades });
            }).catch(err => { console.log(err); });
          }
        }
      });
    });
  }
  componentWillMount=() =>{/** Aqui inicia, sin configuracion no carga nada */
    this.getfuntion("get", "", "", "plataformasAlertas/usuario/"+user.id, "plataformasAlertas", "", "");
    this.getPerfiles();    
  }

   componentDidMount =() => {
    //Función que trae los temporizadores de la BD
    const fetchData = async () => {
      this.temporizadores = [];
      await fetch(
        urlback + "temporizadores/" + currentUser[0].usuario.id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + "Bearer " + sessionStorage.getItem("tok"),
          },
        }
      ).then((response) => response.json()).then(data => {
        tiempos = data;
        if(tiempos && tiempos.length > 0){
            this.temporizadores.push(...tiempos);
        }
      }).catch((err)=>{
        console.log(err);
      });
    }
      fetchData();
    clearInterval(cicloFetch);
    const cicloFetch = setInterval(() => {
      fetchData();
    },60000);
    clearInterval(interval);
    const interval = setInterval(() => {
      this.revisionTemporizador();
    }, 1000);
  }
  addToArray = async (element) => {
    const response = await fetch(urlback + "temporizadores", {
      method: "POST",
      body: JSON.stringify({
        ...element,
        creacion: timeToDateFill(element.creacion),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("tok"),
      },
    });
    if (!response.ok) {
      throw new Error("Algo salió mal!");
    }
    let data = await response.json();
    this.temporizadores.push({
      ...element,
      creacion: element.creacion.getTime(),
      idTemporizadores: data.idTemporizadores,
    });
  };
  /**
   * Función para notificar si el temporizador ha terminado.
   */
  revisionTemporizador = () => {
    this.temporizadores = this.temporizadores.filter((element, index) => {
      if (
        (new Date().getTime() - new Date(element.creacion).getTime()) / 60000 <
        element.minutos
      ) {
        return element;
      } else {
        this.setState((prevstate) => ({
          tiemposAlertas: [...prevstate.tiemposAlertas, element],
        }));
        tiempos.splice(index, 1);
        //DELETE FROM DATABASE
        fetch(urlback + "temporizadores/" + element.idTemporizadores, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + "Bearer " + sessionStorage.getItem("tok"),
          },
        });
      }
    });
  };
  /**Elimina la alerta en el visualizador de alertas del Temporizador */
  removeItem = (idTemporizador) => {
    this.setState({
      tiemposAlertas: this.state.tiemposAlertas.filter(
        (item) => item.idTemporizadores !== idTemporizador
      ),
    });
  };
  changeColToFilter = (idMovimiento) => {
    this.setState({ idToFilter: idMovimiento });
  };
  componentDidUpdate=()=>{
// 'cartaPorte' a 'Control Vehicular'
    let aTags = document.getElementsByTagName("div");
    let searchText = "cartaPorte";
    let found;
    for (var i = 0; i < aTags.length; i++) {
      if (aTags[i].textContent == searchText) {
         found = aTags[i];
        aTags[i].textContent='Control Vehicular';
        break;
      }
    }



    if(this.props.funcionalidades_object !== this.funcionalidades && this.props.funcionalidades_object.length>0){
      this.funcionalidades= this.props.funcionalidades_object;
      this.getfuntion("get","", "", "perfilesFuncionalidades/"+currentUser[0].perfil.id);
      this.getfuntion("get", "", "", "incidencias", "incidencias", "", "");
    }
    if(this.state.movimentosNavieros.length>0 && !this.loadedN){
      this.loadedN= true;
      this.alertasN(this.state.movimentosNavieros);
      this.gpsNavieros();
      EtasN(this.state.movimentosNavieros, this.props.mtipo).then((returnVal) => {
        this.setState({etasN: returnVal});
      }).catch(err => { console.log(err); });
      var modulosMonitoreoN= JSON.parse(localStorage.getItem('modulosMonitoreo'));
      //if(modulosMonitoreoN.estatusN){
      //  this.getfuntion("get","", "", "usuariosCorredores/usuario/"+currentUser[0].usuario.id, "usuariosCorredores");
      //}
    }
    if(this.state.movimentosTerrestres.length>0 && !this.loadedT){
      this.loadedT= true;
      if(url.searchParams.get("q") === "activos" && this.estaPrivilegioActivo("terrestre_movimientos_listar") && this.getFuncionalidad("Alertas(Resumen)") ){
        this.alertasT(this.state.movimentosTerrestres);
        this.gpsTerrestres();
      }
      //EtasN(this.state.movimentosTerrestres, this.props.mtipo).then((returnVal) => {
      //  this.setState({etasN: returnVal});
      //}).catch(err => { console.log(err); });
      //var modulosMonitoreoT= JSON.parse(localStorage.getItem('modulosMonitoreo'));
      //if(modulosMonitoreoT.estatusT){
      //  this.getfuntion("get","", "", "usuarioOperacionCliente/usuario/"+currentUser[0].usuario.id, "usuarioOperacionCliente");
      //}
      this.getfuntion("get","", "", "usuarioOperacionCliente/usuario/"+currentUser[0].usuario.id, "usuarioOperacionCliente");
    }
  }
  /**
   * Actualizar gps
   */
  
  gpsTerrestres=() =>{
    GpsEstatusTerrestre( this.getMovimientosTerrestres(), this.setGpsEstatusTerrestres );
    setTimeout(this.gpsTerrestres,  1000*60*4);//1000*60*1
  }
  /*** Actualiza gpsEstatusNavieros       */
  setGpsEstatusNavieros = (gpsestatus) => {
    if(gpsestatus && this.state.gpsEstatusNavieros !== gpsestatus){
      let gpsEstatusTem= this.state.gpsEstatusNavieros? this.state.gpsEstatusNavieros: {};
      let gpsRecienteTem= this.state.gpsNavieroReciente? this.state.gpsNavieroReciente: {};
      Object.keys( gpsestatus.gpsReciente ).forEach((idMov, i) => {
        if( gpsRecienteTem[idMov] ){
          if( gpsestatus.gpsReciente[idMov] !== gpsRecienteTem[idMov]  ){ gpsRecienteTem[idMov]= gpsestatus.gpsReciente[idMov]; }
        }else{
          gpsRecienteTem[idMov]=[];
          gpsRecienteTem[idMov]= gpsestatus.gpsReciente[idMov];
        }

      });
      Object.keys( gpsestatus.gpsEstatus ).forEach((idGps, i) => {
        if( gpsEstatusTem[idGps] ){
          if( gpsestatus.gpsEstatus[idGps] !== gpsEstatusTem[idGps]  ){ gpsEstatusTem[idGps]= gpsestatus.gpsEstatus[idGps]; }
        }else{
          gpsEstatusTem[idGps]=[];
          gpsEstatusTem[idGps]= gpsestatus.gpsEstatus[idGps];
        }

      });
      this.setState({ gpsEstatusNavieros: gpsEstatusTem });
      this.setState({ gpsNavieroReciente: gpsRecienteTem });
    }
  }
  getGpsEstatusNavieros = () => { return this.state.gpsEstatusNavieros  }
  setGpsEstatusTerrestres = (gpsestatus) => {
    if(gpsestatus.gpsEstatus){
      this.setState({ gpsEstatusTerrestres: gpsestatus.gpsEstatus });
      this.setState({ gpsTerrestreReciente: gpsestatus.gpsReciente });
    }
  }
  /**
   * Fin Gps
   */

  /**
   * Movimientos Navieros
   */
  getMovimientosNavieros   = ()            => {  return this.state.movimentosNavieros; }
  setMovimientosNavieros   = (movimientos) => {

        let movs= {};
        let estatusRuta= {};
        let headersDash= [{ content: "Activos"}, { content: "ontime"}, { content: "delay's"}];
        let movFull=this.state.MovimientosFull;
        for(let item of movimientos){
          if(this.state.currentMovimientoNaviero.id){
            if(item.id === this.state.currentMovimientoNaviero.id && item !== this.state.currentMovimientoNaviero ){
              this.setState({currentMovimientoNaviero: item});
            }
          }
          movs[item.id]= item;
          if(item.idRuta){
            estatusRuta[item.idRuta.nombre]= item.idRuta.nombre;
            let had= false;
            for(let h of headersDash){
              if( h.content === item.idRuta.nombre){ had= true; }
            }
            if(!had){ headersDash.push({content: item.idRuta.nombre }); }
          }
        }
        this.setState({estatusRuta: estatusRuta});
        this.setState({ headersDash: headersDash});

        this.getfuntion("get", "", "", "movimientosPatio/usuario/" + currentUser[0].usuario.id, "movPatio" ).then((returnVal) => {
          let mpatio= {};
          if(returnVal){
            returnVal.map((movPatio, index)=>{
              movFull[movPatio.idMovimiento]= { movimiento: movs[movPatio.idMovimiento], full: movPatio.esFull};
              mpatio[movPatio.idMovimiento]= movPatio;
            });
            if(url.searchParams.get("q") === "activos" && this.estaPrivilegioActivo("terrestre_movimientos_listar") && this.getFuncionalidad("Alertas(Resumen)") ){
              var topics = [];
              topics.push("/topic/users/" + currentUser[0].usuario.username);
              topics.push("/topic/users");
              for (let item of movimientos) {
                if(item.movimientoGps){
                  if( !item.movimientoGps[0] ){
                    if( movFull[item.id] ){
                      if( movFull[item.id].full ){
                        if( movs[movFull[item.id].full] ){
                          let mfull= movs[movFull[item.id].full];
                          if( mfull.movimientoGps[0] ){
                            //item.movimientoGps= mfull.movimientoGps;
                            for(let temmovGps of item.movimientoGps){
                              temmovGps.idMovimiento= item.id;
                            }
                            //item.movimientoGps.idMovimiento= item.id;
                            movs[item.id].movimientoGps= item.movimientoGps;//mfull.movimientoGps; //ACtualiza Movs Keys
                            movFull[item.id].movimiento= item;
                          }
                        }
                      }
                    }
                  }
                }
                topics.push("/topic/" + item.id);
              }
            }
            this.movimentosNavieros= movs;
            this.setState({ MovimientosFull: movFull });
            this.setState({ movimentosNavieros: movimientos });
            this.setState({ topics: topics });
            this.setState({ movPatio: mpatio });
          }
        });
      }
  getMovimientoNavieroById = (id) => {
        return this.movimentosNavieros[id];
  }
  getMovimientoTerrestreById = (id) => {
      return this.movimentosTerrestres[id];
  }

  getMovimientosTerrestres = ()            => {  return this.state.movimentosTerrestres }
  setMovimientosTerrestres = (movimientos) => {
        if(movimientos !== this.state.movimentosTerrestres)
        {
          //corredores Activos
          let exiting= false;
          for(let item of movimientos){
            if(item.corredor){
              for(let c of corredores){
                if( c.corredor === item.corredor.nombre){ exiting= true; }
              }
              if(!exiting){ corredores.push({corredor: item.corredor.nombre }); }
              exiting= false;
            }
            //operacionesTipo
            if(item.operacionTipo){
              for(let o of operacionesTipo){
                if( o.operacionTipo === item.operacionTipo.nombre){ exiting= true; }
              }
              if(!exiting){ operacionesTipo.push({operacionTipo: item.operacionTipo.nombre }); }
              exiting= false;
            }
            //rutaEstatus
            if(item.ruta){
              for(let o of rutasEstatus){
                if( o.rutasEstatus === item.ruta){ exiting= true; }
              }
              if(!exiting){ rutasEstatus.push({rutasEstatus: item.ruta }); }
              exiting= false;
            }
            if(this.getFuncionalidad("Panel incidencias")){//incidencias
              if(item.nombreIncidencia){
                for(let i of incidencias){
                  if( i.incidencia === item.nombreIncidencia){ exiting= true; }
                }
                if(!exiting){ incidencias.push({incidencia: item.nombreIncidencia }); }
                exiting= false;
              }
            }
          }
          
          this.state.headersDashT= [ { content: "Activos"},{ content: "Logistico"},{ content: "Pro Salud"},{ content: "Local"},{ content: "Transito"},{ content: "PFIZER"},{ content: "Dedicado"},{ content: "Custodia"},{ content: "BrennTag"},{ content: "Externo B&S"},{ content: "Externo Elite"},{ content: "Fletes Sunlog"},{ content: "Logistico 1"},{ content: "Logistico 2"},{ content: "PENDIENTE TERRESTRE"} ];
          this.setState({ movimentosTerrestres:movimientos });
          var topicsT = [];
          for (let item of movimientos) { topicsT.push("/topic/" + item.id); }
          this.setState({ topicsT: topicsT });
        }
      }
      /**Fin  uPDATEmOVIMIENTO */
      /**  Current Movimientos */
  getMovimientoTerrestre = () => {  return this.state.currentMovimientoTerrestre; }
  setMovimientoTerrestre = (movimiento) => {
    this.setState({ plataformaTipo: "Terrestre" });
    this.setState({ currentMovimientoTerrestre: movimiento });
    this.mensaje.data= null;
    this.setState({ metodo: "get" });
  }
    /**  Fin Current Movimientos */

  /**
   * Fin Movimientos
   */

  /**
   * Alertas
   */
  setAlerta = (alerta, vista) => {
    this.mensaje.accion= "put";
    this.mensaje.vista= vista;
    this.mensaje.data= alerta;
    this.mensaje.mensaje = "Se atiende alerta("+ alerta["triggerName"] +")";
    this.props.updateStatusMensaje(true);
    this.setState({ metodo: "put" });
  }
  updateAlertasN = (data, ByCategorias) =>{
    this.setState({ alertasN: data });
    this.setState({ alertasNCat: ByCategorias });
  }
  updateAlertasT = (data, ByCategorias, ByCorredores) =>{
    this.setState({ alertasT: data });
    this.setState({ alertasTCat: ByCategorias });
    //this.setState({ alertasTCorr: ByCorredores });
  }

  alertasN=(movimientos) =>{
    AlertasN(movimientos, this.props.mtipo).then((returnVal) => {
      if(this.state.alertasN !== returnVal){
        this.setState({ alertasN: returnVal.alertas });
        let catTemp= [];
        Object.keys(returnVal.alertasCat).forEach((key, indice) => {
          catTemp.push({ categoria: key });
        });
        this.setState({ alertasCategoriasN: catTemp});
        this.setState({ alertasNCat: returnVal.alertasCat });
      }
    }).catch(err =>{
      setTimeout( function() { this.alertasN(this.state.movimentosNavieros); }.bind(this), 1000*60 );
      console.log(err);
    });
  }
  alertasT=(movimientos) =>{
    AlertasT(movimientos).then((returnVal) => {
      if(this.state.alertasT !== returnVal){
        this.setState({ alertasT: returnVal.alertas });
        //Categorias        
        let catTemp= [];
        Object.keys(returnVal.alertasCat).forEach((key, indice) => {
          catTemp.push({ categoria: key });
        });
        this.setState({ alertasCategoriasT: catTemp});
        this.setState({ alertasTCat: returnVal.alertasCat });
        //Corredores        
        let corrTemp= [];
        Object.keys(returnVal.alertasCorredores).forEach((key, indice) => {
          corrTemp.push({ corredor: key });
        });
        this.setState({ alertasTCorr: returnVal.alertasCorredores });
        this.setState({ alertasCorredoresT: corrTemp});
      }
    }).catch(err =>{
      setTimeout( function() { this.alertasT(this.state.movimentosTerrestres); }.bind(this), 1000*60 );
      console.log(err);
    });
    //setTimeout(this.alertasT, 1000*60*4 );
  }



  /**
   * Fin Alertas
   */

  getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /*** filtro de variables  */
        //console.log("699 Monitoreo.react catalogo: " + catalogo + ", metodo:"+metodo+", stateVar:"+stateVar+ ", obj:"+obj);
        switch (metodo) {
          case "get":
            {
              if (stateVar === "incidencias"){
                if (returnVal) {
                  this.setState({ incidencias:returnVal });
                }
              }
              if (stateVar === "plataformasAlertas"){
                if (returnVal) {
                   let cat= {};
                  returnVal.map((c, index) =>{
                    if( c.alertaCategoria ){
                      if( cat[c.plataforma] ){
                        if( cat[c.plataforma][c.alertaTipo.id] ){
                          cat[c.plataforma][c.alertaTipo.id]= c;
                        }else{ cat[c.plataforma][c.alertaTipo.id]= c; }
                      }else{
                        cat[c.plataforma]={};
                        cat[c.plataforma][c.alertaTipo.id]= c;
                      }
                    }
                  });
                  this.setState({ alertasCategorias: cat});
                  //console.log(currentUser, cat);
                  let categoriasTemp= [];//  se toma en cuentala plataforma 1
                  Object.keys(cat).forEach((key, indice) => {
                    if( parseInt(key,10) === 61 ){//if( parseInt(key,10) === currentUser[0].plataforma.id ){
                      let catTemp= [];
                      Object.keys(cat[key]).forEach((key2, indice1) => {
                        catTemp.push(cat[key][key2]);
                      });
                      //this.setState({ alertasCategorias: catTemp});
                    }
                  });
                }
              }

              if (stateVar === "entregas")         {
                if (returnVal) {
                  this.setState({ entregas: returnVal });
                }
              }
              if (stateVar === "columnas"){
                colsT= [];
                colsN= [];
                if(returnVal[0].id){
                  for(let item of returnVal.sort(function(a, b){return a.id-b.id}) ){//var nom= this.getFuncionalidadById(item.idFuncionalidad);
                    this.getFuncionalidadById(item.idFuncionalidad);
                  }//this.setState({ columnas:cols });
                  this.setState({ columnasN:colsN});
                  this.setState({ columnasT:colsT});
                }
              }
              if (stateVar === "usuariosCorredores"){ if(returnVal[0].idUsuario){ this.setState({ usuariosCorredoresN:returnVal }); } }
              if (stateVar === "usuarioOperacionCliente"){ if(returnVal[0].idUsuario){ this.setState({ usuariosCorredoresT: returnVal }); } }
              if (stateVar === "movPatio"){ return returnVal; }
              if (stateVar === "Monitoreo_Terrestre"){ return returnVal; }
            }
            break;
          case "put":
            {
              if (catalogo==="terrestresAlertas"){
                this.setAlerta(returnVal, "terrestresAlertas");
              }
            }
            break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ...."+err);
          //console.log(err)
        }
    })
  }

  updateEtas = (data) => this.setState({ etasN: data });

  getFuncionalidadById=(id) =>{
    for(let item of this.props.funcionalidades_object){
      if(item.bloque.nombre=="terrestreMovimientos"){
        if(item.id == id){
          colsT.push({content:item.nombre, columna:item.nombre, nombre:item.nombre });
        }
      }
    }
  }

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = ( clave)=> {
    var privilegios= this.props.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(privilegios[i].clave === clave){
        if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){
          return true
        }else{
          return false
        }
        break;
      }
    }
  }
  getFuncionalidad=(elemento)=>{
    var BreakException = {};
    var resultado= false;

    try {
      Object.keys( this.state.PlataformasPerfiles ).forEach((key, indice) => {
        if(this.state.PlataformasPerfiles[key].funcionalidades){
          for(let item of this.state.PlataformasPerfiles[key].funcionalidades){
            if(item.nombre == elemento ){
              resultado= true;
            throw BreakException;
            }
          }
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
      return resultado;
    }
    return false;
  }
  onChangeFecha1=(e)=>{
    this.setState({fecha1: e.target.value });
  }
  onChangeFecha2=(e)=>{
    this.setState({fecha2: e.target.value });
  }
  render() {
    return (
      <>
        {this.state.tiemposAlertas.length > 0 && (
          <ul
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 5,
            }}
          >
            {this.state.tiemposAlertas.map((tiempo, index) => (
              <Alert
                key={index}
                tiempo={tiempo}
                setTiempos={this.removeItem}
                changeColToFilter={this.changeColToFilter}
                movimiento= {this.state.currentMovimientoTerrestre}
                tipo={this.state.plataformaTipo}
                stor={this.props.privilegios_object}
                getfuntion={this.getfuntion}
                gpsReciente={this.state.gpsTerrestreReciente}
                closeModal={this.hiddenmodal}
              />
            ))}
          </ul>
        )}
        {<LoadStore catalogos={["privilegios"]}/>}
        <SiteWrapper>
          <Grid.Col sm={12} lg={12}>
          { url.searchParams.get("q") === "activos" && this.estaPrivilegioActivo("terrestre_movimientos_listar") ?
              <audio id="alerta12" src="songs/alert.mp3" ></audio>
            :<audio id="alerta12"></audio>}
          { url.searchParams.get("q") === "activos" && this.estaPrivilegioActivo("terrestre_movimientos_listar") ?  
            <PanelMensajeria  topic         = {this.state.topics.concat(this.state.topicsT)}
                              mensaje       = {this.mensaje}
                              elementosN    = {this.state.alertasN}
                              alertasNCat   = {this.state.alertasNCat}
                              alertasTCorr  = {this.state.alertasTCorr}
                              alertasTCat   = {this.state.alertasTCat}
                              elementosT    = {this.state.alertasT}
                              etasN         = { this.state.etasN }
                              update        = {this.updateAlertasN}
                              updateT       = {this.updateAlertasT}
                              updateEtas    = {this.updateEtas}
                              metodo        = {this.state.metodo}
                              stor          = {this.props}
                              movimientos   = { this.state.movimentosNavieros }
                              movimientosT  = { this.state.movimentosTerrestres }
                              categorias    = { this.state.alertasCategorias}
                      />
          :null}
            <NotificationContainer/>
            <div className="row">
              <div className="col-12 " style={{"background-color": "rgba(43, 41, 33, 0.08)"}}>
                <div>
                    <Grid.Row>
                      {/**
                       * inicio paneles terrteres
                       * */}
                       {/** Operaciones Tipo */}
                        <Grid.Col sm={12} lg={12}>
                          <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-dark text-light">
                            <Table.Header>
                            </Table.Header>
                            <Table.Body>
                              <Table.Row>
                                <Table.Col><strong>OperacionTipo</strong></Table.Col>
                                <Table.Col>Activos: { this.props.movimientos_terrestres_object.length }</Table.Col>
                                { operacionesTipo.sort(function(a,b) {
                                      if (a["operacionTipo"] > b["operacionTipo"]) {  return 1; }
                                      if (a["operacionTipo"] < b["operacionTipo"]) {  return -1;}
                                      return 0;
                                  }).map((o, index) =>  this.props.movimientos_terrestres_object.filter(mov => mov.operacionTipo.nombre===o.operacionTipo).length>0?
                                                          <Table.Col>{o.operacionTipo} ({ this.props.movimientos_terrestres_object.filter(mov => mov.operacionTipo.nombre===o.operacionTipo).length })</Table.Col>: null)}
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Grid.Col>
                       {/** Corredores */}
                        <Grid.Col sm={12} lg={12}>
                          <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-secondary text-light mt-1">
                            <Table.Header>
                            </Table.Header>
                            <Table.Body>
                              <Table.Row>
                                <Table.Col><strong>Corredores</strong></Table.Col>
                                  { corredores.sort(function(a,b) {
                                      if (a["corredor"] > b["corredor"]) {  return 1; }
                                      if (a["corredor"] < b["corredor"]) {  return -1;}
                                      return 0;
                                  }).map((c, index) =>  this.props.movimientos_terrestres_object.filter(mov => mov.corredor.nombre===c.corredor).length>0?
                                                          <Table.Col>{c.corredor} ({ this.props.movimientos_terrestres_object.filter(mov => mov.corredor.nombre===c.corredor).length })</Table.Col>: null)}
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Grid.Col>
                      {/** Incidencias */}
                      {this.getFuncionalidad("Panel incidencias") ?
                        <Grid.Col sm={12} lg={12}>
                          <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-dark text-light mt-1">
                            <Table.Header>
                            </Table.Header>
                            <Table.Body>
                              <Table.Row>
                                <Table.Col><strong>Incidencias</strong></Table.Col>
                                { incidencias.sort(function(a,b) {
                                      if (a["incidencia"] > b["incidencia"]) {  return 1; }
                                      if (a["incidencia"] < b["incidencia"]) {  return -1;}
                                      return 0;
                                  }).map((i, index) =>  this.props.movimientos_terrestres_object.filter(mov => mov.nombreIncidencia ===i.incidencia).length>0?
                                                          <Table.Col>{i.incidencia} ({ this.props.movimientos_terrestres_object.filter(mov => mov.nombreIncidencia ===i.incidencia).length })</Table.Col>: null)}
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Grid.Col>
                      : null}
                      {/** Estatus Ruta */}
                        <Grid.Col sm={12} lg={12}>
                          <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-dark text-light mt-1">
                            <Table.Header>
                            </Table.Header>
                            <Table.Body>
                              <Table.Row>
                                <Table.Col><strong>Resumen Estatus</strong></Table.Col>
                                { rutasEstatus.sort(function(a,b) {
                                      if (a["rutasEstatus"] > b["rutasEstatus"]) {  return 1; }
                                      if (a["rutasEstatus"] < b["rutasEstatus"]) {  return -1; }
                                      return 0;
                                  }).map((i, index) =>  this.props.movimientos_terrestres_object.filter(mov => mov.ruta === i.rutasEstatus).length>0?
                                                          <Table.Col> <span onClick={ () => this.setState( {ruta : i.rutasEstatus} ) } >{i.rutasEstatus}</span> ({ this.props.movimientos_terrestres_object.filter(mov => mov.ruta === i.rutasEstatus).length })</Table.Col>: null)}
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Grid.Col>

                      {/**
                         * alertas x categorias
                         */}
                      { url.searchParams.get("q") === "activos" && this.state.alertasTShowCat &&this.state.alertasTCat && this.estaPrivilegioActivo("terrestre_movimientos_listar") && this.getFuncionalidad("Alertas(Resumen)") ?
                        <Grid.Col sm={12} lg={12}>
                          <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-secondary text-light mt-1">
                            <Table.Header>
                            </Table.Header>
                            <Table.Body>
                              <Table.Row>
                                <Table.Col>Sin Atender</Table.Col>
                                  { this.state.alertasCategoriasT.sort(function(a,b) {
                                      if (a["categoria"] > b["categoria"]) {  return 1; }
                                      if (a["categoria"] < b["categoria"]) {  return -1;}
                                      return 0;
                                  }).map((c, index) =>
                                    <Table.Col>
                                      <ModalLayout
                                        tamanio={'60%'}
                                        title={<div>{this.state.alertasTCat[c.categoria]? <div><i className={"fa "+this.state.alertasTCat[c.categoria][0].logo} style={{"font-size":"20px",color:this.state.alertasTCat[c.categoria][0].color}}></i>{c.categoria}{"("+this.state.alertasTCat[c.categoria].filter(word => !word.alert.atendida ).length+")"}</div>:""}</div>}
                                        name={<Button pill size="sm" outline color="danger">{this.state.alertasTCat[c.categoria]? <div><i className={"fa "+this.state.alertasTCat[c.categoria][0].logo} style={{"font-size":"20px",color:this.state.alertasTCat[c.categoria][0].color}}></i>{c.categoria}{"("+this.state.alertasTCat[c.categoria].filter(word => !word.alert.atendida ).length+")"}</div>:""}</Button>}
                                        formulario={
                                          <AlertasListar
                                            accion={'modificar'}
                                            usuario={user}
                                            alerta={this.state.alertasTCat[c.categoria]? this.state.alertasTCat[c.categoria].filter(word => !word.alert.atendida ): []}
                                            getfuntion={this.getfuntion}
                                            stor={this.props}
                                            closeModal={this.hiddenmodal}
                                            setAlerta={this.setAlerta}
                                            plataformaTipo = { "Terrestre" }
                                            gps = {this.state.gpsTerrestreReciente}
                                            movimientosTerrestres = {this.state.movimentosTerrestres}
                                            
                                          />
                                        }
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                      />
                                    </Table.Col> )}
                              </Table.Row>
                              <Table.Row>
                                <Table.Col>Atendidas</Table.Col>
                                  { this.state.alertasCategoriasT.sort(function(a,b) {
                                      if (a["categoria"] > b["categoria"]) {  return 1; }
                                      if (a["categoria"] < b["categoria"]) {  return -1;}
                                      return 0;
                                  }).map((c, index) => 
                                    <Table.Col>
                                      <ModalLayout
                                        tamanio={'60%'}
                                        title={<div>{this.state.alertasTCat[c.categoria]? <div><i className={"fa "+this.state.alertasTCat[c.categoria][0].logo} style={{"font-size":"20px",color:this.state.alertasTCat[c.categoria][0].color}}></i>{c.categoria}{"("+this.state.alertasTCat[c.categoria].filter(word => word.alert.atendida ).length+")"}</div>:""}</div>}
                                        name={<Button pill size="sm" outline color="success">{this.state.alertasTCat[c.categoria]? <div><i className={"fa "+this.state.alertasTCat[c.categoria][0].logo} style={{"font-size":"20px",color:this.state.alertasTCat[c.categoria][0].color}}></i>{c.categoria}{"("+this.state.alertasTCat[c.categoria].filter(word => word.alert.atendida ).length+")"}</div>:""}</Button>}
                                        formulario={
                                          <AlertasListar
                                            accion={'atendidas'}
                                            usuario={user}
                                            alerta={this.state.alertasTCat[c.categoria]? this.state.alertasTCat[c.categoria].filter(word => word.alert.atendida ): []}
                                            getfuntion={this.getfuntion}
                                            stor={this.props}
                                            closeModal={this.hiddenmodal}
                                            setAlerta={this.setAlerta}
                                            plataformaTipo = { "Terrestre" }
                                          />
                                        }
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                      />
                                    </Table.Col> )}
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Grid.Col>
                      :null}
                      {/**
                       * Alertas x corredores
                       */}
                      { false && url.searchParams.get("q") === "activos" && !this.state.alertasTShowCat && this.state.alertasTCat && this.estaPrivilegioActivo("terrestre_movimientos_listar") && this.getFuncionalidad("Alertas(Resumen)") ?
                        <Grid.Col sm={11} lg={11}>
                          <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-secondary text-light mt-1">
                            <Table.Header>
                            </Table.Header>
                            <Table.Body>
                              <Table.Row>
                                <Table.Col>Sin Atender</Table.Col>
                                  { this.state.alertasCorredoresT.sort(function(a,b) {
                                      if (a["corredor"] > b["corredor"]) {  return 1; }
                                      if (a["corredor"] < b["corredor"]) {  return -1;}
                                      return 0;
                                  }).map((c, index) =>
                                    <Table.Col>
                                      <ModalLayout
                                        tamanio={'60%'}
                                        title={<div>{this.state.alertasTCorr[c.corredor]? <div><i className={"fa "+this.state.alertasTCorr[c.corredor][0].logo} style={{"font-size":"20px",color:this.state.alertasTCorr[c.corredor][0].color}}></i>{c.corredor}{"("+this.state.alertasTCorr[c.corredor].filter(word => !word.alert.atendida ).length+")"}</div>:""}</div>}
                                        name={<Button pill size="sm" outline color="danger">{this.state.alertasTCorr[c.corredor]? <div><i className={"fa "+this.state.alertasTCorr[c.corredor][0].logo} style={{"font-size":"20px",color:this.state.alertasTCorr[c.corredor][0].color}}></i>{c.corredor}{"("+this.state.alertasTCorr[c.corredor].filter(word => !word.alert.atendida ).length+")"}</div>:""}</Button>}
                                        formulario={
                                          <AlertasListar
                                            accion={'modificar'}
                                            usuario={user}
                                            alerta={this.state.alertasTCorr[c.corredor]? this.state.alertasTCorr[c.corredor].filter(word => !word.alert.atendida ): []}
                                            getfuntion={this.getfuntion}
                                            stor={this.props}
                                            closeModal={this.hiddenmodal}
                                            setAlerta={this.setAlerta}
                                            plataformaTipo = { "Terrestre" }
                                          />
                                        }
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                      />
                                    </Table.Col> )}
                              </Table.Row>
                              <Table.Row>
                                <Table.Col>Atendidas</Table.Col>
                                  { this.state.alertasCorredoresT.sort(function(a,b) {
                                      if (a["corredor"] > b["corredor"]) {  return 1; }
                                      if (a["corredor"] < b["corredor"]) {  return -1;}
                                      return 0;
                                  }).map((c, index) => 
                                    <Table.Col>
                                      <ModalLayout
                                        tamanio={'60%'}
                                        title={<div>{this.state.alertasTCorr[c.corredor]? <div><i className={"fa "+this.state.alertasTCorr[c.corredor][0].logo} style={{"font-size":"20px",color:this.state.alertasTCorr[c.corredor][0].color}}></i>{c.corredor}{"("+this.state.alertasTCorr[c.corredor].filter(word => word.alert.atendida ).length+")"}</div>:""}</div>}
                                        name={<Button pill size="sm" outline color="success">{this.state.alertasTCorr[c.corredor]? <div><i className={"fa "+this.state.alertasTCorr[c.corredor][0].logo} style={{"font-size":"20px",color:this.state.alertasTCorr[c.corredor][0].color}}></i>{c.corredor}{"("+this.state.alertasTCorr[c.corredor].filter(word => word.alert.atendida ).length+")"}</div>:""}</Button>}
                                        formulario={
                                          <AlertasListar
                                            accion={'atendidas'}
                                            usuario={user}
                                            alerta={this.state.alertasTCorr[c.corredor]? this.state.alertasTCorr[c.corredor].filter(word => word.alert.atendida ): []}
                                            getfuntion={this.getfuntion}
                                            stor={this.props}
                                            closeModal={this.hiddenmodal}
                                            setAlerta={this.setAlerta}
                                            plataformaTipo = { "Terrestre" }
                                          />
                                        }
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                      />
                                    </Table.Col> )}
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Grid.Col>
                      :null}                 
                      {/** Fin paneles terrestres */}
                      <Grid.Col sm={1} lg={Object.keys( this.state.estatusRuta ).length > 10 ? 2:12-Object.keys( this.state.estatusRuta ).length }>
                        <div className="float-left ml-2">
                          {url.searchParams.get("q") === "activos"?
                            <Form.Switch type="Checkbox" name="toggle" className="ml-2"
                            checked={this.state.alertasTShowCat}
                            onClick={()=> this.setState({alertasTShowCat: !this.state.alertasTShowCat })}
                            label={ this.state.alertasTShowCat? "Alertas por Categorias": "Alertas por Corredores"}/>
                          :null}
                        </div>
                        {url.searchParams.get("q") === "finalizados"?
                          <div>                            
                            <span className="float-left ml-2">
                              <input type="date" id="start" name="trip-start" value={ this.state.fecha1 } onChange={ this.onChangeFecha1 }></input>
                            </span>
                            <span className="float-left ml-2"> - </span>
                            <span className="float-left ml-2">
                              <input type="date" id="start" name="trip-start" value={ this.state.fecha2 } onChange={ this.onChangeFecha2 }></input>
                            </span>
                          </div>
                        :null}
                      </Grid.Col> 
                    </Grid.Row>
                </div>

              </div>
            </div>
            <div style={{ height: "100vh", "font-setMovimientoTerrestreize": "smaller"}}>
              <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {30}>
                <div>
                  {/** Movimientos */}
                  <SplitterLayout vertical= {true}
                    //percentage= {true}
                    //secondaryInitialSize= {50}
                  >
                    {this.estaPrivilegioActivo("terrestre_movimientos_listar") && this.getFuncionalidad("MovimientosT") ?
                      <div>
                        <div>      
                          <div className="panel panel-default" style={{width: '130%'}}>
                            <EspecificoT
                              mtipo                     = { this.props.mtipo }
                              Ctlogo                    = "terrestreMovimientos"
                              setMovimiento             = {this.setMovimientoTerrestre}
                              tamanio                   = "75%"
                              FCtlogo                   = {MonitoreoTerrestreForm}
                              alertasT                  = { this.state.alertasT }
                              gpsTerrestreReciente      = { this.state.gpsTerrestreReciente }
                              catalogos                 = {{ 0: "operacionesEstatus", 1:"funcionalidades", 2:"terrestreMovimientos"}}
                              columnas                  = {this.state.columnasT}
                              usuariosCorredores        = {this.state.usuariosCorredoresT }
                              setMovimientosTerrestres  = {this.setMovimientosTerrestres }
                              PlataformasPerfiles       = { this.state.PlataformasPerfiles }
                              ruta                      = { this.state.ruta }
                              fecha1                    = {this.state.fecha1}
                              fecha2                    = {this.state.fecha2}
                              idToFilter                = {this.state.idToFilter}
                              changeColToFilter         = {this.changeColToFilter}
                            />
                          </div>
                        </div>
                      </div>
                    : null}
                  </SplitterLayout>
                </div>
                <div>
                  {/** Panel */}                      
                      {
                        this.state.plataformaTipo == "Terrestre"?
                          this.state.currentMovimientoTerrestre?
                            <Panel
                              movimiento           = { this.state.currentMovimientoTerrestre.id}
                              getMovimiento        = { this.state.currentMovimientoTerrestre}
                              setMovimiento        = { this.setMovimiento}
                              rutasEstatus         = { this.props.rutasEstatus_object}
                              operacionesEstatus   = { this.props.operacionesEstatus_object}
                              plataformaTipo       = { "Terrestre" }
                              gpsEstatusTerrestres = { this.state.gpsEstatusTerrestres }
                              privilegios_object   = { this.props.privilegios_object }
                              alertasT             = { this.state.alertasT }
                              setAlerta            = { this.setAlerta }
                              getMovimientoTerrestreById = {this.getMovimientoTerrestreById}
                              movimentosTerrestres = { this.movimentosTerrestres }
                              gpsReciente          = { this.state.gpsTerrestreReciente }
                              PlataformasPerfiles  = { this.state.PlataformasPerfiles }
                              tiempos              = { this.state.temporizadores }
                              addToArray           = {this.addToArray}
                              usuario              = {currentUser[0].usuario.id}
                            />
                          :null
                        :null
                      }
                      {
                        !this.state.plataformaTipo ?
                          this.state.currentMovimientoTerrestre?
                            <Panel
                              movimiento          = { 0 }
                              getMovimiento       = { {} }
                              setMovimiento       = { null }
                              updateMovimiento    = { null }
                              rutasEstatus        = { null }
                              operacionesEstatus  = { null }
                              plataformaTipo      = { null }
                              gpsReciente         = { null }
                              privilegios_object  = { this.props.privilegios_object }
                            />
                          :null
                        :null
                      }
                </div>
                <div>
                  {
                  <Panel
                    movimiento={this.state.currentMovimientoTerrestre.id}
                    getMovimiento={this.state.currentMovimientoTerrestre}
                    setMovimiento={this.setMovimiento}
                    rutasEstatus={this.props.rutasEstatus_object}
                    operacionesEstatus={
                      this.props.operacionesEstatus_object
                    }
                    incidencias={this.props.incidencias_object}
                    plataformaTipo={"Terrestre"}
                    getplataformaTipo={this.getplataformaTipo}
                    gpsEstatusTerrestres={this.state.gpsEstatusTerrestres}
                    privilegios_object={this.props.privilegios_object}
                    alertasT={this.state.alertasT}
                    setAlerta={this.setAlerta}
                    getMovimientoTerrestreById={
                      this.getMovimientoTerrestreById
                    }
                    movimentosTerrestres={this.movimentosTerrestres}
                    gpsReciente={this.state.gpsTerrestreReciente}
                    PlataformasPerfiles={this.state.PlataformasPerfiles}
                    tiempos={this.temporizadores}
                    usuario={currentUser[0].usuario.id}
                  />
                 }
                  {!this.state.plataformaTipo ? (
                    this.state.currentMovimientoTerrestre ? (
                      <Panel
                        movimiento={0}
                        getMovimiento={{}}
                        setMovimiento={null}
                        updateMovimiento={null}
                        rutasEstatus={null}
                        operacionesEstatus={null}
                        plataformaTipo={null}
                        getplataformaTipo={this.getplataformaTipo}
                        gpsReciente={null}
                        privilegios_object={this.props.privilegios_object}
                      />
                    ) : null
                  ) : null}
                </div>
              </SplitterLayout>
            </div>

          </Grid.Col>
        </SiteWrapper>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    movimientos_terrestres_object: state.catalogos_reducer.movimientos_terrestres_object,
    movimientosTipo_object: state.catalogos_reducer.movimientosTipo_object,
    incidencias_object: state.catalogos_reducer.incidencias_object,
    clientes_object: state.catalogos_reducer.clientes_object,
    otd_object: state.catalogos_reducer.otd_object,
    plataformas_object: state.catalogos_reducer.plataformas_object,
    rutasEstatus_object: state.catalogos_reducer.rutasEstatus_object,
    transportistas_object: state.catalogos_reducer.transportistas_object,
    corredores_object: state.catalogos_reducer.corredores_object,
    funcionalidades_object: state.catalogos_reducer.funcionalidades_object,
    privilegios_object: state.catalogos_reducer.privilegios_object,
    operacionesEstatus_object: state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo_object: state.catalogos_reducer.operacionesTipo_object,
    operacionesClientes_object: state.catalogos_reducer.operacionesClientes_object,
    operadores_object: state.catalogos_reducer.operadores_object,
    updateStatusMensaje_Object:state.catalogos_reducer.update_mensaje,
  }
}
function mapDispachToProps(dispach) {
  return {
    movimientos_terrestres_update: (elementos) => dispach(ACTIONS.elementos_movimientos_terrestres_imput(elementos)),
    movimientosTipo_update: (elementos) => dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    clientes_update: (elementos) => dispach(ACTIONS.elementos_clientes_imput(elementos)),
    otd_update: (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    plataformas_update: (elementos) => dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    rutasEstatus_update: (elementos) => dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    transportistas_update: (elementos) => dispach(ACTIONS.elementos_transportistas_imput(elementos)),
    corredores_update: (elementos) => dispach(ACTIONS.elementos_corredores_imput(elementos)),
    funcionalidades_update: (elementos) => dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),
    updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status)),
    operacionesEstatus_update: (elementos) => dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos) => dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    operacionesClientes_update: (elementos) => dispach(ACTIONS.elementos_operacionesClientes_imput(elementos)),
    operadores_update: (elementos) => dispach(ACTIONS.elementos_operadores_imput(elementos)),
  }
}
export default connect(mapStateToProps, mapDispachToProps)(Monitoreo);