import {  Crud_Catalogos } from "../../herramientas/Crud_Catalogos";

export function Arroba(movimiento, acumulador){
    return Crud_Catalogos("mail/arrobanaviera/"+movimiento+"/"+acumulador, "movimientos", "get").then((returnVal) => {
        return returnVal;
        }).catch(err =>{
        if( err.response ){
            console.log(err.response);
            return err.response;
        }else{
            return "Error desconocido ....";
        }
    })
}

export function ArrobaTerrestre(idmovmiento, acumulador){
    return Crud_Catalogos("mail/arrobaterrestre/"+idmovmiento+"/"+acumulador, "movimientos", "get").then((returnVal) => {
        return returnVal;
        }).catch(err =>{
        if( err.response ){
            console.log(err.response);
            return err.response;
        }else{
            return "Error desconocido ....";
        }
    })
}

export function ArrobaIncidencia(data){
    return Crud_Catalogos("mail/arrobanavieraincidencia", "movimientos", "post", "", data).then((returnVal) => {
        return returnVal;
        }).catch(err =>{
        if( err.response ){
            console.log(err.response);
            return err.response;
        }else{
            return "Error desconocido ....";
        }
    })
}

export function ArrobaIncidenciaTerrestre(data){
    return Crud_Catalogos("mail/arrobaterrestreincidencia", "movimientos", "post", "", data).then((returnVal) => {
          return returnVal;
        }).catch(err =>{
        if( err.response ){
            console.log(err.response);
            return err.response;
        }else{
            return "Error desconocido ....";
        }
    })
}

export default Arroba;
