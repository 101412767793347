// @flow
import React, { Component } from "react";
import Select from "react-select";
import { Button, Form, Icon, Grid } from "tabler-react";

class GpsFijosForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
                id: "",
        Plataforma:0,
       GpsHardware:0,

            nombre: "",
             marca: "",
              imei: "",
               sim: "",
           latitud: "",
          longitud: "",
         altaFecha: "",
              idGg: "",
           estatus:0,
   crudbutonEnable: true, // Se desabilita cuando se hace click
            accion: this.props.accion, //'nuevo'// modificar, nuevo
        Plataforma: { value:"id", label:"Selecciona una Plataforma " },

    };
    this.enableSave    = this.enableSave.bind(this);
    this.gpsFijoObject = [];
    this.Plataforma    = [];
    this.GpsHardware   = [];
  }
  isEnable=(Accion)=>{
    let borrar= "gps_fijo_borrar";
    let actualizar= "gps_fijo_editar";
    let agregar= "gps_fijo_agregar";
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "borrar"){
        if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }
  componentDidMount = () => {
    this.props.stor.plataformas_object.map((elemento, index) => {
      this.Plataforma.push({ value:elemento.id, label:elemento.nombre });
    });

    this.props.stor.gpsHardware_object.map((elemento, index) =>{
      this.GpsHardware.push({value:elemento.id, label:elemento.nombre})
    })

    if (this.state.accion !== 'nuevo') {
      this.setState({       id: this.props.elementos.id });
      this.setState({   nombre: this.props.elementos.nombre });
      this.setState({    marca: this.props.elementos.marca });
      this.setState({     imei: this.props.elementos.imei });
      this.setState({      sim: this.props.elementos.sim });
      this.setState({  latitud: this.props.elementos.latitud });
      this.setState({ longitud: this.props.elementos.longitud });
      this.setState({     idGg: this.props.elementos.idGg });
      this.setState({  estatus: this.props.elementos.estatus });
      this.setState({Plataforma:    this.props.elementos.plataforma.id });


      this.setState({ GpsHardware:{value:this.props.elementos.gpsHardware.id, label: this.props.elementos.gpsHardware.nombre }});
      this.gpsFijoObject = this.props.elementos;

      for (let item of this.props.stor.plataformas_object) {
        if (item.id === this.props.elementos.plataforma.id) {
          this.setState({ Plataforma: { value:this.props.elementos.plataforma.id, label:this.props.elementos.plataforma.nombre } });
          break;
        }
      }

    } else {
      this.gpsFijoObject =
      {
        "id":0, "nombre":"", "marca":"", "imei":"", "sim":"", "latitud":0, "longitud":0, "altaFecha":null, "idGg":0, "estatus":0,
        "plataforma": {"id":1, "nombre":"DHL" },
        "gpsHardware":{"id":0, "nombre":"" },
      }
    }
  }
  enableSave = () => {
    //if (this.state.nombre ) { //&& this.state.marca && this.state.imei && this.state.sim && this.state.latitud && this.state.longitud && this.state.idGg && this.state.gpsEstatus && this.state.GpsHardware )
      if ( this.state.idGg >0 && this.state.Plataforma.id > 0 && this.state.GpsHardware.id > 0 && this.state.imei >0 && this.state.sim > 0 ) {//&& this.state.nombre.length > 0 && this.state.imei.length > 0 && this.state.sim.length > 0 && this.state.idGg > -1
        //return false;
        return true;
      } else { return false }
    //}else{ return true; }
  }
  changeName = (event) => {
    this.gpsFijoObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  }
  changeMarca = (event) => {
    this.gpsFijoObject.marca = event.target.value;
    this.setState({ marca: event.target.value });
  }
  changeImei = (event) => {
    this.gpsFijoObject.imei = event.target.value;
    this.setState({ imei: event.target.value });
  }
  changeSim = (event) => {
    this.gpsFijoObject.sim = event.target.value;
    this.setState({ sim: event.target.value });
  }
  changeLatitud = (event) => {
    this.gpsFijoObject.latitud = event.target.value;
    this.setState({ latitud: event.target.value });
  }
  changeLongitud = (event) => {
    this.gpsFijoObject.longitud = event.target.value;
    this.setState({ longitud: event.target.value });
  }
  changeidGg = (event) => {
    this.gpsFijoObject.idGg = event.target.value;
    this.gpsFijoObject.id= event.target.value;
    this.setState({ idGg: event.target.value });
    this.setState({ id: event.target.value });
  }
  changeEstatus = (event) => {
    this.state.estatus === 0 ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });
    this.state.estatus === 0 ? this.gpsFijoObject.estatus = 1 : this.gpsFijoObject.estatus = 0;
  }
  changePlataforma =(event) =>{
    //this.gpsFijoObject.Plataforma = event.value;
    //this.setState({ Plataforma: { value: event.value, label: event.label} });
    for (let item of this.props.stor.plataformas_object) {
      if (item.id === event.value) {
        //this.setState({ Plataforma: { value:event.value, label:event.label} });
        this.gpsFijoObject.plataforma = item;
        break;
      }
    }
    this.setState({Plataforma:event});
  }
  changeGpsHardware = (event) => {
    for (let item of this.props.stor.gpsHardware_object) {
      if (item["id"] == event.value) {
        this.gpsFijoObject.gpsHardware = item;
        break;
      }
    }
    this.setState({GpsHardware:event })
  };

  delete()   {  this.setState({ crudbutonEnable: false }); this.props.getfuntion("delete", "", this.state.id); }
  crud_put() {  this.setState({ crudbutonEnable: false }); this.props.getfuntion("put", this.gpsFijoObject ); }
  crud_post(){  this.setState({ crudbutonEnable: false }); this.props.getfuntion("post",this.gpsFijoObject); }

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? <h1>{this.props.elementos.nombre}</h1> : null}
        {
          /**
           * Formulario personas
           */
        }
        <Grid.Row>
          <Grid.Col md={12} xl={12}>

            <Grid.Row>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="id">
                  <Form.Input name="id" disabled={this.state.accion !== "nuevo"} type="number" value={this.state.id} />
                  {this.state.accion !== "nuevo" ? (
                    <label>Si cambia el idGG en GpsGate se actualizara en DHLONE siempre que el IMEI siga siendo el mismo</label>
                  ) : null}
                </Form.Group>
                <Form.Group label="Nombre">
                  <Form.Input name="nombre" disabled={this.state.accion !== "nuevo"} type="text" value={this.state.nombre} onChange={this.changeName} maxlength="30" />
                  {this.state.accion !== "nuevo" ? (
                    <label>Para cambiar el Nombre hacerlo desde <span className="text-danger">GpsGate en Nombre de Pantalla</span></label>
                  ) : null}
                </Form.Group>
                <Form.Group label="Marca">
                  <Form.Input name="marca" type="text" value={this.state.marca} onChange={this.changeMarca} maxlength="50" />
                </Form.Group>
                <Form.Group label="Imei">
                  <Form.Input name="imei" disabled={this.state.accion !== "nuevo"} type="text" value={this.state.imei} onChange={this.changeImei} maxlength="20" />
                  {this.state.accion !== "nuevo" ? (
                    <label>Para cambiar el IMEI hacerlo desde <span className="text-danger">GpsGate</span></label>
                  ) : null}
                </Form.Group>
                <Form.Group label="Sim">
                  <Form.Input name="sim" type="text" value={this.state.sim} onChange={this.changeSim} maxlength="30" />
                </Form.Group>
                <Form.Group label="Latitud">
                  <Form.Input name="latitud" type="number" step='0.01' value={this.state.latitud} onChange={this.changeLatitud} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Longitud">
                  <Form.Input name="longitud" type="number" step='0.01' value={this.state.longitud} onChange={this.changeLongitud} />
                </Form.Group>
                <Form.Group label="Fecha de alta">
                  <Form.Input name="fechaAlta" type="date" value={this.state.altaFecha} disabled />
                </Form.Group>
                <Form.Group label="idGg">
                  <Form.Input name="idGg"
                              disabled={this.state.accion !== 'nuevo'}
                              type="number" value={this.state.idGg} onChange={this.changeidGg} />
                  {this.state.accion !== "nuevo" ? (
                    <label>Si cambia el idGG en GpsGate se actualizara en DHLONE siempre que el IMEI siga siendo el mismo</label>
                  ) : null}
                </Form.Group>
                <Form.Group label="Estatus">
                  <Form.Switch type="Checkbox" name="toggle" value={this.state.estatus} checked={this.state.estatus} onChange={this.changeEstatus} />
                </Form.Group>

                {/* <Form.Group label="Plataforma">
                  <Select value={this.state.Plataforma} onChange={this.changePlataforma} options={this.Plataforma} />
                </Form.Group> */}

                <Form.Group label="GPS Hardware">
                  <Select value={this.state.GpsHardware} onChange={this.changeGpsHardware} options={this.GpsHardware} />
                  {this.state.accion !== "nuevo" ? (
                    <label>Si no existe el Gps Hardware <span className="text-danger">'Darlo de alta en el Catálogo GPS hardware'</span> para poder verlo y seleccionarlo en este campo</label>
                  ) : null}
                </Form.Group>

              </Grid.Col>
            </Grid.Row>

          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                <span className="badge"><Icon link={true} name="save" />Guardar</span>
              </Button>
            </span>
          :null}
          {this.state.accion === "modificar" && this.isEnable("borrar")?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()}  disabled={!this.state.crudbutonEnable}>
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
            :
            null
          }
        </div>
      </div>
    );
  }
}
export default GpsFijosForm;
