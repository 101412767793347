import React, { useState, useEffect } from 'react';
import { Doughnut, Bar, Polar,Pie } from 'react-chartjs-2';
import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import { Grid, Button, Icon, Form, Table } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { Exportar } from '../../herramientas/Excel';
import { data } from 'jquery';
import { timeToDatefull } from "../../herramientas/DateFormat";
import { set } from 'd3-collection';
import * as conf from "../../config/config";



/*import Chart from "chart.js";
import { chartConfig, bindChartEvents } from "./utils";
import "./styles.scss";
const currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
const chartSelector = "[data-results-chart]";
const chartTitleSelector = "[data-results-chart-title]";
const chartLegendSelector = "[data-results-chart-legends]";
let chartEl;
let chartTitleEl;
let chartLegendEL;
let myChart;
chartEl = document.querySelector(chartSelector);
chartTitleEl = document.querySelector(chartTitleSelector);
chartLegendEL = document.querySelector(chartLegendSelector);
const renderTitle = () => "Total value: $500.00";
const renderChart = () => {
  chartTitleEl.textContent = renderTitle();
  const dataItems = ["280.00", "80.00", "50.33", "89.67"];
  const labels = ["Principal & Interest", "Taxes ", "Insurance", "PMI"];
  const ctx = chartEl.getContext("2d");
  myChart = new Chart(ctx, chartConfig({ dataItems, labels }));
  chartLegendEL.innerHTML = myChart.generateLegend();
  bindChartEvents(myChart, document);
};
renderChart();*/


const MovimientosXtransportistaSemanalPage = () => {
  const [ datos, setDatos ] = useState([]);
  const [ year, setYear]= useState(new Date().getFullYear() );
  const [ week, setWeek]= useState(new Date().getWeekNumber() );
  const [ otds, setOtds]= useState( [] );
  const [ transportistas, setTransportistas]= useState([]);
  const [ transportista, setTransportista]= useState({});
  const [ detalle, setDetalle]= useState("r_otd");
  const [ resultado_api, setResultadoApi    ] = useState([]);
  const [chartdata,setCharData]=useState({});


  //  let dataX=null;
  //  console.log(dataX); 
/*const response=fetch(conf.api_raiz+'terrestreMovimientos/contadorIncidencias/', 
  { 
  method: 'get', 
  headers: new Headers({
     'Authorization': "Bearer "+sessionStorage.getItem("tok"),
     'Content-Type':'text/plain; charset=UTF-8'
  })
  }) 
  console.log(response);
*/


 useEffect(() => {

  crud("get", "", "", "terrestreMovimientos/contadorIncidencias/" , "datos_grafica");
},chartdata



);
 
   





  /*  let data2 = [9, 9, 9]
    let labels2 = ["Integradas reportando", "Integradas sin reportar", "Sin integrar"]
    let customLabels2 = labels2.map((label,index) =>`${label}: ${data2[index]}`)
    const chartdata2 = {
      labels2: customLabels2,
      datasets: [
        {
          label: "Estatus de xplacas",
          backgroundColor: [
            "#00A6B4",
            "#f96a5d",
            "#6800B4",
            "#83ce83",
            "#959595",
          ],
          data: data2,
        },
      ],};*/

  


  const getData=(e)=>{
    if( year >= 2020 && week > 0 ){
      crud("get", "", "", "terrestreMovimientos/total_mov_x_Tarnportista_x_Semana/" + year+"/"+ week , "mov_tras_x_semana");
      crud("get", "", "", "terrestreMovimientos/total_otd_x_Semana/" + year+"/"+ week , "otd_x_semana");
      crud("get", "", "", "terrestreMovimientos/contadorIncidencias/" , "");
     
    }
  

  }


  const changeWeek=(e)=>{
    setWeek(e.target.value);
  }
  const changueDetalle =(det, valor)=>{
    setDetalle(det);
    if(det === 'r_semana'){
      setWeek(valor);
      setTransportista({});
      crud("get", "", "", "terrestreMovimientos/total_mov_x_Tarnportista_x_Semana/" + year+"/"+ valor , "mov_tras_x_semana");
    }
  }


  


  const setTrans=(e)=>{
    setTransportista(e);
    setDetalle('r_movimiento');
  }
  return (
    <SiteWrapper>





      <div className="container mt-3">
        <Grid.Row>
          <Grid.Col>
            <h2>	On Time Delivery </h2>
            <h5> Historicas </h5>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="Año">
                  <Form.Input name="year" disabled type="number" placeholder="YYYY"  value={year} onChange={setYear}/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="Semana">
                  <Form.Input name="week" type="number"placeholder="W" value={week}  onChange={changeWeek}/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <Form.Group label=".">
                  <Button pill size="sm" outline color="primary" onClick={ ()=> getData() } ><Icon name="refresh-cw" /></Button>
                </Form.Group>
              </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <h2>	OTD </h2>
            <h5> Tabla resumen On Time Delivery semana { week } </h5>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <div style={{ height: "80%" }} className="text-center">
              <h2>	Estadisticas de movimientos en la semana {week} del {year} </h2>
              <Bar
                data={datos}
                width={100}
                height={90}
                options={{ maintainAspectRatio: false }}
              />
            </div>

          


          </Grid.Col>
        </Grid.Row>
        { detalle === "r_otd"?
          <Grid.Row>
            <div className="mt-5">
              General 10 semanas atras
            </div>
            <Table cards={false} striped={false} responsive={true} className="table-vcenter"
              headerItems={[
                {content:""},
                {content:"Semana"},
                {content:"onTime"},
                {content:"Delay"},
                {content:"Total Paradas"},
                {content:"OTD"},
              ]}>
                            <Table.Header></Table.Header>
                            <Table.Body>
                            { otds.sort().map((w, index) =>
                                <Table.Row align="center" style={ { "background-color": "rgba(95, 231, 50, 0.30)" }}>
                                    <Table.Col> { index+1 } </Table.Col>
                                    <Table.Col> { w.semana } </Table.Col>
                                    <Table.Col> { w.on_time } </Table.Col>
                                    <Table.Col> { w.delay } </Table.Col>
                                    <Table.Col> { w.total } </Table.Col>
                                    <Table.Col> { w.total? Math.floor((100/w.total)*w.on_time): 0 } %</Table.Col>
                                    <Table.Col> <Button pill size="sm" outline color="primary" onClick={ ()=> changueDetalle('r_semana', w.semana) } ><Icon name="list" /></Button> </Table.Col>
                                </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
          </Grid.Row>
        :null}
        { detalle === "r_semana" || detalle === "r_movimiento"?
          <Grid.Row>
            <Grid.Col sm={12} lg={12}>
              <div className="mt-5">
                Detalle de la semana <Button pill size="sm" outline color="primary" onClick={ ()=> setDetalle('r_otd') } ><Icon name="arrow-left" /></Button>
              </div>
            </Grid.Col>
            <Grid.Col>              
              <Table cards={false} striped={false} responsive={true} className="table-vcenter"
                headerItems={[
                  {content:"Semana"},
                  {content:"Transportista"},
                  {content:"onTime"},
                  {content:"Delay"},
                  {content:"Total Paradas"},
                  {content:"OTD"},
                ]}>
                  <Table.Header></Table.Header>
                  <Table.Body>
                  { transportistas.sort().map((w, index) =>
                      <Table.Row align="center" style={ { "background-color": "rgba(95, 231, 50, 0.30)" }}>
                          <Table.Col> { w.semana } </Table.Col>
                          <Table.Col> { w.nombre } </Table.Col>
                          <Table.Col> { w.ontime } </Table.Col>
                          <Table.Col> { w.delay } </Table.Col>
                          <Table.Col> { w.totentregas } </Table.Col>
                          <Table.Col> { w.totentregas ? Math.floor((100/w.totentregas)*w.ontime): 0 } % </Table.Col>
                          <Table.Col> <Button pill size="sm" outline color="primary" onClick={ ()=> setTrans(w) } ><Icon name="list" /></Button> </Table.Col>
                      </Table.Row>
                      )}
                  </Table.Body>
              </Table>
            </Grid.Col>
            {detalle === "r_movimiento"?
              <Grid.Col>              
                <Table cards={false} striped={false} responsive={true} className="table-vcenter">
                  <Table.Header></Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Col> Transportista: </Table.Col><Table.Col> { transportista.nombre? transportista.nombre: "" } </Table.Col>
                    </Table.Row>
                    <Table.Row>  
                      <Table.Col> Contacto: </Table.Col><Table.Col> { transportista.nombre? transportista.contacto_nombre: "" } </Table.Col>
                    </Table.Row>
                    <Table.Row>  
                      <Table.Col> Tel 1: </Table.Col><Table.Col> { transportista.nombre? transportista.telefono1: "" } </Table.Col>
                    </Table.Row>
                    <Table.Row>  
                      <Table.Col> Tel 2: </Table.Col><Table.Col> { transportista.nombre? transportista.telefono2: "" } </Table.Col>
                    </Table.Row>
                    <Table.Row>  
                      <Table.Col> Correo: </Table.Col><Table.Col> { transportista.nombre? transportista.correo: "" } </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Col>
            :null}
          </Grid.Row>
        :null}
       <h2>Estatus de placas</h2>
       <Doughnut data={chartdata} />
       <h2>Estatus de viaje</h2>
       <Pie data={chartdata} 
       
       
       
       />
        
        <Doughnut
        data={chartdata}
        width={50}
        height={50}
        options={{
          legend: { display: true, position: "left" },
          datalabels: {
            display: true,
            color: "white",
          },
          tooltips: {
            backgroundColor: "#5a6e7f",
          },
        }}
      />
    
      </div>


      
    </SiteWrapper>
  );

  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    if (stateVar === "mov_tras_x_semana" && returnVal) {
                      var dat={
                        labels: [ ],
                        datasets: [{ data: [], backgroundColor: [ ], hoverBackgroundColor: [], label: []  }]
                      };
                      setTransportistas(returnVal);

                      returnVal.map((t, index)=>{
                        dat.labels.push(t.nombre);

                        dat.datasets[0].data.push(t.totmovsem);
                        //dat.datasets[0].label.push(corredor.nombre);
                        let color= "#000000".replace(/0/g, () => (~~(Math.random() * 16)).toString(16));
                        dat.datasets[0].backgroundColor.push(color);
                        dat.datasets[0].hoverBackgroundColor.push(color+90);
                      });
                      setDatos(dat);
                    }
                    if( stateVar  === 'otd_x_semana' && returnVal){
                      setOtds(returnVal);
                      console.log(returnVal);
                    }
//mros
                    if( stateVar  === 'datos_grafica' && returnVal){
                      setResultadoApi(returnVal);
                      console.log(returnVal);
                      console.log(resultado_api);

                     let labels = [];
                     let data = [];
                     returnVal.forEach(element => {
                       
                       let a= (element.split(","));
                       data.push(a[0]);
                       labels.push(a[1]);
                       console.log(a);
                     });
                      let customLabels = labels.map((label,index) =>`${label}: ${data[index]}`)
                       setCharData (  {
                        labels: customLabels,
                        datasets: [
                          {
                            label: "Estatus de placas",
                            backgroundColor: [
                              "#83ce83",
                              "#f96a5d",
                              "#6800B4",
                              "#00A6B4",
                              "#83ce83",
                              "#f96a5d",
                              "#6800B4",
                              "#00A6B4",
                              "#959595",
                              "#83ce83",
                              "#f96a5d",
                              "#6800B4",
                              "#00A6B4",
                              "#959595",
                              "#959595",
                            ],
                            data: data,
                          },
                        ],});


                    }

                } break;
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }/*
    Date.prototype.getWeekNumber = function(){
      var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
      var dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
    };*/
}


export default MovimientosXtransportistaSemanalPage;
