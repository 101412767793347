import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
class  Configuracion extends Component {
  constructor(props) {
    super(props);
    this.state = { 
                      id: "",
                  accion: this.props.accion, //'nuevo'// modificar, nuevo
                  plataformaCorreo:[],
                  user: "",
                  password: "",
                  correo: ""
                 };
    this.plataformaCorreo=[];
    this.valid={user: false, password: false, correo: false};
  }
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {}
            break;
          case "post":
            {
              if (stateVar === "plataformaCorreo"){ 
                if(returnVal.idCorreoConfiguracion){ //this.setCorreoPlataforma= returnVal[0]; 
                  //this.plataformaCorreo={ "idPlataforma": this.props.elementos.id, "idCorreoConfiguracion": this.configuracion };
                  this.getfuntion("post", this.plataformaCorreo, "", "correoPlataformas","plataformaCorreo")
                  this.setState({accion: "modificar"});
                  this.props.setCorreoPlataforma(this.plataformaCorreo);
                }else{ alert("error intenta de nuevo ..!") }
              }
            }
            break;
          case "put":
            {
              if (stateVar === "configuracion"){ 
                if(returnVal.idCorreoConfiguracion){ this.plataformaCorreo= returnVal; }else{ alert("error intenta de nuevo ..!") }
              }
            }
            break;
          case "delete":
            {
            }
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }
  componentDidMount =() => {    
    if(this.state.accion !== 'nuevo'){
      if(this.props.getCorreoPlataforma){
        this.valid={user: true, password: true, correo: true};
        this.plataformaCorreo= this.props.getCorreoPlataforma; 
        this.setState({ plataformaCorreo: this.props.getCorreoPlataforma });

        this.setState({user: this.plataformaCorreo.usuario });                  
        this.setState({password: this.plataformaCorreo.password});  
        this.setState({correo: this.plataformaCorreo.correo});
        this.setState({accion: "modificar"});
      }else{
        this.valid={user: false, password: false, correo: false};
        this.plataformaCorreo={"usuario": "", "password": "", "correo": "", "idPlataforma": this.props.elementos.id };
        this.setState({accion: "nuevo"});
      }
    }else{
      this.valid={user: false, password: false, correo: false};
      this.plataformaCorreo={"usuario": "", "password": "", "correo": "", "idPlataforma": this.props.elementos.id };
      this.setState({accion: "nuevo"});
    }
  }
  
  isValid=(campo) =>{
    switch (campo) {
      case "user": return this.valid.user;
        break;
      case "password": return this.valid.password;
        break;
      case "correo": return this.valid.correo;
        break;
      default:
        break;
    }
  }
  changeUsuario =(event) =>{
    this.setState({user: event.target.value})
    if(event.target.value.length > 3 ){
      this.valid.user= true;
    }else{ 
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.user= false;
      } 
    }
    this.plataformaCorreo.usuario= event.target.value;
  }
  changePassword =(event) =>{
    this.setState({ password: event.target.value })
    if(event.target.value.length > 5 ){
      this.valid.password= true;
    }else{ 
      if(event.target.value.length > 0 && event.target.value.length <= 5){
        this.valid.password= false;
      } 
    }
    this.plataformaCorreo.password= event.target.value;  
  }
  changeCorreo =(event) =>{
    var mail= false; var arroba= false; var domain= false;
    var mailChart= ""; var arrobaChart= ""; var domainChart= "";
    var temp= event.target.value;
    var count= 0;
    this.setState({ correo: event.target.value })

    for (var i = 0; i < temp.length; i++) {
      if( temp.charAt(i) == " "){
        this.valid.password= false;
        break;
      }
      if(!arroba && !domain && temp.charAt(i) == "@"){ 
        arrobaChart=arrobaChart+temp.charAt(i); arroba= true;  
        if(mailChart.length > 2 ){ mail=true }
      }
      if(!arroba && !domain){ mailChart= mailChart+temp.charAt(i); }
      if(mail && arroba && temp.charAt(i) !== "@"){ domainChart= domainChart+temp.charAt(i); }
    }
    if(domainChart.includes(".") && domainChart.length > 4 && domainChart.charAt(0) !== "." && domainChart.charAt(domainChart.length-1) !== "." ){ domain= true; }
    if(mail && arroba && domain ){
      this.valid.correo= true;
    }else{ 
      this.valid.correo= false;
    }
    this.plataformaCorreo.correo= event.target.value;
  }
  crud_put =() =>{  
    this.getfuntion("put", this.plataformaCorreo, "", "correoPlataformas","correoPlataformas")
  }
  crud_post =() =>{
    this.getfuntion("post", this.plataformaCorreo, "", "correoPlataformas","correoPlataformas")
  }
  disableSave =() =>{
    let band= false;
    Object.keys(this.valid).forEach(key =>{ if( !this.valid[key]){ band=true; } });
    if(band){ return true }else{ return false }
  }
  render(){
    return (
      <div>
        {/**
          *   Configuracion de correo
          */
        }
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Configuración"
                  isCollapsible={ false }
                  //isClosable
                  isCollapsed={ false}
                  //options={ }
                  body={
                  <div>
                    <Grid.Row>
                          <Grid.Col sm={12} md={6}>
                            <Form.Group label="User">
                              <Form.Input
                                type="text" name="user" feedback="Al menos 4 caracteres" value={this.state.user} placeholder="Ingresa usuario ..."
                                invalid={!this.isValid("user") && this.state.user.length > 0} valid={this.isValid("user") && this.state.user.length > 0 }
                                onChange={this.changeUsuario}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col sm={12} md={6}>
                            <Form.Group label="Password">
                              <Form.Input
                                type="password" name="contraseña" feedback="al menos 6 caracteres" value={this.state.password} placeholder="Password..."
                                invalid={!this.isValid("password")&& this.state.password.length > 0} valid={this.isValid("password") && this.state.password.length > 0 }
                                onChange={this.changePassword}
                              />
                            </Form.Group>
                          </Grid.Col>
                    </Grid.Row> 
                    <Grid.Row>
                      <Grid.Col sm={12} md={6}>
                        <Form.Group label="Correo">
                          <Form.Input
                            type="text" name="correo" feedback="Correo invalido" value={this.state.correo} placeholder="mail@xxx.xx"
                            invalid={!this.isValid("correo") && this.state.correo.length > 0} valid={this.isValid("correo") && this.state.correo.length > 0 }
                            onChange={this.changeCorreo}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={12} md={12}>
                        <div className="float-right">
                            <span className="badge">
                                <Button size="sm" RootComponent="a" color="primary" disabled= { this.disableSave() }
                                    onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() }
                                >
                                    <span className="badge"><Icon link={true} name="save" />Guardar</span>
                                </Button>
                            </span>
                        </div>
                      </Grid.Col>
                    </Grid.Row> 
                  </div>
                }
                />
              </Grid.Col>
            
            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default Configuracion
