import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import ModalLayout from "../../../../components/ModalLayout.react";
import { Unidades } from "../";
import { Operadores } from "../";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { TransportistasForm } from '../../../';
class  TransportistasTerrestres extends Component {
  constructor(props) {
    super(props);
                this.state = {
                                            id  : "",
                                        accion  : "post", //'nuevo'// modificar, nuevo
                                        nombre  : "",
                                   nombreCorto  : "",
                                contactoNombre  : "",
                                     telefono1  : "",
                                     telefono2  : "",
                                        correo  : "",
                                        search  : "",
                                transportistas  : [],
                                      Unidades  : [],
                                    Operadores  : [],
                                       gpsFijo  : [],
                                  hiddenModals  : false,
                              };
      this.transportistas  = [];
            this.Unidades  = [];
          this.Operadores  = [];
    this.TransportistaObj  = { "nombre": "", "nombreCorto": "", "contactoNombre": "", "telefono1": "", "telefono2": "", "correo": "" , "tipo":2};
               this.valid  = {nombre: false, nombreCorto: false, contactoNombre: false, correo: false, telefono1: false};
this.plataformasTransportistas=[];
  }
  componentWillMount =() =>{
    if(this.state.accion !== 'nuevo'){
      this.getfuntion("get", "", "", "transportistas/plataforma/"+this.props.elementos.id,"transportistas");
      this.getfuntion("get", "", "", "plataformasTransportistas/Plataformas/"+this.props.elementos.id,"plataformasTransportistas");
      this.getfuntion("get", "", "", "unidades/plataforma/"+this.props.elementos.id, "Unidades");
      console.log("WillMount transportistas Terrestres: this.props.elementos.id");
      console.log(this.props.elementos.id);

      this.getfuntion("get", "", "", "operadores", "Operadores");
      //this.getfuntion("get", "", "", "operadores/transportista/"+this.props.elementos.id, "Operadores");
      this.getfuntion("get", "", "", "gpsFijo/plataforma/"+this.props.elementos.id, "gpsFijo");
    }else{
    }
  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
    }else{
    }
  }
  hiddenmodal =() => {
    this.setState({hiddenModals: !this.state.hiddenModals});
  }
  isValid=(campo) =>{
    switch (campo) {
      case "nombre": return this.valid.nombre;
        break;
      case "nombreCorto": return this.valid.nombreCorto;
        break;
      case "contactoNombre": return this.valid.contactoNombre;
        break;
      case "correo": return this.valid.correo;
        break;
      case "telefono1": return this.valid.telefono1;
        break;
      default:
        break;
    }
  }
  changeNombre =(event) =>{
    this.setState({nombre: event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.nombre= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.nombre= false;
      }
    }
    this.TransportistaObj.nombre = event.target.value;
  }
  changeNombreCorto =(event) =>{
    this.setState({nombreCorto: event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.nombreCorto= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.nombreCorto= false;
      }
    }
    this.TransportistaObj.nombreCorto = event.target.value;
  }
  changeContactoNombre =(event) =>{
    this.setState({contactoNombre: event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.contactoNombre= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.contactoNombre= false;
      }
    }
    this.TransportistaObj.contactoNombre = event.target.value;
  }
  changeCorreo =(event) =>{
    var mail= false; var arroba= false; var domain= false;
    var mailChart= ""; var arrobaChart= ""; var domainChart= "";
    var temp= event.target.value;
    var count= 0;
    this.setState({ correo: event.target.value })

    for (var i = 0; i < temp.length; i++) {
      if( temp.charAt(i) == " "){
        this.valid.password= false;
        break;
      }
      if(!arroba && !domain && temp.charAt(i) == "@"){
        arrobaChart=arrobaChart+temp.charAt(i); arroba= true;
        if(mailChart.length > 2 ){ mail=true }
      }
      if(!arroba && !domain){ mailChart= mailChart+temp.charAt(i); }
      if(mail && arroba && temp.charAt(i) !== "@"){ domainChart= domainChart+temp.charAt(i); }
    }
    if(domainChart.includes(".") && domainChart.length > 4 && domainChart.charAt(0) !== "." && domainChart.charAt(domainChart.length-1) !== "." ){ domain= true; }
    if(mail && arroba && domain ){
      this.valid.correo= true;
    }else{
      this.valid.correo= false;
    }
    this.TransportistaObj.correo= event.target.value;
  }

  changeTel1 =(event) =>{
    this.setState({ telefono1: event.target.value })
    if(event.target.value.length == 10){
      this.valid.telefono1= true;
    }else{
      if( event.target.value.length < 10){
        this.valid.telefono1= false;
      }
    }
    this.TransportistaObj.telefono1 = event.target.value;
  }
  changeTel2 =(event) =>{
    this.setState({ telefono2: event.target.value });
    this.TransportistaObj.telefono2 = event.target.value;
  }

  TransportistaEdit =(index) =>{
    let current= this.state.transportistas.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[index];
    this.setState({ nombre: current.nombre });
    this.setState({ nombreCorto: current.nombreCorto });
    this.setState({ contactoNombre: current.contactoNombre });
    this.setState({ telefono1: current.telefono1 });
    this.setState({ telefono2: current.telefono2 });
    this.setState({ correo: current.correo });
    this.TransportistaObj = current;
    this.valid  = {nombre: true, nombreCorto: true, contactoNombre: true, correo: true, telefono1: true};
    this.setState({ accion: "modificar" });
  }

  crud_put =() =>{
    this.getfuntion("put", this.TransportistaObj, "", "transportistas","transportistas");
    this.setState({ nombre: "" });  this.setState({ nombreCorto: "" }); this.setState({ contactoNombre: "" });  this.setState({ telefono1: "" }); this.setState({ telefono2: "" }); this.setState({ correo: "" });
    this.TransportistaObj  = { "nombre": "", "nombreCorto": "", "contactoNombre": "", "telefono1": "", "telefono2": "", "correo": "" , "tipo":2};
    this.valid  = {nombre: false, nombreCorto: false, contactoNombre: false, correo: false, telefono1: false};
    this.setState({ accion: "nuevo" });
  }
  crud_delete =(id) =>{
    for (let item of this.plataformasTransportistas) {
      if( item.transportista.id === id ){
        this.getfuntion("delete", "", item.id, "plataformasTransportistas","plataformasTransportistas");
        break;
      }
    }
    this.getfuntion("delete", "", id, "plataformasTransportistas","plataformasTransportistas");
  }
  crud_post =() =>{
    this.getfuntion("post", this.TransportistaObj, "", "transportistas","transportistas");
    this.setState({ nombre: "" });  this.setState({ nombreCorto: "" }); this.setState({ contactoNombre: "" });  this.setState({ telefono1: "" }); this.setState({ telefono2: "" }); this.setState({ correo: "" });
    this.TransportistaObj  = { "nombre": "", "nombreCorto": "", "contactoNombre": "", "telefono1": "", "telefono2": "", "correo": "" , "tipo":2};
    this.valid  = {nombre: false, nombreCorto: false, contactoNombre: false, correo: false, telefono1: false};
    this.setState({ accion: "nuevo" });
  }
  disableSave =() =>{
    let band= false;
    Object.keys(this.valid).forEach(key =>{ if( !this.valid[key]){ band=true; } });
    if(band){ return true }else{ return false }
  }



  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "transportistas"){ this.transportistas= returnVal; this.setState({ transportistas: returnVal }); }
              if (stateVar === "plataformasTransportistas"){  this.plataformasTransportistas= returnVal; }
              if (stateVar === "gpsFijo") { this.setState({ gpsFijo: returnVal }); }
              if (stateVar === "Unidades") {
                for (let item of returnVal) {
                  if(this.Unidades[item.idTransportista]){
                    this.Unidades[item.idTransportista].push(item);
                  }else{
                    this.Unidades[item.idTransportista]= [];
                    this.Unidades[item.idTransportista].push(item);
                  }
                }
                this.setState({ Unidades:this.Unidades });
                //console.log(this.Unidades);
              }
              if (stateVar === "Operadores") {
                for (let item of returnVal) {
                  if(this.Operadores[item.idTransportista]){
                    this.Operadores[item.idTransportista].push(item);
                  }else{
                    this.Operadores[item.idTransportista]= [];
                    this.Operadores[item.idTransportista].push(item);
                  }
                }
                this.setState({ Operadores:this.Operadores });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "transportistas"){
                  this.transportistas.push(returnVal);
                  this.setState({ transportistas: this.transportistas });
                  this.getfuntion("post", {plataforma: this.props.elementos, transportista: returnVal }, "", "plataformasTransportistas","plataformasTransportistas");
                }
              if (stateVar === "plataformasTransportistas"){ this.plataformasTransportistas.push(returnVal); }
              if (stateVar === "Unidades")                 { this.Unidades[returnVal.idTransportista].push(returnVal); this.setState({ Unidades:this.Unidades }); }
              if (stateVar === "Operadores")               { this.Operadores[returnVal.idTransportista].push(returnVal); this.setState({ Operadores:this.Operadores }); }
            }
            break;
          case "put":
            {
              if (stateVar === "transportistas" && returnVal.id){
                for (let item of this.transportistas) {
                  if( item.id === returnVal.id ){
                    item= returnVal;
                    this.setState({ transportistas: this.transportistas });
                    break;
                  }
                }
              }
              if (stateVar === "Unidades")  {
                for (let item of this.Unidades[obj.idTransportista]) {
                  if( item.id === returnVal.id ){
                    //item= returnVal;
                    //console.log( item );
                    //console.log(item.id +"==="+ returnVal.id +": ")
                    //console.log(this.Unidades );
                    this.setState({ Unidades:this.Unidades });
                    break;
                  }
                }
              }
              if (stateVar === "Operadores")  {
                for (let item of this.Operadores[obj.idTransportista]) {
                  if( item.id === returnVal.id ){
                    //item= returnVal;
                    //console.log( item );
                    //console.log(item.id +"==="+ returnVal.id +": ")
                    //console.log(this.Operadores );
                    this.setState({ Operadores:this.Operadores });
                    break;
                  }
                }
              }
            }
          break;
          case "delete":
            {
              if (stateVar === "plataformasTransportistas"){
                for (let i=0; i< this.plataformasTransportistas.length; i++) {
                  if( this.plataformasTransportistas[i].id === id ){
                    for (let j=0; j< this.transportistas.length; j++) {
                      if( this.transportistas[j].id === this.plataformasTransportistas[i].transportista.id ){ this.transportistas.splice(j, 1); this.setState({ transportistas: this.transportistas }); }
                    }
                    this.plataformasTransportistas.splice(i, 1);
                    break;
                  }
                }
              }
              if (stateVar === "Unidades" && returnVal)  {
                this.Unidades[obj.idTransportista].map((elemento, index) =>{ if (id === elemento.id) this.Unidades[obj.idTransportista].splice(index, 1) })
                this.setState({ Unidades:this.Unidades });
              }
              if (stateVar === "Operadores" && returnVal)  {
                this.Operadores[obj.idTransportista].map((elemento, index) =>{ if (id === elemento.id) this.Operadores[obj.idTransportista].splice(index, 1) })
                this.setState({ Operadores:this.Operadores });
              }
            }
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          //console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    });
  }
  searching =(event) =>{
    this.setState({ search: event.target.value })
  }


  render(){
    return (
      <div>
        {/**
          *   Configuracion de correo
          */
        }
          <div>
            <Grid.Row>
              <Grid.Col>

                <Card title="Transportistas" isCollapsible={ false } isCollapseUnidadesd={ false}
                  body={
                  <div>
                    <Grid.Row>
                    <Grid.Col sm={12} md={12}>
                      <Grid.Col sm={12} md={4} className="float-right">
                        <Form.Input className="mb-3" icon="search" placeholder="Buscar por nombre..." position="append" onChange={this.searching} />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col sm={12} md={2}>
                      <Form.Group label="Nombre">
                        <Form.Input
                          type="text" name="nombreDes" feedback="Al menos 4 caracteres" value={this.state.nombre} placeholder="Ingresa transportista ..."
                          invalid={!this.isValid("nombre") && this.state.nombre.length > 0} valid={this.isValid("nombre") && this.state.nombre.length > 0 }
                          onChange={this.changeNombre}
                        />
                      </Form.Group>
                      <Form.Group label="Nombre Corto">
                        <Form.Input
                          type="text" name="nombreCorto" feedback="Al menos 4 caracteres" value={this.state.nombreCorto} placeholder="Ingresa nombre corto ..."
                          invalid={!this.isValid("nombreCorto") && this.state.nombreCorto.length > 0} valid={this.isValid("nombreCorto") && this.state.nombreCorto.length > 0 }
                          onChange={this.changeNombreCorto}
                        />
                      </Form.Group>
                      <Form.Group label="Contacto Nombre">
                        <Form.Input
                          type="text" name="contactoNombre" feedback="Al menos 4 caracteres" value={this.state.contactoNombre} placeholder="Ingresa nombre del contacto ..."
                          invalid={!this.isValid("contactoNombre") && this.state.contactoNombre.length > 0} valid={this.isValid("contactoNombre") && this.state.contactoNombre.length > 0 }
                          onChange={this.changeContactoNombre}
                        />
                      </Form.Group>
                      <Form.Group label="Correo">
                          <Form.Input
                            type="text" name="correo" feedback="Correo invalido" value={this.state.correo} placeholder="mail@xxx.xx"
                            invalid={!this.isValid("correo") && this.state.correo.length > 0} valid={this.isValid("correo") && this.state.correo.length > 0 }
                            onChange={this.changeCorreo}
                          />
                        </Form.Group>
                      <Form.Group label="Telefono 1">
                        <Form.Input
                          type="number" name="Telefono1" feedback="Telefono Invalido" value={this.state.telefono1} placeholder="Ingresa telefono 1 ..."
                          invalid={!this.isValid("telefono1") && this.state.telefono1 !== "" } valid={this.isValid("telefono1") && this.state.telefono1 !== "" }
                          onChange={this.changeTel1}
                        />
                      </Form.Group>
                      <Form.Group label="Telefono 2">
                        <Form.Input type="number" name="Telefono2" value={this.state.telefono2} placeholder="Ingresa telefono 2 ..." onChange={this.changeTel2}/>
                      </Form.Group>
                      <div className="float-right"><span className="badge">
                        <Button size="sm" RootComponent="a" color="primary" disabled= { this.disableSave() } onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() } >
                         <span className="badge"><Icon link={true} name="save" />Guardar</span>
                        </Button></span>
                      </div>
                    </Grid.Col>
                      <Grid.Col sm={12} md={10}>
                        <Form.Group>
                          <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                            <Table.Header>
                              <Table.ColHeader>ID</Table.ColHeader>
                              <Table.ColHeader>Nombre</Table.ColHeader>
                              <Table.ColHeader>Nombre Corto</Table.ColHeader>
                              <Table.ColHeader>Contacto</Table.ColHeader>
                              <Table.ColHeader>Correo</Table.ColHeader>
                              <Table.ColHeader>Tel 1</Table.ColHeader>
                              <Table.ColHeader>Tel 2</Table.ColHeader>
                              <Table.ColHeader>edit</Table.ColHeader>
                              <Table.ColHeader>Acción</Table.ColHeader>
                              <Table.ColHeader>Unidades</Table.ColHeader>
                              <Table.ColHeader>Operadores</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                              {
                                this.state.transportistas.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                  <Table.Row>
                                    <Table.Col>{ elemento["id"] }</Table.Col>
                                    <Table.Col>{ elemento["nombre"] }</Table.Col>
                                    <Table.Col>{ elemento["nombreCorto"] }</Table.Col>
                                    <Table.Col>{ elemento["contactoNombre"] }</Table.Col>
                                    <Table.Col>{ elemento["correo"] }</Table.Col>
                                    <Table.Col>{ elemento["telefono1"] }</Table.Col>
                                    <Table.Col>{ elemento["telefono2"] }</Table.Col>
                                    <Table.Col>
                                    <ModalLayout  tamanio={"90%"} title="Modificar" name= {<Button pill  size="sm" outline color="primary"><Icon name="truck"/><Icon name="edit"/></Button>}  hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                                        formulario={<TransportistasForm accion="modificar" plataforma={this.props.elementos} getfuntion={this.getfuntion} stor={this.props.stor} elementos={elemento} />} />
                                    </Table.Col>
                                    <Table.Col>
                                      <div className="float-right">
                                        <span className="ml-2">
                                          <Button pill  size="sm" outline color="primary" onClick={ ()=> this.TransportistaEdit(index) }><Icon name="edit"/></Button>
                                        </span>
                                        <span className="ml-2">
                                          <Button pill  size="sm" outline color="primary" onClick={ ()=> this.crud_delete(elemento.id) }><Icon name="trash"/></Button>
                                        </span>
                                      </div>
                                    </Table.Col>
                                    <Table.Col>
                                      <ModalLayout tamanio={"80%"} title= "Modificar"
                                      name=
                                      {
                                        <Button pill size="sm" outline color="primary">
                                          <Icon name="truck"/>
                                          {this.state.Unidades[elemento["id"]]? this.state.Unidades[elemento["id"]].length: 0}
                                        </Button>
                                      }
                                      hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                      formulario=
                                      {
                                        <Unidades accion={'nuevo'} elementos= { this.props.elementos }
                                          idTransportista={ elemento["id"] } stor= { this.props.stor }
                                          Unidades= { this.state.Unidades[elemento["id"]]? this.state.Unidades[elemento["id"]]: []}
                                          gpsFijo= { this.state.gpsFijo} closeModal={this.hiddenmodal} getfuntion={ this.getfuntion }
                                        />
                                      }
                                      />
                                  </Table.Col>
                                  <Table.Col>
                                      <ModalLayout tamanio = {"80%"} title= "Operadores"
                                      name=
                                      {
                                        <Button pill size="sm" outline color="primary">
                                          <Icon name="user"/>
                                          {this.state.Operadores[elemento["id"]]? this.state.Operadores[elemento["id"]].length: 0}
                                        </Button>
                                      }
                                      hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                      formulario=
                                      {
                                        <Operadores accion={'nuevo'} elementos= { this.props.elementos }
                                          idTransportista={ elemento["id"] } stor= { this.props.stor }
                                          Operadores= { this.state.Operadores[elemento["id"]]? this.state.Operadores[elemento["id"]]: []}
                                          closeModal={this.hiddenmodal} getfuntion={ this.getfuntion }
                                        />
                                      }
                                      />
                                  </Table.Col>
                                  </Table.Row>
                                )
                              }
                            </Table.Body>
                          </Table>
                        </Form.Group>

                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tus datos antes de guardar, del lado derecho podras activar o desactivar los diferentes transportistas, y eliminarlos ...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }

                />
              </Grid.Col>

            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default TransportistasTerrestres
