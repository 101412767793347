import React, { Component } from "react";
import { Button,Form,Icon,Grid } from "tabler-react";

class  PrivilegiosForm extends Component {
  constructor(props) {
    super(props);
    this.state = {       id:          "",
                         nombre:      "",
                         descripcion: "",
                         clave:       "",
                         categoria:   "",
                         accion: this.props.accion, //'nuevo'// modificar, nuevo
                         crudbutonEnable: true, // Se desabilita cuando se hace click
                  };
    this.enableSave = this.enableSave.bind(this);
    this.privilegiosject=[];
  }
  isEnable=(Accion)=>{
    let borrar= "privilegios_borrar";
    let actualizar= "privilegios_editar";
    let agregar= "privilegios_agregar";
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "borrar"){ 
        if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.setState({id: this.props.elementos.id });
      this.setState({nombre: this.props.elementos.nombre});
      this.setState({descripcion: this.props.elementos.descripcion});
      this.setState({clave: this.props.elementos.clave});
      if(this.props.elementos.categorias){
        this.setState({categoria: this.props.elementos.categorias.id});
      }
      this.privilegiosject= this.props.elementos;
    }else{
      this.privilegiosject={"id":0, "nombre": "", "descripcion": "", "clave": "", "categorias": { } }
    }
  }
  enableSave =() =>{
    if(this.state.nombre.length > 0 && this.state.descripcion.length > 0 && this.state.clave.length > 0 && this.state.categoria > 0 ){
      return false;
    }else{ return true }
  }
  changeNombre =(event) => {
    this.setState({nombre: event.target.value});
    this.privilegiosject.nombre= event.target.value;
  }

  changeDescripcion = (event) =>{
    this.setState({descripcion: event.target.value});
    this.privilegiosject.descripcion= event.target.value;
  }

  changeClave = (event) =>{
    this.setState({clave: event.target.value});
    this.privilegiosject.clave= event.target.value;
  }

  changeCategoria = (event) =>{
    for (let item of this.props.stor.categorias_object) {
      if(item.id == event.target.value){
        this.privilegiosject.categorias= item;
        break;
      }
    }
     this.setState({categoria:event.target.value})
  }

  delete() {    this.setState({ crudbutonEnable: false });  this.props.getfuntion("delete", "", this.state.id); }
  crud_put() {  this.setState({ crudbutonEnable: false });  this.props.getfuntion("put", this.privilegiosject);}
  crud_post() { this.setState({ crudbutonEnable: false });  this.props.getfuntion("post", this.privilegiosject);}
  render(){
    return (
      <div>
        { this.state.accion === "modificar" ? <h1> { this.props.elementos.nombre } </h1> : null }
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
           <Grid.Row>
             <Grid.Col md={3} lg={3}>
              <Form.Group label="id">
                <Form.Input name="id" disabled type="number" value={this.state.id} />
              </Form.Group>
             </Grid.Col>
             <Grid.Col md={9} lg={9}>
              <Form.Group label="Nombre">
                <Form.Input name="nombre" type="text" value={ this.state.nombre } onChange={this.changeNombre} maxlength="30" />
              </Form.Group>
             </Grid.Col>
             <Grid.Col md={6} lg={6}>
              <Form.Group label="Descripcion">
               <Form.Textarea name="descripcion" type="text" value={ this.state.descripcion } onChange={this.changeDescripcion} maxlength="255"/>
              </Form.Group>
             </Grid.Col>
             <Grid.Col md={6} lg={6}>
              <Form.Group label="Clave">
                <Form.Input name="clave" type="text" value={ this.state.clave } onChange={this.changeClave} maxlength="30"/>
              </Form.Group>
             </Grid.Col>
           </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Form.Group label="Categorias">
          <Form.Select name="Categorias" value= { this.state.categoria } onChange={this.changeCategoria} >
           <option value= { 0 }>Selecciona una Categoria</option>
              { this.props.stor.categorias_object.map((elemento, index) => <option value={elemento["id"]}> {elemento["nombre"]}</option> ) }
           </Form.Select>
        </Form.Group>
        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ?
            <span className="badge">
                <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled= { this.enableSave() || !this.state.crudbutonEnable }
                  onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() }
                >
                  <span className="badge"><Icon link={true} name="save" />Guardar</span>
                </Button>
            </span>
          :null}
          { this.state.accion === "modificar" && this.isEnable("borrar") ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary"
                onClick= { () => this.delete() } disabled={!this.state.crudbutonEnable}
              >
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
          :
          null
          }
        </div>
      </div>
    );
  }
}
export default PrivilegiosForm;
