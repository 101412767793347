import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import './ModalLayout.react.css';

var appElement = document.getElementById('root');
Modal.setAppElement(appElement);
class ModalLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false
                  };
    this.modalIsOpen= false;
    };
    customStyles = {
      content : {
        position: 'relative',
             top: '120px',
           width: window.screen.width > 900? this.props.tamanio: window.screen.width-20,
          height: 'auto',
          margin: 'auto',
      }
  }
  componentWillUpdate=() => {
    if(this.modalIsOpen===true && this.props.hiddenModal===true){
      this.setState({modalIsOpen: false});
      this.modalIsOpen= false;
      this.props.Fclose();
    }
    if( !this.modalIsOpen && this.props.open  ){
      this.openModal();
      this.props.setOpen(false);
    }
  }
  openModal = () => {
    //this.props.Fclose();
    this.setState({modalIsOpen: true});
    this.modalIsOpen= true;
  }
  closeModal = () => {
    this.setState({modalIsOpen: false});
    this.modalIsOpen= false;
  }
  handleModalCloseRequest = () => {
    this.setState({modalIsOpen: false});
    this.modalIsOpen= false;
  }
  handleSaveClicked = (e) => {
    alert('Save button was clicked');
  }
  render() {
    return (
      <>
        <label className="transparent-input thumbnail" onClick={this.openModal} >
         { this.props.name }
        </label>
        <Modal
          closeTimeoutMS={50}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.handleModalCloseRequest}
          className="Modal"
          overlayClassName="Overlay"
          style = {this.customStyles}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title"> { this.props.title }</h4>
              <button type="button" className="btn btn-dark fa fa-close outline pill" onClick={this.handleModalCloseRequest}></button>
              {/**
              <button type="button" className="close" onClick={this.handleModalCloseRequest}>
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
               */}

            </div>
            <div className="modal-body">
              { this.props.formulario }
            </div>
            { /**
            <div className="modal-footer">

            </div>
             */ }
          </div>
        </Modal>
      </>
    );
  }
}

//ReactDOM.render(<ModalLayout/>, appElement);
ReactDOM.render(<ModalLayout/>, document.getElementById("root"));
export default ModalLayout;
