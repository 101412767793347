import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../herramientas/Crud_Catalogos";
import Select from "react-select";

/**
 * Date tools
 */
//import { timeToDate, timeToDatetime, timeToGMTS, timeToISOS, timeToJSON, timeToLocaleTime,
//         timeToLocale, timeToString, timeGetTime, timeToUTC, timeToDateFill } from "../../../../herramientas/DateFormat";
import { from } from "rxjs";
class Alertas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AlertasCategoria: { value: 0, label: "Seleccione una Categoria " },
      AlertasTipo: { value: 0, label: "Seleccione un Tipo " },
      incidencias: [],
      incidencia: { value: 0, label: "Elige una opción" },
      comentario: "",
      accion: "post", //'nuevo'// modificar, nuevo
      plataformasAlertas: [],
      search: "",
      crear: true, // true nuevo, false editar
    };
    this.plataformasAlertas = [];
    this.AlertasObj = {
      idPlataforma: this.props.elementos.id,
      alertaCategoria: null,
      alertaTipo: null,
      idIncidencia: null,
      comentario: ""

    };
    this.valid = { AlertasCategoria: false, AlertasTipo: false };
    this.AlertasCategoria = [];
    this.AlertasTipo = [];
  }
  componentDidMount = () => {
    this.props.stor.alertasTipos_object.map((elemento, index) => {
      this.AlertasTipo.push({ value: elemento.id, label: elemento.nombre });
    });
    this.props.stor.alertasCategorias_object.map((elemento, index) => {
      this.AlertasCategoria.push({
        value: elemento.id,
        label: elemento.nombre,
      });
    });

    if (this.state.accion !== "nuevo") {
      this.getfuntion(
        "get",
        "",
        "",
        "plataformasAlertas/plataforma/" + this.props.elementos.id,
        "plataformasAlertas"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "incidencias/idplataforma/" + this.props.elementos.id,
        "incidencias"
      );
    } else {
    }
    //this.AlertasTipo.push({ value:0, label:"Seleccione un Tipo " })
    //this.AlertasCategoria.push({ value:0, label:"Seleccione una Categoria " })
  };
  isValid = (campo) => {
    switch (campo) {
      case "AlertasCategoria":
        return this.valid.AlertasCategoria;
        break;
      case "AlertasTipo":
        return this.valid.AlertasTipo;
        break;
      default:
        break;
    }
  };
  changeAlertasCategoria = (event) => {
    this.setState({ AlertasCategoria: event });
    this.AlertasObj.alertaCategoria = event.value;

    if (this.AlertasObj.alertaCategoria > 0) {
      var ban = false;
      this.state.plataformasAlertas.map((elemento) => {
        if (
          elemento.alertaCategoria === this.AlertasObj.alertaCategoria &&
          elemento.alertaTipo === this.AlertasObj.alertaTipo
        ) {
          ban = true;
        }
      });
      if (this.AlertasObj.alertaTipo > 0) {
        if (ban) {
          this.valid.AlertasCategoria = false;
        } else {
          this.valid.AlertasCategoria = true;
          this.valid.AlertasTipo = true;
        }
      }
    } else {
      this.valid.AlertasCategoria = false;
    }
  };
  changeAlertasTipo = (event) => {
    this.setState({ AlertasTipo: event });
    this.AlertasObj.alertaTipo = event.value;
    if (this.AlertasObj.alertaTipo > 0) {
      var ban = false;
      this.state.plataformasAlertas.map((elemento) => {
        if (
          elemento.alertaCategoria === this.AlertasObj.alertaCategoria &&
          elemento.alertaTipo === this.AlertasObj.alertaTipo
        ) {
          ban = true;
        }
      });
      if (this.AlertasObj.alertaCategoria > 0) {
        if (ban) {
          this.valid.AlertasTipo = false;
        } else {
          this.valid.AlertasTipo = true;
          this.valid.AlertasCategoria = true;
        }
      }
    } else {
      this.valid.AlertasTipo = true;
    }
  };
  changeIdIncidencia = (event) => {
    this.AlertasObj.idIncidencia = event.value;
    this.setState({ incidencia: event });
  };
  changeComentario = (e) => {
    if( e.target.value.length <= 1000){
      this.AlertasObj.comentario= e.target.value;
      this.setState({ comentario: e.target.value});
    }
  }
  getIdIncidenciaById = (id) => {
    if (id === null) {
      return "";
    }
    for (let item of this.state.incidencias) {
      if (item.value === id) {
        return item.label;
      }
    }
    return id;
  };
  getNombreCategoriaById = (id) => {
    if (id === null) {
      return "";
    }
    for (let item of this.props.stor.alertasCategorias_object) {
      if (item.id === id) {
        return item.nombre;
      }
    }
    return id;
  };
  getNombreTipoById = (id) => {
    if (id === null) {
      return "";
    }
    for (let item of this.props.stor.alertasTipos_object) {
      if (item.id === id) {
        return item.nombre;
      }
    }
    return id;
  };
  disableSave = () => {
    let band = false;
    Object.keys(this.valid).forEach((key) => {
      if (!this.valid[key]) {
        band = true;
      }
    });
    if (band) {
      return true;
    } else {
      return false;
    }
  };
  searching = (event) => {
    this.setState({ search: event.target.value });
  };

  crud_put = () => {
    this.getfuntion(
      "put",
      this.AlertasObj,
      "",
      "plataformasAlertas",
      "plataformasAlertas"
    );
    this.valid = { AlertasCategoria: false, AlertasTipo: false };
    this.setState({ accion: "nuevo" });
    this.limpiar();
  };
  crud_post = () => {
    this.getfuntion(
      "post",
      this.AlertasObj,
      "",
      "plataformasAlertas",
      "plataformasAlertas"
    );
    this.valid = { AlertasCategoria: false, AlertasTipo: false };
    this.setState({ accion: "nuevo" });
    this.limpiar();
  };

  crud_delete = (idPlataforma, categoria, tipo) =>
    this.getfuntion(
      "delete",
      "",
      idPlataforma + "/" + categoria + "/" + tipo,
      "plataformasAlertas",
      "plataformasAlertas",
      "",
      categoria,
      tipo
    );

  guardarAlerta = () => {
    if (this.state.crear) {
      this.crud_post();
    } else {
      this.crud_put();
    }
  };
  setEdit = (obj) => {
    this.AlertasObj = obj;
    for (let item of this.AlertasCategoria) {
      if (item.value === obj.alertaCategoria) {
        this.setState({ AlertasCategoria: item });
      }
    }
    for (let item of this.AlertasTipo) {
      if (item.value === obj.alertaTipo) {
        this.setState({ AlertasTipo: item });
      }
    }
    for (let item of this.state.incidencias) {
      if (item.value === obj.idIncidencia) {
        this.setState({ incidencia: item });
      }
    }
    this.setState({ comentario: obj.comentario? obj.comentario: ""});
    this.setState({ crear: false });
    this.valid = { AlertasCategoria: true, AlertasTipo: true };
  };
  limpiar = () => {
    this.setState({
      AlertasCategoria: { value: 0, label: "Selecciona una opción" },
    });
    this.setState({
      AlertasTipo: { value: 0, label: "Selecciona una opción" },
    });
    this.setState({ incidencia: { value: 0, label: "Selecciona una opción" } });
    this.setState({ crear: true }); //se cambia a crear
    this.AlertasObj = {
      idPlataforma: this.props.elementos.id,
      alertaCategoria: null,
      alertaTipo: null,
      idIncidencia: null,
      comentario: ""
    };
    this.valid = { AlertasCategoria: false, AlertasTipo: false };
  };
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = "",
    categoria = "",
    tipo = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "loquequieras",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        if (returnVal) {
          switch (metodo) {
            case "get":
              {
                if (stateVar === "plataformasAlertas") {console.log(returnVal)
                  this.plataformasAlertas = returnVal;
                  this.setState({ plataformasAlertas: returnVal });
                }
                if (stateVar === "incidencias") {
                  let incidenciasTem = [];
                  returnVal.map((inc, index) =>
                    incidenciasTem.push({ value: inc.id, label: inc.nombre })
                  );
                  this.setState({ incidencias: incidenciasTem });
                }
              }
              break;
            case "post":
              {
                if (stateVar === "plataformasAlertas") {
                  this.plataformasAlertas.push(returnVal);
                  this.setState({
                    plataformasAlertas: this.plataformasAlertas,
                  });
                }
              }
              break;
            case "put":
              {
                if (stateVar === "plataformasAlertas" && returnVal.alertaTipo) {
                  for (let item of this.plataformasAlertas) {
                    if (
                      item.idPlataforma +
                        "/" +
                        item.alertaCategoria +
                        "/" +
                        item.alertaTipo ===
                      obj.idPlataforma +
                        "/" +
                        obj.alertaCategoria +
                        "/" +
                        obj.alertaTipo
                    ) {
                      item = returnVal;
                      this.setState({
                        plataformasAlertas: this.plataformasAlertas,
                      });
                      break;
                    }
                  }
                }
              }
              break;
            case "delete":
              {
                if (stateVar === "plataformasAlertas") {
                  for (let i = 0; i < this.plataformasAlertas.length; i++) {
                    if (
                      this.plataformasAlertas[i].idPlataforma +
                        "/" +
                        this.plataformasAlertas[i].alertaCategoria +
                        "/" +
                        this.plataformasAlertas[i].alertaTipo ===
                      id
                    ) {
                      this.plataformasAlertas.splice(i, 1);
                      this.setState({
                        plataformasAlertas: this.plataformasAlertas,
                      });
                      break;
                    }
                  }
                } //End if stateVar
              }
              break;
            default:
              break;
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err);
        }
      });
  };
  render() {
    return (
      <div>
        <div>
          <h2>{this.state.crear ? "Nuevo" : "Editar"}</h2>
          <Grid.Row>
            <Grid.Col>
              <Card
                title="Alertas"
                isCollapsible={false}
                isCollapsed={false}
                body={
                  <div>
                    <Grid.Row>
                      {/*
                    <Grid.Col sm={12} md={12}>
                      <Grid.Col sm={12} md={4} className="float-right">
                        <Form.Input className="mb-3" icon="search" placeholder="Buscar Categoria..." position="append" onChange={this.searching} />
                      </Grid.Col>
                    </Grid.Col>*/}
                      <Grid.Col sm={12} md={4}>
                        <Form.Group label="Categoria">
                          <Select
                            value={this.state.AlertasCategoria}
                            onChange={this.changeAlertasCategoria}
                            options={this.AlertasCategoria}
                          />
                        </Form.Group>

                        <Form.Group label="Tipo">
                          <Select
                            value={this.state.AlertasTipo}
                            onChange={this.changeAlertasTipo}
                            options={this.AlertasTipo}
                          />
                        </Form.Group>
                        <Form.Group label="Incidencia (crea incidencia y envia correo), no requerido">
                          <Select
                            value={this.state.incidencia}
                            onChange={this.changeIdIncidencia}
                            options={this.state.incidencias}
                          />
                        </Form.Group>
                        <Form.Group
                          label={
                            <Form.Label aside={this.state.comentario.length + "/1000"}>
                              Comentario de la incidencia
                            </Form.Label>
                          }
                        >
                          <Form.Textarea
                            value={this.state.comentario}
                            name="nota"
                            onChange={this.changeComentario}
                            placeholder={"Escribe aqui el comentario para la incidencia "+ this.state.incidencia.label}
                            rows={6}
                          />
                        </Form.Group>
                        <div className="float-right">
                          <span class="badge">
                            <Button
                              size="sm"
                              RootComponent="a"
                              color="danger"
                              onClick={() => this.limpiar()}
                            >
                              <span class="badge">
                                <Icon link={true} name="x" />
                              </span>
                            </Button>
                          </span>
                        </div>
                        <div className="float-right">
                          <span class="badge">
                            <Button
                              size="sm"
                              RootComponent="a"
                              color="primary"
                              disabled={this.disableSave()}
                              onClick={this.guardarAlerta}
                            >
                              <span class="badge">
                                <Icon link={true} name="save" />
                                Guardar
                              </span>
                            </Button>
                          </span>
                        </div>
                      </Grid.Col>
                      <Grid.Col sm={12} md={8}>
                        <Form.Group>
                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                          >
                            <Table.Header>
                              <Table.ColHeader>Categoria</Table.ColHeader>
                              <Table.ColHeader>Tipo</Table.ColHeader>
                              <Table.ColHeader>Incidencia</Table.ColHeader>
                              <Table.ColHeader>Editar</Table.ColHeader>
                              <Table.ColHeader>Revover</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                              {this.state.plataformasAlertas.map(
                                (elemento, index) => (
                                  <Table.Row>
                                    <Table.Col>
                                      {this.getNombreCategoriaById(
                                        elemento["alertaCategoria"]
                                      )}
                                    </Table.Col>
                                    <Table.Col>
                                      {this.getNombreTipoById(
                                        elemento["alertaTipo"]
                                      )}
                                    </Table.Col>
                                    <Table.Col>
                                      {this.getIdIncidenciaById(
                                        elemento["idIncidencia"]
                                      )}
                                    </Table.Col>
                                    <Table.Col>
                                      <Button
                                        pill
                                        size="sm"
                                        outline
                                        color="primary"
                                        onClick={() => this.setEdit(elemento)}
                                      >
                                        <Icon name="edit" />
                                      </Button>
                                    </Table.Col>
                                    <Table.Col>
                                      <Button
                                        pill
                                        size="sm"
                                        outline
                                        color="danger"
                                        onClick={() =>
                                          this.crud_delete(
                                            elemento.idPlataforma,
                                            elemento.alertaCategoria,
                                            elemento.alertaTipo
                                          )
                                        }
                                      >
                                        {" "}
                                        <Icon name="trash" />
                                      </Button>
                                    </Table.Col>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tus datos antes de guardar, del lado
                        derecho podras activar o desactivar las diferentes
                        alertas, y eliminarlas ...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }
              />
            </Grid.Col>
          </Grid.Row>
        </div>
      </div>
    );
  }
}

export default Alertas;
