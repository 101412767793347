///////////////////////////////////////////////////////////////////////////////////////////////////////7
import React, { Component } from "react";
import TabsLayout  from "../../components/TabsLayout.react";
import {  InformeForm, InformesFuncionalidades } from "./Formularios";
import { from } from "rxjs";

class  Informe extends Component {
  constructor(props) {
    super(props);
            this.state = {
                          currentTap: 0,
                                  id: "",
                              accion: this.props.accion, //'nuevo'// modificar,
                                 Otd: [],
                               ready: false,
                        };
                          this.tabs = [];
                                  /**
                                   * Tab's
                                   */
                      this.InformeF =         { title: this.props.elementos? this.props.elementos.nombre:"",   icon:"book-open", panel: <InformeForm          accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/> };
                      this.Funcionalidades =  { title: "Columnas",                                             icon:"list",  panel: <InformesFuncionalidades  accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor } plataforma={ this.props.plataforma } /> };

  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){ this.setState({id: this.props.elementos.id}); }else{ }

  }
  componentWillMount =() =>{
    if(this.state.accion !== 'nuevo'){ this.setState({id: this.props.elementos.id});
            this.tabs= [ this.InformeF, this.Funcionalidades ];
    }else{
      this.tabs= [ this.InformeF ];

    }
  }
  selectedTab = (index) =>{ this.setState({ currentTap:index }) }
  render(){
    return (
      <div>
        <TabsLayout tabs={ this.tabs } selectedTab= { this.state.currentTap } selectTab= { this.selectedTab } />
      </div>
    )
  }
}

export default Informe

