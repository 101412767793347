import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { List, Form, Loader } from "tabler-react";
import { api_raiz } from '../../../../config/config';

const Checklist = ({ movementId }) => {

  const [checklist, setChecklist] = useState();

  const [error, setError] = useState();

  const [changeError, setChangeError] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const apiClient = useMemo(() => axios.create({
    baseURL: api_raiz+"checklist",
  }), []);

  useEffect(() => {
    getChecklist()
  }, [])

  const getChecklist = useCallback(
    async function () {
      try {
        setIsLoading(true)
        const TOKEN = sessionStorage.getItem('tok');
        const response = await apiClient.get("/obtener-por-movimiento",
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
            params: {
              movementId
            },
          }
        )
        const checkStatus = {}
        response.data.checks.forEach(check => {
          if (checkStatus[check.status]) {
            checkStatus[check.status].push(check)
          }
          else {
            checkStatus[check.status] = []
            checkStatus[check.status].push(check)
          }
        })
        if (response.data.id === 0) {
          throw new Error();
        } else {
          setChecklist({ ...response.data, checkStatus });
        }
      } catch (error) {
        setError("Sin Checklist en SkyLogistic")
      } finally {
        setIsLoading(false)
      }
    }
    , []);

  const changeStatus = useCallback(async (checklistId) => {
    setChangeError("")
    try {
      const TOKEN = sessionStorage.getItem('tok');
      const response = await apiClient.post("/cambiar-estatus-checklist", {
        checklistId
      },
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          }
        }
      )
      const checkStatus = {}
      response.data.checks.forEach(check => {
        if (checkStatus[check.status]) {
          checkStatus[check.status].push(check)
        }
        else {
          checkStatus[check.status] = []
          checkStatus[check.status].push(check)
        }
      })
      if (response.data.id === 0) {
        throw new Error();
      } else {
        setChecklist({ ...response.data, checkStatus });
      }

    } catch (error) {
      setChangeError("Error cambiando estatus")
    }
  }
    , [])




  return (
    <div className="mt-1">
      {!error && checklist && <List.Group>
        <List.GroupItem > Tipo de checklist: {checklist.checklistType.name}</List.GroupItem>
        <List.GroupItem > Estado: {checklist.status}</List.GroupItem>
        <List.GroupItem > Inicio: {new Date(+checklist.start).toLocaleTimeString() + " " + new Date(+checklist.start).toLocaleDateString()}</List.GroupItem>
        <List.GroupItem > Final: {new Date(+checklist.end).toLocaleTimeString() + " " + new Date(+checklist.end).toLocaleDateString()}</List.GroupItem>
        {Object.keys(checklist.checkStatus).map((status) => {
          return <React.Fragment key={status}>
            <List.GroupItem> Checks estatus: {status}: </List.GroupItem>
            <List.Group>
              {checklist.checkStatus[status].map((check) => {
                return <List.GroupItem key={check.id}>{check.checkType.name.charAt(0).toUpperCase()
                  + check.checkType.name.slice(1)}</List.GroupItem>
              })}
            </List.Group>
          </React.Fragment>
        })}
        <section className='mt-4'>
          <List.Group>
            <List.GroupItem> Cambiar estatus
              <Form.Switch
                type="Checkbox"
                name="toggle"
                className="ml-4"
                checked={checklist.status === "completado" || checklist.status === "permitido"}
                onChange={() => {
                  changeStatus(checklist.id)
                }}
              />
            </List.GroupItem>
            {changeError && <List.GroupItem> {changeError}</List.GroupItem>}
          </List.Group>

        </section>
      </List.Group>
      }
      {error && <h5>{error}</h5>}
      {isLoading && <Loader />}
    </div>
  )
}
export default Checklist;