import React, { Component,useState, useEffect } from "react";
import { List, Button, Icon, Card, Table, Grid, Form } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos.js';
 
class Unidades extends Component {
 // static listaUsuarios;
  constructor(props){
    super(props);
    this.state = {
      imei: "",
      id_gg_app: null,
      id_gg_tag: null,
      id_gg_user: null,
      id_gg_device: null,
      idLineaTransporte: this.props.transportista.id,
      unidades:[],
      unidad: {}
          
    };
    this.unidadObj ={
       "imei": "",
      "id_gg_app": null,
      "id_gg_tag": null,
      "id_gg_user": null,
      "id_gg_device": null,
      "idLineaTransporte": this.props.lineaTransporte.id,
      "unidad": null,//objeto,
    };
    this.metodo = "post";
  }
  agregarReaccion = () => {
     
     Crud_Catalogos("unidadesGerenciador", "", this.metodo==='post'?"post":"put", "", this.unidadObj, "", "", this.props.hiddenmodal, "")
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "unidades") {
             // this.setState({ latitud:  returnVal.latitud });
             // this.setState({ longitud: returnVal.longitud });
             this.setState({unidades: returnVal})
            }
            if (stateVar === "gps") {
              this.setState({imei: returnVal[0]?returnVal[0].imei:''});
              this.unidadObj.imei = returnVal[0]?returnVal[0].imei:'';
             }
             if (stateVar === "unidadGerenciador") {
               if(returnVal.id){
                this.metodo="put"; 
                this.setState({imei: returnVal.imei})
                this.setState({id_gg_app:returnVal.id_gg_app});
                this.setState({id_gg_tag:returnVal.id_gg_tag});
                this.setState({id_gg_user:returnVal.id_gg_user});
                this.setState({id_gg_device:returnVal.id_gg_device});
                this.setState({idLineaTransporte:returnVal.idLineaTransporte});                
                this.unidadObj=returnVal;
               }
               else{
                this.unidadObj ={
                  "imei": "",
                 "id_gg_app": null,
                 "id_gg_tag": null,
                 "id_gg_user": null,
                 "id_gg_device": null,
                 "idLineaTransporte": this.props.lineaTransporte.id,
                 "unidad": obj,//objeto,
                };
                this.metodo="post"; 
                this.getfuntion("get", "", "", "gpsFijos/unidad/"+obj.id, "gps"); 
               }

             }

          }
        case "post":
          {
            if (stateVar === "Reaccion") { this.props.hiddenmodal(); }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response);  } else {console.log("Error desconocido ...." + err.response); }
    })
  }



  changenombre = (event) => this.setState({nombre: event.target.value});
  changeimei = (event) => this.setState({imei: event.target.value});
  changeplacas = (event) => this.setState({placas: event.target.value});
  changeidlineatransporte = (event) => this.setState({idLineaTransporte: event.target.value});
  changeneconomico = (event) => this.setState({neconomico: event.target.value});
  changetipo = (event) => this.setState({tipo: event.target.value});
  changeoperador = (event) => this.setState({operador: event.target.value});
  changetelefono = (event) => this.setState({telefono: event.target.value});
  changeIdGgApp = (event) => {
    this.setState({id_gg_app: event.target.value});
    this.unidadObj.id_gg_app=event.target.value;
  }
  changeIdGgTag = (event) => {
    this.setState({id_gg_tag: event.target.value});
    this.unidadObj.id_gg_tag=event.target.value;

  }
  changeIdGgUser = (event) => 
  {
    this.setState({id_gg_user: event.target.value});
    this.unidadObj.id_gg_user=event.target.value;

  }
  changeIdGgDevice = (event) => {
    this.setState({id_gg_device: event.target.value});
    this.unidadObj.id_gg_device=event.target.value;

  }
  changeUnidad = (unidad) => {
 
        this.getfuntion("get", unidad, "", "unidadesGerenciador/unidad/"+unidad.id, "unidadGerenciador"); 
        this.setState({unidad: unidad});
        this.setState({imei: ''})
        this.setState({id_gg_app:''});
        this.setState({id_gg_tag:''});
        this.setState({id_gg_user:''});
        this.setState({id_gg_device:''});

        };


  componentWillUnmount = () => {
    // this.getfuntion("get", "", "", "unidadesGerenciador/transportista/"+this.props.transportista.id, "unidades"); 
   //  alert("fin del willMount");

  }

  componentDidMount = () => {
    this.getfuntion("get", "", "", "unidades/transportista/"+this.props.transportista.id, "unidades"); 
  //  alert("fin del did mount");
  //  console.log(this.getfuntion("get", "", "", "unidadesGerenciador/transportista/"+this.props.transportista.id, "unidades"));  
  //  Crud_Catalogos("unidadesGerenciador/transportista/1", "", "get", "", this.reaccionObject, "", "", this.props.hiddenmodal, "")


  }

  render() {

    return (
      
      <div>       
       <h1> {this.state.lista}</h1>
         <Grid.Row>
           <Grid.Col sm={3} md={3}> 
        <Grid.Col >
           <Form.Group label="imei">
            <Form.Input name="imei"  value={this.state.imei}  type="text" onChange={this.changeimei}/>
          </Form.Group>
        </Grid.Col>

        <Grid.Col >
          <Form.Group label="id_gg_app">
            <Form.Input name="id_gg_app"  value={ this.state.id_gg_app } type="text" onChange={this.changeIdGgApp}/>
          </Form.Group>
        </Grid.Col>
        <Grid.Col >
          <Form.Group label="id_gg_tag">
            <Form.Input name="id_gg_tag"  value={ this.state.id_gg_tag } type="text" onChange={this.changeIdGgTag}/>
          </Form.Group>
        </Grid.Col>
        <Grid.Col >
          <Form.Group label="id_gg_user">
            <Form.Input name="id_gg_user"  value={ this.state.id_gg_user } type="text" onChange={this.changeIdGgUser}/>
          </Form.Group>
        </Grid.Col>
        <Grid.Col >
          <Form.Group label="id_gg_device">
            <Form.Input name="id_gg_device" value={ this.state.id_gg_device } type="text" onChange={this.changeIdGgDevice}/>
          </Form.Group>
        </Grid.Col>

        <Grid.Col >
      <Form.Group label="">
            <Form.Input name="idLineaTransporte"  value={ this.props.transportista.id }  type="hidden" onChange={this.changeidlineatransporte}/>
          </Form.Group>
        </Grid.Col>
           </Grid.Col> 
           <Grid.Col>
           <Table cards={false} striped={false} responsive={true} className="table-vcenter"
              headerItems={[
                  {content:"ID"},
                  {content:"Placa"},
                  {content:"Marca"},
                  {content:"Modelo"},
                  {content:"Gerenciado"},
                  {content:"CONFIGURACIÓN"},
                  
                ]}>
                  <Table.Body>
                    {this.state.unidades?
                      this.state.unidades.map((t, index) =>
                        <Table.Row align="center" key={index}  >
                            <Table.Col> { t.id } </Table.Col>
                            <Table.Col> { t.placa } </Table.Col>
                            <Table.Col> { t.marca } </Table.Col>
                            <Table.Col> { t.modelo } </Table.Col>
                            <Table.Col> { <Form.Switch type="Checkbox" checked={t.gerenciado} />} </Table.Col>
                            <Table.Col> 
                              <Button onClick={()=>this.changeUnidad(t)} pill size="sm" outline color="primary"><Icon name="settings" /></Button>                                 
                            </Table.Col>
                        </Table.Row>)
                    :null}
                  </Table.Body>
              </Table>
           </Grid.Col> 
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={12}>
            <Form.Group >
              <Button.List align="right">
                  <Button onClick={() => {this.agregarReaccion()}} color="primary">Guardar</Button>
              </Button.List>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      
      </div>
    );
  }  
}


export default Unidades;