import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import Select from "react-select";
import { timingSafeEqual } from "crypto";
import ModalLayout from "../../../components/ModalLayout.react";
import { PersonasForm } from "../../../Catalogos";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
/**
 * Notificaciones
 */
import { NotificationContainer, NotificationManager} from 'react-notifications';
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
class  Usuario extends Component {
  constructor(props) {
    super(props);
              this.state  = {
                                 informe  : {value:0, label: "Selecione una opción", data: null},
                                 tiempo   : "00:00:00",
                                  accion  : "nuevo", //'nuevo'// modificar, nuevo
                         crudbutonEnable  : true, // Se desabilita cuando se hace click
                            idPlataforma  : { value: 0, label: "Seleciona una plataforma", data: {id: 0 } },
                             hiddenModal  : false,
                                informes  : [],
                            reporteExcel  : []
              };
      this.informeObject  = {
        "idUsuario": this.props.elementos.id,
        "idPlataforma": 0,
        "idInforme": 0,
        "tiempo": "00:00:00"
      };
      this.plataformas=[];
  }
  isEnable=(Accion)=>{
    let borrar= "usuarios_borrar";
    let actualizar= "usuarios_editar";
    let agregar= "usuarios_agregar";

    let actualizarPersona= "personas_editar";
    let agregarPersona= "personas_agregar";
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "actualizarPersona"){if( privilegios[i].clave == actualizarPersona){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregarPersona"){if( privilegios[i].clave == agregarPersona){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}

      if(Accion === "borrar"){ 
        if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }

  componentDidMount = () => {
    this.getfuntion("get","","","informes","informes");
    this.getfuntion("get","","","reporte_excel_correo/idusurio/"+this.props.elementos.id,"reporteExcel");
    
    this.plataformas.push({ value: 0, label: "Seleciona una plataforma", data: {id: 0 } });
    currentUser.map((elemento, index) => {
      if(elemento.plataforma.plataformaTipo.id === 2){
        this.plataformas.push({ value: elemento.plataforma.id, label: elemento.plataforma.nombre, data: elemento.plataforma});
      }
    });
  }
  changePlataformas = (event) => {
    this.setState({ idPlataforma: event });
    this.informeObject.idPlataforma= event.value;
  }
  changeInforme = (event) => {
    this.setState({ informe: event });
    this.informeObject.idInforme= event.value;
  }
  changeTiempo =(event) => {
    this.setState({ tiempo: event.target.value });
    this.informeObject.tiempo= event.target.value;
  }

  hiddenmodal = () => {
    this.setState({ hiddenModal: !this.state.hiddenModal });
  }//Solo personas
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas/plataforma/"+this.state.idPlataforma.value , stateVar = "personas", hiddenModl = this.hiddenmodal() ) => {
    return Crud_Catalogos(metodo !== "post" && catalogo.includes("personas")? "personas":catalogo , "tracking", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          if (stateVar === "informes") {
            let informesTemp= [];
            returnVal.map((inf, index)=>{
              informesTemp.push({value: inf.id, label: inf.nombre , data: inf});
            });
            this.setState({ informes: informesTemp });
          }
          if (stateVar === "reporteExcel" && returnVal) {
            this.setState({ reporteExcel: returnVal });
          }
          break;
        case "post":
          {
            if (stateVar === "reporteExcel") { 
              if(returnVal.id){
                let reporteExcelTemp= this.state.reporteExcel;
                reporteExcelTemp.push(returnVal);
                this.cancelar();
                this.setState({ reporteExcel: reporteExcelTemp});
                NotificationManager.info("Registro agregado correctamente", "Guardar");
              }
            }
          }
          break;
        case "put":
          {
            if (stateVar === "reporteExcel") { 
              if(returnVal.id){
                let reporteExcelTemp= this.state.reporteExcel;
                for(let item of reporteExcelTemp){
                  if(item.id === returnVal.id){ item= returnVal; }
                }
                this.cancelar();
                this.setState({ reporteExcel: reporteExcelTemp});
                NotificationManager.info("Registro actualizado correctamente", "Guardar");
              }
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "reporteExcel") { 
              if(returnVal){
                let reporteExcelTemp= this.state.reporteExcel;
                reporteExcelTemp.map((rpot, index)=>{
                  if(rpot.id == id){ reporteExcelTemp.splice(index, 1); NotificationManager.info("Registro borrado correctamente", "Guardar"); }
                });
                this.cancelar();
                this.setState({ reporteExcel: reporteExcelTemp});
              }
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  crud_post   =()=>{ this.getfuntion("post", this.informeObject,"", "reporte_excel_correo", "reporteExcel"); }
  crud_put    =()=>{ this.getfuntion("put", this.informeObject,"", "reporte_excel_correo", "reporteExcel"); }
  crud_delete =(id)=>{ this.getfuntion("delete", "",id, "reporte_excel_correo", "reporteExcel"); }
  editar=(inf)=>{
    this.informeObject  = inf;
    for( let item of this.state.informes){
      if( item.value === inf.idInforme ){  this.setState({ informe: item });  }
    }
    for( let item of this.plataformas){
      if( item.value === inf.idPlataforma ){  this.setState({ idPlataforma: item });  }
    }
    this.setState({ tiempo   : inf.tiempo });
    this.setState({accion: "modificar"});
  }
  cancelar=()=>{
    this.informeObject  = {
      "idUsuario": this.props.elementos.id,
      "idPlataforma": 0,
      "idInforme": 0,
      "tiempo": "00:00:00"
    };
    this.setState({ informe  : {value:0, label: "Selecione una opción", data: null},
                    tiempo   : "00:00:00",
                    idPlataforma  : { value: 0, label: "Seleciona una plataforma", data: {id: 0 } }
                  });
    this.setState({accion: "nuevo"});
  }
  render(){
    return (
      <div>
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Plataforma">
                  <Select value={this.state.idPlataforma} onChange={this.changePlataformas} options={this.plataformas} /></Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="Informe">
                  <Select value={this.state.informe} onChange={this.changeInforme} options={this.state.informes} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="Tiempo">
                  <Form.MaskedInput
                    placeholder="HH:MM:SS"
                    value={this.state.tiempo}
                    onChange={ this.changeTiempo }
                    mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                { this.isEnable("actualizar" || this.isEnable("agregar")) ?
                  <Form.Group label="Guardar">
                    <Button
                      target="_blank" size="sm" RootComponent="a" color="primary" disabled={ !this.state.crudbutonEnable }
                      onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                      <span className="badge"><Icon link={true} name="save" /></span>
                    </Button>
                  </Form.Group>
                :null}
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="Cancelar">
                  <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={() => this.cancelar() } >
                    <span className="badge"><Icon link={true} name="x" /></span>
                  </Button>
                </Form.Group>                
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Table  cards={true} striped={true} responsive={true} className="table-vcenter" >
            <Table.Header>
              <Table.ColHeader>ID</Table.ColHeader>
              <Table.ColHeader>Plataforma</Table.ColHeader>
              <Table.ColHeader>Informe</Table.ColHeader>
              <Table.ColHeader>Tiempo</Table.ColHeader>
              <Table.ColHeader>Edit</Table.ColHeader>
              <Table.ColHeader>Borrar</Table.ColHeader>
            </Table.Header>
            <Table.Body>
              {
                this.state.reporteExcel.map((elemento, index) =>
                  <Table.Row>
                    <Table.Col>{ elemento["id"] }</Table.Col>
                    <Table.Col>{ this.getPlataformaNombre(elemento["idPlataforma"]) }</Table.Col>
                    <Table.Col>{ this.getInformeNombre(elemento["idInforme"]) }</Table.Col>
                    <Table.Col>{ elemento["tiempo"] }</Table.Col>
                    <Table.Col>
                      <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.editar(elemento) } >
                        <span className="badge"><Icon link={true} name="edit" /></span>
                      </Button>
                    </Table.Col>
                    <Table.Col>
                      <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={() => this.crud_delete(elemento["id"]) } >
                        <span className="badge"><Icon link={true} name="trash" /></span>
                      </Button>
                    </Table.Col>
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        </Grid.Row>
      </div>
    )
  }
  getPlataformaNombre=(id)=>{ for(let item of this.plataformas){ if(item.value === id){return item.label}  } return id; }
  getInformeNombre=(id)=>{ for(let item of this.state.informes){ if(item.value === id){return item.label}  } return id; }
}

export default Usuario
