import React, { Component } from "react";
import { List } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';

class Cita extends Component {
  constructor(props){
    super(props);
    this.state = {
      incidencias: [],
      hiddenModals: true,
      restantetoCita: "",
      camposLogisticos:[]
    };
    this.cita=0;
    this.dias=0;
    this.hora = 0;
    this.minuto = 0;
    this.segundo=0;
    this.restante="";
  }
  componentDidMount =() => {
      let cLogisticos= {};
      if(this.props.camposLogisticosNav){
        for(let item of this.props.camposLogisticosNav){
          cLogisticos[item.nombreCampo]= item;
        }
      }
      
      this.setState({ camposLogisticos: cLogisticos });
    
    this.retantetoCita();
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "rter") {
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err); })
  }
   retantetoCita =() =>{
      this.cita=0;
      this.dias=0;
      this.hora = 0;
      this.minuto = 0;
      this.segundo=0;
      this.restante="";
     if( this.props.obj.citaProgramada){
        this.cita = new Date(this.props.obj.citaProgramada);
        this.restante= this.cita.getTime()-new Date().getTime();
        /**
         * Horas
         */
        this.hora = parseInt((this.restante/(1000*60*60)), 10);
        let horaMod= this.restante%(1000*60*60);
        if(this.hora > 24 ){
          this.dias= parseInt((this.hora/24), 10);
          this.hora= this.hora%24;
        }
        this.hora= this.hora < 10 ? "0"+this.hora :this.hora;
        /**
         * Minutos
         */
        this.minuto = parseInt((horaMod/(1000*60)), 10);
        let minMod= horaMod%(1000*60);
        this.minuto= this.minuto < 10 ? "0"+this.minuto :this.minuto;
        /**
         * Segundos
         */
        this.segundo=parseInt((minMod/1000), 10);
        this.segundo= this.segundo < 10 ? "0"+this.segundo :this.segundo
        if(this.restante <= 0){
          this.setState( {restantetoCita: "00:00:00" })
        }else{
          this.setState( {restantetoCita: this.dias > 0
                                                    ? this.dias+" días, "+this.hora+":"+this.minuto+":"+this.segundo
                                                    : this.hora+":"+this.minuto+":"+this.segundo })
        }

        setTimeout(this.retantetoCita,1000)
     }

    }
  render() {
    return (
      <div>
        {this.props.obj.id?
          <div className= "mt-1">
            {
            //console.log(this.props.obj)
            }
            <h2>{ this.props.obj.idMovimientoTipo.nombre.toUpperCase() }</h2>
            <List.Group>
              <List.GroupItem action icon="clock"> Estatus de Operacion:  <strong> { this.props.obj.idOperacionEstatus.nombre } </strong> </List.GroupItem>
              <List.GroupItem action icon="watch"> Ruta Estatus:          <strong>{ this.props.obj.idRuta.nombre }              </strong> </List.GroupItem>
              <List.GroupItem action icon="clock"> Sello:                 <strong> { this.props.obj.sello }                     </strong> </List.GroupItem>
              <List.GroupItem action icon="clock"> Contenedor:            <strong> { this.props.obj.contenedor }                </strong> </List.GroupItem>
              <List.GroupItem action icon="watch"> Cita Programada:       <strong>  { this.props.obj.citaProgramada }           </strong> </List.GroupItem>
              <List.GroupItem action icon="clock"> Tiempo restante libre: <strong>  { this.state.restantetoCita }               </strong> </List.GroupItem>
            </List.Group>
            <List.Group>{/** Importacion */}
              { this.state.camposLogisticos['arribo_terminal_vacio']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['arribo_terminal_vacio'].nombre}     <strong>{ this.props.obj.arriboTerminalVacio }    </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['salida_terminal_lleno']?
                <List.GroupItem action icon="trending-up">{this.state.camposLogisticos['salida_terminal_lleno'].nombre}       <strong>{ this.props.obj.salidaTerminalLleno }    </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['arribo_cliente_lleno']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['arribo_cliente_lleno'].nombre}      <strong>{ this.props.obj.arriboClienteLleno }     </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['salida_cliente_vacio']?
                <List.GroupItem action icon="trending-up">{this.state.camposLogisticos['salida_cliente_vacio'].nombre}        <strong>{ this.props.obj.salidaClienteVacio }     </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['arribo_terminal_vacio_2']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['arribo_terminal_vacio_2'].nombre}   <strong>{ this.props.obj.arriboTerminalVacio2 }   </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['asignacion_piloto']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['asignacion_piloto'].nombre}         <strong>{ this.props.obj.asignacionPiloto }       </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['inicio_ruta']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['inicio_ruta'].nombre}               <strong>{ this.props.obj.inicioRuta }             </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['despacho_predio_cliente']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['despacho_predio_cliente'].nombre}   <strong>{ this.props.obj.despachoPredioCliente }  </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['llegada_frontera']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['llegada_frontera'].nombre}          <strong>{ this.props.obj.llegadaFrontera }        </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['salida_frontera']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['salida_frontera'].nombre}           <strong>{ this.props.obj.salidaFrontera }         </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['arribo_aduana']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['arribo_aduana'].nombre}             <strong>{ this.props.obj.arriboAduana }           </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['salida_aduana']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['salida_aduana'].nombre}             <strong>{ this.props.obj.salidaAduana }           </strong></List.GroupItem>: null }
              {/** Exportacion */}
              { this.state.camposLogisticos['retiro_contenedor']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['retiro_contenedor'].nombre}         <strong>{ this.props.obj.retiroContenedor }       </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['arribo_cliente_vacio']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['arribo_cliente_vacio'].nombre}      <strong>{ this.props.obj.arriboClienteVacio }     </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['salida_cliente_lleno']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['salida_cliente_lleno'].nombre}      <strong>{ this.props.obj.salidaClienteLleno }     </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['arribo_terminal_cargado']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['arribo_terminal_cargado'].nombre}   <strong>{ this.props.obj.arriboTerminalCargado }  </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['salida_predio_vacio']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['salida_predio_vacio'].nombre}       <strong>{ this.props.obj.salidaPredioVacio }      </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['cita_fecha_despacho']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['cita_fecha_despacho'].nombre}       <strong>{ this.props.obj.citaFechaDespacho }      </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['entrada_ciudad_portuaria']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['entrada_ciudad_portuaria'].nombre}  <strong>{ this.props.obj.entradaCiudadPortuaria } </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['arribo_patio_linea']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['arribo_patio_linea'].nombre}        <strong>{ this.props.obj.arriboPatioLinea }       </strong></List.GroupItem>: null }
              { this.state.camposLogisticos['salida_terminal_vacio']?
                <List.GroupItem action icon="trending-down">{this.state.camposLogisticos['salida_terminal_vacio'].nombre}     <strong>{ this.props.obj.salidaTerminalVacio }    </strong></List.GroupItem>: null }
            </List.Group>
          </div>
        :null}
      </div>
    );
  }
}
export default Cita;
