import React, { Component }                      from "react";
import { Button, Form, Icon, Grid, Card, Table, Dimmer } from "tabler-react";
import { Crud_Catalogos, Crud_error }            from '../../../../herramientas/Crud_Catalogos';
import ModalLayout from "../../../../components/ModalLayout.react";
import { PersonasForm } from "../../..";
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';

class DestinatariosEspecialPersonas extends Component {
  constructor(props) {
    super(props);
    this.state = {
              contactos: [],
                agregar: false,
                selectedRow: -1,
   ContactosDisponibles: [],
      clientesContactos:[],
               Personas:[],
           FHabilitados:[],
                 searchP:"",
                 searchM:"",
                 searchN:"",
                 accion:this.props.accion, //'nuevo'// modificar, nuevo
                 FArray:[],
             currentTap:0, pagination: [], pagina: 1, pagesize: 10,
             agregar: false
    };
    this.content= [];
    this.Array = [];
    this.pagination=[];
    this.content = [];
    this.contactosPagination=[];
    this.contactosDisponiblesPagination=[];
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas", stateVar = "personas", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "contactos") {
              for(let item of returnVal){
                if(!item.aMaterno){item.aMaterno=""}
                if(!item.aPaterno){item.aPaterno=""}
                if(!item.nombre){item.nombre=""}
              }
               this.setState({ contactos: returnVal });
            }
            if (stateVar === "ContactosDisponibles") { 
              for(let item of returnVal.content){
                if(!item.aMaterno){item.aMaterno=""}
                if(!item.aPaterno){item.aPaterno=""}
                if(!item.nombre){item.nombre=""}
              }
               this.setState({ ContactosDisponibles: returnVal.content });
            }          
          }
          break;
        case "post":
          {
            if (stateVar === "personas") { 
              if(returnVal.id){
                NotificationManager.info("Se ha agregado contacto al catalogo general( "+returnVal.nombre+" )", "Nuevo");
                //let contactosTem= this.state.contactos; 
                //contactosTem.unshift( returnVal );
                //this.setState({ contactos: contactosTem });
                this.getfuntion("post", { "idDestinatarioEspecial": this.props.destinatario.idDestinatarioEspecial, "idPersona": returnVal.id }, returnVal, "destinatarios_especial_personas", "destinatarios_especial_personas");          
              }
            }
            if (stateVar === "destinatarios_especial_personas") {
              if(returnVal.idPersona){
                NotificationManager.info("El contacto ahora pertenece a "+this.props.destinatario.nombre, "Nuevo");
                let contactossTem= this.state.contactos; 
                contactossTem.unshift( id );
                this.setState({searchP: ""});
                this.setState({searchM: ""});
                this.setState({searchN: ""});
              }
            }
          }
          break;
        case "put":
          {
            if (stateVar === "clientes") {}
          }
          break;
        case "delete":
          {
            if (stateVar === "destinatarios_especial_personas") {
              if(returnVal){
                let contactosTem= this.state.contactos;
                contactosTem.map((item, index)=>{
                  if(item.id == obj.id){
                    contactosTem.splice(index, 1);
                    this.setState({ contactos: contactosTem });  
                  }
                });
                NotificationManager.info("Se a removido correctamente", "Borrar");
              }else{
                NotificationManager.error("No se ha podido borrar", "Borrar");
              }
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.getfuntion("get", "", "", "personas/pagina/0/total/"+this.state.pagesize, "ContactosDisponibles");
      this.getfuntion("get", "", "", "personas/destinatario_especial/"+this.props.destinatario.idDestinatarioEspecial, "contactos");
    }
  }
  componentDidUpdate = (nexProps) => {
    if (nexProps.elementos.Personas !== this.state.Personas) {
      //alert("es igual")
    } else { /*alert(this.state.personas) */ }
  }
  searchingPaterno = (event) => {
    this.setState({ searchP: event.target.value });
    if( this.state.ContactosDisponibles.filter(word => word.aPaterno.toUpperCase().includes(event.target.value.toUpperCase()) && word.aMaterno.toUpperCase().includes(this.state.searchM.toUpperCase()) && word.nombre.toUpperCase().includes(this.state.searchN.toUpperCase()) ).sort().length == 0 && !event.target.value.toUpperCase().includes("/") ){
      this.paginarNombre(this.state.searchN, event.target.value, this.state.searchM);
    }
  }
  searchingMaterno = (event) => {
    this.setState({ searchM:event.target.value });
    if( this.state.ContactosDisponibles.filter(word => word.aPaterno.toUpperCase().includes(this.state.searchP.toUpperCase()) && word.aMaterno.toUpperCase().includes(event.target.value.toUpperCase()) && word.nombre.toUpperCase().includes(this.state.searchN.toUpperCase()) ).sort().length == 0 && !event.target.value.toUpperCase().includes("/") ){
      this.paginarNombre(this.state.searchN, this.state.searchP, event.target.value);
    }
  }
  searchingNombre = (event) => {
    this.setState({ searchN: event.target.value });
    if( this.state.ContactosDisponibles.filter(word => word.aPaterno.toUpperCase().includes(this.state.searchP.toUpperCase()) && word.aMaterno.toUpperCase().includes(this.state.searchM.toUpperCase()) && word.nombre.toUpperCase().includes(event.target.value.toUpperCase()) ).sort().length == 0 && !event.target.value.toUpperCase().includes("/") ){
      this.paginarNombre(event.target.value, this.state.searchP, this.state.searchM);
    }
  }
  paginarNombre=(nombre, paterno, materno)=>{
    this.getfuntion("get", "", "", "personas/nombre/"+(nombre.length>0?nombre:null)+"/paterno/"+(paterno.length>0?paterno:null)+"/materno/"+(materno.length>0?materno:null)+"/pagina/0/total/"+this.state.pagesize, "ContactosDisponibles");
    //this.getfuntion("get", "", "", "personas/disponibles/cliente/"+this.props.elementos.id+"/nombre/"+(nombre.length>0?nombre:null)+"/paterno/"+(paterno.length>0?paterno:null)+"/materno/"+(materno.length>0?materno:null)+"/pagina/0/total/"+this.state.pagesize, "ContactosDisponibles");
  }
  table_headers =() => {
    this.content= [];
    let cont=this.state.agregar? this.state.ContactosDisponibles: this.state.contactos;
    if(cont){ 
      if(cont[0]){
        Object.keys(cont[0]).forEach(key => this.content.push({ content: key }) );
        this.content.push({ content: this.state.agregar? "agregar":"editar" });
        if(!this.state.agregar){ this.content.push({ content: "eliminar" }); }
      }
    }
  }
  _editCol=(elemento)=>{    
    this.colum.push(<Table.Col>
                      <ModalLayout  tamanio={"90%"} title="Modificar" name= {<Button pill  size="sm" outline color="primary"><Icon name="edit"/></Button>}  hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                formulario={<PersonasForm accion="modificar" getfuntion={this.getfuntion} stor={this.props.stor} elementos={elemento}  plataforma={ this.props.plataforma } />} />
                    </Table.Col>);
      this.colum.push(<Table.Col>
                      <span data-toggle="tooltip" data-placement="top" title={"Remover"}>
                        <Button pill  size="sm" outline color="primary" onClick={()=> this.getfuntion("delete", elemento, this.props.destinatario.idDestinatarioEspecial+"/"+elemento.id, "destinatarios_especial_personas", "destinatarios_especial_personas") }><Icon name="user-minus"/></Button>
                      </span>
                    </Table.Col>);
  }
  render() {

    return (
      <div>
        {/**
          *   Contactos del cliente
          */
        }
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title={ "Contactos" }
                  isCollapsible={false}
                  //isClosable
                  isCollapsed={false}
                  body={
                    <Grid.Row>
                      <Grid.Col sm={12} md={5}>
                        <div className="row">
                          <div className="col-3">
                            <span>
                              <Form.Input className="mb-2" icon="search" placeholder="Paterno..." position="append" value={this.state.searchP} onChange={this.searchingPaterno} onKeyDown={this._searchingkey} />
                            </span>
                          </div>
                          <div className="col-3">
                            <span>
                              <Form.Input className="mb-2" icon="search" placeholder="Materno..." position="append" value={this.state.searchM} onChange={this.searchingMaterno} onKeyDown={this._searchingkey} />
                            </span>
                          </div>
                          <div className="col-4">
                            <span>
                              <Form.Input className="mb-2" icon="search" placeholder="Nombre..." position="append" value={this.state.searchN} onChange={this.searchingNombre}   onKeyDown={this._searchingkey} />
                            </span>
                          </div>
                          <div className="col-2">
                            <span data-toggle="tooltip" data-placement="top" title={"Nuevo contacto"}>
                              <ModalLayout  tamanio={"80%"} hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                                title= "Nuevo"
                                                name= {<Button pill  size="sm" outline color="primary"><Icon name="plus"/></Button>}
                                                formulario= { <PersonasForm accion="nuevo" elementos= { [] } getfuntion= {this.getfuntion} stor= { this.props.stor }/> }
                              />
                            </span>
                          </div>          
                        </div>
                        {this.state.ContactosDisponibles?
                          <Form.Group>{console.log(this.state.contactos)}
                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={[{ content: "nombre"}, { content: "correo"}, { content: "agregar"}]}>
                            <Table.Body>
                              {
                                this.state.ContactosDisponibles.filter(word => word.aPaterno.toUpperCase().includes(this.state.searchP.toUpperCase())
                                                                    && word.aMaterno.toUpperCase().includes(this.state.searchM.toUpperCase())
                                                                    && word.nombre.toUpperCase().includes(this.state.searchN.toUpperCase())
                                  ).sort().map((elemento, index) =>
                                  <Table.Row>
                                    <Table.Col> { elemento.aPaterno+" "+ elemento.aMaterno+ " "+ elemento.nombre } </Table.Col>
                                    <Table.Col> { elemento.correo} </Table.Col>
                                    <Table.Col> <Button pill  size="sm" outline color="primary" onClick={()=> this.getfuntion("post", { "idDestinatarioEspecial": this.props.destinatario.idDestinatarioEspecial, "idPersona": elemento.id }, elemento, "destinatarios_especial_personas", "destinatarios_especial_personas") }><Icon name="user-plus"/></Button> </Table.Col>
                                  </Table.Row>
                                )
                              }
                            </Table.Body>
                          </Table>
                          </Form.Group>
                        :null}
                      </Grid.Col>
                      <Grid.Col sm={12} md={7}>
                        {this.state.contactos.length>0?
                          <Form.Group>
                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" {...this.table_headers()} headerItems={this.content}>
                            <Table.Body>
                              {
                                this.state.contactos.filter(word => word.aPaterno.toUpperCase().includes(this.state.searchP.toUpperCase())
                                                                    && word.aMaterno.toUpperCase().includes(this.state.searchM.toUpperCase())
                                                                    && word.nombre.toUpperCase().includes(this.state.searchN.toUpperCase())
                                  ).sort().map((elemento, index) =>
                                  <Table.Row>
                                    {this.colum = []}
                                    {
                                      Object.keys(elemento).forEach((key, index )=>{
                                        switch (key) {
                                          case "skyangel":this.colum.push(<Table.Col> <Form.Switch type="Checkbox" name="skyangel" checked={ elemento[key] } /> </Table.Col>);                                            
                                            break;
                                          case "correoEstatus":this.colum.push(<Table.Col> <Form.Switch type="Checkbox" name="correoEstatus" checked={ elemento[key] } /> </Table.Col>);                                             
                                            break;                                            
                                          default: this.colum.push(<Table.Col> { elemento[key] } </Table.Col>);
                                            break;
                                        }
                                      }),this._editCol(elemento)
                                    }
                                  </Table.Row>
                                )
                              }
                            </Table.Body>
                          </Table>
                          </Form.Group>
                        :<h3>Aún No hay contactos ..</h3>}
                      </Grid.Col>
                    </Grid.Row>
                  }
                />
              </Grid.Col>
            </Grid.Row>
          </div>
        
      </div>
    );
  }
}
export default DestinatariosEspecialPersonas;
