import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import { useGetData } from "../../../hooks/useGetData";
import { LoadingScreen } from "../../../components/LoadingScreen";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";

export const MenuReportesGenerales = ({ setData }) => {
  /* ------------------------- Variables contexto --------------------------*/
  const { control, handleSubmit, watch, getValues } = useFormContext();
  /* ----------------------------- Hooks ------------------------------------*/
  const watchFechaInicio = watch("fechaInicio");
  const watchFechaFin = watch("fechaFin");

  const { data: informes, isLoading: isLoadingInformes } = useGetData(
    "informes",
    "perfilesInformes",
    true
  );

  const {
    data: informeMovimientos,
    isFetching: isFetchingInformeMovimientos,
    refetch: refetchInformeMovimientos,
  } = useGetData(
    `informes/movimientosGenerales/pagina/0/total/5/variables/${watchFechaInicio}/${watchFechaFin}`,
    `informeMovimientos`,
    false
  );

  const {
    data: informeIncidencias,
    isFetching: isFetchingInformeIncidencias,
    refetch: refetchInformeIncidencias,
  } = useGetData(
    `informes/incidenciasGenerales/pagina/0/total/5/variables/${watchFechaInicio}/${watchFechaFin}`,
    `informeIncidencias`,
    false
  );

  const {
    data: informeAlertas,
    isFetching: isFetchingInformeAlertas,
    refetch: refetchInformeAlertas,
  } = useGetData(
    `informes/alertasGenerales/pagina/0/total/5/variables/${watchFechaInicio}/${watchFechaFin}`,
    `informeAlertas`,
    false
  );

  const {
    data: informeEntregas,
    isFetching: isFetchingInformeEntregas,
    refetch: refetchInformeEntregas,
  } = useGetData(
    `informes/entregasGenerales/pagina/0/total/5/variables/${watchFechaInicio}/${watchFechaFin}`,
    `informeEntregas`,
    false
  );

  const {
    data: informeEventos,
    isFetching: isFetchingInformeEventos,
    refetch: refetchInformeEventos,
  } = useGetData(
    `informes/eventosGenerales/pagina/0/total/50/variables/${watchFechaInicio}/${watchFechaFin}`,
    `informeEventos`,
    false
  );

  useEffect(() => {
    setData(informeMovimientos);
  }, [informeMovimientos]);

  useEffect(() => {
    setData(informeIncidencias);
  }, [informeIncidencias]);

  useEffect(() => {
    setData(informeAlertas);
  }, [informeAlertas]);

  useEffect(() => {
    setData(informeEntregas);
  }, [informeEntregas]);

  useEffect(() => {
    setData(informeEventos);
  }, [informeEventos]);

  /* ------------------------------ Funciones -------------------------------*/
  const onSubmit = (data) => {
    switch (getValues("reporte").nombre) {
      case "Movimientos Generales":
        refetchInformeMovimientos();
        break;
      case "Incidencias Generales":
        refetchInformeIncidencias();
        break;
      case "Alertas Generales":
        refetchInformeAlertas();
        break;
      case "Entregas Generales":
        refetchInformeEntregas();
        break;
      case "Eventos Generales":
        refetchInformeEventos();
        break;
      default:
        break;
    }
  };

  /* -------------------------------- return --------------------------------*/
  if (
    isLoadingInformes ||
    isFetchingInformeMovimientos ||
    isFetchingInformeIncidencias ||
    isFetchingInformeAlertas ||
    isFetchingInformeEntregas ||
    isFetchingInformeEventos
  ) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div className="row" style={{ textAlign: "center" }}>
        <div className="col-12">
          <h1 style={{ marginTop: "0.5em" }}>Reportes Generales</h1>
        </div>
        <CustomSelect
          control={control}
          col={"col-4"}
          name={"reporte"}
          rules={{
            required: "Se requiere seleccionar un reporte",
          }}
          text={"Reportes"}
          data={informes.filter((informe) =>
            informe.nombre.includes("Generales")
          )}
          required
        />
        <CustomInput
          control={control}
          col={"col-4"}
          name={"fechaInicio"}
          rules={{
            required: "Se requiere seleccionar una fecha inicial",
          }}
          text={"Fecha inicio"}
          type={"date"}
          required
        />
        <CustomInput
          control={control}
          col={"col-4"}
          name={"fechaFin"}
          rules={{
            validate: {
              fechaCorrecta: (value) => {
                if (!getValues("fechaInicio")) return false;
                var fechaInicio = new Date(getValues("fechaInicio"));
                var fechaFin = new Date(value);
                if (fechaFin < fechaInicio)
                  return "La fecha final necesita ser mayor a la fecha inicial";
              },
            },
            required: "Se requiere seleccionar una fecha final",
          }}
          text={"Fecha fin"}
          type={"date"}
          required
        />
      </div>
      <hr />
      <div className="row mt-3 mb-3">
        <div className="col text-center">
          <button className="btn btn-info" onClick={handleSubmit(onSubmit)}>
            Buscar
          </button>
        </div>
      </div>
    </div>
  );
};
