import React, { useState } from "react";
import SiteWrapper from "../../../SiteWrapper.react";
import { MenuReportesGenerales } from "./MenuReportesGenerales.react";
import { ContentReportesGenerales } from "./ContentReportesGenerales.react";
import { ExportarReportesGenerales } from "./ExportarReportesGenerales.react";
import { useForm, FormProvider } from "react-hook-form";
import { LoadingScreen } from "../../../components/LoadingScreen";

const ReportesGeneralesPage = () => {
  const formulario = useForm();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  return (
    <SiteWrapper>
      <FormProvider {...formulario}>
        <div className="container">
          <MenuReportesGenerales setData={setData}/>
          <ExportarReportesGenerales data={data} setLoading={setLoading}/>
          <ContentReportesGenerales data={data}/>
        </div>
      </FormProvider>
      {loading ? <LoadingScreen loading={loading} /> : null}
    </SiteWrapper>
  );
};

export default ReportesGeneralesPage;
