import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import Select from "react-select";

class  Unidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
                     id : "",
                 accion : "post", //'nuevo'// modificar, nuevo
       plataformaCorreo : [],
          idUnidadMarca : { value: 0, label: "Seleccione una opción " },
              idGpsFijo : { value: 0, label: "Seleccione una opción " },
                  placa : "",
                  color : "",
                 modelo : [],
                 search : "",
      };
         this.destinos  = [];
      this.UnidadesObj  = {
                            "idPlataforma":this.props.elementos.id,
                            "idTransportista":this.props.idTransportista,
                            "idUnidadMarca":"",
                            "idGpsFijo":"",
                            "placa":"",
                            "color":"",
                            "modelo": ""
                          }
            this.valid  = {idUnidadMarca: false, idGpsFijo: false, placa: false, color: false, modelo: false};
    this.UnidadsMarcas  = [];
          this.gpsFijo  = [];
  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
    }else{
    }
    this.UnidadsMarcas.push({ value: 0, label: "Seleccione una opción " });
    this.props.stor.unidadesmarcas_object.map((elemento, index) => {
      this.UnidadsMarcas.push({ value: elemento.id, label: elemento.nombre });
    });
    this.gpsFijo.push( { value: 0, label: "Seleccione una opción " } );
    this.props.gpsFijo.map((elemento, index) => {
      this.gpsFijo.push({ value: elemento.id, label: elemento.nombre });
    });
  }

  isValid=(campo) =>{
    switch (campo) {
      case "idUnidadMarca": return this.valid.idUnidadMarca;
        break;
      case "idGpsFijo": return this.valid.idGpsFijo;
        break;
      case "placa": return this.valid.placa;
        break;
      case "color": return this.valid.color;
        break;
      case "modelo": return this.valid.modelo;
        break;
      default:
        break;
    }
  }

  changeIdUnidadMarca =(event) =>{
    this.setState({idUnidadMarca: event});
    if(event.value > 0){ this.valid.idUnidadMarca= true }else{ this.valid.idUnidadMarca= false }
    this.UnidadesObj.idUnidadMarca = event.value;
  }

  changeIdGpsFijo =(event) =>{
    this.setState({idGpsFijo: event});
    if(event.value > 0){ this.valid.idGpsFijo= true }else{ this.valid.idGpsFijo= false }
    this.UnidadesObj.idGpsFijo = event.value;
  }

  changePlaca =(event) =>{
    this.setState({ placa: event.target.value })
    if(event.target.value.length > 3){
      this.valid.placa= true;
    }else{
      if( event.target.value.length <=3){ this.valid.placa= false; }
    }
    this.UnidadesObj.placa = event.target.value;
  }
  changeColor =(event) =>{
    this.setState({ color: event.target.value })
    if(event.target.value.length > 2){
      this.valid.color= true;
    }else{
      if( event.target.value.length <=2){ this.valid.color= false; }
    }
    this.UnidadesObj.color = event.target.value;
  }
  changeModelo =(event) =>{
    this.setState({ modelo: event.target.value })
    if(event.target.value.length > 1){
      this.valid.modelo= true;
    }else{
      if( event.target.value.length === 1){ this.valid.modelo= false; }
    }
    this.UnidadesObj.modelo = event.target.value;
  }

  UnidadEdit =(index) =>{
    let current= this.props.Unidades.filter(word => word.placa.toUpperCase().includes(this.state.search.toUpperCase())).sort()[index];
    this.UnidadsMarcas.map((elem, index)=>{
      if(elem.value === current.idUnidadMarca){
      this.setState({ idUnidadMarca : elem })}
    });

    this.gpsFijo.map((elem, index)=>{
      if(elem.value === current.idGpsFijo){
      this.setState({ idGpsFijo : elem })}
    });
    this.setState({ placa         : current.placa });
    this.setState({ color         : current.color });
    this.setState({ modelo        : current.modelo });

    this.UnidadesObj = current;
    this.valid  = {idUnidadMarca: true, idGpsFijo: true, placa: true, color: true, modelo: true };
    this.setState({ accion: "modificar" });
  }

  crud_put =() =>{
    this.props.getfuntion("put", this.UnidadesObj, "", "unidades", "Unidades");
    this.setState({ accion        : "nuevo" });
    this.setState({ idUnidadMarca : { value: 0, label: "Seleccione una opción " } });
    this.setState({ idGpsFijo     : { value: 0, label: "Seleccione una opción " } });
    this.setState({ placa         : "" });
    this.setState({ color         : "" });
    this.setState({ modelo        : "" });
    this.UnidadesObj  = {
                          "idPlataforma":this.props.elementos.id,
                          "idTransportista":this.props.idTransportista,
                          "idUnidadMarca":"",
                          "idGpsFijo":"",
                          "placa":"",
                          "color":"",
                          "modelo": ""
                        }
          this.valid  = {idUnidadMarca: false, idGpsFijo: false, placa: false, color: false, modelo: false};
  }
  crud_delete =(id) =>{
    this.props.getfuntion("delete", this.UnidadesObj, id, "unidades","Unidades");
  }
  crud_post =() =>{
    this.props.getfuntion("post", this.UnidadesObj, "", "unidades", "Unidades");
    this.setState({ accion        : "nuevo" });
    this.setState({ idUnidadMarca : { value: 0, label: "Seleccione una opción " } });
    this.setState({ idGpsFijo     : { value: 0, label: "Seleccione una opción " } });
    this.setState({ placa         : "" });
    this.setState({ color         : "" });
    this.setState({ modelo        : "" });
    this.UnidadesObj  = {
                          "idPlataforma":this.props.elementos.id,
                          "idTransportista":this.props.idTransportista,
                          "idUnidadMarca":"",
                          "idGpsFijo":"",
                          "placa":"",
                          "color":"",
                          "modelo":""
                        }
          this.valid  = {idUnidadMarca: false, idGpsFijo: false, placa: false, color: false, modelo: false};

  }

  disableSave =() =>{
    let band= false;
    Object.keys(this.valid).forEach(key =>{ if( !this.valid[key]){ band=true; } });
    if(band){ return true }else{ return false }
  }
  searching =(event) =>{
    this.setState({ search: event.target.value })
  }



  render(){
    return (
      <div>
        {/**
          *   Configuracion de correo
          */
        }
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Unidades"
                  isCollapsible={ false }
                  //isClosable
                  isCollapsed={ false}
                  //options={ }
                  body={
                  <div>
                    <Grid.Row>
                    <Grid.Col sm={12} md={12}>
                      <Grid.Col sm={12} md={4} className="float-right">
                        <Form.Input className="mb-3" icon="search" placeholder="Buscar por placa..." position="append" onChange={this.searching} />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col sm={12} md={4}>
                      <Form.Group label="Marca"><Select value={this.state.idUnidadMarca} onChange={this.changeIdUnidadMarca} options={this.UnidadsMarcas} /></Form.Group>
                      <Form.Group label="GPS"><Select value={this.state.idGpsFijo} onChange={this.changeIdGpsFijo} options={this.gpsFijo} /></Form.Group>
                      <Form.Group label="Placa">
                        <Form.Input
                          type="text" name="placa" feedback="Al menos 4 caracteres" value={this.state.placa} placeholder="Ingresa placa ..."
                          invalid ={ this.state.placa!=null? !this.isValid("placa") && this.state.placa.length>0:false }
                          valid   ={ this.state.placa!=null?  this.isValid("placa") && this.state.placa.length>0:false }
                          onChange={this.changePlaca}
                        />
                      </Form.Group>
                      <Form.Group label="Color">
                        <Form.Input
                          type="text" name="color" feedback="Al menos 3 caracteres" value={this.state.color} placeholder="Ingresa color ..."
                          invalid ={this.state.color? !this.isValid("color") && this.state.color.length>0:false }
                          valid   ={this.state.color?  this.isValid("color") && this.state.color.length>0:false }
                          onChange={this.changeColor}
                        />
                      </Form.Group>
                      <Form.Group label="Modelo">
                        <Form.Input
                          type="text" name="modelo" feedback="Al menos 2 caracteres" value={this.state.modelo} placeholder="Ingresa modelo ..."
                          invalid ={this.state.modelo?!this.isValid("modelo") && this.state.modelo.length > 0:false }
                          valid   ={this.state.modelo? this.isValid("modelo") && this.state.modelo.length > 0:false }
                          onChange={this.changeModelo}
                        />
                      </Form.Group>

                      <div className="float-right">
                        <span className="badge">
                          <Button size="sm" RootComponent="a" color="primary" disabled= { this.disableSave() } onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() } >
                              <span className="badge"><Icon link={true} name="save" />Guardar</span>
                          </Button>
                        </span>
                      </div>
                    </Grid.Col>
                      <Grid.Col sm={12} md={8}>
                        <Form.Group>
                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                          >
                            <Table.Header>
                              <Table.ColHeader>ID</Table.ColHeader>
                              <Table.ColHeader>idUnidadMarca</Table.ColHeader>
                              <Table.ColHeader>idGpsFijo</Table.ColHeader>
                              <Table.ColHeader>placa</Table.ColHeader>
                              <Table.ColHeader>color</Table.ColHeader>
                              <Table.ColHeader>modelo</Table.ColHeader>
                              <Table.ColHeader>Acción</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                              {
                                this.props.Unidades.filter(word => word.placa.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                  <Table.Row>
                                    <Table.Col>{ elemento["id"] }</Table.Col>
                                    <Table.Col>{ elemento["idUnidadMarca"] }</Table.Col>
                                    <Table.Col>{ elemento["idGpsFijo"] }</Table.Col>
                                    <Table.Col>{ elemento["placa"] }</Table.Col>
                                    <Table.Col>{ elemento["color"] }</Table.Col>
                                    <Table.Col>{ elemento["modelo"] }</Table.Col>
                                    <Table.Col>
                                      <div className="float-right">
                                        <Button pill  size="sm" outline color="primary" onClick={ ()=> this.UnidadEdit(index) }><Icon name="edit"/></Button>
                                        <span className="ml-2">
                                          <Button pill  size="sm" outline color="primary" onClick={ ()=> this.crud_delete(elemento.id) }><Icon name="trash"/></Button>
                                        </span>
                                      </div>
                                    </Table.Col>
                                  </Table.Row>
                                )
                              }
                            </Table.Body>
                          </Table>
                        </Form.Group>


                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tus datos antes de guardar, del lado derecho podras activar o desactivar los diferentes destinos, y eliminarlos ...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default Unidades;
