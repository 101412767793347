import React, { useState, useEffect } from "react";

export const LoadingScreen = ({ loading }) => {
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, [show]);

  if (!show) return null;

  return (
    <div className="loading-container">
      <img src="/images/loader.gif"></img>
    </div>
  );
};
