import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import Select from 'react-select';
class Perfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            nombre: "",
            movimientoBy: { value: null, label: "Seleccione una opción" },
            estatus: false,
            Privilegio_arraylist: [],
            accion: this.props.accion, //'nuevo'// modificar, nuevo
            privilegios: [],
            search: "",
            currentTap: 0,
            crudbutonEnable: true, // Se desabilita cuando se hace click
        };

        this.Privilegio_array = [];
        this.perfilPrivilegioId = 0;
        this.perfilObject = [];
        this.enableSave = this.enableSave.bind(this);

        this.checarPrivilegio = 0;      //temporal render
        this.idPerfilPrivilegio = 0; //temporal render
        this.perfilPrivilegioObject = [];
        this.PrivilegiosArray = [];
        this.movimientosby= [];
    }
    isEnable=(Accion)=>{
        let borrar= "perfiles_borrar";
        let actualizar= "perfiles_editar";
        let agregar= "perfiles_agregar";
        var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
        for (let i = 0;  i<privilegios.length; i++) {
            if(Accion === "borrar"){ 
            if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
            if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
            if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
        }
        return false
    }
    componentDidMount = () => {
        if (this.state.accion !== 'nuevo') {
            this.setState({ id: this.props.elementos.id });
            this.setState({ nombre: this.props.elementos.nombre });
            this.setState({ estatus: this.props.elementos.estatus });
            this.setState({ Privilegio_arraylist: this.Privilegio_array });
            this.setState({ privilegios: this.props.elementos.privilegios })
            this.perfilObject = this.props.elementos;
        } else {
            this.perfilObject = { "id": 0, "nombre": "", "movimientosBy": null, "estatus": 0, "privilegios": [] }
        }
        if( this.props.stor.movimientosby_object ){
            this.props.stor.movimientosby_object.map((movBy, index)=>{
              this.movimientosby.push({ value: movBy.id, label: movBy.nombre });
              if(this.state.accion !== 'nuevo' && this.props.elementos.movimientosBy === movBy.id){
                this.setState({ movimientoBy: { value: movBy.id, label: movBy.nombre } });
              }
            });
          }
    }
    componentDidUpdate = (nexProps) => {
        if (nexProps.elementos.privilegios !== this.state.privilegios) {
            //alert("es igual")
        } else { /*alert(this.state.privilegios) */ }
    }

    enableSave = () => {
        if (this.state.nombre.length > 0) {
            return false;
        } else { return true }
    }

    changeName = (event) => {
        this.perfilObject.nombre = event.target.value;
        this.setState({ nombre: event.target.value });
    }
    changMovimientoBy = (event) => {
        this.perfilObject.movimientosBy = event.value;
        this.setState({ movimientoBy: event });
    }
    changeEstatus = (event) => {
        this.perfilObject.estatus= event.target.checked ? 1:0;
        event.target.checked ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });

    }

    changePrivilegio = (privilegioindex, privId, estatus) => {
        this.perfilPrivilegioObject = {
            "idPerfil": this.state.id,
            "idPrivilegio": privId,
            "nombre": this.props.stor.privilegios_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[privilegioindex].nombre
        }
        if (this.PrivilegiosArray[privilegioindex].estatus === 1) { this.perfilObject.privilegios.splice(privilegioindex, 1) }
        this.PrivilegiosArray[privilegioindex].estatus === 1 ?
            this.props.getfuntion("delete", "", this.PrivilegiosArray[privilegioindex].id, this.perfilObject, "perfilesPrivilegios", this.props.stor.perfiles_update)
            :
            this.props.getfuntion("post", this.perfilPrivilegioObject, this.state.id, this.perfilObject, "perfilesPrivilegios", this.props.stor.perfiles_update, "privilegios");
    }

    delete()    {   this.setState({ crudbutonEnable: false }); this.props.getfuntion("delete", "", this.state.id); }
    crud_put()  {   this.setState({ crudbutonEnable: false }); this.props.getfuntion("put",  this.perfilObject); }
    crud_post() {   this.setState({ crudbutonEnable: false }); this.props.getfuntion("post", this.perfilObject); }

    checkedPrivilegio = (indice) => {
        return this.PrivilegiosArray[indice].estatus
    }
    checarPrivilegios = (estatus, id) => {
        this.PrivilegiosArray.push({ estatus: estatus, id: id })
    }
    checarPrivilegioTem = (valor) => {
        this.checarPrivilegio = valor;
    }
    searching = (event) => {
        this.setState({ search: event.target.value })
    }
    cleanArrayList = () => {
        this.PrivilegiosArray = [];
    }
    selectedTab = (index) => {
        this.setState({ currentTap: index })
    }
    render() {

        return (
            <div>
                {this.state.accion === "modificar" ? <h1> {this.props.elementos.nombre} </h1> : null}
                <Grid.Row>
                    <Grid.Col md={12} xl={12}>
                        <Grid.Row>
                            <Grid.Col md={2} lg={2}>
                                <Form.Group label="id">
                                    <Form.Input name="id" disabled type="number" value={this.state.id} />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                                <Form.Group label="Nombre">
                                    <Form.Input name="nombre" type="text" value={this.state.nombre} onChange={this.changeName} maxlength="30" />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={3} lg={3}>
                                <Form.Group label="Obtener movimentos por">
                                    <Select value={ this.state.movimientoBy } onChange={this.changMovimientoBy} options={ this.movimientosby } />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={3} lg={3}>
                                <Form.Group label="Estatus">
                                    <Form.Switch name="estatus" label="Activo" value={this.state.estatus} checked={this.state.estatus} onChange={this.changeEstatus} />
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>


                <div className="float-right">
                  {this.isEnable("actualizar" || this.isEnable("agregar")) ?
                    <span className="badge">
                        <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable}
                            onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()}
                        >
                            <span className="badge"><Icon link={true} name="save" />Guardar</span>
                        </Button>
                    </span>
                  :null}
                    {this.state.accion === "modificar" && this.isEnable("borrar") ?
                        <span className="badge">
                            <Button target="_blank" size="sm" RootComponent="a" color="primary"
                                onClick={() => this.delete()}  disabled={!this.state.crudbutonEnable}
                            >
                                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
                            </Button>
                        </span>
                        :
                        null
                    }
                </div>
            </div>
        );
    }
}
export default Perfil;
