import React, { useState, useEffect } from 'react';
import Select from "react-select";
import SiteWrapper from "../../SiteWrapper.react";
import { Button, Grid, Table, Icon, Form } from 'tabler-react';
import { confirmAlert } from 'react-confirm-alert';
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import ModalLayout from "../../components/ModalLayout.react";
var user = JSON.parse(sessionStorage.getItem("usuario"));


const currentUser= JSON.parse(sessionStorage.getItem("currentUser"));

const TableroVisibilidadCallCenterDhlPage = () => {  
  const [ tableroVisibilidadCallCenter, setTableroVisibilidadCallCenter ] = useState([]);
  const [ open, setOpen]= useState(true);
  const [ hiddenModals, setHiddenModals ]= useState(true);
  const [ incidencias, setIncidencias ] = useState({});
  const [ incidencia, setIncidencia ] = useState({});
  const [ comentario, setComentario ] = useState({});
  const [ entregasIncidencias, setEntregasIncidencias ] = useState([]);
  

  useEffect(() => {
    //document.title = `${plataforma.label}`;
    crud("get", "", "", "terrestreMovimientos/tablero_visibildad_call_center", "mov_tablero_visibilidad_call_center");
    crud("get", "", "", "incidencias/idplataforma/" + 1, "catalogo_incidencias");    
  }, []);
 

  const guardarIncidencia = (registro) => {
    crud("post", 
    {
      "idIncidencia"       :incidencia.value,
      "idMovimiento"       :registro.id_movimiento,
      "idEntrega"          :registro.id_entrega,  
      "comentario"         :comentario,
      "creado"             :null,
      "atencionUsuario"    :user.id,
      "atencionFecha"      :null,
      "latitud"            :0,
      "longitud"           :0,
    },
    "",
    "terrestreEntregasIncidencias",
    "incidenciasTerrestre");
  }

  const incidenciasNombre = (idIncidencia) => {
    for(let i of incidencias){
        console.log(i);      
      if(i.value === idIncidencia){        
        return i.data.nombre; 
      }
    }
    return '';
  }

  const listarIncidencias = (registro) => {
    crud("get", "", "", "terrestreEntregasIncidencias/entrega/" + registro.id_entrega, "incidenciasTerrestre");
  }
  
  return (    
    <SiteWrapper>
      <div className="container mt-3">
        <Grid.Row>
          <Grid.Col>
            <h2> Tablero de Visibildad </h2>
            <h3> Call Center </h3>
          </Grid.Col>          
        </Grid.Row>  
        <Grid.Row>
            <Grid.Col>
                <h2></h2>
            </Grid.Col>    
        </Grid.Row>                              
        <Grid.Row>           
            <div className="mt-5" >
            {/*Solo se muestran los transportista con movimientos activos*/}
            </div>           
            <Table border={1} cards={true} striped={false} responsive={true} className="table-vcenter text-dark border border-primary" style={{ "background-color": "#F3F5E9"}}
              headerItems={[
                {content:"CV"},
                {content:"Linea de transporte"},
                {content:"Placa"},                
                {content:"Origen"},
                {content:"Destino"}, 
                {content:"Contacto de Transportista"},                
                {content:"Cita de entrega"},
                {content:"Asignación de Monitoreo"},                
                {content:"Incidente Operativo"},
                {content:"Recibido / Rechazado"}
              ]}>
              <Table.Header ></Table.Header>
              <Table.Body>
              { tableroVisibilidadCallCenter.sort().map((w, index) =>
                <Table.Row align="center" style={ w.cuenta_espejo === 0 ? { "background-color": "rgba(0, 0, 0, .05)" } : { "background-color": "rgba(243, 233, 19, .30)" }}>
                  <Table.Col><font size="2"> { w.control_vehicular } </font></Table.Col>
                  <Table.Col><font size="2"> { w.nombre_transportista } </font></Table.Col>
                  <Table.Col><div style={ { "color": "#F39C12"} }><font size="2">{ w.placa }</font></div></Table.Col>                  
                  <Table.Col className={w.origen ? "text-secondary" : "text-dart"}><font size="2"> { w.origen } </font></Table.Col>                                    
                  <Table.Col><div  style={ { "color": "#F95143"} }> <font size="2"> { w.nombre_destino } </font></div></Table.Col>                                    
                  <Table.Col><div style={ { "color": "#212F3D"} }><font size="2"> { w.nombre_operador } </font></div></Table.Col>                  
                  <Table.Col><font size="5">  </font> </Table.Col>
                  <Table.Col><font size="5">  </font></Table.Col>                                                                                                                                                 
                  <Table.Col>
                    <ModalLayout
                      tamanio={"50%"}
                      title="Nueva incidencia"
                      name={<Button type="button" className="btn btn-dark fa fa-pencil" onClick={ () => listarIncidencias(w)} ></Button>}
                      open={open}
                      setOpen={ setOpen }
                      hiddenModal={ hiddenModals }
                      Fclose={ setHiddenModals }
                      formulario={
                        <div align="center">
                          <Grid.Row>
                            <Grid.Col md={12} xl={12}>
                              <Grid.Row>

                                  <Grid.Col md={4} lg={4}>
                                    <Form.Group label="Incidencias"><Select value={ incidencia } onChange={(e) => setIncidencia(e) } options={ incidencias } /></Form.Group>
                                  </Grid.Col>
                                  <Grid.Col md={2} lg={2}>
                                    <Form.Group label="Latitud">
                                        <Form.Input name="latitud"  value="" onChange="" />
                                    </Form.Group>
                                  </Grid.Col>
                                  <Grid.Col md={2} lg={2}>
                                    <Form.Group label="Longitud">
                                      <Form.Input name="longitud"  value="" onChange="" />
                                    </Form.Group>
                                  </Grid.Col>
                                  <Grid.Col md={12}>
                                      <Form.Group label="Comentario">
                                          <Form.Textarea name="comentario" type="text" value="" onChange={(e) => setComentario(e.target.value)} maxlength="50" />
                                      </Form.Group>
                                  </Grid.Col>
                                  <span className="badge">                                    
                                      <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled="" onClick={ () => guardarIncidencia(w) } >
                                        <span className="badge"><Icon link={true} name="save" />Guardar Incidencia</span>
                                      </Button>                                    
                                  </span>                                  
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Col><font size="2"><h2></h2></font></Grid.Col>                                  
                              </Grid.Row>
                              <Grid.Row>                                
                                <Grid.Col><font size="1"><h4 align="left">Listado de Incidencias</h4></font></Grid.Col>
                              </Grid.Row>
                              <Grid.Row>
                              <Table border={1} cards={true} striped={false} responsive={true} className="table-vcenter text-dark border border-primary" style={{ "background-color": "#F3F5E9"}}
                                headerItems={[
                                  {content:"Incidencia"},
                                  {content:"Comentario"}
                                ]}>
                                <Table.Header ></Table.Header>
                                <Table.Body>
                                {entregasIncidencias.map((ei, indice1) => 
                                  <Table.Row align="center">
                                  <Table.Col><font size="2"> { incidenciasNombre( ei.idIncidencia )} </font></Table.Col>
                                  <Table.Col><font size="2"> { ei.comentario } </font></Table.Col>
                                  </Table.Row>
                                )}
                                </Table.Body>
                              </Table>
                              </Grid.Row>
                            </Grid.Col>
                          </Grid.Row>
                        </div>
                      }
                    />  
                  </Table.Col>                                                 
                  <Table.Col> 
                    <ModalLayout
                      tamanio={"50%"}
                      title="Modificar"
                      name={<Button type="button" className="btn btn-dark fa fa-calendar-check-o"></Button>}
                      open={open}
                      setOpen={ setOpen }
                      hiddenModal={ hiddenModals }
                      Fclose={ setHiddenModals }
                      formulario={
                        <div align="center">
                          <Form.Group label="fecha y hora en que arribo la unidad a su destino:">
                            
                            
                          </Form.Group>
                          <h3>Captura la fecha y hora: </h3>
                          <button type="button" className="btn btn-dark fa fa-check-square" onClick={()=> alert("Detalle") }></button>
                        </div>
                      }
                    />
                  </Table.Col>
                </Table.Row>
              )}
              </Table.Body>
            </Table>
        </Grid.Row>           
        <Grid.Row>
          <Grid.Col>
            <h2> </h2>
            <h2> </h2>
          </Grid.Col>
        </Grid.Row>        
      </div>     
    </SiteWrapper>    
  );
  


  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    if(stateVar === "mov_tablero_visibilidad_call_center" && returnVal) {
                      setTableroVisibilidadCallCenter(returnVal);                     
                    }                    
                     if(stateVar === "catalogo_incidencias" && returnVal){
                      let incidenciasTem = [];
                      returnVal.map((i, index) => incidenciasTem.push({value:i.id, label:i.nombre, data:i}));
                      setIncidencias(incidenciasTem); 
                     }
                     if(stateVar === "incidenciasTerrestre" && returnVal){
                        setEntregasIncidencias(returnVal);
                      }
                } break;
                case "put": {
                  
                } break; 
                case "post": {
                  if(stateVar === "incidenciasTerrestre"){
                    setHiddenModals(true);
                  }
                } break;
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }
}


export default TableroVisibilidadCallCenterDhlPage;
