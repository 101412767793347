import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
class Operadores extends Component {
  constructor(props) {
    super(props);
    this.state = {
                                id: "",
                            accion: "post", //'nuevo'// modificar, nuevo
                            nombre: "",
                           paterno: "",
                           materno: "",
                 medioComunicacion: "",
                      licenciaTipo: "",
                  licenciaVigencia: "",
                        operadores: [],
                     transportista: 0,
                            search: "",
                 };
                   this.operadores  = [];
                this.operadoresObj  = {
                                        "id":"",
                                        "nombre":"",
                                        "paterno":"",
                                        "materno":"",
                                        "medioComunicacion":"",
                                        "licenciaTipo":"",
                                        "licenciaVigencia":"",
                                        "idTransportista":this.props.idTransportista
                                      };
                this.valid = {nombre:false, paterno:false, materno:false, medioComunicacion:false, licenciaTipo:false, licenciaVigencia:false };
  }
  isValid=(campo) =>{
    switch (campo) {
      case "nombre":            return this.valid.nombre;             break;
      case "paterno":           return this.valid.paterno;            break;
      case "materno":           return this.valid.materno;            break;
      case "medioComunicacion": return this.valid.medioComunicacion;  break;
      case "licenciaTipo":      return this.valid.licenciaTipo;       break;
      case "licenciaVigencia":  return this.valid.licenciaVigencia;   break;
      default:                                                        break;
    }
  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      //this.getfuntion("get", "", "", "operadores/transportista/"+this.props.elementos.id,"operadores")
      //this.getfuntion("get", "", "", "plataformasTransportistas/Plataformas/"+this.props.elementos.id,"plataformasTransportistas")
      console.log("Operadores: this.props.idTransportista");
      console.log(this.props.idTransportista);
      this.getfuntion("get", "", "", "operadores/transportista/"+this.props.idTransportista,"operadores")
    }else{
    }
  }
  changeNombre =(event) =>{
    this.setState({nombre:event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.nombre= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.nombre= false;
      }
    }
    this.operadoresObj.nombre = event.target.value;
  }
  changePaterno =(event) =>{
    this.setState({paterno:event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.paterno= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.paterno= false;
      }
    }
    this.operadoresObj.paterno = event.target.value;
  }
  changeMaterno =(event) =>{
    this.setState({materno:event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.materno= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.materno= false;
      }
    }
    this.operadoresObj.materno = event.target.value;
  }
  changeMedio =(event) =>{
    this.setState({medioComunicacion:event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.medioComunicacion= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.medioComunicacion= false;
      }
    }
    this.operadoresObj.medioComunicacion = event.target.value;
  }
  changeLicencia =(event) =>{
    this.setState({licenciaTipo:event.target.value});
    if(event.target.value.length > 0 ){
      this.valid.licenciaTipo= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.licenciaTipo= false;
      }
    }
    this.operadoresObj.licenciaTipo = event.target.value;
  }
  changeVigencia =(event) =>{
    this.setState({licenciaVigencia:event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.licenciaVigencia= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.licenciaVigencia= false;
      }
    }
    this.operadoresObj.licenciaVigencia = event.target.value;
  }

  OperadorEdit =(index) =>{
    let current= this.state.operadores.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[index];
    this.setState({            nombre: current.nombre });
    this.setState({           paterno: current.paterno });
    this.setState({           materno: current.materno });
    this.setState({ medioComunicacion: current.medioComunicacion });
    this.setState({      licenciaTipo: current.licenciaTipo });
    this.setState({  licenciaVigencia: current.licenciaVigencia });
    this.operadoresObj = current;
    this.valid = {nombre:true, paterno:true, materno:true};
    this.setState({ accion: "modificar" });
  }
  crud_put =() =>{
    this.getfuntion("put", this.operadoresObj, "", "operadores","operadores");
    this.setState({            nombre:"" });
    this.setState({           paterno:"" });
    this.setState({           materno:"" });
    this.setState({ medioComunicacion:"" });
    this.setState({      licenciaTipo:"" });
    this.setState({  licenciaVigencia:"" });
    this.operadoresObj = { "idTransportista":this.props.idTransportista, "nombre":"", "paterno":"", "materno":"", "medioComunicacion":"", "licenciaTipo":"", "licenciaVigencia":"" };
    this.valid = {nombre:false, paterno:false, maternop:false};
    this.setState({ accion: "nuevo" });
  }
  crud_delete =(id) =>{
    this.getfuntion("delete", "", id, "operadores","operadores");
  }
  crud_post =() =>{
    this.getfuntion("post", this.operadoresObj, "", "operadores","operadores");
    this.setState({            nombre:"" });
    this.setState({           paterno:"" });
    this.setState({           materno:"" });
    this.setState({ medioComunicacion:"" });
    this.setState({      licenciaTipo:"" });
    this.setState({  licenciaVigencia:"" });
    this.operadoresObj = { "idTransportista":this.props.idTransportista, "nombre": "", "paterno":"", "materno":"", "medioComunicacion":"", "licenciaTipo":"", "licenciaVigencia":"" };
    this.valid = {nombre:false, paterno:false, materno:false};
    this.setState({ accion: "nuevo" });
  }
  disableSave =() =>{
    let band= false;
    Object.keys(this.valid).forEach(key =>{ if( !this.valid[key]){ band=true; } });
    if(band){ return true }else{ return false }
  }

  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "transportistas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo)  {
          case "get":    {if (stateVar === "operadores"){ this.operadores= returnVal;       this.setState({ operadores:returnVal });} } break;
          case "post":   {if (stateVar === "operadores"){ this.operadores.push(returnVal);  this.setState({ operadores:this.operadores }); } } break;
          case "put":    {if (stateVar === "operadores" && returnVal.id){ for (let item of  this.operadores) { if( item.id === returnVal.id ){ item= returnVal;
                                                                                            this.setState({ operadores:this.operadores }); break; } } } } break;
          case "delete": {if (stateVar === "operadores"){ for (let i=0; i< this.operadores.length; i++) { if( this.operadores[i].id === id ){ this.operadores.splice(i, 1);
                                                                                            this.setState({ operadores:this.operadores }); break; } } } } break;
          default:break; }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{/**Error*/
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }
  searching =(event) =>{ this.setState({ search: event.target.value }) }

  render(){
    return (
      <div>
        {/**
          *   Configuracion de correo
          */
        }
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="operadores" isCollapsible={ false } isCollapsed={ false}
                body={
                  <div>
                    <Grid.Row>
                    <Grid.Col sm={12} md={12}>
                      <Grid.Col sm={12} md={4} className="float-right">
                        <Form.Input className="mb-3" icon="search" placeholder="Buscar Nombre..." position="append" onChange={this.searching} />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col sm={12} md={4}>
                      <Form.Group label="Nombre">
                        <Form.Input
                          type="text" name="nombreDes" feedback="Al menos 4 caracteres" value={this.state.nombre} placeholder="Ingresa Nombre ..."
                          invalid={ this.state.nombre!=null? !this.isValid("nombre") && this.state.nombre.length > 0:false }
                          valid  ={ this.state.nombre!=null?  this.isValid("nombre") && this.state.nombre.length > 0:false }
                          onChange={this.changeNombre}
                        />
                      </Form.Group>
                      <Form.Group label="Paterno">
                        <Form.Input
                          type="text" name="paternoDes" feedback="Invalido Apellido" value={this.state.paterno} placeholder="Ingresa Apellido Paterno..."
                          invalid={ this.state.paterno!=null? !this.isValid("paterno") && this.state.paterno.length > 0:false }
                          valid  ={ this.state.paterno!=null?  this.isValid("paterno") && this.state.paterno.length > 0:false }
                          onChange={this.changePaterno}
                        />
                      </Form.Group>
                      <Form.Group label="Materno">
                        <Form.Input
                          type="text" name="maternoDes" feedback="Invalido Apellido" value={this.state.materno} placeholder="Ingresa Apellido Materno..."
                          invalid={ this.state.materno!=null? !this.isValid("materno") && this.state.materno.length > 0:false }
                          valid  ={ this.state.materno!=null?  this.isValid("materno") && this.state.materno.length > 0:false }
                          onChange={this.changeMaterno}
                        />
                      </Form.Group>
                      <Form.Group label="Medio de Comunicacion">
                        <Form.Input
                          type="text" name="medioComunicacionDes" feedback="Invalido Medio Comunicacion" value={this.state.medioComunicacion} placeholder="Ingresa Medio Comunicacion ..."
                          invalid={ this.state.medioComunicacion? !this.isValid("medioComunicacion") && this.state.medioComunicacion.length > 0:false }
                          valid={   this.state.medioComunicacion?  this.isValid("medioComunicacion") && this.state.medioComunicacion.length > 0:false }
                          onChange={this.changeMedio}
                        />
                      </Form.Group>
                      <Form.Group label="Licencia Tipo">
                        <Form.Input
                          type="text" name="licenciaTipo" feedback="Invalido Licencia Tipo" value={this.state.licenciaTipo} placeholder="Ingresa Licencia Tipo ..."
                          invalid={ this.state.licenciaTipo? !this.isValid("licenciaTipo") && this.state.licenciaTipo.length>0:false }
                          valid  ={ this.state.licenciaTipo?  this.isValid("licenciaTipo") && this.state.licenciaTipo.length>0:false }
                          onChange={this.changeLicencia}
                        />
                      </Form.Group>
                      <Form.Group label="Licencia Vigencia">
                        <Form.Input
                          type="text" name="licenciaVigencia" feedback="Invalido Licencia Vigencia" value={this.state.licenciaVigencia} placeholder="Ingresa Licencia Vigencia ..."
                          invalid={ this.state.licenciaVigencia? !this.isValid("licenciaVigencia") && this.state.licenciaVigencia.length>0:false }
                          valid  ={ this.state.licenciaVigencia?  this.isValid("licenciaVigencia") && this.state.licenciaVigencia.length>0:false }
                          onChange={this.changeVigencia}
                        />
                      </Form.Group>

                      <div className="float-right">
                        <span className="badge">
                            <Button size="sm" RootComponent="a" color="primary" disabled= { this.disableSave() }
                                onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() }
                            >
                                <span className="badge"><Icon link={true} name="save" />Guardar</span>
                            </Button>
                        </span>
                      </div>
                    </Grid.Col>
                      <Grid.Col sm={12} md={8}>
                        <Form.Group>
                          <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                            <Table.Header>
                              <Table.ColHeader>ID</Table.ColHeader>
                              <Table.ColHeader>Nombre</Table.ColHeader>
                              <Table.ColHeader>Paterno</Table.ColHeader>
                              <Table.ColHeader>Materno</Table.ColHeader>
                              <Table.ColHeader>Acción</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                              {
                                this.state.operadores.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                  <Table.Row>
                                    <Table.Col>{ elemento["id"] }</Table.Col>
                                    <Table.Col>{ elemento["nombre"] }</Table.Col>
                                    <Table.Col>{ elemento["paterno"] }</Table.Col>
                                    <Table.Col>{ elemento["materno"] }</Table.Col>
                                    <Table.Col>
                                        <Button pill  size="sm" outline color="primary" onClick={ ()=> this.OperadorEdit(index) }><Icon name="edit"/></Button>
                                        <Button pill  size="sm" outline color="primary" onClick={ ()=> this.crud_delete(elemento.id) }><Icon name="trash"/></Button>
                                    </Table.Col>
                                  </Table.Row>
                                )
                              }
                            </Table.Body>
                          </Table>
                        </Form.Group>


                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tus datos antes de guardar, del lado derecho podras activar o desactivar los diferentes operadores, y eliminarlos ...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default Operadores
