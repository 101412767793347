// @flow
import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from 'react-select';

class IncidenciasForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombre: " ",
      estatus: "",
      idIncidenciaTipo: { value: "id", label: "Selecciona Tipo Incidencia" },
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      crudbutonEnable: true, // Se desabilita cuando se hace click
      etiqueta: {value: 0, label: "Selecciona una etiqueta", data: [] }
    };
    this.incidenciasObject = [];
    this.incidencia = [];
    this.etiquetas = [];
    this.enableSave = this.enableSave.bind(this);
  }
  isEnable=(Accion)=>{
    let borrar= "incidencias_borrar";
    let actualizar= "incidencias_editar";
    let agregar= "incidencias_agregar";
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "borrar"){ 
        if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }
  componentDidMount = () => {
    if (this.state.accion === 'nuevo') {
      this.incidenciasObject ={ "nombre": "", "estatus": 0, "incidenciaTipo": {}, "idEtiqueta": null };
    } else {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ estatus: this.props.elementos.estatus });
      this.setState({ idIncidenciaTipo: { value: this.props.elementos.incidenciaTipo.id, label: this.props.elementos.incidenciaTipo.nombre, data: this.props.elementos.incidenciaTipo } });
      this.incidenciasObject = this.props.elementos;
    }
    /**
     * Tipo de incidencia
     */
    this.incidencia.push({ value: 0, label: "Selecciona Tipo Incidencia" })
    this.props.stor.incidenciasTipo_object.map((incidencia, index) => {
      this.incidencia.push({ value: incidencia.id, label: incidencia.nombre, data: incidencia });
    });
    /**
     * etiqueta,    solo se mostrara donde el nombre incluya incidencia y la descripcion no incluya correo
     */
    //this.etiquetas
    this.setState({ etiqueta: {value: 0, label: "Selecciona una etiqueta", data: [] }  });
    this.props.stor.etiquetas_object.map((etiqueta, index)=>{
      if( etiqueta.nombre.toUpperCase().includes("INCIDENCIA") && !etiqueta.descripcion.toUpperCase().includes("ARROBA") ){
        if(this.props.elementos.idEtiqueta){
          if( this.props.elementos.idEtiqueta == etiqueta.id){
            this.setState({ etiqueta: {value: etiqueta.id, label: etiqueta.descripcion, data: etiqueta.id }  });
          }
        }
        this.etiquetas.push({value: etiqueta.id, label: etiqueta.descripcion, data: etiqueta.id });
      }
    });

  }

  enableSave = () =>{
    if(this.state.nombre.length > 0 && this.state.idIncidenciaTipo.value > 0){
      return false;
    }else{return true}
  }

  changeTipoIncidencia = (event) => {
    this.setState({ idIncidenciaTipo: event });
    this.incidenciasObject.incidenciaTipo = event.data;
  }

  changeEtiqueta = (event) => {
    this.setState({ etiqueta: event });
    this.incidenciasObject.idEtiqueta = event.data;
  }

  changeName = (event) => { this.setState({ nombre: event.target.value }); this.incidenciasObject.nombre = event.target.value}

  changeEstatus = (event) => {
    this.incidenciasObject.estatus= event.target.checked ? 1 :0
    event.target.checked ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 })
   
  }

  delete () {   this.setState({ crudbutonEnable: false });  this.props.getfuntion("delete", "", this.state.id);}
  crud_put() {  this.setState({ crudbutonEnable: false });  this.props.getfuntion("put", this.incidenciasObject )}
  crud_post() { this.setState({ crudbutonEnable: false });  this.props.getfuntion("post", this.incidenciasObject );};
  

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? <h1> {this.props.elementos.nombre} </h1> : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="id">
                  <Form.Input name="id" disabled type="number" value={this.state.id} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={7} lg={7}>
                <Form.Group label="Nombre">
                  <Form.Input name="nombre" type="text" value={this.state.nombre} onChange={this.changeName} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="Estatus">
                  <Form.Switch name="estatus" label="" value={this.state.estatus} checked={this.state.estatus} onChange={this.changeEstatus} />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Form.Group label="Etiqueta">
              <Select value={this.state.etiqueta} onChange={this.changeEtiqueta} options={ this.etiquetas } />
            </Form.Group>
            <Form.Group label="Tipo de Incidencia">
              <Select value={this.state.idIncidenciaTipo} onChange={this.changeTipoIncidencia} options={this.incidencia} />
            </Form.Group>

          </Grid.Col>
        </Grid.Row>
        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                <span className="badge"><Icon link={true} name="save" />Guardar</span>
              </Button>
            </span>
          :null}
          {this.state.accion === "modificar" && this.isEnable("borrar")?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()}  disabled={!this.state.crudbutonEnable}>
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
            :
            null
          }
        </div>
      </div>
    );
  }
}
export default IncidenciasForm;
