import * as conf from "../config/config";
import axios from 'axios';
//import $ from "jquery";
export function Auth(type="logged", user="", password="", code= ""){ //type="logged" //loging
  var tokenStore="tok";
  var deadTokenTime="expired";
  let instance = axios.create();
  if(sessionStorage.getItem('tok')){
    instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem('tok');
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Accept'] = 'application/json';  
  }
  return axios.post(conf.api_raiz+"token/generate-token",{"username": user, "password": password, "code": code}).then(res => {
    if(res.data["token"]){
      sessionStorage.setItem(tokenStore, res.data["token"]);
      sessionStorage.setItem(deadTokenTime, (new Date().getTime()/1000)+ ((60*60*5)-60) );
    }
    return res.data;
  }).catch(err => {
    if (err.response) {
      if( err.response.data.status === 401 ){
        sessionStorage.setItem(tokenStore, err.response.data["token"] ? err.response.data["token"]: "");
      }
      return err.response.data;
    } else {
      sessionStorage.setItem(tokenStore, "");
      return null;
    }
  });;
}
  export default Auth;
