import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import {timeToDateFillZ} from '../../herramientas/DateFormat';
var gpss= {};
var gpsReciente= {};

export function GpsEstatusTerrestre(movimientos, update ){
  gpss= {};
  gpsReciente= {};
  let movsGps= {};
    for(let mov of movimientos){
      if(mov.movimientoGps){
        for(let g of mov.movimientoGps){
          movsGps[g.gps.id]= g;
        }
      }
    }

    getfuntion("get", "", "", "gpsUltReporte/terrestre?operacion_estatus=2", "gpsestatus").then((returnVal) => {
      update({ gpsEstatus: gpss, gpsReciente: gpsReciente });
    });
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getfuntion (metodo="get", obj=[], id="", catalogo="entregas", stateVar= "gpsestatus", hiddenModl="") {
      return Crud_Catalogos(catalogo, "gpsestatus", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
          switch (metodo) {
            case "get":
              if (stateVar === "gpsestatus" && returnVal){
                for(let gps of returnVal){
                  let ultimoReporteMs=  gps.deviceActivity;
                  if( movsGps[gps.idGps] ){
                    /**
                     * Gps por idGg
                     */
                    gpss[gps.idGps]= gps;
                    gpss[gps.idGps].deviceActivity= getUltimoReporteString(ultimoReporteMs);
                    /**
                     * Gps reciente por movimiento
                     */
                    let iconBattery= getIconBattery(gps.bateria);
                    let iconTime= getIconTime(ultimoReporteMs);

                    if( gpsReciente[movsGps[gps.idGps].idMovimiento]){
                      if( ultimoReporteMs > gpsReciente[movsGps[gps.idGps].idMovimiento].ultimo_reporte_ms ){
                        gpsReciente[movsGps[gps.idGps].idMovimiento]=
                          { 
                            nombre:             movsGps[gps.idGps].gps.nombre,
                            idGg:               gps.idGps,
                            nivel_bateria:      gps.bateria,
                            ultimo_reporte:     getUltimoReporteString(ultimoReporteMs),
                            ultimo_reporte_ms:  ultimoReporteMs,
                            iconBattery:        iconBattery,
                            iconTime:           iconTime,
                            latitud:            gps.latitud,
                            longitud:           gps.longitud
                          };
                      }
                    }else{                       
                      gpsReciente[movsGps[gps.idGps].idMovimiento]=
                        { 
                          nombre:             movsGps[gps.idGps].gps.nombre,
                          idGg:               gps.idGps,
                          nivel_bateria:      gps.bateria,
                          ultimo_reporte:     getUltimoReporteString(ultimoReporteMs),
                          ultimo_reporte_ms:  ultimoReporteMs,
                          iconBattery:        iconBattery,
                          iconTime:           iconTime,
                          latitud:            gps.latitud,
                          longitud:           gps.longitud
                        };
                    }
                    //return { gpsEstatus: gpss, gpsReciente: gpsReciente };
                  }
                }
              }
              break;
            default:
              break;
          }
        }).catch(err =>{
            if( err.response ){ console.log("err.response: "+err.response);  }else{  console.log("err: "+err);  }
        });
    }
    function getUltimoReporteString(fechaMs) {
      const dif=   new Date().getTime() - new Date(timeToDateFillZ(fechaMs)).getTime();
      let dias=   Math.trunc((dif)/(1000*60*60*24));
      let horas=  Math.trunc((dif%(1000*60*60*24))/(1000*60*60));
      let min=    Math.trunc((dif%(1000*60*60))/(1000*60));
      let tiempoString= "";
      tiempoString= tiempoString+( dias? dias+ " días, ": "");
      tiempoString= tiempoString+( horas? horas+ " hrs, ": "");
      tiempoString= tiempoString+( min? min+ " min": "");

      return tiempoString;
    }
    function getIconTime(ms) {
      const dif=   new Date().getTime() - new Date(timeToDateFillZ(ms)).getTime();
      let iconTemp= {icono: "fa fa-eye-slash", color: "red" };
      if(dif <  (1000*60*10) && dif >= 0            ){ iconTemp= {icono: "fa fa-eye",       color: "green" };  }
      if(dif >= (1000*60*10) && dif <= (1000*60*20) ){ iconTemp= {icono: "fa fa-eye",       color: "orange" }; }
      if(dif >  (1000*60*20)                        ){ iconTemp= {icono: "fa fa-eye-slash", color: "red" };    }
      return iconTemp;
    }
    function getIconBattery(bt) {
      let iconTemp= {icono: "fa fa-battery-0",       color: "red" };
      if(bt >= 0 && bt <= 5    ){ iconTemp= {icono: "fa fa-battery-0",       color: "red" };   }
      if(bt > 5  && bt <= 15   ){ iconTemp= {icono: "fa fa-battery-1",       color: "red" };   }
      if(bt > 15 && bt <= 30   ){ iconTemp= {icono: "fa fa-battery-1",       color: "orange"}; }
      if(bt > 30 && bt <= 40   ){ iconTemp= {icono: "fa fa-battery-1",       color: "green"};  }
      if(bt > 40 && bt <= 65   ){ iconTemp= {icono: "fa fa-battery-2",       color: "green"};  }
      if(bt > 65 && bt <= 90   ){ iconTemp= {icono: "fa fa-battery-3",       color: "green"};  }
      if(bt > 90 && bt <= 100  ){ iconTemp= {icono: "fa fa-battery-4",       color: "green"};  }
      return iconTemp;
    }
}

  export default GpsEstatusTerrestre;
