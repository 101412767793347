import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { NotificationManager } from 'react-notifications';
import { timingSafeEqual } from "crypto";

class EtaConfiguracion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      tiempoForaneo: "",
      tiempoLocal: "",
      velocidadPromedio: "",
      estatus: 0,
      plataformaEta: [],

    };
    this.plataformaEta = [];
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "plataformaEta") {
              if (returnVal) {          
                this.plataformaEta = returnVal; this.setState({ plataformaEta: returnVal });
                //this.props.setEtaConfiguracion(returnVal);
                this.setState({ id: returnVal.id });
                this.setState({ tiempoForaneo: returnVal.tiempoForaneo });
                this.setState({ tiempoLocal: returnVal.tiempoLocal });
                this.setState({ velocidadPromedio: returnVal.velocidadPromedio });
                this.setState({ estatus: returnVal.estatus });
              } else {
                this.setState({ accion: "nuevo" })
                this.plataformaEta = { "tiempoForaneo": "", "tiempoLocal": "", "velocidadPromedio": "", "estatus": "", "idPlataforma": this.props.elementos.id };
              }

            }
          }
          break;
        case "post":
          {
            if (stateVar === "plataformaEtaConfiguracion") { NotificationManager.info("Se agrega configuración", "Correcto"); }
            if (stateVar === "plataformaEta") {
              if (returnVal.id) {
                this.getfuntion("post", { "idPlataforma": this.props.elementos.id, "idEtaConfiguracion": returnVal.id },
                  "", "plataformasEta", "plataformaEtaConfiguracion");
                this.setState({ accion: "modificar" });
                this.setState({ tiempoForaneo: returnVal.tiempoForaneo });
                this.setState({ tiempoLocal: returnVal.tiempoLocal });
                this.setState({ velocidadPromedio: returnVal.velocidadPromedio });
                this.setState({ estatus: returnVal.estatus });
                this.plataformaEta = returnVal;
              } else { alert("error intenta de nuevo ..!") }
            }
          }
          break;
        case "put":
          {
            if (stateVar === "configuracion") {
              if (returnVal.idCorreoConfiguracion) {
                this.configuracion = returnVal;
              } else {
                alert("error intenta de nuevo ..!")
              }
            }
          }
          break;
        case "delete":
          {
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response);
      } else {
        /**
         * Error
         */
        console.log("Error desconocido ....");
        console.log(err)
      }
    })
  }

  componentDidMount = () => {
      this.getfuntion("get", "", "", "etaConfiguracion/plataforma/" + this.props.elementos.id, "plataformaEta");
  }




  changeName = (event) => this.setState({ nombre: event.target.value });

  changeTiempoForaneo = (event) => this.setState({ tiempoForaneo: event.target.value });

  changeTiempoLocal = (event) => this.setState({ tiempoLocal: event.target.value });

  changeVelocidadPromedio = (event) => this.setState({ velocidadPromedio: event.target.value });


  changeEstatus = (event) => {
    event.target.checked ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });

  }

  delete() {
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.getfuntion("put", {
      "id": this.state.id,
      "tiempoForaneo": this.state.tiempoForaneo,
      "tiempoLocal": this.state.tiempoLocal,
      "velocidadPromedio": this.state.velocidadPromedio,
      "estatus": this.state.estatus
    }, "", "etaConfiguracion", "plataformaEta");
  }
  crud_post() {
    this.getfuntion("post", {
      "tiempoForaneo": this.state.tiempoForaneo,
      "tiempoLocal": this.state.tiempoLocal,
      "velocidadPromedio": this.state.velocidadPromedio,
      "estatus": this.state.estatus
    }, "", "etaConfiguracion", "plataformaEta");
  }



  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? <h1>{this.props.elementos.tiempoForaneo}</h1> : null}
        <div>
          {this.props.tipoPlataforma.toUpperCase().includes("NAVIERA") ?
           <Grid.Row>
           <Grid.Col>
             <Card
               title="Configuración"
               isCollapsible={false}
               //isClosable
               isCollapsed={false}
               //options={ }
               body={
                 <div>
                   <Grid.Row>

                     <Grid.Col sm={12} md={6}>
                       <Form.Group label="Tiempo Foraneo">
                         <Form.Input name="tiempoForaneo" type="text" value={this.state.tiempoForaneo} onChange={this.changeTiempoForaneo} />
                       </Form.Group>
                     </Grid.Col>

                     <Grid.Col sm={12} md={6}>
                       <Form.Group label="Tiempo Local">
                         <Form.Input name="tiempoLocal" type="text" value={this.state.tiempoLocal} onChange={this.changeTiempoLocal} />
                       </Form.Group>
                     </Grid.Col>

                   </Grid.Row>
                   <Grid.Row>
                     <Grid.Col sm={12} md={6}>
                       <Form.Group label="Velocidad Promedio">
                         <Form.Input name="velocidadPromedio" type="number" value={this.state.velocidadPromedio} onChange={this.changeVelocidadPromedio} />

                       </Form.Group>
                     </Grid.Col>
                     <Grid.Col sm={12} md={6}>
                       <Form.Group label="Activo">
                         <Form.Switch name="estatus" label="" checked={this.state.estatus} onChange={this.changeEstatus} />

                       </Form.Group>
                     </Grid.Col>
                     <Grid.Col sm={12} md={12}>
                       <div className="float-right">
                         <span className="badge">
                           <Button size="sm" RootComponent="a" color="primary"
                             onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()}
                           >
                             <span className="badge"><Icon link={true} name="save" />Guardar</span>
                           </Button>
                         </span>
                       </div>
                     </Grid.Col>
                   </Grid.Row>
                 </div>
               }
             />
           </Grid.Col>

         </Grid.Row>
          
          
          
          : 

          <Grid.Row>
          <Grid.Col>
            <Card
              title="Configuración"
              isCollapsible={false}
              //isClosable
              isCollapsed={false}
              //options={ }
              body={
                <div>
                  <Grid.Row>

                   
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col sm={12} md={6}>
                      <Form.Group label="Velocidad Promedio">
                        <Form.Input name="velocidadPromedio" type="number" value={this.state.velocidadPromedio} onChange={this.changeVelocidadPromedio} />

                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={12} md={6}>
                      <Form.Group label="Activo">
                        <Form.Switch name="estatus" label="." checked={this.state.estatus} onChange={this.changeEstatus} />

                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={12} md={12}>
                      <div className="float-right">
                        <span className="badge">
                          <Button size="sm" RootComponent="a" color="primary"
                            onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()}
                          >
                            <span className="badge"><Icon link={true} name="save" />Guardar</span>
                          </Button>
                        </span>
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                </div>
              }
            />
          </Grid.Col>

        </Grid.Row>
         
          
          }
         
        </div>
      </div>
    )
  }
}

export default EtaConfiguracion
