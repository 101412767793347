import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import { Grid, Button, Icon, Form } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { Exportar } from '../../herramientas/Excel';
import { data } from 'jquery';
import { timeToDatefull } from "../../herramientas/DateFormat";
import * as conf from "../../config/config";


const currentUser= JSON.parse(sessionStorage.getItem("currentUser"));

let plataformas=[];
let funcionalidades= [];
//let fecha1= null; timeToDatefull( new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*7)).getTime() ),


const ReportesPage = () => {
  const [ plataforma, setPlataforma ] = useState( { value: 0, label: "Seleccione una opción", data: null } );
  const [ informes, setInformes ] = useState([]);
  const [ informe, setInforme ] = useState( {} );
  const [ datos, seDatos ] = useState([]);
  const [ fecha1, setFecha1]= useState(null);
  const [ fecha2, setFecha2]= useState(null);
  const [ todas, setTodas]= useState(false);
  const [ tipoMovimiento, setTipoMovimiento]= useState("0");
  const [ ano, setAno ] = useState(new Date().getFullYear());
  const [ semana, setSemana ] = useState(new Date().getWeekNumber());

  useEffect(() => {
    if( !plataformas.length ){
      currentUser.map((cUser, index)=> plataformas.push({ value: cUser.plataforma.id, label: cUser.plataforma.nombre, data: cUser }) );
    }
    if(currentUser.length === 1){
      changePlataforma({ value: currentUser[0].plataforma.id, label: currentUser[0].plataforma.nombre, data: currentUser[0] });
    }
  },[]);

  const changePlataforma=(e)=>{
    setPlataforma(e);
    if( e.value ){
      crud("get", "", "", "informes/perfil/" + e.data.perfil.id, "informes");   
    }
  }
  const changeInforme=(e)=>{
    seDatos([]);
    if([3].includes(e.value)){
      setFecha1(timeToDatefull(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*1) ));
      setFecha2(timeToDatefull(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*1) ));
    }else{
      setFecha1(null);
      setFecha2(null);
    }
    setInforme(e);
    if(e.value){
      crud("get", "", e.value, "funcionalidades/informe/" + e.value, "funcionalidades");
    }    
  }
  
  const changeFecha1 =(e)=> { setFecha1(e.target.value);};
  const changeFecha2 =(e)=> { setFecha2(e.target.value);};

  const changeWeek =(e)=> { setSemana(e.target.value)};
  const changeAno =(e)=> { setAno(e.target.value)};

  const getData =()=>{
    switch (informe.data.bloque) {
        case 268: //Incidencias dhl
          {
            if( !plataforma.data.perfil.movimientosBy ){ return; }
            switch (informe.value) {
              case 3:{//incidencias
                  if( !(fecha1 && fecha2 && informe.data) ){ return; }
                  if( new Date(fecha1).getTime() > new Date(fecha2).getTime() ){ return; }
                  crud("get", [], "", "terrestreEntregasIncidencias/reporte/"+fecha1+" 00:00:00/"+fecha2+" 23:59:59", "data");
                }
                break;
            
              default:{/*
                if( !(fecha1 && fecha2 && informe.data) ){ return; }//Se valida que este seleccionado un informe y fechas seleccionadas
                if( new Date(fecha1).getTime() > new Date(fecha2).getTime() ){ return; }//Validamos que la fecha 2 sea mayor a la fecha 1
                if( plataforma.data.perfil.movimientosBy === 2){  //corredores                
                  crud("get", [], "", "navieraMovimientosPerfil/usuario/" + currentUser[0].usuario.id, "data");
                }
                if( plataforma.data.perfil.movimientosBy === 3){//transportistas
                  crud("get", [], "", "navieraMovimientosPerfil/usuario_transportista/" + currentUser[0].usuario.id+"/fecha1/"+fecha1+" 00:00:00/fecha2/"+fecha2+" 23:59:59", "data");
                }*/
              }
                break;
            }            
          }
            break;
    
        default:
            break;
    }
  }
  const changeTodas=(e)=> setTodas(e.target.checked);
/*
  const redirecciona=()=>{

      fetch(conf.api_raiz+'gpsLog/csv', { 
        method: 'get', 
        headers: new Headers({
        'Authorization': "Bearer "+sessionStorage.getItem("tok"),
        'Content-Type':'text/plain; charset=UTF-8'
        })
        }).then((res) => {
          return res.blob();
     })   
     .then((blob) => {
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'reporte.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
     })
}*/

  return (
    <SiteWrapper>
      <Grid.Row>
          <Grid.Col sm={3}>
            <Select value={plataforma} onChange={ changePlataforma } options={plataformas} />
          </Grid.Col>
          <Grid.Col sm={3}>
            <Select value={informe} onChange={ changeInforme } options={informes} />
            <label>{informe.value ? informe.data.descripcion : ""}</label>
          </Grid.Col>
          {  informe.value? ![4, 68, 69, 70].includes(informe.value)?
            <Grid.Col sm={2}>
              <input type="date" id="start" name="trip-start" onChange={ changeFecha1 } value={fecha1} ></input>{/**value={ this.state.fecha1 } onChange={ this.onChangeFecha1 } */}
            </Grid.Col>
          :null :null}
          {  informe.value? ![4, 68, 69, 70].includes(informe.value)?
            <Grid.Col sm={2}>
              <input type="date" id="start" name="trip-start" onChange={ changeFecha2 } value={ fecha2 }></input>
            </Grid.Col>
          :null: null}
          {
            informe.value ? [68, 69, 70].includes(informe.value) ?            
            <Grid.Col sm={2}>
              <Form.Group label="Año: ">
                <Form.Input name="year" type="number" value={ano} onChange={changeAno} />                
              </Form.Group>
            </Grid.Col>
          :null: null}
          {
            informe.value ? [68, 69, 70].includes(informe.value) ?
            <Grid.Col sm={2}>
              <Form.Group label="Semana: ">
                <Form.Input name="week" type="number" value={semana} onChange={changeWeek} />                
              </Form.Group>
            </Grid.Col>
          :null: null}
          {  informe.value? [4, 68, 69, 70].includes(informe.value)?
            <Grid.Col>
             <Form.Switch type="Checkbox" checked={todas} onChange={ changeTodas } label={todas? "Todas las plataformas":plataforma.label}/>
            </Grid.Col>
          :null:null}
          {  informe.value? [4].includes(informe.value) && todas?
            <Grid.Col>
              <Form.Group label="Tipo de movimiento">
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "0"? true: false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >General</div>}
                  value="0"
                  isInline
                />
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "1"? true: false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >Importación</div>}
                  value="1"
                  isInline
                />
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "2"? true: false}
                  disabled={false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >Exportación</div>}
                  value="2"
                  isInline
                />
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "3"? true: false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >Impo Crossborder</div>}
                  value="3"
                  isInline
                />
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "4"? true: false}
                  disabled={false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >Expo Crossborder</div>}
                  value="4"
                  isInline
                />
              </Form.Group>
            </Grid.Col>
          :null:null}
          {  informe.value? [4, 68, 69, 70].includes(informe.value) || fecha1 && fecha2 ?
            <Grid.Col sm={2}>
              <Button pill size="sm" outline color="primary" onClick={ ()=> getData() } ><Icon name="refresh-cw" /></Button>
            </Grid.Col>
          :null : null }          
          <Grid.Col sm={2}>
            <h4>{ datos.length? <Exportar crud={ null } catalogo={informe.data? informe.data.nombre: ""} data={ datos } title= {informe.data? informe.data.nombre: ""} />:null }</h4>
          </Grid.Col>
      </Grid.Row>


      
    </SiteWrapper>
  );



  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    if (stateVar === "informes") {
                        let informesTemp = [{value: 0, label: "Seleccione informe", data: null }];
                        for (let item of returnVal) {
                            informesTemp.push({ value: item.id, label: item.nombre, data: item });
                        }
                        setInformes(informesTemp);
                        setInforme({ value: 0, label: "Seleccione informe", data: null });
                    }
                  /*  if (stateVar === "csv") {
                      alert('aa');
                      window.location.href = "http://localhost:8080/employees";
                  }*/



                    if (stateVar === "funcionalidades" && returnVal) {
                        funcionalidades= returnVal;
                    }
                    if (stateVar === "data" && returnVal) {                    
                      let datosTemp= [];
                      if( tipoMovimiento === "0" ){                                                
                        returnVal.map((d, index)=>{
                          datosTemp[index]= {};
                          console.log(funcionalidades);
                          for( let item of funcionalidades){
                            switch (item.nombre) {
                              case 'movimientoGps':
                                  d[item.nombre].map((gps, indice)=> datosTemp[index][item.label+"-"+gps.gpsTipo.nombre]= gps.gps.nombre );
                                break;
                            
                              default:  datosTemp[index][item.label]= d[item.nombre];
                                break;
                            }
                          }
                        });
                      }else{
                        const camposT={
                          "1" : [
                              "arriboTerminalVacio",
                              "salidaTerminalLleno",
                              "inicioRuta",
                              "arriboClienteLleno",
                              "salidaClienteVacio",
                              ],
                          "2" : [
                            "arriboClienteVacio",
                            "salidaClienteLleno",
                            "arriboTerminalCargado",
                            "salidaTerminalVacio",
                            ],
                          "3":[
                            "arriboTerminalVacio",
                            "salidaTerminalLleno",
                            "inicioRuta",
                            "llegadaFrontera",
                            "salidaFrontera",
                            "arriboClienteLleno",
                            "salidaClienteVacio"
                            ],
                          "4":[
                            "arriboClienteVacio",
                            "salidaClienteLleno",
                            "llegadaFrontera",
                            "salidaFrontera",
                            "arriboTerminalCargado",
                            "salidaTerminalVacio"
                            ]
                        };
                        returnVal.map((d, index)=>{
                          datosTemp[index]= {};
                          for( let item of funcionalidades){
                            let bandera= true;
                            Object.keys(camposT).forEach(key => {
                              for(let ct of camposT[key]){
                                if( ct === item.nombre){
                                  bandera= false;
                                  if(key === tipoMovimiento){
                                    datosTemp[index][item.label]= d[item.nombre];
                                  }
                                }
                              }
                            });
                            if(bandera){
                              switch (item.nombre) {
                                case 'movimientoGps':
                                    d[item.nombre].map((gps, indice)=> datosTemp[index][item.label+"-"+gps.gpsTipo.nombre]= gps.gps.nombre );
                                  break;
                              
                                default:  datosTemp[index][item.label]= d[item.nombre];
                                  break;
                              }
                            }
                            bandera= true;
                          }
                        });
                      }

                        seDatos(datosTemp);
                    }
                } break;
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }
}


export default ReportesPage;
