import React, { Component } from "react";
import TabsLayout from "../../components/TabsLayout.react";
import { Movimiento, Unidades } from "./Formularios"; //Movimiento

class MonitoreoTerrestreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTap: 0,
      id: "",
      accion: this.props.accion, //'nuevo'// modificar,
      ready: false,
    };
    this.tabs = [];
    /**
     * Tab's
     */
    this.Movimiento = {
      title:
        this.props.elementos != undefined ? this.props.elementos.nombre : "",
      icon: "layout",
      panel: (
        <Movimiento
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
        />
      ),
      close: false,
    };
    this.Unidades = {
      title: "Unidades",
      icon: "map-pin",
      panel: (
        <Unidades
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
        />
      ),
      close: false,
    };
  }
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
    } else {
    }
  };

  componentWillMount = () => {
    if (this.state.accion !== "nuevo") {
      this.tabs = [this.Movimiento, this.Unidades];
    } else {
      this.tabs = [this.Movimiento];
    }
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };
  render() {
    return (
      <div>
        <TabsLayout
          tabs={this.tabs}
          selectedTab={this.state.currentTap}
          selectTab={this.selectedTab}
        />
      </div>
    );
  }
}

export default MonitoreoTerrestreForm;
