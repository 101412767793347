import React from 'react';
import * as ACTIONS from '../store/actions/actions'
import { connect } from 'react-redux';
import ModalLayout from "./ModalLayout.react";
import { Crud_Catalogos, Crud_error } from '../herramientas/Crud_Catalogos';
import Mensajeria from '../socket/Mensajeria.react';
import { Form, Grid, Card, Table, Alert,Button, Icon, Dimmer } from "tabler-react";
import { Importar, ImportarCC, Exportar, Plantilla, Manual } from '../herramientas/Excel';
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
var cargaCont= 0;

class Catalogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
              elementos: [],
       tableheaderItems: [],
           hiddenModals: false,
              readyData: false,
                message: "",
           ErrorMessage: [],
       showErrormessage: false,
                 metodo: "get",
           showMensajes: true,
                 search: "",
             pagination: [],
                 pagina: 1,
               pagesize: 200
    };
    this.hiddenmodal= this.hiddenmodal.bind(this);
    this.pagination=[];
    this.ErrorMessage = "";
    this.ErrorMessageArray = [];
    this.catalogo = this.props.Ctlogo;
    this.catalogoTem = "";
    this.Catalogo_form = this.props.FCtlogo;
    this.tamanio = this.props.tamanio;
    this.updateStatus_elementos = this.updateStatus_elementos.bind(this);
    this.getfuntion = this.getfuntion.bind(this);
    this.content = [...this.state.tableheaderItems];
    this.colum= [];
    this.columna= [];
    this.fila= [];
    this.getMensage=false;
    this.mensaje= { "mensaje": "", "plataforma": "", "corredor": "", "vista": "", "data": null, "accion": "" };
    this.elements=[];
  }
  paginar=(pag)=>{ this.getfuntion("get", [], "", [], this.catalogo+"/pagina/"+pag+"/total/"+this.state.pagesize, this.getStoreFunction( this.catalogo )); this.setState({ pagina: pag+1});  }
  paginarNombre=(nombre)=>{
    /*
    if(this.catalogo === "gps" || this.catalogo === "cliente" || this.catalogo === "consignatariosMapa" ){//|| this.catalogo === "consignatariosMapa"
      if(this.state.elementos.filter(word => word.nombre ?
        word.nombre.toUpperCase().includes(nombre.toUpperCase()):
        word.username ?
        word.username.toUpperCase().includes(nombre.toUpperCase()):
        word.estatus !== -1
        ).sort().length === 0){
          this.getfuntion("get", [], "", [], this.catalogo+"/nombre/"+nombre+"/pag/"+this.state.pagesize, this.getStoreFunction( this.catalogo ));
        }
    }*/
    if( ["consignatariosMapa", "destinos", "unidades", "gpsFijos"].includes(this.catalogo)){
      if(nombre.length > 2){
        this.setState({ pagina: 1});
          this.getfuntion("get", [], "", [], this.catalogo+"/nombre/pagina/0/total/"+this.state.pagesize+"?NOMBRE="+nombre, this.getStoreFunction( this.catalogo ));
        } else {
          this.paginar(this.state.pagination.number);
        }
    }
  }

  getStoreFunction=(cat) =>{
    switch (cat) {
      case "acumuladores":            return this.props.acumuladores_update;
      case "alertasCategorias":       return this.props.alertasCategorias_update;
      case "alertasTipos":            return this.props.alertasTipos_update;

      case "bloque":                  return this.props.bloques_update;
      case "bitacoraMonitoreo":       return this.props.bitacoraMonitoreo_update;

      case "categoria":               return this.props.categorias_update;
      case "cliente":                 return this.props.clientes_update;
      case "clientesContactos":       return this.props.clientescontactos_update;
      case "operacionesClientes":     return this.props.operacionesClientes_update;

      case "corredores":              return this.props.corredores_update;
      case "destinos":                return this.props.destinos_update;
      case "consignatariosMapa":      return this.props.consignatariosMapa_update;
      case "consignatariosColor":     return this.props.consignatariosColor_update;
      case "etiquetas":               return this.props.etiquetas_update;
      case "etaConfiguracion":        return this.props.etaConfiguracion_update;

      case "funcionalidades":         return this.props.funcionalidades_update;

      case "gps":                     return this.props.gps_update;
      case "gpsFijos":                return this.props.gpsFijos_update;
      case "gpsEstatus":              return this.props.gpsEstatus_update;
      case "gpsTipoColocacion":       return this.props.gpstipocolocacion_update;
      case "gpsHardware":             return this.props.gpsHardware_update;

      case "informes":                return this.props.informes_update;
      case "informesFuncionalidades": return this.props.informesFuncionalidades_update;
      case "incidencias":             return this.props.incidencias_update;
      case "incidenciastipo":         return this.props.incidenciasTipo_update;

      case "movimientosTipo":         return this.props.movimientosTipo_update;
      case "navieras":                return this.props.navieras_update;
      case "clientes_navieros":       return this.props.clientesNavieros_update;
      case "otd":                     return this.props.otd_update;
      case "operacionesEstatus":      return this.props.operacionesEstatus_update;
      case "operacionesTipo":         return this.props.operacionesTipo_update;
      case "operadores":              return this.props.operadores_update;

      case "personas":                return this.props.personas_update;
      case "perfil":                  return this.props.perfiles_update;
      case "movimientos_by":          return this.props.movimientosby_update;
      case "plataformas":             return this.props.plataformas_update;
      case "plataformasTipos":        return this.props.plataformasTipo_update;
      case "privilegios":             return this.props.privilegios_update;

      case "rutasEstatus":            return this.props.rutasEstatus_update;
      case "salasMonitoreo":          return this.props.salasMonitoreo_update;
      case "transportistas":          return this.props.transportistas_update;
      case "usuarios":                return this.props.usuarios_update;
      case "usuarioPerfil":           return this.props.usuarioperfil_update;

      case "unidades":                return this.props.unidades_update;
      case "unidadesMarcas":          return this.props.unidadesmarcas_update;
      case "unidadEstatusOperacion":  return this.props.unidadEstatusOperacion_update;
      case "unidadTipo":              return this.props.unidadTipo_update;
      case "unidadTipoColocacion":    return this.props.unidadTipoColocacion_update;

      default:
        break;
    }
  }
  componentWillMount=() =>{
  }
  componentDidMount = () => {
    if (this.catalogo !== "" || this.catalogo !== null) {
      this.mensaje.mensaje = "Se acualizo: " + this.catalogo;
      Object.keys(this.props.catalogos).forEach(key => {
        switch (this.props.catalogos[key]) {
          case "gps":
            this.getfuntion("get", [], "", [], this.props.catalogos[key]+"/pagina/0/total/"+this.state.pagesize, this.getStoreFunction(this.props.catalogos[key]));
            break;
          case "gpsFijos":
            this.getfuntion("get", [], "", [], this.props.catalogos[key]+"/pagina/0/total/"+this.state.pagesize, this.getStoreFunction(this.props.catalogos[key]));
            break;
          case "cliente":
            this.getfuntion("get", [], "", [], this.props.catalogos[key]+"/pagina/0/total/"+this.state.pagesize, this.getStoreFunction(this.props.catalogos[key]));
            break;
          case "consignatariosMapa":
            this.getfuntion("get", [], "", [], this.props.catalogos[key]+"/pagina/0/total/"+this.state.pagesize, this.getStoreFunction(this.props.catalogos[key]));
            break;
          case "destinos":
              this.getfuntion("get", [], "", [], this.props.catalogos[key]+"/pagina/0/total/"+this.state.pagesize, this.getStoreFunction(this.props.catalogos[key]));
              break;
          case "unidades":
              this.getfuntion("get", [], "", [], this.props.catalogos[key]+"/pagina/0/total/"+this.state.pagesize, this.getStoreFunction(this.props.catalogos[key]));
              break;
          case "plataformas":
            if(currentUser[0].perfil.id !== 1 ){
              cargaCont++;
              var plataformas=[];
              currentUser.map((perfil, index)=>{ plataformas.push(perfil.plataforma); })
              this.props.plataformas_update(plataformas);
              this.setState({ elementos: plataformas });
            }else{
              this.getfuntion("get", [], "", [], this.props.catalogos[key], this.getStoreFunction(this.props.catalogos[key]));
            }
            break;
          default:
            this.getfuntion("get", [], "", [], this.props.catalogos[key], this.getStoreFunction(this.props.catalogos[key]));
        }
      });
    }
  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
    this.setState({ metodo: "get" });
  }
  paginacion=(pag)=>{ this.pagination=pag;  this.pagination.content=[];   this.setState({pagination: this.pagination}); }

  getfuntion =(metodo="get", params=[], id="", arraytoUpdate=[], catalogoTem=this.catalogo, storeFunction= this.getStoreFunction( this.catalogo ), columna="") => {
    if (catalogoTem !== ""){
      Crud_Catalogos(catalogoTem, this.catalogo, metodo, id, params, storeFunction, this.state.elementos, this.hiddenmodal, arraytoUpdate, columna).then((returnVal) => {
        if(metodo !== "get"){
          this.props.updateStatusMensaje(true);
          this.mensaje.data= returnVal;
          this.mensaje.vista= catalogoTem;
        }else{
          cargaCont++;
          if( cargaCont === Object.keys(this.props.catalogos).length ){
            this.setState({ readyData: true });
          }
        }
        this.mensaje.accion= metodo;
        if (catalogoTem.includes("/pag") ||  catalogoTem === this.catalogo ){
          this.updateStatus_elementos();
        }
        this.setState({ metodo: metodo })
      }).catch(err =>{
        if( err.response ){
          this.setState({ readyData: false });
          this.setState({ showErrormessage: true });
          this.ErrorMessage = Crud_error(err.response.status, catalogoTem);
          this.ErrorMessageArray.push( <Alert type="danger" icon="alert-octagon" > {this.ErrorMessage} </Alert> );
          this.setState({ ErrorMessage: this.ErrorMessageArray });
        } else {
          this.setState({ readyData: false });
          this.setState({ showErrormessage: true });
          this.ErrorMessage = Crud_error(404, catalogoTem);
          this.ErrorMessageArray.push( <Alert type="danger" icon="alert-octagon"> {this.ErrorMessage} </Alert> );
          this.setState({ ErrorMessage: this.ErrorMessageArray });
        }
      });
    }
  }
      updateStatus_elementos =(vista) => {
        if(vista){
          this.elements= this.state.elementos;
          this.state.elementos.map((elemento, index) =>{
            if(vista.accion === "put"){ if(elemento.id === vista.data.id){ this.elements[index]= vista.data; }  }
            if(vista.accion === "post"){ if(elemento.id === vista.data.id && this.catalogo === vista.vista){ }else{ if(elemento.id === vista.data.id){ this.elements[index]= vista.data;} }  }
            if(vista.accion === "delete"){ if(elemento.id === vista.data.id && this.catalogo !==vista.vista){ this.elements[index]= vista.data; }else{ if(elemento.id === vista.data.id){this.elements.splice(index, 1) }} }
          });
          if(vista.vista === this.catalogo && this.elements !== this.state.elementos){ this.elements.push(vista.data); }
          this.setState({ elementos: this.elements});
        }
        if(!vista){
          switch(this.catalogo){
            case "acumuladores":            return this.setState({elementos: this.props.acumuladores_object});
            case "alertasCategorias":       return this.setState({elementos: this.props.alertasCategorias_object });
            case "alertasTipos":            return this.setState({elementos: this.props.alertasTipos_object });

            case "bloque":                  return this.setState({elementos: this.props.bloques_object });
            case "bitacoraMonitoreo":       return this.setState({elementos: this.props.bitacoraMonitoreo_object});

            case "categoria":               return this.setState({elementos: this.props.categorias_object });
            case "cliente":                 {
                                              if( this.props.clientes_object.content ){
                                                  this.setState( {elementos: this.props.clientes_object.content });
                                                this.paginacion(this.props.clientes_object);
                                              }
                                              if( !this.props.clientes_object.content && this.props.clientes_object ){
                                                this.setState({ elementos: this.props.clientes_object });
                                              }
                                            }
                                            break;
            case "clientesContactos":       return this.setState({elementos: this.props.clientescontactos_object });
            case "operacionesClientes":     return this.setState({elementos: this.props.operacionesClientes_object});

            case "corredores":              return this.setState({elementos: this.props.corredores_object });
            case "destinos":                {
                                              if( this.props.destinos_object.content ){
                                                this.setState( {elementos: this.props.destinos_object.content });
                                                this.paginacion(this.props.destinos_object);
                                              }
                                              if( !this.props.destinos_object.content && this.props.destinos_object ){
                                                this.setState({ elementos: this.props.destinos_object });
                                              }

                                            }break;
            case "consignatariosMapa":      {
                                              if( this.props.consignatariosMapa_object.content ){
                                                this.setState( {elementos: this.props.consignatariosMapa_object.content });
                                                this.paginacion(this.props.consignatariosMapa_object);
                                              }
                                              if( !this.props.consignatariosMapa_object.content && this.props.consignatariosMapa_object ){
                                                this.setState({ elementos: this.props.consignatariosMapa_object });
                                              }

                                            }break;            
            case "etiquetas":               return this.setState({elementos: this.props.etiquetas_object });
            case "etaConfiguracion":        return this.setState({elementos: this.props.etaConfiguracion_object});

            case "funcionalidades":         return this.setState({elementos: this.props.funcionalidades_object });

            case "gpsEstatus":              return this.setState({ elementos: this.props.gpsEstatus_object });
            //case "gpsFijos":                return this.setState({elementos: this.props.gpsFijos_object});
            case "gpsFijos":{               if (this.props.gpsFijos_object.content) {
                                                  this.setState({ elementos: this.props.gpsFijos_object.content });
                                                  this.paginacion(this.props.gpsFijos_object);
                                                }
                                                if (!this.props.gpsFijos_object.content && this.props.gpsFijos_object) {
                                                  this.setState({ elementos: this.props.gpsFijos_object });
                                                }
                                              }
                                            break;
            case "gpsHardware":             return this.setState({elementos: this.props.gpsHardware_object});
            case "gpsTipoColocacion":       return this.setState({elementos: this.props.gpstipocolocacion_object});
            case "gps":{                    if( this.props.gps_object.content ){ this.setState({ elementos: this.props.gps_object.content }); this.paginacion(this.props.gps_object);}
                                            if( !this.props.gps_object.content && this.props.gps_object ){ this.setState({ elementos: this.props.gps_object }); } }break;
            case "informes":                return this.setState({ elementos: this.props.informes_object });
            case "informesFuncionalidades": return this.setState({ elementos: this.props.informesFuncionalidades_object });
            case "incidenciastipo":         return this.setState({ elementos: this.props.incidenciasTipo_object });
            case "incidencias":             return this.setState({ elementos: this.props.incidencias_object });

            case "movimientosTipo":         return this.setState({elementos: this.props.movimientosTipo_object});
            case "navieras":                return this.setState({elementos: this.props.navieras_object});
            case "clientes_navieros":       return this.setState({elementos: this.props.clientes_navieros_object });
            case "otd":                     return this.setState({elementos: this.props.otd_object});
            case "operacionesEstatus":      return this.setState({elementos: this.props.operacionesEstatus_object});
            case "operacionesTipo":         return this.setState({elementos: this.props.operacionesTipo_object});
            case "operadores":              return this.setState({elementos: this.props.operadores_object});

            case "personas":                return this.setState({ elementos: this.props.personas_object });
            case "perfil":                  return this.setState({ elementos: this.props.perfiles_object });
            case "movimientos_by":          return this.setState({ elementos: this.props.movimientosby_object });
            case "plataformas":             return this.setState({ elementos: this.props.plataformas_object });
            case "plataformasTipos":        return this.setState({ elementos: this.props.plataformasTipo_object });
            case "privilegios":             return this.setState({ elementos: this.props.privilegios_object });

            case "rutasEstatus":            return this.setState({elementos: this.props.rutasEstatus_object});

            case "salasMonitoreo":          return this.setState({elementos: this.props.salasMonitoreo_object});

            case "transportistas":          return this.setState({elementos: this.props.transportistas_object});

            //case "unidades":                return this.setState({elementos: this.props.unidades_object });
            case "unidades":                {
                                              if( this.props.unidades_object.content ){
                                                this.setState( {elementos: this.props.unidades_object.content });
                                                this.paginacion(this.props.unidades_object);
                                              }
                                              if( !this.props.unidades_object.content && this.props.unidades_object ){
                                                this.setState({ elementos: this.props.unidades_object });
                                              }

                                            }break;
            case "unidadesMarcas":          return this.setState({elementos: this.props.unidadesmarcas_object});
            case "unidadTipo":              return this.setState({elementos: this.props.unidadTipo_object });
            case "unidadEstatusOperacion":  return this.setState({elementos: this.props.unidadEstatusOperacion_object });
            case "usuarios":                return this.setState({elementos: this.props.usuarios_object });
            case "usuarioPerfil":           return this.setState({elementos: this.props.usuarioperfil_object });
            case "unidadTipoColocacion":    return this.setState({elementos: this.props.unidadTipoColocacion_Object });

            default: return;
          }
        }
      }
      table_headers =() => {
        this.content= [];
        if(this.state.elementos){
          for (let item of this.state.elementos){
            Object.keys(item).forEach(key =>
              this.content.push({ content: key }) );
              this.content.push({ content: "editar" });
              break;
          }
        }
      }
      isobject =() => {
        if (typeof this.columna === "object") {
          if(this.columna.length > 1){
            for (let item of this.columna) {
              if( !item["nombre"] ){
                this.colum.push(<Table.Col>{ "..." }</Table.Col>);
                break;
              }
              this.colum.push(<Table.Col>{ item["nombre"]+" ..." }</Table.Col>);
              break;
            }
          }
          else
          {
            if (this.columna.length === 1) {
              for (let item of this.columna) {
                this.colum.push(<Table.Col>{ item["nombre"] }</Table.Col>); break;
              }
            }
            else
            {
              switch (this.catalogo) {
                case 'usuarios':
                    this.colum.push(
                      <Table.Col>{
                        <ModalLayout
                          tamanio={this.tamanio}
                          title="Modificar"
                          name={this.columna["nombre"] ? this.columna["nombre"]+" "+this.columna["aPaterno"]+" "+this.columna["aMaterno"]: this.columna["username"]}
                          hiddenModal={this.state.hiddenModals }
                          Fclose={this.hiddenmodal}
                          elementos={ this.fila }
                          getfuntion={this.getfuntion}
                          stor={ this.props }
                          formulario={<this.Catalogo_form accion="modificar"  /> }
                        />
                      }
                      </Table.Col>);
                  break;
                case 'clientesContactos':
                  if (this.columna["aPaterno"]!=undefined){
                    //console.log("351 "+this.columna["aPaterno"] + " " + this.columna["aMaterno"])
                    this.colum.push(
                      <Table.Col>
                      {
                        <ModalLayout tamanio={this.tamanio} title="Modificar"
                          name={this.columna["nombre"]+" "+this.columna["aPaterno"]+" "+this.columna["aMaterno"]}
                          hiddenModal={this.state.hiddenModals }
                          Fclose={this.hiddenmodal}
                          elementos={ this.fila }
                          getfuntion={this.getfuntion}
                          stor={ this.props }
                          formulario={<this.Catalogo_form accion="modificar"  /> }
                        />
                      }
                      </Table.Col>);
                  }else {
                    //console.log("353 aPaterno y aMaterno no están definidos");
                    this.colum.push(<Table.Col>{ this.columna["nombre"] }</Table.Col>);
                  }
                  break;
                default:
                  if( !this.columna["nombre"] ){
                    this.colum.push(<Table.Col>{ "..." }</Table.Col>);
                  }else{
                    this.colum.push(<Table.Col>{ this.columna["nombre"]+" ..." }</Table.Col>);
                  }
                  break;
              }
            }
          }
        }else { this.colum.push(<Table.Col>{ this.columna["nombre"]+" ..." }</Table.Col>); }
      }
      searchingbyName =(event) =>{
        this.setState({ search: event.target.value });
        this.paginarNombre(event.target.value);
      }
      getpaginationLinks=()=>{
        var links=[];     var numberPag= this.pagination.number;
        if(this.pagination){
          for(let i= numberPag; i<numberPag+5; i++ ){
            if(i === numberPag){  links.push(<li className="btn btn-primary btn-sm" aria-current="page"> <span className="page-link"> {i+1} <span className="sr-only">(current)</span> </span> </li>);
            }else{
              if(i < this.pagination.totalPages ){ links.push(<li className="page-item"><a className="page-link" onClick={()=> this.paginar(i)}>{i+1}</a></li>); }
            }
          }
        }
        return links;
      }
    render() {
      return (
        <div>
          <Dimmer></Dimmer>

            <Grid.Col lg={12}>
              <Mensajeria
                topic={[ "/topic/" + this.catalogo]}
                mensaje={this.mensaje}
                elementos={ this.state.elementos}
                storeFunction={this.getStoreFunction( this.catalogo )}
                update={ this.updateStatus_elementos}
                openmodal={this.state.hiddenModals}
                metodo={ this.state.metodo }
                stor={this.props}
              />

              <div className= "m-3"></div>
              <div className="row">
                <div className="col col-12 col-sm-12 col-md-12 ">
                  <h3> {this.catalogo } </h3>
                  <Card title={ " " }isCollapsible={ false } isCollapsed={ false } // title={this.catalogo }
                        options={
                                  <div className="row">
                                    <div className="col col-2 col-sm-12 col-md-2 mt-2">
                                      {this.catalogo ==="clientesContactos"?
                                        <ImportarCC crud={this.getfuntion} catalogo={this.catalogo} />:<Importar   crud={this.getfuntion} catalogo={this.catalogo} />
                                      }
                                    </div>
                                    <div className="col col-2 col-sm-12 col-md-2 mt-2"><Plantilla crud={this.getfuntion} catalogo={this.catalogo} /></div>
                                    <div className="col col-2 col-sm-12 col-md-2 mt-2"><Exportar  crud={this.getfuntion} catalogo={this.catalogo} data={this.state.elementos}/></div>
                                    {
                                      this.catalogo ==="gps"?
                                      <div className="col col-2 col-sm-12 col-md-2 mt-2"><Manual  crud={this.getfuntion} catalogo={this.catalogo} /></div>
                                      :
                                      <div></div>
                                    }


                                    <div className="col col-3 col-sm-12 col-md-3 mt-3">
                                      <Form.Input className="mb-3" icon="search" placeholder="Buscar..." position="append" onChange={this.searchingbyName} />
                                    </div>
                                    <div className="col col-1 col-sm-12 col-md-1 mt-1">
                                      <span className="badge">
                                        <ModalLayout
                                          tamanio={this.tamanio}
                                          hiddenModal={ this.state.hiddenModals }
                                          Fclose={this.hiddenmodal}
                                          title= "Nuevo"
                                          //tamanio = {this.tamanio}
                                          name= {<Button pill size="sm" outline color="primary"><Icon name="plus"/></Button>}
                                          formulario= {
                                            <this.Catalogo_form
                                              accion="nuevo"
                                              elementos={ this.state.elementos }
                                              getfuntion= {this.getfuntion}
                                              stor= { this.props }
                                            />
                                          }
                                        />
                                      </span>
                                    </div>
                                  </div>
                                }
                    >
                      <Table cards={true} striped={true} responsive={true} className="table-center col-12" {...this.table_headers()} headerItems={this.content} >
                        <Table.Header>
                        </Table.Header>
                        <Table.Body>
                          {
                            this.state.elementos
                              .filter((word) =>
                                (word.nombre ?
                                  word.cveDestinoDhl? word.nombre.toUpperCase().includes(this.state.search.toUpperCase()) || word.cveDestinoDhl.toUpperCase().includes(this.state.search.toUpperCase())
                                  :  this.catalogo != "unidades" ? word.nombre.toUpperCase().includes(this.state.search.toUpperCase()) : word.estatus!==-1
                                  : word.username ?
                                   word.username.toUpperCase().includes(this.state.search.toUpperCase())
                                  : word.nombreDireccion ?
                                  word.nombreDireccion.toUpperCase().includes(this.state.search.toUpperCase()) || word.idDhl.toUpperCase().includes(this.state.search.toUpperCase())
                                  : word.estatus !== -1) ||
                                  ( ["gpsFijos"].includes(this.catalogo) &&
                                    ( word.imei && word.imei.toUpperCase().includes(this.state.search.toUpperCase()) ||
                                      (Number(this.state.search)
                                        && word.id === parseInt(this.state.search)) )
                                  )
                              ).sort().map((elemento, index) =>
                              <Table.Row>
                                {this.colum = []}
                                {
                                  Object.keys(elemento).forEach((key, index )=> {
                                    if (typeof elemento[key] === "object" && elemento[key] !== null) { this.columna = elemento[key]; this.fila = elemento; this.isobject();
                                    }else{
                                      switch (key) {
                                        case "estatus":       this.colum.push(<Table.Col>{<Form.Switch type="Checkbox" checked={elemento[key]} />}</Table.Col>); break;
                                        case "correoEstatus": this.colum.push(<Table.Col>{<Form.Switch type="Checkbox" checked={elemento[key]} />}</Table.Col>); break;
                                        case "password":      this.colum.push(<Table.Col> ******* </Table.Col>); break;
                                        case "persona":       this.colum.push(<Table.Col> { elemento[key] } </Table.Col>); break;
                                        case "logo":          this.colum.push(<Table.Col><i className={'fa '+ elemento[key]} ></i></Table.Col>);break;
                                        default:              this.colum.push(<Table.Col> { elemento[key] } </Table.Col>); break;



                                      }
                                    }
                                  })
                                 }
                                 { this._editCol(<ModalLayout
                                                      tamanio={this.tamanio}
                                                      title="Modificar"
                                                      name={<Button pill size="sm" outline color="primary"><Icon name="edit"/></Button>}
                                                      hiddenModal={this.state.hiddenModals}
                                                      Fclose={this.hiddenmodal}
                                                      formulario={
                                                        <this.Catalogo_form
                                                          accion="modificar"
                                                          elementos={elemento}
                                                          getfuntion={this.getfuntion}
                                                          stor={this.props}
                                                        />
                                                      }
                                                    />)
                                                    
                                  }
                              </Table.Row>
                            )
                          }
                        </Table.Body>
                      </Table>
                {this.pagination.content?
                  <div className="row">
                  <div className="col col-12 col-sm-12 col-md-2"></div>
                  <div className="col">
                    <nav aria-label="...">
                      <ul className="pagination">
                        <li className="page-item" >
                          <span className="page-link" onClick={()=> this.state.pagination? this.state.pagination.number > 0 ? this.paginar(this.state.pagination.number-1) :null:null}>Anterior</span>
                        </li>
                        { this.getpaginationLinks() }
                        <li className="page-item">
                          <a className="page-link" onClick={()=> this.state.pagination? this.state.pagination.number < this.state.pagination.totalPages ? this.paginar(this.state.pagination.number+1):null:null }>Siguiente</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-3">
                    <div className="row">
                      <div className="col-2 text-right"> <Form.Input name="pag212" type="number" value={ this.state.pagina}  onKeyDown={this._buscar}  onChange={this._pag} /> </div>
                      <div className="col-10 text-left align-bottom"> /{ this.state.pagination? this.state.pagination.totalPages? this.state.pagination.totalPages:1:0 }Páginas </div>
                    </div>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-2">
                    <div className="row">
                        <div className="col-6 text-right"> Total por página </div>
                        <div className="col-6"> <Form.Input name="pagTotal" type="number" value={ this.state.pagesize } onChange={this._pagesize} /> </div>
                    </div>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-2">
                    {/* <div className="col-6 text-right"> Total {this.state.pagesize? this.state.pagesize.totalElements: 0}</div> */}
                    
                    <div className="col-12 text-right"> Mostrando {this.pagination.content? this.pagination.numberOfElements: 0} de {this.pagination.content? this.pagination.totalElements: 0}</div>
                  </div>
                  </div>:""
                }
              </Card>
                </div>
              </div>
              <div hidden={!this.state.showErrormessage}> {this.state.ErrorMessage} </div>
            </Grid.Col>
            { this.state.readyData ===true && this.state.elementos ? null
          : this.ErrorMessageArray}
        </div>
      );
  }
  _editCol=(data)=>{ this.colum.push(<Table.Col>{data}</Table.Col>) }
  _pagesize=(e)=> this.setState({pagesize: e.target.value });
  _buscar=(e)=> { if (e.key === 'Enter') { if(this.pagination.totalPages){ this.paginar(e.target.value-1);} } }
  _pag=(event)=> { this.setState({ pagina: event.target.value > this.pagination.totalPages ? this.pagination.totalPages: this.pagination.totalPages? event.target.value: 1 }); }
}
function mapStateToProps(state) {
  return {
    alertasCategorias_object:         state.catalogos_reducer.alertasCategorias_object,
    alertasTipos_object:              state.catalogos_reducer.alertasTipos_object,
    acumuladores_object:              state.catalogos_reducer.acumuladores_object,

    bloques_object:                   state.catalogos_reducer.bloques_object,
    bitacoraMonitoreo_object:         state.catalogos_reducer.bitacoraMonitoreo_object,

    categorias_object:                state.catalogos_reducer.categorias_object,
    clientes_object:                  state.catalogos_reducer.clientes_object,
    clientescontactos_object:         state.catalogos_reducer.clientescontactos_object,
    operacionesClientes_object:       state.catalogos_reducer.operacionesClientes_object,

    corredores_object:                state.catalogos_reducer.corredores_object,
    destinos_object:                  state.catalogos_reducer.destinos_object,
    consignatariosMapa_object:        state.catalogos_reducer.consignatariosMapa_object,
    consignatariosColor_object:       state.catalogos_reducer.consignatariosColor_object,
    etiquetas_object:                 state.catalogos_reducer.etiquetas_object,
    etaConfiguracion_object:          state.catalogos_reducer.etaConfiguracion_object,

    funcionalidades_object:           state.catalogos_reducer.funcionalidades_object,

    gps_object:                       state.catalogos_reducer.gps_object,
    gpsFijos_object:                  state.catalogos_reducer.gpsFijos_object,
    gpsHardware_object:               state.catalogos_reducer.gpsHardware_object,
    gpstipocolocacion_object:         state.catalogos_reducer.gpstipocolocacion_object,
    gpsEstatus_object:                state.catalogos_reducer.gpsEstatus_object,

    informes_object:                  state.catalogos_reducer.informes_object,
    informesFuncionalidades_object:   state.catalogos_reducer.informesFuncionalidades_object,
    incidenciasTipo_object:           state.catalogos_reducer.incidenciasTipo_object,
    incidencias_object:               state.catalogos_reducer.incidencias_object,

    movimientosTipo_object:           state.catalogos_reducer.movimientosTipo_object,
    navieras_object:                  state.catalogos_reducer.navieras_object,
    clientes_navieros_object:         state.catalogos_reducer.clientes_navieros_object,
    operacionesEstatus_object:        state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo_object:           state.catalogos_reducer.operacionesTipo_object,
    operadores_object:                state.catalogos_reducer.operadores_object,
    otd_object:                       state.catalogos_reducer.otd_object,

    personas_object:                  state.catalogos_reducer.personas_object,
    plataformas_object:               state.catalogos_reducer.plataformas_object,
    plataformasTipo_object:           state.catalogos_reducer.plataformasTipo_object,
    privilegios_object:               state.catalogos_reducer.privilegios_object,
    perfiles_object:                  state.catalogos_reducer.perfiles_object,
    movimientosby_object:             state.catalogos_reducer.movimientosby_object,
    rutasEstatus_object:              state.catalogos_reducer.rutasEstatus_object,

    salasMonitoreo_object:            state.catalogos_reducer.salasMonitoreo_object,

    transportistas_object:            state.catalogos_reducer.transportistas_object,

    usuarios_object:                  state.catalogos_reducer.usuarios_object,
    usuarioperfil_object:             state.catalogos_reducer.usuarioperfil_object,
    unidadesmarcas_object:            state.catalogos_reducer.unidadesmarcas_object,
    unidades_object:                  state.catalogos_reducer.unidades_object,
    unidadTipo_object:                state.catalogos_reducer.unidadTipo_object,
    unidadEstatusOperacion_object:    state.catalogos_reducer.unidadEstatusOperacion_object,
    updateStatusMensaje_Object:       state.catalogos_reducer.update_mensaje,
    unidadTipoColocacion_Object:      state.catalogos_reducer.unidadTipoColocacion_object,

  }
}
function mapDispachToProps(dispach) {
  return {
    acumuladores_update:            (elementos) => dispach(ACTIONS.elementos_acumuladores_imput(elementos)),
    alertasCategorias_update:       (elementos) => dispach(ACTIONS.elementos_alertasCategorias_imput(elementos)),
    alertasTipos_update:            (elementos) => dispach(ACTIONS.elementos_alertasTipos_imput(elementos)),

    bloques_update:                 (elementos) => dispach(ACTIONS.elementos_bloques_imput(elementos)),
    bitacoraMonitoreo_update:       (elementos) => dispach(ACTIONS.elementos_bitacoraMonitoreo_imput(elementos)),

    categorias_update:              (elementos) => dispach(ACTIONS.elementos_categorias_imput(elementos)),
    clientes_update:                (elementos) => dispach(ACTIONS.elementos_clientes_imput(elementos)),
    clientescontactos_update:       (elementos) => dispach(ACTIONS.elementos_clientescontactos_imput(elementos)),
    operacionesClientes_update:     (elementos) => dispach(ACTIONS.elementos_operacionesClientes_imput(elementos)),

    corredores_update:              (elementos) => dispach(ACTIONS.elementos_corredores_imput(elementos)),
    destinos_update:                (elementos) => dispach(ACTIONS.elementos_destinos_imput(elementos)),
    consignatariosMapa_update:      (elementos) => dispach(ACTIONS.elementos_consignatariosmapa_imput(elementos)),
    consignatariosColor_update:     (elementos) => dispach(ACTIONS.elementos_consignatarioscolor_imput(elementos)),
    etiquetas_update:               (elementos) => dispach(ACTIONS.elementos_etiquetas_imput(elementos)),
    etaConfiguracion_update:        (elementos) => dispach(ACTIONS.elementos_etaConfiguracion_imput(elementos)),

    funcionalidades_update:         (elementos) => dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),

    gps_update:                     (elementos) => dispach(ACTIONS.elementos_gps_imput(elementos)),
    gpsFijos_update:                (elementos) => dispach(ACTIONS.elementos_gpsFijos_imput(elementos)),
    gpstipocolocacion_update:       (elementos) => dispach(ACTIONS.elementos_gpstipocolocacion_imput(elementos)),
    gpsEstatus_update:              (elementos) => dispach(ACTIONS.elementos_gpsestatus_imput(elementos)),
    gpsHardware_update:             (elementos) => dispach(ACTIONS.elementos_gpshardware_imput(elementos)),

    informes_update:                (elementos) => dispach(ACTIONS.elementos_informes_imput(elementos)),
    informesFuncionalidades_update: (elementos) => dispach(ACTIONS.elementos_informesFuncionalidades_imput(elementos)),
    incidenciasTipo_update:         (elementos) => dispach(ACTIONS.elementos_incidenciastipo_imput(elementos)),
    incidencias_update:             (elementos) => dispach(ACTIONS.elementos_incidencias_imput(elementos)),

    movimientosTipo_update:         (elementos) => dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),

    navieras_update:                (elementos) => dispach(ACTIONS.elementos_navieras_imput(elementos)),
    clientesNavieros_update:        (elementos) => dispach(ACTIONS.elementos_clientes_navieros_imput(elementos)),
    otd_update:                     (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    operacionesEstatus_update:      (elementos) => dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update:         (elementos) => dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    operadores_update:              (elementos) => dispach(ACTIONS.elementos_operadores_imput(elementos)),

    personas_update:                (elementos) => dispach(ACTIONS.elementos_personas_imput(elementos)),
    plataformas_update:             (elementos) => dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    plataformasTipo_update:         (elementos) => dispach(ACTIONS.elementos_plataformastipo_imput(elementos)),
    privilegios_update:             (elementos) => dispach(ACTIONS.elementos_privilegios_imput(elementos)),
    perfiles_update:                (elementos) => dispach(ACTIONS.elementos_perfiles_imput(elementos)),
    movimientosby_update:           (elementos) => dispach(ACTIONS.elementos_movimientosby_imput(elementos)),
    rutasEstatus_update:            (elementos) => dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),

    salasMonitoreo_update:          (elementos) => dispach(ACTIONS.elementos_salasMonitoreo_imput(elementos)),

    transportistas_update:          (elementos) => dispach(ACTIONS.elementos_transportistas_imput(elementos)),

    usuarios_update:                (elementos) => dispach(ACTIONS.elementos_usuarios_imput(elementos)),
    usuarioperfil_update:           (elementos) => dispach(ACTIONS.elementos_usuarioperfil_imput(elementos)),
    updateStatusMensaje:            (status) => dispach(ACTIONS.update_mensaje(status)),
    unidadesmarcas_update:          (elementos) => dispach(ACTIONS.elementos_unidadesmarcas_imput(elementos)),
    unidades_update:                (elementos) => dispach(ACTIONS.elementos_unidades_imput(elementos)),
    unidadTipo_update:              (elementos) => dispach(ACTIONS.elementos_unidadTipo_imput(elementos)),
    unidadEstatusOperacion_update:  (elementos) => dispach(ACTIONS.elementos_unidadEstatusOperacion_imput(elementos)),
    unidadTipoColocacion_update:    (elementos) => dispach(ACTIONS.elementos_unidadTipoColocacion_imput(elementos)),

  }
}
export default connect(mapStateToProps, mapDispachToProps)(Catalogo)
