import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import TabsLayout  from "../../components/TabsLayout.react";
import { Cliente, Contactos, Etiquetas, CorreosClit } from "./Formularios";

class  ClienteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
                   currentTap:0,
                           id:"",
                       accion:this.props.accion, //'nuevo'// modificar,
                        ready:false
                 };
                  this.tabs =[];//this.props.elementos.nombre
            this.Cliente    ={ title:"Clientes" , icon:"layout",   panel:<Cliente     accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor } plataforma={ this.props.plataforma }/>,close:false };
            this.Contactos  ={ title:"Contactos" ,icon:"layout",   panel:<Contactos   accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor } plataforma={ this.props.plataforma }/>,close:false };
            this.Etiquetas  ={ title:"Etiquetas", icon:"git-merge",panel:<Etiquetas   accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
            //this.Correos    ={ title:"Correos",   icon:"mail",     panel:<CorreosClit accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
  }
  componentDidMount =() => {

  };

  componentWillMount =() =>{
    if(this.state.accion !== 'nuevo'){
      this.setState({id:this.props.elementos.id});
      this.tabs= [this.Cliente,this.Contactos, this.Etiquetas];
      }else{
        this.tabs= [this.Cliente];
       }
  }
  selectedTab =(index) => { this.setState({ currentTap:index }) }
  render() {
    return (
      <div>
        <TabsLayout tabs={ this.tabs } selectedTab= { this.state.currentTap } selectTab= { this.selectedTab } />
      </div>
    )
  }
}

export default ClienteForm
