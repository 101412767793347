import React, { useState, useEffect } from "react";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";

const enviar = (accion, payload, setData, setLoading) => {
	let resultados = [];
	if (payload === "") {
		setData([]);
		setLoading(false);
		return;
	}
	switch (accion) {
		case "Inicio":
			setLoading(true);
			break;
		case "Error":
			setLoading(false);
			break;
		case "Resultados":
			setLoading(false);
			setData(payload);
			break;
		default:
			setLoading(false);
			throw new Error();
	}
};

const FetchData = (url, opcion, setData, setLoading) => {
	enviar("Inicio", null, setData, setLoading);
	return Crud_Catalogos(url, "resportesGenerales", "get", "", "", "", opcion, "", [])
		.then((returnVal) => {
			enviar(opcion, returnVal, setData, setLoading);
			return new Promise((resolve) => {
				setTimeout(() => resolve("bien"), 5000);
			});
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
			} else {
				console.log("Error desconocido ...." + err);
			}
			enviar("Error", null, setData, setLoading);
			return new Promise((resolve) => {
				setTimeout(() => resolve("mal"), 1000);
			});
		});
};

export {FetchData};
