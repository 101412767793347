import React, { useState, useEffect } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FetchData } from "./FetchData.react";
import { useFormContext } from "react-hook-form";

export const ExportarReportesGenerales = ({ setLoading, data }) => {
  /* ------------------------- Variables contexto --------------------------*/
  const { getValues } = useFormContext();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [csvData, setCsvData] = useState();
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (csvData !== undefined && csvData.content.length > 0) {
      setLoading(true);
      let datos = [];
      datos = csvData.content.map((row, index) => {
        let newRow;
        newRow = JSON.parse(row);
        if (newRow.lista_gps !== undefined && newRow.lista_gps !== null) {
          newRow.unidad1 = "";
          newRow.gps1 = "";
          newRow.placa1 = "";
          newRow.unidad2 = "";
          newRow.gps2 = "";
          newRow.placa2 = "";
          newRow.unidad3 = "";
          newRow.gps3 = "";
          newRow.placa3 = "";
          let listaGps = newRow.lista_gps.split(" || ");
          if (listaGps[0] !== undefined) {
            let data = listaGps[0].split(", ");
            newRow.unidad1 = data[0];
            newRow.gps1 = data[1];
            newRow.placa1 = data[2];
          }
          if (listaGps[1] !== undefined) {
            let data = listaGps[1].split(", ");
            newRow.unidad2 = data[0];
            newRow.gps2 = data[1];
            newRow.placa2 = data[2];
          }
          if (listaGps[2] !== undefined) {
            let data = listaGps[2].split(", ");
            newRow.unidad3 = data[0];
            newRow.gps3 = data[1];
            newRow.placa3 = data[2];
          }
        }
        delete newRow["lista_gps"];
        return newRow;
      });
      const ws = XLSX.utils.json_to_sheet(datos);
      const wb = { Sheets: { datos: ws }, SheetNames: ["datos"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }, [csvData]);

  const buscar = async () => {
    let pagina = 0;
    let paginas = Math.ceil(data.totalElements / 50000);
    setFileName(getValues("reporte").nombre);
    while (pagina < paginas) {
      switch (getValues("reporte").nombre) {
        case "Movimientos Generales":
          await FetchData(
            "informes/movimientosGenerales/pagina/" +
              pagina +
              "/total/50000/variables/" +
              getValues("fechaInicio") +
              "/" +
              getValues("fechaFin"),
            "Resultados",
            setCsvData,
            setLoading
          );
          break;
        case "Incidencias Generales":
          await FetchData(
            "informes/incidenciasGenerales/pagina/" +
              pagina +
              "/total/50000/variables/" +
              getValues("fechaInicio") +
              "/" +
              getValues("fechaFin"),
            "Resultados",
            setCsvData,
            setLoading
          );
          break;
        case "Alertas Generales":
          await FetchData(
            "informes/alertasGenerales/pagina/" +
              pagina +
              "/total/50000/variables/" +
              getValues("fechaInicio") +
              "/" +
              getValues("fechaFin"),
            "Resultados",
            setCsvData,
            setLoading
          );
          break;
        case "Entregas Generales":
          await FetchData(
            "informes/entregasGenerales/pagina/" +
              pagina +
              "/total/50000/variables/" +
              getValues("fechaInicio") +
              "/" +
              getValues("fechaFin"),
            "Resultados",
            setCsvData,
            setLoading
          );
          break;
        case "Eventos Generales":
          await FetchData(
            "informes/eventosGenerales/pagina/" +
              pagina +
              "/total/50/variables/" +
              getValues("fechaInicio") +
              "/" +
              getValues("fechaFin"),
            "Resultados",
            setCsvData,
            setLoading
          );
          break;
        default:
          break;
      }
      pagina++;
    }
    setLoading(false);
  };

  return (
    <>
      {data && data.totalElements ? (
        <div>
          <div
            className="row justify-conP
        tent-end"
          >
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => buscar()}
            >
              Exportar a Excel
            </button>
          </div>
          <div className="row justify-content-end">
            <p className="font-weight-light font-italic">
              Se descargarán {Math.ceil(data.totalElements / 50000)} archivo(s)
              de excel (el número máximo de registros por archivo es de 50,000).
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};
