import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import Crud_Catalogos from "../../../../herramientas/Crud_Catalogos";
/**
 * Date tools
 */
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import DateTimePicker from "react-datetime-picker";

class  EntregaForm extends Component {
  constructor(props) {
    //console.log(props);
    super(props);
        this.state  = {
                            idEntrega       : 0,
                            idDestino       : { value: 1, label: "DESTINO SIN CATALOGO", indice:0 },
                            orden           : 1,
                            destino         : "",
                            direccion       : "",
                            contacto        : "",
                            cartaPorte      : "",
                            fechaArribo     : "",
                            fechaSalida     : "",
                            tiempoEstadia   : "",
                            tiempoTraslado  : "",
                            fechaCambio     : "",
                            citaCarga       : "",
                            inicioRuta      : "",
                            estatus         : 0,
                            eta             : 0,
                            accion: this.props.accion, //'nuevo'// modificar, nuevo
                            disabledSave: true,
                            destinos        : []
         };
         this.valid  = { destino: false, orden: false };//, direccion: false };
         this.idDestinos  = [];
         this.entrega = {
                           "idEntrega"       : 0,
                           "idMovimiento"    : this.props.idmovimiento,
                           "idDestino"       : 1,//{ value: 1, label: "DESTINO SIN CATALOGO", indice:0 },
                           "orden"           : 0,
                           "destino"         : "PENDIENTE",
                           "direccion"       : "PENDIENTE",
                           "contacto"        : "PENDIENTE",
                           "cartaPorte"      : "",
                           "fechaArribo"     : null,
                           "fechaSalida"     : null,
                           "tiempoEstadia"   : null,
                           "tiempoTraslado"  : null,
                           "fechaAlta"       : null,
                           "fechaCambio"     : null,
                           "latitud"         : -90,
                           "longitud"        : -180,
                           "citaCarga"       : null,
                           "inicioRuta"      : null,
                           "estatus"         : 0,
                           "eta"             : 0
         };
  }

  componentDidMount =() => {    

    if(this.state.accion === 'nuevo'){      
      this.setState({direccion: "PENDIENTE" });
      this.setState({contacto : "PENDIENTE" });
      this.setState({destino  : "PENDIENTE" });
      this.setState({latitud  : -90 });
      this.setState({longitud : -180 });
      this.valid.destino = true;
      this.valid.orden = true;
    }
    else
    {
      //Se Modifica Registro Actual
     
      this.entrega = this.props.elementos;
      this.valid  = { idDestino:true, destino: true, orden: true, direccion: true };
      //this.setState({idDestino      : { value: 1, label: "DESTINO SIN CATALOGO", indice:0 } }); //this.props.elementos.idDestino      });
      this.setState({idEntrega      : this.props.elementos.idEntrega      });
      this.setState({orden          : this.props.elementos.orden?this.props.elementos.orden: 1 });
      this.setState({destino        : this.props.elementos.destino        });
      this.setState({direccion      : this.props.elementos.direccion      });
      this.setState({contacto       : this.entrega.contacto               });
      this.setState({cartaPorte     : this.entrega.cartaPorte             });
      this.setState({latitud        : this.props.elementos.latitud        });
      this.setState({longitud       : this.props.elementos.longitud       });
      this.setState({fechaArribo    : this.props.elementos.fechaArribo?   new Date(this.props.elementos.fechaArribo)    :  "" });
      this.setState({fechaSalida    : this.props.elementos.fechaSalida?   new Date(this.props.elementos.fechaSalida)    :  "" });
      this.setState({tiempoEstadia  : this.props.elementos.tiempoEstadia? new Date(this.props.elementos.tiempoEstadia)  :  "" });
      this.setState({tiempoTraslado : this.props.elementos.tiempoTraslado?new Date(this.props.elementos.tiempoTraslado) :  "" });
      this.setState({fechaCambio    : this.props.elementos.fechaCambio?   new Date(this.props.elementos.fechaCambio)    :  "" });
      this.setState({citaCarga      : this.props.elementos.citaCarga?     new Date(this.props.elementos.citaCarga)      :  "" });
      this.setState({inicioRuta     : this.props.elementos.inicioRuta?    new Date(this.props.elementos.inicioRuta)     :  "" });
      this.setState({estatus        : this.props.elementos.estatus        });     
      this.getfuntion("get", "", "", "destinos/" + this.props.elementos.idDestino, "destino");
    }
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "entregas", stateVar = "entregas", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "entregasTerrestre", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get": {
          if(stateVar === "destino"){
            this.setState({idDestino: { value: returnVal.id, label: returnVal.cveDestinoDhl + " - " + returnVal.nombre, data: returnVal } });
          }

          if (stateVar === "destinos" && returnVal.content) { 
            let destinosTemp = [];
            destinosTemp.push({ value: 1, label: "DESTINO SIN CATALOGO", data:null });
            
              returnVal.content.map((destino, index) => {
                destinosTemp.push({ value: destino.id, label: destino.cveDestinoDhl + " - " + destino.nombre, data: destino });
                if (this.entrega){
                  if (this.entrega.idDestino === 1){
                    this.setState({ idDestino: { value: 1, label: "DESTINO SIN CATALOGO", data:null } });
                  }
                  if (destino.id === this.entrega.idDestino){
                    this.setState({ idDestino: { value: destino.id, label: destino.cveDestinoDhl + " - " + destino.nombre, data: destino } });
                  }
                }
              });
            
            this.setState({ destinos: destinosTemp }); 
          }
        }
          break;       
        default:
          break;
      }
    }).catch(err => { console.log(err); });
  }

  getDestinos = (nombre) => {
    this.getfuntion("get", "", "", "destinos/nombre/pagina/0/total/200?NOMBRE=" + nombre , "destinos");
  }

  isValid=(campo) =>{
    switch (campo) {
      //case "idDestino": return this.valid.idDestino;
      //  break;
      case "destino": return this.valid.destino;
        break;
      case "orden": return this.valid.orden;
        break;
      //case "direccion": return this.valid.direccion;
      //  break;
      default:
        break;
    }
  }
  disableSave =() =>{
    if (this.state.idDestino.value===1){
      if (this.state.destino.lenght>3 && !this.state.orden ){
        return true;
      }else{
        return false;
      }
    }else{
      if (this.state.contacto!=null&&this.state.contacto.lenght>3 && !this.state.orden){
        return true;
      }else{
        return false;
      }
    }
    //let band= false;
    //Object.keys(this.valid).forEach(key =>
    //  {
    //  if( !this.valid[key]){
    //    band=true; }
    //  });
    //if(band){ return true }else{ return false }
  }

  delete()   { this.props.getfuntion("delete","",this.props.elementos.idEntrega, "entregas", "entregas"); }
  crud_put() {
    let tmp,tmp2 = "";
    tmp=this.entrega.destino;
    tmp2=this.entrega.direccion;
    this.entrega.destino=tmp.trim();
    this.entrega.direccion=tmp2.trim();    
    this.props.getfuntion("put",this.entrega,"", "entregas", "entregas");
  }
  crud_post(){ this.props.getfuntion("post",this.entrega,"", "entregas", "entregas");}

  changeidDestinos=(event) =>{
          
      this.entrega.idDestino  = event.data.id;
      this.entrega.destino    = event.data.nombre? event.data.nombre: "";
      this.entrega.latitud    = event.data.latitud;
      this.entrega.longitud   = event.data.longitud;
      this.entrega.direccion  = event.data.direccion? event.data.direccion: "";
      this.entrega.contacto   = "PENDIENTE";

      this.setState({ idDestino: event });
      this.setState({ destino: event.data.nombre });
      this.setState({ direccion: event.data.direccion });
      this.setState({ latitud: event.data.latitud });
      this.setState({ longitud: event.data.longitud });
      this.setState({ contacto: "PENDIENTE" });

  }

  changeDestino  =(event) => {
    this.entrega.destino=event.target.value;
    this.setState({destino:event.target.value});
    if(event.target.value.length > 5 ){  this.valid.destino= true; }else{  this.valid.destino= false;  }
  };
  changeCartaPorte=(event) => {
    this.entrega.cartaPorte= event.target.value;
    this.setState({cartaPorte: event.target.value});
    if(event.target.value.length > 3 ){  this.valid.cartaPorte= true; }else{  this.valid.cartaPorte= false;  }
  };
  changeOrden    =(event) => {
    this.entrega.orden= event.target.value;
    this.setState({orden: event.target.value});
    console.log(event.target.value);
    if(event.target.value){ this.valid.orden= true; }else{this.valid.orden= false;  }
  };
  changeDireccion=(event) => {
    this.entrega.direccion= event.target.value;
    this.setState({direccion: event.target.value});
  };
  changeLatitud=(event) => {
    this.entrega.latitud= event.target.value;
    this.setState({latitud: event.target.value});
  };
  changeLongitud=(event) => {
    this.entrega.longitud= event.target.value;
    this.setState({longitud: event.target.value});
  };
  changeContacto=(event) => {
    this.entrega.contacto= event.target.value;
    this.setState({contacto: event.target.value});
  };
  changeEstatus = (event) => {
    this.state.estatus === 0 ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });
    this.state.estatus === 0 ? this.entrega.estatus = 1 : this.entrega.estatus = 0;
  }
  render(){
    return (
      <div>
        {
          //this.props.destinos?
          <div>
            { this.state.accion === "modificar" ? <h1> { this.props.elementos.destino } </h1> : null }
            <Grid.Row>
            <Grid.Col md={12} xl={12}>
              <Grid.Row>
                <Grid.Col md={8} lg={8}>
                    <Form.Group label="Catalogo">
                      <Select value={this.state.idDestino} onChange={this.changeidDestinos} options={this.state.destinos} onInputChange={this.getDestinos}/>
                    </Form.Group>
                </Grid.Col>
                {/* <Grid.Col md={4} lg={4}>
                    <Form.Group label="Control Vehicular">
                      <Form.Input type="text" name="Carta Porte" feedback="Al menos 3" value={this.state.cartaPorte} placeholder="Control Vehicular" onChange={this.changeCartaPorte}
                      //valid={this.isValid("cartaPorte") && this.state.cartaPorte.length > 0 }
                      //invalid={!this.isValid("orden") && this.state.cartaPorte.length > 0}
                      />
                    </Form.Group>
                </Grid.Col> */}
                  {
                    this.state.idDestino.value===1?
                    <Grid.Col md={8} lg={8}>
                        <Form.Group label="Nombre de la Entrega">
                            <Form.Input type="text" name="destino" feedback="Al menos 6" value={this.state.destino} placeholder="Ingresa Nombre del Destino..." invalid={!this.isValid("destino") && this.state.destino.length > 0}  onChange={this.changeDestino} />
                        </Form.Group>
                    </Grid.Col>
                    :
                    ""
                  }
                <Grid.Col md={4} lg={4}>
                    <Form.Group label="Orden">
                      <Form.Input type="number" name="orden" feedback="Al menos 1" value={this.state.orden} placeholder="Ingresa orden..." onChange={this.changeOrden} valid={this.isValid("orden") && this.state.orden.length > 0 } invalid={!this.isValid("orden")} />
                    </Form.Group>
                </Grid.Col>
                {
                  this.state.idDestino.value===1?
                  <Grid.Col md={12} lg={12}>
                      <Form.Group label="Dirección">
                        <Form.Input type="text" name="direccion" feedback="Al menos 6 caracteres" value={this.state.direccion} placeholder="Ingresa dirección..." onChange={this.changeDireccion} />
                      </Form.Group>
                      <Form.Group label="Contacto">
                        <Form.Input type="text" name="contacto" value={this.state.contacto} placeholder="Ingresa Contacto..." onChange={this.changeContacto}/>
                      </Form.Group>
                  </Grid.Col>
                  :
                  <Grid.Col md={12} lg={12}>
                    <Form.Group label="Contacto">
                      <Form.Input type="text" name="contacto" value={this.state.contacto} placeholder="Ingresa Contacto..." onChange={this.changeContacto}/>
                    </Form.Group>
                  </Grid.Col>

                }
                <Grid.Col md={6} lg={6}>
                  <Form.Group label="Latitud">
                    <Form.Input type="number" name="latitud"  value={this.state.latitud} placeholder="Ingresa Latitud..." onChange={this.changeLatitud} />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={6} lg={6}>
                  <Form.Group label="Longitud">
                    <Form.Input type="number" name="longitud" value={this.state.longitud}placeholder="Ingresa Longitud.." onChange={this.changeLongitud} />
                  </Form.Group>
                </Grid.Col>

              </Grid.Row>
            </Grid.Col>
            </Grid.Row>
            <div className="float-right">
              <span className="badge">
                  <Button size="sm"
                          RootComponent="a"
                          color="primary"
                          //disabled= { this.state.idDestino==='1'? false : true }
                          disabled= { this.disableSave() }
                          onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() } >
                      <span className="badge"><Icon link={true} name="save" />Guardar</span>
                  </Button>
              </span>
              {/* {console.log("canceladaManual: " + this.props.elementos.canceladaManual)} */}
              { this.state.accion === "modificar" && this.props.elementos.canceladaManual === false ?
                <span className="badge">
                  <Button pill size="sm" color= "red" onClick={ ()=> this.delete() }><Icon name="trash"/></Button>
                </span>
              : null }
            </div>

            <Grid.Col md={3} lg={3}>
               <Form.Group label="Est@tus">
                 <Form.Switch type="Checkbox" name="toggle" value={this.state.estatus} checked={this.state.estatus} onChange={this.changeEstatus} />
               </Form.Group>
            </Grid.Col>

          </div>
      //  :null
      }
    </div>
    );
  }
}
export default EntregaForm;
