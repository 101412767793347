// @flow
import React, { useState } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from 'react-select';

let elementObject=
  {
    colorGeocerca: "",
    consignatario: "",
    idConsignatarioMapa: 0,
    idDhl: "",
    nombreDireccion: ""
  };
let colores= [];
const  ConsignatariosMapa =({ accion, elementos, getfuntion, stor})=> {  
  const [nombreDireccion, setNombreDireccion]= useState(elementos.nombreDireccion? elementos.nombreDireccion:"");
  const [consignatario, setConsignatario]= useState(elementos.consignatario? elementos.consignatario: "");
  const [idDhl, setIdDhl]= useState(elementos.idDhl? elementos.idDhl: "");
  const [colorGeocerca, setColorGeocerca]= useState(elementos.colorGeocerca? { value: 1, label: elementos.colorGeocerca }:{ value:0, label: "Selecciona un color" });
  const [ crudbutonEnable, setCrudbutonEnable]= useState(true);
  
  stor.consignatariosColor_object.map((c,index)=> colores.push({ value: c.codigoColor, label: c.colorGeocerca }) );
  if(accion !== "nuevo"){ elementObject= elementos; }
const isEnable=(Accion)=>{/*
  let borrar= "bloques_borrar";
  let actualizar= "bloques_editar";
  let agregar= "bloques_agregar";
  var privilegios= this.props.stor.privilegios_object;
  var perfil = JSON.parse(sessionStorage.getItem("perfil"));
  for (let i = 0;  i<privilegios.length; i++) {
    if(Accion === "borrar"){
      if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
  }
  return false*/
  return true;
}

  const enableSave =() =>{
    if( nombreDireccion.length && consignatario.length && idDhl.length && colorGeocerca.value ){
      return false;
    }else{
      return true
    }
  }

  const changeNombreDireccion =(e) =>{
    setNombreDireccion(e.target.value);
    elementObject.nombreDireccion= e.target.value
  }
  const changeConsignatario =(e) =>{
    setConsignatario(e.target.value);
    elementObject.consignatario= e.target.value
  }
  const changeIdDhl =(e) =>{
    setIdDhl(e.target.value);
    elementObject.idDhl= e.target.value
  }
  const changeColor =(e) =>{
    setColorGeocerca(e);
    elementObject.colorGeocerca= e.label;
  }

  const borrar =()=>{ 
    setCrudbutonEnable(false);  getfuntion("delete", "", elementObject.idConsignatarioMapa);
  }
  const crud_put =()=>  {
    setCrudbutonEnable(false);  getfuntion("put", elementObject);
  }
  const crud_post =()=> {
    setCrudbutonEnable(false);  getfuntion("post", elementObject);
  }

  return (
    <div>
      { accion === "modificar"? <h1>{ elementos.nombre }</h1>:null }
      <Grid.Row>
        <Grid.Col md={12} xl={12}>
          <Grid.Row>
            <Grid.Col md={3} lg={3}>
              <Form.Group label="Nombre">
                <Form.Input name="nombre" type="text" value={ nombreDireccion } onChange={ changeNombreDireccion }/>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} lg={3}>
              <Form.Group label="Consignatario">
                <Form.Input name="consignatario" type="text" value={ consignatario } onChange={ changeConsignatario }/>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} lg={3}>
              <Form.Group label="Clave de DHL">
                <Form.Input name="iddhl" type="text" value={ idDhl } onChange={ changeIdDhl }/>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} lg={3}>
              <Form.Group label="Clave de DHL">
               <Select value={ colorGeocerca } onChange={ changeColor } options={ colores } />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>

      <div className="float-right">
        { isEnable("actualizar" || isEnable("agregar")) ?
          <span className="badge">
            <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={ enableSave() || !crudbutonEnable}
              onClick={accion === "modificar" ? () => crud_put() : () => crud_post()} >
              <span className="badge"><Icon link={true} name="save" />Guardar</span>
            </Button>
          </span>
        :null}
        {accion === "modificar" && isEnable("borrar")?
          <span className="badge">
            <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => borrar() }  disabled={!crudbutonEnable}>
              <span className="badge"><Icon link={true} name="trash" />Borrar</span>
            </Button>
          </span>
          :
          null
        }
      </div>
    </div>
  );
}
export default ConsignatariosMapa;
