import React, { Component } from "react";
import { List } from "tabler-react";
import { Crud_Catalogos, Crud_error }            from '../../../../herramientas/Crud_Catalogos';
class Cliente extends Component {
  constructor(props){
    super(props);
    this.state = {
      incidencias: [],
      hiddenModals: true,
      contactos: []
    };
  }
  componentDidMount =() => {
    if(this.props.obj){
      this.getfuntion("get", "", "", "personas/cliente/"+this.props.obj.id, "contactos");
    }
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas", stateVar = "personas", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "contactos" && returnVal) {
              for(let item of returnVal){
                if(!item.aMaterno){item.aMaterno=""}
                if(!item.aPaterno){item.aPaterno=""}
                if(!item.nombre){item.nombre=""}
              }
               this.setState({ contactos: returnVal });
            }       
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  render() {
    return (
      
      <div className= "mt-1">
        <List.Group>
          <List.GroupItem action icon="user"> Cliente: {this.props.obj? this.props.obj.nombre: null } </List.GroupItem>
          <List.GroupItem action icon="phone"> Telefono: {this.props.obj? this.props.obj.telefono: null }, ext: {this.props.obj? this.props.obj.extension:null } </List.GroupItem>
          <List.GroupItem action icon="home"> Domicilio: {this.props.obj? this.props.obj.domicilio:null } </List.GroupItem>
          <h4>Contactos</h4>
          {this.state.contactos.map((cont, index)=>
            <List.GroupItem action icon="mail">
              <div  className="badge" data-toggle="tooltip" data-placement="top" title={cont.correoEstatus? "Recibe correos":"No recibe correos"}
                                                                      style={{ color: cont.correoEstatus? "green": "red", "font-size": "100%" }}>
                <span className="fa fa-flag"></span>                
              </div>
              {cont.correo}( {cont.aPaterno+" "+ cont.aMaterno+ " "+ cont.nombre} )
            </List.GroupItem>
          )}
        </List.Group>
      </div>
            
    );
  }
}
export default Cliente;