import React, { useState, useEffect } from 'react';
import { Grid, Button, Icon, Form, Table, Dimmer } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../herramientas/Crud_Catalogos';
import ModalLayout from '../components/ModalLayout.react';
import TabsLayout from '../components/TabsLayout.react';
import { Configuracion, Usuarios, Unidades } from './Formularios';

let trans= [];
const Gerenciador = () => {
  const [ control, setControl]=useState({ loadingTrans: true });
  const [ tabs, setTabs]=useState([]);

  const [ open, setOpen]= useState(false);
  const [ hiddenModals, setHiddenModals ]= useState(false);
  const [ currentTap, setCurrentTap ]= useState(0);
  const [ transportistas, setTransportistas]= useState([]);
  const [ transportista, setTransportista]= useState({id:0});

  useEffect(() => {
    crud("get", "", "", "transportistas", "transportistas");
    //let form1   = { title:"Nuevo" , icon:"plus",  panel: <Configuracion  metodo= "post" crudT={crud} />,close:false };
    //setTabs([form1]);
  }, []);
  const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get": {
              if (stateVar === "transportistas") {
                  setTransportistas(returnVal);
                  setControl({loadingTrans: false});
              }
              if( stateVar === "lineaTransporte"){
                if( returnVal.id ){
                  let    form1   = { title:"Configuración" , icon:"settings",  panel:<Configuracion transportista={trans} lineaTransporte= {returnVal}  metodo= "put" />,close:false };
                  let form2   = { title:"Usuarios"      , icon:"user",         panel:<Usuarios      transportista={trans} lineaTransporte= {returnVal}   />,close:false };
                  let form3   = { title:"Unidades"      , icon:"truck",        panel:<Unidades      transportista={trans} lineaTransporte= {returnVal}   />,close:false };
                  setTabs([form1, form2, form3]);
                }else{console.log(trans);
                  let form1   = { title:"Nuevo" , icon:"plus",  panel: <Configuracion  transportista={trans} lineaTransporte= {returnVal} metodo= "post" />,close:false };
                  setTabs([form1]);
                }
              }
          } break;
          default:
              break;
        }
    }).catch(err => {
      if (stateVar === "transportistas") {
        setControl({loadingTrans: false});
      }
       console.log(err);
      });
}
  const changeTransportista=(e)=>{
    trans= e;
    setTimeout(() => {
      setTransportista(e);
    }, 5);
    crud("get", "", "", "lineaTransporte/transportista/"+ transportista.id, "lineaTransporte");
  }
  const nuevo =()=>{
    let form1   = { title:"Nuevo" , icon:"plus",  panel: <Configuracion  transportista={transportista} metodo= "post" />,close:false };
    setTabs([form1]);
  }
  return (
    <div className="container mt-3">
      <Grid.Row>
        <Grid.Col>
          <h2>	LINEAS DE TRANSPORTE </h2>
          <span className="float-right">
            <ModalLayout
              tamanio={"90%"}
              title={transportista.linea}
              name={<Button pill size="sm" outline color="primary" onClick={()=> nuevo()}><Icon name="plus" /></Button>}
              hiddenModal={ hiddenModals }
              Fclose={ setHiddenModals }
              formulario={
                <TabsLayout tabs={ tabs } selectedTab= { currentTap } selectTab= { setCurrentTap } />
              }
            />
          </span>
          <h5> DHL</h5>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          { control.loadingTrans?
            <Dimmer active={control.loadingTrans} loader className="mt-6"></Dimmer>            
            :
              <Table cards={false} striped={false} responsive={true} className="table-vcenter"
              headerItems={[
                  {content:"ID"},
                  {content:"linea"},
                  {content:"Nombre Corto"},
                  {content:"Gerenciado"},
                  {content:"CONFIGURACIÓN"},
                ]}>
                  <Table.Body>
                    {transportistas?
                      transportistas.map((t, index) =>
                        <Table.Row align="center" key={index} style={{ "backgroundColor": transportista.id === t.id? "rgba(95, 231, 50, 0.30)":"rgba(95, 231, 50, 0)"}}onClick={()=>changeTransportista(t)} >
                            <Table.Col> { t.id } </Table.Col>
                            <Table.Col> { t.nombre } </Table.Col>
                            <Table.Col> { t.nombreCorto } </Table.Col>
                            <Table.Col> { <Form.Switch type="Checkbox" checked={t.gerenciado} /> } </Table.Col>
                            <Table.Col> 
                              <ModalLayout
                                tamanio={"90%"}
                                title={transportista.linea}
                                name={<Button pill size="sm" outline color="primary"><Icon name="settings" /></Button>}
                                hiddenModal={ hiddenModals }
                                Fclose={ setHiddenModals }
                                formulario={transportista.id?
                                  <TabsLayout tabs={ tabs } selectedTab= { currentTap } selectTab= { setCurrentTap } />
                                  :<div></div>
                                }
                              />     
                            </Table.Col>
                        </Table.Row>)
                    :null}
                  </Table.Body>
              </Table>
          }  
        </Grid.Col>
      </Grid.Row>
    
      
    </div>
  );
}


export default Gerenciador;
