// @flow
import React, { Component } from "react";
import Select from "react-select";
import { Button, Form, Icon, Grid } from "tabler-react";

class GPSForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombre: "",
      marca: "",
      imei: "",
      sim: "",
      altaFecha: "",
      idGg: "",
      estatus: 0,
      gpsEstatus: {value: 0, label:"Seleciona una opción"},
      gpsHardware: 0,
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      crudbutonEnable: true, // Se desabilita cuando se hace click
    };
    this.checarEtiqueta = false;
    this.etiquetaId = 0;
    this.enableSave = this.enableSave.bind(this);
    this.gpsObject = [];
    this.gpsEstas = [];
    this.gpsHardwares = [];
  }
  isEnable=(Accion)=>{
    let borrar= "gps_borrar";
    let actualizar= "gps_editar";
    let agregar= "gps_agregar";
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "borrar"){ 
        if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }
  componentDidMount = () => {
    this.gpsEstas.push({value: 0, label:"Seleciona una opción"})
    this.props.stor.gpsEstatus_object.map((elemento, index)=> {
      this.gpsEstas.push({value:elemento.id, label:elemento.nombre})
    })


    this.props.stor.gpsHardware_object.map((elemento, index) =>{
      this.gpsHardwares.push({value:elemento.id, label:elemento.nombre})
    })


    if (this.state.accion !== 'nuevo') {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ marca: this.props.elementos.marca });
      this.setState({ imei: this.props.elementos.imei });
      this.setState({ sim: this.props.elementos.sim });
      this.setState({ idGg: this.props.elementos.idGg });
      this.setState({ estatus: this.props.elementos.estatus });
      this.setState({ gpsEstatus: {value:this.props.elementos.gpsEstatus.id, label: this.props.elementos.gpsEstatus.nombre }});
      this.setState({ gpsHardware: {value:this.props.elementos.gpsHardware.id, label: this.props.elementos.gpsHardware.nombre }});
      var d = new Date(this.props.elementos.altaFecha);
      var mes = (d.getMonth() + 1);
      var dia = d.getDate();
      var mes1 = "";
      var dia1 = "";
      mes < 10 ? mes1 = "0" + mes : mes1 = mes;
      dia < 10 ? dia1 = "0" + dia : dia1 = dia;
      this.setState({ altaFecha: d.getFullYear() + "-" + mes1 + "-" + dia1 });
      this.gpsObject = this.props.elementos;
    } else {
      this.gpsObject = {  "id": 0, "nombre": "", "marca": "", "imei": "", "sim": "", "latitud": 0, "longitud": 0, "altaFecha": null, "idGg": 0, "estatus": 0, "gpsEstatus": {}, "gpsHardware": {},
                          "idCorredor": {
                            "estatus": 1,
                            "id": 1,
                            "latitud": 0,
                            "longitud": 0,
                            "nombre": "PENDIENTE NAVIERA",
                            "nombreCorto": "PENDIENTE NAVIERA",
                            "plataformaTipo": {id: 1, nombre: "Naviera"},
                            "tipoCorredor": "F"
                          } 
                        }
    }

  }
  enableSave = () => {
    if (this.state.nombre && this.state.marca && this.state.imei && this.state.sim && this.state.idGg && this.state.gpsEstatus && this.state.gpsHardware ){
      if (this.state.nombre.length > 0 && this.state.marca.length > 0 && this.state.imei.length > 0 && this.state.sim.length > 0 && this.state.idGg > -1 && this.state.gpsEstatus.value > 0 && this.state.gpsHardware.value > 0) {
        return false;
      } else { return true }
    }else{ return true; }
  }
  changeName = (event) => {
    this.gpsObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  }
  changeMarca = (event) => {
    this.gpsObject.marca = event.target.value;
    this.setState({ marca: event.target.value });
  }
  changeImei = (event) => {
    this.gpsObject.imei = event.target.value;
    this.setState({ imei: event.target.value });
  }
  changeSim = (event) => {
    this.gpsObject.sim = event.target.value;
    this.setState({ sim: event.target.value });
  }
  changeidGg = (event) => {
    this.gpsObject.idGg = event.target.value;
    this.gpsObject.id= event.target.value;
    this.setState({ idGg: event.target.value });
    this.setState({ id: event.target.value });
  }
  changeEstatus = (event) => {
    this.state.estatus === 0 ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });
    this.state.estatus === 0 ? this.gpsObject.estatus = 1 : this.gpsObject.estatus = 0;
  }
  changeGPSEstatus = (event) => {
    for (let item of this.props.stor.gpsEstatus_object) {
      if (item["id"] == event.value) {
        this.gpsObject.gpsEstatus = item;
        break;
      }
    }
    this.setState({ gpsEstatus: event })
  };
  changeGPSHardware = (event) => {
    for (let item of this.props.stor.gpsHardware_object) {
      if (item["id"] == event.value) {
        this.gpsObject.gpsHardware = item;
        break;
      }
    }
    this.setState({ gpsHardware: event });
  }

  delete() { this.props.getfuntion("delete", "", this.state.id);  this.setState({ crudbutonEnable: false }); }
  crud_put() {
    var params = this.gpsObject;
    params.altaFecha = null;
    this.props.getfuntion("put", params);
    this.setState({ crudbutonEnable: false });
  }
  crud_post() {
    var params = this.gpsObject;
    params.altaFecha = null;
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("post", params);
  }
  render() {
    return (
      <div>        
        <div className="row">
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="idGg">
                <Form.Input name="idGg" type="number" value={this.state.idGg} onChange={this.changeidGg} />
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Nombre">
                <Form.Input name="nombre" type="text" value={this.state.nombre} onChange={this.changeName} maxlength="30" />
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Marca">
                <Form.Input name="marca" type="text" value={this.state.marca} onChange={this.changeMarca} maxlength="50" />
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Imei">
                <Form.Input name="imei" type="text" value={this.state.imei} onChange={this.changeImei} maxlength="20" />
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Sim">
                <Form.Input name="sim" type="text" value={this.state.sim} onChange={this.changeSim} maxlength="30" />
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="GPS Hardware">
                <Select value={this.state.gpsHardware}  onChange={this.changeGPSHardware} options={this.gpsHardwares} />
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="GPS Status">
                <Select value={this.state.gpsEstatus} onChange={this.changeGPSEstatus}  options={this.gpsEstas}/>
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Fecha de alta">
                <Form.Input name="fechaAlta" type="date" value={this.state.altaFecha} disabled />
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Estatus">
                <Form.Switch type="Checkbox" name="toggle" value={this.state.estatus} checked={this.state.estatus} onChange={this.changeEstatus} />
              </Form.Group>
            </div>
        </div>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                <span className="badge"><Icon link={true} name="save" />Guardar</span>
              </Button>
            </span>
          :null}
          {this.state.accion === "modificar" && this.isEnable("borrar")?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()}  disabled={!this.state.crudbutonEnable}>
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
            :
            null
          }
        </div>
      </div>
    );
  }
}
export default GPSForm;
