import React, { useState, useEffect } from 'react';
import { Grid, Button, Icon, Form, Table, Dimmer } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import Select from "react-select";


let transObj= {
  "transportista": null,//obj
  "razonSocial": "",
  "idProveedorGps": null,//obj
  "idOrigen": null,//obj
  "idGgTag": null,
  "idCliente": null//obj
};
const Configuracion = ({transportista, metodo, lineaTransporte}) => {
  const [ control, setControl]=useState({ crudbutonEnable: true});
  const [ clientes, setClientes ]= useState([]);
  const [ proveedores, setProveedores ]= useState([]);
  const [ origenes, setOrigenes ]= useState([]);
  const [ form, setForm]= useState({  idGgTag: null,
                                      razonSocial:"",
                                      cliente:{ value: 0, label: "Seleccione una opción"},
                                      proveedor:{ value: 0, label: "Seleccione una opción"},
                                      origen:{ value: 0, label: "Seleccione una opción"}
                                    });
  useEffect(() => {
    //crud("get", "", "", "lineaTransporte/transportista/"+ transportista.id, "lineaTransporte");
    if( metodo === "put" && lineaTransporte ){
      transObj= lineaTransporte;
      setForm({ idGgTag: lineaTransporte.idGgTag,
                razonSocial: lineaTransporte.razonSocial,
                cliente: lineaTransporte.idCliente? { value: lineaTransporte.idCliente.id, label: lineaTransporte.idCliente.nombre, data: lineaTransporte.idCliente }: { value: 0, label: "Seleccione una opción"},
                proveedor: lineaTransporte.idProveedorGps? { value: lineaTransporte.idProveedorGps.id, label: lineaTransporte.idProveedorGps.nombre, data: lineaTransporte.idProveedorGps }: { value: 0, label: "Seleccione una opción"},
                origen: lineaTransporte.idOrigen? { value: lineaTransporte.idOrigen.id, label: lineaTransporte.idOrigen.nombre, data: lineaTransporte.idOrigen }: { value: 0, label: "Seleccione una opción"}
              });
    }else{
      transObj.transportista= transportista;
    }

    crud("get", "", "", "clientesGerenciador", "clientes");
    crud("get", "", "", "proveedoresGps", "proveedores");
    crud("get", "", "", "origen", "origen");
  }, []);
  const enableSave =() =>{
    if( true ){
      return false;
    }else{ return true }
  }
  const crud_put  =() => { setControl({ ...control, crudbutonEnable: false });  crud("put", transObj, "", "lineaTransporte", "transportistas"); }
  const crud_post =() => { setControl({ ...control, crudbutonEnable: false });  crud("post", transObj, "", "lineaTransporte", "transportistas"); }
  return (
    <div className="container mt-3">{console.log(transportista, metodo, lineaTransporte)}
      <Grid.Row>
        <Grid.Col>
          <h5>	LINEAS DE TRANSPORTE </h5>
          <h2> {transportista?transportista.nombre+ "...":"empty"}</h2>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Form.Group label="idGgTag: ">
            <Form.Input name="idGgTag" type="number" value={ form.idGgTag } onChange={(e)=>{ setForm({...form, idGgTag: e.target.value }); transObj.idGgTag= e.target.value; } } />
          </Form.Group>
        </Grid.Col>
        <Grid.Col>
          <Form.Group label="Razon Social: ">
            <Form.Input name="razonSocial" type="text" value={ form.razonSocial } onChange={(e)=>{ setForm({...form, razonSocial: e.target.value }); transObj.razonSocial= e.target.value; } } />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Form.Group label="Cliente: ">
            <Select value={form.cliente}  options={clientes} onChange={(e)=>{ setForm({...form, cliente: e }); transObj.idCliente= e.data; }} />
          </Form.Group>
        </Grid.Col>
        <Grid.Col>
          <Form.Group label="Proveedor: ">
            <Select value={form.proveedor}  options={proveedores} onChange={(e)=>{ setForm({...form, proveedor: e }); transObj.idProveedorGps= e.data; }} />
          </Form.Group>
        </Grid.Col>
        <Grid.Col>
          <Form.Group label="Origen: ">
            <Select value={form.origen}  options={origenes} onChange={(e)=>{ setForm({...form, origen: e }); transObj.idOrigen= e.data; }} />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <div className="float-right">
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={ enableSave() || !control.crudbutonEnable}
                onClick={ metodo === "put" ? () => crud_put() : () => crud_post()} >
                <span className="badge"><Icon link={true} name={metodo === "put" ? "edit": metodo === "post" ? "plus": "save"} />Guardar</span>
              </Button>
            </span>
          { false?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => alert("borrar")}  disabled={!control.crudbutonEnable}>
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
            :
            null
          }
        </div>
      </Grid.Row>
    
      
    </div>
  );

  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    if (stateVar === "clientes") {
                      let clientesTemp= [];
                      returnVal.map((c, index)=> clientesTemp.push({ value: c.id, label: c.nombre, data:c}) );
                      setClientes(clientesTemp);
                    }
                    if (stateVar === "proveedores") {
                      let proveedoresTemp= [];
                      returnVal.map((p, index)=> proveedoresTemp.push({ value: p.id, label: p.nombre, data:p}) );
                      setProveedores(proveedoresTemp);
                    }
                    if (stateVar === "origen") {
                      let origenesTemp= [];
                      returnVal.map((o, index)=> origenesTemp.push({ value: o.id, label: o.nombre, data:o}) );
                      setOrigenes( origenesTemp);
                    }
                } break;
                case "post": {
                  if (stateVar === "transportistas") {
                    //setHiddenModals(true);
                  }
                } break;
                case "put": {
                  if (stateVar === "transportistas") {
                    //setHiddenModals(true);
                  }
                } break;
                default:
                    break;
            }
        }).catch(err => {
          setControl({ ...control, crudbutonEnable: true });
          if (stateVar === "transportistas") {
            //setControl({loadingTrans: false});
          }
           console.log(err);
          });
    }
}


export default Configuracion;
