import React, { useEffect } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import Select from "react-select";

export const CustomInput = ({
  control,
  name,
  rules = {},
  text,
  value = "",
  col,
  type = "text",
  required,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <div className={`${col} form-group`}>
          <label className={`form-label ${required ? 'required' : ''}`}>{text}</label>
          <input
            className="form-control"
            ref={ref}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
          />
          {error && (
            <small clasName="form-text text-muted" style={{ color: "red" }}>
              {error.message || "Error"}
            </small>
          )}
        </div>
      )}
    />
  );
};

export default CustomInput;
