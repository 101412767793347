import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { api_raiz } from "../config/config";

export const useGetData = (url, name, habilitado = true) => {
  const token = sessionStorage.getItem("tok");

  const getData = async () => {
    const { data } = await axios.get(api_raiz + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return data;
  };

  const { data, isLoading, isFetching, refetch } = useQuery({queryKey: [name], queryFn: getData, enabled: !!habilitado});

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
