import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Catalogo from "../../components/Catalogo";
import {TableroRetransmisionesForm}  from "../../Consola";

function TableroRetransmisionesPage() {
  return (
    <SiteWrapper>
        <Grid.Col sm={12} lg={12}> 
        < TableroRetransmisionesForm  />
        </Grid.Col>
    </SiteWrapper>
  );
}
export default TableroRetransmisionesPage;
