import React from "react";
import classes from "./Alert.module.css";

var user = JSON.parse(sessionStorage.getItem("usuario"));

const Alert = React.memo(
  ({
    tiempo,
    setTiempos,
    changeColToFilter,
  }) => {
    return (
      <li className={`${classes.Message} ${classes.AnimationAppear}`}>
        {console.log(tiempo)}
        <div className={classes.MessageBody}>
          <div onClick={() => console.log("click")}>
            <p className={classes.MessageBodyTitle}>
              ID del Movimiento: {tiempo.idMovimiento}
            </p>
            <p className={classes.MessageBodyP}>{tiempo.asunto}</p>
            <p className={classes.MessageBodyP}>Placa: {tiempo.placa}</p>
            <p className={classes.MessageBodyP}>
              Carta Porte: {tiempo.cartaPorte}
            </p>
          </div>
          <button
            className={`${classes.MessageButton}`}
            id="js-showMe"
            onClick={() => changeColToFilter(tiempo.idMovimiento)}
          >
            Ir al Movimiento
          </button>
          <button
            className={`${classes.MessageClose}`}
            onClick={() => setTiempos(tiempo.idTemporizadores)}
          >
            <i class="fa fa-times" style={{ "font-size": "1em" }} />
          </button>
        </div>
      </li>
    );
  }
);

export default Alert;
