import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import Select from "react-select";

class GPSFijos extends Component {
    constructor(props) {
      super(props);
      this.state = {
                 gpsFijo : { value:0, label:"Seleccione un GPS Fijo " },
                 gpsTipo : { value:0, label:"Seleccione un Tipo de Colocacion " },
                  accion : "post", //'nuevo'// modificar, nuevo
                  gpsFijos: [],
                  unidadGps: [],
        };
             this.gpsTipo=[];
               this.valid={ gpsTipo:false, gpsFijos:false };
               this.gps={};
    }
    componentDidMount =() => {
      this.getfuntion("get", "", "", "gpsFijos/estatus/0","gpsFijos");
      this.getfuntion("get", "", "", "gpsFijos/unidad/"+this.props.elementos.id,"unidadGps");
      this.props.stor.gpstipocolocacion_object.map((elemento, index) => this.gpsTipo.push({ value:elemento.id, label:elemento.nombre, data: elemento }) );

      if(this.state.accion !== 'nuevo'){
      }else{
      }
           //this.AlertasTipo.push({ value:0, label:"Seleccione un Tipo " })
      //this.AlertasCategoria.push({ value:0, label:"Seleccione una Categoria " })
    }
    isValid=(campo) =>{
      switch (campo) {
        case "gpsFijo":
          return this.valid.gpsFijo;
          break;
        case "gpsTipo":
          return this.valid.gpsTipo;
          break;
        default:
          break;
      }
    }
    changeGpsTipo =(event) =>{
      this.gps.idGpsTipoColocacion= event.data;
      this.setState({gpsTipo:event});
      if(event.value > 0){ this.valid.gpsTipo= true }else{ this.valid.gpsTipo= false }
    }
    changeGpsFijo =(event) =>{
      this.gps= event.data;
      this.gps.idUnidad= this.props.elementos.id;
      this.gps.estatus= 1;
      this.setState({gpsFijo:event});
      if(event.value > 0){ this.valid.gpsFijo= true }else{ this.valid.gpsFijo= false }
    }

    disableSave =() =>{
      if (this.valid.gpsFijo && this.valid.gpsTipo) {
        return false;
      }else{
        return true;
      }
    }

    crud_put =() =>{}
    crud_post =() =>{
      this.getfuntion("put", this.gps, "", "gpsFijos","gpsFijo");     
      this.setState({ accion: "nuevo" });
    }
    crud_delete =(gps) =>{
      gps.estatus= 0;
      gps.idGpsTipoColocacion= null;
      gps.idUnidad= null;
      this.getfuntion("put", gps, "", "gpsFijos","gpsFijo");      
      this.setState({ accion: "nuevo" });
    }
    
    getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
      //Crud_Catalogos("clientesContactos"+"/borrar", "", "delete", parametrosLlave Compuesta, "" , "" , "FHabilitados")
      Crud_Catalogos(catalogo, "loquequieras", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
          switch (metodo) {
            case "post":
              {
              } break;
            case "get":
              {
                if (stateVar === "gpsFijos"){
                  let gpsFijosTem=[];
                  returnVal.map((elemento, index) => gpsFijosTem.push({ value:elemento.id, label:elemento.nombre, data: elemento }) );
                  this.setState({ gpsFijos: gpsFijosTem});
                }
                if (stateVar === "unidadGps"){
                  let gpsFijosTem=[];
                  returnVal.map((elemento, index) => gpsFijosTem.push(elemento) );
                  this.setState({ unidadGps: gpsFijosTem});
                }
              } break;
            case "put":
              {
                if (stateVar === "gpsFijo"){
                  this.setState({ gpsFijo : { value:0, label:"Seleccione un GPS Fijo " } });
                  this.setState({ gpsTipo : { value:0, label:"Seleccione un Tipo de Colocacion " } });
                  this.gps= {};
                  if(returnVal.estatus === 1){
                    let gpsFijosTem= this.state.gpsFijos;
                    gpsFijosTem.map((g, index)=>{
                      if( g.id === returnVal.id){
                        gpsFijosTem.splice(index, 1);
                        this.setState({ gpsFijos: gpsFijosTem });
                        this.valid={ gpsTipo:false, gpsFijos:false };
                      }
                    });
                    let unidadGpsTemp= this.state.unidadGps;
                    unidadGpsTemp.push(returnVal);
                    this.setState({ unidadGps: unidadGpsTemp });
                  }else{
                    let unidadGpsTemp= this.state.unidadGps;
                    unidadGpsTemp.map((g, index)=>{
                      if( g.id === returnVal.id){
                        unidadGpsTemp.splice(index, 1);
                        this.setState({ unidadGps: unidadGpsTemp });
                        this.valid={ gpsTipo:false, gpsFijos:false };
                        let gpsFijosTem= this.state.gpsFijos;
                        gpsFijosTem.push(returnVal);
                        this.setState({ gpsFijos: gpsFijosTem });
                      }
                    });
                  }
                }
              } break;
            default:
              break;
          }
        }).catch(err =>{
          if( err.response ){
            console.log(err.response);
          }else{
            /**
             * Error
             */
            console.log("Error desconocido ....");
            console.log(err)
          }
      })
    }
  //<Button pill  size="sm" outline color="primary" onClick={ ()=> this.AlertasEdit(elemento.alertaCategoria.id, elemento.alertaTipo.id) }><Icon name="edit"/></Button>
    render(){
      return (
        <div>
            <div>
              <Grid.Row>{ console.log(this.props.elementos)}
                <Grid.Col>
                  <Card
                    title=  { <div>Gps de la unidad: 
                              {this.props.elementos.unidadTipo?this.props.elementos.unidadTipo.nombre:""}, 
                              {this.props.elementos.unidadMarca?this.props.elementos.unidadMarca.nombre:""},
                              {this.props.elementos.modelo},
                              {this.props.elementos.placa}, 
                              {this.props.elementos.color}
                            </div>}
                    isCollapsible={ false }
                    isCollapsed={ false}
                    body={
                    <div>
                      <Grid.Row>
                      <Grid.Col sm={12} md={4}>
                        <Form.Group label="GPS Fijo">
                            <Select value={this.state.gpsFijo} onChange={this.changeGpsFijo} options={this.state.gpsFijos} />
                        </Form.Group>
                        <Form.Group label="Tipo de GPS">
                            <Select value={this.state.gpsTipo} onChange={this.changeGpsTipo} options={this.gpsTipo} />
                        </Form.Group>
                        <div className="float-right">
                          <span className="badge">
                            <Button size="sm" RootComponent="a" color="primary" disabled= { this.disableSave() } onClick={this.state.accion==="modificar"?()=>this.crud_put():()=>this.crud_post()} >
                              <span className="badge"><Icon link={true} name="save" />Guardar</span>
                            </Button>
                          </span>
                        </div>
                      </Grid.Col>

                      <Grid.Col sm={12} md={8}>
                          <Form.Group>
                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                              <Table.Header>
                                <Table.ColHeader>idUnidad</Table.ColHeader>
                                <Table.ColHeader>GPS Fijo (idGg, Nombre, Imei)</Table.ColHeader>
                                <Table.ColHeader>GPS Tipo</Table.ColHeader>
                                <Table.ColHeader>Acción</Table.ColHeader>
                              </Table.Header>
                              <Table.Body>{console.log(this.state.unidadGps)}
                              {
                                this.state.unidadGps.map((elemento, index) =>
                                  <Table.Row>
                                    <Table.Col>{ elemento.idUnidad? elemento.idUnidad:"" }</Table.Col>
                                    <Table.Col>{ elemento.idGg? elemento.idGg+ " - " + elemento.nombre + " - " + elemento.imei:"" }</Table.Col>
                                    <Table.Col>{ elemento.idGpsTipoColocacion? elemento.idGpsTipoColocacion.nombre:"" }</Table.Col>
                                    <Table.Col><Button pill  size="sm" outline color="primary" onClick={ ()=> this.crud_delete(elemento) }><Icon name="trash"/></Button></Table.Col>
                                  </Table.Row>
                                )
                              }
                              </Table.Body>
                            </Table>
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col sm={12} md={12}>
                          Valida todos tus datos antes de guardar, del lado derecho podras asignar los GP's Fijos...
                        </Grid.Col>
                      </Grid.Row>
                    </div>
                  }
                  />
                </Grid.Col>

              </Grid.Row>
            </div>
        </div>
      )
    }
  }

  export default GPSFijos;
