// @flow
import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Site, Nav, Grid, List, Button, RouterContextProvider } from "tabler-react";
import type { NotificationProps } from "./tabler-react";

import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

type Props = {|
  +children: React.Node,
|};

type State = {|
  notificationsObjects: Array<NotificationProps>,
|};

type subNavItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +LinkComponent?: React.ElementType,
|};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +active?: boolean,
  +LinkComponent?: React.ElementType,
  +subItems?: Array<subNavItem>,
  +useExact?: boolean,
|};

const navBarItems: Array<navItem> = JSON.parse(sessionStorage.getItem("navBarItems"));

var user= JSON.parse(sessionStorage.getItem("usuario"));
var perfil= JSON.parse(sessionStorage.getItem("perfil"));


const accountDropdownProps = {
  avatarURL: "./user/user.png",
  name: user ? user.persona? user.persona.nombre+" "+user.persona.aPaterno+" "+ user.persona.aMaterno: "":"",
  description: perfil ? perfil.nombre: "",
  options: sessionStorage.getItem("navBarItems")? [
    { icon: "user", value: "Perfil", to: "/perfilusuario"},
    { icon: "log-out", value: "Salir", to: "/logout"},
  ]:[],
};

class SiteWrapper extends React.Component<Props, State> {
  state = {
    notificationsObjects: [
      {
        unread: true,
        avatarURL: "demo/faces/male/41.jpg",
        message: (
          <React.Fragment>
            <strong>Skyangel</strong> Esto es una notificación
          </React.Fragment>
        ),
        time: "10 hace 10 min",
      },
    ],
  };

  render(): React.Node {
    const notificationsObjects = this.state.notificationsObjects || [];
    const unreadCount = this.state.notificationsObjects.reduce(
      (a, v) => a || v.unread,
      false
    );
    return (
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "Sky Angel",
          imageURL: "images/official_dhllogo.png",
          navItems: (
            <Nav.Item type="div" className="d-none d-md-flex">
              {}
            </Nav.Item>
          ),
          
          accountDropdown: accountDropdownProps,
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
      >
        <NotificationContainer/>
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default SiteWrapper;
