// @flow

import React, { Component } from "react";
import { Card, FormTextInput, Button, Icon, Grid, Alert, Dimmer } from "tabler-react";
import defaultStrings from "../../data/LoginPage.strings";
import { Auth } from '../../Auth/Auth';
import * as conf from "../../config/config";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import { from, retry } from "rxjs";
import { NavLink, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { getPrivilegio, tokenGetUserName } from "../../herramientas/JWT";
const alertasConf= [
  {type: "danger", icon:"alert-triangle"},
  {type:"primary", icon:"bell"},  
  {type:"success", icon:"check"},
];
let loadingVar= false;
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      respuesta: "",
      username: "",
      password: "",
      code: "",
      showmessage: false,
      message: "No message",
      loginMessage: null,
      authStatus: 0,
      loading: false
    };
    this.props.history.push('/login');
  }
  componentWillMount = () => {
    sessionStorage.setItem("tok", "");
    //alert(window.location);
  }
  changePassword = (event) => {
    this.setState({ password: event.target.value });
  }
  changeCode = (event) => {
    this.setState({ code: event.target.value });
  }
  changeUsername = (event) => {
    this.setState({ username: event.target.value });
  }
  getLogin = async () => {
    if( loadingVar ){
      return true;
    }
    let resp = [];
    let usuario;
    this.setState({ showmessage: false });
    this.setState({ authStatus: 0 });
    this.setState({ loading: true});
    loadingVar= true;
    await Auth("login", this.state.username, this.state.password, this.state.code).then((res) => {
      this.setState({ username: '' });
      this.setState({ password: '' });
      this.setState({ loading: false});
      loadingVar= false;
      resp = res;
      if (res.lastUpdatePassword >= 60) { window.location.href = 'actualizar_password'; }
      this.setState({ message: res.message });
      this.setState({ showmessage: true }); 
      if( resp.token  === null ){
        this.setState({ authStatus: 1 });
      }else{
        if(getPrivilegio( resp.token, "PRE_VERIFICATION_USER")){
          this.setState({ authStatus: 2 });
        }
        if(getPrivilegio( resp.token, "plataformas_usuarios_perfiles_listar")){
          this.setState({ authStatus: 3 });
        }
      }
    }).catch(err => {
      console.log(err);
      if (err.response) {alert("fjidsn")
        resp= err.response.data;
        this.setState({ loginMessage: null });
        this.setState({ showmessage: true });        
        this.setState({ code: '' });
        this.setState({ message: err.response.data.message });
      } else {
        this.setState({ loginMessage: null });
        this.setState({ code: '' });
        this.setState({ message: "" + err });
        this.setState({ showmessage: true });
      }
    });
    if (resp.token && getPrivilegio(sessionStorage.getItem("tok"), "plataformas_usuarios_perfiles_listar")) {
      this.setState({ loginMessage: "Cargando perfiles .." });
      await Crud_Catalogos("plataformasUsuariosPerfiles/username", "usuarios", "post", "", { "username": tokenGetUserName(resp.token) }, "", "currentUser").then((returnVal) => {
        usuario = returnVal[0] ? returnVal[0] : null;
        this.setState({ loginMessage: "Construyendo menu de opciones ..." });
        sessionStorage.setItem("usuario", JSON.stringify(returnVal[0].usuario));
        sessionStorage.setItem("perfil", JSON.stringify(returnVal[0].perfil));
        sessionStorage.setItem("plataforma", JSON.stringify(returnVal[0].plataforma));
        sessionStorage.setItem("currentUser", JSON.stringify(returnVal));
      }).catch(err => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
        //window.location.href = "/login";
      });

      await Crud_Catalogos("bloque/usuario/" + usuario.usuario.id + "/perfil/" + usuario.perfil.id, "usuarios", "get", "", "", "", "currentUser").then((bloques) => {
        let Administracion = false;
        let navBarItems = [];

        let configuracionSubItems = [];
        let dashboardSubItems = [];
        let modulosMonitoreoSubItems = [];
        let consultaSubItems = [];
        console.log(bloques);

        bloques.map((item, index) => {
          switch (item.nombre) {
            case "Administracion": {
              navBarItems.push({ value: "Administración", icon: "layers", to: "/adminstracion", LinkComponent: withRouter(NavLink), useExact: true });
              Administracion = true;
            } break;

            case "Monitoreo_Terrestre": {
              modulosMonitoreoSubItems.push({ value: "Monitoreo Activos", icon: "layers", to: "/monitoreo?q=activos", LinkComponent: withRouter(NavLink) });
              modulosMonitoreoSubItems.push({ value: "Monitoreo Finalizados", icon: "layers", to: "/monitoreo?q=finalizados", LinkComponent: withRouter(NavLink) });             
            } break;
            case "movimientos_lenovo": {
              modulosMonitoreoSubItems.push({ value: "Movimientos Lenovo", icon: "layers", to: "/monitoreo?q=lenovo", LinkComponent: withRouter(NavLink) });
            } break;
            case "movimientos_delay": {
              modulosMonitoreoSubItems.push({ value: "Movimientos en delay", icon: "layers", to: "/monitoreo?q=delay", LinkComponent: withRouter(NavLink) });
            } break;
            case "con_unidad_con_gps": {
              modulosMonitoreoSubItems.push({ value: "Movimientos con unidad y con gps", icon: "layers", to: "/monitoreo?q=con_unidad_con_gps", LinkComponent: withRouter(NavLink) });
            } break;
            case "con_unidad_sin_gps": {
              modulosMonitoreoSubItems.push({ value: "Movimientos con unidad y sin gps", icon: "layers", to: "/monitoreo?q=con_unidad_sin_gps", LinkComponent: withRouter(NavLink) });
            } break;
            case "sin_unidad_sin_gps": {
              modulosMonitoreoSubItems.push({ value: "Movimientos sin unidad y sin gps", icon: "layers", to: "/monitoreo?q=sin_unidad_sin_gps", LinkComponent: withRouter(NavLink) });
            } break;
            case "sin_transportista": {
              modulosMonitoreoSubItems.push({ value: "Movimientos sin transportista", icon: "layers", to: "/monitoreo?q=sin_transportista", LinkComponent: withRouter(NavLink) });
            } break;
            case "mapa": {
              modulosMonitoreoSubItems.push({ value: "Mapa", icon: "map", to: "/mapa", LinkComponent: withRouter(NavLink), useExact: true });
            } break;
            case "Unidades_sin_ruta": {
              modulosMonitoreoSubItems.push({ value: "Unidades Sin Ruta", icon: "alert-circle", to: "/unidadessinruta", LinkComponent: withRouter(NavLink) }) }
              break;
            case "unidades_linea_transportistas": {
              modulosMonitoreoSubItems.push({ value: "Unidades linea transportistas", icon: "alert-circle", to: "/Unidades_Lt", LinkComponent: withRouter(NavLink) }) }
              break;
            case "tablero_visibilidad_dhl": { 
              modulosMonitoreoSubItems.push({ value: "Tablero visibilidad", icon: "check-square", to: "/tablero_visibilidad_dhl", LinkComponent: withRouter(NavLink) }) }
              break;
            case "Reportes":{           navBarItems.push({ value:  "Reportes", icon:"file-text",      to:"/reportes",LinkComponent:withRouter(NavLink)})}
              break;
            case "Reportes_Generales":{ navBarItems.push({ value: "Reportes Generales", icon:"file-text", to:"/reportesGenerales",LinkComponent:withRouter(NavLink)})}
              break; 
            case "SkyCold": { modulosMonitoreoSubItems.push({ value: "Sky Cold", icon: "thermometer", to: "/skycold", LinkComponent: withRouter(NavLink) }) }
              break;

            case "Consola_Terrestre": { configuracionSubItems.push({ value: "Usuario Consola Terrestres", icon: "airplay", to: "/usuarioTerrestre", LinkComponent: withRouter(NavLink) }) }
              break;
            case "gerenciador": { configuracionSubItems.push({ value: "Gerenciador", icon: "cpu", to: "/gerenciador", LinkComponent: withRouter(NavLink) }) }
              break;

            /*
            case "Consola_Naviera":{    configuracionSubItems.push({ value:  "Usuario Consola Navieros",   to:"/usuarioConsola",LinkComponent:withRouter(NavLink)}) }
              break;                
            case "Asignar_GPS":{        configuracionSubItems.push({ value:  "Asignar GPS",                to:"/movimientoGps",LinkComponent:withRouter(NavLink)}) }
              break;
            case "Recuperar_GPS":{      configuracionSubItems.push({ value:  "Recuperar GPS",              to:"/recuperarGps",LinkComponent:withRouter(NavLink)}) }                  
              break;
            */

            case "dashboard_gps_year": {
              dashboardSubItems.push({ value: "Dashboard GPS por Año", to: "/dashboard_gps_year", icon: "bar-chart-2", LinkComponent: withRouter(NavLink), });
            } break;
            case "mov_x_transportista_semanal": {
              dashboardSubItems.push({ value: "Mov por Transportista Semanal", to: "/reportes_por_trasportista_semanal", icon: "bar-chart-2", LinkComponent: withRouter(NavLink), useExact: true });
            } break;
            case "otd_movimiento_activos_por_transportista": {
              dashboardSubItems.push({ value: "OTD Movimientos Activos por Transportista", to: "/otd_movimiento_activos_por_transportista", icon: "bar-chart-2", LinkComponent: withRouter(NavLink), useExact: true });
            } break;
            case "graficos_visibilidad": {
              dashboardSubItems.push({ value: "Estatus de placas", to: "/estatus_placas", icon: "pie-chart", LinkComponent: withRouter(NavLink), useExact: true });
              dashboardSubItems.push({ value: "Estatus de viaje", to: "/estatus_viajes", icon: "pie-chart", LinkComponent: withRouter(NavLink), useExact: true });
              dashboardSubItems.push({ value: "Estatus de incidencias", to: "/estatus_incidencias", icon: "pie-chart", LinkComponent: withRouter(NavLink), useExact: true });
              dashboardSubItems.push({ value: "Estatus de visibilidad", to: "/estatus_visibilidad", icon: "pie-chart", LinkComponent: withRouter(NavLink), useExact: true });

            } break;
            case "eventos_enviados_otm_msc": {
              dashboardSubItems.push({ value: "Eventos enviados a OTM y MSC", to: "/historico_KPI", icon: "file-text", LinkComponent: withRouter(NavLink), useExact: true });
            } break;
            case "indisciplina_operativa": {
              dashboardSubItems.push({ value: "Indisciplinas operativas", to: "/indisciplinas operativas", icon: "file-text", LinkComponent: withRouter(NavLink), useExact: true });
            } break;
            case "tableroRetransmisiones": { consultaSubItems.push({ value: "Tablero Retransmisiones", icon: "list", to: "/tableroRetransmisiones", LinkComponent: withRouter(NavLink) }) }
              break;
            case "gerenciador_log": { consultaSubItems.push({ value: "Gerenciador Log", icon: "list", to: "/gerenciador_log", LinkComponent: withRouter(NavLink) }) }
              break;



            default:
              break;
          }

        });
        if (dashboardSubItems.length) {
          navBarItems.push({ value: "Dashboards", icon: "bar-chart-2", subItems: dashboardSubItems });
          for (let item of dashboardSubItems) {
            if (item.value === "OTD Movimientos Activos por Transportista") { redirect = "/otd_movimiento_activos_por_transportista"; break; }
            if (item.value === "Mov por Transportista Semanal") { redirect = "/reportes_por_trasportista_semanal"; break; }
          }
        }
        if (modulosMonitoreoSubItems.length) {
          navBarItems.push({ value: "Monitoreo", icon: "cast", subItems: modulosMonitoreoSubItems });
        }
        if (consultaSubItems.length) {
          navBarItems.push({ value: "Consultas", icon: "book-open", subItems: consultaSubItems });
        }
        /**
         * Cuando no se tiene acceso a dashboard, administracion, redirigira a monitoreo , consola naviera, consola terrestre, asignar gps, recuperar gps, unidades sin ruta, skycold, en ese orden
         */
        let redirect = "";
        if (configuracionSubItems.length) {
          navBarItems.push({ value: "Configuración", icon: "settings", subItems: configuracionSubItems });
        }
        for (let item of configuracionSubItems) {
          if (item.value === "Monitoreo") { redirect = "/monitoreo"; break; }
          if (item.value === "Usuario Consola Navieros") { redirect = "/usuarioConsola"; break; }
          if (item.value === "Usuario Consola Terrestres") { redirect = "/usuarioTerrestre"; break; }
          if (item.value === "Asignar GPS") { redirect = "/movimientoGps"; break; }
          if (item.value === "Recuperar GPS") { redirect = "/recuperarGps"; break; }
          if (item.value === "Unidades Sin Ruta") { redirect = "/unidadessinruta"; break; }
          if (item.value === "Sky Cold") { redirect = "/skycold"; break; }
          if (item.value === "Tablero Retransmisiones") { redirect = "/tableroRetransmisiones"; break; }
        }
        for (let item of modulosMonitoreoSubItems) {
          redirect= item.to;
          if (item.value === "Monitoreo Activos") { redirect = "/monitoreo?q=activos"; break; }
          //if (item.value === "Monitoreo Finalizados") { redirect = "/monitoreo?q=finalizados"; break; }
          //if (item.value === "Movmientos en delay") { redirect = "/monitoreo?q=delay"; }
        }
        sessionStorage.setItem("navBarItems", JSON.stringify(navBarItems));

        if (dashboardSubItems.length) {
          window.location.href = redirect;
        } else {
          if (Administracion) {
            window.location.href = "/adminstracion";
          } else {
            if (modulosMonitoreoSubItems.length) {
              window.location.href = redirect;
            } else {
              if (configuracionSubItems.length) {
                window.location.href = redirect;
              } else {
                window.location.href = "/login";
              }
            }
          }
        }
      });

    }
  }
  screenWidth = () => {
    //console.log("screen with: "+ window.screen.width)
    if (window.screen.width > 900) {
      return 4;
    } else {
      return 0;
    }
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.getLogin();
    }
  }
  render() {
    const {

      values,
      strings = {},

    } = this.props;
    return (
      <Grid.Col sm={12} lg={4} offset={this.screenWidth()} alignItems="center">
        <div className="p-4"></div>
        <Card>
          <Card.Body>
            <div className="col-12 text-center">
              <img src="images/official_dhllogo.png" width="100" height="50" />
            </div>
            <div className="col-12 text-center" hidden={this.state.loginMessage}>
              {strings.title || defaultStrings.title}
            </div>
            <div>
              { this.state.authStatus !== 0?
                <Alert type={alertasConf[this.state.authStatus-1].type} icon={alertasConf[this.state.authStatus-1].icon}>
                  {this.state.message}
                </Alert>
              :null}
            </div>

            <div hidden={!this.state.loginMessage || !this.state.authStatus === 3} style={{ height: "40vh" }}>
              <h1 className="mt-4">{this.state.loginMessage}</h1>
              <Dimmer active={true} loader>
              </Dimmer>
              {this.state.loading?<Dimmer active={true} loader> </Dimmer>: null}
            </div>
            <div className="mt-5" style={{ height: this.state.loading? "8vh": "0vh" }}>
              {this.state.loading?<Dimmer active={true} loader> </Dimmer>: null}
            </div>

            <div hidden={this.state.loginMessage}>
              { !getPrivilegio(sessionStorage.getItem("tok"), "PRE_VERIFICATION_USER")?
                <FormTextInput
                  name="username"
                  label={defaultStrings.usernameLabel}
                  placeholder={defaultStrings.usernamePlaceholder}
                  onChange={this.changeUsername}
                  //onBlur={onBlur}
                  value={this.state.username}
                //error={errors && errors.email}
                />: null }
              { !getPrivilegio(sessionStorage.getItem("tok"), "PRE_VERIFICATION_USER")?
                <FormTextInput
                  name="password"
                  type="password"
                  label={strings.passwordLabel || defaultStrings.passwordLabel}
                  placeholder={strings.passwordPlaceholder || defaultStrings.passwordPlaceholder}
                  onKeyDown={this._handleKeyDown}
                  onChange={this.changePassword}
                  //onBlur={onBlur}
                  value={this.state.password}
                //error={errors && errors.password}
                />: null }
              { getPrivilegio(sessionStorage.getItem("tok"), "PRE_VERIFICATION_USER")?
                <FormTextInput
                  name="code"
                  type="password"
                  label={"Codigo De verificación"}
                  placeholder={"000000"}
                  onKeyDown={this._handleKeyDown}
                  onChange={this.changeCode}
                  //onBlur={onBlur}
                  value={this.state.code}
                //error={errors && errors.password}
                />: null }              
              <div className="col-6 text-right  float-right">
                <Button target="_blank" size="sm" RootComponent="a" color="primary"
                  disabled={
                    (getPrivilegio(sessionStorage.getItem("tok"), "PRE_VERIFICATION_USER") && !this.state.code.length)
                    || this.state.loading}
                  onClick={() => this.getLogin()} >
                  <span className="badge"><Icon link={true} name="user" />Login</span>
                </Button>
              </div>
              <div className="col-6 text-right float-right" hidden={!getPrivilegio(sessionStorage.getItem("tok"), "PRE_VERIFICATION_USER")}>
                <Button target="_blank" size="sm" RootComponent="a" color="primary"
                  disabled={getPrivilegio(sessionStorage.getItem("tok"), "PRE_VERIFICATION_USER") && this.state.code.length}
                  onClick={() => this.getLogin()} >
                  <span className="badge"><Icon link={true} name="user" />Reenviar código</span>
                </Button>
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <a href="/recuperar_password">Recuperar contraseña</a>
          </Card.Footer>
        </Card>
      </Grid.Col>
    );
  }
}

export default LoginPage;
