import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Icon } from "tabler-react";
import "react-tabs/style/react-tabs.css";

class TabsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsPanelindex: this.props.selectedTab,
      indexPoint: 0,
    };
    this.newTab = this.newTab.bind(this);
    this.addTab = [];
    this.addTabPanel = [];
    this.indice = 0;
  }
  changeTab = (index) => {
    this.setState({ tabsPanelindex: index });
    this.props.selectTab(index);
  };
  changeindexpoint = (indi) => {
    this.setState({ indexPoint: indi });
  };
  newTab = (title, icon, panel, close) => {
    let posision = this.indice; //this.state.indexPoint;
    this.addTab.push(
      <Tab forceRenderTabPanel onClick={() => this.changeTab(posision)}>
        {" "}
        <Icon name={icon} />
        {title}
        {close ? (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.removeTab(posision)}
          >
            X
          </button>
        ) : null}
      </Tab>
    );
    this.addTabPanel.push(
      <TabPanel>{panel /* Contenido del panel*/}</TabPanel>
    );
    this.setState({ indexPoint: this.state.indexPoint + 1 });
    this.indice++;
  };

  removeTab = (inde) => {
    this.changeindexpoint(inde);
    alert(this.state.indexPoint);
    var TabseconPart = this.addTab.splice(inde + 1, this.addTab.length - inde);
    this.addTab.pop();
    this.addTabPanel.pop();
    for (let item of TabseconPart) {
      this.newTab();
    }
  };
  componentDidMount = () => {
    if (this.props.tabs) {
      this.props.tabs.map((elemento, index) => {
        this.newTab(
          elemento.title,
          elemento.icon,
          elemento.panel,
          elemento.close
        );
      });
    }
  };
  render() {
    return (
      <div>
        <div>
          <Tabs forceRenderTabPanel selectedIndex={this.state.tabsPanelindex}>
            <TabList>{this.addTab}</TabList>
            {this.addTabPanel}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default TabsLayout;
