import React, { useState, useEffect, useContext } from "react";
import SiteWrapper from "../../SiteWrapper.react";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import Select from "react-select";
import { Doughnut, Bar, Polar, Pie, Line } from 'react-chartjs-2';
import Chart from "react-apexcharts";
import { confirmAlert } from "react-confirm-alert";
import {timeToDateFill} from '../../herramientas/DateFormat';
import { TablePageable } from "../../Helpers";

const DashBoardGpsYearPage =()=> {  
  const [gpsList, setGpsList] = useState({years: {}, meses: {}});
  const [year, setYear] = useState({ value: 0, label: "Todos", data: null });
  const [years, setYears] = useState([{ value: 0, label: "Todos", data: null }]);
  const [mes, setMes] = useState({ value: 0, label: "Todos", data: null });
  const [meses, setmeses] = useState([
      {value: 0, label: "Todos"},
      {value: 1, label: "Enero"},
      {value: 2, label: "Febrero"},
      {value: 3, label: "Marzo"},
      {value: 4, label: "Abril"},
      {value: 5, label: "Mayo"},
      {value: 6, label: "Junio"},
      {value: 7, label: "Julio"},
      {value: 8, label: "Agosto"},
      {value: 9, label: "Septiembre"},
      {value: 10, label: "Octubre"},
      {value: 11, label: "Noviembre"},
      {value: 12, label: "Diciembre"}
  ]);
  
  useEffect(() => {
		getfuntion("get", "", "", "gpsFijos", "gpsFijos");
    //DashBoardGps_year_year
    const dataYear = sessionStorage.getItem('DashBoardGps_year_year');
    if(dataYear){
      for(let y of years){        
        if( y.value === JSON.parse(dataYear).value )
          setYear(y);
      }
    }
	}, []);
  const getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "nada", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "gpsFijos"){
                let gpsYear= {years: {}, meses: {}};
                for(let g of returnVal){//console.log(g.id);
                  const yearTemp= new Date(g.altaFechaGg).getFullYear() === 1969? "Sin fecha": new Date(g.altaFechaGg).getFullYear();
                  const mesTemp= new Date(g.altaFechaGg).getMonth();

                  if(!gpsYear["years"][yearTemp])
                    gpsYear["years"][yearTemp]= [];
                  gpsYear["years"][yearTemp].push(g);

                  //Meses
                  if(!gpsYear["meses"][yearTemp])
                    gpsYear["meses"][yearTemp]= {};

                  if(!gpsYear["meses"][yearTemp][mesTemp])
                    gpsYear["meses"][yearTemp][mesTemp]= [];

                  gpsYear["meses"][yearTemp][mesTemp].push(g);
                }
                let yearsTemp= [{value: 0, label : "Todos"}];
                Object.keys(gpsList["years"]).forEach((y, indice) => {
                  yearsTemp.push({value: y, label : y});  
                });
                console.log(gpsYear);
                setYears(yearsTemp);
                setGpsList(gpsYear);
              }
            }
            break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){ console.log(err.response); }else{console.log("Error desconocido .... "+ err); }
    })
  }
  //new Date().getWeekNumber()
  const random_rgba =(opacidad= 1)=> {
    var o = Math.round, r = Math.random, s = 255;
    //return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ','+opacidad+ ')';
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ','+opacidad+ ')';
  }
  const getGraficaGpsYear =()=>{
    let labels= [];
    let data= [];
    let backgroundColor=[];
    let hoverBackgroundColor=[];
    Object.keys(gpsList["years"]).forEach((y, indice) => {
        labels.push(y);
        data.push(gpsList["years"][y].length);  
        backgroundColor.push(random_rgba(0.5));
        hoverBackgroundColor.push(random_rgba());
    });
    //console.log(random_rgba())
    return { labels, data };
  }
  const getGraficaGpsYearMes =(yearTemp)=>{
    const mesesString=
        [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre" ];
    let labels= [];
    let data= [];
    if( !yearTemp )
      return [];
    
    let backgroundColor=[];
    let hoverBackgroundColor=[];
    Object.keys(gpsList["meses"][yearTemp]).forEach((m, indice) => {
        labels.push(mesesString[m]);
        data.push(gpsList["meses"][yearTemp][m].length);  
        backgroundColor.push(random_rgba(0.5));
        hoverBackgroundColor.push(random_rgba());
    });
    //console.log(random_rgba())
    return { labels, data };
  }
  const getGps =()=>{
    let gpsListTemp= [];
    if( year.value){
      gpsListTemp= gpsList["years"][year.value];
    }
      
    if( mes.value ){
      gpsListTemp= gpsList["meses"][year.value][mes.value];
    }
      return gpsListTemp;
  }
  const getMesString=(mesString)=>{
    const mesesString=
        [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre" ];
    for(let i=0; i< mesesString.length; i++){
      if( mesString === mesesString[i] )
       return i
    }
    return 0;
  }
  return (
    <SiteWrapper>
      <div className="container-fluid" style={{ height: "90vh", "font-size": "smaller"}}>{/** width: "100%",  */}
        <br/>
        <br/>
        <div className="row">
          <div className={"col"+(!year.value? "-12": "-6")}>
            <div className="card border-primary mb-3">
              <div className="card-header" style={{backgroundColor: "rgba(57, 121, 249, 0.30)"}}>
                <h4>
                  Altas de Gps por Año
                </h4>
              </div>
              <div className="card-body text-success">
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function(event, chartContext, config) {
                          setMes({ value: 0, label: "Todos", data: null });
                          setYear({value: getGraficaGpsYear().labels[config.dataPointIndex], label: getGraficaGpsYear().labels[config.dataPointIndex] });
                        }
                      }
                    },
                    xaxis: {
                      categories: getGraficaGpsYear().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Gps", data: getGraficaGpsYear().data},
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={300}
                />
              </div>
            </div>
          </div>
          <div className="col-6" hidden={ !year.value }>
            <div className="card border-primary mb-3">
              <div className="card-header" style={{backgroundColor: "rgba(57, 121, 249, 0.30)"}}>
                <h4>
                  Altas de Gps por mes del año {" "+year.label}
                </h4>
              </div>
              <div className="card-body text-success">
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function(event, chartContext, config) {
                          setMes({
                            value: getMesString(getGraficaGpsYearMes(year.value).labels[config.dataPointIndex]),
                            label: getGraficaGpsYearMes(year.value) .labels[config.dataPointIndex] });
                        }
                      }
                    },
                    xaxis: {
                      categories: getGraficaGpsYearMes(year.value).labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Gps", data: getGraficaGpsYearMes(year.value).data},
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={300}
                />
              </div>
            </div>
          </div>
        </div>
        <br/>
        <hr/>
        <div className="row" hidden={!year.value && !mes.value}>
          <div className="col-12">
          <TablePageable
            titulo={ <h3> Listado de gps </h3> }
            lista= { getGps()? getGps(): [] }
            columnas={
              [
                { columna: "id", label: "Id" },
                { columna: "nombre", label: "Nombre" },
                { columna: "marca", label: "Marca" },
                { columna: "imei", label: "Imei" },
                { columna: "altaFechaGg", label: "Fecha" },
                { columna: "gpsHardware.nombre", label: "Hardware" },                
              ]
             }
            id= "id"
            selected={()=>{}}
            csvname= "Gps Fijos"
            //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
            //resaltarColor={"rgba(223, 58, 58, 0.47)"}
          />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
}

export default DashBoardGpsYearPage;
