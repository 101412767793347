// @flow
import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";

class DestinosForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Llava Primaria
      id: "",
      idPlataforma: 0,

      //Campos
      nombre: "",
      direccion: "",
      latitud: 0,
      longitud: 0,
      estatus: 0,
      cveDestinoDhl: "",
      dominio: "",
      //Variables
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      IdPlataforma: { value: "id", label: "Selecciona una Plataforma " },
      crudbutonEnable: true, // Se desabilita cuando se hace click
    };
   
    this.DestinosObj = {
      "id": this.props.elementos.id,
      "idPlataforma": this.props.elementos.idPlataforma,
      "nombre": this.props.elementos.nombre,
      "latitud": this.props.elementos.latitud,
      "longitud": this.props.elementos.longitud,
      "estatus": this.props.elementos.estatus,
      "cveDestinoDhl": this.props.elementos.cveDestinoDhl,
      "dominio": this.props.elementos.dominio,
    };
    this.enableSave = this.enableSave.bind(this);
    this.IdPlataforma = [];
  }
  isEnable = (Accion) => {
    let borrar = "destinos_borrar";
    let actualizar = "destinos_editar";
    let agregar = "destinos_agregar";
    var privilegios = this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) { if (perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0) { return true } }
      }
      if (Accion === "actualizar") { if (privilegios[i].clave == actualizar) { if (perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0) { return true } } }
      if (Accion === "agregar") { if (privilegios[i].clave == agregar) { if (perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0) { return true } } }
    }
    return false
  }
  componentDidMount = () => {
    if (this.state.accion === 'nuevo') {
    } else {
      this.setState({ id: this.props.elementos.id });
     
      this.setState({ idPlataforma: this.props.elementos.idPlataforma });
      
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ direccion: this.props.elementos.direccion });
      this.setState({ latitud: this.props.elementos.latitud });
      this.setState({ longitud: this.props.elementos.longitud });
      this.setState({ estatus: this.props.elementos.estatus });
      this.setState({ cveDestinoDhl: this.props.elementos.cveDestinoDhl });
      this.setState({ dominio: this.props.elementos.dominio });
    }
    this.props.stor.plataformas_object.map((elemento, index) => {
      this.IdPlataforma.push({ value: elemento.id, label: elemento.nombre });
    });
    for (let item of this.props.stor.plataformas_object) {
      //console.log("item: " + item.id + " target: " + event.value);
      if (item.id === this.props.elementos.idPlataforma) {
        this.setState({ IdPlataforma: { value: this.props.elementos.idPlataforma, label: item.nombre } });
        break;
      }
    }

  }

  enableSave = () => {
    
    if (this.state.nombre.length > 0 && this.state.idPlataforma > 0) {
      
      return true;
    } else { return false }
  }

  changeName = (event) => {
    this.setState({ nombre: event.target.value });
    //this.DestinosObj.nombre = event.target.value;
    this.DestinosObj.nombre = event.target.value;
  }
  changeDireccion = (event) => {
    this.setState({ direccion: event.target.value });
    this.DestinosObj.direccion = event.target.value;
  }
  changeLatitud = (event) => {
    this.setState({ latitud: event.target.value });
    this.DestinosObj.latitud = event.target.value;
  }
  changeLongitud = (event) => {
    this.setState({ longitud: event.target.value });
    this.DestinosObj.longitud = event.target.value;
  }
  //changeEstatus =(event) =>{
  //  this.setState({estatus: event.target.value});
  //  this.DestinosObj.estatus = event.target.value;
  //}
  changeEstatus = (event) => {
    this.state.estatus === 0 ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });
    this.state.estatus === 0 ? this.DestinosObj.estatus = 1 : this.DestinosObj.estatus = 0;
  }
  changeCveDestinoDhl = (event) => {
    this.setState({ cveDestinoDhl: event.target.value });
    this.DestinosObj.cveDestinoDhl = event.target.value;
  }
  changeDominio = (event) => {
    this.setState({ dominio: event.target.value });
    this.DestinosObj.dominio = event.target.value;
  }
  changeIdPlataforma = (event) => {
    // console.log("event");
    // console.log(event);
    this.setState({ idPlataforma: event.value });
    //if(event.value > 0){ this.valid.AlertasTipo= true }else{ this.valid.AlertasTipo= false }
    this.DestinosObj.idPlataforma = event.value;
   
    this.setState({ IdBloques: { value: event.value, label: event.label } });
    for (let item of this.props.stor.plataformas_object) {
      // console.log("item: " + item.id + " target: " + event.value);
      if (item.id === event.value) {
        this.setState({ IdPlataforma: { value: event.value, label: event.label } });
        break;
      }
    }
  }

  delete() { this.setState({ crudbutonEnable: false }); this.props.getfuntion("delete", "", this.state.id); }
  crud_put() { this.setState({ crudbutonEnable: false }); this.props.getfuntion("put", this.DestinosObj, "", "destinos", "destinos"); }
  crud_post() { this.setState({ crudbutonEnable: false }); this.props.getfuntion("post", this.DestinosObj, "", "destinos", "destinos"); }
  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? <h1>{this.props.elementos.nombre}</h1> : null}
        
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="id">
                  <Form.Input name="id" disabled type="number" value={this.state.id} />
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={9} lg={9}>
                <Form.Group label="Nombre">
                  <Form.Input name="nombre" type="text" value={this.state.nombre} onChange={this.changeName} maxlength="30" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Clave destino DHL">
                  <Form.Input name="cveDestinoDhl" type="text" value={this.state.cveDestinoDhl} onChange={this.changeCveDestinoDhl} maxlength="30" />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Dominio DHL">
                  <Form.Input name="dominio" type="text" value={this.state.dominio} onChange={this.changeDominio} maxlength="30" />
                </Form.Group>
              </Grid.Col>
              
            </Grid.Row>

            <Grid.Row>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Latitud">
                  <Form.Input name="latitud" type="number" value={this.state.latitud} onChange={this.changeLatitud} maxlength="20" />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Longitud">
                  <Form.Input name="longitud" type="number" value={this.state.longitud} onChange={this.changeLongitud} maxlength="20" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>

              <Grid.Col md={9} lg={9}>
                <Form.Group label="Plataforma" >
                  <Select value={this.state.IdPlataforma} onChange={this.changeIdPlataforma} options={this.IdPlataforma} />
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={3} lg={3}>
                <Form.Group label="Estatus">
                  <Form.Switch type="Checkbox" name="toggle" value={this.state.estatus} checked={this.state.estatus} onChange={this.changeEstatus} />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col md={12} lg={12}>
                <Form.Group label="Direccion">
                  <Form.Input name="direccion" type="text" value={this.state.direccion} onChange={this.changeDireccion} />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={!this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                <span className="badge"><Icon link={true} name="save" />Guardar</span>
              </Button>
            </span>
            : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()} disabled={!this.state.crudbutonEnable}>
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
            :
            null
          }
        </div>
      </div>
    );
  }
}
export default DestinosForm;
