
import React, { Component } from 'react';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { Dimmer, Button, Icon, Form } from "tabler-react";
import { timeToDateFill } from "../../../herramientas/DateFormat";
import {NotificationContainer, NotificationManager} from 'react-notifications';
var     id	=0;
var terrores=0;
let sinError=0;
var stringValid="";
var contadorDeCarga= 0;
var currentClietePage= 0;
var contadorVincular= 0;
class ImportarContactos extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
                  accion:this.props.accion,
                validado:this.props.validado                |false,
                    data:[], // datos nuevos
         descargandoClientes: 0,
         cargarDatosNuevos: false,
         datosValidos:[], // datos validos nuevos
         datosNoValidos: [],  // Datos no validos nuevos
         errores:[],
         contadorDeCarga: 0,
contadorDescargaClientes: 0,
            porVincular: [],
            contadorVincular: 0
    };
    this.plataformasClientes= []; //Clientes de la plataforma
    this.clientes=[]; //Catalogo de clientes
    this.data=[]; // archivo de excel
    this.datosValidos=[]; // datos nuevos validos
    this.datosNoValidos=[]; //datos nuevos no validos
    this.porVincular= [];
  }
  componentWillMount(){
  }

  onImportExcel = file => {
		//this.state.data= [];
    const { files } = file.target;
    console.log("fileName");
    console.log(files[0].name);

    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            console.log("sheet: " + sheet);
            //this.setState({catalogo: sheet });//this.props.data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
            this.data= XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
          }
        }
        //console.log(this.state.data);
        console.log('Carga de Archivo Exitosa！');
        id=0;
        terrores=0;
				sinError=0;
      } catch (e) {
        console.log("Error! "+e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
    this.openModal();
    //cargar plataformasClientes
    this.getfuntion("get", "", "", "plataformasClientes/plataforma/"+this.props.idPlataforma, "plataformasClientes");
    this.setState({descargandoClientes : 1 });
}

cargarClientes=()=>{
  this.getfuntion("get", "", "", "cliente/pagina/"+currentClietePage+"/total/1000", "clientes" );
}

openModal = () => {
  //this.props.Fclose();
  this.setState({modalIsOpen: true});
}
closeModal = () => {
  this.setState({modalIsOpen: false});
}
handleModalCloseRequest = () => {
  this.setState({modalIsOpen: false});
}
handleSaveClicked = (e) => {
  alert('Save button was clicked');
}
validarRepetidos=(nombre)=>{
  let clienteTemp= this.clientes.filter(word => word.nombre ? word.nombre.toUpperCase().includes( nombre.toUpperCase() ): false );
  if(clienteTemp[0]){
    if(this.plataformasClientes.filter(word => word.idCliente === clienteTemp[0].id).length === 0 ){
      this.porVincular.push({idPlataforma: this.props.idPlataforma, idCliente: clienteTemp[0].id})
    }else{
    }
  }
  return clienteTemp.length;
}

getfuntion = (metodo = "get", obj = [], id = "", catalogo = "cliente", stateVar = "clientes", hiddenModl = "") => {
  Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
    /**
     * filtro de variables
     */
    switch (metodo) {
      case "get":
        {
          if (stateVar === "clientes") {
            if(returnVal){
              if( returnVal.totalPages ){
                currentClietePage++;
                if(currentClietePage < returnVal.totalPages){
                  this.clientes= this.clientes.concat(returnVal.content);
                  this.cargarClientes();
                }else{
                  this.clientes= this.clientes.concat(returnVal.content);
                  this.setState({descargandoClientes : 2 });
                  let coincidencias=[];

                  for(let item of this.data){//this.data es el excel
                    let contador= this.validarRepetidos(item.nombre);
                    if(contador === 0 ){
                      if( item.fecha){   item.fecha= timeToDateFill( new Date((item.fecha - (25567 + 2))*86400*1000).getTime()+(6*60*60*1000) ); }
                      coincidencias.push({ data: item, repetidos: contador });
                    }else{
                    }
                  }

                  console.log(coincidencias);
                  this.setState({data: coincidencias}); //this.state.data son los datos nuevos
                  currentClietePage= 0;
                  contadorVincular= 0;
                  this.setState({porVincular: this.porVincular});
                }
              }
            }
          }
          if (stateVar === "plataformasClientes") {
            if(returnVal){
              currentClietePage= 0;
              this.clientes= [];
              this.plataformasClientes= returnVal;
              this.cargarClientes();
              /*
              let clientesBinculados= returnVal;
              let clientesABincular=[];
              for(let item of this.clientes){
                let vinculado= false;
                let cliente={};
                for(let vincul of clientesBinculados){
                  if(vincul.idCliente == item.id){
                    vinculado= true;
                  }
                }
                if(!vinculado){
                  clientesABincular.push({idPlataforma: this.props.idPlataforma, idCliente: item.id});
                }
              }
              console.log("clientesABincular");
              console.log(clientesABincular);
              */
            }
          }
        }
        break;
      case "post":
        {
          if (stateVar === "clientes") {
            this.getfuntion("post", { "idPlataforma": this.props.idPlataforma, "idCliente": returnVal.id }, "", "plataformasClientes", "plataformasClientes");
          }
          if (stateVar === "plataformasClientesVincular") {
            contadorVincular++;
            this.setState({contadorVincular: contadorVincular});
            if(this.porVincular[contadorVincular]){ // this.datosValidos[contadorDeCarga]
              setTimeout( ()=> this.vincular(),  200);
            }else{
              contadorVincular=0;
            }
          }
          if (stateVar === "plataformasClientes") {
            contadorDeCarga++;
            this.setState({contadorDeCarga: contadorDeCarga});
            if(this.datosValidos[contadorDeCarga]){ // this.datosValidos[contadorDeCarga]
              setTimeout( ()=> this.cargarDatosCorrectos(),  200);
            }else{
              contadorDeCarga=0;
            }
          }
        }
        break;
      case "put":
        {
          if (stateVar === "clientes") {
          }
        }
        break;
      default:
        break;
    }
  }).catch(err => {
    if (stateVar === "clientes") {
      currentClietePage--;
      alert("hubo un error refresque la pantalla y vualva a cargar  el el archivo, solo se cargaran los datos faltantes")
      //this.cargarClientes();
    }
    console.log(err)
  });
}
typeAndLength=(campo, valor, tipo, longitud)=>{
  if(valor || valor>=0 ){
    if(typeof valor == tipo){ // tipo de dato correcto
      if(tipo !== "number"){
        if(valor.length <= longitud){
          return true;
        }else{//excede longitud
          stringValid= stringValid.length ==0? stringValid+"campo: " +campo+"- valor: "+valor+", excede longitud("+valor.length+" > "+longitud+")" : stringValid+", "+"campo: " +campo+"- valor: "+valor+", excede longitud("+valor.length+" > "+longitud+")";
          console.log(campo+": valor => "+valor);
        return false
        }
      }else{
        return true;
      }
    }else{ //tipo de dato incorrecto
      stringValid= stringValid.length ==0? stringValid+"campo: " +campo+"- valor: "+valor+", tipo de dato incorrecto" : stringValid+", "+"campo: " +campo+"- valor: "+valor+", tipo de dato incorrecto";
      console.log(campo+": valor => "+valor);
      return false
    }
  }else{
    console.log(campo+": valor => "+valor);
    switch (campo) {
      case "nombre":    { stringValid= stringValid.length ==0? stringValid+"campo valor:"+"campo: " +campo+"- valor: "+valor+", Es requerido" : stringValid+", "+"campo: " +campo+"- valor: "+valor+", Es requerido"; return false; }
      case "domicilio": { stringValid= stringValid.length ==0? stringValid+"campo: " +campo+"- valor: "+valor+", Es requerido" : stringValid+", "+"campo: " +campo+"- valor: "+valor+", Es requerido"; return false; }
      case "vip":       { stringValid= stringValid.length ==0? stringValid+"campo: " +campo+"- valor: "+valor+", Es requerido" : stringValid+", "+"campo: " +campo+"- valor: "+valor+", Es requerido"; return false; }
      case "estatus":   { stringValid= stringValid.length ==0? stringValid+"campo: " +campo+"- valor: "+valor+", Es requerido" : stringValid+", "+"campo: " +campo+"- valor: "+valor+", Es requerido"; return false; }
      default: return true;
    }
  }
}

cargarDatosNuevos=()=>{
  this.datosValidos =[];
  this.datosNoValidos=[];
  let errores=[];

  for( let item of this.state.data ){
    let valid= true;
    stringValid="";
    let jsonCliente={
      nombre:"",         // string 250
      nombreCorto:"",    // string 250
      domicilio:"",      // string 500
      contacto: " ",     // string 50
      telefono: "",      // string 15
      extension: "",     // string 10
      nota:"",           // string 255
      vip:0 ,            // 0 o 1
      latitud: null,     // decimal
      longitud: null,    // decimal
      estatus: 1,        // 0 , 1
      idGeocerca: null,
      idPlataformaTipo: 1,
      idCampoLogistico: null,
      cron: "00:00:00",
      wt: "00:00:00",
      id:0               //integer primarykey
    }
    Object.keys(item.data).forEach((key, indice) =>{
      switch (key) {
        case "nombre":{           jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "string", 250):false;}
          break;
        case "nombreCorto":{      jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "string", 250):false;}
          break;
        case "domicilio":{        jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "string", 500):false;}
          break;
        case "contacto":{         jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "string", 50):false;}
          break;
        case "telefono":{         jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "number", 15):false;}
          break;
        case "extension":{        jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "number", 10):false;}
          break;
        case "nota":{             jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "string", 255):false;}
          break;
        case "vip":{              jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "number", 10):false;}
          break;
        case "latitud":{          jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "number", 10):false;}
          break;
        case "longitud":{         jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "number", 10):false;}
          break;
        case "estatus":{          jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "number", 10):false;}
          break;
        case "idPlataformaTipo":{ jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "number", 1):false;}
          break;
        case "idCampoLogistico":{ jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "string", 2):false;}
          break;
        case "cron":{             jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "string", 8):false;}
        break;
        case "wt":{             jsonCliente[key]= item.data[key]; valid= valid? this.typeAndLength(key, item.data[key], "string", 8):false;}
          break;
        default:
          break;
      }
    });
    if(valid){
      this.datosValidos.push(jsonCliente);
    }else{
      this.datosNoValidos.push(jsonCliente);
      errores.push({error: stringValid});
      console.log(console.log("datos no validos==> "+ stringValid));
      console.log(jsonCliente);
    }
  }
  contadorDeCarga=0;
  this.setState({datosValidos: this.datosValidos});
  this.setState({datosNoValidos: this.datosNoValidos});
  this.setState({errores: errores});
  this.setState({ cargarDatosNuevos: true });
}
cargarDatosCorrectos=()=>{
  console.log(new Date()+" guardando: "+contadorDeCarga)
  if(this.datosValidos[contadorDeCarga]){ // this.datosValidos[contadorDeCarga]
    this.getfuntion("post", this.datosValidos[contadorDeCarga], "", "cliente", "clientes");
  }else{
    contadorDeCarga=0;
  }
}
vincular=()=>{
  console.log(new Date()+" vinculando: "+contadorVincular)
  if(this.porVincular[contadorVincular]){
    this.getfuntion("post", this.porVincular[contadorVincular], "", "plataformasClientes", "plataformasClientesVincular");
  }else{
    contadorDeCarga=0;
  }

}
contadorVincular
render() {
    return (
      <>
      <Button target="_blank" size="sm" color="primary" icon={"file-text"} className="text-white" onClick={()=> this.openModal()}>
      </Button>
      <Modal
          closeTimeoutMS={50}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.handleModalCloseRequest}
          className="Modal"
          overlayClassName="Overlay"
          style = {this.customStyles}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title"> Clientes </h4>
              <p>El tiempo de carga y validacion dependera de su conexión a internet y las caracteristicas de hardware y software de su equipo</p>
              <button type="button" className="btn btn-dark fa fa-close outline pill " onClick={this.handleModalCloseRequest}></button>
            </div>
            <div className="modal-body">
              {this.state.data.length >0?null:
              <Button target="_blank" size="sm" color="primary" icon={"file-text"} className="text-white">
                <input type='file' id="file" className="file" accept='.xlsx, .xls' onChange={this.onImportExcel} ></input>
              </Button>}
              { this.state.data.length>0 && this.state.descargandoClientes == 1?
                  <div>Validando Datos <Dimmer active={true} loader></Dimmer></div>
                : this.state.descargandoClientes == 2 ?
                    <div className="row">
                      <div className="col">
                        { this.state.data.length == 0 ?
                            <h3>No hay datos nuevos ..({this.state.porVincular.length>0 ?this.state.porVincular.length+" por vincular )": null } </h3>
                          :
                            <h3>Finalizado, se listan los datos nuevos ({this.state.porVincular.length>0 ?this.state.porVincular.length+" por vincular )":null}</h3>
                        }
                      </div>
                      <div className="col">
                        <Form.Group label="Cargar datos nuevos">
                          <Button size="sm" color="primary" icon={"save"} className="text-white" onClick={()=> this.cargarDatosNuevos() } disabled={ this.state.data.length ===  0}></Button>
                        </Form.Group>
                      </div>
                      <div className="col">
                        <Form.Group label="Actualizar datos">
                          <Button size="sm" color="primary" icon={"refresh-ccw"} className="text-white" onClick={()=> alert("Se validara datos a subir") }></Button>
                        </Form.Group>
                      </div>
                    </div>
                  :this.state.descargandoClientes == 2 ? <div>Finalizado, No hay datos nuevos</div>: null
              }

              <div hidden={this.state.cargarDatosNuevos}>
                { this.state.data.length>0  && this.state.descargandoClientes == 2?
                  this.state.data.map((contacto, indice)=>
                    <div className="row mt-4">
                      <div className="col-6">
                        {contacto.data? contacto.data.nombre: "... " }
                      </div>
                      <div className="col-3">
                        { contacto.repetidos>=0? contacto.repetidos+" "+ "coincidencias":"calculando .. "}
                      </div>
                      <div className="col-3">
                        { contacto.data? contacto.data.fecha: "0000-00-00 00:00:00"  }
                      </div>
                    </div>
                  ):<div>{ this.state.descargandoClientes == 1 ?"Cargando..": !this.state.descargandoClientes ?"No se ha seleccionado catalogo":null}</div>}
              </div>
              {/** vincular movimientos */}

              <div className="row mt-4" hidden={!this.state.porVincular.length}>
                  <div className="col-10">
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{"width": ((100/this.state.porVincular.length)* this.state.contadorVincular)+"%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{Math.round((100/this.state.porVincular.length)* this.state.contadorVincular)+"%"}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    <Button size="sm" color="primary" icon={"save"} className="text-white" onClick={()=> this.vincular() } disabled={ this.state.porVincular.length ===  0 || this.state.contadorVincular > 0 }>Vincular clientes </Button>
                  </div>
                </div>
              {/**Mostrar datos antes de subir, datos correctos, datos incorrectos */}
              <div hidden={!this.state.cargarDatosNuevos}>
                <div className="row">
                  <div className="col">Datos correctos: { this.state.datosValidos.length}</div>
                  <div className="col">Datos con errores: {this.state.datosNoValidos.length} </div>
                </div>
                <h4>aqui se listaran los datos correctos</h4>
                <div className="row mt-4">
                  <div className="col-10">
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{"width": ((100/this.state.datosValidos.length)* this.state.contadorDeCarga)+"%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{Math.round((100/this.state.datosValidos.length)* this.state.contadorDeCarga)+"%"}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    <Button size="sm" color="primary" icon={"save"} className="text-white" onClick={()=> this.cargarDatosCorrectos() } disabled={ this.state.data.length === 0 || this.state.contadorDeCarga > 0}>Guardar datos validos </Button>
                  </div>
                </div>
                      <div className="row mt-4">
                        <div className="col-1"> nombre </div>
                        <div className="col-1"> nombreCorto </div>
                        <div className="col-1"> domicilio </div>
                        <div className="col-1"> contacto </div>
                        <div className="col-1"> telefono </div>
                        <div className="col-1"> extension </div>
                        <div className="col-1"> nota </div>
                        <div className="col-1"> vip </div>
                        <div className="col-1"> latitud </div>
                        <div className="col-1"> longitud </div>
                        <div className="col-1"> estatus </div>
                        <div className="col-1"> id </div>
                      </div>
                  { this.state.datosValidos.length > 0?
                    this.state.datosValidos.map((elemento, index)=>
                      <div className="row mt-4">
                        <div className="col-1"> {elemento.nombre } </div>
                        <div className="col-1"> {elemento.nombreCorto } </div>
                        <div className="col-1"> {elemento.domicilio } </div>
                        <div className="col-1"> {elemento.contacto } </div>
                        <div className="col-1"> {elemento.telefono } </div>
                        <div className="col-1"> {elemento.extension } </div>
                        <div className="col-1"> {elemento.nota } </div>
                        <div className="col-1"> {elemento.vip } </div>
                        <div className="col-1"> {elemento.latitud } </div>
                        <div className="col-1"> {elemento.longitud } </div>
                        <div className="col-1"> {elemento.estatus } </div>
                        <div className="col-1"> {elemento.id } </div>
                      </div>
                    )
                    :null
                  }
                <h4>aqui se listaran los datos con errores</h4>
                      <div className="row mt-4">
                        <div className="col-1"> nombre </div>
                        <div className="col-1"> nombreCorto </div>
                        <div className="col-1"> domicilio </div>
                        <div className="col-1"> contacto </div>
                        <div className="col-1"> telefono </div>
                        <div className="col-1"> extension </div>
                        <div className="col-1"> nota </div>
                        <div className="col-1"> vip </div>
                        <div className="col-1"> latitud </div>
                        <div className="col-1"> longitud </div>
                        <div className="col-1"> estatus </div>
                        <div className="col-1"> error </div>
                      </div>
                  { this.state.datosNoValidos.length > 0?
                    this.state.datosNoValidos.map((elemento, index)=>
                      <div className="row mt-4">
                        <div className="col-1"> {elemento.nombre } </div>
                        <div className="col-1"> {elemento.nombreCorto } </div>
                        <div className="col-1"> {elemento.domicilio } </div>
                        <div className="col-1"> {elemento.contacto } </div>
                        <div className="col-1"> {elemento.telefono } </div>
                        <div className="col-1"> {elemento.extension } </div>
                        <div className="col-1"> {elemento.nota } </div>
                        <div className="col-1"> {elemento.vip } </div>
                        <div className="col-1"> {elemento.latitud } </div>
                        <div className="col-1"> {elemento.longitud } </div>
                        <div className="col-1"> {elemento.estatus } </div>
                        <div className="col-1"> {this.state.errores[index].error } </div>
                      </div>
                    )
                    :null
                  }
              </div>
            </div>

          </div>
        </Modal>
      </>
    );
  }
}
export default ImportarContactos;
