import React, { useState, useEffect } from 'react';
import { Doughnut, Bar, Polar, Pie } from 'react-chartjs-2';
import SiteWrapper from "../../SiteWrapper.react";
import { Grid, Button, Icon, Form, Table } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import Select from "react-select";

const EstatusViajePage = () => {

  const [resultado_api, setResultadoApi] = useState([]);
  const [chartdata, setCharData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        backgroundColor: [],
        data: [],
      },
    ],
  });
  const [estatus, setEstatus] = useState({ value: 0, label: "Todos" });
  const [cv, setCv] = useState({ value: 0, label: "Todos" });
  const [lineaTransp, setLineaTransp] = useState({ value: 0, label: "Todas" });
  const [listaLineasTransp, setListaLineasTransp] = useState([{ value: 0, label: "Todas" }])
  const [listaCvs, setListaCvs] = useState([{ value: 0, label: "Todos" }]);
  const [listaEstatus, setListaEstatus] = useState([{value:0,label:"Todos"}]);

  let ltFilt = "0";
  let cvFilt = "0";
  let estFilt = "Todos";

  useEffect(() => {
    crud("get", "", "", "graficosVisibilidad/estatusViajes/", "datos_grafica");
  }, chartdata

  );

  return (
    <SiteWrapper>
      <h2 >Estatus del Viaje</h2>
      <div className="container mt-3" style={{ width: 600 }} >
        <Pie
          data={chartdata.datasets ?
            chartdata.datasets.data ?
              chartdata.datasets.data.length ?
                chartdata.filter(r => estatus.value ? r.estatus === estatus.label : true)
                : chartdata : chartdata : chartdata
          }
          options={{
            responsive: true,
            legend: { display: true, position: "left" },
            datalabels: {
              display: true,
              color: "white",
            },
            tooltips: {
              backgroundColor: "#5a6e7f",
            },
          }}
        />
      </div>

      <div className="container mt-3">
        <Grid.Row>
          <Grid.Col>

            <Form.Group label="Seleccione una Linea de transporte :">
              <Select value={lineaTransp} options={listaLineasTransp} onChange={(e) => cambiaLinea(e)} isMulti={false} />
            </Form.Group>
            <Form.Group label="Seleccione Estatus :">
              <Select value={estatus} options={listaEstatus} onChange={(e) => cambiaEstatus(e)} isMulti={false} />
            </Form.Group>
            <Form.Group label="Seleccione un CV :">
              <Select value={cv} options={listaCvs} onChange={(e) => cambiaCV(e)} isMulti={false} />
            </Form.Group>


          </Grid.Col>
        </Grid.Row>
        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
          <Table.Header>
            <Table.ColHeader>CV</Table.ColHeader>
            <Table.ColHeader>Línea Transporte</Table.ColHeader>
            <Table.ColHeader>Estatus</Table.ColHeader>
          </Table.Header>

          <Table.Body>
            {

              resultado_api.filter(r => estatus.value ? r.estatus === estatus.label : true).filter(r => lineaTransp.value ? r.lineaTransporte === lineaTransp.label : true).filter(r => cv.value ? r.cv === cv.label : true).map((element, index) =>
                <Table.Row>
                  <Table.Col> {element["cv"]} </Table.Col>
                  <Table.Col> {element["lineaTransporte"]} </Table.Col>
                  <Table.Col> {element["estatus"]} </Table.Col>

                </Table.Row>
              )

            }

          </Table.Body>
        </Table>


      </div>

    </SiteWrapper>
  );

  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get": {
          if (stateVar === "mov_tras_x_semana" && returnVal) {
            var dat = {
              labels: [],
              datasets: [{ data: [], backgroundColor: [], hoverBackgroundColor: [], label: [] }]
            };

            returnVal.map((t, index) => {
              dat.labels.push(t.nombre);

              dat.datasets[0].data.push(t.totmovsem);
              //dat.datasets[0].label.push(corredor.nombre);
              let color = "#000000".replace(/0/g, () => (~~(Math.random() * 16)).toString(16));
              dat.datasets[0].backgroundColor.push(color);
              dat.datasets[0].hoverBackgroundColor.push(color + 90);
            });
          }

          //mros
          if (stateVar === 'datos_grafica' && returnVal) {
            let carga = 0;
            let txcm = 0;
            let txcc = 0;
            let cierre = 0;

            setResultadoApi(returnVal);

            let labels = [];
            let data = [];
            returnVal.forEach(element => {
              if (element.estatus == "Carga") {
                carga++;
              }
              if (element.estatus == "Transito por Centro de Monitoreo") {
                txcm++;
              }
              if (element.estatus == "Transito por Call Center") {
                txcc++;
              }

              if (element.estatus == "Cierre") {
                cierre++;
              }

              setListaCvs(state => [...state, { value: element.cv, label: element.cv }]);

              setListaLineasTransp((prevState) => {
                let duplicate = prevState.find((x) => x.label === element.lineaTransporte)
                if (!duplicate)
                  return [...prevState, { value: element.lineaTransporte, label: element.lineaTransporte }];
                else
                  return [...prevState];
              });
              setListaEstatus((prevState) => {
                let duplicate = prevState.find((x) => x.label === element.estatus)
                if (!duplicate)
                  return [...prevState, { value: element.estatus, label: element.estatus }];
                else
                  return [...prevState];
              });

            })


            labels = ["Carga", "Tránsito por Centro de Monitoreo", "Tránsito por Call Center", "Cierre"];
            data = [carga, txcm, txcc, cierre];
            let customLabels = labels.map((label, index) => `${label}: ${data[index]}`)
            setCharData({
              labels: customLabels,
              datasets: [
                {
                  label: "Estatus del Viaje",
                  backgroundColor: [
                    "#83ce83",
                    "#f96a5d",
                    "#6800B4",
                    "#00A6B4",

                  ],
                  data: data,
                },
              ],
            });
          }


        } break;
        default:
          break;
      }
    }).catch(err => { console.log(err); });
  }
  function cambiaLinea(e) {
    setLineaTransp(e);
    ltFilt = e.label;
    estFilt = estatus.label;
    cvFilt = cv.value;
    filtraGrafica();
  }
  function cambiaEstatus(e) {
    setEstatus(e);
    estFilt = e.label;
    ltFilt = lineaTransp.label;
    cvFilt = cv.value;
    filtraGrafica();
  }
  function cambiaCV(e) {
    setCv(e);
    cvFilt = e.value;
    estFilt = estatus.label;
    ltFilt = lineaTransp.label;
    filtraGrafica();
  }
  function filtraGrafica() {
    let carga = 0;
    let txcm = 0;
    let txcc = 0;
    let cierre = 0;
    let datosActuales = resultado_api;
    let labels = [];
    let data = [];
    setListaCvs([{ value: 0, label: "Todos" }]);
    setListaLineasTransp([{ value: 0, label: "Todas" }]);
    setListaEstatus([{ value: 0, label: "Todos" }]);
    datosActuales.forEach(element => {
      if (cvFilt == "0" || cvFilt == element.cv) {
        if (ltFilt == "Todas" || ltFilt == element.lineaTransporte) {
          if (estFilt == "Todos" || estFilt == element.estatus) {
            if (element.estatus == "Carga") {
              carga++;
            }
            if (element.estatus == "Transito por Centro de Monitoreo") {
              txcm++;
            }
            if (element.estatus == "Transito por Call Center") {
              txcc++;
            }

            if (element.estatus == "Cierre") {
              cierre++;
            }
            setListaCvs(state => [...state, { value: element.cv, label: element.cv }]);
            setListaLineasTransp((prevState) => {
              let duplicate = prevState.find((x) => x.label === element.lineaTransporte)
              if (!duplicate)
                return [...prevState, { value: element.lineaTransporte, label: element.lineaTransporte }];
              else
                return [...prevState];
            });
            setListaEstatus((prevState) => {
              let duplicate = prevState.find((x) => x.label === element.estatus)
              if (!duplicate)
                return [...prevState, { value: element.estatus, label: element.estatus }];
              else
                return [...prevState];
            });
          }
        }
      }
    })

    labels = ["Carga", "Tránsito por Centro de Monitoreo", "Tránsito por Call Center", "Cierre"];
    data = [carga, txcm, txcc, cierre];
    let customLabels = labels.map((label, index) => `${label}: ${data[index]}`)
    setCharData({
      labels: customLabels,
      datasets: [
        {
          label: "Estatus del Viaje",
          backgroundColor: [
            "#83ce83",
            "#f96a5d",
            "#6800B4",
            "#00A6B4",

          ],
          data: data,
        },
      ],
    });
  }
}


export default EstatusViajePage;
