import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card } from "tabler-react";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { ClientesForm } from '../../Catalogos';
import { TransportistasForm } from '../../Catalogos';
import ModalLayout from "../../components/ModalLayout.react";
/**
 * Date tools
 */
import { timeToDateFill } from "../../herramientas/DateFormat";
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';

import { from } from "rxjs";
var user= JSON.parse(sessionStorage.getItem("usuario"));
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
const datetime = 'yyyy-mm-dd HH:mm:ss';

function pad(number) {
  if (number < 10) { return '0' + number; }
  return number;
}
function myFormato(date) {
  console.log(date);
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}
function dateToStringFormat(date1, date2, date3) {
  var stringformat = "";
  var date = '';

  if (date1 != null) {
    date = date2;
  } else {
    date = date3;
  }

  if (date != '' && date instanceof Date) {
    var dd = date.getDate();
    var mm = date.getMonth();
    var mmm = mm + 1;
    var hora = date.getHours();
    var min = date.getMinutes();
    var seg = date.getSeconds();
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mmm < 10) {
      mmm = '0' + mmm;
    }

    stringformat = yyyy + '-' + mmm + '-' + dd + " " + hora + ":" + min + ":" + seg;
  }

  return stringformat;
}



class MonitoreoNavieroForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenModals: false,
      id: 0,
      idCorredor: { value: 0 , label: "No se ha seleccionado plataforma" },
      idCliente: { value: 0 , label: "No se ha seleccionado plataforma" },
      idClienteNaviero: { value: 0 , label: "No se ha seleccionado plataforma" },
      idMovimientoTipo: { value: 0 , label: "No se ha seleccionado plataforma" },
      idNaviera: { value: 0 , label: "No se ha seleccionado plataforma" },
      idOperacionTipo: { value: 0 , label: "No se ha seleccionado plataforma" },
      idPlataforma: { value: 0 , label: "Selecciona una plataforma" },
      idTransportista: { value: 0 , label: "No se ha seleccionado plataforma" },
      entregaVacio:false,
      vip: false,
      tipoOperacion: 0,
      booking: "",
      po:"",
      contenedor:"",
      citaProgramada: "",
      citaProgramadaPrueba: null,
      citaProgramadaActualizada: "",

      //destinoCalle: "",
      //destinoNumero: "",
      //destinoLocalidad: "",
      //destinoEstado: "",
      //destinoCp: "",

      //eta: "00:00:00",
      arriboTerminalVacio: "",
      salidaTerminalLleno: "",
      arriboClienteLleno: "",
      salidaClienteVacio: "",
      arriboTerminalVacio2: "",
      retiroContenedor: "",
      arriboClienteVacio: "",
      salidaClienteLleno: "",
      arriboTerminalCargado: "",
      sello: "",
      ontime: "00:00:00",
      delay: "00:00:00",
      wti6h: "00:00:00",
      transit: "00:00:00",
      //movimientoGps: "",
      //movimientoAcumuladores: "",
      selectedOption: null,
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      corredores: [],
      navieras:  [],
      clientesNavieros:  [],
      clientes:  [],
      operacionesTipo: [],
      movimientosTipo: [],
      transportistas:  [],
      contenedoresTipo: [],
      contenedorTipo: { value: 0, label: "Selecciona el tipo de carga", data: [] },
      contenedorSize: { value: 0, label: "Selecciona el tamaño" },
      destino: "",
      nota: "",
      opcion: true,
      crudbutonEnable: true, // Se desabilita cuando se hace click


    };
    this.enableSave = this.enableSave.bind(this);
    this.MovimientosObject = {
      "id": 0,
      "idCorredor": { "id": "", "nombre": "", "nombreCorto": "", "latitud": "", "longitud": "", "estatus": "" },
      "idCliente": { "id": "", "nombre": "", "nombreCorto": "", "domicilio": "", "contacto": "", "telefono": "", "extension": "", "nota": "", "vip": "", "latitud": "", "longitud": "", "estatus": "" },
      "idMovimientoTipo": { "id": "", "nombre": "" },
      "idNaviera": { "id": "1", "nombre": "Sin naviera" },
      "idClienteNaviero": null,
      "idOperacionEstatus": { "id": 1, "nombre": "Sin GPS" },
      "idOperacionTipo": { "id": "", "nombre": "" },
      "idOtd": { "id": 0, "nombre": "Pendiente", "descripcion": "" },
      "idPlataforma": { "id": "", "nombre": "", "clave": "", "plataformaTipo": { "id": "", "nombre": "" }, "corredores": [] },
      "idRuta": { "id": 1, "nombre": "**UNIDAD PROGRAMADA**" },
      "idTransportista": { "id": "", "nombre": "", "nombreCorto": "", "contactoNombre": "", "telefono1": "", "telefono2": "", "correo": "" },
      "entregaVacio":0,
      "tipoOperacion":0,
      "booking": "",
      "po":"",
      "contenedor":"",
      "citaProgramada": "",
      "contenedorTipo": 1,
      "contenedorSize": 0,
      "destino": "",
      "nota": "",
      //"destinoCalle": "",
      //"destinoNumero": "",
      //"destinoLocalidad": "",
      //"destinoEstado": "",
      //"destinoCp": "",

      //"eta": "00:00:00",
      "arriboTerminalVacio": "",
      "salidaTerminalLleno": "",
      "arriboClienteLleno": "",
      "salidaClienteVacio": "",
      "arriboTerminalVacio2": "",
      "retiroContenedor": "",
      "arriboClienteVacio": "",
      "salidaClienteLleno": "",
      "arriboTerminalCargado": "",
      "sello": "",
      "ontime": "00:00:00",
      "delay": "00:00:00",
      "wti6h": "00:00:00",
      "transit": "00:00:00",
      "actualizarFull": 0,
      "vip": false,
      "reaccion": false
      //"movimientoAcumuladores": [],
    }
    this.clientes = [];
    this.transportistas = [];
    this.plataformas = [];
    this.navieras = [];
    this.operaciones = [];
    this.estatusOperacion = [];
    this.movimientoTipos = [];
    this.corredores = [];
    this.estatusRutas = [];
    this.contenedorSizes= [{ value: 0, label: "Selecciona el tamaño" }, { value: 20, label: "20" }, { value: 40, label: "40" }];
  }
  isEnable=(Accion)=>{
    //let borrar= "clientes_borrar";

    let actualizar= "movimientos_editar";
    let agregar= "movimientos_agregar";

    let actualizarCliente= "clientes_editar";
    let agregarCliente= "clientes_agregar";

    let actualizarTransportista= "transportistas_editar";
    let agregarTransportista= "transportistas_agregar";

    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "actualizarCliente"){if( privilegios[i].clave == actualizarCliente){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregarCliente"){if( privilegios[i].clave == agregarCliente){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}

      if(Accion === "actualizarTransportista"){if( privilegios[i].clave == actualizarTransportista){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregarTransportista"){if( privilegios[i].clave == agregarTransportista){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}

      //if(Accion === "borrar"){ if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }
  hiddenmodal = () => { this.setState({ hiddenModals: !this.state.hiddenModals }); }
  getfuntion = (metodo="get", obj=[], id="", catalogo="cliente", stateVar= "clientes", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "corredores"){ this.corredores=[];
                returnVal.map((corredor, index)=>this.corredores.push({ value: corredor.id, label: corredor.nombre, data: corredor }) );
                this.setState({corredores: this.corredores});
                if(this.props.accion == 'nuevo'){this.setState({ idCorredor: { value: 0 , label: "Selecciona un corredor" } });}
              }
              if (stateVar === "contenedorTipo"){
                let ContTipoTemp= [];
                ContTipoTemp.push({ value: 0, label: "Selecciona el tipo de carga", data: [] });
                returnVal.map((contTipo, index)=>{
                  if(this.props.accion == 'nuevo'){
                    if(contTipo.id === 1){
                      this.setState({ contenedorTipo: { value: contTipo.id, label: contTipo.nombre, data: contTipo } });
                      this.MovimientosObject.contenedorTipo= contTipo.id;
                    }
                  }else{
                    if(this.props.elementos.contenedorTipo === contTipo.id){
                      this.setState({ contenedorTipo: { value: contTipo.id, label: contTipo.nombre, data: contTipo } });
                      this.MovimientosObject.contenedorTipo= contTipo.id;
                    }
                  }
                  ContTipoTemp.push({ value: contTipo.id, label: contTipo.nombre, data: contTipo });
                });
                this.setState({contenedoresTipo: ContTipoTemp });
              }
              if (stateVar === "navieras"){ this.navieras = [];
                returnVal.map((nav, index)=>this.navieras.push({ value: nav.id, label: nav.nombre, data: nav }) );
                this.setState({ navieras: this.navieras  });
                if(this.props.accion == 'nuevo'){ this.setState({ idNaviera: { value: 0 , label: "Selecciona una naviera" } });}
              }
              if (stateVar === "clientes"){ this.clientes = [];
                if(returnVal.content.length>0){
                  returnVal.content.map((client, index)=>this.clientes.push({ value: client.id, label: client.nombre, data: client }) );
                  this.setState({ clientes: this.clientes });
                  if(this.props.accion == 'nuevo'){ this.setState({ idCliente: { value: 0 , label: "Selecciona un cliente" } });}
                }else{
                  this.setState({ idCliente: { value: 0 , label: "No hay clientes" } });
                }
              }
              if (stateVar === "clientesNavieros"){
                let clientesNav = [];
                if(returnVal.content.length>0){
                  returnVal.content.map((client, index)=> clientesNav.push({ value: client.id, label: client.nombre, data: client }) );
                  this.setState({ clientesNavieros: clientesNav });
                  if(this.props.accion == 'nuevo'){ this.setState({ idCliente: { value: 0 , label: "Selecciona un cliente" } });}
                }else{
                  this.setState({ idClienteNaviero: { value: 0 , label: "No hay clientes" } });
                }
              }
              if (stateVar === "operacionesTipo"){ this.operaciones = [];
                returnVal.map((op, index)=>this.operaciones.push({ value: op.id, label: op.nombre, data: op }) );
                this.setState({ operacionesTipo: this.operaciones  });
                if(this.props.accion == 'nuevo'){this.setState({ idOperacionTipo: { value: 0 , label: "Selecciona un Tipo de operación" } });}
              }
              if (stateVar === "movimientosTipo"){ this.movimientoTipos = [];
                returnVal.map((mt, index)=>this.movimientoTipos.push({ value: mt.id, label: mt.nombre, data: mt }) );
                this.setState({ movimientosTipo: this.movimientoTipos  });
                if(this.props.accion == 'nuevo'){this.setState({ idMovimientoTipo: { value: 0 , label: "Selecciona un Tipo de movimiento" }});}
              }
              if (stateVar === "transportistas"){ this.transportistas = [];
                returnVal.map((t, index)=>this.transportistas.push({ value: t.id, label: t.nombre, data: t }) );
                this.setState({ transportistas: this.transportistas  });
                if(this.props.accion == 'nuevo'){ this.setState({ idTransportista: { value: 0 , label: "Selecciona un Transportistas" } });}
              }
            }
            break;
          case "post":
            {
              if (stateVar === "clientes") {
                if(returnVal.id){
                  this.getfuntion("post", { "idPlataforma": this.state.idPlataforma.value, "idCliente": returnVal.id }, "", "plataformasClientes", "plataformasClientes");
                  NotificationManager.info("Se ha agregado el cliente al catlogo general( "+returnVal.nombre+" )", "Nuevo");
                  this.clientes = this.state.clientes;
                  this.clientes.push({ value: returnVal.id, label: returnVal.nombre, data: returnVal });
                  this.MovimientosObject.idCliente = returnVal;
                  this.setState({ clientes: this.clientes });

                  this.setState({ idCliente: { value: returnVal.id, label: returnVal.nombre, data: returnVal } });
                }
              }
              if (stateVar === "plataformasClientes") {
                if(returnVal.idPlataforma){
                  NotificationManager.info("El cliente ahora pertenece a la plataforma( "+this.state.idPlataforma.value+" )", "Nuevo");
                }
                this.hiddenmodal();
              }
            }break;
          case "put":
              {
                if (stateVar === "clientes") {
                  if(returnVal.id){
                    NotificationManager.info("Se han agregado las modificaciones del cliente al catlogo general( "+returnVal.nombre+" )", "Modificación");
                    let clientesTem= this.state.clientes;
                    for(let item of clientesTem){
                      if(item.id == returnVal.id){
                        item= returnVal;
                      }
                    }
                    this.setState({ clientes: clientesTem });
                    this.hiddenmodal();
                  }
                }
              }
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){ console.log(err.response); }else{console.log("Error desconocido ...."); }
    });
  }
  getfuntionTrans = (metodo="get", obj=[], id="", catalogo="transportistas", stateVar= "transportistas", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "post":
            {
              if (stateVar === "transportistas") {
                if(returnVal.id){
                  this.getfuntionTrans("post", { "plataforma": this.props.elementos.idPlataforma, "transportista": returnVal }, "", "plataformasTransportistas", "plataformasTransportistas");
                  NotificationManager.info("Se ha agregado el transportista al catlogo general( "+returnVal.nombre+" )", "Nuevo");
                  this.transportistas = this.state.transportistas;
                  this.transportistas.push({ value: returnVal.id, label: returnVal.nombre, data: returnVal });
                  this.MovimientosObject.idTransportista = returnVal;
                  this.setState({ transportistas: this.transportistas });
                  this.setState({ idTransportista: { value: returnVal.id, label: returnVal.nombre, data: returnVal } });
                }
              }
              if (stateVar === "plataformasTransportistas") {
                if(returnVal.plataforma){
                  NotificationManager.info("El transportista ahora pertenece a la plataforma( "+returnVal.plataforma.nombre+" )", "Nuevo");
                }
                this.hiddenmodal();
              }
            }break;
          case "put":
              {
                if (stateVar === "transportistas") {
                  if(returnVal.id){
                    NotificationManager.info("Se han agregado las modificaciones del transportista al catalogo general( "+returnVal.nombre+" )", "Modificación");
                    let transportistasTem= this.state.transportistas;
                    for(let item of transportistasTem){
                      if(item.id == returnVal.id){
                        item= returnVal;
                      }
                    }
                    this.transportistas = transportistasTem;
                    this.setState({ transportistas: transportistasTem });
                    this.setState({ idTransportista: { value: returnVal.id, label: returnVal.nombre, data: returnVal } });
                    this.hiddenmodal();
                  }
                }
              }
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){ console.log(err.response); }else{console.log("Error desconocido ...."); }
    });
  }
  componentWillMount =() =>{}
  componentDidMount = () => {
    /////////////////////////////////////////////////////////////////////////////////77
    currentUser.map((elemento, index) => {
      if(elemento.plataforma.plataformaTipo.nombre.toUpperCase().includes('NAVIERA') ){
        this.plataformas.push({ value: elemento.plataforma.id, label: elemento.plataforma.nombre, data: elemento.plataforma});
      }
    });
    this.getfuntion("get", "", "", "contenedorTipo","contenedorTipo");
    if (this.state.accion !== 'nuevo') {
      this.getfuntion("get", "", "", "corredores/plataforma/"+this.props.elementos.idPlataforma.id,"corredores");
      this.setState({ contenedorSize: this.props.elementos.contenedorSize? { value: this.props.elementos.contenedorSize, label: this.props.elementos.contenedorSize }: { value: 0, label: "Selecciona el tamaño" } });
      this.setState({ destino: this.props.elementos.destino? this.props.elementos.destino: "" });
      this.setState({ nota: this.props.elementos.nota? this.props.elementos.nota: "" });
      this.setState({ id: this.props.elementos.id });
      this.setState({ idNaviera: { value: this.props.elementos.idNaviera.id, label: this.props.elementos.idNaviera.nombre } });
      this.setState({ opcion: this.props.elementos.idNaviera.id > 1 ? true: false });
      /**
       * Cargar catalogos
       */
      let idPlt= this.props.elementos.idPlataforma.id;
      if(idPlt){
        this.getfuntion("get","","","corredores/plataforma/"+idPlt,"corredores");
        this.getfuntion("get","","","navieras/plataforma/"+idPlt,"navieras");
        if(this.props.elementos.idCliente){
          let nombre= this.props.elementos.idCliente.nombre;
          this.getfuntion("get","","","cliente/plataforma/"+idPlt+"/cliente/"+nombre+"/pagina/0/total/100", "clientes");
        }else{
          this.getfuntion("get","","","cliente/plataforma/"+idPlt+"/pagina/0/total/100","clientes");
        }
        if(this.props.elementos.idClienteNaviero){
          let nombre= this.props.elementos.idClienteNaviero.nombre;
          this.getfuntion("get","","","clientes_navieros/plataforma/"+idPlt+"/cliente/"+nombre+"/pagina/0/total/100", "clientesNavieros");
        }else{
          this.getfuntion("get","","","clientes_navieros/plataforma/"+idPlt+"/pagina/0/total/100","clientesNavieros");
        }
        this.getfuntion("get","","","operacionesTipo/plataforma/"+idPlt,"operacionesTipo");
        this.getfuntion("get","","","movimientosTipo/plataforma/"+idPlt,"movimientosTipo");
        this.getfuntion("get","","","transportistas/plataforma/"+idPlt,"transportistas");
      }
      if(this.props.elementos.idPlataforma){
        this.setState({ idPlataforma: { value: this.props.elementos.idPlataforma.id, label: this.props.elementos.idPlataforma.nombre, data: this.props.elementos.idPlataforma } });
      }else{ this.setState({ idPlataforma: { value: 0, label: "Seleccione Una opción" } }); }

      if(this.props.elementos.idOperacionTipo){
        this.setState({ idOperacionTipo: { value: this.props.elementos.idOperacionTipo.id, label: this.props.elementos.idOperacionTipo.nombre } });
      }else{ this.setState({ idOperacionTipo: { value: 0, label: "Seleccione Una opción" } }); }

      if(this.props.elementos.idTransportista){
        this.setState({ idTransportista: { value: this.props.elementos.idTransportista? this.props.elementos.idTransportista.id: 0, label: this.props.elementos.idTransportista? this.props.elementos.idTransportista.nombre: "Sin transportista", data: this.props.elementos.idTransportista? this.props.elementos.idTransportista:null } });
      }else{ this.setState({ idTransportista: { value: 0, label: "Seleccione Una opción" } }); }

      if(this.props.elementos.idMovimientoTipo){
        this.setState({ idMovimientoTipo: { value: this.props.elementos.idMovimientoTipo.id, label: this.props.elementos.idMovimientoTipo.nombre } });
      }else{ this.setState({ idMovimientoTipo: { value: 0, label: "Seleccione Una opción" } }); }

      if(this.props.elementos.idCorredor){
        this.setState({ idCorredor: { value: this.props.elementos.idCorredor.id, label: this.props.elementos.idCorredor.nombre } });
      }else{ this.setState({ idCorredor: { value: 0, label: "Seleccione Una opción" } }); }

      if(this.props.elementos.idCliente){
        this.setState({ idCliente: { value: this.props.elementos.idCliente.id, label: this.props.elementos.idCliente.nombre, data: this.props.elementos.idCliente } });
      }else{ this.setState({ idCliente: { value: 0, label: "Seleccione Una opción" } }); }

      this.setState({ booking: this.props.elementos.booking });
      this.setState({ po: this.props.elementos.po });
      this.setState({ entregaVacio:this.props.elementos.entregaVacio });
      this.setState({ vip: this.props.elementos.vip});
      this.setState({ contenedor: this.props.elementos.contenedor });
      this.setState({ tipoOperacion: this.props.elementos.tipoOperacion });

      if(this.props.elementos.citaProgramada != null){
        this.setState({
          citaProgramada: new Date(this.props.elementos.citaProgramada),
          citaProgramadaPrueba: new Date (this.props.elementos.citaProgramada)
        });
      }

      //this.setState({ destinoCalle: this.props.elementos.destinoCalle });
      //this.setState({ destinoNumero: this.props.elementos.destinoNumero });
      //this.setState({ destinoLocalidad: this.props.elementos.destinoLocalidad });
      //this.setState({ destinoEstado: this.props.elementos.destinoEstado });
      //this.setState({ destinoCp: this.props.elementos.destinoCp });

      //this.setState({ eta: this.props.elementos.eta });
      this.setState({ arriboTerminalVacio: this.props.elementos.arriboTerminalVacio });
      this.setState({ salidaTerminalLleno: this.props.elementos.salidaTerminalLleno });
      this.setState({ arriboClienteLleno: this.props.elementos.arriboClienteLleno });
      this.setState({ salidaClienteVacio: this.props.elementos.salidaClienteVacio });
      this.setState({ arriboTerminalVacio2: this.props.elementos.arriboTerminalVacio2 });
      this.setState({ retiroContenedor: this.props.elementos.retiroContenedor });
      this.setState({ arriboClienteVacio: this.props.elementos.arriboClienteVacio });
      this.setState({ salidaClienteLleno: this.props.elementos.salidaClienteLleno });
      this.setState({ arriboTerminalCargado: this.props.elementos.arriboTerminalCargado });
      this.setState({ sello: this.props.elementos.sello });
      this.setState({ ontime: this.props.elementos.ontime });
      this.setState({ delay: this.props.elementos.delay });
      this.setState({ wti6h: this.props.elementos.wti6h });
      this.setState({ transit: this.props.elementos.transit });
      //this.setState({ movimientoGps: this.props.elementos.movimientoGps });
      //this.setState({ movimientoAcumuladores: this.props.elementos.movimientoAcumuladores });
      this.MovimientosObject = this.props.elementos;
    }else{
    }
  }
  enableSave = () => {
    if (this.state.idPlataforma.value > 0 && this.state.idCliente.value > 0 && this.state.idOperacionTipo.value > 0 && this.state.idMovimientoTipo.value > 0 && this.state.idCorredor.value > 0) {
      return false;
    } else { return true }
  }

  //************ Combos ***********************
  changePlataformas = (event) => {
    currentUser.map((elemento, index) => { if (event.value == elemento.plataforma.id) { this.MovimientosObject.idPlataforma = elemento.plataforma; } });
    console.log(this.MovimientosObject.idPlataforma);
    this.setState({ idPlataforma: event });
    this.setState({ idCorredor: { value: 0 , label: "Cargando corredores" } });
    this.setState({ idNaviera: { value: 0 , label: "Cargando navieras" } });
    this.setState({ idCliente: { value: 0 , label: "Cargando Clientes" } });
    this.setState({ idOperacionTipo: { value: 0 , label: "Cargando Tipos de operación" } });
    this.setState({ idMovimientoTipo: { value: 0 , label: "Cargando Tipos de movimiento" }});
    this.setState({ idTransportista: { value: 0 , label: "Cargando Transportistas" } });

    this.getfuntion("get","","","corredores/plataforma/"+event.value,"corredores");
    this.getfuntion("get","","","navieras/plataforma/"+event.value,"navieras");
    this.getfuntion("get","","","cliente/plataforma/"+event.value+"/pagina/0/total/100","clientes");
    this.getfuntion("get","","","clientes_navieros/plataforma/"+event.value+"/pagina/0/total/100","clientesNavieros");
    this.getfuntion("get","","","operacionesTipo/plataforma/"+event.value,"operacionesTipo");
    this.getfuntion("get","","","movimientosTipo/plataforma/"+event.value,"movimientosTipo");
    this.getfuntion("get","","","transportistas/plataforma/"+event.value,"transportistas");
  }
  changeNaviera = (event)             => {  this.navieras.map((elemento, index)                   => { if (event.value == elemento.data.id) { this.MovimientosObject.idNaviera = elemento.data; } }); this.setState({ idNaviera: event }); }

  changeCliente = (event)             => {  this.MovimientosObject.idCliente = event.data;        this.setState({ idCliente:event }); }
  changeClienteNaviero = (event)      => {  this.MovimientosObject.idClienteNaviero = event.data; this.setState({ idClienteNaviero: event }); }

  changeContenedorTipo = (event)      => {  this.MovimientosObject.contenedorTipo = event.value;  this.setState({ contenedorTipo:event }); }
  changeContenedorSize = (event)      => {  this.MovimientosObject.contenedorSize = event.value;  this.setState({ contenedorSize:event }); }
  changeDestino = (event)             => {  this.MovimientosObject.destino = event.target.value;  this.setState({ destino: event.target.value }); }
  changeNota = (event)                => {  if(event.target.value.length<=2000){ this.MovimientosObject.nota = event.target.value;     this.setState({ nota:event.target.value });} }

  changeCorredor = (event)            => { this.corredores.map((elemento, index)                  => { if (event.value == elemento.data.id) { this.MovimientosObject.idCorredor = elemento.data; } }); this.setState({ idCorredor: event }); }
  changeMovimientoTipo = (event)      => { this.movimientoTipos.map((elemento, index)             => { if (event.value == elemento.data.id) { this.MovimientosObject.idMovimientoTipo = elemento.data; } }); this.setState({ idMovimientoTipo: event }); }
  changeTransportista = (event)       => { this.transportistas.map((elemento, index)              => { if (event.value == elemento.data.id) { this.MovimientosObject.idTransportista = elemento.data; } }); this.setState({ idTransportista: event }); }
  changeOperacionesTipo = (event)     => {this.operaciones.map((elemento, index)                  => { if (event.value == elemento.data.id) { this.MovimientosObject.idOperacionTipo = elemento.data; } });  this.setState({ idOperacionTipo: event }); }
  //Campos Normales
  changeid = (event)                  => { this.MovimientosObject.id = event.target.value; this.setState({ id: event.target.value }); }
  changeBooking = (event)             => { this.MovimientosObject.booking = event.target.value; this.setState({ booking: event.target.value }); }
  changePo = (event)                  => { this.MovimientosObject.po = event.target.value; this.setState({ po: event.target.value }); }
  changeEntregaVacio = (event)        => { this.MovimientosObject.entregaVacio = event.target.checked ? 1 : 0; event.target.checked ? this.setState({ entregaVacio: 1}) : this.setState({entregaVacio:0}); }
  changeVip = (event)                 => { this.MovimientosObject.vip = event.target.checked; this.setState({ vip: event.target.checked }) }
  changeOpcion = (event)              => { this.setState({ opcion: event.target.checked }) }

  changeContenedor = (event)          => { this.MovimientosObject.contenedor = event.target.value; this.setState({ contenedor: event.target.value }); }
  changeTipoOperacion = (event)       => { this.MovimientosObject.tipoOperacion = event.target.value; this.setState({ tipoOperacion: event.target.value }); }

  //changeCalle = (event)               => { this.MovimientosObject.destinoCalle = event.target.value; this.setState({ destinoCalle: event.target.value }); }
  //changeNumero = (event)              => { this.MovimientosObject.destinoNumero = event.target.value; this.setState({ destinoNumero: event.target.value }); }
  //changeLocalidad = (event)           => { this.MovimientosObject.destinoLocalidad = event.target.value; this.setState({ destinoLocalidad: event.target.value }); }
  //changeEstado = (event)              => { this.MovimientosObject.destinoEstado = event.target.value; this.setState({ destinoEstado: event.target.value }); }
  //changeCp = (event)                  => { this.MovimientosObject.destinoCp = event.target.value;this.setState({ destinoCp: event.target.value }); }

  changeSello = (event)               => { this.MovimientosObject.sello = event.target.value; this.setState({ sello: event.target.value }); }
  changeOnTime = (event) => {
    let Wti6h = "";
    Wti6h = event.target.value;
    let hora = Wti6h.substr(0, 2) + ":" + Wti6h.substr(3, 2) + ":" + Wti6h.substr(6, 2);//console.log("OnTime: " + hora + " target: " + event.target.value);
    this.MovimientosObject.ontime = hora;
    this.setState({ ontime: hora });
  }

  changeDelay = (event) => {
    let Wti6h = "";
    Wti6h = event.target.value;
    let hora = Wti6h.substr(0, 2) + ":" + Wti6h.substr(3, 2) + ":" + Wti6h.substr(6, 2);
    this.MovimientosObject.delay = hora;
    this.setState({ delay: hora });
  }

  changeWtih = (event) => {
    let Wti6h = "";
    Wti6h = event.target.value;
    let hora = Wti6h.substr(0, 2) + ":" + Wti6h.substr(3, 2) + ":" + Wti6h.substr(6, 2);//console.log("Wti6h: " + hWti6h );
    this.MovimientosObject.wti6h = hora;
    this.setState({ wti6h: hora });
  }

  changeTransit = (event) => {
    let Wti6h = "";
    Wti6h = event.target.value;
    let hora = Wti6h.substr(0, 2) + ":" + Wti6h.substr(3, 2) + ":" + Wti6h.substr(6, 2);//console.log("transit: " +  hora );
    this.MovimientosObject.transit = hora;
    this.setState({ transit: hora });
  }

  changeCitaProgramada = (event) =>{
    this.setState({citaProgramada: event.target.value})
  }

  onChangeDate = (date) =>{
    this.MovimientosObject.citaProgramada= timeToDateFill(new Date(date).getTime());
    if(date != null){
      this.setState({
        citaProgramada: date,
        citaProgramadaActualizada: date,
        citaProgramadaPrueba: null
      })
    }else{
      this.setState({
        citaProgramada: date,
        citaProgramadaPrueba: null
      })
    }
  }
  crud_put() {
    var date = '';
    var dates = dateToStringFormat(this.state.citaProgramadaPrueba, this.state.citaProgramada, this.state.citaProgramadaActualizada);
    this.props.getfuntion("put", this.MovimientosObject);
    this.setState({ crudbutonEnable: false });
  };

  crud_post() {

    var date = '';

    var dates = dateToStringFormat(this.state.citaProgramadaPrueba
      , this.state.citaProgramada
      , this.state.citaProgramadaActualizada);

    console.log("Post Movimiento");
    console.log(this.MovimientosObject);
    this.props.getfuntion("post", this.MovimientosObject);
    this.setState({ crudbutonEnable: false });
  };
/*
  _KeyDown_clientes=(e)=> {
    let valid="QWERTYUIOPASDFGHJKLLÑZXCVBNM_().1234567890ÁÉÍÓÚ";
    let texto="";
    let buscar= true;
    if( valid.toUpperCase().includes(e.key.toUpperCase()) ){
        texto=e.target.value+e.key;
        for(let item of this.state.clientes){
          if( item.label.toUpperCase().includes(texto.toUpperCase()).length >= 100 ){ buscar= false; break; }
        }
    }
    if (e.key !== ' ' && texto && this.state.idPlataforma.value > 0 && buscar ) {
      this.getfuntion("get","","","cliente/plataforma/"+this.state.idPlataforma.value+"/cliente/"+texto+"/pagina/0/total/100", "clientes");
    }
  }*/
  changeValueCliente=(e)=>{
    if (this.state.idPlataforma.value > 0 && e) {
      this.getfuntion("get","","","cliente/plataforma/"+this.state.idPlataforma.value+"/cliente/"+e+"/pagina/0/total/100", "clientes");
    }
  }
  changeValueClienteNaviero=(e)=> {
    if (this.state.idPlataforma.value > 0 && e ) {//e.key !== ' ' &&
      this.getfuntion("get","","","clientes_navieros/plataforma/"+this.state.idPlataforma.value+"/cliente/"+e+"/pagina/0/total/300", "clientesNavieros");
    }
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        {this.state.accion === "modificar" ? <h1>{this.props.elementos.nombre}</h1> : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>

              <Grid.Col md={4} lg={2}><Form.Group label="Id"><Form.Input name="id" onChange={this.changeid} disabled type="number" value={this.state.id} /></Form.Group></Grid.Col>

              <Grid.Col md={4} lg={4}>
                <Form.Group label="Plataforma"><Select value={this.state.idPlataforma} onChange={this.changePlataformas} options={this.plataformas} /></Form.Group>
              </Grid.Col>
              {this.state.idPlataforma.value>0? this.state.idPlataforma.data.plataformaTipo.id === 1?
                <Grid.Col md={2}>
                  <Form.Switch name="opcion" label={ this.state.opcion? "naviera":"cliente dir" } checked={ this.state.opcion } onChange={this.changeOpcion} />
                </Grid.Col>
              :null:null}
              { this.state.idPlataforma.value>0? this.state.idPlataforma.data.plataformaTipo.id === 1?
                  this.state.opcion?
                    <Grid.Col md={4}>
                      <Form.Group label="Naviera"><Select value={this.state.idNaviera} onChange={this.changeNaviera} options={this.state.navieras} isLoading={this.state.navieras.length == 0? true:false} /></Form.Group>
                    </Grid.Col>
                  :
                    <Grid.Col md={4}>
                      <Form.Group label="Cliente Direct.">
                        <Select value={this.state.idClienteNaviero} onChange={this.changeClienteNaviero} options={ this.state.clientesNavieros } isLoading={this.state.clientesNavieros.length == 0? true:false} onInputChange={this.changeValueClienteNaviero} />
                      </Form.Group>
                    </Grid.Col>
                :null:null
              }


              <Grid.Col md={4}>
                <Form.Group label="Cliente">
                  <Grid.Row>
                    <Grid.Col>
                      <Select value={this.state.idCliente} onChange={this.changeCliente} options={ this.state.clientes } isLoading={this.state.clientes.length == 0? true:false} onInputChange={this.changeValueCliente} />
                      { this.state.idCliente.value>0 ?<p>{this.state.idCliente.data.domicilio}</p>:null}
                    </Grid.Col>
                    { this.state.idPlataforma.value>0 && (this.isEnable("actualizarCliente") || this.isEnable("agregarCliente") )?
                      <Grid.Col md={2} lg={2}>
                        {this.isEnable("agregarCliente")?
                          <span className="float-right">
                            <ModalLayout tamanio="85%" title={"Nuevo"}
                                        name={<span className="fa fa-plus ml-2" data-toggle="tooltip" data-placement="top" title={"Nuevo cliente(Antes de crear  valida que no exista)"}></span>} hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                        formulario={<ClientesForm accion="nuevo" plataforma={this.state.idPlataforma.data} elementos={this.state.idCliente.data} getfuntion={this.getfuntion} stor={this.props.stor} />}
                            />
                          </span>
                        :null}
                        {this.isEnable("actualizarCliente")?
                          <span className="float-right">
                            { this.state.idCliente.value>0 ?
                              <ModalLayout tamanio="85%" title="Modificar"
                                          name={<span className="fa fa-edit" data-toggle="tooltip" data-placement="top" title={"Modificar ("+ this.state.idCliente.label+ ")"}></span>} hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                          formulario={<ClientesForm accion="modificar" plataforma={this.state.idPlataforma.data} elementos={this.state.idCliente.data} getfuntion={this.getfuntion} stor={this.props.stor} />}
                              />
                            :null}
                          </span>
                        :null}
                      </Grid.Col>
                    :null}
                  </Grid.Row>
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={4}>
                <Form.Group label="Tipo de Operaciones"><Select value={this.state.idOperacionTipo} onChange={this.changeOperacionesTipo} options={this.state.operacionesTipo} isLoading={this.state.operacionesTipo.length == 0? true:false}/></Form.Group>
              </Grid.Col>

              <Grid.Col md={4}>
                <Form.Group label="Tipo de Movimiento"><Select value={this.state.idMovimientoTipo} onChange={this.changeMovimientoTipo} options={this.state.movimientosTipo} isLoading={this.state.movimientosTipo.length == 0? true:false} /></Form.Group>
              </Grid.Col>

              <Grid.Col md={4}>
                <Form.Group label="Corredores"><Select value={this.state.idCorredor} onChange={this.changeCorredor} options={this.state.corredores} isLoading={this.state.corredores.length == 0? true:false}/></Form.Group>
              </Grid.Col>

              <Grid.Col md={4}>
                <Form.Group label="Transportistas">
                  <Grid.Row>
                    <Grid.Col>
                      <Select value={this.state.idTransportista} onChange={this.changeTransportista} options={this.state.transportistas} isLoading={this.state.transportistas.length == 0? true:false} />
                    </Grid.Col>
                      { this.state.idPlataforma.value>0 && (this.isEnable("actualizarTransportista") || this.isEnable("agregarTransportista") )?
                          <Grid.Col md={2} lg={2}>
                            {this.isEnable("agregarTransportista")?
                              <span className="float-right">
                                <ModalLayout tamanio="85%" title={"Nuevo"}
                                            name={<span className="fa fa-plus ml-2" data-toggle="tooltip" data-placement="top" title={"Nuevo Transportista(Antes de crear  valida que no exista)"}></span>} hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                            formulario={<TransportistasForm accion="nuevo" plataforma={this.state.idPlataforma.data} elementos={this.state.idTransportista.data} getfuntion={this.getfuntionTrans} stor={this.props.stor} />}
                                />
                              </span>
                            :null}
                            {this.isEnable("actualizarTransportista")?
                              <span className="float-right">
                                { this.state.idTransportista.value>0 ?
                                  <ModalLayout tamanio="85%" title="Modificar"
                                              name={<span className="fa fa-edit" data-toggle="tooltip" data-placement="top" title={"Modificar ("+ this.state.idTransportista.label+ ")"}></span>} hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                              formulario={<TransportistasForm accion="modificar" plataforma={this.state.idPlataforma.data} elementos={this.state.idTransportista.data} getfuntion={this.getfuntionTrans} stor={this.props.stor} />}
                                  />
                                :null}
                              </span>
                            :null}
                          </Grid.Col>
                        :null}
                  </Grid.Row>
                </Form.Group>
              </Grid.Col>
              {this.state.idPlataforma.value>0? this.state.idPlataforma.data.plataformaTipo.id === 1?
                <Grid.Col md={4}>
                  <Form.Group label="Tipo de carga"><Select value={this.state.contenedorTipo} onChange={this.changeContenedorTipo} options={ this.state.contenedoresTipo } isLoading={this.state.contenedoresTipo.length == 0? true:false} /></Form.Group>
                </Grid.Col>
              :null:null}
              {this.state.idPlataforma.value>0? this.state.idPlataforma.data.plataformaTipo.id === 1?
                <Grid.Col md={4}>
                  <Form.Group label="Tamaño"><Select value={this.state.contenedorSize} onChange={this.changeContenedorSize} options={ this.contenedorSizes } /></Form.Group>
                </Grid.Col>
              :null:null}

              <Card title="Datos Datos">
                <Grid.Col md={12} xl={12}>
                  <Grid.Row>
                    <Grid.Col md={4}><Form.Group label="Booking">         <Form.Input name="booking" type="text" value={this.state.booking} onChange={this.changeBooking} maxlength="20" /></Form.Group></Grid.Col>
                    <Grid.Col md={4}><Form.Group label="Po">              <Form.Input name="po"      type="text" value={this.state.po}      onChange={this.changePo} maxlength="20"/></Form.Group></Grid.Col>

                    <Grid.Col md={4}><Form.Group label="Sello">           <Form.Input name="sello" type="text" value={this.state.sello}     onChange={this.changeSello} maxlength="20" /></Form.Group></Grid.Col>
                    <Grid.Col md={4}><Form.Group label="Contenedor">      <Form.Input name="contenedor" type="text" value={this.state.contenedor} onChange={this.changeContenedor} maxlength="30" /></Form.Group></Grid.Col>
                    <Grid.Col md={2}>
                      <Form.Group label="Entrega vacio">
                      <Form.Switch name="entregaVacio" label="" value={this.state.entregaVacio} checked={this.state.entregaVacio} onChange={this.changeEntregaVacio} />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={2}>
                      <Form.Group label="Vip">
                      <Form.Switch name="vip" label="" value={this.state.vip} checked={this.state.vip} onChange={this.changeVip} />
                      </Form.Group>
                    </Grid.Col>

                    <Grid.Col md={4}>
                      <Form.Group label="Cita programada">
                        <DateTimePicker
                          format="y-MM-dd h:mm:ss a"
                          value={this.state.citaProgramada}
                          onChange={this.onChangeDate}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}><Form.Group label="Destino">         <Form.Input name="booking" type="text" value={this.state.destino} onChange={this.changeDestino} maxlength="30" /></Form.Group> </Grid.Col>
                    <Grid.Col md={4}><Form.Group label={<Form.Label aside={this.state.nota.length+"/2000"}>Nota</Form.Label>}> <Form.Textarea value={this.state.nota} name="nota" onChange={this.changeNota} placeholder="Nota del movimiento.." rows={6} /> </Form.Group> </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Card>

            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable} onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                <span className="badge"><Icon link={true} name="save" />Guardar</span>
              </Button>
            </span>
          :null}
        </div>
      </div>
    );
  }
}
export default MonitoreoNavieroForm;
