import React, { Component } from "react";
import { List, Table, Grid, Button, Icon, Alert, Form } from "tabler-react";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../../herramientas/Crud_Catalogos";
import EntregaFrom from "./EntregaForm";
import ModalLayout from "../../../../components/ModalLayout.react";
import EtaCalculadaTerrestre from "../EtaCalculadaTerrestre";
import { from } from "rxjs";

class Entregas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entregas: [],
      hiddenModals: false,
      destinos: [],
      movimientoTerrestre: "",
      elementos: [],
      etasCalculadas: {},
      cancelada: false,           
    };
    this.etasCalculadas = {};
    this.searchingentregas = [];
    this.entregas = [];
    this.movimientoAcumuladoresTerrestre = {};
  }
  componentWillMount = () => {};
  componentDidMount = () => {
    if (this.props.entregas) {
      this.setState({ entregas: this.props.entregas });
      this.entregas = this.props.entregas;
      this.entregas.map((entrega, index) => {
        this.AcumuladoresEtaTerrestre(entrega.idEntrega);  
              
      });      
    }
  };

  AcumuladoresEtaTerrestre = (idEntrega) => {
    var conteo = 0;
    Crud_Catalogos(
      "terrestreEntregasAcumuladores/etasEntrega/" + idEntrega,
      "terrestreEntregasAcumuladores",
      "get"
    )
      .then((returnVal) => {
        if (returnVal > 0) {
          this.etasCalculadas[idEntrega] = returnVal;
          this.setState({ etasCalculadas: this.etasCalculadas });
        } else {
        }
        //return returnVal;
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        } else {
          /**
           * Error
           */
        }
      });
    setTimeout(() => {
      //console.log("VALORES "+conteo);
      return conteo;
    }, 1000);
  };

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "entregas",
    stateVar = "entregas",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "entregasTerrestre",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
            }
            break;
          case "post":
            {
              if (stateVar === "entregas" && returnVal.idEntrega) {
                this.hiddenmodal();
                this.entregas.push(returnVal);
                this.setState({ entregas: this.entregas });
              }
            }
            break;
          case "put":
            {
              if (stateVar === "entregas" && returnVal.idEntrega) {
                this.hiddenmodal();
                //Colocamos todas las entregas en estatus 0, solo si el estatus de la entrega ha cambiado
                if (returnVal.estatus !== obj.estatus || returnVal.estatus) {
                  this.entregas.map(
                    (et, index) => (this.entregas[index].estatus = 0)
                  );
                }
                //Sustituir entrega actualizada
                this.entregas.map((et, index) => {
                  if (et.idEntrega === returnVal.idEntrega) {
                    this.entregas[index] = returnVal;
                    this.setState({ entregas: this.entregas });
                  }
                });
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "entregas" && returnVal === true) {
                this.hiddenmodal();
                for (let i = 0; i < this.entregas.length; i++) {
                  if (this.entregas[i].idEntrega == id) {
                    this.entregas.splice(i, 1);
                    this.setState({ entregas: this.entregas });
                    break;
                  }
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };
  changeEstatus = (e) => {
    for (let item of this.state.entregas) {
      if (item.idEntrega === parseInt(e.target.value, 10)) {
        let entTem = item;
        entTem.estatus = e.target.checked ? 1 : 0;
        this.getfuntion("put", entTem, "", "entregas", "entregas");
        if (e.target.checked) {
          this.props.setIdEntregaActiva(entTem);
        } else {
          this.props.setIdEntregaActiva({});
        }
        document.cookie =
          "idEntrega=null; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie =
          "destino=null; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie =
          "arribo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie =
          "salida=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "idEntrega=" + item.idEntrega + ";";
        document.cookie = "destino=" + item.destino + ";";
        document.cookie = "arribo=" + item.fechaArribo + ";";
        document.cookie = "salida=" + item.fechaSalida + ";";

        break;
      }
    }
  };
  render() {    
    return (
      <div className="mt-1">        
        <div className="row">
          <div className="col"></div>
          <div className="col">
            <ModalLayout
              tamanio={800}
              title="Nueva"
              name={
                <Button pill size="sm" outline color="primary">
                  <span data-toggle="tooltip"
                          data-placement="top"
                          title={"Crear Nueva Entrega"}>                         
                    <Icon name="plus" />
                  </span>
                </Button>
              }
              hiddenModal={this.state.hiddenModals}
              Fclose={this.hiddenmodal}
              formulario={
                <EntregaFrom
                  accion={"nuevo"}
                  idmovimiento={this.props.obj.id}
                  destinos={this.state.destinos}
                  stor={this.props}
                  getfuntion={this.getfuntion}
                  closeModal={this.hiddenmodal}
                />
              }
            />
          </div>          
          <div className="col">
            <Button pill size="sm" outline color="primary" onClick={() => this.setState({cancelada:!this.state.cancelada})}>
              <Icon name={this.state.cancelada ? "eye" : "eye-off" } />
              {this.state.cancelada ? "Ver Activas" : "Ver Canceladas" }
            </Button>
          </div>            
        </div>
        <div className="col" style={{color: '#0992A7'}}><span>{!this.state.cancelada ? "Entregas Activas" : "Entregas Canceladas"}</span></div>
        {this.state.entregas.filter(e => e.idDestino === 1).map((idDest) => 
          <div className="col" 
              style={{color: 'red'}} 
              data-toggle="tooltip" 
              data-placement="top" 
              title={"Debes seleccionar un destino del catalogo para que la entrega envie eventos logisticos hacia DHL"}>
            <span>La entrega {idDest.destino} no tiene un destino seleccionado del catalogo</span>
          </div>
        )}        
        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter bg-light"
          headerItems={[
            { content: "Destino" },
            { content: "Activa" },

            { content: "Arribo" },
            { content: "Salida" },
            { content: "Editar" },
            { content: "ETAS" },
            { content: "FechaAlta" },
            { content: "DIRECCIÓN" },
          ]}
        >
          <Table.Header></Table.Header>
          <Table.Body>
            {this.state.entregas.filter(e => this.state.cancelada ? (e.canceladaManual || e.canceladaOtm) : (!e.canceladaManual && !e.canceladaOtm))
              .sort(function (a, b) {
                if (a["orden"] === null) {
                  return -1;
                }
                if (a["orden"] > b["orden"]) {
                  return 1;
                }
                if (a["orden"] < b["orden"]) {
                  return -1;
                }
                return 0;
              })
              .map((elemento, index) => (
                <>
                  <Table.Row
                    style={
                      elemento.estatus
                        ? { "background-color": "rgba(95, 231, 50, 0.30)" }
                        : null
                    }
                  >
                    <Table.Col>{elemento.destino}</Table.Col>

                    {
                      <Table.Col>
                        <Form.Switch
                          type="Checkbox"
                          name="toggle"
                          value={elemento.idEntrega}
                          checked={elemento.estatus}
                          onChange={this.changeEstatus}
                        />
                      </Table.Col>
                    }

                    {/* <Table.Col>
                    <Form.Switch type="Checkbox" name="toggle" value={elemento.idEntrega} onChange={ this.changeEstatus }/>
                  </Table.Col> */}

                    <Table.Col>{elemento.fechaArribo}</Table.Col>

                    <Table.Col>{elemento.fechaSalida}</Table.Col>

                    <Table.Col>
                      <ModalLayout
                        tamanio={800}
                        title="Editar"
                        name={
                          <Button pill size="sm" outline color="primary">
                            <Icon name="edit" />
                          </Button>
                        }
                        hiddenModal={this.state.hiddenModals}
                        Fclose={this.hiddenmodal}
                        formulario={
                          <EntregaFrom
                            accion={"modificar"}                            
                            idmovimiento={this.props.obj.id}
                            destinos={this.state.destinos}
                            elementos={elemento}
                            stor={this.props}
                            getfuntion={this.getfuntion}
                            closeModal={this.hiddenmodal}
                          />
                        }
                      />
                    </Table.Col>

                    <Table.Col>
                      <ModalLayout
                        tamanio={1200}
                        title="Eta"
                        name={
                          <Button pill size="sm" outline color="primary">
                            <Icon name="message-circle" />
                            <span>
                              {this.state.etasCalculadas[elemento.idEntrega]
                                ? this.state.etasCalculadas[elemento.idEntrega]
                                : null}
                            </span>
                          </Button>
                        }
                        hiddenModal={this.state.hiddenModals}
                        Fclose={this.hiddenmodal}
                        formulario={
                          <EtaCalculadaTerrestre
                            accion={"modificar"}
                            idEntrega={elemento.idEntrega}
                            destinos={this.state.destinos}                            
                            elementos={elemento}
                            stor={this.props}
                            getfuntion={this.getfuntion}
                            closeModal={this.hiddenmodal}
                          />
                        }
                      />
                    </Table.Col>

                    <Table.Col>{elemento.fechaAlta}</Table.Col>
                    <Table.Col>{elemento.direccion}</Table.Col>
                  </Table.Row>
                </>
              ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
export default Entregas;
