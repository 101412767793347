import React, { useState, useEffect, useRef } from 'react'
import { Grid, Card, Table } from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import { GestionarUnidadTransportista } from '../monitoreoPages/GestionarUnidadTransportistaPage';
import { UnidadMovimientos } from '../monitoreoPages/UnidadMovimiento';
import { TablePageable } from "../../Helpers";

const buttonStyle = {
  margin: '10px 0',
};

const Button = ({ label, handleClick }) => (
  <button
    className="btn btn-default"
    style={buttonStyle}
    onClick={handleClick}
  >
    {label}
  </button>
);

export const UnidadesLt= () => {
  const [unidades, setUnidades] = useState([]);
  const [unidadTrans, setUnidadTrans] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [unidad, setUnidad] = useState({});
  const [unidadGpsFijo, setUnidadGpsFijo] = useState({});
  const [unidadTrack, setUnidadTrack] = useState({});

    useEffect(() => {
    obtenerUnidades();
    }, [])

    useEffect(() => {
      if (unidad.id) {
        setActiveIndex(2);
      }
      }, [unidad.id])

    useEffect(() => {
      if (unidadGpsFijo.id) {
        obtenerGpsFijo();
      }
      }, [unidadGpsFijo.id])

    useEffect(() => {
      if (unidadTrack.id) {
        obtenerTrack();
      }
      }, [unidadTrack.id])

    useEffect(() => {
      if (unidadTrans.id) {
        setActiveIndex(1)
      }
      }, [unidadTrans.id])

    const obtenerGpsFijo = async() =>{
      await getfuntion("get", [], "", "gpsFijos/unidad/"+ unidadGpsFijo.id).then((gps) =>{
        if (gps[0]) {
          setUnidadTrack(gps[0]);
        } else {
          alert("Rastreo no disponible");
        }
       
      })
    }

    const obtenerTrack = async() =>{
      await getfuntion("get",[], "", "terrestreMovimientos/plataforma/"+ unidadTrack.plataforma.idGg +"/gps/"+ unidadTrack.idGg, "").then((track) =>{
        console.log(track);
        window.open(
          track.url,
          "sharer",
          "width=900,height=600,scrollbars=NO"
        );
      })
    }

    const obtenerUnidades = async () => {
        let unit = [...unidades];
        await getfuntion("get", [], "", "unidadLt", "unidades_linea_transportista", "").then((unlt) => {
            unlt.forEach(un => {
                unit.push(un);
            });
        });
        setUnidades(unit);
    }

  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            switch (stateVar) {

              default: return returnVal;
            }
          }
        default: return returnVal;
      }
    })
  }
  return (
    <SiteWrapper>
      <Panel isActive={activeIndex === 0} onShow={() => setActiveIndex(0)}>
        <Grid.Col>
          <br/>
          <div style={{ width: "80%", margin: "auto" }}>
          {
                unidades.length > 1 ?
          <TablePageable
            titulo={ <h3> Listado de unidades </h3> }
            lista= { unidades }
            columnas={
              [
                { columna: "transportista", label: "transportista" },
                { columna: "unidad", label: "Unidad" },
                { columna: "estatus", label: "Estatus" },
                { columna: "placa", label: "Placa" }, 
                { 
                  columna: "operacion",
                  label: "Operación",
                  enClick: (e) => {if(e.operacion !== 'EXTERNO')setUnidad(e)}, 
                  enClickDisabled: (e)=> e.operacion === 'EXTERNO' ? true: false,
                  form: (e)=> e.operacion !== 'DHL' && e.operacion !== 'Pendiente' ? e.operacion : <input type="image" src="images/official_dhllogo.png" alt="Submit" width="70" height="30"/>
                },
                {
                  columna: "id",
                  label: "Transportistas",
                  enClick: (e) => { setUnidadTrans(e) },
                  enClickDisabled: (e) => false,
                  form: (e)=> <span style={{fontSize: "216%", paddingLeft: "48px"}} className="fa fa-edit"></span>
                },
                {
                  columna: "id",
                  label: "Rastreame",
                  enClick: (e) => { setUnidadGpsFijo(e); setUnidadTrack({}) },
                  enClickDisabled: (e) => false,
                  form: (e)=> <span style={{fontSize: "216%", paddingLeft: "48px"}} className="fa fa-map-marker"></span>
                }
              ]
            }
            id= "id"
          /> :
            <span className='h1 bg-red-lightest' align="center">No hay registro de unidades</span>
          }
          </div>
        </Grid.Col>
      </Panel>
      <Panel isActive={activeIndex === 1} onShow={() => setActiveIndex(1)}>
        <div style={{ width: "80%", margin: "auto" }}>
          <br/>
          <div style={{
            position: 'absolute',
            right: '0px',
            width: '467px',
            padding: '10px',
            zIndex: '1'
            }}>
            <Button label="Regresar" handleClick={() => {setActiveIndex(0); setUnidadTrans({})}}/>
          </div>
          <br/>
          <GestionarUnidadTransportista unidad = {unidadTrans.id}/>
        </div> 
      </Panel>
      <Panel isActive={activeIndex === 2} onShow={() => setActiveIndex(2)}>
        <div>
          <div style={{
            position: 'absolute',
            right: '0px',
            width: '318px',
            padding: '10px',
            zIndex: '2'
            }}>
            <Button label="Regresar" handleClick={() => {setActiveIndex(0); setUnidad({})}}/>
          </div>
          <br/>
          <br/>
          <br/>
          <UnidadMovimientos unidad = {unidad.id}/>
        </div> 
      </Panel>
    </SiteWrapper>
  )
}
function Panel({
  children,
  isActive,
  onShow
}) {
  return (
    <>
      {isActive ? (
        <>{children}</>
      ) : ""}
    </>
  );
}
export default React.memo(UnidadesLt);