import React, { Component, useState, useEffect } from "react";
import { Page, Card, Grid, Form, Button, Dropdown, Table } from "tabler-react";
import Select from "react-select";
import axios from 'axios';
import Modal from 'react-modal';
import * as conf from "../../config/config";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import ModalLayout from "../../components/ModalLayout.react";
import { timeToDateFill } from "../../herramientas/DateFormat";
import moment from "moment";
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));


export const TableroRetransmisionesForm = (props) => {
    
  
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [fechaInicioM, setFechaInicioM] = useState("");
  const [fechaFinM, setFechaFinM] = useState("");

  const [total_movimientos, setTotal_movimientos] = useState("0");
  const [total_eventos, setTotal_eventos] = useState("0");
  const [ke, setKe] = useState("0");
  const [kt, setKt] = useState("0");
  const [kl, setKl] = useState("0");
  const [ks, setKs] = useState("0");
  const [respOculto, setRespOculto] = useState(true)
  
  

  let instance = axios.create();
  let tokenStore = "tok";


  useEffect(() => {

        cambiaFechaInicio(moment().subtract(1,"d").format("YYYY-MM-DD"))
        cambiaFechaFin(moment().format("YYYY-MM-DD"))
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
               

  },[sessionStorage.getItem(tokenStore)])
    
  const cambiaFechaInicio=(fecha)=>{
   
    setFechaInicio(fecha+" 00:00:00");
    setFechaInicioM(fecha);
    
  }
  
  const cambiaFechaFin=(fecha)=>{
    
    setFechaFin(fecha+" 23:59:59");
    setFechaFinM(fecha);
    
  }

  const muestraResultados=(event)=>{
    getfuntion("get", [], "", "resumenEventosKpi/agrupado/fecha1/"+fechaInicio+"/fecha2/"+fechaFin, "eventosAgrupados").then((agrupado) => {
      
      setTotal_eventos(agrupado.total_eventos);
      setKe(agrupado.ke);
      setKt(agrupado.kt);
      setKs(agrupado.ks);
      setKl(agrupado.kl);
    
    });
    getfuntion("get", [], "", "resumenEventosKpi/movTotales/fecha1/"+fechaInicio+"/fecha2/"+fechaFin, "eventosAgrupados").then((total) => {
      
      setTotal_movimientos(total);
    });
    setRespOculto(false);
    // const [total_movimientos, setTotal_movimientos] = useState("0");
  }
  
    
    const getfuntion = (metodo="get", obj=[], id="", catalogo="resumenEventosKpi", stateVar= "columnas", hiddenModl="") =>{
      
      return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {

            /*** filtro de variables  */
            
            switch (metodo) {
              case "post":
              {
                   
              }
              break;
              case "get":
              {
            
                if (stateVar === "eventosAgrupados") { return returnVal; }
              }
        
              break;
      
              default:
              break;
            }
                
          }).catch(err =>{
              console.log(err);
            })
      }
    
      
    
      
    
        return (
            <Page.Card>
                <h1>Tablero de Retransmisiones por Rango de Fechas</h1>
                <div className="row" style={{verticalAlign: "middle"}}>
                <div className="col col-12 col-sm-12 col-md-4 ">
                <Form.Group label="Fecha de Inicio">
                  <Form.Input type="date" id="fechaInicio" name="fechaInicio" placeholder="AAAA-MM-DD" onChange={(e)=>cambiaFechaInicio(e.target.value)} value={fechaInicioM} />
                </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-4 ">
                <Form.Group label="Fecha Final">
                  <Form.Input type="date" id="fechaFin" name="fechaFin" placeholder="AAAA-MM-DD" onChange={(e)=>cambiaFechaFin(e.target.value)} value={fechaFinM}/>
                </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-4 " >
                  <br/>
                  <Button onClick={()=>muestraResultados()}>Filtrar</Button>
                </div>
                </div>
                
                <Table cards={true} striped={true} responsive={true} className="table-vcenter" hidden={respOculto} >
                  <Table.Body>
                    <Table.Row>
                      <Table.Col><div style={{textAlign: "center", color: "red", fontSize: 20, fontWeight: "bolder"}}>Movimientos Totales</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "red", fontSize: 20, fontWeight: "bolder"}}>Retransmisiones Totales</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "red", fontSize: 20, fontWeight: "bolder"}}>Eventos KE</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "red", fontSize: 20, fontWeight: "bolder"}}>Eventos KT</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "red", fontSize: 20, fontWeight: "bolder"}}>Eventos KL</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "red", fontSize: 20, fontWeight: "bolder"}}>Eventos KS</div></Table.Col>
                    </Table.Row>
                    
                    <Table.Row>
                      <Table.Col><div style={{textAlign: "center", color: "#FF9900", fontSize: 18, fontWeight: "bolder"}}>{total_movimientos}</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "#FF9900", fontSize: 18, fontWeight: "bolder"}}>{total_eventos}</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "#FF9900", fontSize: 18, fontWeight: "bolder"}}>{ke}</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "#FF9900", fontSize: 18, fontWeight: "bolder"}}>{kt}</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "#FF9900", fontSize: 18, fontWeight: "bolder"}}>{kl}</div></Table.Col>
                      <Table.Col><div style={{textAlign: "center", color: "#FF9900", fontSize: 18, fontWeight: "bolder"}}>{ks}</div></Table.Col>

                    </Table.Row>                    
                  </Table.Body>
                </Table>
                
                <br/>
            </Page.Card>
        );
    
}

export default TableroRetransmisionesForm;
