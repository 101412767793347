import React, { Component } from "react";
import { Page, Card, Icon, Form, Button, Dropdown, Table } from "tabler-react";
import axios from 'axios';
import Modal from 'react-modal';
import * as conf from "../../config/config";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import ModalLayout from "../../components/ModalLayout.react";
import DatosMovimientoGpsForm from "./DatosMovimientoGpsForm";
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    border                : '0px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    'background-color': 'rgba(0,0,0,0.5)'
  }
};

var user= JSON.parse(sessionStorage.getItem("usuario"));
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
//console.log("Valores "+JSON.stringify(user.id));



class MovimientoGpsForm extends Component {
    constructor() {
        super();
        this.state = {
            plataformas:[],
            tabla_movimiento: false,
            movimientoSinGps: [],
            poMovimiento: "",
            hiddenModals: false,
            idUsuario: "",
            plataformaid: 0,
            search:"",
            movimientos: [],
            tableheaderItems: [],
            colToFilter : "po", //columna a filtrar
            asignados: false
        };
        this.colToFilter= "po";
        this.content = [];
    }

    componentWillMount(){
        /*for (let index = 0; index < currentUser.length; index++) {
            //this.state.ids.push(currentUser[index].usuario.id);
            //this.state.plataformas.push(currentUser[index].plataforma.nombre);
            if(currentUser[index].usuario.id != null){
                //console.log("valores DATOS-> "+currentUser[index].usuario.id);
                let instance = axios.create();
                var tokenStore="tok";
                instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
                instance.defaults.headers.common['Content-Type'] = 'application/json';
                instance.defaults.headers.common['Accept'] = 'application/json';
                axios.get(conf.api_raiz+"pglobalMobile/movimientos_sin_gps_total").then(res => {
                    const plataformas = res.data
                    this.setState({plataformas})
                
                
                });
            }
            
        }*/

        this.setState({idUsuario: JSON.stringify(user.id)});

        Crud_Catalogos("globalMobile/movimientos_sin_gps_total", "movimientosgps", "get", "", [], "", this.state.plataformas, "", []).then((returnVal) => {
            this.setState({ plataformas: returnVal });
      
        }).catch(err => {
            if (err.response) {
                console.log(err);
            } else {
                
            }
        });
        this.table_headers(true);
    }

    handleChange = (event) =>{
        this.setState({plataformaid: parseInt( event.target.value, 10)});
        //console.log("PLATAFORMA SELECCIONADA "+event.target.value);
        Crud_Catalogos("globalMobile/movimientos_sin_gps_x_plataforma/"+event.target.value, "movimientosgps", "get", "", [], "", this.state.plataformas, "", []).then((returnVal) => {
            this.setState({ movimientoSinGps: returnVal });
      
        }).catch(err => {
            if (err.response) {
                console.log(err);
            } else {
                
            }
        });
        this.setState({tabla_movimiento: true});
    }

    hiddenmodal = () => {
        this.setState({ hiddenModals: !this.state.hiddenModals });
    }
    searching =(event) =>{ 
        this.setState({ search: event.target.value });
    }

    getfuntion = (metodo = "get", obj = [], id = "", catalogo = "cliente", stateVar = "clientes", hiddenModl = "") => {
        return Crud_Catalogos(catalogo, "movimientoGps", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
          /**
           * filtro de variables
           */
          switch (metodo) {
            case "get":
              {
                if (stateVar === "movimientos") {
                    this.setState({ movimientos: returnVal });
                }
                if (stateVar === "movimiento") { return returnVal; }
                if (stateVar === "movimientosPatio") { return returnVal; }
                if (stateVar === "movimientosPatioF") { return returnVal; }
              }
              break;
            case "post":
              {
                if (stateVar === "removerActivo") {
                  if(returnVal.OK ){
                    NotificationManager.info("GPS: "+ obj.gps, "Gps removido");
                  }
                  if( returnVal.Error ){
                  NotificationManager.error("GPS: "+ obj.gps, returnVal.Error);
                  }
                  return returnVal;
                }
              }
              break;
            case "put":
              {
              }
              break;
            default:
              break;
          }
        }).catch(err => { console.log(err) });
      }
      refrescar=()=>{
        Crud_Catalogos("globalMobile/movimientos_sin_gps_x_plataforma/"+this.state.plataformaid, "movimientosgps", "get", "", [], "", "", "", []).then((returnVal) => {
          this.setState({ movimientoSinGps: returnVal });
        }).catch(err => {
            if (err.response) {
                console.log(err);
            } else {
                
            }
        });
      }
      reset=(id)=>{
        let movimientoT= [];
        let mPatio1=[];
        console.log("=> "+ id)
        this.getfuntion("get", [], "", "navieraMovimientos/"+id, "movimiento").then((movNav) => {
            movimientoT= movNav;
            //obtener full
            this.getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+id, "movimientosPatio").then((mPatio) => {
              mPatio1= mPatio;
              if( mPatio.esFull ){
                this.getfuntion("get", [], "", "navieraMovimientos/"+mPatio.esFull, "movimientoFull").then((movNavFull) => {
                  for(let item of movNavFull.movimientoGps ){
                    //remover gps de full
                    var recGps={gps: item.gps.id , plataforma: movNavFull.idPlataforma.id, estatus: 1, idMovimiento: item.idMovimiento}
                    this.getfuntion("post", recGps, "", "gps/remover_activo", "removerActivo").then((rmGps) => {});
                  }
                  //reset patio
                  this.getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+movNavFull.id, "movimientosPatioF").then((mPatioFull) => {
                    this.getfuntion("delete", "", mPatioFull.id, "movimientosPatio", "movimientosPatio");
                  });                
                });
              }
              //remover patio
              this.getfuntion("delete", "", mPatio1.id, "movimientosPatio", "movimientosPatio");
            });
          //remover gps
          for(let item of movimientoT.movimientoGps ){
            var recGps={gps: item.gps.id , plataforma: movimientoT.idPlataforma.id, estatus: 1, idMovimiento: item.idMovimiento}
            this.getfuntion("post", recGps, "", "gps/remover_activo", "removerActivo").then((rmGps) => {});
          }
          
        }); 
      }
      removerGps =(gps, mov)=>{
        var recGps={gps: gps.gps.id , plataforma: mov.idPlataforma.id, estatus: 1, idMovimiento: gps.idMovimiento}
        confirmAlert({
          title: 'Antención',
          message: '¿Esta seguro de remover el gps: '+gps.gps.nombre+'( del mov: '+gps.idMovimiento+') ?',
          buttons: [
            {
              label: 'Confirmar',
              onClick: () => { this.getfuntion("post", recGps, "", "gps/remover_activo", "removerActivo"); }
            },
            {
              label: 'Cancelar',
              onClick: () => {return false}
            }
          ]
        });
      }

    render() {
        return (
                <div className="row">
                  <div className="col col-12 col-sm-12 col-md-6 ">
                    <Form.Group label="Selecciona la Plataforma">
                      <Form.Select onChange={this.handleChange}>
                        <option value={0}>Seleccione una Opción</option>
                          { this.state.plataformas.map((plataforma, index) =>
                            <option value={plataforma.idPlataformas}>{plataforma.plataforma}</option>
                          )}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group label="Filtrar por ">
                      <Form.Input className="mb-3" icon="search" placeholder={this.state.colToFilter} position="append" value={this.state.search} onChange={this.searching} disabled={this.state.movimientoSinGps.length<=0}/>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group label="Refrescar">
                      <Button pill size="sm" outline color="primary" onClick={ ()=> this.refrescar() } ><Icon name="refresh-cw" /></Button> 
                    </Form.Group>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-12 ">
                    <h3><Form.Switch type="Checkbox" checked={this.state.asignados} onChange={ this.changeMovs } />{(this.state.asignados?"Movimientos con GPS asignados" :"Movimientos a asignar GPS")+", count:"+ this.state.movimientoSinGps.filter(word =>
                                          (this.state.asignados? (word.gps || ( !word.gps && word.esfull )): ( !word.gps && !word.esfull )) &&
                                          ( !Number.isInteger(word[this.colToFilter])? //Si se filtra texto
                                              this.getVal(word[this.colToFilter]).toUpperCase().includes(this.state.search.toUpperCase())
                                            : this.state.search !=""?
                                                parseInt(word[this.colToFilter], 10) === parseInt(this.state.search, 10)? true : false //Si se filtra numero o boolean
                                            : true
                                          )
                                          ).sort().length}</h3>
                    { this.state.tabla_movimiento && this.state.movimientoSinGps.length > 0 && this.state.tableheaderItems.length?
                    <Table cards={false} striped={false} responsive={true} className="table-vcenter" headerItems={this.state.tableheaderItems}>
                        <Table.Header></Table.Header>
                        {/*<Table.Header align="center">
                        <Table.ColHeader>ID MOVIMIENTO</Table.ColHeader>
                        <Table.ColHeader>GPS</Table.ColHeader>
                        <Table.ColHeader>PO</Table.ColHeader>
                        <Table.ColHeader>CONTENEDOR</Table.ColHeader>
                        <Table.ColHeader>CITA</Table.ColHeader>
                        <Table.ColHeader>TRANSPORTISTA</Table.ColHeader>
                        <Table.ColHeader>CORREDOR</Table.ColHeader>
                        <Table.ColHeader>MOVIMIENTO</Table.ColHeader>
                        <Table.ColHeader>AGREGAR GPS</Table.ColHeader>
                        </Table.Header>*/}
                        <Table.Body>
                        { this.state.movimientoSinGps.filter(word =>
                                          (this.state.asignados? (word.gps || ( !word.gps && word.esfull )): ( !word.gps && !word.esfull )) && 
                                          ( !Number.isInteger(word[this.colToFilter])? //Si se filtra texto
                                              this.getVal(word[this.colToFilter]).toUpperCase().includes(this.state.search.toUpperCase())
                                            : this.state.search !=""?
                                                parseInt(word[this.colToFilter], 10) === parseInt(this.state.search, 10)? true : false //Si se filtra numero o boolean
                                            : true
                                          )
                                          ).sort().map((movimiento, index) =>
                            <Table.Row align="center" style={ movimiento.gps ? { "background-color": "rgba(95, 231, 50, 0.30)" } :  movimiento.programado ? { "background-color": "rgba(38, 229, 242, 0.3)" }: null}>
                                <Table.Col>
                                    {movimiento.idMovimiento}
                                </Table.Col>
                                <Table.Col>
                                    { movimiento.gps? 
                                        movimiento.gps
                                      : movimiento.esfull?
                                          "full con: "+movimiento.esfull
                                        : movimiento.programado ? "programado": ""
                                    }
                                </Table.Col>
                                <Table.Col>
                                    {movimiento.po}
                                </Table.Col>
                                <Table.Col>
                                    {movimiento.contenedor}
                                </Table.Col>
                                <Table.Col>
                                    {movimiento.cita}
                                </Table.Col>
                                <Table.Col>
                                    {movimiento.transportista}
                                </Table.Col>
                                <Table.Col>
                                    {movimiento.corredor}
                                </Table.Col>
                                <Table.Col>
                                    {movimiento.movimientoTipo}
                                </Table.Col>
                                <Table.Col >
                                    <ModalLayout tamanio={"99%"} title="GPS Movimiento"
                                        name={<Button pill color="azure"><i className="fa fa-plus"></i></Button>}
                                            hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                            formulario={<DatosMovimientoGpsForm movimiento={movimiento} idMovimiento={movimiento.idMovimiento} userId={this.state.idUsuario} hiddenmodal={this.hiddenmodal} plataformaid={this.state.plataformaid} refrescar={this.refrescar } />}
                                        />
                                </Table.Col>
                                <Table.Col><Button onClick={() => this.reset(movimiento.idMovimiento) } color="danger">resetear</Button> </Table.Col>
                            </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    : null}
                  </div>
                </div>
        );
    }
    
    getVal=(col)=>{ if(col){ return col;}else{ return "";} }
    table_headers = (asc) => {
      this.content = [];
      columnas.map((col, index) => {
        this.content.push(
          { content: <div onClick={() => {this.sortElements( col.nombre)} }> {col.label} {this.colToFilter === col.nombre? <Icon name={asc?"chevron-up":"chevron-down"}/> : null}</div>  }
        );
      });
      this.setState({ tableheaderItems: this.content });
    }
    sortElements=(columna) =>{
      let oldCol= this.colToFilter;
      if( this.colToFilter == columna){ this.AscDesc= !this.AscDesc; }else{ this.AscDesc= true; }
      this.colToFilter= columna;
      this.setState({ colToFilter: columna});
      if(oldCol == columna){ this.table_headers(this.AscDesc); }else{ this.table_headers(true); }
  
      var originalArray= this.state.movimientoSinGps;
      if(!originalArray[0]){ return; }
      if( this.AscDesc){
  
        originalArray= originalArray.sort(function(a,b) {
          let columnaA= a[columna]? a[columna]: "";
          let columnaB= b[columna]? b[columna]: "";
            if (columnaA > columnaB) {
              return 1;
            }
            if (columnaA < columnaB) {
              return -1;
            }
            return 0;
        });
          //return a[columna]-b[columna] }); //Asecendente
      }else{
          originalArray= originalArray.sort(function(a,b) {
            let columnaA= a[columna]? a[columna]: "";
            let columnaB= b[columna]? b[columna]: "";
            //if(a[columna] === null ){ return -1; }
            if (columnaA < columnaB) {
              return 1;
            }
            if (columnaA > columnaB) {
              return -1;
            }
            return 0;
          });
          //return b[columna]-a[columna] }); //Descendente
      }
      this.setState({ movimientoSinGps: originalArray });
    }
    changeMovs=(event)=>{this.setState({asignados: event.target.checked})}
}
let columnas= [ {nombre: "idMovimiento", label: "ID MOVIMIENTO" },
                {nombre: "gps", label: "GPS" },
                {nombre: "po", label: "PO" },
                {nombre: "contenedor", label: "CONTENEDOR" },
                {nombre: "cita", label: "CITA" },
                {nombre: "transportista", label: "TRANSPORTISTA" },
                {nombre: "corredor", label: "CORREDOR" },
                {nombre: "movimientoTipo", label: "TIPO DE MOVIMIENTO" },
                {nombre: "add", label: "AGREGAR GPS" },
                {nombre: "reset", label: "Resetear" }
              ];

export default MovimientoGpsForm;
