import React, { useState, useEffect, useRef } from 'react';
import { Grid, Card, Table, Form } from "tabler-react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import { TablePageable } from "../../Helpers";
import TableroVisibilidadDhlPage from "../dashboards/TableroVisibilidadDhlPage.react";

export const UnidadMovimientos = (unidad) =>{
  const [movimiento, setMovimiento] = useState([]);
  const [activo, setActivo] = useState({});
    
  useEffect(() => {
    obtenerMovimiento();
    },[])

  const obtenerMovimiento = async() =>{
    let movi = [...movimiento];
    await getfuntion("get", [], "", "terrestreMovimientos/unidad/"+unidad.unidad, "movimientos", "").then((mov) => {
        mov.forEach(m => {
          movi.push(m);
        });
    });
    setMovimiento(movi);
  }


  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            switch (stateVar) {

              default: return returnVal;
            }
          }
        default: return returnVal;
      }
    })
  }
    return (
    <Grid.Col>
      <br/>
      <>
      { 
        movimiento.length > 0 ?
          <TableroVisibilidadDhlPage mov={ movimiento[0].id } />    
        :
        <span className='h1 bg-red-lightest' align="center">No hay movimiento disponible</span>
      }
      </>
    </Grid.Col>
  )

}
export default React.memo(UnidadMovimientos);