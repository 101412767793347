import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card } from "tabler-react";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
var user= JSON.parse(sessionStorage.getItem("usuario"));

class ActualizarHorariosNavieros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      camposLogisticos:[],
                       po: "",
             rutasEstatus: [],
                     ruta: { value: 0, label: "Selecciona Estatus de ruta"  },
                  cliente: { value: 0, label: "Selecciona un cliente"  },
                 clientes: [],
                     otds: [],
                      otd: { value: 0, label: "Selecciona Otd"  },
                    sello: "",
                  booking: "",
               contenedor: "",
           citaProgramada: "",
      /**Exportaciones */
         retiroContenedor: "",
       arriboClienteVacio: "",
       salidaClienteLleno: "",
    arriboTerminalCargado: "",
        salidaPredioVacio: "",
        citaFechaDespacho: "",
   entradaCiudadPortuaria: "",
         arriboPatioLinea: "",
      salidaTerminalVacio: "",
      /**Importaciones */
      arriboTerminalVacio: "",
      salidaTerminalLleno: "",
       arriboClienteLleno: "",
       salidaClienteVacio: "",
     arriboTerminalVacio2: "",
         asignacionPiloto:"",
               inicioRuta:"",
    despachoPredioCliente:"",
          llegadaFrontera:"",
           salidaFrontera:"",
             arriboAduana:"",
             salidaAduana:"",
           actualizarFull:0,
                 accion:this.props.accion, //'nuevo'// modificar, nuevo
               estaEnfull:false
    };
    
    //this.enableSave = this.enableSave.bind(this);
    this.camposLogisticos= [];
            this.clientes= [];
       this.campLogistObj= {
      "idOperacionEstatus": this.props.movimiento.idOperacionEstatus,
                  "idRuta": this.props.movimiento.idRuta,
          "citaProgramada": this.props.movimiento.citaProgramada,
                   "sello": this.props.movimiento.sello,
                    "po"  : this.props.movimiento.po,
               "booking"  : this.props.movimiento.booking,
              "contenedor": this.props.movimiento.contenedor,
                   "idOtd": this.props.movimiento.idOtd,
               "idCliente": this.props.movimiento.idCliente,
          "actualizarFull": this.props.movimiento.actualizarFull,
        "idMovimientoTipo": this.props.movimiento.idMovimientoTipo,
         "idOperacionTipo": this.props.movimiento.idOperacionTipo,
			           "idRuta2": this.props.movimiento.idRuta2,
      /*Exportacion*/
        "retiroContenedor": this.props.movimiento.retiroContenedor,
      "arriboClienteVacio": this.props.movimiento.arriboClienteVacio,
      "salidaClienteLleno": this.props.movimiento.salidaClienteLleno,
   "arriboTerminalCargado": this.props.movimiento.arriboTerminalCargado,
       "salidaPredioVacio": this.props.movimiento.salidaPredioVacio,
       "citaFechaDespacho": this.props.movimiento.citaFechaDespacho,
  "entradaCiudadPortuaria": this.props.movimiento.entradaCiudadPortuaria,
        "arriboPatioLinea": this.props.movimiento.arriboPatioLinea,
     "salidaTerminalVacio": this.props.movimiento.salidaTerminalVacio,
      /**Importacion */
     "arriboTerminalVacio": this.props.movimiento.arriboTerminalVacio,
     "salidaTerminalLleno": this.props.movimiento.salidaTerminalLleno,
      "arriboClienteLleno": this.props.movimiento.arriboClienteLleno,
      "salidaClienteVacio": this.props.movimiento.salidaClienteVacio,
    "arriboTerminalVacio2": this.props.movimiento.arriboTerminalVacio2,
        "asignacionPiloto": this.props.movimiento.asignacionPiloto,
              "inicioRuta": this.props.movimiento.inicioRuta,
   "despachoPredioCliente": this.props.movimiento.despachoPredioCliente,
         "llegadaFrontera": this.props.movimiento.llegadaFrontera,
          "salidaFrontera": this.props.movimiento.salidaFrontera,
            "arriboAduana": this.props.movimiento.arriboAduana,
            "salidaAduana": this.props.movimiento.salidaAduana
    };
    this.movimiento= [];
  }
  componentWillMount = () => {
    if(this.props.camposLogisticos){
      this.setState({ camposLogisticos: this.props.camposLogisticosNav });
    }else{
      let movTipoTemp= this.props.movimiento.idMovimientoTipo.id;
        if(movTipoTemp === 3){ movTipoTemp= 1 }
        if(movTipoTemp === 4){ movTipoTemp= 2 }
      this.getfuntion("get", "", "", "camposLogisticos/plataforma/"+this.props.movimiento.idPlataforma.id+"/mtipo/"+movTipoTemp,"camposLogisticos");
    }

    if(this.props.movimiento.idCliente){
      let nombre= this.props.movimiento.idCliente.nombre;
      this.getfuntion("get","","","cliente/plataforma/"+this.props.movimiento.idPlataforma.id+"/cliente/"+nombre+"/pagina/0/total/100", "clientes");
    }else{
      this.getfuntion("get","","","cliente/plataforma/"+this.props.movimiento.idPlataforma.id+"/pagina/0/total/100","clientes");
    }
    this.getfuntion("get","","","otd/plataforma/"+this.props.movimiento.idPlataforma.id,"otd");
    this.getfuntion("get","","","rutasEstatus/plataforma/"+this.props.movimiento.idPlataforma.id,"rutasEstatus");
  }
  componentWillUpdate = ()=>{
    if(this.movimiento !== this.props.movimiento){
      this.movimiento =  this.props.movimiento;
      this.setState({ id                    : this.props.movimiento.id });
      this.setState({ po                    : this.props.movimiento.po });
      this.setState({ booking               : this.props.movimiento.booking });
      this.setState({ ruta                  : {value: this.props.movimiento.idRuta.id, label: this.props.movimiento.idRuta.nombre } });
      this.setState({ cliente               : {value: this.props.movimiento.idCliente.id, label: this.props.movimiento.idCliente.nombre } });
      this.setState({ otd                   : {value: this.props.movimiento.idOtd.id, label: this.props.movimiento.idOtd.nombre }  });
      this.setState({ operacionEstatus      : this.props.movimiento.idOperacionEstatus.id });
      this.setState({ actualizarFull        : this.props.movimiento.actualizarFull });
      this.setState({ sello                 : this.props.movimiento.sello });
      this.setState({ contenedor            : this.props.movimiento.contenedor });
      this.setState({citaProgramada         : this.props.movimiento.citaProgramada?               new Date(this.props.movimiento.citaProgramada)          :  "" });

      /** Datos Importacion */
      this.setState({arriboTerminalVacio    : this.props.movimiento.arriboTerminalVacio?          new Date(this.props.movimiento.arriboTerminalVacio)     :  "" });
      this.setState({salidaTerminalLleno    : this.props.movimiento.salidaTerminalLleno?          new Date(this.props.movimiento.salidaTerminalLleno)     :  "" });
      this.setState({arriboClienteLleno     : this.props.movimiento.arriboClienteLleno?           new Date(this.props.movimiento.arriboClienteLleno)      :  "" });
      this.setState({salidaClienteVacio     : this.props.movimiento.salidaClienteVacio?           new Date(this.props.movimiento.salidaClienteVacio)      :  "" });
      this.setState({arriboTerminalVacio2   : this.props.movimiento.arriboTerminalVacio2?         new Date(this.props.movimiento.arriboTerminalVacio2)    :  "" });
      this.setState({asignacionPiloto       : this.props.movimiento.asignacionPiloto?             new Date(this.props.movimiento.asignacionPiloto)        :  "" });
      this.setState({inicioRuta             : this.props.movimiento.inicioRuta?                   new Date(this.props.movimiento.inicioRuta)              :  "" });
      this.setState({despachoPredioCliente  : this.props.movimiento.despachoPredioCliente?        new Date(this.props.movimiento.despachoPredioCliente)   :  "" });
      this.setState({llegadaFrontera        : this.props.movimiento.llegadaFrontera?              new Date(this.props.movimiento.llegadaFrontera)         :  "" });
      this.setState({salidaFrontera         : this.props.movimiento.salidaFrontera?               new Date(this.props.movimiento.salidaFrontera)          :  "" });
      this.setState({arriboAduana           : this.props.movimiento.arriboAduana?                 new Date(this.props.movimiento.arriboAduana)            :  "" });
      this.setState({salidaAduana           : this.props.movimiento.salidaAduana?                 new Date(this.props.movimiento.salidaAduana)            :  "" });
      /* Datos Exportacion */
      this.setState({retiroContenedor       : this.props.movimiento.retiroContenedor?             new Date(this.props.movimiento.retiroContenedor)        :  "" });
      this.setState({salidaClienteLleno     : this.props.movimiento.salidaClienteLleno?           new Date(this.props.movimiento.salidaClienteLleno)      :  "" });
      this.setState({arriboClienteVacio     : this.props.movimiento.arriboClienteVacio?           new Date(this.props.movimiento.arriboClienteVacio)      :  "" });
      this.setState({arriboTerminalCargado  : this.props.movimiento.arriboTerminalCargado?        new Date(this.props.movimiento.arriboTerminalCargado)   :  "" });
      this.setState({salidaPredioVacio      : this.props.movimiento.salidaPredioVacio?            new Date(this.props.movimiento.salidaPredioVacio)       :  "" });
      this.setState({citaFechaDespacho      : this.props.movimiento.citaFechaDespacho?            new Date(this.props.movimiento.citaFechaDespacho)       :  "" });
      this.setState({entradaCiudadPortuaria : this.props.movimiento.entradaCiudadPortuaria?       new Date(this.props.movimiento.entradaCiudadPortuaria)  :  "" });
      this.setState({arriboPatioLinea       : this.props.movimiento.arriboPatioLinea?             new Date(this.props.movimiento.arriboPatioLinea)        :  "" });
      this.setState({salidaTerminalVacio    : this.props.movimiento.salidaTerminalVacio?          new Date(this.props.movimiento.salidaTerminalVacio)     :  "" });
      this.getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+this.props.movimiento.id, "estaEnfull");
    }
  }
  componentDidMount = () => {
    this.setState({ id                    : this.props.movimiento.id });
    this.setState({ po                    : this.props.movimiento.po });
    this.setState({ booking               : this.props.movimiento.booking });
    this.setState({ ruta                  : {value: this.props.movimiento.idRuta.id, label: this.props.movimiento.idRuta.nombre } });
    this.setState({ cliente               : {value: this.props.movimiento.idCliente.id, label: this.props.movimiento.idCliente.nombre } });
    this.setState({ otd                   : {value: this.props.movimiento.idOtd.id, label: this.props.movimiento.idOtd.nombre }  });
    this.setState({ operacionEstatus      : this.props.movimiento.idOperacionEstatus.id });
    this.setState({ actualizarFull        : this.props.movimiento.actualizarFull });
    this.setState({ sello                 : this.props.movimiento.sello });
    this.setState({ contenedor            : this.props.movimiento.contenedor });
    this.setState({citaProgramada         : this.props.movimiento.citaProgramada?               new Date(this.props.movimiento.citaProgramada)          :  "" });

    /** Datos Importacion */
    this.setState({arriboTerminalVacio    : this.props.movimiento.arriboTerminalVacio?          new Date(this.props.movimiento.arriboTerminalVacio)     :  "" });
    this.setState({salidaTerminalLleno    : this.props.movimiento.salidaTerminalLleno?          new Date(this.props.movimiento.salidaTerminalLleno)     :  "" });
    this.setState({arriboClienteLleno     : this.props.movimiento.arriboClienteLleno?           new Date(this.props.movimiento.arriboClienteLleno)      :  "" });
    this.setState({salidaClienteVacio     : this.props.movimiento.salidaClienteVacio?           new Date(this.props.movimiento.salidaClienteVacio)      :  "" });
    this.setState({arriboTerminalVacio2   : this.props.movimiento.arriboTerminalVacio2?         new Date(this.props.movimiento.arriboTerminalVacio2)    :  "" });
    this.setState({asignacionPiloto       : this.props.movimiento.asignacionPiloto?             new Date(this.props.movimiento.asignacionPiloto)        :  "" });
    this.setState({inicioRuta             : this.props.movimiento.inicioRuta?                   new Date(this.props.movimiento.inicioRuta)              :  "" });
    this.setState({despachoPredioCliente  : this.props.movimiento.despachoPredioCliente?        new Date(this.props.movimiento.despachoPredioCliente)   :  "" });
    this.setState({llegadaFrontera        : this.props.movimiento.llegadaFrontera?              new Date(this.props.movimiento.llegadaFrontera)         :  "" });
    this.setState({salidaFrontera         : this.props.movimiento.salidaFrontera?               new Date(this.props.movimiento.salidaFrontera)          :  "" });
    this.setState({arriboAduana           : this.props.movimiento.arriboAduana?                 new Date(this.props.movimiento.arriboAduana)            :  "" });
    this.setState({salidaAduana           : this.props.movimiento.salidaAduana?                 new Date(this.props.movimiento.salidaAduana)            :  "" });
    /* Datos Exportacion */
    this.setState({retiroContenedor       : this.props.movimiento.retiroContenedor?             new Date(this.props.movimiento.retiroContenedor)        :  "" });
    this.setState({salidaClienteLleno     : this.props.movimiento.salidaClienteLleno?           new Date(this.props.movimiento.salidaClienteLleno)      :  "" });
    this.setState({arriboClienteVacio     : this.props.movimiento.arriboClienteVacio?           new Date(this.props.movimiento.arriboClienteVacio)      :  "" });
    this.setState({arriboTerminalCargado  : this.props.movimiento.arriboTerminalCargado?        new Date(this.props.movimiento.arriboTerminalCargado)   :  "" });
    this.setState({salidaPredioVacio      : this.props.movimiento.salidaPredioVacio?            new Date(this.props.movimiento.salidaPredioVacio)       :  "" });
    this.setState({citaFechaDespacho      : this.props.movimiento.citaFechaDespacho?            new Date(this.props.movimiento.citaFechaDespacho)       :  "" });
    this.setState({entradaCiudadPortuaria : this.props.movimiento.entradaCiudadPortuaria?       new Date(this.props.movimiento.entradaCiudadPortuaria)  :  "" });
    this.setState({arriboPatioLinea       : this.props.movimiento.arriboPatioLinea?             new Date(this.props.movimiento.arriboPatioLinea)        :  "" });
    this.setState({salidaTerminalVacio    : this.props.movimiento.salidaTerminalVacio?          new Date(this.props.movimiento.salidaTerminalVacio)     :  "" });
    this.getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+this.props.movimiento.id, "estaEnfull");
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get": {
          if (stateVar === "estaEnfull") { if(returnVal.esFull){ this.setState({ estaEnfull: true }); } }
          if (stateVar === "camposLogisticos") { this.setState({ camposLogisticos: returnVal }); }
          if (stateVar === "clientes"){ this.clientes = [];
            if(returnVal.content.length>0){
              returnVal.content.map((client, index)=>this.clientes.push({ value: client.id, label: client.nombre, data: client }) );
              this.setState({ clientes: this.clientes });
            }else{
              this.setState({ cliente: { value: 0 , label: "No hay clientes" } });
            }
          }
          if (stateVar === "otd"){
            let otdsTemp = [];
            otdsTemp.push({ value: 0, label: "Selecciona Otd"  });
            returnVal.map((ot, index)=>otdsTemp.push({ value: ot.id, label: ot.nombre, data: ot }) );
            this.setState({ otds: otdsTemp  });
          }
          if (stateVar === "rutasEstatus"){
            let estatusRutasTem = [];
            estatusRutasTem.push({ value: 0, label: "Selecciona Estatus de ruta"  });
            returnVal.map((re, index)=>estatusRutasTem.push({ value: re.id, label: re.nombre, data: re }) );
            this.setState({ rutasEstatus: estatusRutasTem  });
          }
        } break;
        case "put": {
          console.log("153 Put this.usuariosLogObject");
          //console.log(this.usuariosLogObject);
        } break;
        default:    break;
      }
    }).catch(err => { console.log(err); });
  }

  changePo                        = (event) => {this.setState({ po:event.target.value});                                        this.campLogistObj.po= event.target.value; }
  changeBooking                   = (event) => {this.setState({ booking:event.target.value });                                  this.campLogistObj.booking= event.target.value; }
  changeRuta                      = (event) => {this.setState({ ruta: event });                                                 this.campLogistObj.idRuta= event.data;            }
  changeCliente                   = (event) => {this.setState({ cliente:event });                                               this.campLogistObj.idCliente=event.data;        }
  changeOtd                       = (event) => {this.setState({ otd:event });                                                   this.campLogistObj.idOtd=event.data;                }
  changeSello                     = (event) => {this.setState({ sello:event.target.value});                                     this.campLogistObj.sello= event.target.value;                   }
  changeContenedor                = (event) => {this.setState({ contenedor:event.target.value});                                this.campLogistObj.contenedor= event.target.value;              }
  changeActFull                   = (event) => {this.setState({ actualizarFull:event.target.checked? 1:0 });                    this.campLogistObj.actualizarFull= event.target.checked? 1:0;   }
  onChangeDate                    = (date)  => {this.setState({ citaProgramada: date });        this.campLogistObj.citaProgramada=          date? timeToDateFill(new Date(date).getTime()): date}
  /** Exportacion */
  onChangeRetiroContenedor        = (date)  => {this.setState({ retiroContenedor:date });       this.campLogistObj.retiroContenedor=        date? timeToDateFill(new Date(date).getTime()): date}
  onChangeSalidaClienteLleno      = (date)  => {this.setState({ salidaClienteLleno:date });     this.campLogistObj.salidaClienteLleno=      date? timeToDateFill(new Date(date).getTime()): date}
  onChangeArriboClienteVacio      = (date)  => {this.setState({ arriboClienteVacio:date });     this.campLogistObj.arriboClienteVacio=      date? timeToDateFill(new Date(date).getTime()): date}
  onChangeArriboTerminalCargado   = (date)  => {this.setState({ arriboTerminalCargado:date });  this.campLogistObj.arriboTerminalCargado=   date? timeToDateFill(new Date(date).getTime()): date}
  onChangeSalidaPredioVacio       = (date)  => {this.setState({ salidaPredioVacio:date });      this.campLogistObj.salidaPredioVacio=       date? timeToDateFill(new Date(date).getTime()): date}
  onChangeCitaFechaDespacho       = (date)  => {this.setState({ citaFechaDespacho:date });      this.campLogistObj.citaFechaDespacho=       date? timeToDateFill(new Date(date).getTime()): date}
  onChangeEntradaCiudadPortuaria  = (date)  => {this.setState({ entradaCiudadPortuaria:date }); this.campLogistObj.entradaCiudadPortuaria=  date? timeToDateFill(new Date(date).getTime()): date}
  onChangeArriboPatioLinea        = (date)  => {this.setState({ arriboPatioLinea:date});        this.campLogistObj.arriboPatioLinea=        date? timeToDateFill(new Date(date).getTime()): date}
  onChangeSalidaTerminalVacio     = (date)  => {this.setState({ salidaTerminalVacio:date });    this.campLogistObj.salidaTerminalVacio=     date? timeToDateFill(new Date(date).getTime()): date}
  /**Importacion */
  onChangeArriboTerminalVacio     = (date)  => {this.setState({ arriboTerminalVacio:date });    this.campLogistObj.arriboTerminalVacio=     date? timeToDateFill(new Date(date).getTime()): date}
  onChangeSalidaTerminalLleno     = (date)  => {this.setState({ salidaTerminalLleno:date });    this.campLogistObj.salidaTerminalLleno=     date? timeToDateFill(new Date(date).getTime()): date}
  onChangeArriboClienteLleno      = (date)  => {this.setState({ arriboClienteLleno:date });     this.campLogistObj.arriboClienteLleno=      date? timeToDateFill(new Date(date).getTime()): date}
  onChangeSalidaClienteVacio      = (date)  => {this.setState({ salidaClienteVacio:date });     this.campLogistObj.salidaClienteVacio=      date? timeToDateFill(new Date(date).getTime()): date}
  onChangeArriboTerminalVacio2    = (date)  => {this.setState({ arriboTerminalVacio2:date });   this.campLogistObj.arriboTerminalVacio2=    date? timeToDateFill(new Date(date).getTime()): date}
  onChangeAsignacionDePiloto      = (date)  => {this.setState({ asignacionPiloto:date });       this.campLogistObj.asignacionPiloto=        date? timeToDateFill(new Date(date).getTime()): date}
  onChangeInicioRuta              = (date)  => {this.setState({ inicioRuta:date });             this.campLogistObj.inicioRuta=              date? timeToDateFill(new Date(date).getTime()): date}
  onChangeDespachoPredioCliente   = (date)  => {this.setState({ despachoPredioCliente:date });  this.campLogistObj.despachoPredioCliente=   date? timeToDateFill(new Date(date).getTime()): date}
  onChangeLlegadaFrontera         = (date)  => {this.setState({ llegadaFrontera:date });        this.campLogistObj.llegadaFrontera=         date? timeToDateFill(new Date(date).getTime()): date}
  onChangeSalidaFrontera          = (date)  => {this.setState({ salidaFrontera:date });         this.campLogistObj.salidaFrontera=          date? timeToDateFill(new Date(date).getTime()): date}
  onChangeArriboAduana            = (date)  => {this.setState({ arriboAduana:date });           this.campLogistObj.arriboAduana=            date? timeToDateFill(new Date(date).getTime()): date}
  onChangeSalidaAduana            = (date)  =>{this.setState({ salidaAduana:date });            this.campLogistObj.salidaAduana=            date? timeToDateFill(new Date(date).getTime()): date}
                        //this.getfuntion("get", "",               ""  , "cliente/plataforma/"+this.props.movimiento.idPlataforma.id+"/pagina/0/total/100","clientes");
  crud_put  = () => this.props.getfuntion("put", this.campLogistObj, this.props.movimiento, "navieraMovimientos/updateFechas/" + this.props.movimiento.id, "updateFechas");
  //crud_put  = () => {console.log("put => "); console.log(this.campLogistObj);};
  terminarMovimiento =()=>{
    let opEstatus= this.props.stor.operacionesEstatus;
    for( let item of opEstatus ){
      if(item.nombre.toUpperCase().includes("TERMINADO") ){
        this.campLogistObj.idOperacionEstatus= item;
      }
    }
    confirmAlert({
      title: 'Antención',
      message: '¿Esta seguro de terminar el movimiento('+this.props.movimiento.id+') ?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { this.crud_put() }
        },
        {
          label: 'Cancelar',
          onClick: () => {return false}
        }
      ]
    });
  }


  getCamposLogisticos = () =>{
    let camposLogist= [];
    if(!this.state.camposLogisticos){return;}
    this.state.camposLogisticos.map((cLog, index)=>{
      switch (cLog.nombreCampo) {
        /**
         * Importacion
         */
        case "arribo_terminal_vacio":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboTerminalVacio }  onChange={this.onChangeArriboTerminalVacio} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "salida_terminal_lleno":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.salidaTerminalLleno }  onChange={this.onChangeSalidaTerminalLleno} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "arribo_cliente_lleno":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboClienteLleno }  onChange={this.onChangeArriboClienteLleno} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "salida_cliente_vacio":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.salidaClienteVacio }  onChange={this.onChangeSalidaClienteVacio} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "arribo_terminal_vacio_2":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboTerminalVacio2 }  onChange={this.onChangeArriboTerminalVacio2} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "asignacion_piloto":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.asignacionPiloto }  onChange={this.onChangeAsignacionDePiloto} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "inicio_ruta":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.inicioRuta }  onChange={this.onChangeInicioRuta} showLeadingZeros={false} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "despacho_predio_cliente":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.despachoPredioCliente }  onChange={this.onChangeDespachoPredioCliente} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "llegada_frontera":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.llegadaFrontera }  onChange={this.onChangeLlegadaFrontera} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "salida_frontera":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.salidaFrontera }  onChange={this.onChangeSalidaFrontera} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "arribo_aduana":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboAduana }  onChange={this.onChangeArriboAduana} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "salida_aduana":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.salidaAduana }  onChange={this.onChangeSalidaAduana} />  </Form.Group>
          </Grid.Col>);
        } break;
        /**
         * Exportacion
         */
        case "retiro_contenedor":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.retiroContenedor }  onChange={this.onChangeRetiroContenedor} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "arribo_cliente_vacio":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboClienteVacio }  onChange={this.onChangeArriboClienteVacio} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "salida_cliente_lleno":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.salidaClienteLleno }  onChange={this.onChangeSalidaClienteLleno} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "arribo_terminal_cargado":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboTerminalCargado }  onChange={this.onChangeArriboTerminalCargado} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "salida_predio_vacio":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.salidaPredioVacio }  onChange={this.onChangeSalidaPredioVacio} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "cita_fecha_despacho":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.citaFechaDespacho }  onChange={this.onChangeCitaFechaDespacho} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "entrada_ciudad_portuaria":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.entradaCiudadPortuaria }  onChange={this.onChangeEntradaCiudadPortuaria} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "arribo_patio_linea":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboPatioLinea }  onChange={this.onChangeArriboPatioLinea} />  </Form.Group>
          </Grid.Col>);
        } break;
        case "salida_terminal_vacio":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.salidaTerminalVacio }  onChange={this.onChangeSalidaTerminalVacio} />  </Form.Group>
          </Grid.Col>);
        } break;

        default:
          break;
      }
    })
    return camposLogist;
  }

  _KeyDown_clientes=(e)=> {
    let valid="QWERTYUIOPASDFGHJKLLÑZXCVBNM-_().1234567890ÁÉÍÓÚ";
    let texto="";
    let buscar= true;
    if( valid.toUpperCase().includes(e.key.toUpperCase()) ){
        texto=e.target.value+e.key;
        for(let item of this.state.clientes){
          if( item.label.toUpperCase().includes(texto.toUpperCase()) ){ buscar= false; break; }
        }
    }
    if (e.key !== ' ' && texto && buscar ) {
      this.getfuntion("get","","","cliente/plataforma/"+this.props.movimiento.idPlataforma.id+"/cliente/"+texto+"/pagina/0/total/100", "clientes");
    }
  }
  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Col><h1>{this.props.movimiento.idMovimientoTipo.nombre.toUpperCase()}, Movimiento: {this.props.movimiento.id}</h1> </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={4}> <Form.Group label="PO">               <Form.Input name="po" type="text"    value={this.state.po}         onChange={this.changePo} /> </Form.Group> </Grid.Col>
          <Grid.Col md={4}> <Form.Group label="Booking">          <Form.Input name="booking" type="text" value={this.state.booking}    onChange={this.changeBooking} /> </Form.Group> </Grid.Col>
          <Grid.Col md={4}> <Form.Group label="Sello">            <Form.Input name="sello" type="text"            value={this.state.sello}      onChange={this.changeSello} />  </Form.Group> </Grid.Col>
          <Grid.Col md={4}> <Form.Group label="Contenedor">       <Form.Input name="contenedor" type="text"       value={this.state.contenedor} onChange={this.changeContenedor} /> </Form.Group> </Grid.Col>
          {this.state.estaEnfull?
            <Grid.Col md={4}> <Form.Group label="Actualizar automaticamente movimiento en full">  <Form.Switch name="actualizarFull" checked={this.state.actualizarFull} onChange={this.changeActFull} /> </Form.Group> </Grid.Col>
          : null}
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={4}><Form.Group label="Cita programada">         <DateTimePicker format="y-MM-dd h:mm:ss a" onChange={this.onChangeDate}  value={this.state.citaProgramada} /> </Form.Group> </Grid.Col>
          <Grid.Col md={4}><Form.Group isRequired label="Estatus ruta"> <Select value={this.state.ruta}            onChange={this.changeRuta}    options={this.state.rutasEstatus} /> </Form.Group></Grid.Col>
          <Grid.Col md={4}><Form.Group isRequired label="Cliente">      <Select value={this.state.cliente}         onChange={this.changeCliente} options={ this.state.clientes } isLoading={this.state.clientes.length == 0? true:false} onKeyDown={this._KeyDown_clientes} />     </Form.Group></Grid.Col>
          <Grid.Col md={4}><Form.Group isRequired label="Otd">          <Select value={this.state.otd}             onChange={this.changeOtd}     options={this.state.otds} />          </Form.Group></Grid.Col>
        </Grid.Row>

        <Grid.Row>
          {
            this.getCamposLogisticos()
          }
        </Grid.Row>
        <div className="float-right">
          <span className="badge">
            <Button
              disabled={this.state.ruta.value === 0 || this.state.cliente.value === 0 }
              target="_blank" size="sm" RootComponent="a" color="primary"
              onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
              <span className="badge"><Icon link={true} name="save" />Guardar</span>
            </Button>
          </span>
          <span className="badge">
            <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={() => this.terminarMovimiento() } > <span className="badge"><Icon link={true} name="trash" />Terminar Movimiento</span> </Button>
          </span>
        </div>
      </div>
    );
  }
}
export default ActualizarHorariosNavieros;
