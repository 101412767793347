import React, { Component } from "react";
import { Dimmer, Button, Icon, Card, Table, Grid, Form, Alert } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../herramientas/DateFormat";
import Select from "react-select";
import {NotificationContainer, NotificationManager} from 'react-notifications';
const estilo= {color: "red", size: 100, "border-width": "5px", padding: "0px", "margin-top": "0.5em"};
var contadorCarga= 0;
var disabledButon= false;
class DatosMovimientoGpsForm extends Component {
  constructor(props){
    super(props);
                 this.state =   {   disabledButon: false,
                                    esFull: false,
                                    /**
                                    * MOVIMIENTO PRINCIPAL
                                    */
                                    po1                 :   "",
                                    gpsAgregado         :   [],
                                    gpsList             :   [],
                                    idGps               :   { value: 0, label:"Seleccione un GPS" },
                                    tipoGps             :   { value: 0, label:"Seleccione una opción" },
                                    
                                    tractorModelo       :   "",
                                    tractorMarca        :   "",
                                    tractorColor        :   "",

                                    tractorPlaca        :   "",
                                    remolquePlaca       :   "",//*
                                    idRemolque          :   { value: 0, label:"Seleccione una Opción" },
                                    economicot          :   "",//*

                                    economicor          :   "",//*
                                    
                                    operadorNombre      :   "",
                                    operadorContacto    :   "",
                                    operadorLicencia    :   "",
                                    observaciones       :   "",
                                    /**
                                     * MOVIMIENTO SECUNDARIO
                                     */
                                    po2                 :   "",
                                    idMovFull           :   { value: 0, label:"Seleccione un Movimiento" },
                                    gpsAgregadosFull    :   [],
                                    gpsListFull         :   [],                                    
                                    idGpsFull           :   { value: 0, label:"Seleccione un GPS" },
                                    tipoGpsFull         :   { value: 0, label:"Seleccione una opción" },

                                    remolquePlacaFull       :   "",//*
                                    idRemolqueFull          :   { value: 0, label:"Seleccione una Opción" },
                                    economicorFull           :   "",//*                                    
                                    observacionesFull       :   "",//*

                                    movimientosSinGps   :   [],
                                    gpsGateEtiquetas    :   null,
                                    updateMSG           :   false,
                                                                     
                                };
         this.direccionGPS  =   "";
         this.nivelBateria  =   0;
         this.idMovPrimario =   this.props.idMovimiento;
         this.movimiento= [];
         this.nuevo1= true;
         this.nuevoFull= true;
         
         this.idRemolques=[ { value: 0, label:"Seleccione una Opción", data:[] },
                            { value: 1, label:"PLATAFORMA",         data: {idRemolque: 1, nombre: "PLATAFORMA"} },
                            { value: 2, label:"CHASIS 40",          data: {idRemolque: 2, nombre: "CHASIS 40"} },
                            { value: 3, label:"CHASIS 20",          data: {idRemolque: 3, nombre: "CHASIS 20"} },
                            { value: 4, label:"CHASIS ABATIBLE",    data: {idRemolque: 4, nombre: "CHASIS ABATIBLE"} },
                            { value: 5, label:"TORTON",             data: {idRemolque: 5, nombre: "TORTON"} }
                          ];
        /**
         * Datos viejos
         */
           this.gpsAgregadoOld =   [];
      this.gpsAgregadosFullOld =   [];
        /**
         * Datos nuevos
         */
       this.movimientosPatios =   { "idMovimiento": this.props.idMovimiento, "economicor": "", "economicot": "","idRemolque": { "idRemolque": 0 }, "operadorNombre": "", "operadorContacto": "", "operadorLicencia": "", "tractorModelo": "", "tractorMarca": "", "tractorColor": "", "tractorPlaca": "", "esFull": "", "remolquePlaca": "", "colocacionFecha": "", "observaciones": ""};
   this.movimientosPatiosFull =   { "idMovimiento": 0, "economicor": "", "economicot": "","idRemolque": { "idRemolque": 0 }, "operadorNombre": "", "operadorContacto": "", "operadorLicencia": "", "tractorModelo": "", "tractorMarca": "", "tractorColor": "", "tractorPlaca": "", "esFull": "", "remolquePlaca": "", "colocacionFecha": "", "observaciones": ""};
             this.gpsAgregado =   [];
        this.gpsAgregadosFull =   [];



        this.movimientosGps =   { "idMovimiento": 0, "gps": { "id": 0 }, "gpsTipo": { "id": 0 } };
    this.movimientosGpsFull =   { "idMovimiento": 0, "gps": { "id": 0 }, "gpsTipo": { "id": 0 } };

                 this.gpsGG =   { "gps": 0, "plataforma": 0, "estatus": 0 };
              this.gpsTipos =   [{ value: 0, label:"Seleccione una opción" }, { value: 1, label:"PRINCIPAL" }, { value: 2, label:"DESECHABLE" }, { value: 3, label:"CINTURÓN" }, { value: 4, label:"RESPALDO" }, { value: 5, label:"CANDADO" }];
        this.gpsDisponibles =   [];
          this.direccionGps =   [];
     this.usuariosLogObject = {
            "idBitacoraMonitoreo": 0,
            "idUsuario": 0,
            "idMovimiento": 0,
            "accionFecha": "",
            "descripcion": ""
          };

  }
  componentDidMount =() => {
    disabledButon= false;
    this.getfuntion("get", [], "", "gpsMobile/idGpsEstatus/1", "gpsDisponible"); //Gps Disponibles
    this.getfuntion("get", [], "", "gpsMobile/idGpsEstatus/4", "gpsAsignado"); //Gps Disponibles
    this.getfuntion("get", [], "", "globalMobile/movimientos_sin_gps", "movimiento_s_gps");//Movimientos sin gps para full
    this.getfuntion("get", [], "", "navieraMovimientos/"+this.props.idMovimiento, "movimiento").then((movNav) => {
        this.movimiento= movNav;
        this.getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+this.props.idMovimiento, "movimientosPatio");
    });    
    this.getfuntion("get", [], "", "gpsGateEtiquetas/PLATAFORMA/"+this.props.plataformaid, "gpsGateEtiquetas");

    //this.setState({po1: this.props.movimiento.po+" - "+this.props.movimiento.contenedor}); 
  }
  setMovPatio=(patio)=>{
    this.setState({idRemolque:          { value: patio.idRemolque.idRemolque , label: patio.idRemolque.nombre, data: patio.idRemolque } });
    this.setState({operadorNombre:      patio.operadorNombre    });
    this.setState({operadorContacto:    patio.operadorContacto  });
    this.setState({operadorLicencia:    patio.operadorLicencia  });
    this.setState({tractorModelo:       patio.tractorModelo     });
    this.setState({tractorMarca:        patio.tractorMarca      });
    this.setState({tractorColor:        patio.tractorColor      });
    this.setState({tractorPlaca:        patio.tractorPlaca      });
    this.setState({remolquePlaca:       patio.remolquePlaca     });
    this.setState({observaciones:       patio.observaciones     });
    this.setState({economicor:          patio.economicor? patio.economicor: ""});
    this.setState({economicot:          patio.economicot? patio.economicot: ""});
  }
  setMovPatioFull=(patio)=>{
    this.setState({idRemolqueFull:          { value: patio.idRemolque.idRemolque , label: patio.idRemolque.nombre, data: patio.idRemolque } });
    this.setState({operadorNombreFull:      patio.operadorNombre    });
    this.setState({operadorContactoFull:    patio.operadorContacto  });
    this.setState({operadorLicenciaFull:    patio.operadorLicencia  });
    this.setState({tractorModeloFull:       patio.tractorModelo     });
    this.setState({tractorMarcaFull:        patio.tractorMarca      });
    this.setState({tractorColorFull:        patio.tractorColor      });
    this.setState({tractorPlacaFull:        patio.tractorPlaca      });
    this.setState({remolquePlacaFull:       patio.remolquePlaca     });
    this.setState({observacionesFull:       patio.observaciones     });
    this.setState({economicorFull:          patio.economicor? patio.economicor: ""});
    this.setState({economicotFull:          patio.economicot? patio.economicot: ""});
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "post":
          {
            if (stateVar === "movimientosPatios") { }
            if (stateVar === "movimientoGp") { return true ;}
            if (stateVar === "asignar_gps") { return true ;}            
          }
          break;
        case "get":
          {
            if (stateVar === "gpsGateEtiquetas") { this.setState({gpsGateEtiquetas: returnVal});
                this.setState({updateMSG: true}); //Valida Etiquetas(De no estar la  equieteta no se ra posible configurar)
            }
            if (stateVar === "gpsEstatus") { return returnVal; }
            if (stateVar === "movimiento") { return returnVal; }
            if (stateVar === "gpsestatus") { return returnVal; }
            if (stateVar === "movimientoFull") { return returnVal; }
            if (stateVar === "movimientosPatioFull") { return returnVal; }
            
            if (stateVar === "movimientosPatio") {                
                if(returnVal.id){
                    this.nuevo1= false;
                    console.log("movimiento patio(Editar, validar si esta en full) ===>");
                    console.log(returnVal);
                    if(returnVal.esFull){                       
                        this.setState({esFull: true});
                        if(!this.movimiento.movimientoGps[0]){//No tiene GPS, aqui se valida el el mov full
                            this.getfuntion("get", [], "", "navieraMovimientos/"+returnVal.esFull, "movimientoFull").then((movFull) => {
                              if(!movFull.movimientoGps[0]){//No importa el orden, no hay GPS asignados(conservamos el mov seleccionado como primario)
                                this.setState({po1: this.movimiento.id+"-"+this.movimiento.po+" - "+this.movimiento.contenedor});
                                this.setState({po2: movFull.id+" - "+movFull.po+" - "+movFull.contenedor});
                                this.setState({idMovFull: { value: movFull.id, label: movFull.id+" - "+movFull.po+" - "+movFull.contenedor, data: movFull }});
                                this.movimientosPatios= returnVal;
                                this.setMovPatio(returnVal);
                                this.getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+returnVal.esFull, "movimientosPatioFull").then((movPatioFull) => {                                    
                                    if(movPatioFull.id){
                                        this.nuevoFull= false;
                                        this.movimientosPatiosFull= movPatioFull;
                                        this.setMovPatioFull(movPatioFull);
                                    }else{
                                        this.movimientosPatiosFull= returnVal;
                                        this.setMovPatioFull(returnVal);
                                    }
                                });
                              }else{//El Movimiento selecionado pasa como full
                                this.idMovPrimario =   movFull.idMovimiento;
                                this.setState({po2: this.movimiento.id+" - "+this.movimiento.po+" - "+this.movimiento.contenedor});
                                this.setState({po1: movFull.id+" - "+movFull.po+" - "+movFull.contenedor});
                                this.setState({idMovFull: { value: this.movimiento.id, label: this.movimiento.id+" - "+this.movimiento.po+" - "+this.movimiento.contenedor, data: this.movimiento }});
                                this.getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+returnVal.esFull, "movimientosPatioFull").then((movPatioFull) => {
                                    if(movPatioFull.id){
                                        this.nuevoFull= false;
                                        this.movimientosPatios= movPatioFull;
                                        this.setMovPatio(movPatioFull);
                                        this.movimientosPatiosFull= returnVal;
                                        this.setMovPatioFull(returnVal);
                                    }else{
                                        this.movimientosPatios= returnVal;
                                        this.setMovPatio(returnVal);
                                        this.movimientosPatiosFull= returnVal;
                                        this.setMovPatioFull(returnVal);
                                    }
                                    
                                });
                                for(let mgps of movFull.movimientoGps){
                                    this.gpsDisponibles.push({ value: mgps.gps.id, label: mgps.gps.nombre });
                                    this.setState({gpsList: this.gpsDisponibles});
                                    this.setState({ gpsListFull: this.gpsDisponibles });//
                                    if( mgps.gps.idGg > 10 ){
                                      this.getfuntion("get", "", "", "gpsestatus/plataforma/"+this.movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                          this.gpsAgregado.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );
                                          this.gpsAgregadoOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );                                    
                                          this.setState({gpsAgregado: this.gpsAgregado});
                                      });
                                    }else{
                                      this.gpsAgregado.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0"} );
                                      this.gpsAgregadoOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0"} );                                    
                                      this.setState({gpsAgregado: this.gpsAgregado});
                                    }
                                }
                                for(let mgps of movFull.movimientoGps){
                                    this.gpsDisponibles.push({ value: mgps.gps.id, label: mgps.gps.nombre });
                                    this.setState({gpsList: this.gpsDisponibles});
                                    this.setState({ gpsListFull: this.gpsDisponibles });//
                                    if( mgps.gps.idGg > 10 ){
                                      this.getfuntion("get", "", "", "gpsestatus/plataforma/"+this.movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                        this.gpsAgregadosFull.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );
                                        this.gpsAgregadosFullOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );                                    
                                        this.setState({gpsAgregadosFull: this.gpsAgregadosFull});
                                      });
                                    }else{
                                      this.gpsAgregadosFull.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0" } );
                                      this.gpsAgregadosFullOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "" } );                                    
                                       this.setState({gpsAgregadosFull: this.gpsAgregadosFull});
                                    }
                                    
                                }
                              }
                            });

                        }else{//Si hay GPS, Se queda como proimario()agregamos gps a lista
                            this.setState({po1: this.movimiento.id+" - "+this.movimiento.po+" - "+this.movimiento.contenedor});
                            this.movimientosPatios= returnVal;
                            this.setMovPatio(returnVal);
                            for(let mgps of this.movimiento.movimientoGps){
                                this.gpsDisponibles.push({ value: mgps.gps.id, label: mgps.gps.nombre });
                                this.setState({gpsList: this.gpsDisponibles});
                                this.setState({ gpsListFull: this.gpsDisponibles });//
                                if( mgps.gps.idGg > 10 ){
                                  this.getfuntion("get", "", "", "gpsestatus/plataforma/"+this.movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                      this.gpsAgregado.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );
                                      this.gpsAgregadoOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );                                    
                                      this.setState({gpsAgregado: this.gpsAgregado});
                                  });
                                }else{
                                  this.gpsAgregado.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0" } );
                                  this.gpsAgregadoOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0" } );                                    
                                  this.setState({gpsAgregado: this.gpsAgregado});
                                }
                            }
                            this.getfuntion("get", [], "", "navieraMovimientos/"+returnVal.esFull, "movimientoFull").then((movFull) => {
                                this.setState({po2: movFull.id+" - "+movFull.po+" - "+movFull.contenedor});
                                this.setState({idMovFull: { value: movFull.id, label: movFull.id+" - "+movFull.po+" - "+movFull.contenedor, data: movFull }});
                                this.getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+returnVal.esFull, "movimientosPatioFull").then((movPatioFull) => {
                                    
                                    if(movPatioFull.id){
                                        this.nuevoFull= false;
                                        this.movimientosPatiosFull= movPatioFull;
                                        this.setMovPatioFull(movPatioFull);
                                    }else{
                                        this.movimientosPatiosFull= returnVal;
                                        this.setMovPatioFull(returnVal);
                                    }
                                });
                                if(movFull.movimientoGps[0]){
                                    for(let mgps of movFull.movimientoGps){
                                        this.gpsDisponibles.push({ value: mgps.gps.id, label: mgps.gps.nombre });
                                        this.setState({gpsList: this.gpsDisponibles});
                                        this.setState({ gpsListFull: this.gpsDisponibles });//
                                        if( mgps.gps.idGg > 10 ){
                                          this.getfuntion("get", "", "", "gpsestatus/plataforma/"+this.movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                              this.gpsAgregadosFull.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );
                                              this.gpsAgregadosFullOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );                                    
                                              this.setState({gpsAgregadosFull: this.gpsAgregadosFull});
                                          });
                                        }else{
                                          this.gpsAgregadosFull.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0" } );
                                          this.gpsAgregadosFullOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0" } );                                    
                                          this.setState({gpsAgregadosFull: this.gpsAgregadosFull});
                                        }
                                    }
                                }else{
                                    for(let mgps of this.movimiento.movimientoGps){
                                        this.gpsDisponibles.push({ value: mgps.gps.id, label: mgps.gps.nombre });
                                        this.setState({gpsList: this.gpsDisponibles});
                                        this.setState({ gpsListFull: this.gpsDisponibles });//
                                        if( mgps.gps.idGg > 10 ){
                                          this.getfuntion("get", "", "", "gpsestatus/plataforma/"+this.movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                              this.gpsAgregadosFull.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );
                                              this.gpsAgregadosFullOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );                                    
                                              this.setState({gpsAgregadosFull: this.gpsAgregadosFull});
                                          });
                                        }else{
                                          this.gpsAgregadosFull.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0" } );
                                          this.gpsAgregadosFullOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0" } );                                    
                                          this.setState({gpsAgregadosFull: this.gpsAgregadosFull});
                                        }
                                    }
                                }                               
                            });                            
                        }
                    }else{//Ediatar, No es full
                        this.idMovPrimario =   this.props.idMovimiento;
                        this.setState({po1: this.movimiento.id+" - "+this.movimiento.po+" - "+this.movimiento.contenedor});
                        this.movimientosPatios= returnVal;
                        this.setMovPatio(returnVal);
                        console.log(this.movimiento);
                        if(this.movimiento.movimientoGps[0]){//Tiene GPS, agregamos los GPS'S a la lista
                            for(let mgps of this.movimiento.movimientoGps){
                                this.gpsDisponibles.push({ value: mgps.gps.id, label: mgps.gps.nombre });
                                this.setState({gpsList: this.gpsDisponibles});
                                this.setState({ gpsListFull: this.gpsDisponibles });//
                                if( mgps.gps.idGg > 10 ){
                                  this.getfuntion("get", "", "", "gpsestatus/plataforma/"+this.movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                      this.gpsAgregado.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );
                                      this.gpsAgregadoOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria} );                                    
                                      this.setState({gpsAgregado: this.gpsAgregado});
                                  });
                                }else{
                                  this.gpsAgregado.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0" } );
                                  this.gpsAgregadoOld.push( {id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0" } );                                    
                                  this.setState({gpsAgregado: this.gpsAgregado});
                                }
                            }
                        }
                    }
                }else{
                    this.setState({po1: this.movimiento.id+" - "+this.movimiento.po+" - "+this.movimiento.contenedor});
                }
            }
            if (stateVar === "gpsDisponible") {
                this.setState({ gpsDisponible: returnVal });
                returnVal.map((elemento, index) => {
                    this.gpsDisponibles.push({ value: elemento.id, label: elemento.nombre });
                });
                this.setState({gpsList: this.gpsDisponibles});
                this.setState({ gpsListFull: this.gpsDisponibles });
            }
            if (stateVar === "gpsAsignado") {
              let gpDispo= this.state.gpsDisponible;
              gpDispo = gpDispo.concat( returnVal );
              returnVal.map((elemento, index) => {
                  this.gpsDisponibles.push({ value: elemento.id, label: elemento.nombre });
              });
              this.setState({gpsList: this.gpsDisponibles});
              this.setState({ gpsListFull: this.gpsDisponibles });
          }
            if(stateVar === "datosGps"){
                this.direccionGPS= returnVal.direccion; 
                this.nivelBateria= returnVal.nivel_bateria;
            }
            if(stateVar === "datosGpsSGPS"){
                this.direccionGPS= returnVal.direccion;
                this.nivelBateria= returnVal.nivel_bateria;
            }
            if(stateVar === "movimiento_s_gps"){
                let movSinGpsTem= this.state.movimientosSinGps;
                returnVal.map((elemento, index) => {
                  if( this.props.idMovimiento !== elemento.idMovimiento ){
                    movSinGpsTem.push({ value: elemento.idMovimiento, label: elemento.idMovimiento+" - "+elemento.po+" - "+elemento.contenedor, data: elemento });
                  }
                });
                this.setState({movimientosSinGps: movSinGpsTem});   
            }
          } 
            break;
        
        default:
          break;
      }
    })
  } 

  changeEconomicor      = (event) => {this.movimientosPatios['economicor']               =   event.target.value; this.setState({ economicor:          event.target.value });}
  changeEconomicorF     = (event) => {this.movimientosPatiosFull['economicor']           =   event.target.value; this.setState({ economicorFull:      event.target.value });}
  changeplacaRemolque   = (event) => {this.movimientosPatios['remolquePlaca']            =   event.target.value; this.setState({ remolquePlaca:      event.target.value });}
  changeplacaRemolqueF  = (event) => {this.movimientosPatiosFull['remolquePlaca']        =   event.target.value; this.setState({ remolquePlacaFull:  event.target.value });}
  changeObservaciones   = (event) => {this.movimientosPatios['observaciones']            =   event.target.value; this.setState({ observaciones:      event.target.value });}
  changeObservacionesF  = (event) => {this.movimientosPatiosFull['observaciones']        =   event.target.value; this.setState({ observacionesFull:  event.target.value });}
  changeEconomicot      = (event) => {this.movimientosPatios['economicot']               =   event.target.value; this.movimientosPatiosFull['economicot']               =   event.target.value; this.setState({ economicot:         event.target.value });}
  changemodeloTractor   = (event) => {this.movimientosPatios['tractorModelo']            =   event.target.value; this.movimientosPatiosFull['tractorModelo']            =   event.target.value; this.setState({ tractorModelo:      event.target.value });}
  changemarcaTractor    = (event) => {this.movimientosPatios['tractorMarca']             =   event.target.value; this.movimientosPatiosFull['tractorMarca']             =   event.target.value; this.setState({ tractorMarca:       event.target.value });}
  changecolorTractor    = (event) => {this.movimientosPatios['tractorColor']             =   event.target.value; this.movimientosPatiosFull['tractorColor']             =   event.target.value; this.setState({ tractorColor:       event.target.value });}
  changeplacaTractor    = (event) => {this.movimientosPatios['tractorPlaca']             =   event.target.value; this.movimientosPatiosFull['tractorPlaca']             =   event.target.value; this.setState({ tractorPlaca:       event.target.value });}
  changenombreOperador  = (event) => {this.movimientosPatios['operadorNombre']           =   event.target.value; this.movimientosPatiosFull['operadorNombre']           =   event.target.value; this.setState({ operadorNombre:     event.target.value });}
  changemedioContacto   = (event) => {this.movimientosPatios['operadorContacto']         =   event.target.value; this.movimientosPatiosFull['operadorContacto']         =   event.target.value; this.setState({ operadorContacto:   event.target.value });}
  changelicencia        = (event) => {this.movimientosPatios['operadorLicencia']         =   event.target.value; this.movimientosPatiosFull['operadorLicencia']         =   event.target.value; this.setState({ operadorLicencia:   event.target.value });}
  tipoPlataforma        = (event) => {this.movimientosPatios['idRemolque']               =   event.data;         this.movimientosPatiosFull['idRemolque']               =   event.data;         this.setState({ idRemolque:         event              });}
  tipoGps               = (event) => this.setState({tipoGps: event});
  tipoGpsFull           = (event) => this.setState({tipoGpsFull: event});
  changeMovimientoFull  = (event) => { if(this.nuevoFull){ this.setState({idMovFull: event});} }
  changeGps             = (event) => {  this.getfuntion("get", [], "", "gpsestatus/plataforma/16/gps/"+event.value, "datosGps");    this.setState({ idGps: event }); };
  changeGpsFull         = (event) => { this.getfuntion("get", [], "", "gpsestatus/plataforma/16/gps/"+event.value, "datosGpsSGPS"); this.setState({ idGpsFull: event }); }
  
  agregarGpsaLista(){
    this.gpsAgregado.push( {id: this.state.idGps.value, nombre: this.state.idGps.label, tipoid: this.state.tipoGps.value, tipo: this.state.tipoGps.label, direccion: this.direccionGPS, nivelbateria: this.nivelBateria} );
    this.setState({gpsAgregado: this.gpsAgregado});
    /** Ocultar gps seleccionados */
    let gpsListTem= [];
    this.gpsDisponibles.map((elemento, index) => {
        let found= false;
        for(let item of this.gpsAgregado){ if(elemento.value === item.id){ found= true;}  }
        if(!found){ gpsListTem.push(elemento); }
    });
    this.setState({gpsList: gpsListTem});
    this.setState({tipoGps: { value: 0, label:"Seleccione una opción" }});
    this.setState({ idGps:  { value: 0, label:"Seleccione un GPS" } });
  }

  agregarGpsaListaFull(){ 
    this.gpsAgregadosFull.push( {id: this.state.idGpsFull.value, nombre: this.state.idGpsFull.label, tipoid: this.state.tipoGpsFull.value, tipo: this.state.tipoGpsFull.label, direccion: this.direccionGPS, nivelbateria: this.nivelBateria} );
    this.setState({gpsAgregadosFull: this.gpsAgregadosFull});
    /** Ocultar gps seleccionados */
    let gpsListTem= [];
    this.gpsDisponibles.map((elemento, index) => {
        let found= false;
        for(let item of this.gpsAgregadosFull){ if(elemento.value === item.id){ found= true;}  }
        if(!found){ gpsListTem.push(elemento); }
    });
    this.setState({gpsListFull: gpsListTem});
    this.setState({tipoGpsFull: { value: 0, label:"Seleccione una opción" }});
    this.setState({idGpsFull: { value: 0, label:"Seleccione un GPS" }});
  }

  recuperarGPS = (gpsAgregadoRec) => { 
    var recGps={gps: gpsAgregadoRec.id , plataforma:16, estatus: 1}
    this.getfuntion("post", recGps, "", "gps/asignar_gps", "gpsRecuperar").then((gpsEstatus) => {
    });
  }
  agregarGPS = () => {
    if(disabledButon){ 

    }else{ disabledButon= true; this.setState({ disabledButon: true });
      let errores= false;
      if(this.movimientosPatios.idRemolque['idRemolque']>0){
          if(this.gpsAgregado.length > 0 || this.gpsAgregadoOld !== this.gpsAgregado){
              if(this.state.idMovFull.value > 0){
                  if(this.gpsAgregadosFull.length>0 || this.gpsAgregadosFullOld !== this.gpsAgregadosFull){}else{ NotificationManager.warning("Se ha detectado que quiere hacer full con: "+this.state.idMovFull.label+" (pero, no ha completado los datos)", "Guardar" ); return;}
              }
              NotificationManager.info("Guardando datos ... ", "Guardar" );
              
              let movFull= this.state.idMovFull.value;
              if(this.state.idMovFull.value > 0){
                  this.movimientosPatios['esFull'] = movFull;
                  this.movimientosPatiosFull['idMovimiento']= movFull;
                  this.movimientosPatiosFull['esFull'] = this.idMovPrimario;
              } else { this.movimientosPatios['esFull'] = null; }            
              //if(this.state.idMovFull.value > 0){ this.movimientosPatiosFull['esFull'] = this.idMovPrimario; } else { this.movimientosPatiosFull['esFull'] = null; }            
              //this.movimientosPatiosFull['idMovimiento']=movFull;
              console.log("new=> "+ this.nuevo1)
              if(this.nuevo1 && !this.movimientosPatios.id){
                  console.log("this.movimientosPatios =>" );
                  console.log(this.movimientosPatios );
                  this.getfuntion("post", this.movimientosPatios, "", "movimientosPatio", "movimientosPatios").catch(err => {
                      errores= true;
                      NotificationManager.error("Error al guardar datos del tracto y del operador", "Error" );
                      return;
                  });                
              }
              if(!this.nuevo1 && this.movimientosPatios.id){
                  console.log("this.movimientosPatios put =>" );
                  console.log(this.movimientosPatios );
                  this.getfuntion("put", this.movimientosPatios, "", "movimientosPatio", "movimientosPatios").catch(err => {
                      errores= true;
                      NotificationManager.error("Error al guardar datos del tracto y del operador", "Error" );
                      return;
                  });
              }
              this.getfuntion("put", [], "", "navieraMovimientos/movimiento/"+this.idMovPrimario+"/operacionestatus/2").then((navMov) => {}).catch(err => {
                  errores= true;
                  NotificationManager.error("error al cambiar el estatus de operacion del movimiento", "Error" );
                  return;
              });
              this.gpsAgregadoOld.map((viejo, index)=>{
                  let eliminar= true;
                  for(let nuevo of this.gpsAgregado){
                      if(viejo.id == nuevo.id){
                          eliminar= false;
                          /*                      
                          if(viejo.tipoid !== nuevo.tipoid){
                              eliminar= true;console.log(viejo, nuevo);
                          } */
                      }
                  }
                  if(eliminar){ this.recuperarGPS(viejo); }
              });
              contadorCarga= this.gpsAgregado.length;
              for(let item of this.gpsAgregado){
                  let actualizar= false;
                  let datoViejo= false;
                  for(let viejo of this.gpsAgregadoOld){ 
                      if(viejo.id == item.id){datoViejo= true};
                      if(viejo.tipoid !== item.tipoid){
                          actualizar= true;
                      }
                  }

                  this.movimientosGps.idMovimiento = this.idMovPrimario;
                  this.movimientosGps.gps.id       = item.id;
                  this.movimientosGps.gpsTipo.id   = item.tipoid;

                  this.gpsGG.gps          =   item.id;
                  this.gpsGG.plataforma   =   this.props.plataformaid;
                  this.gpsGG.estatus      =   2;
                  
                  if( (actualizar ||  !datoViejo) && item.id > 10  ){// los id menor a 10 seran , sin gps, radio operado, etc( y no se etiquetaran en gpsGate)
                      /** Asignar Gps en GG */
                      this.getfuntion("post", this.gpsGG, "", "gps/asignar_gps","asignar_gps").then((respuesta) => {
                          /** Cambiar Estatus de gps  */
                          this.getfuntion("get", [], "", "gps/idGps/"+item.id+"/idGpsEstatus/2", "gpsEstatus").then((estatus) => {
                              /** Asignar a movimientoGps */
                              this.getfuntion("post", this.movimientosGps, "", "movimientoGps/idUsuario/"+this.props.userId, "movimientoGp").then((movGps) => {
                                  this.usuariosLogObject.idBitacoraMonitoreo = 5;
                                  this.usuariosLogObject.idUsuario = this.props.userId;
                                  this.usuariosLogObject.idMovimiento = this.idMovPrimario;
                                  this.usuariosLogObject.idPlataformaTipo = 1;
                                  this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                                  this.usuariosLogObject.descripcion = "Asignacion de GPS: "+item.nombre+"("+item.id+")";
                                  Crud_Catalogos("usuariosLog", "nada", "post", "", this.usuariosLogObject);
                                  NotificationManager.info("Gps: "+item.nombre+" agregado", "Guardado" );
                                  contadorCarga--;
                                  if( contadorCarga === 0 ){ this.props.refrescar(); }
                              }).catch(err => {
                                  this.usuariosLogObject.idBitacoraMonitoreo = 5;
                                  this.usuariosLogObject.idUsuario = this.props.userId;
                                  this.usuariosLogObject.idMovimiento = this.idMovPrimario;
                                  this.usuariosLogObject.idPlataformaTipo = 1;
                                  this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                                  this.usuariosLogObject.descripcion = "Error al asignar gps: "+item.nombre+"("+item.id+")";
                                  Crud_Catalogos("usuariosLog", "nada", "post", "", this.usuariosLogObject);

                                  errores= true;
                                  var recGps={gps: item.id , plataforma:16, estatus: 1}
                                  this.getfuntion("post", recGps, "", "gps/asignar_gps", "gpsRecuperar");
                                  NotificationManager.error("Hubo un error al vincular el gps al movimiento", "Error" );
                              });
                          }).catch(err => {
                              errores= true;
                              var recGps={gps: item.id , plataforma:16, estatus: 1}
                              this.getfuntion("post", recGps, "", "gps/asignar_gps", "gpsRecuperar");
                              NotificationManager.error("no se pudo cambiar el estatus del gps", "Error" );
                          });
                      }).catch(err => { 
                          errores= true;
                          var recGps={gps: item.id , plataforma:16, estatus: 1}
                          this.getfuntion("post", recGps, "", "gps/asignar_gps", "gpsRecuperar");
                          //Recuperar gps y regresar movimiento a 1
                          NotificationManager.error("No se ha podido asignar gps", "Error" );
                      });
                  }
                  if( (actualizar ||  !datoViejo) && item.id <= 10  ){ //Radio operado, sin gps, aqui se vinculan a l mov( No cambia el estatus del gps)(No recibiran alertas, actualizacion automatica, )
                      this.getfuntion("post", this.movimientosGps, "", "movimientoGps/idUsuario/"+this.props.userId, "movimientoGp").then((movGps) => {
                        this.usuariosLogObject.idBitacoraMonitoreo = 5;
                        this.usuariosLogObject.idUsuario = this.props.userId;
                        this.usuariosLogObject.idMovimiento = this.idMovPrimario;
                        this.usuariosLogObject.idPlataformaTipo = 1;
                        this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                        this.usuariosLogObject.descripcion = "Asignacion de GPS: "+item.nombre+"("+item.id+")";
                        Crud_Catalogos("usuariosLog", "nada", "post", "", this.usuariosLogObject);
                        NotificationManager.info("Gps: "+item.nombre+" agregado", "Guardado" );
                        contadorCarga--;
                        if( contadorCarga === 0 ){ this.props.refrescar(); }
                      }).catch(err => {
                          errores= true;
                          NotificationManager.error("Hubo un error al vincular el gps al movimiento", "Error" );
                      });
                    }        
              }
              /**
               * Mov Full
               */
              console.log("new full => "+ this.nuevoFull);
              if(this.state.idMovFull.value > 0){
                  if(this.nuevoFull && !this.movimientosPatiosFull.id){
                      console.log("this.movimientosPatios =>" );
                      console.log(this.movimientosPatios );
                      this.getfuntion("post", this.movimientosPatiosFull, "", "movimientosPatio", "movimientosPatios").catch(err => {
                          errores= true;
                          NotificationManager.error("Error al guardar datos del tracto y del operador", "Error" );
                          return;
                      });                
                  }
                  if(!this.nuevoFull && this.movimientosPatiosFull.id){
                      console.log("this.movimientosPatios put =>" );
                      console.log(this.movimientosPatios );
                      this.getfuntion("put", this.movimientosPatiosFull, "", "movimientosPatio", "movimientosPatios").catch(err => {
                          errores= true;
                          NotificationManager.error("Error al guardar datos del tracto y del operador", "Error" );
                          return;
                      });
                  }
                  this.getfuntion("put", [], "", "navieraMovimientos/movimiento/"+movFull+"/operacionestatus/2").catch(err => {
                      errores= true;
                      NotificationManager.error("error al cambiar el estatus de operacion del movimiento", "Error" );
                      return;
                  });

                  this.gpsAgregadosFullOld.map((viejo, index)=>{
                      let eliminar= true;
                      for(let nuevo of this.gpsAgregadosFull){
                          if(viejo.id == nuevo.id){
                              eliminar= false;                      
                              /*
                              if(viejo.tipoid !== nuevo.tipoid){
                                  eliminar= true;console.log(viejo, nuevo);
                              }*/
                          }
                      }
                      if(eliminar){ this.recuperarGPS(viejo); }
                  });
                  contadorCarga= contadorCarga + this.gpsAgregadosFull.length ;
                  for(let item of this.gpsAgregadosFull){
                      let actualizar= false;
                      let datoViejo= false;
                      for(let viejo of this.gpsAgregadosFullOld){ 
                          if(viejo.id == item.id){datoViejo= true;}
                          if(viejo.tipoid !== item.tipoid){
                              actualizar= true;
                          }
                      }
                      this.movimientosGpsFull.idMovimiento = this.state.idMovFull.value;
                      this.movimientosGpsFull.gps.id = item.id;
                      this.movimientosGpsFull.gpsTipo.id = item.tipoid;
                      if( (actualizar ||  !datoViejo) && item.id > 10   ){                        
                          this.gpsGG.gps          =   item.id;
                          this.gpsGG.plataforma   =   this.props.plataformaid;
                          this.gpsGG.estatus      =   2;
                          let foundMov= false;
                          for(let item1 of this.gpsAgregado){ if(item1.id == item.id){ foundMov= true; } }
                          if(!foundMov){
                              /** Asignar Gps en GG */            
                              this.getfuntion("post", this.gpsGG, "", "gps/asignar_gps").then((respuesta) => {
                                  this.usuariosLogObject.idBitacoraMonitoreo = 5;
                                  this.usuariosLogObject.idUsuario = this.props.userId;
                                  this.usuariosLogObject.idMovimiento = this.state.idMovFull.value;
                                  this.usuariosLogObject.idPlataformaTipo = 1;
                                  this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                                  this.usuariosLogObject.descripcion = "Asignacion de GPS: "+item.nombre+"("+item.id+")";
                                  Crud_Catalogos("usuariosLog", "nada", "post", "", this.usuariosLogObject);
                                  /** Asignar a movimientoGps */
                                  this.getfuntion("post", this.movimientosGpsFull, "", "movimientoGps/idUsuario/"+this.props.userId, "movimientoGp");
                                  /** Cambiar Estatus de gps  */
                                  this.getfuntion("get", [], "", "gps/idGps/"+item.id+"/idGpsEstatus/2");
                                  NotificationManager.info("Gps: "+item.nombre+" agregado", "Guardado" );
                                  contadorCarga--;
                                  if( contadorCarga === 0 ){ this.props.refrescar(); }
                              }).catch(err => { 
                                  NotificationManager.error("No se ha podido asignar gps", "Error" );
                                  var recGps={gps: item.id , plataforma:16, estatus: 1}
                                  this.getfuntion("post", recGps, "", "gps/asignar_gps", "gpsRecuperar");
                              });                            
                          }
                      }
                      if( (actualizar ||  !datoViejo) && item.id <= 10  ){ //Radio operado, sin gps, aqui se vinculan a l mov( No cambia el estatus del gps)(No recibiran alertas, actualizacion automatica, )
                        this.getfuntion("post", this.movimientosGpsFull, "", "movimientoGps/idUsuario/"+this.props.userId, "movimientoGp").then((movGps) => {
                          this.usuariosLogObject.idBitacoraMonitoreo = 5;
                          this.usuariosLogObject.idUsuario = this.props.userId;
                          this.usuariosLogObject.idMovimiento = this.idMovPrimario;
                          this.usuariosLogObject.idPlataformaTipo = 1;
                          this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                          this.usuariosLogObject.descripcion = "Asignacion de GPS: "+item.nombre+"("+item.id+")";
                          Crud_Catalogos("usuariosLog", "nada", "post", "", this.usuariosLogObject);
                          NotificationManager.info("Gps: "+item.nombre+" agregado", "Guardado" );
                          contadorCarga--;
                          if( contadorCarga === 0 ){ this.props.refrescar(); }
                        }).catch(err => {
                            errores= true;
                            NotificationManager.error("Hubo un error al vincular el gps al movimiento", "Error" );
                        });
                      }                                
                  }                
              }
          }else{ NotificationManager.warning("No se han agregado GPS'S", "Atención" ); }              
      }else{
          NotificationManager.warning("Tipo de plataforma requerido ..!", "Atención" );
      }
    }
    setTimeout(this.props.hiddenmodal,  1000*10);//1000*10
  }

  eliminarGps = (index, idGps) => {

    this.gpsAgregado.splice(index, 1);
    this.setState({gpsAgregado: this.gpsAgregado});
    /**Libera lista de gps's */
    let gpsListTem= [];
    this.gpsDisponibles.map((elemento, index) => {
        let found= false;
        for(let item of this.gpsAgregado){ if(elemento.value === item.id){ found= true;} }
        if(!found){ gpsListTem.push(elemento); }
    });
    this.setState({gpsList: gpsListTem});
    //this.getfuntion("get", [], "", "gpsestatus/plataforma/16/gps/"+idGps, "datosGps");
  }

  eliminarGpsSGPS = (index, idGpsSGPS) => {
    this.gpsAgregadosFull.splice(index, 1);
    this.setState({ gpsAgregadosFull: this.gpsAgregadosFull} );
    /** Ocultar gps seleccionados */
    let gpsListTem= [];
    this.gpsDisponibles.map((elemento, index) => {
        let found= false;
        for(let item of this.gpsAgregadosFull){ if(elemento.value === item.id){ found= true;}  }
        if(!found){ gpsListTem.push(elemento); }
    });
    this.setState({gpsListFull: gpsListTem});
    //this.getfuntion("get", [], "", "gpsestatus/plataforma/16/gps/"+idGpsSGPS, "datosGpsSGPS");
  }

  render() {
    return (
        <div>
          <div className="row">
            <div className="col col-12 col-sm-12 col-md-12 ">
              { this.state.updateMSG?
                <div hidden={this.state.gpsGateEtiquetas.id}>
                  <Alert type="danger" icon="alert-octagon"> Pide a sistemas que configure la etiqueta plataforma en gpsgate, para poder asignar gps</Alert>
                </div>: null
              } 
            </div>
            <div className="col col-12 col-sm-12 col-md-12 "> {/*Datos de tractor */}
              <h3>TRACTO</h3>
              <div className="row">
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Modelo Tractor">     <Form.Input name="modeloTractor"  type="text" value={this.state.tractorModelo} onChange={this.changemodeloTractor}/>    </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Marca Tractor">      <Form.Input name="marcaTractor"  type="text"  value={this.state.tractorMarca} onChange={this.changemarcaTractor}/>      </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Color Tractor">      <Form.Input name="colorTractor"  type="text"  value={this.state.tractorColor} onChange={this.changecolorTractor}/>      </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Placa Tractor">      <Form.Input name="placaTractor"  type="text"  value={this.state.tractorPlaca} onChange={this.changeplacaTractor}/>      </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Tipo Plataforma(requerido)">     <Select value={this.state.idRemolque} onChange={this.tipoPlataforma} options={this.idRemolques} />  </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Economico(Tracto)">     <Form.Input name="economicot"  type="text" value={this.state.economicot} onChange={this.changeEconomicot}/>    </Form.Group>
                </div>
              </div>
            </div>            
          </div>

          <hr style={ estilo } />

          <div className="row"> {/* Datos del operador */}
            <h3>OPERADOR</h3>
            <div className="col col-12 col-sm-12 col-md-4 ">
              <Form.Group label="Nombre Operador">    <Form.Input name="nombreOperador"  type="text" value={this.state.operadorNombre} onChange={this.changenombreOperador}/>  </Form.Group>  
            </div>
            <div className="col col-12 col-sm-12 col-md-4 ">
              <Form.Group label="Medio Contacto">     <Form.Input name="medioContacto"   type="text" value={this.state.operadorContacto} onChange={this.changemedioContacto}/>   </Form.Group>  
            </div>
            <div className="col col-12 col-sm-12 col-md-4 ">
              <Form.Group label="Licencia">           <Form.Input name="licencia"        type="text" value={this.state.operadorLicencia} onChange={this.changelicencia}/>        </Form.Group>   
            </div>
          </div>

          <hr style={ estilo } />

          <div className="row">{/** Asignacion de gps */}
            <Card title={<h3>GPS()</h3>} isCollapsible>
                <Card.Body>
                  <div className="row">
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Placa Remolque">     <Form.Input name="placaRemolque"  type="text" value={this.state.remolquePlaca} onChange={this.changeplacaRemolque}/>    </Form.Group> 
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Economico (remolque)">     <Form.Input name="economicor"  type="text" value={this.state.economicor} onChange={this.changeEconomicor}/>    </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Obseraciones">           <Form.Textarea name="observaciones"        value={this.state.observaciones}     onChange={this.changeObservaciones}/>   </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="ID MOVIMIENTO - PO-CONTENEDOR">  <Form.Input name="movRoot"  type="text"  value={this.state.po1} />                </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="NOMBRE GPS">     <Select value={this.state.idGps} onChange={this.changeGps} options={this.state.gpsList} />  </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="TIPO GPS"><Select value={this.state.tipoGps} onChange={this.tipoGps} options={this.gpsTipos} />              </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Button onClick={() => {this.agregarGpsaLista()}} pill color="primary" outline disabled={!this.state.idGps.value || !this.state.tipoGps.value}><i className="fa fa-plus" data-toggle="tooltip" data-placement="top" title="agregar GPS"></i></Button>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-12 ">
                      <Table  cards={true}    striped={true}  responsive={true}   className="table-vcenter">
                        <Table.Header align="center">
                          <Table.ColHeader>   ID                  </Table.ColHeader>
                          <Table.ColHeader>   NOMBRE GPS          </Table.ColHeader>
                          <Table.ColHeader>   TIPO GPS            </Table.ColHeader>
                          <Table.ColHeader>   DATOS GPS           </Table.ColHeader>
                          <Table.ColHeader>   NIVEL BATERIA GPS   </Table.ColHeader>
                          <Table.ColHeader>                       </Table.ColHeader>                            
                        </Table.Header>
                        <Table.Body>
                          { this.state.gpsAgregado.map((gpsDis, index) =>
                            <Table.Row align="center">
                              <Table.Col> {gpsDis.id}        </Table.Col>
                              <Table.Col> {gpsDis.nombre}    </Table.Col>
                              <Table.Col> {gpsDis.tipo}      </Table.Col>
                              <Table.Col> {gpsDis.direccion} </Table.Col>
                              <Table.Col>
                                {gpsDis.nivelbateria <= "100" && gpsDis.nivelbateria >= "80" ? <Icon prefix="fa" name="battery-4" />: null}
                                {gpsDis.nivelbateria <= "79" && gpsDis.nivelbateria >= "55" ? <Icon prefix="fa" name="battery-3" />: null}
                                {gpsDis.nivelbateria <= "54" && gpsDis.nivelbateria >= "41" ? <Icon prefix="fa" name="battery-2" />: null}
                                {gpsDis.nivelbateria <= "40" && gpsDis.nivelbateria >= "25" ? <Icon prefix="fa" name="battery" />: null}
                                {gpsDis.nivelbateria >= "0" && gpsDis.nivelbateria <= "24"? <Icon prefix="fa" name="battery-0" />: null}
                                {gpsDis.nivelbateria} %
                              </Table.Col>
                              <Table.Col> <Button onClick={() => this.eliminarGps(index, gpsDis.id)} color="danger">Borrar</Button>   </Table.Col>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </div>
                  <div className="row">

                  </div>
                </Card.Body>
            </Card>
          </div>
          
          <hr style={ estilo } />
          
          <div className="row">{/**  Movimientos en full, debe de tomarse como una nueva asignacion*/}
            <Form.Switch type="Checkbox" label={this.state.esFull?"Movimento en full": "Colocar en full"} checked={this.state.esFull} onChange={ this.changeEsFull } />
            
            { this.state.esFull?
              <Card title={<h3>MOVIMIENTO FULL</h3>} isCollapsible>
                <Card.Body>
                  <div className="row">
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Placa Remolque"> <Form.Input name="placaRemolqueF"  type="text"  value={this.state.remolquePlacaFull} onChange={this.changeplacaRemolqueF}/>    </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Economico (Remolque)">      <Form.Input name="economicorF"      type="text"  value={this.state.economicorFull} onChange={this.changeEconomicorF}/>    </Form.Group>  
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Obseraciones">   <Form.Textarea name="observacionesF"            value={this.state.observacionesFull}     onChange={this.changeObservacionesF}/>   </Form.Group>  
                    </div>


                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="ID MOVIMIENTO- PO -CONTENEDOR FULL"><Select value={this.state.idMovFull} onChange={this.changeMovimientoFull} options={ this.state.movimientosSinGps } /></Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="NOMBRE GPS FULL"><Select value={this.state.idGpsFull} onChange={this.changeGpsFull} options={this.state.gpsListFull} /></Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="TIPO GPS FULL"> <Select value={this.state.tipoGpsFull} onChange={this.tipoGpsFull} options={this.gpsTipos} /> </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Button onClick={() => {this.agregarGpsaListaFull()}} pill color="primary" outline disabled={!this.state.idGpsFull.value || !this.state.tipoGpsFull.value}><i className="fa fa-plus" data-toggle="tooltip" data-placement="top" title="agregar GPS"></i></Button>
                    </div>

                    <div className="col col-12 col-sm-12 col-md-12 ">
                      <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                        <Table.Header align="center">
                          <Table.ColHeader>   ID                  </Table.ColHeader>
                          <Table.ColHeader>   NOMBRE GPS          </Table.ColHeader>
                          <Table.ColHeader>   TIPO GPS            </Table.ColHeader>                            
                          <Table.ColHeader>   DATOS GPS           </Table.ColHeader>
                          <Table.ColHeader>   NIVEL BATERIA GPS   </Table.ColHeader>
                          <Table.ColHeader>   ULTIMO REPORTE      </Table.ColHeader>
                          <Table.ColHeader>   BORRAR              </Table.ColHeader>                            
                          </Table.Header>
                        <Table.Body>
                          { this.state.gpsAgregadosFull.map((gpsDis, index) =>
                            <Table.Row align="center">
                              <Table.Col> {gpsDis.id}         </Table.Col>
                              <Table.Col> {gpsDis.nombre}     </Table.Col>
                              <Table.Col> {gpsDis.tipo}       </Table.Col>
                              <Table.Col> {gpsDis.direccion}  </Table.Col>
                              <Table.Col>
                                {gpsDis.nivelbateria <= "100" && gpsDis.nivelbateria >= "80" ? <Icon prefix="fa" name="battery-4" />: null}
                                {gpsDis.nivelbateria <= "79" && gpsDis.nivelbateria >= "55" ? <Icon prefix="fa" name="battery-3" />: null}
                                {gpsDis.nivelbateria <= "54" && gpsDis.nivelbateria >= "41" ? <Icon prefix="fa" name="battery-2" />: null}
                                {gpsDis.nivelbateria <= "40" && gpsDis.nivelbateria >= "25" ? <Icon prefix="fa" name="battery" />: null}
                                {gpsDis.nivelbateria >= "0" && gpsDis.nivelbateria <= "24"? <Icon prefix="fa" name="battery-0" />: null}
                                {gpsDis.nivelbateria} %
                              </Table.Col>
                              <Table.Col> {gpsDis.ultimo_reporte}  </Table.Col>
                              <Table.Col>
                                <Button onClick={() => this.eliminarGpsSGPS(index, gpsDis.id)} color="danger">Borrar</Button>
                              </Table.Col>
                            </Table.Row>
                          )}                        
                        </Table.Body>
                      </Table> 
                    </div>
                  </div>
                </Card.Body>
            </Card>
            :null}
          
          </div>

          <Grid.Row>
            <Grid.Col md={6}>
                <Form.Group >
            
                </Form.Group>
            </Grid.Col>
            <Grid.Col md={6}>
                <Form.Group >
                <Button.List align="right">
                    <Button onClick={() => {this.agregarGPS()}} color="primary" disabled={this.state.disabledButon} >Guardar</Button>
                </Button.List>
                </Form.Group>
            </Grid.Col>
          </Grid.Row>


           
      </div>
    );
  }
  changeEsFull=(event)=>{this.setState({esFull: event.target.checked})}
}
export default DatosMovimientoGpsForm;