import React from 'react';
import * as ACTIONS from '../store/actions/actions'
import { connect } from 'react-redux';
import ModalLayout from "./ModalLayout.react";
import { Crud_Catalogos, Crud_error } from '../herramientas/Crud_Catalogos';
import Mensajeria from '../socket/Mensajeria.react';
import { Form, Grid, Card, Table, Alert,Button, Icon, Dimmer, List } from "tabler-react";
import Columnas from '../pages/monitoreoPages/Columnas';
import { timeToDatefull } from "../herramientas/DateFormat";
import Select from "react-select";
var tableHeaders= JSON.parse(localStorage.getItem('tableHeaders'));

var alertaIncidencia={
  "idTransportista": -1,
  "idIncidencia": -1,
  "idAlertaTipo": -1,
  "operadorNombre": "",
  "operadorMedio": "",
  "unidadPlaca": "",
  "responsable": "",
  "fechaRegistro": null,
  "problema": "",
  "solucion": "",
  "observaciones": "",
  "id": 0
};
class Catalogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  elementos: [],
                    tableheaderItems: [], 
                    hiddenModals: false, 
                    readyData: false, 
                    message: "", 
                    ErrorMessage: [], 
                    showErrormessage: false, 
                    metodo: "get",
                    search: "", 
                    content:[], 
                    labels:[], 
                    date1: timeToDatefull(new Date().getTime()), 
                    date2: timeToDatefull(new Date().getTime()), 
                    noAtendidas: true,
                    incidencia: {label: "Selecciona una opción", value: 0, data:[]},
                    incidencias: [],
                    currentAlerta: [],
                    alertaTipo: [],
    };
    this.hiddenmodal= this.hiddenmodal.bind(this);
    this.ErrorMessage = "";
    this.ErrorMessageArray = [];
    this.catalogo = this.props.Ctlogo;
    this.catalogoTem = "";
    this.Catalogo_form = this.props.FCtlogo;
    this.tamanio = this.props.tamanio;
    this.updateStatus_elementos = this.updateStatus_elementos.bind(this);
    this.getfuntion = this.getfuntion.bind(this);
    this.content = [];
    this.colum= [];
    this.columna= [];
    this.fila= [];
    this.getMensage=false;
    this.mensaje= { "mensaje": "", "plataforma": "", "corredor": "", "vista": "", "data": null, "accion": "" };
    this.elements=[];
    this.incidencias= [];
  }
  componentDidUpdate=()=>{     
    if(this.props.alertas !== this.state.elementos && this.props.alertas[0]){
      this.setState({ elementos: this.props.alertas }); this.props.setAlertas(this.state.elementos); 
    }      
  }
  componentDidMount = () => {
    if (this.catalogo !== "" || this.catalogo !== null) {
      this.mensaje.mensaje = "Se acualizo: " + this.catalogo;
      this.getfuntion("get", [], "", [], "alertasTipos");
      this.getfuntion("get", [], "", [], "terrestresAlertasSinRuta/noAtendidas", this.props.alertasUnidades_update);
    }
    
  }

  componentWillMount = () => {  

  }

  changeIncidencia =(e)  =>{ 
    this.setState({ incidencia:    e});
    alertaIncidencia.idIncidencia   = e.value;        
  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
    this.setState({ metodo: "get" });
  }
  getfuntion =(metodo="get", params=[], id="", arraytoUpdate=[], catalogoTem=this.catalogo, storeFunction= "", columna="") => {
    if (catalogoTem !== ""){
      Crud_Catalogos(catalogoTem, this.catalogo, metodo, id, params, storeFunction, this.state.elementos, this.hiddenmodal, arraytoUpdate, columna).then((returnVal) => {
        switch (metodo) {
          case "get":
            if( catalogoTem.includes("terrestresAlertasSinRuta")){
              if(metodo !== "get"){
                this.props.updateStatusMensaje(true);
                this.mensaje.data= returnVal;
                this.mensaje.vista= catalogoTem;
              }        
              this.mensaje.accion= metodo
              if (catalogoTem === this.catalogo ){ this.setState({ readyData: true }); }
              this.updateStatus_elementos();
              this.setState({ metodo: metodo }); 
              this.state.incidencias.map((inc, index)=>{ if(inc.value == returnVal.idIncidencia){ this.setState({incidencia: inc})} });
            }
            if( catalogoTem === "alertasTipos" ){
              let alertaTipoTemp= [];
              returnVal.map((a)=> alertaTipoTemp.push({ value: a.id, label: a.nombre}) );
              this.setState({ alertaTipo: alertaTipoTemp });
            }
            break;
        
          default:
            break;
        }
        

      }).catch(err =>{
        if( err.response ){console.log(err);
          this.setState({ readyData: false });    this.setState({ showErrormessage: true });
          this.ErrorMessage = Crud_error(err.response.status, catalogoTem);
          this.ErrorMessageArray.push( <Alert type="danger" icon="alert-octagon" > {this.ErrorMessage} </Alert> );
          this.setState({ ErrorMessage: this.ErrorMessageArray });
        } else {
          this.setState({ readyData: false });
          this.setState({ showErrormessage: true });
          this.ErrorMessage = Crud_error(404, catalogoTem);
          this.ErrorMessageArray.push( <Alert type="danger" icon="alert-octagon"> {this.ErrorMessage} </Alert> );
          this.setState({ ErrorMessage: this.ErrorMessageArray });
        }
      });
    }
  }
  updateStatus_elementos =(vista) => {
    if(vista){
      this.elements= this.state.elementos;
      this.state.elementos.map((elemento, index) =>{
        if(vista.accion === "put"){ if(elemento.id === vista.data.id){ this.elements[index]= vista.data; }  }
        if(vista.accion === "post"){ if(elemento.id === vista.data.id && this.catalogo === vista.vista){ }else{ if(elemento.id === vista.data.id){ this.elements[index]= vista.data;} }  }
        if(vista.accion === "delete"){ if(elemento.id === vista.data.id && this.catalogo !==vista.vista){ this.elements[index]= vista.data; }else{ if(elemento.id === vista.data.id){this.elements.splice(index, 1) }} }
      });
      if(vista.vista === this.catalogo && this.elements !== this.state.elementos){ this.elements.push(vista.data); }
      this.setState({ elementos: this.elements});
      this.table_headers();
    }
    if(!vista){
      switch(this.catalogo){
        case "terrestresAlertasSinRuta/noAtendidas":{ this.setState({ elementos: this.props.alertasUnidades_object }); this.table_headers();};
        default: return;
      }      
    }
  }
  table_headers =() => {
    let labels= [];
    if(this.state.elementos[0]){
      if(tableHeaders){
        this.setLabels(tableHeaders);
      }else{
        Object.keys(this.state.elementos[0]).forEach(key =>{
          switch (key) {
            case 'id':              labels.push({ label: 'ID',                  value: key, status: true });  break;
            case 'idTransportista': labels.push({ label: 'TRANSPORTISTA',       value: key, status: true });  break;
            case 'idUnidad':        labels.push({ label: 'UNIDAD',              value: key, status: true });  break;
            case 'placa':           labels.push({ label: 'PLACA',               value: key, status: true });  break;
            case 'idAlertaTipo':    labels.push({ label: 'TIPO DE ALERTA',      value: key, status: true });  break;
            case 'causa':           labels.push({ label: 'CAUSA',               value: key, status: true });  break;
            case 'triggerName':     labels.push({ label: 'TRIGGER',             value: key, status: true });  break;
            case 'latitud':         labels.push({ label: 'LATITUD',             value: key, status: true });  break;
            case 'longitud':        labels.push({ label: 'LONGITUD',            value: key, status: true });  break;
            case 'mensaje':         labels.push({ label: 'MENSAJE',             value: key, status: true });  break;
            case 'fechaCreacion':   labels.push({ label: 'FECHA REGISTRO',      value: key, status: true });  break;
            case 'atendida':        labels.push({ label: 'ATENDIDA',            value: key, status: true });  break;
            case 'atiendeUsuario':  labels.push({ label: 'USUARIO QUE ATIENDE',  value: key, status: true });  break;
            case 'atiendeFecha':    labels.push({ label: 'FECHA ATENCIÓN',      value: key, status: true });  break;
            case 'detalle':         labels.push({ label: 'VER',                 value: key, status: true });  break;
            default:  break;
          }
        }       
        );
        this.setLabels(labels);
        localStorage.setItem('tableHeaders', JSON.stringify(labels) );
      }     
    }
  }
  setLabels=(lbls)=>{
    this.content= [];
    for (let item of lbls) {
      if( item.status ){ this.content.push({ content: item.label }); }
    }
    this.setState({content: this.content});
    this.setState({ labels: lbls});
  }
  isobject =() => {
    if (typeof this.columna === "object") {
      if(this.columna.length > 1){ for (let item of this.columna) { this.colum.push(<Table.Col>{ item["nombre"]+" ..." }</Table.Col>); break; }
      }else{ if (this.columna.length === 1) { for (let item of this.columna) { this.colum.push(<Table.Col>{ item["nombre"]+" ..." }</Table.Col>); break; }
        }else{
          if (this.catalogo==='usuarios'){ this.colum.push(<Table.Col>{ <ModalLayout tamanio = {this.tamanio} title= "Modificar" name= {this.columna["nombre"] ? this.columna["nombre"]+" "+this.columna["aPaterno"]+" "+this.columna["aMaterno"]: this.columna["username"]} hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}  elementos= { this.fila } getfuntion= {this.getfuntion} stor= { this.props } formulario= { <this.Catalogo_form accion="modificar"  /> } /> }</Table.Col>);
          }else{ this.colum.push(<Table.Col>{ this.columna["nombre"]+" ..." }</Table.Col>);  }
        }
      }
    }else { this.colum.push(<Table.Col>{ this.columna["nombre"]+" ..." }</Table.Col>); }
  }
  searchingbyName =(event) =>{ this.setState({ search: event.target.value }); }
  
  changeDate1=(e)=>{ 
    let today= new Date(timeToDatefull(new Date().getTime())).getTime();
    let fecha= new Date(e.target.value).getTime();
    let fecha2= new Date(this.state.date2).getTime();
    if(fecha > fecha2 && fecha < today){ this.setState({ date2: e.target.value }); }
    if(fecha <= today ){ this.setState({ date1: e.target.value }); }
  }
  changeDate2=(e)=>{
    let today= new Date(timeToDatefull(new Date().getTime())).getTime();
    let fecha= new Date(e.target.value).getTime();
    let fecha1= new Date(this.state.date1).getTime();
    if(fecha < fecha1 && fecha < today){  this.setState({ date1: e.target.value }); }
    if(fecha <= today ){ this.setState({ date2: e.target.value }); }
  }
  getByDates=()=>{ this.getfuntion("get", [], "", [], "terrestresAlertasSinRuta/fecha1/"+this.state.date1+" 00:00:00/fecha2/"+this.state.date2+" 23:59:59", this.props.alertasUnidades_update); }
  changeNoAtendidas=(e)=> { this.setState({ noAtendidas: e.target.checked }); 
    if(!e.target.checked){ this.getByDates(); }else{ this.getfuntion("get", [], "", [], "terrestresAlertasSinRuta/noAtendidas", this.props.alertasUnidades_update); }
  }
    render() {
        return (
          <div>
            { this.state.readyData ===true && this.state.elementos ?
              <Grid.Col lg={12}>
                <Mensajeria topic={[ "/topic/alertasunidades" ]}  mensaje={this.mensaje}   elementos={ this.state.elementos}  storeFunction= {this.props.alertasUnidades_update}
                            update={ this.updateStatus_elementos}  openmodal={this.state.hiddenModals}  metodo={ this.state.metodo } stor={this.props} />
                <div className= "m-3"></div>
                <Card title={ "." } isCollapsible={ false } isCollapsed={ false }
                      options={ <div>                                  
                                  <span className="float-right text-dark btn" data-toggle="tooltip" data-placement="top" title="Configurar columnas">
                                    <ModalLayout  
                                      tamanio={this.tamanio}
                                      hiddenModal={ this.state.hiddenModals }
                                      Fclose={this.hiddenmodal}
                                      title= "Nuevo"
                                      name= {<Icon name="settings" className="h2"/>}
                                      formulario= { <Columnas accion="nuevo" elementos= { this.state.labels } setLabels={ this.setLabels } getfuntion= {this.getfuntion} stor= { this.props }/> }
                                    />
                                  </span>
                                  <span className="mr-1 float-right" data-toggle="tooltip" data-placement="top" title={this.state.noAtendidas? "Estas viendo alertas no atendidas":"Estas viendo alertas por fechas"}>
                                    <Form.Switch type="Checkbox" checked={this.state.noAtendidas}  onChange={ this.changeNoAtendidas } />
                                  </span>
                                  <span className="mr-1 ml-1 float-right"> <Form.Input className="mb-3" icon="search" placeholder="Ingresa PLACA" position="append" onChange={this.searchingbyName} /> </span>
                                  {!this.state.noAtendidas?
                                    <span className="mr-1 float-right btn text-dark" onClick={()=> this.getByDates() }><Icon name="refresh-cw"/></span>
                                  :null}
                                  {!this.state.noAtendidas?
                                    <span className="mr-1 float-right"><Form.Input name="date2" type="date" value={this.state.date2} onChange={this.changeDate2 } disabled={this.state.noAtendidas} /></span>
                                  :null}
                                  {!this.state.noAtendidas?
                                    <span className="mr-1 ml-1 float-right">y</span>
                                  :null}
                                  {!this.state.noAtendidas?
                                    <span className="mr-1 float-right"><Form.Input name="date1" type="date" value={this.state.date1} onChange={this.changeDate1}  disabled={this.state.noAtendidas} /></span>
                                  :null}
                                </div>
                              }>
              <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={this.state.content} >
               <Table.Header>
               </Table.Header>
                <Table.Body>
                  {
                    this.state.elementos.filter(word => word.placa ?
                                                        word.placa.toUpperCase().includes(this.state.search.toUpperCase()):
                                                        word.estatus !== -1
                                                        ).sort().map((elemento, index) =>
                      <Table.Row
                        onClick={()=>{ if(!this.props.manual){ this.props.setCurrentAlerta(elemento); } } }
                        style={ this.props.getCurrentAlerta().id && elemento.id === this.props.getCurrentAlerta().id ? { "background-color": "rgba(95, 231, 50, 0.30)" } : null}
                      >
                        {this.colum = []}
                        {
                          this.state.labels.map((lbl, indice1)=>{
                            if(lbl.status){
                              if (typeof elemento[lbl.value] === "object" && elemento[lbl.value] !== null) { this.columna = elemento[lbl.value]; this.fila = elemento; this.isobject();
                              }else{
                                switch (lbl.value) {
                                  case "atendida":      this.colum.push(<Table.Col>{<Form.Switch type="Checkbox" checked={elemento[lbl.value]} disabled={true} />}</Table.Col>); break;
                                  case "detalle":       this.colum.push(
                                                          <Table.Col>
                                                            <Button pill size="sm" outline color="primary"
                                                              onClick= { ()=> window.open("https://maps.google.com/?q="+elemento.latitud+","+elemento.longitud+"&t=m", 'sharer', 'width=900,height=600,scrollbars=NO') }
                                                            >
                                                              <div className={'fa fa-map-marker'} style={{ color:'green'}} ></div>
                                                            </Button>
                                                          </Table.Col>); break;
                                  default:              this.colum.push(<Table.Col> { elemento[lbl.value] } </Table.Col>); break;
                                }
                              }
                            }
                          })
                        }
                      </Table.Row>
                    )
                  }
                </Table.Body>
              </Table>
            </Card>
            <div hidden={!this.state.showErrormessage}> {this.state.ErrorMessage} </div>
          </Grid.Col>
          : this.ErrorMessageArray}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    alertasUnidades_object:           state.catalogos_reducer.alertasUnidades_object,
    alertasTipos_object:              state.catalogos_reducer.alertasTipos_object
  }
}
function mapDispachToProps(dispach) {
  return {
    alertasUnidades_update:         (elementos) => dispach(ACTIONS.elementos_alertasUnidades_imput(elementos)),
  }
}
export default connect(mapStateToProps, mapDispachToProps)(Catalogo)
