import axios from 'axios';
//import {NotificationManager} from 'react-notifications';
import * as conf from "../../../../../config/config";

let instance = axios.create();
//let url= "http://54.227.1.17:8080/skyone/uploadFile";
let url= conf.api_raiz+"uploadFile";
var tokenStore="tok";
instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';

export function LoadPicture(image){
    console.log(image);
    var formData = new FormData();
    formData.append("file",image);
    return axios.post(url, formData).then(res => {
        if( res.data ){
            if( res.data.fileName ){
                return res.data;
            }else{ return  false; }
        }else{ return false; }
    }).catch(err =>{ console.log(err); alert("error"); return false; });
}
export default LoadPicture;