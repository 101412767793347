import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
var user= JSON.parse(sessionStorage.getItem("usuario"));
var disabeleButton= false;
class MovimientoUnidad extends Component {
  constructor(props) {
    super(props);
    this.state = {
                 unidades: [],
                   unidad: null,
                     full: false,
                 accion:this.props.accion, //'nuevo'// modificar, nuevo
    };
    this.movimiento= [];
  }
  componentWillMount = () => {
  }
  componentWillUpdate = ()=>{
    if(this.movimiento !== this.props.movimiento){
      this.movimiento =  this.props.movimiento;
      this.getfuntion("get","","","unidadTerNav/idMovimiento/"+this.props.movimiento.id, "unidad");
      this.setState({ id                    : this.props.movimiento.id });
    }
  }
  componentDidMount = () => {
    this.setState({ id                    : this.props.movimiento.id });
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get": {
          if (stateVar === "unidad"){
            if(returnVal){
              this.setState({ unidad: returnVal });               
            }else{
              this.getfuntion("get","","","unidadTerNav/disponibles/"+this.props.movimiento.idPlataforma.id+"/"+this.props.movimiento.idTransportista.id, "unidades");
            }
          }
          if (stateVar === "unidades" && returnVal){
              this.setState({ unidades: returnVal });
          }
          if (stateVar === "movimientoUnidad"){
            return returnVal;
          }
          
         } break;
         case "post": {
          if (stateVar === "unidad"){
            disabeleButton= false;
            this.getfuntion("get","","","unidadTerNav/idMovimiento/"+this.props.movimiento.id, "unidad");           
          }
        } break;
        case "delete": {
          if (stateVar === "unidad"){
            if(this.state.unidad.id){
              if(this.props.movimiento.id+"/"+this.state.unidad.id){
                this.setState({ unidad: null });
              }
            }            
          }
        } break;
        case "put": {
          console.log("153 Put this.usuariosLogObject");
          //console.log(this.usuariosLogObject);
        } break;
        default:    break;
      }
    }).catch(err => {
      console.log(err);
      switch (metodo) {
         case "post": {
          if (stateVar === "unidad"){
            disabeleButton= false;
            alert("Ocurrio un arror al asignar la unidad vuelve a intentarlo");
          }
        } break;
        default:    break;
      }
    });
  }
  
  removerUnidad =(id)=>{
    confirmAlert({
      title: 'Antención',
      message: '¿Esta seguro de remover la unidad del mov('+this.props.movimiento.id+') ?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { this.getfuntion("delete","",this.props.movimiento.id+"/"+id,"movimientoUnidadTerNav", "unidad"); }
        },
        {
          label: 'Cancelar',
          onClick: () => {return false}
        }
      ]
    });
  }
  hacerFulll=(e)=>{
    this.setState({ full: e.target.checked });
    if( e.target.checked ){
      this.getfuntion("get","","","unidadTerNav/enruta/"+this.props.movimiento.idPlataforma.id+"/"+this.props.movimiento.idTransportista.id, "unidades");
    }else{
      this.getfuntion("get","","","unidadTerNav/disponibles/"+this.props.movimiento.idPlataforma.id+"/"+this.props.movimiento.idTransportista.id, "unidades");
    }
  }
  movFull=(un)=>{
    this.getfuntion("get","","","movimientoUnidadTerNav/idUnidadTerNav/"+un.id, "movimientoUnidad").then((unidades) => {
      if(unidades){
        let inc= unidades.sort(function (a, b) {
          if (a.idMovimiento > b.idMovimiento) {
            return -1;
          }
          if (a.idMovimiento < b.idMovimientoid) {
            return 1;
          }
          return 0;
        });
        this.getfuntion("post",{"idMovimiento": this.props.movimiento.id, "idUnidadTerNav": un.id}, "","movimientoUnidadTerNav/full/"+inc[0].idMovimiento, "unidad");
      }
    });
  }
  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Col><h1>{this.props.movimiento.idPlataforma.nombre.toUpperCase()}, LT: {this.props.movimiento.idTransportista.nombre}</h1> </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          { this.state.unidad?
            <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={[{ content: "ID Mov" }, { content: "Unidad" }, { content: "Placa" }, { content: "Remover" }]} >
              <Table.Body>
                <Table.Row>
                  <Table.Col> {this.props.movimiento.id}  </Table.Col>
                  <Table.Col> {this.state.unidad? this.state.unidad.nombre: "" }</Table.Col>
                  <Table.Col> {this.state.unidad? this.state.unidad.placa: "" }</Table.Col>
                  <Table.Col> <Button target="_blank" size="sm" color="danger" onClick={() => this.removerUnidad(this.state.unidad.id) } > <span className="badge"><Icon link={true} name="trash" />Remover unidad</span> </Button></Table.Col>
                </Table.Row>
              </Table.Body>
            </Table>: null
          }          
        </Grid.Row>
        <div hidden={this.state.unidad} >
          <h2> Selecciona una unidad </h2>
          <Form.Switch type="Checkbox" name="full" value="option1" label="Hacer full" checked={this.state.full}  onChange={this.hacerFulll} />
          <Grid.Row>
            <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={[{ content: "ID Unidad" }, { content: "Nombre" }, { content: "Placas" }, { content: "Agregar" }]} >
              <Table.Body>
                { this.state.unidades.map((un, index)=>
                  <Table.Row>
                    <Table.Col> {un.id}  </Table.Col>
                    <Table.Col> {un.nombre}</Table.Col>
                    <Table.Col> {un.placa}</Table.Col>

                    <Table.Col> <Button target="_blank" size="sm" color="primary"
                                  onClick={() => this.agregar(un)} >
                                  <span className="badge"><Icon link={true} name="plus" />Agregar</span>
                                </Button></Table.Col>
                  </Table.Row>
                ) }                
              </Table.Body>
            </Table>
          </Grid.Row>
        </div>
      </div>
    );
  }
  agregar=(un)=>{
    if( !disabeleButton ){
      disabeleButton= true;
      if( !this.state.full ){
        this.getfuntion("post",{"idMovimiento": this.props.movimiento.id, "idUnidadTerNav": un.id}, "","movimientoUnidadTerNav/idusuario/"+user.id, "unidad");
      }else{
        this.movFull(un);
      }
    }
  }
}
export default MovimientoUnidad;
