import React, { Component } from 'react';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { Dimmer, Button, Icon, Form } from "tabler-react";
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import {NotificationContainer, NotificationManager} from 'react-notifications';
var contadorDeCarga= 0;
class ImportarUnidades extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
                  accion:this.props.accion,
                    data:[], // datos nuevos
         descargandoUnidades: 0,
         datosValidos:[], // datos validos nuevos
         datosNoValidos: [],  // Datos no validos nuevos
         contadorDeCarga: 0,
    };
    this.data=[]; // archivo de excel
  }
  componentWillMount(){
  }

  onImportExcel = file => {
		//this.state.data= [];
    const { files } = file.target;
    console.log("fileName");
    console.log(files[0].name);

    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            console.log("sheet: " + sheet);
            //this.setState({catalogo: sheet });//this.props.data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
            this.data= XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            console.log(this.data);
            this.setState({ data: this.data });
          }
        }
        this.validarDatos();
        //console.log(this.state.data);
        console.log('Carga de Archivo Exitosa！');
      } catch (e) {
        console.log("Error! "+e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
    this.openModal();
    //cargar plataformasClientes
    this.setState({descargandoUnidades : 1 });
}

openModal = () => {
  //this.props.Fclose();
  this.setState({modalIsOpen: true});
}
closeModal = () => {
  this.setState({modalIsOpen: false});
}
handleModalCloseRequest = () => {
  this.setState({modalIsOpen: false});
}

getfuntion = (metodo = "get", obj = [], id = "", catalogo = "cliente", stateVar = "clientes", hiddenModl = "") => {
  return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
    /**
     * filtro de variables
     */
    switch (metodo) {
      case "get":
        {
          if (stateVar === "Unidades" && returnVal) {
              this.unidades= returnVal;
          }
          if (stateVar === "transportistas" && returnVal) {
            return returnVal;
          }
        }
        break;
      case "post":
        {
          if (stateVar === "Unidades") {
            contadorDeCarga++;
            this.setState({contadorDeCarga: contadorDeCarga});
            if(this.state.datosValidos[contadorDeCarga]){
              setTimeout( ()=> this.cargarDatosCorrectos(),  200);
            }else{
              contadorDeCarga=0;
            }
          }
        }
        break;
      case "put":
        {
          if (stateVar === "Unidades") {
            contadorDeCarga++;
            this.setState({contadorDeCarga: contadorDeCarga});
            if(this.state.datosValidos[contadorDeCarga]){
              setTimeout( ()=> this.cargarDatosCorrectos(),  200);
            }else{
              contadorDeCarga=0;
            }
          }
        }
        break;
      default:
        break;
    }
  }).catch(err => {
    console.log(err)
  });
}
validarDatos =()=>{
  let jsonUnidad = { id: 0, idPlataforma:  this.props.idPlataforma, idTransportista: null, idUnidadTipo: null, idUnidadMarca: null, nombre: null, placa: null, color: null, modelo: null, estatus: null, idUnidadEstatusOperacion:  null};
  let validos= [];
  let noValidos= [];
  this.getfuntion("get", "", "", "transportistas/plataforma/"+this.props.idPlataforma.id, "transportistas").then((returnVal) => {
    for( let item of this.data ){
      let err= false;
       Object.keys(item).forEach(key => {
         switch ( key) {
           case "id": jsonUnidad.id= item[key];
             break;
           case "idPlataforma": if( item[key] === this.props.idPlataforma.id || item[key] === this.props.idPlataforma.nombre ){ jsonUnidad.idPlataforma= this.props.idPlataforma }
             break;
           case "idTransportista": for( let trans of returnVal ){ if( trans.id === item[key] || item[key] === trans.nombre ){ jsonUnidad.idTransportista= trans } }
             break;
           case "idUnidadTipo":
             this.props.stor.unidadTipo_object.map((elemento, index) => {
               if( item[key] === elemento.id || item[key] === elemento.nombre ){ jsonUnidad.idUnidadTipo= elemento }
             });
             break;
           case "idUnidadMarca":
             this.props.stor.unidadesmarcas_object.map((elemento, index) => {
               if( item[key] === elemento.id || item[key] === elemento.nombre ){ jsonUnidad.idUnidadMarca= elemento }
             });
             break;
           case "nombre": jsonUnidad.nombre= item[key];
             break;
           case "placa":  jsonUnidad.placa= item[key];
             break;
           case "color":  jsonUnidad.color= item[key];
             break;
           case "modelo": jsonUnidad.modelo= item[key];
             break;
           case "estatus": jsonUnidad.estatus= item[key]? true: false ;
             break;
           case "idUnidadEstatusOperacion":
             this.props.stor.unidadEstatusOperacion_object.map((elemento, index) => {
               if( item[key] === elemento.id || item[key] === elemento.nombre ){ jsonUnidad.idUnidadEstatusOperacion= elemento }
             });
             break;
           default:
             break;
         }
        });
      if( jsonUnidad.idPlataforma !== null && jsonUnidad.idTransportista !== null && jsonUnidad.idUnidadTipo !== null &&
          jsonUnidad.idUnidadMarca !== null && jsonUnidad.placa !== null && jsonUnidad.idUnidadEstatusOperacion !== null ){
          if(!jsonUnidad.nombre){ jsonUnidad.nombre= jsonUnidad.idUnidadMarca.nombre+" "+(item['modelo']?item['modelo']:"")+" "+item['placa']; }
        validos.push( jsonUnidad );
      }else{
        let err= "";
        if( jsonUnidad.idTransportista          === null ){ jsonUnidad.idTransportista= item.idTransportista;          err= err+ (err.length>0? ", ":"")+ "transportista no valido( o no pertenece a la plataforma)" }
        if( jsonUnidad.idUnidadTipo             === null ){ jsonUnidad.idUnidadTipo= item.idUnidadTipo;             err= err+ (err.length>0? ", ":"")+ "tipo de unidad no valido o no existe en el catalogo" }
        if( jsonUnidad.idUnidadMarca            === null ){ jsonUnidad.idUnidadMarca= item.idUnidadMarca;            err= err+ (err.length>0? ", ":"")+ "unidad marca no valido o no existe en el catalogo" }
        if( jsonUnidad.idUnidadEstatusOperacion === null ){ jsonUnidad.idUnidadEstatusOperacion= item.idUnidadEstatusOperacion; err= err+ (err.length>0? ", ":"")+ "operación estatus no valido o no existe en el catalogo" }
        if( jsonUnidad.placa                    === null ){ err= err+ (err.length>0? ", ":"")+ "la placa es requerida" }
        jsonUnidad.error= err;
        console.log(jsonUnidad);
        noValidos.push( jsonUnidad );
      }
      jsonUnidad = { id: 0, idPlataforma:  this.props.idPlataforma, idTransportista: null, idUnidadTipo: null, idUnidadMarca: null, nombre: null, placa: null, color: null, modelo: null, estatus: null, idUnidadEstatusOperacion:  null};
    }
    this.setState({ datosValidos: validos });
    this.setState({ datosNoValidos: noValidos });
    this.setState({ descargandoUnidades: 2 });
  });
}
cargarDatosCorrectos=()=>{
  console.log(new Date()+" guardando: "+contadorDeCarga)
  if(this.state.datosValidos[contadorDeCarga]){
    this.getfuntion(this.state.datosValidos[contadorDeCarga].id?"put":"post", this.state.datosValidos[contadorDeCarga], "", "unidadTerNav", "Unidades");
  }else{
    contadorDeCarga=0;
  }
}
render() {
    return (
      <>
      <Button target="_blank" size="sm" color="primary" icon={"file-text"} className="text-white" onClick={()=> this.openModal()}>
      </Button>
      <Modal
          closeTimeoutMS={50}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.handleModalCloseRequest}
          className="Modal"
          overlayClassName="Overlay"
          style = {this.customStyles}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title"> Unidades </h4>
              <p>El tiempo de carga y validacion dependera de su conexión a internet y las caracteristicas de hardware y software de su equipo</p>
              <button type="button" className="btn btn-dark fa fa-close outline pill " onClick={this.handleModalCloseRequest}></button>
            </div>
            <div className="modal-body">
              {this.state.data.length >0?null:
              <Button target="_blank" size="sm" color="primary" icon={"file-text"} className="text-white">
                <input type='file' id="file" className="file" accept='.xlsx, .xls' onChange={this.onImportExcel} ></input>
              </Button>}
              { this.state.data.length>0 && this.state.descargandoUnidades == 1?
                  <div>Validando Datos <Dimmer active={true} loader></Dimmer></div>
                : this.state.descargandoUnidades == 2 ?
                    <div className="row">
                      <div className="col">
                        { this.state.data.length == 0 ?
                            <h3>No hay datos nuevos ..</h3>
                          :
                            <h3>Finalizado</h3>
                        }
                      </div>
                    </div>
                  :this.state.descargandoUnidades == 2 ? <div>Finalizado, No hay datos nuevos</div>: null
              }

              {/**Mostrar datos antes de subir, datos correctos, datos incorrectos */}
              <div hidden={this.state.datosValidos.length === 0 && this.state.datosNoValidos.length === 0 }>
                <div className="row">
                  <div className="col">Datos correctos: { this.state.datosValidos.length}</div>
                  <div className="col">Datos con errores: {this.state.datosNoValidos.length} </div>
                </div>
                <h4>aqui se listaran los datos correctos</h4>
                <div className="row mt-4">
                  <div className="col-10">
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{"width": ((100/this.state.datosValidos.length)* this.state.contadorDeCarga)+"%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{Math.round((100/this.state.datosValidos.length)* this.state.contadorDeCarga)+"%"}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    <Button size="sm" color="primary" icon={"save"} className="text-white" onClick={()=> this.cargarDatosCorrectos() } disabled={ this.state.data.length === 0 || this.state.contadorDeCarga > 0}>Guardar datos validos </Button>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-1"> id </div>
                  <div className="col-1"> idPlataforma </div>
                  <div className="col-1"> idTransportista </div>
                  <div className="col-1"> idUnidadTipo </div>
                  <div className="col-1"> idUnidadMarca </div>
                  <div className="col-1"> nombre </div>
                  <div className="col-1"> placa </div>
                  <div className="col-1"> color </div>
                  <div className="col-1"> modelo </div>
                  <div className="col-1"> estatus </div>
                  <div className="col-1"> idUnidadEstatusOperacion </div>
                </div>
                { this.state.datosValidos.length > 0?
                  this.state.datosValidos.map((elemento, index)=>
                    <div className="row mt-4">
                      <div className="col-1"> {elemento.id } </div>
                      <div className="col-1"> {elemento.idPlataforma.nombre } </div>
                      <div className="col-1"> {elemento.idTransportista.nombre } </div>
                      <div className="col-1"> {elemento.idUnidadTipo.nombre } </div>
                      <div className="col-1"> {elemento.idUnidadMarca.nombre } </div>
                      <div className="col-1"> {elemento.nombre } </div>
                      <div className="col-1"> {elemento.placa } </div>
                      <div className="col-1"> {elemento.color } </div>
                      <div className="col-1"> {elemento.modelo } </div>
                      <div className="col-1"> {elemento.estatus? "true": "false" } </div>
                      <div className="col-1"> {elemento.idUnidadEstatusOperacion.nombre } </div>
                    </div>
                  )
                  :null
                }
                <h4>aqui se listaran los datos con errores</h4>
                      <div className="row mt-4">
                        <div className="col-1"> id </div>
                        <div className="col-1"> idPlataforma </div>
                        <div className="col-1"> idTransportista </div>
                        <div className="col-1"> idUnidadTipo </div>
                        <div className="col-1"> idUnidadMarca </div>
                        <div className="col-1"> nombre </div>
                        <div className="col-1"> placa </div>
                        <div className="col-1"> color </div>
                        <div className="col-1"> modelo </div>
                        <div className="col-1"> estatus </div>
                        <div className="col-1"> idUnidadEstatusOperacion </div>
                        <div className="col-1"> error </div>
                      </div>
                  { this.state.datosNoValidos.length > 0?
                    this.state.datosNoValidos.map((elemento, index)=>
                      <div className="row mt-4">{console.log(elemento) }
                        <div className="col-1"> {elemento.id } </div>
                        <div className="col-1"> {elemento.idPlataforma? elemento.idPlataforma.nombre: "" } </div>
                        <div className="col-1"> { elemento.idTransportista.nombre? elemento.idTransportista.nombre:  elemento.idTransportista } </div>
                        <div className="col-1"> {elemento.idUnidadTipo.nombre? elemento.idUnidadTipo.nombre: elemento.idUnidadTipo } </div>
                        <div className="col-1"> {elemento.idUnidadMarca.nombre? elemento.idUnidadMarca.nombre: elemento.idUnidadMarca } </div>
                        <div className="col-1"> {elemento.nombre } </div>
                        <div className="col-1"> {elemento.placa } </div>
                        <div className="col-1"> {elemento.color } </div>
                        <div className="col-1"> {elemento.modelo } </div>
                        <div className="col-1"> {elemento.estatus } </div>
                        <div className="col-1"> {elemento.idUnidadEstatusOperacion.nombre ? elemento.idUnidadEstatusOperacion.nombre: elemento.idUnidadEstatusOperacion } </div>
                        <div className="col-1"> {elemento.error } </div>
                      </div>
                    )
                    :null
                  }
              </div>
            </div>

          </div>
        </Modal>
      </>
    );
  }
}
export default ImportarUnidades;
