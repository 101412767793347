import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import TabsLayout  from "../../components/TabsLayout.react";
import { Transportistas, Plataformas, Contactos, Etiquetas } from "./Formularios"

class  TransportistaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
                   currentTap:0,
                           id:"",
                       accion:this.props.accion, //'nuevo'// modificar,
                        ready:false
                 };
            this.tabs =[];//this.props.elementos.nombre
            this.Plataformas    ={ title:"Plataformas",    icon:"layout",   panel:<Plataformas    accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
            this.Transportistas ={ title:"Transportistas", icon:"layout",   panel:<Transportistas plataforma={this.props.plataforma } accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
            this.Etiquetas      ={ title:"Etiquetas", icon:"git-merge",panel:<Etiquetas   accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
            this.Contactos      ={ title:"Contactos" ,     icon:"layout",   panel:<Contactos      accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
  }
  componentDidMount =() => { if(this.state.accion !== 'nuevo'){ this.setState({id:this.props.elementos.id}); }else{ } };

  componentWillMount =() => {
    if(this.state.accion !== 'nuevo'){
      if (this.props.elementos.tipo===1){
        this.tabs= [
          this.Transportistas, this.Contactos, this.Etiquetas
        ];
      }else{
        this.tabs= [
          this.Transportistas, this.Contactos, this.Plataformas, this.Etiquetas
        ];
      }
    }else{
      this.tabs= [ this.Transportistas ];
    }    
  }
  selectedTab =(index) => { this.setState({ currentTap:index }) }
  render() {
    return (
      <div>
        <TabsLayout tabs={ this.tabs } selectedTab= { this.state.currentTap } selectTab= { this.selectedTab } />
      </div>
    )
  }
}

export default TransportistaForm
