import React, { Component } from "react";
import { Button, Form, Icon, Grid, Table, Card, Dimmer, List, Alert } from "tabler-react";
import Select from "react-select";
import ModalLayout from "../../../components/ModalLayout.react";
import { AlertaTForm } from "./index"
import MovimientoIncidenciasTerrestre from "../MovimientoIncidenciasTerrestre";
import MovimientoIncidencias from "../MovimientoIncidencias";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { NotificationManager } from 'react-notifications';
import { Arroba, ArrobaIncidencia, ArrobaTerrestre, ArrobaIncidenciaTerrestre } from "../Arroba";
import { ImportarT, Exportar, Plantilla } from '../../../herramientas/Excel';
import IncidenciasTraxion from "../../Monitoreo/IncidenciasTraxion";
/**
 * Date tools
 */
 import { timeToDateFill } from "../../../herramientas/DateFormat";

var user = JSON.parse(sessionStorage.getItem("usuario"));
//Formato de Fecha
function pad(number) {
  if (number < 10) { return '0' + number; }
  return number;
}
function myFormato(date) {
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}

class AlertasListar extends Component {
  constructor(props){
    super(props);
    this.state = {
      showIncidenciaF : false,
      incidencias     : [],
      hiddenModal     : false,
      entregas        : [],
      entregasActiva  : "",
      arrayEActivas   : [],
      filas            : [],
    };
    this.encabezados  =[];
    this.aPlacas      =[];
    this.alertas= [];
  }
  hiddenmodal = () => {
    this.setState({ hiddenModal: !this.state.hiddenModal });
  }
  movimientoTerrestrePlaca(id){
    let temporal ="";
    if (id){
      temporal = this.props.movimientosTerrestres? this.props.movimientosTerrestres.filter(word => word.id===id):[];
      if (temporal[0]){
        return temporal[0].placa;
      } else {
        // temporal = this.props.gps ? this.props.gps[id].nombre:'';
        console.log('entró al else');
        try {
          temporal = this.props.gps[id] ? this.props.gps[id].nombre : '';
        } catch(e){
          console.log("Error al obtener la placa: "+e);
        }
        if (temporal!=""){
        return temporal;
      }
      }
    }
  }
  getMovimientoTer(id){
    let movTem = this.props.movimientosTerrestres? this.props.movimientosTerrestres.filter(word => word.id===id):[];
      if (movTem[0]){
        return movTem[0];
      }else{
        return null;
      }
  }
  movimientoTerrestreEco(id){
    if (id){
     
      try {
        if (this.props.gps[id].iconBattery.color){
          return(
            <div>
              <div  className="badge" style={{ color:this.props.gps[id].iconBattery.color, "font-size":"80%" }}>{ this.props.gps[id].nombre }</div>
              <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.props.gps[id].nombre+", "+this.props.gps[id].nivel_bateria+" %" }
                                            style={{ color:this.props.gps[id].iconBattery.color, "font-size":"70%" }}>
                <span className={this.props.gps[id].iconBattery.icono}></span>
              </div>
              <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.props.gps[id].ultimo_reporte }
                                            style={{ color:this.props.gps[id].iconTime.color, "font-size":"70%" }}>
                <span className={this.props.gps[id].iconTime.icono}></span>
              </div>
            </div>
          );
        }
      }catch(e){
        //console.log("Error de iconBatery: "+e);
      }
    }
  }
  
  movimientoTerrestreEActiva(id){
    let entrGas = [];
    entrGas = this.state.arrayEActivas.filter(word => word.idMovimiento==id);
    if (entrGas){
      //entrAtva=entrGas.filter(word => word.estatus==1);
      return entrGas.idEntregaActiva;
    }
    /*
    let entrAtva = [];

    if (this.props.entregas){
      entrGas = this.props.entregas.filter(word => word.idMovimiento==id);
      if (entrGas){
        entrAtva=entrGas.filter(word => word.estatus==1);
      }
    }
    else//if (this.state.entregas)
    {
      entrGas = this.state.entregas.filter(word => word.idMovimiento==id);
      if (entrGas){
        entrAtva=entrGas.filter(word => word.estatus==1);
      }
    }
    if (entrAtva[0]){
      return entrAtva[0];
    }else{
      return 0;
    }
    */
  }
  alertaTer(tmpAlerta, index) {
      tmpAlerta.atendida=1;
      tmpAlerta.atiendeFecha = timeToDateFill(new Date().getTime());
      tmpAlerta.atiendeUsuario=this.props.usuario.id;
      this.props.getfuntion("put", tmpAlerta, "", "terrestresAlertas","", this.props.closeModal);
      this.state.filas.splice(index, 1);
      this.setState({ filas: this.state.filas });
  }
  
  alertaNav(alertN) {
    alertN.atendida=1;
    alertN.atiendeFecha = timeToDateFill(new Date().getTime());
    alertN.atiendeUsuario=this.props.usuario.id;
    this.props.getfuntion("put", alertN, "", "navierasAlertas","");
  }
  ordenarAsc(p_array_json, p_key)
  {
   p_array_json.sort(function (a, b) {
      return a[p_key] > b[p_key];
   });
  }
  getAlertas(){
    this.setState({filas:[]});
    let filasTemp= [];
    if(this.props.accion==="modificar"){
      this.encabezados=([{ content:"MovId" },{ content:"Placa" },{ content:"AlertaId" },{ content:"Triggername" },{ content:"Fecha-GPSGate" },{ content:"Atender" },{ content:"incidencia" },{ content:"Detalle" },{ content:"Ubicacion" }]);
      if(this.props.alerta.length>0){
        if(this.props.plataformaTipo === "Naviera"){
          this.props.alerta.sort(function(a,b) {
            if (a.alert["fechaRegistro"] > b.alert["fechaRegistro"]) {  return -1; }
            if (a.alert["fechaRegistro"] < b.alert["fechaRegistro"]) {  return 1;}
            return 0;
          }).map((a, index) => {
            let alert = a.alert;
            filasTemp.push(
              <Table.Row>
                <Table.Col>{alert.idMovimiento}</Table.Col>
                {/* <Table.Col>{this.movimientoTerrestreEco(alert.idMovimiento)}</Table.Col> */}
                <Table.Col>{this.movimientoTerrestrePlaca(alert.idMovimiento)}</Table.Col>
                <Table.Col>{alert.id}</Table.Col>
                <Table.Col>{alert.triggerName}</Table.Col>
                <Table.Col>{ alert.fechaRegistro }</Table.Col>
                <Table.Col>
                  <Button pill size="sm" outline color="primary" onClick= { () => this.props.plataformaTipo === "Naviera"? this.alertaNav(alert): this.alertaTer(alert, index)}><div className={'fa fa-bell'} style={{ color:'blue'}} ></div></Button>
                </Table.Col>
                <Table.Col>
                  { this.getMovimientoTer(alert.idMovimiento)?
                    <ModalLayout
                      tamanio={'50%'}
                      title="Nueva Incidencia"
                      name={<Button pill size="sm" outline color="primary" ><Icon name="plus" /></Button>}
                      hiddenModal={this.state.hiddenModals}
                      Fclose={this.hiddenmodal}
                      formulario=
                        {this.getMovimientoTer(alert.idMovimiento).plataforma.nombre.toUpperCase().includes("TRAXION")?
                            <IncidenciasTraxion
                              accion={'nuevo'}
                              idusuario={user.id}
                              idmovimiento={alert.idMovimiento}
                              idplataforma={this.getMovimientoTer(alert.idMovimiento).plataforma.id}
                              movimiento={this.getMovimientoTer(alert.idMovimiento)}
                              unidad= {null}//recargar
                              idEntregaActiva={null}//recargar
                              entregas={null}//recargar
                              getfuntion={this.getfuntion}
                              stor={this.props.stor}
                              plataformaTipo={2}
                              gpsReciente={this.props.gps[alert.idMovimiento]?this.props.gps[alert.idMovimiento]:null }
                              latitud={alert.latitud }
                              longitud={alert.longitud }
                              closeModal={this.hiddenmodal}
                            />
                            :
                          <MovimientoIncidenciasTerrestre
                            accion={'nuevo'}
                            desde={'EspecificoTerrestre'}
                            idusuario={user.id}
                            idmovimiento={alert.idMovimiento}
                            idplataforma={this.getMovimientoTer(alert.idMovimiento).plataforma.id}
                            movimiento={this.getMovimientoTer(alert.idMovimiento)}
                            stor={this.props.stor}
                            getfuntion={this.getfuntion}
                            plataformaTipo={2}
                            gpsReciente={this.props.gps[alert.idMovimiento]?this.props.gps[alert.idMovimiento]:null }
                            latitud={alert.latitud }
                            longitud={alert.longitud }
                            closeModal={this.hiddenmodal}
                          />
                        }
                    />
                  :null}
                </Table.Col>
                <Table.Col>
                {
                  //Detalle de Alerta
                  <ModalLayout
                    tamanio={"60%"}
                    title="Atender Alertas Terrestres"
                    name={
                      <Button pill size="sm" outline color="primary">
                        <div className={'fa fa-bell'} style={{ color:'green'}} ></div>
                      </Button>}
                    hiddenModal={this.state.hiddenModals}
                    Fclose={this.hiddenmodal}
                    formulario={
                      <AlertaTForm
                        accion={'modificar'}
                        usuario={this.props.usuario}
                        alerta={alert}
                        getfuntion={this.getfuntion}
                        idMovimiento={alert.idMovimiento}
                        idEntregaActiva={this.movimientoTerrestreEActiva(alert.idMovimiento)}
                        //entregas={this.movimientoTerrestreEntregas(alert.idMovimiento)}
                        stor={this.props}
                        closeModal={this.hiddenmodal}
                        Fclose={this.hiddenmodal}
                        IncidenciasFrom={
                          <MovimientoIncidenciasTerrestre
                            accion={'nuevo'}
                            desde={'AlertasListar'}
                            idusuario={this.props.usuario.id}
                            idmovimiento={alert.idMovimiento}
                            //idplataforma={this.state.movimiento.plataforma.id}
                            movimiento={this.state.movimiento}
                            incidencias={this.state.incidenciasAll}
                            getfuntion={this.getfuntion}
                            stor={this.props}
                            //idEntrega={this.state.idEntrega}
                            idEntregaActiva={this.state.idEntregaActiva}
                            //entregas={this.movimientoTerrestreEntregas(alert.idMovimiento)}
                            plataformaTipo={this.props.plataformaTipo}
                            //gpsReciente={this.props.gpsReciente?this.props.gpsReciente[this.state.movimiento.id]:null}
                            gpsReciente={this.props.gpsReciente?this.props.gpsReciente[this.props.getMovimiento.id]:null}
                            latitud={alert.latitud }
                            longitud={alert.longitud }
                            closeModal={this.hiddenmodal}
                          />
                        }
                      />
                    }
                  />
                }
                </Table.Col>
                <Table.Col>
                  {
                    <Button pill size="sm" outline color="primary"
                      onClick= { ()=> window.open("https://maps.google.com/?q="+alert.latitud+","+alert.longitud+"&t=m", 'sharer', 'width=900,height=600,scrollbars=NO') }
                    >
                      <div className={'fa fa-map-marker'} style={{ color:'green|'}} ></div>
                    </Button>
                  }
                </Table.Col>
              </Table.Row>
            )
          });
        }else{
          this.props.alerta.map((a, index) => {
            let alert= a.alert
            filasTemp.push(
              <Table.Row>
                <Table.Col>{alert.idMovimiento}</Table.Col>
                {/* <Table.Col>{this.movimientoTerrestreEco(alert.idMovimiento)}</Table.Col> */}
                <Table.Col>{this.movimientoTerrestrePlaca(alert.idMovimiento)}</Table.Col>
                <Table.Col>{alert.id}</Table.Col>
                <Table.Col>{alert.triggerName}</Table.Col>
                <Table.Col>{ alert.fechaRegistro }</Table.Col>
                <Table.Col>
                  <Button pill size="sm" outline color="primary" onClick= { () => this.props.plataformaTipo === "Naviera"? this.alertaNav(alert): this.alertaTer(alert, index)}><div className={'fa fa-bell'} style={{ color:'blue'}} ></div></Button>
                </Table.Col>
                <Table.Col>
                  { this.getMovimientoTer(alert.idMovimiento)?
                    <ModalLayout
                      tamanio={'50%'}
                      title="Nueva Incidencia"
                      name={<Button pill size="sm" outline color="primary" ><Icon name="plus" /></Button>}
                      hiddenModal={this.state.hiddenModals}
                      Fclose={this.hiddenmodal}
                      formulario=
                        {this.getMovimientoTer(alert.idMovimiento).plataforma.nombre.toUpperCase().includes("TRAXION")?
                            <IncidenciasTraxion
                              accion={'nuevo'}
                              idusuario={user.id}
                              idmovimiento={alert.idMovimiento}
                              idplataforma={this.getMovimientoTer(alert.idMovimiento).plataforma.id}
                              movimiento={this.getMovimientoTer(alert.idMovimiento)}
                              unidad= {null}//recargar
                              idEntregaActiva={null}//recargar
                              entregas={null}//recargar
                              getfuntion={this.getfuntion}
                              stor={this.props.stor}
                              plataformaTipo={2}
                              gpsReciente={this.props.gps[alert.idMovimiento]?this.props.gps[alert.idMovimiento]:null }
                              latitud={alert.latitud }
                              longitud={alert.longitud }
                              closeModal={this.hiddenmodal}
                            />
                            :
                          <MovimientoIncidenciasTerrestre
                            accion={'nuevo'}
                            desde={'EspecificoTerrestre'}
                            idusuario={user.id}
                            idmovimiento={alert.idMovimiento}
                            idplataforma={this.getMovimientoTer(alert.idMovimiento).plataforma.id}
                            movimiento={this.getMovimientoTer(alert.idMovimiento)}
                            stor={this.props.stor}
                            getfuntion={this.getfuntion}
                            plataformaTipo={2}
                            gpsReciente={this.props.gps[alert.idMovimiento]?this.props.gps[alert.idMovimiento]:null }
                            latitud={alert.latitud }
                            longitud={alert.longitud }
                            closeModal={this.hiddenmodal}
                          />
                        }
                    />
                  :null}
                </Table.Col>
                <Table.Col>
                {
                  //Detalle de Alerta
                  <ModalLayout
                    tamanio={"60%"}
                    title="Atender Alertas Terrestres"
                    name={
                      <Button pill size="sm" outline color="primary">
                        <div className={'fa fa-bell'} style={{ color:'green'}} ></div>
                      </Button>}
                    hiddenModal={this.state.hiddenModals}
                    Fclose={this.hiddenmodal}
                    formulario={
                      <AlertaTForm
                        accion={'modificar'}
                        usuario={this.props.usuario}
                        alerta={alert}
                        getfuntion={this.getfuntion}
                        idMovimiento={alert.idMovimiento}
                        idEntregaActiva={this.movimientoTerrestreEActiva(alert.idMovimiento)}
                        //entregas={this.movimientoTerrestreEntregas(alert.idMovimiento)}
                        stor={this.props}
                        closeModal={this.hiddenmodal}
                        Fclose={this.hiddenmodal}
                        IncidenciasFrom={
                          <MovimientoIncidenciasTerrestre
                            accion={'nuevo'}
                            desde={'AlertasListar'}
                            idusuario={this.props.usuario.id}
                            idmovimiento={alert.idMovimiento}
                            //idplataforma={this.state.movimiento.plataforma.id}
                            movimiento={this.state.movimiento}
                            incidencias={this.state.incidenciasAll}
                            getfuntion={this.getfuntion}
                            stor={this.props}
                            //idEntrega={this.state.idEntrega}
                            idEntregaActiva={this.state.idEntregaActiva}
                            //entregas={this.movimientoTerrestreEntregas(alert.idMovimiento)}
                            plataformaTipo={this.props.plataformaTipo}
                            //gpsReciente={this.props.gpsReciente?this.props.gpsReciente[this.state.movimiento.id]:null}
                            gpsReciente={this.props.gpsReciente?this.props.gpsReciente[this.props.getMovimiento.id]:null}
                            latitud={alert.latitud }
                            longitud={alert.longitud }
                            closeModal={this.hiddenmodal}
                          />
                        }
                      />
                    }
                  />
                }
                </Table.Col>
                <Table.Col>
                  {
                    <Button pill size="sm" outline color="primary"
                      onClick= { ()=> window.open("https://maps.google.com/?q="+alert.latitud+","+alert.longitud+"&t=m", 'sharer', 'width=900,height=600,scrollbars=NO') }
                    >
                      <div className={'fa fa-map-marker'} style={{ color:'green|'}} ></div>
                    </Button>
                  }
                </Table.Col>
              </Table.Row>
            )
          });
        }
      }else{
        filasTemp.push(
        <Table.Row>
          No Hay Alertas por Atender
        </Table.Row>
        );
      }
    }else{
      //this.props.accion==="Atendidas"
      this.encabezados=([{ content:"MovId" },{ content:"AlertaId" },{ content:"Tiggername" },{ content:"UsuarioId" },{ content:"Atención Fecha" },]);
      if(this.props.alerta.length>0){
        if(this.props.plataformaTipo === "Naviera"){
          this.props.alerta.sort(function(a,b) {
            if (a.alert["fechaRegistro"] > b.alert["fechaRegistro"]) {  return -1; }
            if (a.alert["fechaRegistro"] < b.alert["fechaRegistro"]) {  return 1;}
            return 0;
          }).map((a, index) => { console.log(a.alert.fechaRegistro)
              let alert = a.alert;
              filasTemp.push(
                <Table.Row>
                  <Table.Col>{alert.idMovimiento}</Table.Col>
                  <Table.Col>{alert.id}</Table.Col>
                  <Table.Col>{alert.triggerName}</Table.Col>
                  <Table.Col>{alert.atiendeUsuario}</Table.Col>
                  <Table.Col>{alert.atiendeFecha}</Table.Col>
                </Table.Row>
              )
            });
        }else{
          this.props.alerta.sort(function(a,b) {
            if (a["fechaRegistro"] > b["fechaRegistro"]) {  return -1; }
            if (a["fechaRegistro"] < b["fechaRegistro"]) {  return 1;}
            return 0;
          }).map((a, index) => {
              let alert = a.alert;
              filasTemp.push(
                <Table.Row>
                  <Table.Col>{alert.idMovimiento}</Table.Col>
                  <Table.Col>{alert.id}</Table.Col>
                  <Table.Col>{alert.triggerName}</Table.Col>
                  <Table.Col>{alert.atiendeUsuario}</Table.Col>
                  <Table.Col>{alert.atiendeFecha}</Table.Col>
                </Table.Row>
              )
            });
        }
      }else{
        filasTemp.push(
        <Table.Row>
          No Hay Alertas Atendidas
        </Table.Row>
        )
      }
    }
    this.setState({filas: filasTemp});
  }
  componentDidMount(){
    this.alertas= this.props.alerta;
    this.getAlertas();
  }
  componentDidUpdate = () => {
    if( this.alertas !== this.props.alerta){
      this.alertas= this.props.alerta;
      this.getAlertas();
    }
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "", enviar) => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
    //console.log("251 AlertasListar catalogo: " + catalogo + ", metodo:"+metodo+", stateVar:"+stateVar+ ", obj:"+obj);
      switch (metodo) {
        case "get":
          {
            if (stateVar === "camposLogisticos")  { return returnVal; }
            if (stateVar === "entregasActiva")    { return returnVal; }
            if (stateVar === "incidenciasAll")    { this.setState({ incidenciasAll: returnVal }); }
            if (stateVar === "entregas")          {
              if (returnVal) {
                this.setState({ entregas: returnVal });}
              return returnVal;
            }
            if (stateVar === "incidenciasTerrestre") {
              this.incidenciasTerrestre = returnVal;
              this.setState({ incidenciasTerrestre: returnVal });
            }
          }
          break;
        case "post":
          {
            if (stateVar === "incidencias") {
                let destMonitoreo     ="";
                let destTransportistas="";
                let destClientes      ="";
                obj.destinosMonitoreo.forEach((key, index)=>{
                  destMonitoreo=destMonitoreo + ", " + key.correo;
                });
                obj.destinosTransportistas.forEach((key, index)=>{
                  destTransportistas=destTransportistas + ", " + key.correo;
                });
                obj.destinosClientes.forEach((key, index)=>{
                  destClientes=destClientes + ", " + key.correo;
                });
                this.incidencias.unshift(returnVal);
                this.setState({ incidencias: this.incidencias });
                NotificationManager.info(returnVal.comentario, "Incidencia Nueva Naviera ("+returnVal.idMovimiento+")");
                this.usuariosLogObject.idBitacoraMonitoreo=2;
                this.usuariosLogObject.idUsuario=user.id;
                this.usuariosLogObject.idMovimiento =this.props.movimiento;
                this.usuariosLogObject.idPlataformaTipo=1;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.descripcion = returnVal.incidencia.nombre;
                Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", "", "");
                if(enviar && returnVal.id){
                  ArrobaIncidencia(returnVal).then((arrobaInc) =>{
                    NotificationManager.info( arrobaInc,this.privilegioDescripcion("arroba_incidencia"));
                    this.usuariosLogObject.idBitacoraMonitoreo=6;
                    this.usuariosLogObject.idUsuario=user.id;
                    this.usuariosLogObject.idMovimiento =this.props.movimiento;
                    this.usuariosLogObject.idPlataformaTipo=1;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    this.usuariosLogObject.descripcion = "Notificación de incidencia("+returnVal.incidencia.nombre+"): "+ destMonitoreo + " " + destTransportistas + " " + destClientes+" "+arrobaInc;
                    Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", "", "");
                  });
                }
                if(this.state.movimiento.actualizarFull && this.state.movimientoFull ){
                  let incidenciaFull= { atencionFecha: null,
                                        atencionUsuario: obj.atencionUsuario,
                                        comentario: obj.comentario,
                                        fechaCreado: null,
                                        idMovimiento: this.state.movimientoFull,
                                        incidencia: obj.incidencia,
                                        latitud: obj.latitud,
                                        longitud: obj.longitud
                                      }
                  incidenciaFull=
                  this.getfuntion("post", incidenciaFull, "", "movimientoIncidencias", "newIncidenciaFull", "", enviar);
                }
              }
          }
          break;
        case "put":
          {
            if (stateVar ==="terrestresAlertas"){
              this.props.setAlerta(returnVal, "terrestresAlertas");
            }
            switch (catalogo) {
              case "terrestresAlertas":
                //this.usuariosLogObject.idBitacoraMonitoreo = 4;
                //this.usuariosLogObject.idUsuario = user.id;
                //this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                //this.usuariosLogObject.idPlataformaTipo = 2;
                //this.usuariosLogObject.accionFecha = myFormato(new Date());
                //this.usuariosLogObject.descripcion = "Alerta Atendida id: " + returnVal.id + " de Tipo: " + returnVal.triggerName + " del Movimiento id: " + returnVal.idMovimiento;
                this.props.setAlerta(returnVal, "terrestresAlertas");
                obj.estatus=1;

                //Crud_Catalogos("entregas", "entregas", "put", "", obj, "", "", this.hiddenmodal, "");
                break;
              default:
                break;
            }
            switch (stateVar) {
              case "terrestresAlertas":
                NotificationManager.info(returnVal.comentario, "Terrestre, Alerta Atendida ("+returnVal.id+")");
                this.usuariosLogObject.idBitacoraMonitoreo = 4;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                this.usuariosLogObject.idPlataformaTipo = 2;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.descripcion = "Alerta Atendida id: " + returnVal.id + " de Tipo: " + returnVal.triggerName + " del Movimiento id: " + returnVal.idMovimiento;
                this.props.setAlerta(returnVal, "terrestresAlertas");
                Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
                break;
              case "updateFechas":
                {
                  this.hiddenmodal();
                  NotificationManager.info("Se han registrado horarios en campos logisticos", "Actualizacion, mov ("+returnVal.id+")");
                  let cambios= this.getCambiosNav(id, returnVal);//id= old Movimiento, retunVal= Actualizado // {bitacora: bitacora , camposmodificados: camposmodificados }
                  if(obj.actualizarFull){
                    let mFull=   this.props.MovimientosFull[this.state.movimiento.id]? this.props.getMovimientoNavieroById(this.state.movimientoFull): this.props.MovimientosFull[this.state.movimientoFull].movimiento;
                    let campLogistObj = {
                      "idOperacionEstatus"  : obj.idOperacionEstatus,
                                  "idRuta"  : obj.idRuta,
                          "citaProgramada"  : obj.citaProgramada,
                                  "sello"   : mFull.sello,
                              "contenedor"  : mFull.contenedor,
                                   "idOtd"  : obj.idOtd,
                               "idCliente"  : obj.idCliente,
                          "actualizarFull"  : obj.actualizarFull,
                        "idMovimientoTipo"  : obj.idMovimientoTipo,
                         "idOperacionTipo"  : obj.idOperacionTipo,
			                           "idRuta2"  : obj.idRuta2,
                      /*Exportacion*/
                        "retiroContenedor"  : obj.retiroContenedor,
                      "arriboClienteVacio"  : obj.arriboClienteVacio,
                      "salidaClienteLleno"  : obj.salidaClienteLleno,
                  "arriboTerminalCargado"  : obj.arriboTerminalCargado,
                      "salidaPredioVacio"  : obj.salidaPredioVacio,
                      "citaFechaDespacho"  : obj.citaFechaDespacho,
                  "entradaCiudadPortuaria"  : obj.entradaCiudadPortuaria,
                        "arriboPatioLinea"  : obj.arriboPatioLinea,
                    "salidaTerminalVacio"  : obj.salidaTerminalVacio,
                      /**Importacion */
                    "arriboTerminalVacio"  : obj.arriboTerminalVacio,
                    "salidaTerminalLleno"  : obj.salidaTerminalLleno,
                      "arriboClienteLleno"  : obj.arriboClienteLleno,
                      "salidaClienteVacio"  : obj.salidaClienteVacio,
                    "arriboTerminalVacio2"  : obj.arriboTerminalVacio2,
                        "asignacionPiloto"  : obj.asignacionPiloto,
                              "inicioRuta"  : obj.inicioRuta,
                  "despachoPredioCliente"  : obj.despachoPredioCliente,
                        "llegadaFrontera"  : obj.llegadaFrontera,
                          "salidaFrontera"  : obj.salidaFrontera,
                            "arriboAduana"  : obj.arriboAduana,
                            "salidaAduana"  : obj.salidaAduana
                    };
                    this.getfuntion("put", campLogistObj, mFull, "navieraMovimientos/updateFechas/"+ mFull.id, "updateFechasFull");
                  }
                  this.usuariosLogObject.idBitacoraMonitoreo = cambios.bitacora;
                  this.usuariosLogObject.idUsuario = user.id;
                  this.usuariosLogObject.idMovimiento = returnVal.id;
                  this.usuariosLogObject.idPlataformaTipo = 1;
                  this.usuariosLogObject.accionFecha = myFormato(new Date());
                  //this.usuariosLogObject.descripcion = id;//en id vienen los campos modificados
                  //Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject);
                  this.usuariosLogObject.descripcion = cambios.camposmodificados ;//en id vienen los campos modificados
                  Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject);
                }
                break;
              case "terrestreMovimientos":
                {
                  NotificationManager.info("Se Terminara el movimiento", "Actualizacion, mov ("+returnVal.id+") por Usuario: " + user.id);
                  this.usuariosLogObject.idBitacoraMonitoreo = 5;
                  this.usuariosLogObject.idUsuario = user.id;
                  this.usuariosLogObject.idMovimiento = returnVal.id;
                  this.usuariosLogObject.idPlataformaTipo = 2;
                  this.usuariosLogObject.accionFecha = myFormato(new Date());
                  this.usuariosLogObject.descripcion = "Finalizado Movimiento id:" + returnVal.id + " por Usuario id: " + user.id;
                  Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
                }break;
              case "entregas":
                {
                  let entregasTem= this.state.entregas;
                  entregasTem.map((entre, index) => {
                    if(returnVal.idEntrega == entre.idEntrega){
                      this.usuariosLogObject.idBitacoraMonitoreo = 3;
                      this.usuariosLogObject.idUsuario = user.id;
                      this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;//obj.id;//returnVal.id;
                      this.usuariosLogObject.idPlataformaTipo = 2;
                      this.usuariosLogObject.accionFecha = myFormato(new Date());
                      this.usuariosLogObject.descripcion = "Actualizacion Entrega: " + entre.idEntrega+ " Movimiento id:" + returnVal.idMovimiento + " Campo: " + entre.campo;//returnVal.id;
                      entre=returnVal;
                      if (returnVal.estatus == 1) { this.setIdEntregaActiva(returnVal); }
                      this.setState({ entregas: entregasTem });
                      NotificationManager.info("Se modifica entrega", "Actualizacion, mov ("+returnVal.idMovimiento+")");
                    }
                  });
                  Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
                }break;
              default:
                break;
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response);
      } else {
        /**
         * Error
         */
        console.log(err);
      }
    })
  }
  render() {
    return (
      <div>
        <div style={{"max-width":"35px"}}><Exportar crud={this.getfuntion} catalogo={"Alertas"} data={this.props.alerta} /></div>
        <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={this.encabezados}>
        <Table.Body>
          {this.state.filas}
        </Table.Body>
        </Table>
      </div>
    );
  }
}
export default AlertasListar;
