import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';

class  Corredores extends Component {
  constructor(props) {
    super(props);
                         this.state  = {
                                              id: "",
                                          accion: this.props.accion, //'nuevo'// modificar, nuevo
                                          search:"",
                                      corredores: [],
                                 ListaCorredores:[],
                                       };
                    this.corredores  = [];
               this.ListaCorredores  = [];
             this.plataformasObject  = [];
                  this.RutasEstatus  = [];
             this.plataformasObject  = [];
            this.checarCorredor  = 0;      //temporal render
     this.idplataformaCorredor  = 0; //temporal render
             this.corredoresArray  = [];
  }

  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      let plataformaTipoTemp= this.props.elementos.plataformaTipo.id === 3? 1: this.props.elementos.plataformaTipo.id;
      this.setState({id: this.props.elementos.id});
      this.getfuntion("get", "", "", "plataformasCorredores/"+this.props.elementos.id,"corredores");
      this.getfuntion("get", "", "", "corredores/tipoId/"+plataformaTipoTemp, "ListaCorredores", "ListaCorredores");
      this.plataformasObject= this.props.elementos;
    }else{}
    //console.log("34 this.state.corredores");
    //console.log(this.state.corredores);
    //console.log("34 this.ListaCorredores");
    //console.log(this.state.ListaCorredores);
  }

  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "corredores")      { this.corredores= returnVal;     this.setState({ corredores: returnVal }); }
              if (stateVar === "ListaCorredores") { this.ListaCorredores= returnVal;this.setState({ ListaCorredores: returnVal }); }
            }
            break;
          case "post":
            {
            if (stateVar === "corredores"){
                this.corredores.push(returnVal);
                this.setState({ corredores: this.corredores });
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "corredores"){
                this.corredores.map((elemento, index)=>
                  {if(id === elemento.id){
                    this.corredores.splice(index, 1);
                  }}
                )
                this.setState({ corredores: this.corredores })
              }
            }
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log("Error en Corredores.js linea 67 ");
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }

  changeCorredor =(corredorIndex, corredorId, corredorStatus) => {
    this.plataformaCorredoresObject={
      "idPlataforma": this.plataformasObject,
      //"idCorredor": this.props.stor.corredores_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[corredorIndex],
      "idCorredor": this.ListaCorredores.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[corredorIndex],
      "estatus": 1
    }
      this.corredoresArray[corredorIndex].estatus ?
      this.getfuntion("delete", "", this.corredoresArray[corredorIndex].id, "plataformasCorredores", "corredores")
      :
      this.getfuntion("post",this.plataformaCorredoresObject, "", "plataformasCorredores", "corredores");


  }

  checkedCorredor =(indice) =>{
    return this.corredoresArray[indice].estatus
  }
  checarCorredores =(estatus, id) =>{
    this.corredoresArray.push({ estatus: estatus, id: id })
  }
  checarCorredorTem =(valor) =>{
    this.checarCorredor=valor;
  }
  searching =(event) =>{
    this.setState({ search: event.target.value })
  }
  cleanArrayList =() =>{
    this.corredoresArray= [];
  }

  render(){
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        {this.cleanArrayList() }
        { this.state.accion === "modificar" ?
          <div>

            <Grid.Row>
              <Grid.Col>
                <Card title="Corredores" isCollapsible={ false } isCollapsed={ false} options={
                  <Form.Group>
                      <Form.Input className="mb-3" icon="search" placeholder="Buscar corredor..." position="append" onChange={this.searching} />
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Nombre Corto</Table.ColHeader>
                            <Table.ColHeader>Descripción</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              //this.props.stor.corredores_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                              this.ListaCorredores.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{ elemento["id"] }</Table.Col>
                                  <Table.Col>{ elemento["nombre"] }</Table.Col>
                                  <Table.Col>{ elemento["nombreCorto"] }</Table.Col>
                                  { this.checarCorredorTem(0)}
                                  { this.idplataformaCorredor="" }
                                  {
                                    //console.log( this.state.corredores )
                                  }
                                  {
                                    this.state.corredores.map((corredor, ind) =>
                                    {
                                      //console.log( corredor.idCorredor.id + " === " + elemento.id )
                                      if(corredor.idCorredor.id === elemento.id){
                                        { this.checarCorredorTem(1)}
                                        this.idplataformaCorredor=corredor.id;
                                      }
                                    })
                                  }
                                  { this.checarCorredores(this.checarCorredor, this.idplataformaCorredor)}
                                  <Table.Col>
                                    <Form.Switch type="Checkbox" name="toggle" value="option1" checked={ this.checkedCorredor(index) } className={"disabled"} onChange={ () => this.changeCorredor(index, elemento["id"], this.checarCorredor) } />
                                  </Table.Col>
                                </Table.Row>
                              )
                            }
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
        :null}
      </div>
    )
  }
}

export default Corredores
