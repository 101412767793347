import React from 'react';
import * as ACTIONS from '../store/actions/actions'
import { connect } from 'react-redux';
import ModalLayout from "./ModalLayout.react";
import { Crud_Catalogos, Crud_error } from '../herramientas/Crud_Catalogos';
import { Form, Grid, Card, Table, Alert, Icon, Dimmer, Button } from "tabler-react";
import { ImportarMT, ImportarT, Exportar, Plantilla, Terminados, Activados } from '../herramientas/Excel';
import Select from "react-select";
import EntregaFrom from '../Monitoristas/Monitoreo/panelForms/Entregas/EntregaForm';
import MonitoreoMensajeria from '../socket/MonitoreoMensajeria.react';
import IncompletosTerminados from './IncompletosTerminados';
import { timeToMilisegundos } from "../herramientas/DateFormat";
import MovimientoIncidenciasTerrestre from "../Monitoristas/Monitoreo/MovimientoIncidenciasTerrestre";
import IncidenciasTraxion from "../Monitoristas/Monitoreo/IncidenciasTraxion";
let ruta = [];
var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
function myFormato(date) {
  function pad(number) {
    if (number < 10) { return '0' + number; }
    return number;
  }
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}
const url = new URL(window.location.href);
let fecha1= new Date();
let fecha2= new Date();
class Catalogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementos: this.props.elementos || [],
      tableheaderItems: [],
      test: this.categorias_object,
      hiddenModals: false,//Cierra o No el Modal
      readyData: false,
      message: "",
      ErrorMessage: [],
      showErrormessage: false,
      selectedRow: -1,
      alertasT: {},
      matrizTem :{},
      gpsT: {},
      plataforma: { value: 0, label: "Todas ...", search: "" },
      funcionalidadesCol: [],
      search: "",
      metodo: "get",
      colToFilter : "origen", //columna a filtrar
      AscDesc: true,
      topics: [],
      activo: true
    };
    this.usuariosLogObject = {
      "idBitacoraMonitoreo":0,
      "idUsuario":0,
      "idMovimiento":0,
      "accionFecha":"",
      "descripcion":"",
      "idPlataformaTipo":2
    };
    this.colToFilter= "origen";
    this.AscDesc= true;
    this.ErrorMessage = "";
    this.ErrorMessageArray = [];
    this.catalogo = this.props.Ctlogo;
    this.catalogoTem = "";
    this.Catalogo_form = this.props.FCtlogo;
    this.tamanio = this.props.tamanio;
    this.updateStatus_elementos = this.updateStatus_elementos.bind(this);
    this.getfuntion = this.getfuntion.bind(this);
    this.content = [];
    //this.colum = this.props.colum | [];
    this.columna = [];
    this.fila = [];
    this.get_Store_Elements = null;
    this.update_Store_Elements = null;
    //this.hiddenmodal = this.hiddenmodal.bind(this);
    this.getMensage = false;
    this.movimientoudated = true;
    this.plataformas = [];
    this.mensaje= { "mensaje": "", "plataforma": "", "corredor": "", "vista": "", "data": null, "accion": "get" };
  }
  

  estaPrivilegioActivo = (clave)=> {
    var privilegios= this.props.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(privilegios[i].clave === clave){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}else{ return false }
        break;
      }
    }
  }

  filter(event){
    var text = event.target.value
    const data = this.state.productoBackup
    const newData = data.filter(function(item){
        const itemDataTitle = item.titulo.toUpperCase()
        const itemDataDescp = item.descripcion.toUpperCase()
        const campo = itemDataTitle+" "+itemDataDescp
        const textData = text.toUpperCase()
        return campo.indexOf(textData) > -1
    })
    this.setState({
        producto: newData,
        text: text,
    })
  }

  componentDidUpdate = () => {
    if (this.props.idToFilter) {
      this.sortElements("id");
      this.setState({ search: this.props.idToFilter.toString() });
      this.props.changeColToFilter(0);
    }
    if (this.props.ruta !== ruta){
      ruta = this.props.ruta;
      this.setState( { colToFilter : "ruta" } );
      this.setState( { search : ruta } );
    }    
    if (this.props.alertasT !== this.state.alertasT)             {this.setState({ alertasT: this.props.alertasT }); }
    if (this.props.gpsTerrestreReciente !== this.state.gpsT)     {this.setState({ gpsT: this.props.gpsTerrestreReciente }); }
    if (this.state.funcionalidadesCol !== this.props.columnas)   {this.setState({ funcionalidadesCol: this.props.columnas });this.table_headers(true); }
    if (this.usuariosCorredores !== this.props.usuariosCorredores){
      this.usuariosCorredores = this.props.usuariosCorredores;
      var topics = [];
      for (let item of this.props.usuariosCorredores) {
        topics.push("/topic/" + item.idPlataforma + "-" + item.idOperacionCliente);
      }
      this.setState({ topics: topics });
    }
    if( fecha1 !== this.props.fecha1 || fecha2 !== this.props.fecha2 ){
      fecha1= this.props.fecha1;
      fecha2= this.props.fecha2;
     if( new Date(this.props.fecha1).getTime() < new Date(this.props.fecha2).getTime()  ){
      if(url.searchParams.get("q") === "finalizados"){
        if (currentUser[0].perfil.movimientosBy){
          switch (currentUser[0].perfil.movimientosBy) {
            case 1:
              this.getfuntion("get", [], "", [], this.props.Ctlogo + "/terminadosU/" + user.id+"/"+this.props.fecha1+" 00:00:00/"+this.props.fecha2+" 23:59:59", this.update_Store_Elements);
              break;
            case 3:
              //this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/transportistas/" + user.id, this.update_Store_Elements);
              break;
            default:
              //this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
              break;
          }
        }else{
          this.getfuntion("get", [], "", [], this.props.Ctlogo + "/usuario/" + user.id, this.update_Store_Elements);
        }
      }
     }
    }
  }
  updateMessage = (sms) => {
     this.setState({ message: sms });
  }
  componentDidMount = () => {
    
    this.getfuntion("get", [], "", [], "eta_codigo_colores/usuario/" + user.id, "");
    /*
      var topics = [];
      for (let item of this.props.usuariosCorredores) {
        topics.push("/topic/" + item.idPlataforma + "-" + item.idOperacionCliente);
      }
      this.setState({ topics: topics });
    */

    if (this.catalogo !== "" || this.catalogo !== null) {
      Object.keys(this.props.catalogos).forEach(
        key => {
          if (this.catalogo === this.props.catalogos[key]) {
          }
          switch (this.props.catalogos[key]) {
            case "terrestreMovimientos":

                this.update_Store_Elements = this.props.movimientos_terrestres_update;
                if( url.searchParams.get("q") === "activos" ){
                  if (currentUser[0].perfil.movimientosBy){
                    switch (currentUser[0].perfil.movimientosBy) {
                      case 1:
                        this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
                        break;
                      case 2:
                        this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/transportistas/" + user.id, this.update_Store_Elements);
                        break;
                      default:
                        this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
                        break;
                    }
                  }else{
                    this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
                  }
                }
                if(url.searchParams.get("q") === "finalizados"){
                  if (currentUser[0].perfil.movimientosBy){
                    switch (currentUser[0].perfil.movimientosBy) {
                      case 1:
                        this.getfuntion("get", [], "", [], this.props.Ctlogo + "/terminadosU/" + user.id+"/"+this.props.fecha1+" 00:00:00/"+this.props.fecha2+" 23:59:59", this.update_Store_Elements);
                        break;
                      case 3:
                        //this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/transportistas/" + user.id, this.update_Store_Elements);
                        break;
                      default:
                        //this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
                        break;
                    }
                  }else{
                    this.getfuntion("get", [], "", [], this.props.Ctlogo + "/usuario/" + user.id, this.update_Store_Elements);
                  }
                }
                if(url.searchParams.get("q") === "lenovo"){
                  if (currentUser[0].perfil.movimientosBy){
                    switch (currentUser[0].perfil.movimientosBy) {
                      case 1:
                        this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id + "/dominioCuenta/20", this.update_Store_Elements);
                        break;                      
                      default:
                        //this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
                        break;
                    }
                  }else{
                    this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id + "/dominioCuenta/20", this.update_Store_Elements);
                  }
                }
                if( url.searchParams.get("q") === "delay" ){
                  if (currentUser[0].perfil.movimientosBy){
                    switch (currentUser[0].perfil.movimientosBy) {
                      case 2:
                        this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/delay/usuario/" + user.id, this.update_Store_Elements);
                        break;
                      case 3:
                        this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/delay/transportistas/" + user.id, this.update_Store_Elements);
                        break;
                      default:
                        this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/delay/usuario/" + user.id, this.update_Store_Elements);
                        break;
                    }
                  }else{
                    this.getfuntion("get", [], "", [], this.props.catalogos[key] + "delay/usuario/" + user.id, this.update_Store_Elements);
                  }
                }
                if( url.searchParams.get("q") === "con_unidad_con_gps" ){
                  this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/con_unidad_con_gps", this.update_Store_Elements);
                }
                if( url.searchParams.get("q") === "con_unidad_sin_gps" ){
                  this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/con_unidad_sin_gps", this.update_Store_Elements);
                }
                if( url.searchParams.get("q") === "sin_unidad_sin_gps" ){
                  this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/sin_unidad_sin_gps", this.update_Store_Elements);
                }
                if( url.searchParams.get("q") === "sin_transportista" ){
                  this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/sin_transportista", this.update_Store_Elements);
                }

                break;
            case "terrestreMovimientosTerminados":
              this.update_Store_Elements = this.props.movimientos_terrestres_update;
              if (currentUser[0].perfil.movimientosBy){
                switch (currentUser[0].perfil.movimientosBy) {
                  case 2:
                    this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/terminadosU/usuario/" + user.id+"/2021-06-13 00:00:00/2021-07-13 23:59:59", this.update_Store_Elements);
                    break;
                  case 3:
                    this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/transportistas/" + user.id, this.update_Store_Elements);
                    break;
                  default:
                    this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
                    break;
                }
              }else{
                this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
              }
              break;
            case "clientes":
              {
                this.update_Store_Elements = this.props.clientes_update;
                this.getfuntion("get", [], "", [], "catalogos/" + this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "movimientosTipo":
              {
                this.update_Store_Elements = this.props.movimientosTipo_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "navieras":
              {
                this.update_Store_Elements = this.props.navieras_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "transportistas":
              {
                this.update_Store_Elements = this.props.transportistas_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "otd":
              {
                this.update_Store_Elements = this.props.otd_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "plataformas":
              {
                this.update_Store_Elements = this.props.plataformas_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "rutasEstatus":
              {
                this.update_Store_Elements = this.props.rutasEstatus_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "corredores":
              {
                this.update_Store_Elements = this.props.corredores_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "funcionalidades":
              {
                this.update_Store_Elements = this.props.funcionalidades_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "privilegios":
              {
                this.update_Store_Elements = this.props.privilegios_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }

            case "operacionesClientes":
              {
                this.update_Store_Elements = this.props.operacionesClientes_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "operacionesEstatus":
              {
                this.update_Store_Elements = this.props.operacionesEstatus_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "operacionesTipo":
              {
                this.update_Store_Elements = this.props.operacionesTipo_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "operadores":
              {
                this.update_Store_Elements = this.props.operadores_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "unidades":
              {
                this.update_Store_Elements = this.props.unidades_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }

            default: return;
          }
        }
      )
    }
    this.plataformas.push({ value: 0, label: "Todas ...", search: "" });
    currentUser.map((usr, index) => {
      if(usr.plataforma.plataformaTipo.nombre.toUpperCase() === 'TERRESTRE' ){
        this.plataformas.push({ value: usr.plataforma.id, label:usr.plataforma.nombre, search: usr.plataforma.nombre });
      }
    })
  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  }
  getfuntion = (metodo = "get", params = [], id = "", arraytoUpdate = [], catalogoTem = this.catalogo, storeFunction = this.update_Store_Elements) => {
    this.usuariosLogObject.idPlataformaTipo = 2;
    let objTemp = params;
    if (catalogoTem !== "") {
      Crud_Catalogos(catalogoTem, this.catalogo+"_", metodo, id, params, storeFunction, this.state.elementos, this.hiddenmodal, arraytoUpdate).then((returnVal) => {
        this.updateStatus_elementos();
        this.setState({ metodo: metodo })
        switch (metodo) {
          case "get":
            {
              if (catalogoTem === this.catalogo + "/usuario/" + user.id) {
                //this.table_headers();
                this.setState({ readyData: true });
              }
              if (catalogoTem.includes(this.catalogo + "/")) {
                this.setState({ readyData: true });
              }
              if (catalogoTem.includes(this.catalogo + "/terminadosU/" + user.id) ) {
                this.setState({ readyData: true });
              }
              if (catalogoTem === this.catalogo + "/transportistas/" + user.id) {
                this.setState({ readyData: true });
              }
              if (catalogoTem === "eta_codigo_colores/usuario/" + user.id) {
                let coloresTem= {};
                returnVal.map((t, index)=>{
                  if( !coloresTem[t.idMovimiento]){  coloresTem[t.idMovimiento]= []; }
                  coloresTem[t.idMovimiento].push(t);
                });
                this.setState({ matrizTem: coloresTem });
              }
              if( catalogoTem.includes("terrestreMovimientos/cv/") ){
                let mTemp= this.state.elementos;
                if( returnVal.id ){
                  mTemp.push(returnVal);
                }            
                if( returnVal.length && returnVal[0] ){
                  for(let m of returnVal){
                    mTemp.push(m);
                  }
                }                    
                this.setState({ elementos: mTemp});
              }
            } break;
          case "post":
            {
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 2;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion = "Nuevo Movimiento Terrestre Post id=" + returnVal.id;
              Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
              break;
            }
          case "put":
            {/*
              this.mensaje.data= returnVal.data;
              this.mensaje.corredor= returnVal.data.corredor.id;
              this.mensaje.plataforma= returnVal.data.plataforma.id;
              this.mensaje.mensaje = "Actualización ("+returnVal.data.id+", plataforma "+returnVal.data.plataforma.nombre+")";
              */
              //this.props.updateStatusMensaje(true);
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = objTemp.id;//returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 2;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion = "Actualizacion de Movimiento id:" + objTemp.id;//returnVal.id;              
              if (objTemp.operacionEstatus.id === 3) {                
                this.usuariosLogObject.descripcion = "Se finaliza Movimiento id:" + objTemp.id;
              }
              if (objTemp.operacionEstatus.id === 0) {                
                this.usuariosLogObject.descripcion = "Se elimino Movimiento id:" + objTemp.id;
              }              
              Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
              break;
            }
          case "delete":
            {
              this.usuariosLogObject.idBitacoraMonitoreo = 10;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion = "Delete Movimiento Terrestre: id:" + objTemp.id;
              Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
              break;
            }
          default: return;
        }
      })
        .catch(err => {
          console.log("Error: =======>" + err);
          this.setState({ readyData: true });
          if (err.response) {
            this.setState({ readyData: false });
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(err.response.status, catalogoTem);
            this.ErrorMessageArray.push(<Alert type="danger" icon="alert-octagon" > {this.ErrorMessage} </Alert>);
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          }
          else {
            this.setState({ readyData: false });
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(404, catalogoTem);
            this.ErrorMessageArray.push(<Alert type="danger" icon="alert-octagon" > {this.ErrorMessage} </Alert>);
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          }
        })
    }
  }
  updateStatus_elementos = (vista) => {
    this.setState({ elementos: this.props.movimientos_terrestres_object });
    this.props.setMovimientosTerrestres(this.props.movimientos_terrestres_object);
  }
  table_headers = (asc) => {
    this.content = [];
    this.props.columnas.map((columna, index) => {
      this.content.push({ content: <div onClick={() => this.sortElements(columna.nombre) }>{columna.nombre}{this.colToFilter === this.props.columnas[index].nombre? <Icon name={asc?"chevron-up":"chevron-down"}/> : null}</div> });
    })
    this.setState({ tableheaderItems: this.content });
  }
  isobject = () => {
    if (typeof this.columna === "object") {
      if (this.columna.length > 1) {
        for (let item of this.columna) {
          this.colum.push(
            <Table.Col>
              <div className="row" style={{"font-size":"80%"}}>
                {<ModalLayout tamanio={this.tamanio} title="Modificar" accion="modificar" 
                  name={item["nombre"] + " ..."} 
                  hiddenModal={this.state.hiddenModals} 
                  Fclose={this.hiddenmodal} 
                  formulario={<this.Catalogo_form 
                  accion="modificar" 
                  elementos={this.fila} 
                  getfuntion={this.getfuntion} 
                  stor={this.props} />} 
                  />}
              </div>
            </Table.Col>);
          break;
        }
      }
      else {
        if (this.columna.length === 1) {
          for (let item of this.columna) {
            this.colum.push(
              <Table.Col><div className="row" style={{"font-size":"20%"}}>{item["nombre"]}</div></Table.Col>);
            break;
          }
        }
        else {
          switch (this.campo)
          {
            case "operador":
              {
                this.colum.push(
                  <Table.Col>
                    <div className="row" style={{"font-size":"80%"}}>
                      { this.columna["nombre"]+" "+this.columna["paterno"]+" "+this.columna["materno"] }<br></br>{this.columna["medioComunicacion"] }
                    </div>
                  </Table.Col>);
                break;
              }
            case "nombreIncidencia":
              {
                this.colum.push(
                  <Table.Col><div className="row" style={{"font-size":"60%"}}>{this.columna["nombreIncidencia"]}</div></Table.Col>);
                break;
              }
            case "ultimaIncidencia":
              {
                this.colum.push(
                  <Table.Col><div className="row" style={{"font-size":"80%"}}>{this.columna["ultimaIncidencia"]}</div></Table.Col>);
                break;
              }
            case "nombreIncidencia":
              {
                this.colum.push(
                  <Table.Col><div className="row" style={{"font-size":"60%"}}>{this.columna["nombreIncidencia"]}</div></Table.Col>);
                break;
              }
            case "idUltimaEntrega":
              {
                this.colum.push(
                  <Table.Col><div className="row" style={{"font-size":"80%"}}>{this.columna["idUltimaEntrega"]}</div></Table.Col>);
                break;
              }
            case "fechaEntrega":
              {
                this.colum.push(
                  <Table.Col><div className="row" style={{"font-size":"80%"}}>{this.columna["fechaEntrega"]}</div></Table.Col>);
                break;
              }
            case "ultimaEntrega":
              {
                this.colum.push(
                  <Table.Col><div className="row" style={{"font-size":"80%"}}>{this.columna["ultimaEntrega"]}</div></Table.Col>);
                break;
              }
            default:
              //this.colum.push(<Table.Col>{elemento[columna.columna]}</Table.Col>)
              this.colum.push(
                <Table.Col>
                  <div className="row" style={{"font-size":"80%"}}>
                    {this.columna["nombre"] ? this.columna["nombre"] : this.columna["username"]}
                  </div>
                </Table.Col>);
              break;
          }

        }
      }

    }
  }

  searching = (event) => {
    this.setState({ search: event.target.value });
    if(
        this.colToFilter === "cartaPorte"
        && this.state.elementos.filter(m=> m.cartaPorte? m.cartaPorte.includes(event.target.value): false).length===0){
      this.getfuntion("get", [], "", [], "terrestreMovimientos/cv/" + event.target.value, "" );
    }
  }
  changePlataformas = (event) => {
    this.setState({ plataforma: event });
  }
  sortElements=(columna) =>{
    let oldCol= this.colToFilter;
    if( this.colToFilter == columna){ this.AscDesc= !this.AscDesc; }else{ this.AscDesc= true; }
    this.colToFilter= columna;
    this.setState({ colToFilter: columna});
    if(oldCol == columna){ this.table_headers(this.AscDesc); }else{ this.table_headers(true); }
    var originalArray= this.state.elementos;
    if(!originalArray[0]){ return; }
    if( this.AscDesc){
      originalArray= originalArray.sort(function(a,b) {
        if(typeof a[columna] === "object" && typeof b[columna] === "object" && a[columna] !== null && b[columna] !== null ){
          if(a[columna] === null ||  b[columna] === null){ return 1; }// si es nulo lo ordena al final
          if (a[columna].nombre > b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre < b[columna].nombre) {
            return -1;
          }
          return 0;
        }else{
          if(a[columna] === null){ return -1; }
          if (a[columna] > b[columna]) {
            return 1;
          }
          if (a[columna] < b[columna]) {
            return -1;
          }
          return 0;
        }
      });
        //return a[columna]-b[columna] }); //Asecendente
    }else{
      if(typeof originalArray[0][columna] == "object"){
        originalArray= originalArray.sort(function(a,b) {
          if(a[columna] === null ||  b[columna] === null){ return -1; }// si es nulo lo ordena al final
          if (a[columna].nombre < b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre > b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      }else{
        originalArray= originalArray.sort(function(a,b) {
          if(a[columna] === null ){ return -1; }
          if (a[columna] < b[columna]) {
            return 1;
          }
          if (a[columna] > b[columna]) {
            return -1;
          }
          return 0;
        });
      }
        //return b[columna]-a[columna] }); //Descendente
    }
    this.setState({ elementos: originalArray });
  }
  objectFilter =(columna) =>{
    if(columna){
      if( columna.nombre ){ return columna.nombre;  }else{ return columna; }
    }else{return "" }
  }
  getFuncionalidad=(elemento)=>{
    //if (elemento != "Importar"){
    let movActivo = this.state.elementos.filter( word =>
                        word.plataforma.nombre.includes(this.state.plataforma.search) &&
                        ( !Number.isInteger(word[this.state.colToFilter])? //Si se filtra texto
                            this.objectFilter(word[this.state.colToFilter]).toUpperCase().includes(this.state.search.toUpperCase())
                            : this.state.search !=""?
                              parseInt(word[this.state.colToFilter], 10) === parseInt(this.state.search, 10)? true : false //Si se filtra numero o boolean
                          : true
                        )
      ).sort()[this.state.selectedRow];
    if(movActivo){
        if(movActivo.plataforma){
            if(this.props.PlataformasPerfiles[movActivo.plataforma.id]) {
              let funcionalidadesTemp= this.props.PlataformasPerfiles[movActivo.plataforma.id].funcionalidades;
              if(!funcionalidadesTemp){
                return false;
              }
              for(let item of funcionalidadesTemp){
                if(item.nombre === elemento ){
                  return true;
                }
              }
            }
        }
    }

    //}


    return false;
  }

  getFuncionalidadGeneral=(elemento)=>{
    var BreakException = {};
    var resultado= false;

    try {
      Object.keys( this.props.PlataformasPerfiles ).forEach((key, indice) => {
        if(this.props.PlataformasPerfiles[key].funcionalidades){
          for(let item of this.props.PlataformasPerfiles[key].funcionalidades){
            if(item.nombre == elemento ){ resultado= true; throw BreakException; }
          }
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
      return resultado;
    }
    return false;
  }

  render() {
    return (
      <Grid.Col lg={12}>
        <div className="m-3">
          <MonitoreoMensajeria topic={this.state.topics} mensaje={this.mensaje} elementosT={this.state.elementos} storeFunction={this.update_Store_Elements}
            updateT={this.updateStatus_elementos} openmodal={this.state.hiddenModals} metodo={this.state.metodo} stor={this.props}
          />
        </div>
        <Card>
          <Card.Header>
            <div className="container-fluid">
              <nav className="navbar navbar-expand-lg navbar-light">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                    <a>
                      <div className="float-right" style={{ "width": "160px" }}>
                        <Select value={this.state.plataforma} onChange={this.changePlataformas} options={this.plataformas} />
                      </div>
                    </a>
                  </li>
                  <li className="nav-item active">
                    <a>
                      <Form.Input className="lg-12" icon="search" placeholder={this.state.colToFilter} position="append" onChange={this.searching} value={this.state.search} />
                    </a>
                  </li>
                  { url.searchParams.get("q") === "activos" && this.estaPrivilegioActivo("CargaMasiva") && this.getFuncionalidad("Importar") ?
                      <li className="nav-item active">
                        <a> <div className="float-right" style={{ "width": "45px" }}><ImportarMT crud={this.getfuntion} catalogo={this.catalogo} /></div> </a>
                      </li>
                    : <li></li>}
                  { url.searchParams.get("q") === "activos" && this.estaPrivilegioActivo("CargaMasiva") && this.getFuncionalidad("Importar") ?
                      <li className="nav-item active">
                        <a> <div className="float-right" style={{ "width": "45px" }}><ImportarT crud={this.getfuntion} catalogo={this.catalogo} /></div> </a>
                      </li>
                    : <li></li>}
                  { url.searchParams.get("q") === "activos" && this.getFuncionalidad("Importar")?
                      <li className="nav-item active">
                        <a> <div className="float-right" style={{ "width": "45px" }}><Plantilla crud={this.getfuntion} catalogo={this.catalogo} /></div> </a>
                      </li>
                    : <li></li>}
                  { this.getFuncionalidad("Exportar")?
                      <li className="nav-item active">
                        <a>
                          <div className="float-right" style={{ "width": "45px" }}>
                            <Exportar crud={this.getfuntion} catalogo={this.catalogo} data={this.state.elementos}
                            //filtro={"/usuario/"+user.id }
                            />
                          </div>
                        </a>
                      </li>
                    : <li></li>}
                    { url.searchParams.get("q") === "activos" && this.getFuncionalidad("Movimientos Incompletos")?
                        <li>
                          <a>
                            <div className="float-right" style={{ "width": "45px" }}>
                              <Terminados
                                    crud={this.getfuntion}
                                    catalogo={this.catalogo}
                                    data={this.state.elementos}
                                    filtro={user}
                              />
                            </div>
                          </a>
                        </li>
                        :
                        <li>
                        </li>
                    }
                    { url.searchParams.get("q") === "activos" && this.getFuncionalidad("Movimientos Incompletos")?
                        <li>
                          <a>
                            <div className="float-right" style={{ "width": "45px" }}>
                              <Activados
                                    crud={this.getfuntion}
                                    catalogo={this.catalogo}
                                    data={this.state.elementos}
                                    filtro={user}
                              />
                            </div>
                          </a>
                        </li>
                        :
                        <li>
                        </li>
                    }
                    { url.searchParams.get("q") === "activos" && this.getFuncionalidad("Movimientos Completos")?
                        <li className="nav-item active">
                          <a>
                              <ModalLayout tamanio={"85%"}
                                           title="Terminados"
                                           name={<Button pill size="sm" outline color="primary"><Icon name="maximize-2" /></Button>}
                                           hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                           formulario={<IncompletosTerminados columnas={this.state.funcionalidadesCol} elementos= { this.state.elementos } closeModal={this.hiddenmodal} plataformaTipo={ "Terrestre" } /> }
                            />
                          </a>
                        </li>
                    : <li></li>}
                    { url.searchParams.get("q") === "activos" && this.getFuncionalidadGeneral("Agregar Movimiento") ?
                        <li className="nav-item active">
                          <a>
                            <ModalLayout hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                         title="Nuevo"
                                         name={<Button pill size="sm" outline color="primary"><Icon name="plus" /></Button>}
                                         tamanio={this.tamanio}
                                         formulario={<this.Catalogo_form 
                                          accion="nuevo" 
                                          elementos={this.state.elementos[0]} 
                                          getfuntion={this.getfuntion} 
                                          closeModal={this.hiddenmodal} 
                                          stor={{...this.props,hideModal:this.hiddenmodal}}
                                          />}
                            />
                          </a>
                        </li>
                    : <li></li>}



                </ul>
              </nav>
            </div>
            <div className="w-5"></div>


          </Card.Header>
          <Card.Body>
            <Dimmer active={!this.state.readyData} loader>
              <Table cards={true} striped={false} responsive={true} className="table-vcenter" headerItems={this.state.tableheaderItems} >
                <Table.Header></Table.Header>
                <Table.Body>
                  {this.state.elementos ? //.filter(word => word.po.toUpperCase().includes(this.state.search.toUpperCase()) || word.booking.toUpperCase().includes(this.state.search.toUpperCase()) || word.contenedor.toUpperCase().includes(this.state.search.toUpperCase())).sort()
                      this.state.elementos.filter(word =>
                                            word.plataforma.nombre.includes(this.state.plataforma.search) &&
                                            ( !Number.isInteger(word[this.state.colToFilter])? //Si se filtra texto
                                                this.objectFilter(word[this.state.colToFilter]).toUpperCase().includes(this.state.search.toUpperCase())
                                                : this.state.search !=""?
                                                  parseInt(word[this.state.colToFilter], 10) === parseInt(this.state.search, 10)? true : false //Si se filtra numero o boolean
                                              : true
                                            )
                                            ).sort().map((elemento, index) =>
                      <Table.Row style={this.state.selectedRow === index ? { "background-color": "rgba(95, 231, 50, 0.30)" } : null} onClick={() => { this.props.setMovimiento(elemento); this.setState({ selectedRow: index }); }}>
                        {this.colum = []}
                        {
                          this.state.funcionalidadesCol.map(
                          (columna, index) =>
                          {
                            if (typeof elemento[columna.columna] === "object" && elemento[columna.columna] !== null)
                            {
                                  this.columna = elemento[columna.columna];
                                  this.fila = elemento;
                                  this.campo = columna.columna;
                                  this.isobject();
                            }
                            else //Inicia No es Objeto
                            {
                              //Inicia Switch
                              
                              switch (columna.columna)
                             
                              {
                                
                                case "DestinoColor": 
                                  if(this.state.matrizTem[elemento.id]){                              
                                    this.state.matrizTem[elemento.id].map((data,index)=>{
                                      if(data.ontimeDelay==="ontime" ){
                                        this.colum.push(<Table.Col className="ontimeCol" style={{backgroundColor:"#53c229 ",color:"#fff"}}>
                                          {
                                            <div className="ontimeCol" style={{backgroundColor:"#53c229 ",color:"#fff",width:"100px",height:"120px"}}>
                                            <Table.Row>{<small>Destino:{data.destino}</small>}</Table.Row>
                                            <Table.Row>{<small>Cita:{data.citaCarga}</small>}</Table.Row>
                                            <Table.Row>{
                                              <ModalLayout
                                              tamanio={"99%"}
                                              title={"Informacion de ETA"}
                                              name={<Button pill size="sm" fill color="info">ETA</Button>}
                                              hiddenModal={this.state.hiddenModals}
                                              Fclose={this.hiddenmodal}
                                              formulario=
                                              {
                                                <div>
                                                  <Table
                                                  cards={true}
                                                  striped={true}
                                                  responsive={true}
                                                  className="table-vcenter">
                                                    <Table.Header>
                                                    <Table.ColHeader>ID</Table.ColHeader>
                                                    <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                                    <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                                    <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                                    <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                                    <Table.ColHeader>Fecha</Table.ColHeader>
                                                    <Table.ColHeader>Distancia</Table.ColHeader>
                                                    <Table.ColHeader>Tiempo</Table.ColHeader>
                                                    <Table.ColHeader>ETA</Table.ColHeader>
                                                    <Table.ColHeader>Status</Table.ColHeader>
                                                    <Table.ColHeader>Tipo</Table.ColHeader>
                                                    <Table.ColHeader></Table.ColHeader>
                                                    </Table.Header>
                                                    <Table.Body>
                                                    <Table.Col>ID campo</Table.Col>
                                                    <Table.Col>Latitud Origen campo</Table.Col>
                                                    <Table.Col>Longitud Origen campo</Table.Col>
                                                    <Table.Col>Latitud Destino campo</Table.Col>
                                                    <Table.Col>Longitud Destino campo</Table.Col>
                                                    <Table.Col>Fecha campo</Table.Col>
                                                    <Table.Col>Distancia campo</Table.Col>
                                                    <Table.Col>Tiempo campo</Table.Col>
                                                    <Table.Col>ETA campo</Table.Col>
                                                    <Table.Col>Status campo</Table.Col>
                                                    <Table.Col>Tipo campo</Table.Col>
                                                    <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                      </Table.Body>
                                                  </Table>
                                                </div>
                                              }
                                            />
                                              }</Table.Row>
                                            </div>
                                            }</Table.Col>)
                                      }
                                      else if(data.ontimeDelay==="delay" ){
                                        this.colum.push(<Table.Col >
                                          {
                                            <div className="delayCol" style={{backgroundColor:"#e34152",color:"#fff",width:"100px",height:"auto"}}>
                                            <Table.Row>{<small>Destino:{data.destino}</small>}</Table.Row>
                                            <Table.Row>{<small>Fecha:{data.fechaAlta}</small>}</Table.Row>
                                            <Table.Row>{
                                              <ModalLayout
                                              tamanio={"99%"}
                                              title={"Informacion de ETA"}
                                              name={<Button pill size="sm" fill color="info">ETA</Button>}
                                              hiddenModal={this.state.hiddenModals}
                                              Fclose={this.hiddenmodal}
                                              formulario=
                                              {
                                                <div>
                                                  <Table
                                                  cards={true}
                                                  striped={true}
                                                  responsive={true}
                                                  className="table-vcenter">
                                                    <Table.Header>
                                                    <Table.ColHeader>ID</Table.ColHeader>
                                                    <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                                    <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                                    <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                                    <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                                    <Table.ColHeader>Fecha</Table.ColHeader>
                                                    <Table.ColHeader>Distancia</Table.ColHeader>
                                                    <Table.ColHeader>Tiempo</Table.ColHeader>
                                                    <Table.ColHeader>ETA</Table.ColHeader>
                                                    <Table.ColHeader>Status</Table.ColHeader>
                                                    <Table.ColHeader>Tipo</Table.ColHeader>
                                                    <Table.ColHeader></Table.ColHeader>
                                                    </Table.Header>
                                                    <Table.Body>
                                                    <Table.Col>ID campo</Table.Col>
                                                    <Table.Col>Latitud Origen campo</Table.Col>
                                                    <Table.Col>Longitud Origen campo</Table.Col>
                                                    <Table.Col>Latitud Destino campo</Table.Col>
                                                    <Table.Col>Longitud Destino campo</Table.Col>
                                                    <Table.Col>Fecha campo</Table.Col>
                                                    <Table.Col>Distancia campo</Table.Col>
                                                    <Table.Col>Tiempo campo</Table.Col>
                                                    <Table.Col>ETA campo</Table.Col>
                                                    <Table.Col>Status campo</Table.Col>
                                                    <Table.Col>Tipo campo</Table.Col>
                                                    <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                      </Table.Body>
                                                  </Table>
                                                </div>
                                              }
                                            />
                                              
                                              }</Table.Row>
                                            </div>
                                            }</Table.Col>)
                                      }
                                      else{
                                      if(data.citaCarga==="0000-00-00 00:00:00" || data.citaCarga==="1900-01-01 00:00:00" || data.citaCarga===null){
                                        this.colum.push(<Table.Col >
                                          {
                                            <div className="delayCol" style={{backgroundColor:"#ffbf00",color:"#fff",width:"100px",height:"auto"}}>
                                            <Table.Row>{<small>Destino:{data.destino}</small>}</Table.Row>
                                            <Table.Row>{<small>Fecha:{data.fechaAlta}</small>}</Table.Row>
                                            <Table.Row>{
                                              <ModalLayout
                                              tamanio={"99%"}
                                              title={"Informacion de ETA"}
                                              name={<Button pill size="sm" fill color="info">ETA</Button>}
                                              hiddenModal={this.state.hiddenModals}
                                              Fclose={this.hiddenmodal}
                                              formulario=
                                              {
                                                <div>
                                                  <Table
                                                  cards={true}
                                                  striped={true}
                                                  responsive={true}
                                                  className="table-vcenter">
                                                      <Table.Header>
                                                      <Table.ColHeader>ID</Table.ColHeader>
                                                      <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                                      <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                                      <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                                      <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                                      <Table.ColHeader>Fecha</Table.ColHeader>
                                                      <Table.ColHeader>Distancia</Table.ColHeader>
                                                      <Table.ColHeader>Tiempo</Table.ColHeader>
                                                      <Table.ColHeader>ETA</Table.ColHeader>
                                                      <Table.ColHeader>Status</Table.ColHeader>
                                                      <Table.ColHeader>Tipo</Table.ColHeader>
                                                      <Table.ColHeader></Table.ColHeader>
                                                      </Table.Header>
                                                      <Table.Body>
                                                      <Table.Col>ID campo</Table.Col>
                                                      <Table.Col>Latitud Origen campo</Table.Col>
                                                      <Table.Col>Longitud Origen campo</Table.Col>
                                                      <Table.Col>Latitud Destino campo</Table.Col>
                                                      <Table.Col>Longitud Destino campo</Table.Col>
                                                      <Table.Col>Fecha campo</Table.Col>
                                                      <Table.Col>Distancia campo</Table.Col>
                                                      <Table.Col>Tiempo campo</Table.Col>
                                                      <Table.Col>ETA campo</Table.Col>
                                                      <Table.Col>Status campo</Table.Col>
                                                      <Table.Col>Tipo campo</Table.Col>
                                                      <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                        </Table.Body>
                                                  </Table>
                                                </div>
                                              }
                                            />
                                              
                                              }</Table.Row>
                                            </div>
                                            
                                          }</Table.Col>)
                                      }
                                      else if(data.fechaAlta > data.citaCarga + "0000-00-00 00:30:00"){
                                        this.colum.push(<Table.Col >
                                          {
                                            <div className="delayCol" style={{backgroundColor:"#4f5b3a",color:"#fff",width:"100px",height:"auto"}}>
                                            <Table.Row>{<small>Destino:{data.destino}</small>}</Table.Row>
                                            <Table.Row>{<small>Fecha:{data.fechaAlta}</small>}</Table.Row>
                                            <Table.Row>{
                                              <ModalLayout
                                              tamanio={"99%"}
                                              title={"Informacion de ETA"}
                                              name={<Button pill size="sm" fill color="info">ETA</Button>}
                                              hiddenModal={this.state.hiddenModals}
                                              Fclose={this.hiddenmodal}
                                              formulario=
                                              {
                                                <div>
                                                  <Table
                                                  cards={true}
                                                  striped={true}
                                                  responsive={true}
                                                  className="table-vcenter">
                                                      <Table.Header>
                                                      <Table.ColHeader>ID</Table.ColHeader>
                                                      <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                                      <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                                      <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                                      <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                                      <Table.ColHeader>Fecha</Table.ColHeader>
                                                      <Table.ColHeader>Distancia</Table.ColHeader>
                                                      <Table.ColHeader>Tiempo</Table.ColHeader>
                                                      <Table.ColHeader>ETA</Table.ColHeader>
                                                      <Table.ColHeader>Status</Table.ColHeader>
                                                      <Table.ColHeader>Tipo</Table.ColHeader>
                                                      <Table.ColHeader></Table.ColHeader>
                                                      </Table.Header>
                                                      <Table.Body>
                                                      <Table.Col>ID campo</Table.Col>
                                                      <Table.Col>Latitud Origen campo</Table.Col>
                                                      <Table.Col>Longitud Origen campo</Table.Col>
                                                      <Table.Col>Latitud Destino campo</Table.Col>
                                                      <Table.Col>Longitud Destino campo</Table.Col>
                                                      <Table.Col>Fecha campo</Table.Col>
                                                      <Table.Col>Distancia campo</Table.Col>
                                                      <Table.Col>Tiempo campo</Table.Col>
                                                      <Table.Col>ETA campo</Table.Col>
                                                      <Table.Col>Status campo</Table.Col>
                                                      <Table.Col>Tipo campo</Table.Col>
                                                      <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                        </Table.Body>
                                                  </Table>
                                                </div>
                                              }
                                            />
                                              
                                              }</Table.Row>
                                            </div>
                                            
                                          }</Table.Col>)
                                      }
                                      else if(data.idDestino === '0' || data.idDestino === '-3' || data.idDestino === '-2' || data.idDestino === null){
                                        this.colum.push(<Table.Col >
                                          {
                                            <div className="delayCol" style={{backgroundColor:"#04a3ca",color:"#fff",width:"100px",height:"auto"}}>
                                            <Table.Row>{<small>Destino:{data.destino}</small>}</Table.Row>
                                            <Table.Row>{<small>Fecha:{data.fechaAlta}</small>}</Table.Row>
                                            <Table.Row>{
                                              <ModalLayout
                                              tamanio={"99%"}
                                              title={"Informacion de ETA"}
                                              name={<Button pill size="sm" fill color="info">ETA</Button>}
                                              hiddenModal={this.state.hiddenModals}
                                              Fclose={this.hiddenmodal}
                                              formulario=
                                              {
                                                <div>
                                                  <Table
                                                  cards={true}
                                                  striped={true}
                                                  responsive={true}
                                                  className="table-vcenter">
                                                      <Table.Header>
                                                      <Table.ColHeader>ID</Table.ColHeader>
                                                      <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                                      <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                                      <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                                      <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                                      <Table.ColHeader>Fecha</Table.ColHeader>
                                                      <Table.ColHeader>Distancia</Table.ColHeader>
                                                      <Table.ColHeader>Tiempo</Table.ColHeader>
                                                      <Table.ColHeader>ETA</Table.ColHeader>
                                                      <Table.ColHeader>Status</Table.ColHeader>
                                                      <Table.ColHeader>Tipo</Table.ColHeader>
                                                      <Table.ColHeader></Table.ColHeader>
                                                      </Table.Header>
                                                      <Table.Body>
                                                      <Table.Col>ID campo</Table.Col>
                                                      <Table.Col>Latitud Origen campo</Table.Col>
                                                      <Table.Col>Longitud Origen campo</Table.Col>
                                                      <Table.Col>Latitud Destino campo</Table.Col>
                                                      <Table.Col>Longitud Destino campo</Table.Col>
                                                      <Table.Col>Fecha campo</Table.Col>
                                                      <Table.Col>Distancia campo</Table.Col>
                                                      <Table.Col>Tiempo campo</Table.Col>
                                                      <Table.Col>ETA campo</Table.Col>
                                                      <Table.Col>Status campo</Table.Col>
                                                      <Table.Col>Tipo campo</Table.Col>
                                                      <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                        </Table.Body>
                                                  </Table>
                                                </div>
                                              }
                                            />
                                              
                                              
                                              }</Table.Row>
                                            </div>
                                            
                                          }</Table.Col>)
                                      }
                                  
                                      else if((data.latitud === '0.00000000000' || data.latitud===null) && (data.longitud ==='0.00000000000' || data.longitud===null)){
                                        this.colum.push(<Table.Col >
                                          {
                                            <div className="delayCol" style={{backgroundColor:"#9a5804",color:"#fff",width:"100px",height:"auto"}}>
                                            <Table.Row>{<small>Destino:{data.destino}</small>}</Table.Row>
                                            <Table.Row>{<small>Fecha:{data.fechaAlta}</small>}</Table.Row>
                                            <Table.Row>{
                                              <ModalLayout
                                              tamanio={"99%"}
                                              title={"Informacion de ETA"}
                                              name={<Button pill size="sm" fill color="info">ETA</Button>}
                                              hiddenModal={this.state.hiddenModals}
                                              Fclose={this.hiddenmodal}
                                              formulario=
                                              {
                                                <div>
                                                  <Table
                                                  cards={true}
                                                  striped={true}
                                                  responsive={true}
                                                  className="table-vcenter">
                                                      <Table.Header>
                                                      <Table.ColHeader>ID</Table.ColHeader>
                                                      <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                                      <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                                      <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                                      <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                                      <Table.ColHeader>Fecha</Table.ColHeader>
                                                      <Table.ColHeader>Distancia</Table.ColHeader>
                                                      <Table.ColHeader>Tiempo</Table.ColHeader>
                                                      <Table.ColHeader>ETA</Table.ColHeader>
                                                      <Table.ColHeader>Status</Table.ColHeader>
                                                      <Table.ColHeader>Tipo</Table.ColHeader>
                                                      <Table.ColHeader></Table.ColHeader>
                                                      </Table.Header>
                                                      <Table.Body>
                                                      <Table.Col>ID campo</Table.Col>
                                                      <Table.Col>Latitud Origen campo</Table.Col>
                                                      <Table.Col>Longitud Origen campo</Table.Col>
                                                      <Table.Col>Latitud Destino campo</Table.Col>
                                                      <Table.Col>Longitud Destino campo</Table.Col>
                                                      <Table.Col>Fecha campo</Table.Col>
                                                      <Table.Col>Distancia campo</Table.Col>
                                                      <Table.Col>Tiempo campo</Table.Col>
                                                      <Table.Col>ETA campo</Table.Col>
                                                      <Table.Col>Status campo</Table.Col>
                                                      <Table.Col>Tipo campo</Table.Col>
                                                      <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                        </Table.Body>
                                                  </Table>
                                                </div>
                                              }
                                            />
                                              }</Table.Row>
                                            </div>
                                            
                                          }</Table.Col>)
                                      }
                                      }
                                    });
                                  }  
                                  break;
                                case "estatus":
                                  this.colum.push(<Table.Col>{<Form.Switch type="Checkbox" checked={elemento[columna.columna]} />}</Table.Col>)
                                  break;
                                case "password":
                                  this.colum.push(<Table.Col> {<ModalLayout title="Modificar" accion="modificar" name="*******" hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal} formulario={<this.Catalogo_form accion="modificar" elementos={elemento} getfuntion={this.getfuntion} stor={this.props} />} />} </Table.Col>)
                                  break;
                                case "deRegreso":
                                  this.colum.push(<Table.Col><Form.Switch type="Checkbox" name="toggle" value={elemento[columna.columna]} checked={elemento[columna.columna]? true: false} className={"disabled"} /></Table.Col>)
                                  break;
                                case "revision":
                                    this.colum.push(<Table.Col><Form.Switch type="Checkbox" name="toggle" value={elemento[columna.columna]} checked={elemento[columna.columna]? true: false} onChange={(e) => {elemento[columna.columna] = e.target.checked ? 1 : 0; this.getfuntion("put", elemento )} } /></Table.Col>)
                                  break;
                                case "checklist":
                                  this.colum.push(<Table.Col><Form.Switch type="Checkbox" name="toggle" value={elemento[columna.columna]} checked={elemento[columna.columna]? true: false} className={"disabled"} /></Table.Col>)
                                  //this.colum.push(<Table.Col>{elemento[columna.columna]}</Table.Col>)
                                  break;
                                case "custodia":
                                  this.colum.push(<Table.Col><Form.Switch type="Checkbox" name="toggle" value={elemento[columna.columna]} checked={elemento[columna.columna]? true: false} className={"disabled"} /></Table.Col>)
                                  break;
                                case "origenMovimiento":
                                    switch (elemento[columna.columna]){
                                      case "OTM": 
                                        this.colum.push(<Table.Col>DHL</Table.Col>)
                                      break;
                                      case "":
                                      case null:
                                        this.colum.push(<Table.Col>Manual</Table.Col>)
                                      break;
                                      default:
                                        this.colum.push(<Table.Col>{elemento[columna.columna]}</Table.Col>)
                                      break;
                                    }
                                    break;
                                case "gps":{
                                    if(this.state.gpsT){
                                    this.colum.push(
                                      <Table.Col>
                                      {this.state.gpsT[elemento.id]?
                                      <div>
                                        <div  className="badge" style={{ color:this.state.gpsT[elemento.id].iconBattery.color, "font-size":"80%" }}>
                                          { this.state.gpsT[elemento.id].nombre }
                                        </div>
                                        <br></br>
                                        <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.state.gpsT[elemento.id].nombre+", "+this.state.gpsT[elemento.id].nivel_bateria+" %" }
                                                                      style={{ color:this.state.gpsT[elemento.id].iconBattery.color, "font-size":"70%" }}>
                                          <span className={this.state.gpsT[elemento.id].iconBattery.icono}></span>
                                        </div>
                                        <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.state.gpsT[elemento.id].ultimo_reporte }
                                                                      style={{ color:this.state.gpsT[elemento.id].iconTime.color, "font-size":"70%" }}>
                                          <span className={this.state.gpsT[elemento.id].iconTime.icono}></span>
                                        </div>
                                      </div>
                                      :
                                      <div>{ elemento.movimientoGps? elemento.movimientoGps.map((g, ind)=> <span> {g.gps.nombre}</span>): "" }</div>
                                      }
                                      </Table.Col>)
                                    }else{
                                      this.colum.push(<Table.Col>
                                        <div>{ elemento.movimientoGps? elemento.movimientoGps.map((g, ind)=> <span> {g.gps.nombre}</span>): "" }</div>
                                      </Table.Col>);
                                    }
                                  }
                                  break;
                                case "notificaciones":
                                  var nticacion= [];
                                  if (this.state.alertasT) {
                                      if (this.state.alertasT[elemento.id]) {
                                        let semaforo= 0; //0,1,
                                        Object.keys( this.state.alertasT[elemento.id] ).forEach(
                                          (key, indice) => {
                                            if(this.state.alertasT[elemento.id][key][0]){
                                              if(this.state.alertasT[elemento.id][key][0].tiempo){
                                                let timemil= timeToMilisegundos(this.state.alertasT[elemento.id][key][0].tiempo);
                                                if(timemil > 0){
                                                  for(let alertas of this.state.alertasT[elemento.id][key] ){
                                                    if( (new Date(alertas.alert.fechaCreacion).getTime()+timemil) < new Date().getTime() ){
                                                      semaforo= 2;
                                                    }else{
                                                      let diferencia= new Date(alertas.alert.fechaCreacion).getTime() - new Date().getTime();
                                                      if( (timemil-diferencia) < 2 && diferencia > 0 && semaforo < 2){
                                                        semaforo= 1;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            this.state.alertasT[elemento.id][key][0]?
                                            nticacion.push( <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.state.alertasT[elemento.id][key][0].tipo+ ", Tiempo de atención: "+ (timeToMilisegundos(this.state.alertasT[elemento.id][key][0].tiempo)/1000)/60 +" min" }
                                                                  style={{ color: this.state.alertasT[elemento.id][key][0].color, "font-size": "140%" }}>
                                            <span className={'fa '+key}>{this.state.alertasT[elemento.id][key].length }</span>
                                            </div>): console.log("")
                                          });
                                          this.colum.push(<Table.Col>
                                                            <div className="row" style={{ "background-color": semaforo === 1? "rgba(228, 228, 98, 0.7)" : semaforo === 2? "rgba(241, 111, 111, 0.67)":"rgba(73, 223, 67, 0.29)" }}>
                                                              {<div className="badge col"> { nticacion } </div>}
                                                            </div>
                                                          </Table.Col>);
                                      }else{
                                        this.colum.push(<Table.Col> </Table.Col>);
                                      }
                                  }
                                  break;
                                case "alertas":
                                  var nticacion= [];
                                  if (this.state.alertasT) {
                                      if (this.state.alertasT[elemento.id]) {
                                        let semaforo= 0; //0,1,
                                        Object.keys( this.state.alertasT[elemento.id] ).forEach(
                                          (key, indice) => {
                                            if(this.state.alertasT[elemento.id][key][0]){
                                              if(this.state.alertasT[elemento.id][key][0].tiempo){
                                                let timemil= timeToMilisegundos(this.state.alertasT[elemento.id][key][0].tiempo);
                                                if(timemil > 0){
                                                  for(let alertas of this.state.alertasT[elemento.id][key] ){
                                                    if( (new Date(alertas.alert.fechaCreacion).getTime()+timemil) < new Date().getTime() ){
                                                      semaforo= 2;
                                                    }else{
                                                      let diferencia= new Date(alertas.alert.fechaCreacion).getTime() - new Date().getTime();
                                                      if( (timemil-diferencia) < 2 && diferencia > 0 && semaforo < 2){
                                                        semaforo= 1;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            if(this.state.alertasT[elemento.id][key].filter(word => !word.alert.atendida).length){

                                              nticacion.push( <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.state.alertasT[elemento.id][key][0].tipo+ ", Tiempo de atención: "+ (timeToMilisegundos(this.state.alertasT[elemento.id][key][0].tiempo)/1000)/60 +" min" }
                                                                  style={{ color: this.state.alertasT[elemento.id][key][0].color, "font-size": "140%" }}>
                                                                <span className={'fa '+key}>{this.state.alertasT[elemento.id][key].filter(word => !word.alert.atendida).length }</span>
                                                              </div>);
                                            }
                                          });
                                          if(nticacion.length){
                                            this.colum.push(<Table.Col>
                                              <div className="row" style={{ "background-color": semaforo === 1? "rgba(228, 228, 98, 0.7)" : semaforo === 2? "rgba(241, 111, 111, 0.67)":"rgba(73, 223, 67, 0.29)" }}>
                                                {<div className="badge col"> { nticacion } </div>}
                                              </div>
                                            </Table.Col>
                                            );
                                          }else{
                                            this.colum.push(<Table.Col> </Table.Col>);
                                          }
                                      }else{
                                        this.colum.push(<Table.Col> </Table.Col>);
                                      }
                                  }else{ this.colum.push(<Table.Col> </Table.Col>); }
                                  break;
                                case "editar":
                                  this.colum.push(
                                    <Table.Col>
                                      <ModalLayout
                                        title="Modificar"
                                        accion="modificar"
                                        //name="Editar"
                                        name={  <div className="badge" style={{ color: "green", "font-size": "120%" }} data-toggle="tooltip" data-placement="top" title="Editar movimiento"><span className="fa fa-edit"></span> </div> }
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        formulario={<this.Catalogo_form 
                                          accion={'modificar'} 
                                          elementos={elemento} 
                                          getfuntion={this.getfuntion}
                                          stor={{...this.props,hideModal:this.hiddenmodal}} 
                                          
                                          />}
                                      />
                                    </Table.Col>)
                                  break;
                                case "incidencias":
                                    let diferencia= 0;
                                    if (new Date().getTime() > new Date(elemento.ultimaIncidencia).getTime()) {
                                      diferencia = (((new Date().getTime() - new Date(elemento.ultimaIncidencia).getTime())/1000)/60)/60  
                                    }
                                    this.colum.push(
                                    <Table.Col>
                                      <div className="row" style={{"font-size":"80%"}}>{elemento.nombreIncidencia}</div>
                                      <div className="row" style={{"font-size":"80%", "background-color": diferencia > 1? "rgba(241, 111, 111, 0.67)":"rgba(73, 223, 67, 0.29)"}}>{elemento.ultimaIncidencia}</div>
                                      
                                      <ModalLayout
                                        tamanio={'50%'}
                                        title="Nueva Incidencia"
                                        name={<Button pill size="sm" outline color="primary" ><Icon name="plus" /></Button>}
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        formulario=
                                          {elemento.plataforma.nombre.toUpperCase().includes("TRAXION")?
                                              <IncidenciasTraxion
                                                accion={'nuevo'}
                                                idusuario={user.id}
                                                idmovimiento={elemento.id}
                                                idplataforma={elemento.plataforma.id}
                                                movimiento={elemento}
                                                unidad= {null}//recargar
                                                idEntregaActiva={null}//recargar
                                                entregas={null}//recargar
                                                getfuntion={this.getfuntion}
                                                stor={this.props}
                                                plataformaTipo={2}
                                                gpsReciente={this.state.gpsT? this.state.gpsT[elemento.id]?this.state.gpsT[elemento.id]:null:null}
                                                latitud= { null }
                                                longitud= { null }
                                                closeModal={this.hiddenmodal}
                                              />
                                              :
                                            <MovimientoIncidenciasTerrestre
                                              accion={'nuevo'}
                                              desde={'EspecificoTerrestre'}
                                              idusuario={user.id}
                                              idmovimiento={elemento.id}
                                              idplataforma={elemento.plataforma.id}
                                              movimiento={elemento}
                                              //idEntrega={this.state.idEntrega}
                                              //idEntregaActiva={null}
                                              //entregas={null}
                                              //incidencias={null}
                                              //getfuntion={null}
                                              stor={this.props}
                                              getfuntion={this.getfuntion}
                                              plataformaTipo={2}
                                              gpsReciente={this.state.gpsT? this.state.gpsT[elemento.id]?this.state.gpsT[elemento.id]:null:null}
                                              //latitud= { null }
                                              //longitud= { null }
                                              closeModal={this.hiddenmodal}
                                            />
                                          }
                                      />
                                    </Table.Col>
                                                )
                                  break;
                                // case "nuevaIncidencia":
                                //   this.colum.push(
                                //     <Table.Col>
                                //       <ModalLayout
                                //         tamanio={'50%'}
                                //         title="Nueva Incidencia"
                                //         name={<Button pill size="sm" outline color="primary"><Icon name="plus" /></Button>}
                                //         hiddenModal={this.state.hiddenModals}
                                //         Fclose={this.hiddenmodal}
                                //         formulario=
                                //           {
                                //             <MovimientoIncidenciasTerrestre
                                //               accion={'nuevo'}
                                //               desde={'EspecificoTerrestre'}
                                //               idusuario={user.id}
                                //               idmovimiento={elemento.id}
                                //               idplataforma={elemento.plataforma.id}
                                //               movimiento={elemento}
                                //               //idEntrega={this.state.idEntrega}
                                //               //idEntregaActiva={null}
                                //               //entregas={null}
                                //               //incidencias={null}
                                //               //getfuntion={null}
                                //               stor={this.props}
                                //               getfuntion={this.getfuntion}
                                //               plataformaTipo={2}
                                //               gpsReciente={this.state.gpsT? this.state.gpsT[elemento.id]?this.state.gpsT[elemento.id]:null:null}
                                //               //latitud= { null }
                                //               //longitud= { null }
                                //               closeModal={this.hiddenmodal}
                                //             />
                                //           }
                                //       />
                                //     </Table.Col>
                                //                 )
                                  break;
                                case "nuevaEntrega":
                                  this.colum.push(
                                    <Table.Col>
                                      <ModalLayout
                                        tamanio={'50%'}
                                        title="Nueva Entrega"
                                        name={<Button pill size="sm" outline color="primary"><Icon name="plus" /></Button>}
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        /*
                                        formulario=
                                          {
                                            {
                                              <NuevaEntrega
                                                accion={'nuevo'}
                                                idusuario={user.id}
                                                idmovimiento={elemento.id}
                                                idplataforma={elemento.plataforma.id}
                                                movimiento={elemento}
                                                stor={this.props}
                                                getfuntion={this.getfuntion}
                                                plataformaTipo={2}
                                                gpsReciente={this.state.gpsT? this.state.gpsT[elemento.id]?this.state.gpsT[elemento.id]:null:null}
                                                closeModal={this.hiddenmodal}
                                              />
                                            }
                                            />
                                          */
                                      />
                                    </Table.Col>
                                                )
                                  break;
                                case "entregas":
                                  this.colum.push(
                                    <Table.Col>
                                      <div className="row" style={{"font-size":"80%"}}>{elemento.ultimaEntrega}</div>
                                      <div className="row" style={{"font-size":"80%"}}>{elemento.fechaEntrega}</div>
                                      <ModalLayout
                                        tamanio={'50%'}
                                        title="Nueva Entrega"
                                        name={<Button pill size="sm" outline color="primary" ><Icon name="plus" /></Button>}
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        formulario={
                                          <EntregaFrom
                                            accion={'nuevo'}
                                            idmovimiento={elemento.id}
                                            //destinos={this.state.destinos}
                                            stor={this.props}
                                            getfuntion={this.getfuntion}
                                            closeModal={this.hiddenmodal}
                                          />
                                        }
                                      />
                                    </Table.Col>
                                                )
                                  break;
                                default:
                                  this.colum.push(<Table.Col>{elemento[columna.columna]}</Table.Col>)
                                  break;
                              }
                              //Fin Switch
                            //Fin No es Objeto
                            }
                          })
                        }</Table.Row>
                    )
                    :
                    <div>
                      <Table.Row><Table.Col> No hay Movimientos ..! </Table.Col></Table.Row>
                    </div>
                  }


                </Table.Body>
              </Table>
            </Dimmer>
          </Card.Body>
        </Card>
        <div hidden={!this.state.showErrormessage}>{this.state.ErrorMessage}</div>
      </Grid.Col>
    );

  }

  }
function mapStateToProps(state) {
  return {
    movimientos_naviera_object: state.catalogos_reducer.movimientos_naviera_object,
    movimientos_terrestres_object: state.catalogos_reducer.movimientos_terrestres_object,
    clientes_object: state.catalogos_reducer.clientes_object,
    movimientosTipo_object: state.catalogos_reducer.movimientosTipo_object,
    navieras_object: state.catalogos_reducer.navieras_object,
    operacionesClientes_object: state.catalogos_reducer.operacionesClientes_object,
    operacionesEstatus_object: state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo_object: state.catalogos_reducer.operacionesTipo_object,
    operadores_object: state.catalogos_reducer.operadores_object,
    transportistas_object: state.catalogos_reducer.transportistas_object,
    updateStatusMensaje_Object:state.catalogos_reducer.update_mensaje,
    otd_object: state.catalogos_reducer.otd_object,
    plataformas_object: state.catalogos_reducer.plataformas_object,
    rutasEstatus_object: state.catalogos_reducer.rutasEstatus_object,
    corredores_object: state.catalogos_reducer.corredores_object,
    funcionalidades_object: state.catalogos_reducer.funcionalidades_object,
    privilegios_object: state.catalogos_reducer.privilegios_object,
    unidades_object: state.catalogos_reducer.unidades_object,
  }
}
function mapDispachToProps(dispach) {
  return {
    movimientos_naviera_update: (elementos) => dispach(ACTIONS.elementos_movimientos_naviera_imput(elementos)),
    movimientos_terrestres_update: (elementos) => dispach(ACTIONS.elementos_movimientos_terrestres_imput(elementos)),
    clientes_update: (elementos) => dispach(ACTIONS.elementos_clientes_imput(elementos)),
    movimientosTipo_update: (elementos) => dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    navieras_update: (elementos) => dispach(ACTIONS.elementos_navieras_imput(elementos)),
    operacionesClientes_update: (elementos) => dispach(ACTIONS.elementos_operacionesClientes_imput(elementos)),
    operacionesEstatus_update: (elementos) => dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos) => dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    operadores_update: (elementos) => dispach(ACTIONS.elementos_operadores_imput(elementos)),
    updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status)),
    transportistas_update: (elementos) => dispach(ACTIONS.elementos_transportistas_imput(elementos)),
    otd_update: (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    plataformas_update: (elementos) => dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    rutasEstatus_update: (elementos) => dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    corredores_update: (elementos) => dispach(ACTIONS.elementos_corredores_imput(elementos)),
    funcionalidades_update: (elementos) => dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),
    privilegios_update: (elementos) => dispach(ACTIONS.elementos_privilegios_imput(elementos)),
    unidades_update: (elementos) => dispach(ACTIONS.elementos_unidades_imput(elementos)),
  }
}
export default connect(mapStateToProps, mapDispachToProps)(Catalogo)
