import LogOut from "../Auth/LogOut";
export function JWT(){
}
export function validarToken (token) {
        if(token){
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }).join(''));
        let tok= JSON.parse(jsonPayload);
        if(new Date() > new Date(tok.exp*1000) ){
          LogOut(); //Salir
        }
        //console.log("user   : "+ tok.sub);
        //console.log("creado : "+ new Date(tok.iat*1000));
        //console.log("finnal : "+ new Date(tok.exp*1000));
        }else{
          LogOut(); //Salir
        }
    };
  export function tokenGetUserName (token) {
    if(token){
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }).join(''));
    let tok= JSON.parse(jsonPayload);
    if(new Date() > new Date(tok.exp*1000) ){
      LogOut(); //Salir
    }
    return tok.sub;
    }else{
      LogOut(); //Salir
    }
  };
export function getPrivilegio (token, privilegio) {
  if(token && privilegio){
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }).join(''));
    let tok= JSON.parse(jsonPayload);
    if(new Date() > new Date(tok.exp*1000) ){
      LogOut(); //Salir
    }
    let privilegios= tok.scopes.split(",");
    return privilegios.filter((p) => p === privilegio).length === 1 ? true: false;
  }
};
export default JWT;
