import React, { Component } from "react";
import { Button, Form, Icon, Grid, List, Table } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { ArrobaIncidenciaTerrestre } from "./Arroba";
import { NotificationManager } from 'react-notifications';
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
//import reloj from '../../../src/clock.png';  
import ModalLayout from "../../components/ModalLayout.react"
import { ActualizarHorariosTerrestres } from "./panelForms"



//import DatePicker from "react-datepicker";
//import 'react-datepicker/dist/react-datepicker.css';
import { timeToDateFill, timeToMilisegundos } from "../../herramientas/DateFormat";
var user = JSON.parse(sessionStorage.getItem("usuario"));
let saved = false;
function myFormato(date) {
  function pad(number) {
    if (number < 10) { return '0' + number; }
    return number;
  }
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}

class MovimientoIncidenciasTerrestreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incidencias: [],
      entregas: [],
      Entregas: { value: 0, label: "Selecciona una entrega", data: null },
      incidencia: { value: 0, label: "Elige Tipo de Incidencia", data: null },
      idPlataforma: this.props.idPlataforma || this.props.idplataforma,
      movimiento: this.props.movimiento || this.props.movimiento,
      comentario: this.props.comentario || "",
      latitud: this.props.latitud || "",
      longitud: this.props.longitud || "",
      enviar: false,
      verContactos: false,
      destinosTransportistas: [],
      destinosMonitoreo: [],
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      disabledSave: true,
      hiddenModals: false,
      saved: false,
      deshabilitado: false,
      motivoDeshabilitado: "",
      incidenciasMovimiento: [],
      idMovimiento: 0,
      indisciplinaOperativa: false
    };
    this.enableSave = this.enableSave.bind(this);
    this.usuariosLogObject = {
      "idBitacoraMonitoreo": 0,
      "idUsuario": 0,
      "idMovimiento": 0,
      "idPlataformaTipo": 2,
      "accionFecha": "",
      "descripcion": ""
    };


  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  }
  enableSave = () => {
    if (this.state.incidencia.value > 0 && this.state.Entregas.value > 0) {
      return false;
    } else {
      return true
    }
  }

  componentWillMount = () => {
    saved = false;
    this.setState({ saved: saved });
    //this.getfuntion("get", "", "", "entregas/movimiento/"+this.props.idmovimiento,"entregas");
    this.getfuntion("get", [], "", "incidencias/idplataforma/" + this.props.idplataforma, "incidenciasAll");
    this.getfuntion("get", [], "", "terrestreEntregasIncidencias/movimiento/" + this.props.idmovimiento, "incidenciasMovimiento");
  }


  changeEntregas = (event) => {
    this.setState({ Entregas: event });
    this.validaFechas(this.state.incidencia, event);
  }
  componentDidMount = () => {
    let entregasTemp = [];
    if (this.props.entregas) {
      this.props.entregas.map((entre, index) => {
        entregasTemp.push({ value: entre.idEntrega, label: entre.destino, data: entre });
        if (entre.estatus) {
          this.setState({ Entregas: { value: entre.idEntrega, label: entre.destino, data: entre } })
        }
      });
    } else {
      this.getfuntion("get", "", "", "entregas/movimiento/" + this.props.idmovimiento, "entregas").then((returnVal) => {
        returnVal.map((entre, index) => {
          entregasTemp.push({ value: entre.idEntrega, label: entre.destino, data: entre });
          if (entre.estatus) {
            this.setState({ Entregas: { value: entre.idEntrega, label: entre.destino, data: entre } })
          }
        });

      });
    }
    this.setState({ entregas: entregasTemp });
    if (this.state.accion === 'nuevo') {
      let latitud = "";
      let longitud = "";
      if (this.props.latitud) {
        latitud = this.props.latitud;
      } else {
        latitud = this.props.gpsReciente ? this.props.gpsReciente.latitud : "";
      }
      if (this.props.longitud) {
        longitud = this.props.longitud
      } else {
        longitud = this.props.gpsReciente ? this.props.gpsReciente.longitud : "";
      }
      this.setState({ latitud: latitud });
      this.setState({ longitud: longitud });
    } else {

    }
    this.setState({idMovimiento: this.props.idmovimiento})
  }
  crud_post() {
    if (!saved) {
      saved = true;
      this.setState({ saved: saved });
      this.getfuntion("post", {
        "idIncidencia": this.state.incidencia.value,
        "idMovimiento": this.state.idMovimiento,
        "idEntrega": this.state.Entregas.value,
        "comentario": this.state.comentario,
        "creado": null,
        "atencionUsuario": user.id,
        "atencionFecha": null,
        "latitud": this.state.latitud,
        "longitud": this.state.longitud,
        "indisciplinaOperativa": this.state.indisciplinaOperativa
      },
        "",
        "terrestreEntregasIncidencias",
        "incidenciasTerrestre",
        "",
        this.state.enviar);
    }
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "", enviar = false) => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "put": {
          if (stateVar === "terrestreMovimientos") {
            NotificationManager.info("Se Terminara el movimiento", "Actualizacion, mov (" + returnVal.id + ") por Usuario: " + user.id);
            this.usuariosLogObject.idBitacoraMonitoreo = 5;
            this.usuariosLogObject.idUsuario = user.id;
            this.usuariosLogObject.idMovimiento = returnVal.id;
            this.usuariosLogObject.idPlataformaTipo = 2;
            this.usuariosLogObject.accionFecha = myFormato(new Date());
            this.usuariosLogObject.descripcion = "Finalizado Movimiento id:" + returnVal.id + " por Usuario id: " + user.id;
            Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
          }
          if (stateVar === "delTerrestreMovimientos") {
            NotificationManager.info("Se eliminará el movimiento", "Actualizacion, mov (" + returnVal.id + ") por Usuario: " + user.id);
            this.usuariosLogObject.idBitacoraMonitoreo = 5;
            this.usuariosLogObject.idUsuario = user.id;
            this.usuariosLogObject.idMovimiento = returnVal.id;
            this.usuariosLogObject.idPlataformaTipo = 2;
            this.usuariosLogObject.accionFecha = myFormato(new Date());
            this.usuariosLogObject.descripcion = "Eliminado Movimiento id:" + returnVal.id + " por Usuario id: " + user.id;
            Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
          }
          if (stateVar === "entregas") {
            let entregasTem = this.state.entregas;
            entregasTem.map((entre, index) => {
              if (returnVal.idEntrega === entre.idEntrega) {
                entre = returnVal;
                this.setState({ entregas: entregasTem });
                NotificationManager.info("Se modifica entrega", "Actualizacion, mov (" + returnVal.idMovimiento + ")");
              }
            })
            this.usuariosLogObject.idBitacoraMonitoreo = 3;
            this.usuariosLogObject.idUsuario = user.id;
            this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;//obj.id;//returnVal.id;
            this.usuariosLogObject.idPlataformaTipo = 2;
            this.usuariosLogObject.accionFecha = myFormato(new Date());
            this.usuariosLogObject.descripcion = "Actualizacion Entrega: " + returnVal.idEntrega + " Movimiento id:" + returnVal.idMovimiento + " Campo: " + id;//returnVal.id;
            Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
          }
        }
          break;
        case "get":
          {
            if (stateVar === "incidenciasAll") {
              let incidenciasTemp = [{ value: 0, label: "Selecciona Incidencia", data: null }];
              returnVal.map((incidencia, index) => {
                if (this.state.accion === "modificar") { this.setState({ incidencia: { value: incidencia.id, label: incidencia.nombre, incidencia: null } }); }
                incidenciasTemp.push({ value: incidencia.id, label: incidencia.nombre, data: incidencia });
              });
              this.setState({ incidencias: incidenciasTemp });
            }
            if (stateVar === "destinosTransportistas") {
              this.setState({ destinosTransportistas: returnVal });
            }
            if (stateVar === "destinatarios_sky_terrestre") {
              this.setState({ destinosMonitoreo: returnVal });
            }
            if (stateVar === "incidenciasMovimiento") {
              this.setState({ incidenciasMovimiento: returnVal });
            }
            if (stateVar === "entregas") {

              return returnVal;
            }

          }
          break;
        case "post":
          {
            if (stateVar === "incidenciasTerrestre") {
              this.props.closeModal();
              this.setState({ algo: true });
              let destMonitoreo = "";
              let destTransportistas = "";
              try {
                this.state.destinosMonitoreo.forEach((key, index) => {
                  destMonitoreo = destMonitoreo.length > 0 ? destMonitoreo + ", " : destMonitoreo;
                  destMonitoreo = destMonitoreo + key.nombre + " " + key.aPaterno + " " + key.aMaterno + "<" + key.correo + ">";
                });
              } catch (e) {
                console.log(e);
              }
              try {
                this.state.destinosTransportistas.forEach((key, index) => {
                  destTransportistas = destTransportistas.length > 0 ? destTransportistas + ", " : destTransportistas;
                  destTransportistas = destTransportistas + key.nombre + " " + key.aPaterno + " " + key.aMaterno + "<" + key.correo + ">";
                });
              } catch (e) {
                console.log(e);
              }
              if (returnVal.idEntrega) {
                try {
                  NotificationManager.info(returnVal.comentario, "Terrestre, Incidencia Nueva (" + returnVal.idEntrega + ")");
                  if (enviar && returnVal.idEntrega) {
                    ArrobaIncidenciaTerrestre(returnVal).then((returnVals) => {
                      NotificationManager.info(returnVals, "Notificación de incidencia");
                      this.usuariosLogObject.idBitacoraMonitoreo = 6;
                      this.usuariosLogObject.idUsuario = user.id;
                      this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                      this.usuariosLogObject.idPlataformaTipo = 2;
                      this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                      this.usuariosLogObject.descripcion = "Arroba Incidencia (" + destMonitoreo + destTransportistas + " ), Detalle: " + returnVals;
                      Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", "", "");
                    });
                    this.usuariosLogObject.idBitacoraMonitoreo = 2;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                    this.usuariosLogObject.idPlataformaTipo = 2;
                    this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                    this.usuariosLogObject.descripcion = "idIncidencia: " + returnVal.idIncidencia + ", comentario: " + returnVal.comentario;
                    Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", "", "");
                  } else {
                    if (enviar && !returnVal.idEntrega) {
                      NotificationManager.error("No se puede enviar notificación", "Incidencia Nueva");
                    }
                  }
                } catch (e) {
                  console.log(e);
                }

              } else {
                NotificationManager.error("Al parecer no se ha creado la incidencia", "Incidencia Nueva");
              }

            }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      console.log(err);
      if (stateVar === "incidenciasTerrestre") {
        saved = false;
        this.setState({ saved: saved });
      }
    });
  }

  changeIncidencias = (event) => {
    this.setState({ incidencia: event });

    this.getfuntion("get", [], "", "personas/destinatarios_transportistas_ter/" + this.state.idMovimiento + "/" + 1, "destinosTransportistas"); //acumulador 1
    if (event.data) {
      this.getfuntion("get", [], "", "personas/destinatarios_sky_terrestre_incidencias/" + this.state.idMovimiento + "/" + (event.data.idEtiqueta ? event.data.idEtiqueta : 0), "destinatarios_sky_terrestre");
    }
    this.setState({ enviar: true });

    this.validaFechas(event, this.state.Entregas);

    /*
    if(event.data.idEtiqueta){
      this.getfuntion("get", [], "", "personas/destinatarios_transportistas_ter/"+this.props.idmovimiento+"/"+1, "destinosTransportistas"); //acumulador 1
      this.getfuntion("get", [], "", "personas/destinatarios_sky_terrestre_incidencias/"+this.props.idmovimiento+"/"+event.data.idEtiqueta, "destinatarios_sky_terrestre");
      this.setState({ enviar: true });
    }else{
      this.setState({ destinosTransportistas: [] });
      this.setState({ destinosMonitoreo: [] });
      this.setState({ enviar: false });
    }*/
  }
  validaFechas = (incidencia, entrega) => {
    
    if (entrega.data) {
      if (incidencia.label === "Inicio de ruta" || incidencia.label === "Inicio de ruta con unidad en movimiento") {

        if (entrega.data.inicioRuta == null || entrega.data.inicioRuta == "") {
          this.setState({ deshabilitado: true });
          this.setState({ motivoDeshabilitado: "Para registrar la incidencia "+incidencia.label+" se debe capturar la fecha de inicio de ruta" });
        } else {
          this.setState({ deshabilitado: false });
          this.setState({ motivoDeshabilitado: "" });
        }

      }
      if (incidencia.label === "FIN" || incidencia.label === "Fin de ruta") {
        let entregasSinFecha = 0;
        for (let entr of this.state.entregas) {
          
          let tmpFechaArribo = entr.data.fechaArribo;
          let tmpInicioRuta = entr.data.inicioRuta;
          let tmpFechaSalida = entr.data.fechaSalida;
          if (tmpFechaArribo == null || tmpFechaArribo == "" || tmpInicioRuta == null || tmpInicioRuta == "" || tmpFechaSalida == null || tmpFechaSalida == "") {
            entregasSinFecha ++;
          }
        }

        if (entregasSinFecha > 0) {
          this.setState({ deshabilitado: true });
          this.setState({ motivoDeshabilitado: "No se puede registrar la incidencia "+incidencia.label+" porque el movimiento tiene "+entregasSinFecha+" entrega"+(entregasSinFecha>1?"s":"")+" sin fechas completas (inicio de ruta, arribo a dirección de entrega o salida de dirección de entrega)" });

        } else {
          this.setState({ deshabilitado: false });
          this.setState({ motivoDeshabilitado: "" });
        }

      }
      if (incidencia.label === "Arribo a cliente" || incidencia.label === "En cliente descargando") {

        if (entrega.data.fechaArribo == null || entrega.data.fechaArribo == "") {
          this.setState({ deshabilitado: true });
          this.setState({ motivoDeshabilitado: "Para registrar la incidencia "+incidencia.label+" se debe capturar la fecha de arribo a dirección de entrega" });

        } else {
          this.setState({ deshabilitado: false });
          this.setState({ motivoDeshabilitado: "" });
        }

      }
      if (incidencia.label != "Inicio de ruta" && incidencia.label != "Inicio de ruta con unidad en movimiento" && incidencia.label != "FIN" && incidencia.label != "Fin de ruta" && incidencia.label != "Arribo a cliente" && incidencia.label != "En cliente descargando") {
        this.setState({ deshabilitado: false });
        this.setState({ motivoDeshabilitado: "" });
      }
    } else {
      this.setState({ deshabilitado: true });
      this.setState({ motivoDeshabilitado: "Debe seleccionar una entrega" });
    }
    
  }
  changeComentario = (event) => {
    this.state.comentario = event.target.value;
    this.setState({ comentario: event.target.value });
  }
  estaPrivilegioActivo = (clave) => {

    let privilegios = "";//this.props.stor.privilegios_object;
    if (this.props.stor.privilegios_object) {
      privilegios = this.props.stor.privilegios_object;
    } else {
      privilegios = this.props.stor.stor.privilegios_object;
    }
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0) { this.privilegio = privilegios[i]; return true } else { return false }
      }
    }
    return false;
  }
  render() {
    return (
      <div>
        
        <h1>Movimiento: {this.state.idMovimiento}</h1>
        {this.state.Entregas.value ?
          <ModalLayout
            tamanio={"70%"}
            title="Actualizar horario"
            name={<Button pill size="sm" outline color="primary"><Icon name="upload" /><Icon name="clock" /></Button>}
            hiddenModal={this.state.hiddenModals}
            Fclose={this.hiddenmodal}
            formulario={<ActualizarHorariosTerrestres
              accion={'modificar'}
              idusuario={user.id}//movimiento={this.state.movimiento}
              movimiento={this.props.movimiento}//oky
              idEntregaActiva={this.state.Entregas.data} //oky
              idEntrega={this.state.Entregas.data}   //oky
              getfuntion={this.getfuntion}
              stor={this.props.stor}
              rutasEstatusObject={this.props.stor.rutasEstatus_object ? this.props.stor.rutasEstatus_object : this.props.stor.rutasEstatus}
              operacionesEstatus={[{ "id": 2, "nombre": "EN RUTA" }, { "id": 3, "nombre": "TERMINADO" }, { "id": 1, "nombre": "SIN UNIDAD" }, { "id": 0, "nombre": "ELIMINADO" }]}
              closeModal={this.hiddenmodal}

            />}
          /> : null}
        {this.state.accion === "modificar" ? <h1> {this.props.elementos.comentario} </h1> : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Incidenci@s"><Select value={this.state.incidencia} onChange={this.changeIncidencias} options={this.state.incidencias} /></Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Latitud">
                  <Form.Input name="latitud" value={this.state.latitud} onChange={(e) => this.setState({ latitud: e.target.value })} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Longitud">
                  <Form.Input name="longitud" value={this.state.longitud} onChange={(e) => this.setState({ longitud: e.target.value })} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12}>
                <Form.Group label="Comentario">
                  <Form.Textarea name="comentario" type="text" value={this.state.comentario} onChange={this.changeComentario} maxlength="50" />
                </Form.Group>
              </Grid.Col>

            </Grid.Row>
          </Grid.Col>

          <Grid.Col md={12}>
            <Form.Group label="Enviar correo">
              <Form.Switch type="Checkbox" checked={this.state.enviar} onChange={(e) => this.setState({ enviar: e.target.checked })} />
            </Form.Group>
            <Form.Group label="Indisciplina Operativa">
              <Form.Switch
                type="Checkbox"
                checked={this.state.indisciplinaOperativa}
                onChange={(e)=> this.setState({indisciplinaOperativa: e.target.checked })}
              />
            </Form.Group>
            {this.state.enviar ?
              <Form.Group label="ver contactos">
                <Form.Switch type="Checkbox" checked={this.state.verContactos} onChange={(e) => this.setState({ verContactos: e.target.checked })} />
              </Form.Group> : null
            }
            <div hidden={!this.state.verContactos}>
              {
                this.state.destinosTransportistas ?
                  <List.Group>
                    <h3>{this.state.destinosTransportistas.length > 0 ? "Transportista" : null}</h3>
                    {this.state.destinosTransportistas.map((transp, index) =>
                      <List.GroupItem action icon="rss">
                        <div className="row">
                          <div className="col-5"> <Icon name="user" />{transp.nombre + " " + transp.aPaterno + " " + transp.aMaterno} </div>
                          <div className="col-5"> <Icon name="mail" />{transp.correo} </div>
                        </div>
                      </List.GroupItem>
                    )
                    }
                  </List.Group>
                  :
                  null
              }
              {
                this.state.destinosMonitoreo ?
                  <List.Group>
                    <h3>{this.state.destinosMonitoreo.length > 0 ? "Monitoreo" : null}</h3>
                    {
                      this.state.destinosMonitoreo.map((moni, index) =>
                        <List.GroupItem action icon="rss">
                          <div className="row">
                            <div className="col-5"> <Icon name="user" />{moni.nombre + " " + moni.aPaterno + " " + moni.aMaterno} </div>
                            <div className="col-5"> <Icon name="mail" />{moni.correo} </div>
                          </div>
                        </List.GroupItem>
                      )
                    }
                  </List.Group>
                  :
                  null
              }
            </div>
            {
              this.state.enviar ?
                this.state.destinosTransportistas.length > 0 || this.state.destinosMonitoreo.length > 0 ? null
                  : <List.GroupItem action icon="rss"> Al parecer no se ha configurado la plantilla, o no hay destinatarios, revise su configuración </List.GroupItem>
                : null
            }
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <List.GroupItem action icon="message-square" label="Entrega">
              <Select value={this.state.Entregas} onChange={this.changeEntregas} options={this.state.entregas} />
            </List.GroupItem>
          </Grid.Col>
        </Grid.Row>
        
        <div className="float-right">
          <span className="badge">
            {this.props.desde != 'AlertasListar' ?
              <Button target="_blank" size="sm" color="primary" disabled={this.enableSave() || this.state.saved || this.state.deshabilitado} onClick={() => this.crud_post()} >
                <span className="badge"><Icon link={true} name="save" />Guardar Incidencia</span>
              </Button> : <div></div>
            }

          </span>
          
          {
            this.state.accion === "modificar" ?
              <span className="badge">
                <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()} >
                  <span className="badge"><Icon link={true} name="trash" />Borrar</span>
                </Button>
              </span>
              : null
          }
        </div>
        <div>{
            this.props.desde != 'AlertasListar' && this.state.motivoDeshabilitado ?
              <div className="text-red">{this.state.motivoDeshabilitado}</div> : <span></span>
          }</div>
        <div>
          {this.state.incidencias.length > 0 ?
            <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-light" headerItems={[{ content: "ID" }, { content: "Incidencia" }, { content: "Comentario" }, { content: "Creado" }, { content: "Atencion Fecha" }]}>
              <Table.Body>{
                this.state.incidenciasMovimiento.filter(word => word.idEntrega === this.state.Entregas.value ? true : false).sort(function (a, b) {
                  if (a["creado"] === null) { return -1; }
                  if (a["creado"] < b["creado"]) { return 1; }
                  if (a["creado"] > b["creado"]) { return -1; }
                  return 0;
                }).map((elemento, index) =>
                  <Table.Row>
                    <Table.Col>{elemento.idEntregaIncidencia}</Table.Col>
                    <Table.Col>{this.getIncidenciaNombre(elemento.idIncidencia)}</Table.Col>
                    <Table.Col>{elemento.comentario}</Table.Col>
                    <Table.Col>{elemento.creado}</Table.Col>
                    <Table.Col>{elemento.atencionFecha}</Table.Col>
                    <Table.Col>
                      <small>
                      <Form.Switch type="Checkbox" checked={elemento.indisciplinaOperativa} />
                      </small>
                    </Table.Col>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            :
            null
          }
        </div>
      </div>

    );
  }
  getIncidenciaNombre = (id) => {

    for (let item of this.state.incidencias) {
      if (id === item.value) { return item.label }
    }
    return "";
  }
}
export default MovimientoIncidenciasTerrestreForm;
