import React, { Component } from "react";
import { Page, Card, Grid, Form, Button, Dropdown, Table, Input, Icon } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import Select from "react-select";
/** alertas */
import { NotificationManager } from 'react-notifications';
class RecuperarGpsForm extends Component {
    constructor() {
        super();
        this.state =  { gps: [],
                        gpsTemp: [],
                        gpsR: [],
                        gpsEstatus: [],
                        estatus: {label: "Selecciona estatus", value: 0 },
                        opcion: {label: "Seleccione una opción", value: 0 },
                        search: null,
                        corredores: [],
                        corredor: { value: 0, label: "Selecciona un corredor" }
                      };
        this.opciones= [
          { value: 0, label: "Seleccione una opción",   api: "" },
          { value: 1, label: "Corredores",              api: "corredor" },
          { value: 2, label: "Transportistas",          api: "transportista" },
          { value: 3, label: "Placas",                  api: "remolque_placa" },
          { value: 4, label: "Booking",                 api: "booking" },
          { value: 5, label: "Fecha colocación",        api: "fecha_colocacion" },
          { value: 6, label: "Gps",                     api: "gps" },
          { value: 7, label: "Cita Programada",         api: "cita_programada" },
        ];
        this.estatus= [
          { value: 0, label: "Selecciona estatus"},
          { value: 1, label: "Disponible" },
          { value: 2, label: "Instalado" },
          { value: 3, label: "Por recuperar" },
          { value: 4, label: "Baja definitiva" }
        ]
    }

    componentWillMount(){ 
      this.getfuntion("get", "", "", "corredores/tipoId/1", "corredores");
    }
    changeEstatus = (event) => {
      this.setState({ estatus: event });
      if( event.value > 0 ){
        if( this.state.opcion.value > 0 ){
            this.getfuntion("get", [], "", "gps_por_recuperar/"+this.state.opcion.api+"/"+this.state.search+"/"+event.value+"/pagina/0/total/10000", "gps");
        }else{
            this.getfuntion("get", [], "", "gps_por_recuperar/gps_estatus/"+event.value+"/pagina/0/total/10000", "gps");
        }
      }
    }
    changeOpcion = (event) => {
      let searchTemp= null;
      this.setState({ search: searchTemp });
      this.setState({ corredor: { value: 0, label: "Selecciona un corredor" } });
      this.setState({ opcion: event });
      let estatus= this.state.estatus.value > 0 ? this.state.estatus.value : 3;
      if( event.value > 0 && searchTemp){
        //this.getfuntion("get", [], "", "gps_por_recuperar/"+event.api+"/"+this.state.search+"/"+estatus+"/pagina/0/total/10000", "gps"); 
      }
    }
    changeGps = (event) => this.setState({ gpsR: event });

    getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
        Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
          /**
           * filtro de variables
           */
          switch (metodo) {
            case "post":
              {
                if (stateVar === "gpsRecuperar") {
                 var gpsTem= this.state.gps;
                 if(returnVal.OK ){
                    gpsTem.map((gp, index)=>{ if(obj.gps === gp.idGps){ gpsTem.splice(index, 1); } } );
                    this.setState({ gps: gpsTem });    this.setState({ gpsR: [] });
                    NotificationManager.info("GPS "+ obj.gps, "RECUPERADO");
                 }
                 if( returnVal.Error ){
                  NotificationManager.error("GPS "+ obj.gps, returnVal.Error);
                 }
                }                
              }
              break;
            case "get":{
                if (stateVar === "gps") { 
                  if(returnVal.content){
                    this.setState({ gps: returnVal.content });
                  }
                }
                if (stateVar === "corredores" && stateVar[0]) {
                  let corredoresTemp= [{ value: 0, label: "Selecciona un corredor" }];
                    for( let item of returnVal){
                      corredoresTemp.push({ value: item.id, label: item.nombre });
                    }
                    this.setState({ corredores: corredoresTemp });
                }
                if (stateVar === "gpsestatus") { 
                  this.setState({ gpsEstatus: returnVal });
                }
                if (stateVar === "gpsRecuperar" && returnVal) {
                    this.getfuntion("get", "", "", "gpsestatus/plataforma/"+obj.idPlataforma.idGg+"/gps/"+obj.idGps, "gpsestatus");
                    this.setState({ gpsR: returnVal});
                    //NotificationManager.info("GPS "+ obj.gps, "RECUPERADO");
                }
                if (stateVar === "urlGps") {
                  window.open(returnVal.url, 'sharer', 'width=900,height=600,scrollbars=NO');
                }
            }
                break;
            
            default:
              break;
          }
        }).catch(err => {
          if (err.response) {
            console.log(err.response);  } else {console.log("Error desconocido ...." + err.response); }
        })
    }  

    recuperarGPS = () => { 
        var recGps={gps: this.state.gpsR.id , plataforma:16, estatus: 1}
        this.getfuntion("post", recGps, "", "gps/asignar_gps", "gpsRecuperar");
    }
    changesearch=(e)=>{
      let searchTemp= null;
      switch (this.state.opcion.value) {
        case 1: searchTemp= e.value; this.setState({ corredor: e });
          break;
        case 2: searchTemp= e.target.value;
          break;
        case 3: searchTemp= e.target.value;
          break;
        case 4: searchTemp= e.target.value;
          break;
        case 5: searchTemp= e.target.value;
          break;
        case 6: searchTemp= e.target.value;
          break;
        case 7: searchTemp= e.target.value;
          break;
        default:
          break;
      }
      this.setState({ search: searchTemp });
      if(this.state.opcion.value === 5 || this.state.opcion.value === 7 ){
        searchTemp= searchTemp+" 00:00:00";
      }
      let estatus= this.state.estatus.value > 0 ? this.state.estatus.value : 3;
      if( searchTemp ){
        this.getfuntion("get", [], "", "gps_por_recuperar/"+this.state.opcion.api+"/"+searchTemp+"/"+estatus+"/pagina/0/total/10000", "gps"); 
      }
    }
    getGps = (gpsT) => {
        this.getfuntion("get", gpsT, "", "gps/"+gpsT.idGps, "gpsRecuperar");
        this.setState({ gpsTemp: gpsT });
    }
    rastreame = (gps="") => {
        if( this.state.gpsTemp.idGps ){
          this.getfuntion("get", "", "", "navieraMovimientos/plataforma/"+this.state.gpsTemp.idPlataforma.idGg+"/gps/"+this.state.gpsTemp.idGps, "urlGps");
        }
    }
    cancelar =()=>this.setState({ gpsR: [] });
    render() {
        return (
                <div className="row">
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Estatus"><Select value={this.state.estatus} onChange={this.changeEstatus} options={this.estatus} /></Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Opción"><Select value={this.state.opcion} onChange={this.changeOpcion} options={this.opciones} /></Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                    { this.state.opcion.value === 1 ?
                        <Form.Group label="Busqueda"><Select value={this.state.corredor} onChange={this.changesearch} options={this.state.corredores} /></Form.Group>
                       : null
                      }
                      { this.state.opcion.value === 2 || this.state.opcion.value === 3 || this.state.opcion.value === 4 || this.state.opcion.value === 6 ?
                        <Form.Group label="Busqueda"><Form.Input className="mb-3" icon="search" value={ this.state.search } placeholder="Buscar..." position="append" onChange={this.changesearch} /> </Form.Group>
                       : null
                      }
                      { this.state.opcion.value === 5 || this.state.opcion.value === 7 ?
                      <Form.Group label="Busqueda">
                        <input type="date" id="fech" name="fecha" placeholder="AAAA-MM-DD" onChange={ this.changesearch }></input>
                      </Form.Group>
                       : null
                      }
                    </div>
                    <div className="col col-12 col-md-12" hidden={this.state.gpsR.idGg}>
                        <Card title="GPS">
                            <Card.Body>
                                <Table cards={true} striped={true} responsive={true} className="table-vcenter" 
                                        headerItems={[ { content: "ID" },{ content: "NOMBRE" },{ content: "PLACA REMOLQUE" },{ content: "TRANSPORTISTA" },
                                                        { content: "CONTENEDOR" },{ content: "PLATAFORMA" },{ content: "CORREDOR" },{ content: "DESTINO" },
                                                        { content: "CITA PROGRAMADA" }, { content: "FECHA COLOCADO" }, { content: "ESTATUS" }, { content: "RECUPERAR" } 
                                                    ] } >
                                
                                    <Table.Body>
                                        { this.state.gps.map((gpsT, index)=>
                                            <Table.Row>
                                            <Table.Col> {gpsT.idGps        }  </Table.Col>
                                            <Table.Col> {gpsT.nombre    }  </Table.Col>
                                            <Table.Col> {gpsT.remolquePlaca     }  </Table.Col>
                                            <Table.Col> {gpsT.idTransportista.nombreCorto     }  </Table.Col>
                                            <Table.Col> {gpsT.contenedor      }  </Table.Col>
                                            <Table.Col> {gpsT.idPlataforma.nombre     }  </Table.Col>
                                            <Table.Col> {gpsT.idCorredor.nombreCorto     }  </Table.Col>
                                            <Table.Col> {gpsT.destino     }  </Table.Col>
                                            <Table.Col> {gpsT.citaProgramada     }  </Table.Col>
                                            <Table.Col> {gpsT.colocacionFecha    }  </Table.Col>                           
                                            <Table.Col> {gpsT.idGpsEstatus    }  </Table.Col>
                                            <Table.Col> <Button color="primary" size="sm" onClick={() => {this.getGps(gpsT )}}>Recuperar</Button>  </Table.Col>
                                            </Table.Row>  
                                        )
                                        }  
                                                            
                                    </Table.Body>
                                </Table>
                            </Card.Body>
                        </Card> 
                    </div>

                    <div className="col col-12 col-md-12" hidden= { !this.state.gpsR.idGg }>
                        <Card>
                            <Card.Header>
                                <Card.Title>Detalles</Card.Title>
                                <Card.Options>
                                    { this.state.gpsR.idGg ?
                                        <div>
                                            <Button color="primary" size="sm" onClick={() => this.recuperarGPS() }>Confirmar</Button>
                                            <Button color="danger"  size="sm" onClick={() => this.cancelar() }>Cancelar</Button>
                                        </div>
                                    :null}
                                </Card.Options>
                            </Card.Header>
                            <Card.Body>
                                { this.state.gpsR.idGg ?
                                    <Table  cards={true} striped={true} responsive={true} className="table-vcenter" 
                                            headerItems={[ { content: "ID" },{ content: "NOMBRE" },{ content: "MARCA" },{ content: "Bateria" },{ content: "ULTIMO REPORTE" },
                                                           { content: "DIRECCIÓN" }, { content: "RASTREAME" } ] } >
                                    
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Col> {this.state.gpsR.id        }  </Table.Col>
                                                <Table.Col> {this.state.gpsR.nombre    }  </Table.Col>
                                                <Table.Col> {this.state.gpsR.marca     }  </Table.Col>
                                                <Table.Col> {this.state.gpsEstatus.nivel_bateria >= 0? this.state.gpsEstatus.nivel_bateria+" %": "" }  </Table.Col>
                                                <Table.Col> {this.state.gpsEstatus.ultimo_reporte ? this.state.gpsEstatus.ultimo_reporte: "" }  </Table.Col>
                                                <Table.Col> {this.state.gpsEstatus.direccion? "("+this.state.gpsEstatus.latitud+", "+this.state.gpsEstatus.longitud+"). "+this.state.gpsEstatus.direccion: "" }  </Table.Col>
                                                <Table.Col> <span className="badge" onClick={() => this.rastreame() } data-toggle="tooltip" data-placement="top" title="rastrear gps">
                                                              <label style={{ "color": "black", "font-size": "200%" }}><Icon name="map-pin" /></label>
                                                            </span>
                                                </Table.Col>
                                            </Table.Row>                    
                                        </Table.Body>
                                    </Table>
                                : <div>No se ha seleccionado ningun GPS</div>}
                            </Card.Body>
                            <Card.Footer></Card.Footer>
                        </Card> 
                    </div>
                </div>                   
                
        );
    }
}

export default RecuperarGpsForm;
