import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import { RecuperarPassword } from "../../seguridad";
const url = new URL(window.location.href);

function RecuperarPasswordPage() {
  return (
    <SiteWrapper>
      <Grid.Col sm={12} lg={12}> 
        <RecuperarPassword token={url.searchParams.get("token")}/>
      </Grid.Col>
    </SiteWrapper>
  );
}
export default RecuperarPasswordPage;
