import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import './excel.css';
import './modal.css'
import $ from 'jquery';
import { Crud_Catalogos, Crud_error } from '../Crud_Catalogos';
import { Dimmer } from "tabler-react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
var user = JSON.parse(sessionStorage.getItem("usuario"));
var     id	=0;var terrores=0;let sinError=0;var dia			=0;var mes			=0;var anio		=0;var hora 		=0;var minuto	=0;var segundo =0;
var fechas	=0;var horas		=0;var arrayErr=[];let Errores	=[];let FilaErr	=[];const fdatetime	= 'yyyy-mm-dd HH:mm:ss';
const fdate			= 'yyyy-mm-dd';const fhora			= 'HH:mm:ss';const nobis 		= [31,28,31,30,31,30,31,31,30,31,30,31];const sbiss 		= [31,29,31,30,31,30,31,31,30,31,30,31];
function myModalT(Muestra) {
  var x = document.getElementById("xlsModalT");
  x.style.display = Muestra;
}
class ImportarT extends Component
{
  constructor(props) {
    super(props);
    this.state = {
//Variables
                catalogo:this.props.catalogo                |"",
                  accion:this.props.accion,
                validado:this.props.validado                |false,
                    data:this.props.data										|[],

//Tabla Movimientos
                      id:this.props.id                      |0,
              cartaPorte:this.props.cartaPorte              |"",
              rutaInicio:this.props.rutaInicio              |"",
                  origen:this.props.origen                  |"",
                    nota:this.props.nota                    |"",
               deRegreso:this.props.deRegreso               |0,
                custodia:this.props.custodia                |0,
          custodiaNombre:this.props.custodiaNombre          |"",
               checklist:this.props.checklist               |0,
        ultimaIncidencia:this.props.ultimaIncidencia        |"",
          nombreOperador:this.props.nombreOperador          |"",

//Catalogos
              plataforma:this.props.plataforma              |[],
           transportista:this.props.transportista           |[],
                operador:this.props.operador                |[],
                corredor:this.props.corredor                |[],
        operacionEstatus:this.props.operacionEstatus        |[],
           operacionTipo:this.props.operacionTipo           |[],

    };
    this.cita=0;
  }
  componentDidMount(){
		myModalT("none");
  }
  componentWillMount(){
    switch (this.props.catalogo) {
      case "terrestreMovimientos":
        console.log("Carga de Catalogos dependientes en terrestreMovimientos");
        { this.cargaCorredor() };
        { this.cargaOperacionTipo() };
        { this.cargaOperacionEstatus() };
        break;
      default:
        console.log("Sin Catalogos Dependientes Definidos en: " + this.props.catalogo );

    }
  }

  //Inicio Tabla Movimientos
  cargaOperacionTipo() {
    Crud_Catalogos("operacionesTipo", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ operacionTipo:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" operacionesTipo "+" verifique el Nombre del Catalogo");}  });
  }
  cargaOperacionEstatus() {
    Crud_Catalogos("operacionesEstatus", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ operacionEstatus:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" operacionesEstatus "+" verifique el Nombre del Catalogo");}  });
  }
  cargaCorredor() {
    Crud_Catalogos("corredores", "", "get", "",[],"", "", "", [], "", "", "/tipoNombre/Terrestre").then((returnVal) => {
    this.setState({ corredor:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" corredores "+" verifique el Nombre del Catalogo");}  });
  }
  //Fin Tabla Movimientos

valObjeto(ObjValidar, j){
  Crud_Catalogos("validacionDatos/terrestre", "", "post", "", ObjValidar,"", "", "", [], "", "", "").then((returnVal) => {
    console.log("271 validacionDatos/terrestre");
    console.log(returnVal);
    this.state.data[j].plataforma    = returnVal.plataforma;
    this.state.data[j].transportista = returnVal.transportista;
    this.state.data[j].operador      = returnVal.operador;

    let tempplataforma=this.state.data[j].plataforma;
    let temptransportista=this.state.data[j].transportista;
    let tempoperador=this.state.data[j].operador;

    if ( tempplataforma.nombre === "" || tempplataforma.nombre === null || tempplataforma.nombre === undefined ){
        terrores=terrores+1;
        FilaErr[j]="Error en la Fila " + (j);
        arrayErr[terrores]="La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j);
        NotificationManager.warning("La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j) );
        Errores.push( <div>{"La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j) }</div> );
    }
    if ( temptransportista.id === null || temptransportista.id === 0)  {
      terrores=terrores+1;
      FilaErr[j]="Error en la Fila " + (j);
      arrayErr[terrores]="El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
      NotificationManager.warning("El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
      Errores.push( <div>{"El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
    }
    if ( temptransportista.nombre === "" || temptransportista.nombre === null ) {
      terrores=terrores+1;
      FilaErr[j]="Error en la Fila " + (j);
      arrayErr[terrores]="El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
      NotificationManager.warning("El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
      Errores.push( <div>{"El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
    }
    if ( tempoperador.id === null || tempoperador.id === 0)  {
      terrores=terrores+1;
      FilaErr[j]="Error en la Fila " + (j);
      arrayErr[terrores]="El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
      NotificationManager.warning("El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
      Errores.push( <div>{"El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
    }
    if ( tempoperador.nombre === "" || tempoperador.nombre === null ) {
      terrores=terrores+1;
      FilaErr[j]="Error en la Fila " + (j);
      arrayErr[terrores]="El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
      NotificationManager.warning("El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
      Errores.push( <div>{"El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
    }
  }).catch(err =>{
    //if( err.response ){
    console.log("567 Error de validacionDatos")
    console.log(err);
    }
  );
}

valCatalogoId(Catalogo, Id, I, Campo){
  var k=0;
  switch (Catalogo) {
    case "movimientoTipo":
    //  Campo.id=Id; //this.state.plataforma[I].id;
      this.state.data[I].movimientoTipo = Campo;
      break;
    case "plataforma":
      Campo.id=Id; //this.state.plataforma[I].id;
      this.state.data[I].plataforma = Campo;
      break;
    case "transportista":
      Campo.id=Id; //this.state.transportista[I].id;
      this.state.data[I].transportista = Campo;
      break;
    case "operador":
      Campo.id=Id; //this.state.operador[I].id;
      this.state.data[I].operador = Campo;
      break;

    case "operacionTipo":
      k=0;
      this.state.operacionTipo.map(ids => {
        if (this.state.operacionTipo[k].id===Id){
          Campo = ids;//this.state.operacionTipo[k];//Campo.id=Id; //this.state.operacionTipo[I].id;
        }
        k=k+1; });
      this.state.data[I].operacionTipo = Campo;
      break;
    case "operacionEstatus":
      k=0;
      Campo.id=1;
      Campo.nombre="SIN GPS";
      this.state.data[I].operacionEstatus = Campo;
      break;
    case "corredor":
      k=0;
      this.state.corredor.map(ids => {
      if (this.state.corredor[k].id===Id){
        //Campo = this.state.corredor[k];//Campo.id=Id; //this.state.corredor[I].id;
        Campo= ids;//Campo;
      } k=k+1; });
      this.state.data[I].corredor = Campo;
      break;
    default:
    console.log("No Existe el Catalogo Definido");
  }
}
getCatalogoId(Catalogo, Nombre, I, Campo){
  var k=0;
  switch (Catalogo) {
    case "movimientoTipo":
      //Campo.nombre = Nombre;
      this.state.data[I].movimientoTipo = Campo;
      break;
    case "plataforma":
      Campo.nombre = Nombre;
      this.state.data[I].plataforma = Campo;
      break;
    case "transportista":
      Campo.nombre = Nombre;
      this.state.data[I].transportista = Campo;
      break;
    case "operador":
      Campo.nombre = Nombre;
      this.state.data[I].operador = Campo;
      break;

    case "operacionTipo":
      k=0;
      this.state.operacionTipo.map(ids => {
        if (this.state.operacionTipo[k].nombre===Nombre){
          Campo = ids;//this.state.operacionTipo[k];//Campo.id=this.state.operacionTipo[k].id;
        }
        k=k+1; });
      this.state.data[I].operacionTipo = Campo;
      break;
    case "operacionEstatus":
      k=0;
      Campo.id=1;
      Campo.nombre="SIN GPS";
      this.state.data[I].operacionEstatus = Campo;
      break;
    case "corredor":
      k=0;
      this.state.corredor.map(ids => {
        if (this.state.corredor[k].nombre===Nombre){//console.log("this.state.corredor[I].id");//console.log(this.state.corredor[I].id);
          Campo=ids;//this.state.corredor[k];//Campo.id=this.state.corredor[k].id;//console.log("Campo.id Lin:165");//console.log(Campo.id);
        }
        k=k+1; });
      this.state.data[I].corredor = Campo;//console.log(Campo);
      break;
		case "po"://k=0;No hace Nada
      break;
    default:
    NotificationManager.warning("No Existe el Catalogo Definido");
  }
}

// Valida Archivo de Excel (lectura/Escritura) para su carga al componente
validaArchivo() {
    console.log("Inicia Validacion de Archivo Terrestre");
    switch (this.props.catalogo) {
      case 'terrestreMovimientos':
        var j = 0;
        this.state.data.map(datos =>{
          var plataforma = {"id":"", "nombre":"" }
          var corredor = {"id":"", "nombre":"" }
          var transportista = { "id":"", "nombre":"" }
          var operador = {"id":"", "nombre":"" }
          var objeto = { plataforma, transportista, operador };
          var movimientoTipo = { "id":1,"nombre":"Importación" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'plataforma':
                    if (Number.isInteger(datos[num])){
                      //plataforma.id=datos[num];
                      { this.valCatalogoId("plataforma", datos[num], j, plataforma) }
                      /*
                      if (this.state.data[j].plataforma.nombre===0 || this.state.data[j].plataforma.nombre===null ||this.state.data[j].plataforma.nombre===""){
                        terrores=terrores+1;
                        FilaErr[j]="Error en la Fila " + (j+1);
                        arrayErr[terrores]="La plataforma id:" + plataforma.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
                        Errores.push( <div>{"La plataforma id:" + plataforma.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }*/
                    }else{
                      plataforma.nombre=datos[num];
                      { this.getCatalogoId("plataforma", datos[num], j, plataforma) }
                      /*
                      if (this.state.data[j].plataforma.id===0 || this.state.data[j].plataforma.id===null ||this.state.data[j].plataforma.id===""){
                        terrores=terrores+1;
                        FilaErr[j]="Error en la Fila " + (j+1);
                        arrayErr[terrores]="La plataforma id:" + plataforma.nombre + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
                        Errores.push( <div>{"La plataforma id:" + plataforma.nombre + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }*/
                    }
                    break;
                  case 'transportista':
                    if (Number.isInteger(datos[num])){
                      { this.valCatalogoId("transportista", datos[num], j, transportista) }
                      /*
                      if (this.state.data[j].transportista.nombre===0 || this.state.data[j].transportista.nombre===null ||this.state.data[j].transportista.nombre===""){
                        terrores=terrores+1;
                        FilaErr[j]="Error en la Fila " + (j+1);
                        arrayErr[terrores]="La transportista id:" + transportista.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
                        Errores.push( <div>{"La transportista id:" + transportista.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }
                      */
                    }else{
                      transportista.nombre=datos[num];
                      { this.getCatalogoId("transportista", datos[num], j, transportista) }
                      /*
                      if (this.state.data[j].transportista.id===0 || this.state.data[j].transportista.id===null ||this.state.data[j].transportista.id===""){
                        terrores=terrores+1;
                        FilaErr[j]="Error en la Fila " + (j+1);
                        arrayErr[terrores]="La transportista id:" + transportista.nombre + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
                        Errores.push( <div>{"La transportista id:" + transportista.nombre + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }
                      */
                    }
                    break;
                  case 'operador':
                    if (Number.isInteger(datos[num])){
                      { this.valCatalogoId("operador", datos[num], j, operador) }
                      /*
                      if (this.state.data[j].operador.nombre===0 || this.state.data[j].operador.nombre===null ||this.state.data[j].operador.nombre===""){
                        terrores=terrores+1;
                        FilaErr[j]="Error en la Fila " + (j+1);
                        arrayErr[terrores]="La operador id:" + operador.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
                        Errores.push( <div>{"La operador id:" + operador.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }
                      */
                    }else{
                      operador.nombre=datos[num];
                      { this.getCatalogoId("operador", datos[num], j, operador) }
                      /*
                      if (this.state.data[j].operador.id===0 || this.state.data[j].operador.id===null ||this.state.data[j].operador.id===""){
                        terrores=terrores+1;
                        FilaErr[j]="Error en la Fila " + (j+1);
                        arrayErr[terrores]="La operador id:" + operador.nombre + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
                        Errores.push( <div>{"La operador id:" + operador.nombre + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }
                      */
                    }
                    break;

                  case 'movimientoTipo':
                    if (Number.isInteger(datos[num])){
                      { this.valCatalogoId("movimientoTipo", datos[num], j, movimientoTipo) }
                    }else{
                      plataforma.nombre=datos[num];
                      { this.getCatalogoId("movimientoTipo", datos[num], j, movimientoTipo) }
                    }
                    break;
                  case 'corredor':
                    if (Number.isInteger(datos[num])){
                      { this.valCatalogoId("corredor", datos[num], j, corredor) }
                      if (this.state.data[j].corredor.nombre===0 || this.state.data[j].corredor.nombre===null ||this.state.data[j].corredor.nombre===""){
                        terrores=terrores+1;
                        FilaErr[j]="Error en la Fila " + (j+1);
                        arrayErr[terrores]="La corredor id:" + corredor.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
                        Errores.push( <div>{"La corredor id:" + corredor.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }
                    }else{
                      corredor.nombre=datos[num];
                      { this.getCatalogoId("corredor", datos[num], j, corredor) }
                      if (this.state.data[j].corredor.id===0 || this.state.data[j].corredor.id===null || this.state.data[j].corredor.id===""){
                        terrores=terrores+1;
                        FilaErr[j]="Error en la Fila " + (j+1);
                        arrayErr[terrores]="La corredor id:" + corredor.nombre + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
                        Errores.push( <div>{"La corredor id:" + corredor.nombre + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }
                    }
                    break;
                  case 'operacionEstatus':
                    var operacionEstatus = {"id":1, "nombre":"SIN GPS" }
                    if (Number.isInteger(datos[num])){
                      { this.valCatalogoId("operacionEstatus", datos[num], j, operacionEstatus) }
                      if (operacionEstatus.id===0 || operacionEstatus.id===null || operacionEstatus.id===""){
                        terrores=terrores+1;
												FilaErr[j]="Error en la Fila " + (j+1);
												arrayErr[terrores]="La OperacionEstatus id:" + operacionEstatus.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
												Errores.push( <div>{"La OperacionEstatus id:" + operacionEstatus.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }
                    }else{
                      operacionEstatus.nombre=datos[num];
                      { this.getCatalogoId("operacionEstatus", datos[num], j, operacionEstatus) }
                      if (operacionEstatus.id===0 || operacionEstatus.id===null || operacionEstatus.id===""){
                        terrores=terrores+1;
												FilaErr[j]="Error en la Fila " + (j+1);
												arrayErr[terrores]="La OperacionEstatus !" + operacionEstatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
												Errores.push( <div>{"La OperacionEstatus !" + operacionEstatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
                      }
                    }
                    break;
                  case 'operacionTipo':
                    var operacionTipo = { "id":"", "nombre":""}
                    if (Number.isInteger(datos[num])){
                      { this.valCatalogoId("operacionTipo", datos[num], j, operacionTipo) }
                    }else{
                      operacionTipo.nombre=datos[num];
                      { this.getCatalogoId("operacionTipo", datos[num], j, operacionTipo) }
                    }
                    break;
                  default://console.log("Se queda Igual "+ j);
                  break;
                }//Fin Switch num
            }
          );
          this.valObjeto(objeto, j);

					//Validacion de Campos No Null
					var temporal="";
					//Validacion del Campo corredor
					temporal=this.state.data[j].corredor;
					if ((temporal==="")||(temporal===null)||(temporal===undefined)){
						console.log("No puede ir Vacío el Campo 'corredor'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="El corredor: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
						NotificationManager.warning("El corredor: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1) );
						Errores.push( <div>{"El corredor: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1) }</div> );
					}
					//Fin Validacion del Campo corredor

					//Validacion del Campo operacionEstatus
					temporal=this.state.data[j].operacionEstatus;
					if ((temporal==="")||(temporal===null)||(temporal===undefined)){
						console.log("No puede ir Vacío el Campo 'operacionEstatus'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La OperacionEstatus: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
						NotificationManager.warning("El operacionEstatus: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1) );
						Errores.push( <div>{"La OperacionEstatus: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1) }</div> );
					}
					//Fin Validacion del Campo operacionEstatus

					//Validacion del Campo operacionTipo
					temporal=this.state.data[j].operacionTipo;
					if ((temporal==="")||(temporal===null)||(temporal===undefined)){
						console.log("No puede ir Vacío el Campo 'operacionTipo'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La OperacionTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
						NotificationManager.warning("El operacionTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1) );
						Errores.push( <div>{"La OperacionTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1) }</div> );
					}
					//Fin Validacion del Campo operacionTipo

          //Validacion del Campo corredor
					temporal=this.state.data[j].movimientoTipo;
					if ((temporal==="")||(temporal===null)||(temporal===undefined)){
						console.log("No puede ir Vacío el Campo 'movimientoTipo'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="El movimientoTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
						NotificationManager.warning("El movimientoTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1) );
						Errores.push( <div>{"El movimientoTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1) }</div> );
					}
					//Fin Validacion del Campo corredor

          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Archivo Validado!"}</div> );
        //Errores.push( <div id={j}>{this.state.validado?"Validación Finalizada":terrores}</div> );
				break;//Fin Case Movimientos 608
      default:
				NotificationManager.warning="No esta Defina una Carga Masiva para este Catalogo";
        break;
    }
    console.log("572 this.state.data");
    console.log(this.state.data);
    this.setState({validado:true });//document.getElementById("cargar").disabled=true;
		console.log("Fin Validacion de Archivo: "+ terrores);
  }
cargarDatos = (carga, errores) => {
		console.log("Presiono Cargar Datos Correctos e:" + errores);
    console.log("550 carga");
    console.log(carga);
    let w=0;
    let errResponse=[];
    for (let i = 0; i < carga.length; i++) {
      if (errores==0){
        console.log("556 carga[i]");
        console.log(carga[i]);
        Crud_Catalogos(this.props.catalogo, ""           , "post", "", carga[i],"" , "",     "", []).then((returnVal) => {
          console.log("559 returnVal");
          console.log(returnVal);
          if(i === carga.length-1){
            this.props.crud("get", [], "", [], "terrestreMovimientos/usuario/" + user.id);
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModalT("none");
          }
        }).catch(err =>{
          if(i === carga.length-1){
            this.props.crud("get", [], "", [], "terrestreMovimientos/usuario/" + user.id);
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModalT("none");
          }
          errResponse.push({datos: carga[i]});
          if( err.response ){ console.log(err);}else{console.log("384 Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}
        });
      }else{
        if ( (FilaErr[i]!="") || (FilaErr[i]!=null) || (FilaErr[i]!=undefined) ){
          console.log("No se Ejecuto la Carga de la Fila:" + i);
        }else{
          Crud_Catalogos(this.props.catalogo, ""           , "post", "", carga[i],"" , "",     "", []).then((returnVal) => {
          if(i === carga.length-1){
            this.props.crud("get", [], "", [], "terrestreMovimientos/usuario/" + user.id);
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModalT("none");
          }
          }).catch(err =>{
            if(i === carga.length-1){
              this.props.crud("get", [], "", [], "terrestreMovimientos/usuario/" + user.id);
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModalT("none");
          }
            errResponse.push({datos: carga[i]});
            if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}
          });
        }
      }
    }
	}
onImportExcel = file => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            console.log("sheet: " + sheet);
            this.setState({catalogo: sheet });//this.props.data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
          }
        }
        console.log('Carga de Archivo Exitosa！');
        id=0;
        terrores=0;
				sinError=0;
        { this.validaArchivo() }
      } catch (e) {
        console.log("Error! "+e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
		myModalT("block");//document.getElementById("cargar").disabled=false;
    document.getElementById("cerrar").focus();
}

render() {
    return (
      <div className="excelT" id="excelT">
        <p className="etiquetaT" id="etiquetaT"><a href="#miModalT">Imp</a></p>
        <input type='file' id="file" className="file" accept='.xlsx, .xls' onChange={this.onImportExcel} />
				<div id="xlsModalT" className="xlsModalT">
					<div className="modal-contenidoT">
					<center><h2>Confirme su Carga</h2></center>
					<div className="container-200">
						<div className="scroll-col col-sm-12">
						{
              this.state.validado===false ? <div><br></br>Verificando Errores ..!! <Dimmer active={true} loader></Dimmer> por favor Espere!</div>:
              terrores==0? <div>Archivo Correcto</div>:Errores
						}
						</div>
					</div><br></br>
					<center>
						<button id="cerrar" className="azul" onClick={()=>myModalT('none')} >Cerrar y Corregir Archivo de Excel</button>
						<spam></spam>
						<button id="cargar" className="azul" onClick={()=>this.cargarDatos(this.state.data, terrores)} >Cargar Datos Correctos</button>
					</center>
					</div>
				</div>
      </div>
    );
  }
}
export default ImportarT;
