import React, { useState, useEffect } from 'react';
import Column from './Column';
import ReactDOM from 'react-dom';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { styled } from '../../stiches.config';
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';

const StyledColumns = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  margin: '1vh 7vh 7vh 15vh',
  width: '80%',
  height: '60vh',
  gap: '15px',
  overflowY: "scroll"
});

const getfuntion = (metodo = "put", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
  Crud_Catalogos(catalogo, "dfgfdgsdfg", metodo, id, obj, "", stateVar, hiddenModl, [])
}


const Dnds = ({ col: columnas, tablas, cat }) => {
  const [columns, setColumns] = useState({});
  useEffect(()=>{
    if(columnas)
    setColumns({...columnas});
  },[columnas]);
  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null
    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null

    // Set start and end variables
    
    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter(function(item){
        return item.id !== source.index;
      });
      // Then insert the item at the right location
      let obj = start.list.find(o => o.id === source.index);
      newList.splice(destination.index, 0, obj)

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList
      }

      // Update the state
      setColumns(state => ({ ...state, [newCol.id]: newCol }))
      return null
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter(function(item){
        return item.id !== source.index;
      });

      // Create a new start column
      const newStartCol = {
        id: start.id,
        list: newStartList
      }
      let LoteArr = [];
      for (let index = 0; index < tablas.length; index++) {
        if( tablas[index][0] == start.id && tablas[index][2] == true ){
          newStartCol.list.forEach(item => {
            LoteArr.push({[cat.id1]: tablas[index][4], [cat.id2]: item.id})
          });
          getfuntion('post',LoteArr,"", tablas[index][1], tablas[index][4], "");
        } 
      }
      // Make a new end list array
      const newEndList = end.list
      // Insert the item into the end list
      let obj = start.list.find(o => o.id === source.index);
      newEndList.splice(destination.index, 0, obj )
      // Create a new end column
      const newEndCol = {
        id: end.id,
        list: newEndList
      }
  
      for (let index = 0; index < tablas.length; index++) {
        if( tablas[index][0] == end.id && tablas[index][2] == true ){
          newEndCol.list.forEach(item => {
           LoteArr.push({[cat.id1]: tablas[index][4], [cat.id2]: item.id})
          });
          getfuntion('post',LoteArr,"", tablas[index][1], tablas[index][4], "");
        } 
      }
      // Update the state
      setColumns(state => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol
      }))
      return null
    }
  }

  const listarColumnas = Object.values(columns).map(column => 
    <Column col={column} key={column.id}/>
    )
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StyledColumns>
          {listarColumnas}
      </StyledColumns>
    </DragDropContext>
  );
}

export default React.memo(Dnds);