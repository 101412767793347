import React, { Component } from "react";
import { List, Button, Icon, Card, Table, Grid, Form } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import DateTimePicker from "react-datetime-picker";
import { timeToDateFill } from "../../../../herramientas/DateFormat";
var user = JSON.parse(sessionStorage.getItem("usuario"));
//Formato de Fecha
function pad(number) {
  if (number < 10) { return '0' + number; }
  return number;
}
function myFormato(date) {
  console.log(date);
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}

class Reaccion extends Component {
  constructor(props){
    super(props);
    this.state = {
      reaccionMovimiento: {},
      idPlataforma: 0,
      reaccionObject: [],
      longitud: "",
      supervisorRes: "",
      reporte: "",
      reporteUrl: "",
      latitud: "",
      lineaTransporte: "",
      numContenedor: "",
      tipoCaja: "",
      nombreCliente: "",
      noContenedores: "",
      colorContenedor: "",
      cargadoVacio: 0,
      placaRemolque: "",
      economico: "",
      placaTracto: "",
      marcaTracto: "",
      modeloTracto: "",
      colorTracto: "",
      nombreOperador: "",
      telefonoOperador: "",
      clavenoAmago: "",
      direcOrigen: "",
      direcDestino: "",
      correPista: "",
      kilometro: "",
      sentidoUnidad: "",
      citaCliente: "",
      nombrecoordinador: "",
      telefonoCoordinador: "",
      noSerie: "",
      noMotor: "",
      observaciones: "",
    };
    this.usuariosLogObject = {
      "idBitacoraMonitoreo": 7,
      "idUsuario": 0,
      "idMovimiento": 0,
      "accionFecha": "",
      "descripcion": "",
      "idPlataformaTipo":""
    };
    this.reaccionObject = {
      "idMovimiento": 0,
      "idPlataforma": 0,
      "supervisor": "",
      "reporte": "",
      "reporte": "",
      "reporteUrl": "",
      "latitud": "",
      "longitud": "",
      "transportista": "",
      "contenedor": "",
      "clienteNombre": "",
      "cajaTipo": "",
      "contenedorTotal": "",
      "contenedorColor": "",
      "contenedorCargado": 0,
      "remolquePlaca": "",
      "economico": "",
      "tractoPlaca": "",
      "tractoMarca": "",
      "tractoModelo": "",
      "tractoColor": "",
      "operadorNombre": "",
      "operadorTelefono": "",
      "claveNoAmago": "",
      "origen": "",
      "destino": "",
      "citaProgramada": "",
      "coordinadorNombre": "",
      "coordinadorTelefono": "",
      "serieNumero": "",
      "motorNumero": "",
      "observaciones": "",
      "registroFecha": ""
      };

  }


  componentDidMount =() => {
     //console.log(this.props.getMovimiento.idPlataforma.id +"Construyendo Formulario de naviera");
     //console.log(this.props.getMovimiento.plataforma.id +"Construyendo Formulario de terrestre");
     this.setState({citaCliente    : this.state.citaCliente ?     new Date(this.state.citaCliente)    :  "" });
    if(this.props.plataformaTipo == "Naviera"){
      this.setState({idPlataforma: this.props.getMovimiento.idPlataforma.id});
      setTimeout(() => {
        this.getReaccionMovi();

        }, 1000);
    } else if(this.props.plataformaTipo == "Terrestre"){
      this.setState({idPlataforma: this.props.getMovimiento.plataforma.id});
      setTimeout(() => {
        this.getReaccionMoviTerrestre();

        }, 1000);
    }

  }

  getReaccionMovi = () => {
    Crud_Catalogos("reaccionInfoMovimientos/"+this.props.movimiento, "reaccion", "get", "", [], "", this.state.reaccionMovimiento, "", []).then((returnVal) => {
      this.reaccionMovimiento = returnVal;
        this.getfuntion("get", [], "", "gpsestatus/plataforma/"+this.props.getMovimiento.idPlataforma.idGg+"/gps/" + returnVal.idGps, "gpsestatus");
      this.setState({ reaccionMovimiento: returnVal });
      this.setState({ nombreCliente: returnVal.contactoCliente });
      this.setState({ numContenedor: returnVal.contenedor });
      this.setState({ citaCliente: returnVal.horaCita });
      this.setState({ lineaTransporte: returnVal.lineaTransportista });
      this.setState({ noContenedores: returnVal.noContenedores });
      this.setState({ nombreOperador: returnVal.operadorNombre });
      this.setState({ telefonoOperador: returnVal.operadorContacto });
      this.setState({ placaRemolque: returnVal.remolquePlaca });
      this.setState({ tipoCaja: returnVal.tipoCaja });
      this.setState({ colorTracto: returnVal.tractorColor });
      this.setState({ marcaTracto: returnVal.tractorMarca });
      this.setState({ modeloTracto: returnVal.tractorModelo });
      this.setState({ placaTracto: returnVal.tractorPlaca });
    }).catch(err => {
      if (err.response) {
        console.log(err);
      } else {
        /**
         * Error
         */
      }
    })
  }

  getReaccionMoviTerrestre = () => {
    Crud_Catalogos("reaccionInfoMovimientosTerrestres/"+this.props.movimiento, "reaccion", "get", "", [], "", this.state.reaccionMovimiento, "", []).then((returnVal) => {
      this.reaccionMovimiento = returnVal;
      this.setState({ reaccionMovimiento: returnVal.supervisor });

    }).catch(err => {
      if (err.response) {
        console.log(err);
      } else {
        /**
         * Error
         */
      }
    })
  }



  agregarReaccion = () => {
    this.reaccionObject.idMovimiento = this.props.movimiento;
    //PLATAFORMA
    this.reaccionObject.idPlataforma = this.state.idPlataforma;
    //this.reaccionObject.longitud = this.state.longitud;
    this.reaccionObject.supervisor = this.state.supervisorRes;
    //REPORTE
    this.reaccionObject.reporte = this.state.reporte;
    this.reaccionObject.reporteUrl = this.state.reporteUrl;
    this.reaccionObject.latitud = this.state.latitud;
    this.reaccionObject.longitud = this.state.longitud;

    this.reaccionObject.transportista = this.state.lineaTransporte;
    this.reaccionObject.contenedor = this.state.numContenedor;
    this.reaccionObject.clienteNombre = this.state.nombreCliente;
    this.reaccionObject.cajaTipo = this.state.tipoCaja;
    this.reaccionObject.contenedorTotal = this.state.noContenedores;
    this.reaccionObject.contenedorColor = this.state.colorContenedor;
    this.reaccionObject.contenedorCargado = this.state.cargadoVacio;
    this.reaccionObject.contenedorTotal = this.state.noContenedores;
    this.reaccionObject.contenedorColor = this.state.colorContenedor;
    this.reaccionObject.contenedorCargado = this.state.cargadoVacio;
    this.reaccionObject.remolquePlaca = this.state.placaRemolque;
    this.reaccionObject.economico = this.state.economico;
    this.reaccionObject.tractoPlaca = this.state.placaTracto;
    this.reaccionObject.tractoMarca = this.state.marcaTracto;
    this.reaccionObject.tractoModelo = this.state.modeloTracto;
    this.reaccionObject.tractoColor = this.state.colorTracto;
    this.reaccionObject.operadorNombre = this.state.nombreOperador;
    this.reaccionObject.operadorTelefono = this.state.telefonoOperador;
    this.reaccionObject.claveNoAmago = this.state.clavenoAmago;
    this.reaccionObject.origen = this.state.direcOrigen;
    this.reaccionObject.destino = this.state.direcDestino;
    this.reaccionObject.citaProgramada = this.state.citaCliente;
    this.reaccionObject.coordinadorNombre = this.state.nombrecoordinador;
    this.reaccionObject.coordinadorTelefono = this.state.telefonoCoordinador;
    this.reaccionObject.serieNumero = this.state.noSerie;
    this.reaccionObject.motorNumero = this.state.noMotor;
    this.reaccionObject.observaciones = this.state.observaciones;

/*
    this.reaccionObject.reporte = this.state.reporte;
    this.reaccionObject.reporteUrl = this.state.reporteUrl;
    this.reaccionObject.latitud = this.state.latitud;

    this.reaccionObject.pista = this.state.correPista;
    this.reaccionObject.kilometro = this.state.kilometro;
    this.reaccionObject.sentido = this.state.sentidoUnidad;*/

    //Crud_Catalogos("reaccion", "reaccion", "post", "", this.reaccionObject, "", "", this.props.hiddenmodal, "").then((returnVal) => {});
    this.getfuntion("post", this.reaccionObject, "", "reaccion", "Reaccion");

    this.usuariosLogObject.idBitacoraMonitoreo = 7;
    this.usuariosLogObject.idUsuario = user.id;
    this.usuariosLogObject.idMovimiento = this.reaccionObject.idMovimiento;
    this.usuariosLogObject.idPlataformaTipo = this.props.plataformaTipo == "Naviera"?1:2;
    this.usuariosLogObject.accionFecha = myFormato(new Date());
    this.usuariosLogObject.descripcion = "Reacción Enviada en Movimiento id: " + this.reaccionObject.idMovimiento + " Data: " + JSON.stringify(this.reaccionObject);
    Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "gpsestatus") {
              this.setState({ latitud:  returnVal.latitud });
              this.setState({ longitud: returnVal.longitud });
            }
          }
        case "post":
          {
            if (stateVar === "Reaccion") { this.props.hiddenmodal(); }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response);  } else {console.log("Error desconocido ...." + err.response); }
    })
  }

  changelongitud = (event) => this.setState({longitud: event.target.value});
  changesupervisorRes = (event) => this.setState({supervisorRes: event.target.value});
  changereporte = (event) => this.setState({reporte: event.target.value});
  changereporteUrl = (event) => this.setState({reporteUrl: event.target.value});
  changelatitud = (event) => this.setState({latitud: event.target.value});
  changelineaTransporte = (event) => this.setState({lineaTransporte: event.target.value});
  changenumContenedor = (event) => this.setState({numContenedor: event.target.value});
  changetipoCaja = (event) => this.setState({tipoCaja: event.target.value});
  changenombreCliente = (event) => this.setState({nombreCliente: event.target.value});
  changenoContenedores = (event) => this.setState({noContenedores: event.target.value});
  changecolorContenedor = (event) => this.setState({colorContenedor: event.target.value});
  changecargadoVacio = (event) => this.setState({cargadoVacio: event.target.checked? 1:0});
  changeplacaRemolque = (event) => this.setState({placaRemolque: event.target.value});
  change = (event) => this.setState({economico: event.target.value});
  changeplacaTracto = (event) => this.setState({placaTracto: event.target.value});
  changemarcaTracto = (event) => this.setState({marcaTracto: event.target.value});
  changemodeloTracto = (event) => this.setState({modeloTracto: event.target.value});
  changecolorTracto = (event) => this.setState({colorTracto: event.target.value});
  changenombreOperador = (event) => this.setState({nombreOperador: event.target.value});
  changetelefonoOperador = (event) => this.setState({telefonoOperador: event.target.value});
  changeclavenoAmago = (event) => this.setState({clavenoAmago: event.target.value});
  changedirecOrigen = (event) => this.setState({direcOrigen: event.target.value});
  changedirecDestino = (event) => this.setState({direcDestino: event.target.value});
  changecorrePista = (event) => this.setState({correPista: event.target.value});
  changekilometro = (event) => this.setState({kilometro: event.target.value});
  changesentidoUnidad = (event) => this.setState({sentidoUnidad: event.target.value});
  //changecitaCliente = (event) => this.setState({citaCliente: event.target.value});

  changecitaCliente = (date)  =>{ timeToDateFill(new Date(date).getTime()); this.setState({ citaCliente: date }); }
  changenombrecoordinador = (event) => this.setState({nombrecoordinador: event.target.value});
  changetelefonoCoordinador = (event) => this.setState({telefonoCoordinador: event.target.value});
  changenoSerie = (event) => this.setState({noSerie: event.target.value});
  changenoMotor = (event) => this.setState({noMotor: event.target.value});
  changeobservaciones = (event) => this.setState({observaciones: event.target.value});
  render() {
    return (

      <div>
        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Supervisor Responsable">
              <Form.Input name="supervisorRes"  type="text" onChange={this.changesupervisorRes} />
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Reporte">
              <Form.Input name="reporte"  type="text"  onChange={this.changereporte}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Reporte Url">
              <Form.Input name="reporteUrl"  type="text" onChange={this.changereporteUrl}/>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Latitud">
              <Form.Input name="latitud"  type="text" value={this.state.latitud} onChange={this.changelatitud}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Longitud">
              <Form.Input name="longitud"  type="text" value={this.state.longitud} onChange={this.changelongitud}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Línea de Transporte">
              <Form.Input name="lineaTransporte"  type="text" value={this.state.lineaTransporte } onChange={this.changelineaTransporte}/>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Número de contenedor">
              <Form.Input name="numContenedor"  type="text" value={this.state.numContenedor} onChange={this.changenumContenedor}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Tipo de caja">
              <Form.Input name="tipoCaja"  type="text"  value={this.state.tipoCaja} onChange={this.changetipoCaja}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Nombre del Cliente">
              <Form.Input name="nombreCliente"  type="text" value={this.state.nombreCliente} onChange={this.changenombreCliente}/>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="No. de Contenedores">
              <Form.Input name="noContenedores"  type="text" value={ this.state.noContenedores } onChange={this.changenoContenedores}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Color de contenedor">
              <Form.Input name="colorContenedor"  type="text" value={this.state.reaccionMovimiento.contenedorColor} onChange={this.changecolorContenedor}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Cargado o Vacío">
            <Form.Switch type="Checkbox" onChange={this.changecargadoVacio} />
            <label>{this.state.cargadoVacio? "Cargado": "Vacio" }</label>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Placa del Remolque">
              <Form.Input name="placaRemolque"  type="text" value={this.state.placaRemolque} onChange={this.changeplacaRemolque}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Ecónomico">
              <Form.Input name=""  type="text" onChange={this.change}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Placa Tracto">
              <Form.Input name="placaTracto"  type="text"  value={this.state.placaTracto} onChange={this.changeplacaTracto}/>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Marca Tracto">
              <Form.Input name="marcaTracto"  type="text" value={this.state.marcaTracto} onChange={this.changemarcaTracto}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Modelo Tracto">
              <Form.Input name="modeloTracto"  type="text" value={this.state.modeloTracto} onChange={this.changemodeloTracto}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Color Tracto">
              <Form.Input name="colorTracto"  type="text"  value={this.state.colorTracto} onChange={this.changecolorTracto}/>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Nombre del Operador">
              <Form.Input name="nombreOperador"   value={ this.state.nombreOperador } type="text" onChange={this.changenombreOperador}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Teléfono operdador">
              <Form.Input name="telefonoOperador"  type="text" value={ this.state.telefonoOperador } onChange={this.changetelefonoOperador}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Clave de no amago">
              <Form.Input name="clavenoAmago"  type="text"  onChange={this.changeclavenoAmago}/>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Dirección Origen">
              <Form.Input name="direcOrigen"  type="text" onChange={this.changedirecOrigen}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Dirección Destino">
              <Form.Input name="direcDestino"  type="text" onChange={this.changedirecDestino}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Hora cita cliente">
              <DateTimePicker format="y-MM-dd h:mm:ss a" value={this.state.citaCliente}  onChange={this.changecitaCliente}></DateTimePicker>
            </Form.Group>
          </Grid.Col>

        </Grid.Row>


        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Nombre del coordinador">
              <Form.Input name="nombrecoordinador"  type="text" onChange={this.changenombrecoordinador}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Teléfono del coordinador">
              <Form.Input name="telefonoCoordinador"  type="text" onChange={this.changetelefonoCoordinador}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="No. de serie">
              <Form.Input name="noSerie"  type="text"  onChange={this.changenoSerie}/>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="No. de motor">
              <Form.Input name="noMotor"  type="text" onChange={this.changenoMotor}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Observaciones">
              <Form.Input name="observaciones"  type="text" onChange={this.changeobservaciones}/>
            </Form.Group>
          </Grid.Col>


        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={12}>
            <Form.Group >
              <Button.List align="right">
                  <Button onClick={() => {this.agregarReaccion()}} color="primary">Guardar</Button>
              </Button.List>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>


      </div>
    );
  }
}
export default Reaccion;
