import React, { Component } from "react";
import { List } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
class Transportista extends Component {
  constructor(props){
    super(props);
    this.state = {
      incidencias: [],
      movPatio: null,
      hiddenModals: true,
      unidad: "",
      operador: "",
      observaciones: "",
      plataformaTipo: 0
    };
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "", enviar) => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "movPatio" && returnVal){ //navieras
              this.setState({movPatio:returnVal});
              this.setState({ unidad: returnVal.tractorMarca+" "+returnVal.tractorColor+" "+returnVal.tractorModelo+" "+returnVal.tractorPlaca });
              this.setState({ operador: returnVal.operadorNombre+", contacto: "+returnVal.operadorContacto });
              this.setState({ observaciones: returnVal.observaciones });
            }
            if (stateVar === "operador" && returnVal){ //terrestre y terrestre naviera
              this.setState({ operador: returnVal.nombre+" "+returnVal.paterno+" "+returnVal.materno  +", contacto: "+returnVal.medioComunicacion });
            }
            if (stateVar === "unidadTerNav" && returnVal){ //terrestre naviera
              this.setState({ unidad: returnVal.idUnidadMarca.nombre+" "+returnVal.color+" "+returnVal.modelo+" "+returnVal.placa });
            }
          }
          break;

        default:
          break;
      }
    }).catch(err => { console.log(err);  });
  }
  componentDidMount =() => {
    if(this.props.mov.idPlataforma){
      this.setState({ plataformaTipo: this.props.mov.idPlataforma.plataformaTipo.id });
      if(this.props.mov.idPlataforma.plataformaTipo.id === 1 ){
        console.log(this.props.mov );
        this.getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+this.props.mov.id, "movPatio");
      }
      if(this.props.mov.idPlataforma.plataformaTipo.id === 3 ){
        this.getfuntion("get","","","unidadTerNav/idMovimiento/"+this.props.mov.id, "unidadTerNav");
        this.getfuntion("get","","","operadores/idmovimiento_nav/"+this.props.mov.id, "operador");
      }
    }
  }
  render() {
    return (

      <div className= "mt-1">
        <List.Group>
          <List.GroupItem action icon="truck"> Transportista: {this.props.obj? this.props.obj.nombre:null } </List.GroupItem>
          <List.GroupItem action icon="calendar">Contacto: {this.props.obj? this.props.obj.contactoNombre:null }</List.GroupItem>
          <List.GroupItem action icon="phone"> Telefono: {this.props.obj? this.props.obj.telefono1:null } </List.GroupItem>
          <List.GroupItem action icon="phone"> Telefono: {this.props.obj? this.props.obj.telefono2:null } </List.GroupItem>
          <List.GroupItem action icon="mail"> Email: {this.props.obj? this.props.obj.correo:null }</List.GroupItem>
          { this.state.plataformaTipo === 1 || this.state.plataformaTipo === 3 ?
            <List.Group>
              <List.GroupItem action icon="mail"> Unidad:         { this.state.unidad } </List.GroupItem>
              <List.GroupItem action icon="mail"> Operador:       { this.state.operador }</List.GroupItem>
            </List.Group>: null
          }
          { this.state.plataformaTipo === 1 ?
            <List.Group>
              <List.GroupItem action icon="mail"> Observaciones:  { this.state.observaciones }</List.GroupItem>
            </List.Group>: null
          }
          {/** Terrestre */}
        </List.Group>
      </div>

    );
  }
}
export default Transportista;
