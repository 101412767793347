import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";

class  OperacionesClientesForm extends Component {
  constructor(props) {
    super(props);
    this.state = { id: "",
                   nombre: "",
              nombreCorto: "",
                  latitud: "",
                 longitud: "",
                  estatus: 0,
              tipoEntrega: 0,
                   accion: this.props.accion, //'nuevo'// modificar, nuevo
                   disabledSave: true
                  };
                  this.enableSave = this.enableSave.bind(this);
  }
  componentDidMount =() => {
    if(this.state.accion === 'nuevo'){
    }else{
      this.setState({id: this.props.elementos.id});
      this.setState({nombre: this.props.elementos.nombre});
      this.setState({nombreCorto: this.props.elementos.nombreCorto});
      this.setState({latitud: this.props.elementos.latitud});
      this.setState({longitud: this.props.elementos.longitud});
      this.setState({estatus: this.props.elementos.estatus});
      this.setState({tipoEntrega: this.props.elementos.tipoEntrega});
    }
  }

  enableSave =() =>{
    console.log("Enable button: "+this.state.nombre)
    if(this.state.nombre.length > 0 ){
      return false;
    }else{ return true }
  }

  changeName =(event) => this.setState({nombre: event.target.value});
  changeNombreCorto =(event) => this.setState({nombreCorto: event.target.value});
  changeLatitud =(event) => this.setState({latitud: event.target.value});
  changeLongitud =(event) => this.setState({longitud: event.target.value});
  changeEstatus =(event) => this.setState({estatus: event.target.value});
  changeTipoEntrega =(event) => this.setState({tipoEntrega: event.target.value});

  delete()   { this.props.getfuntion("delete", "", this.state.id); }
  crud_put() { this.props.getfuntion("put", { "id":this.state.id, "nombre":this.state.nombre, "nombreCorto":this.state.nombreCorto, "latitud":this.state.latitud, "longitud":this.state.longitud, "estatus":this.state.estatus, "tipoEntrega":this.state.tipoEntrega } ); }
  crud_post(){ this.props.getfuntion("post",{ "id":this.state.id, "nombre":this.state.nombre, "nombreCorto":this.state.nombreCorto, "latitud":this.state.latitud, "longitud":this.state.longitud, "estatus":this.state.estatus, "tipoEntrega":this.state.tipoEntrega } ); }
  render(){

    return (
      <div>
        { this.state.accion === "modificar" ? <h1> { this.props.elementos.nombre } </h1> : null }
      <Grid.Row>
        <Grid.Col md={12} xl={12}>
          <Grid.Row>
            <Grid.Col md={3} lg={3}>
              <Form.Group label="id">
                <Form.Input name="id" disabled type="number" value={this.state.id} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={9} lg={9}>
              <Form.Group label="Nombre Corto">
                <Form.Input name="nombreCorto" type="text" value={ this.state.nombreCorto } onChange={this.changeNombreCorto} maxlength="30" />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={12} lg={12}>
              <Form.Group label="Nombre">
                <Form.Input name="nombre" type="text" value={ this.state.nombre } onChange={this.changeName} maxlength="90" />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col md={6} lg={6}>
              <Form.Group label="Latitud">
                <Form.Input name="latitud" type="text" value={ this.state.latitud } onChange={this.changeLatitud} maxlength="20" />
              </Form.Group>
          </Grid.Col>

            <Grid.Col md={6} lg={6}>
              <Form.Group label="Longitud">
                <Form.Input name="longitud" type="text" value={ this.state.longitud } onChange={this.changeLongitud} maxlength="20" />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} lg={6}>
             <Form.Group label="Estatus">
                <Form.Switch type="Checkbox" name="toggle" value={this.state.estatus} checked={this.state.estatus} onChange={this.changeEstatus} />
             </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} lg={6}>
             <Form.Group label="Tipo Entrega">
                <Form.Switch type="Checkbox" name="toggle" value={this.state.tipoEntrega} checked={this.state.tipoEntrega} onChange={this.changeTipoEntrega} />
             </Form.Group>
            </Grid.Col>
          </Grid.Row>

        </Grid.Col>
      </Grid.Row>

        <div className="float-right">
          <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary"
               disabled= { this.enableSave() }
                onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() }
              >
                <span className="badge"><Icon link={true} name="save" />Guardar</span>
              </Button>
          </span>
          { this.state.accion === "modificar" ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary"
                onClick= { () => this.delete() }
              >
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
          :
          null
          }
        </div>
      </div>
    );
  }
}
export default OperacionesClientesForm;
