import React, { useState, useEffect } from 'react';
import { Doughnut, Bar, Polar, Pie } from 'react-chartjs-2';
import SiteWrapper from "../../SiteWrapper.react";
import { Grid, Button, Icon, Form, Table } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import Select from "react-select";


const HistoricoKPIPage = () => {

  const [resultado_api, setResultadoApi] = useState([]);
  const [chartdata, setCharData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        backgroundColor: [],
        data: [],
      },
    ],
  });
  const [cv, setCV] = useState({ value: 0, label: "Todos" });
  const [tipoEvento, setTipoEvento] = useState({ value: 0, label: "Todos" });
  const [cvInp, setCvInp] = useState("");
  const [reset, setReset] = useState(0);

  const [listaCV, setListaCV] = useState([{ value: 0, label: "Todos" }])
  const [listaTiposEvento, setListaTiposEvento] = useState([{ value: 0, label: "Todos" }]);


  let cvFilt = "Todos";
  let tipoEventoFilt = "Todos";


  useEffect(() => {
    iniciaVariable();
  }, reset

  );

  function iniciaVariable(){
    let fechaHoy = new Date();
    let fechaPrev = new Date();
    fechaPrev.setDate(fechaHoy.getDate() - 7);
    fechaHoy.setDate(fechaHoy.getDate() + 1);
    let strHoy = fechaHoy.toISOString().split('T')[0];
    let strPrev = fechaPrev.toISOString().split('T')[0];

    crud("get", "", "", "logEventosKpi/fecha1/" + strPrev + "/fecha2/" + strHoy + "/", "eventosKPI");
    
  }


  return (
    <SiteWrapper>

      {/* <div className="container mt-3" style={{ width: 600 }} >
        <Pie
          data={chartdata.datasets ?
            chartdata.datasets.data ?
              chartdata.datasets.data.length ?
                chartdata.filter(r => estatus.value ? r.estatus === estatus.label : true)
                : chartdata : chartdata : chartdata
          }
          options={{
            responsive: true,
            legend: { display: true, position: "left" },
            datalabels: {
              display: true,
              color: "white",
            },
            tooltips: {
              backgroundColor: "#5a6e7f",
            },
          }}
        /> 
      </div> */}

      <div className="container mt-3">
        <h2 ><div>Archivos de eventos enviados a OTM</div></h2>
        <Grid.Row>
          <Grid.Col>
              <Form.Input className="lg-12" icon="search" placeholder="CV" position="append" onChange={(e) => setCvInp(e.target.value)} value={cvInp} />
          </Grid.Col>
          <Grid.Col>
          <Form.Group label="">
             
              <Button pill size="sm" outline color="primary" onClick={() => { buscaPorCV() }}><Icon name="refresh-cw" /></Button>
          </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Seleccione el tipo de evento :">
              <Select value={tipoEvento} options={listaTiposEvento} onChange={(e) => cambiaTipoEvento(e)} isMulti={false} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col>
            <Form.Group label="Seleccione un CV :">
              <Select value={cv} options={listaCV} onChange={(e) => cambiaCV(e)} isMulti={false} />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
          <Table.Header>
            <Table.ColHeader>CV</Table.ColHeader>
            <Table.ColHeader>Tipo de Evento</Table.ColHeader>
            <Table.ColHeader>Fecha</Table.ColHeader>
            <Table.ColHeader>Nombre de Archivo</Table.ColHeader>
            {/* <Table.ColHeader>Contenido</Table.ColHeader> */}
            <Table.ColHeader>Estatus</Table.ColHeader>
            <Table.ColHeader>Documento</Table.ColHeader>
          </Table.Header>

          <Table.Body>

            {
              resultado_api.filter(r => cv.value ? r.bookingRef === cv.label : true).filter(r => tipoEvento.value ? r.id_event === tipoEvento.label : true).map((element, index) =>
                <Table.Row>
                  <Table.Col> {element["bookingRef"]} </Table.Col>
                  <Table.Col> {element["id_event"]} </Table.Col>
                  <Table.Col> {element["timestampSystem"]} </Table.Col>
                  <Table.Col> {element["response"]}</Table.Col>
                  {/* <Table.Col> {element["request_body"]} </Table.Col> */}
                  <Table.Col> {element["response_type"]} </Table.Col>
                  <Table.Col>{element["response"] != null ? <Button pill size="sm" outline color="primary"
                    onClick={() => { guardaArchivoTexto(element["request_body"], element["response"]) }}><Icon name="download" /></Button> : ""}</Table.Col>

                </Table.Row>
              )

            }

          </Table.Body>
        </Table>


      </div>

    </SiteWrapper>
  );

  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get": {
          if (stateVar === 'eventosKPI' && returnVal) {
           
            let si = 0;
            let isr = 0;
            let ir = 0;
            setResultadoApi(returnVal);

            let labels = [];
            let data = [];
            returnVal.forEach(element => {
              if (element.id_event == "SI") {
                si++;
              }
              if (element.id_event == "ISR") {
                isr++;
              }
              if (element.id_event == "IR") {
                ir++;
              }



              setListaCV((prevState) => {
                let duplicate = prevState.find((x) => x.label === element.bookingRef)
                if (!duplicate)
                  return [...prevState, { value: element.bookingRef, label: element.bookingRef }];
                else
                  return [...prevState];
              });
              setListaTiposEvento((prevState) => {
                let duplicate = prevState.find((x) => x.label === element.id_event)
                if (!duplicate)
                  return [...prevState, { value: element.id_event, label: element.id_event }];
                else
                  return [...prevState];
              });

            })


            labels = ["Integradas reportando", "Integradas sin reportar", "Sin integrar"];
            data = [ir, isr, si];
            let customLabels = labels.map((label, index) => `${label}: ${data[index]}`)
            setCharData({
              labels: customLabels,
              datasets: [
                {
                  label: "Total de eventos",
                  backgroundColor: [
                    "#83ce83",
                    "#f96a5d",
                    "#6800B4",
                    "#00A6B4",

                  ],
                  data: data,
                },
              ],
            });
          }


        } break;
        default:
          break;
      }
    }).catch(err => { console.log(err); });
  }
  function cambiaCV(e) {

    setCV(e);

    cvFilt = e.label;
    tipoEventoFilt = tipoEvento.label;
    filtraGrafica();
  }
  function cambiaTipoEvento(e) {
    setTipoEvento(e);
    tipoEventoFilt = e.label;
    cvFilt = cv.label;
    filtraGrafica();
  }
  function buscaPorCV() {
    
    if (cvInp != "") {
      crud("get", "", "", "logEventosKpi/cv/" + cvInp + "/", "eventosKPI");
    } else {
      iniciaVariable();
    }
  }

  function filtraGrafica() {
    let si = 0;
    let isr = 0;
    let ir = 0;
    let datosActuales = resultado_api;
    let labels = [];
    let data = [];

    setListaCV([{ value: 0, label: "Todos" }]);
    setListaTiposEvento([{ value: 0, label: "Todos" }]);

    datosActuales.forEach(element => {
      if (cvFilt == "Todos" || cvFilt == element.bookingRef) {
        if (tipoEventoFilt == "Todos" || tipoEventoFilt == element.id_event) {

          if (element.id_event == "SI") {
            si++;
          }
          if (element.id_event == "ISR") {
            isr++;
          }
          if (element.id_event == "IR") {
            ir++;
          }

          setListaCV((prevState) => {
            let duplicate = prevState.find((x) => x.label === element.bookingRef)
            if (!duplicate)
              return [...prevState, { value: element.bookingRef, label: element.bookingRef }];
            else
              return [...prevState];
          });
          setListaTiposEvento((prevState) => {
            let duplicate = prevState.find((x) => x.label === element.id_event)
            if (!duplicate)
              return [...prevState, { value: element.id_event, label: element.id_event }];
            else
              return [...prevState];
          });

        }
      }
    })

    labels = ["Integradas reportando", "Integradas sin reportar", "Sin integrar"];
    data = [ir, isr, si];
    let customLabels = labels.map((label, index) => `${label}: ${data[index]}`)
    setCharData({
      labels: customLabels,
      datasets: [
        {
          label: "Estatus de placas",
          backgroundColor: [
            "#83ce83",
            "#f96a5d",
            "#6800B4",
            "#00A6B4",

          ],
          data: data,
        },
      ],
    });
  }
  function guardaArchivoTexto(contenido, nombre) {
    let a = document.createElement("a");
    let archivo = new Blob([contenido + "\n"], { type: 'text/plain' });
    let url = URL.createObjectURL(archivo);
    a.href = url;
    a.download = nombre;
    a.click();
    URL.revokeObjectURL(url);
  }
}


export default HistoricoKPIPage;
