import React, { useState, useEffect } from 'react';
import { Grid, Form } from "tabler-react";
import SiteWrapper from "../../../SiteWrapper.react";
import { Crud_Catalogos } from '../../../herramientas/Crud_Catalogos';
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { StatBox } from "./components/StatBox";
import { Header } from "./components/Header";
import { BarGraph } from "./components/BarGraph";
import { LineChart } from "./components/LineChart";
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CircularProgress from '@mui/material/CircularProgress';
import HomeIcon from '@mui/icons-material/Home';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import MenuIcon from '@mui/icons-material/Menu';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode, tokens } from "./theme";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const Item = ({ title, icon, selected, enclick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={enclick}
      icon={icon}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ChartTabs({children}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} centered>
          {children}
        </Tabs>
      </Box>
    </Box>
  );
}

function Panel({
  children,
  isActive,
  onShow
}) {
  return (
    <>
      {isActive ? (
        <>{children}</>
      ) : ""}
    </>
  );
}

const buttonStyle = {
  margin: '10px 0',

};

const Button = ({ label, handleClick }) => (
  <button
    className="btn btn-default"
    style={buttonStyle}
    onClick={handleClick}
  >
    {label}
  </button>
);

const Cuadro = ({valor, nombre, porciento}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    return(
      <Box
        gridColumn="span 3"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
      <StatBox
        title={valor}
        subtitle={nombre}
        progress={porciento}
        icon={
          <GppMaybeIcon
            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
          />
        }
      />
    </Box>
    )
}

export const IncidenciasIndisciplinasPorTransportistaPage = () => {
  const anioActual = new Date().getFullYear();
  const mesActual = new Date().getMonth();
  const [transportistas, setTransportistas] = useState([]);
  const [incidencias, setIncidencias] = useState([]);
  const [idTransportista, setIdTransportista] = useState(0);
  const [search, setSearch] = useState("todos");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");
  const [total, setTotal] = useState(0);
  const [tema, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tipos, setTipos] = useState([])
  const [evolucion, setEvolucion] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [recientes, setRecientes] = useState([]);
  const [anios, setAnios] = useState({});
  const [contador, setContador] = useState(0)
  const [anio, setAnio] = useState(anioActual);
  const [top, setTop] = useState([]);
  const [mes, setMes] = useState(mesActual);
  const [meses, setMeses] = useState({});
  let titulo="Top 6 transportistas indisciplinados de "+anioActual;
  useEffect(()=>{
    buscarTransportista(search);
    obtenerIndisciplinasPorTipoIncidencia(anio);
    obtenerIndisciplinasRecientes();
    obtenerTop6TransportistasAlMes(mesActual);
    obtenerAnios();
    obtenerMeses(anioActual);
  },[]);
  useEffect(()=>{
    if (search.length > 3) {
      buscarTransportista(search);
    }
    if(search === ""){ 
      buscarTransportista("todos");
     }
  },[search]);

  useEffect(()=>{
    if (idTransportista > 0 ) {
      obtenerIncidenciasTransportistas(idTransportista);
      setActiveIndex(1);
    }
  },[idTransportista]);

  useEffect(()=>{
    if (incidencias.length > 0) {
      calcularTotal();
    }
  },[incidencias,total]);

  useEffect(() => {
    if (anio != anioActual) {
      obtenerIndisciplinasPorTipoIncidencia(anio);
    }else{
      obtenerIndisciplinasPorTipoIncidencia(anioActual);
    }
  }, [anio])
  
  useEffect(() => {
    if (mes != mesActual) {
      obtenerTop6TransportistasAlMes(mes);
    }else{
      obtenerTop6TransportistasAlMes(mesActual);
    }
  }, [mes]);
  
  const obtenerMeses = async () => {
    let m = [];
    await getfuntion("get", [], "", "mesesEntregasIncidenciasEntregas/anio/"+anioActual, "indisciplinas_incidencias_mes", "").then((res) => {
      res.forEach(r => {
          m.push(r);
      });
    });
    setMeses(m);
  }

  const obtenerTop6TransportistasAlMes = async (month) => {
    let t = [];
    await getfuntion("get", [], "", "topTransportistasIndisciplinas/mes/"+month, "indisciplinas_incidencias_mes", "").then((res) => {
      res.forEach(r => {
          t.push(r);
      });
    });
    setTop(t);
  }

  const obtenerIndisciplinasPorTipoIncidencia = async (a) => {
    let lineasColoreadas = [];
    await getfuntion("get", [], "", "indisciplinasPorTipoAlMes/anio/"+a, "indisciplinas_incidencias_mes", "").then((res) => {
      res.forEach(r => {
          lineasColoreadas.push(r);
      });
    });
    setEvolucion(lineasColoreadas);
  }

  const obtenerAnios = async () => {
    let years = [];
    await getfuntion("get", [], "", "aniosIncidenciasEntregas", "entregas_incidencias", "").then((res) => {
      res.forEach(r => {
          years.push(r);
      });
    });
    setAnios(years);
  }

  const obtenerIndisciplinasRecientes = async () => {
    let recientes = [];
    await getfuntion("get", [], "", "indisciplinasRecientes", "entregas_incidencias", "").then((res) => {
      res.forEach(r => {
          recientes.push(r);
      });
    });
    setRecientes(recientes);
  }

  const obtenerIncidenciasTransportistas = async (id) => {
    let incid = [];
    await getfuntion("get", [], "", "incidenciasOperativas/transportista/"+id, "entregas_incidencias", "").then((res) => {
      res.forEach(r => {
          incid.push(r);
      });
    });
    setIncidencias(incid);
  }
  const buscarTransportista = async(texto) => {
    let search = [];
    await getfuntion("get", [], "", "transportistas/search/" + texto, "transportistas", "").then((res) => {
      res.forEach(r => {
        search.push(r);
      });
    });
    setTransportistas(search);
  }
  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            switch (stateVar) {

              default: return returnVal;
            }
          }
        default: return returnVal;
      }
    })
  }
  

  let calcularTotal = () => {
    let valor = 0;
    incidencias.map(item=>{
      valor += item.value
    })
    setTotal(valor);
  }

  let calcularPorcentaje = (cifra) =>{
    return (cifra*total)/100;
  }

  let dataTopTransportista = [];
  let dataTopTotal = [];
  top.map(item=>{
    dataTopTransportista.push(item.nombre);
    dataTopTotal.push(item.total);
  })

  let datos = [];
  evolucion.map(item=>{
    datos.push({ 
      id:item.tipo, 
      color: random_hex(), 
      data: [
        {
          x: "Enero",
          y: item.enero
        },
        {
          x: "Febrero",
          y: item.febrero
        },
        {
          x: "Marzo",
          y: item.marzo
        },
        {
          x: "Abril",
          y: item.abril
        },
        {
          x: "Mayo",
          y: item.mayo
        },
        {
          x: "Junio",
          y: item.enero
        },
        {
          x: "Julio",
          y: item.julio
        },
        {
          x: "Agosto",
          y: item.agosto
        },
        {
          x: "Septiembre",
          y: item.septiembre
        },
        {
          x: "Octubre",
          y: item.octubre
        },
        {
          x: "Noviembre",
          y: item.noviembre
        },
        {
          x: "Diciembre",
          y: item.diciembre
        },
      ]
    });
  });
  
  function random_hex() {
    let letters = "0123456789ABCDEF";
    let color = '#';
    for (let i = 0; i < 6; i++){
        color += letters[(Math.floor(Math.random() * 16))];
    }
    return color;
  }
  
  const aniosTab = Object.values(anios).map(item => 
    <Tab key={item.id} label={item.anios} {...a11yProps(item.id)} onClick={()=>setAnio(item.anios)} style={{fontSize:"20px"}}/>
    )
  const mesesTab = Object.values(meses).map(item => 
    <Tab key={"m-"+item.id} label={item.mes} {...a11yProps("m-"+item.id)} onClick={()=>setMes(item.id)} style={{fontSize:"20px"}}/>
    )
    const listaTransportistas = dataTopTransportista.map(t=>
      <li style={{
        float: "left",
        paddingLeft:"2%"
      }}>{t}</li>
     )
  return (
    <SiteWrapper>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={tema}>
          <CssBaseline/>
            <div className="app">
              <Grid.Row>
                <Grid.Col sm={4} lg={4} style={{textAlign: "center"}}>
                  <Box
                    sx={{
                      "& .pro-sidebar-inner": {
                        background: `${colors.primary[400]} !important`,
                      },
                      "& .pro-icon-wrapper": {
                        backgroundColor: "transparent !important",
                      },
                      "& .pro-inner-item": {
                        padding: "5px 35px 5px 20px !important",
                      },
                      "& .pro-inner-item:hover": {
                        color: "#868dfb !important",
                      },
                      "& .pro-menu-item.active": {
                        color: "#6870fa !important",
                      },
                    }}
                  >
                  <Sidebar collapsed={isCollapsed} width="500px">
                    <Menu iconShape="square">
                      <MenuItem
                        key="sidebar"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuIcon /> : undefined}
                        style={{
                          margin: "10px 0 5px 0",
                          color: colors.grey[100],
                        }}
                      >
                        {!isCollapsed && (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            ml="30px"
                          >
                            <Typography variant="h3" color={colors.grey[100]}>
                              Transportistas
                            </Typography>
                            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                              <MenuIcon />
                            </IconButton>
                          </Box>
                        )}
                      </MenuItem>
                      <Item
                        key={'indisciplinas_operativas_recientes'}
                        title={"Indisciplinas recientes"}
                        icon={<AccessTimeFilledIcon />}
                        enclick={()=>{setActiveIndex(2)}}
                      />
                      <br/>
                      <br/>
                      <MenuItem key="lista" style={
                          {
                            width:'84%',
                            paddingLeft: '10%'
                          }
                        }>
                        <Form.Input name={"transportistas"} type={"text"} label={"Buscar transportistas"}
                          onKeyUp={(e)=>{
                            setSearch(e.target.value)
                          }}
                        />
                      </MenuItem>
                      <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        {transportistas.map(item =>{
                          return(
                            <Item
                              key={'t-'+item.id}
                              title={item.nombre}
                              icon={<DirectionsBusFilledIcon />}
                              enclick={()=>{setIdTransportista(item.id)}}
                            />
                          )})}
                      </Box>
                    </Menu>
                  </Sidebar>
                </Box>
                </Grid.Col>
                <Grid.Col>
                <Panel isActive={activeIndex === 0} onShow={() => setActiveIndex(0)}>
                  <br/>
                  <main className="content" style={{
                    width:"85%"
                  }}>
                  <Header title="Indisciplinas por mes" />
                  <Box height="250px" m="-20px 0 0 0">
                    {datos.length > 0 ? 
                    <>
                    <ChartTabs children={aniosTab}/>
                    <LineChart isDashboard={true} data={datos} />
                    </>
                    :
                    <>
                    <br/>
                    <br/>
                    <CircularProgress />
                    </>
                    }
                  </Box>
                  <br/>
                  <br/>
                  
                  <Box>
                    <Header title={titulo} />
                    {dataTopTransportista.length > 0 ?
                    <>
                    <ChartTabs children={mesesTab}/>
                    <ol style={{
                      listStyle: "none"
                    }}>{listaTransportistas}</ol>
                    <BarGraph dataX={dataTopTransportista} dataY={dataTopTotal} />
                    </>
                    :
                    <>
                    <br/>
                    <br/>
                    <CircularProgress/>
                    </>
                    }
                  </Box>
                  <br/>  
                  </main>
                </Panel>
                  <Panel isActive={activeIndex === 1} onShow={() => setActiveIndex(1)}>
                    <br/>
                    <main className="content" style={{
                      width:"80%"
                    }}>
                    {incidencias.length > 0 ? 
                      <Box m="20px">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Header title="Indisciplinas operativas" subtitle="Tablero de indisciplinas por tipo de incidencia" />
                          <Button label="Regresar" handleClick={() => {setActiveIndex(0); setIdTransportista(0)}}/>
                        </Box>
                        <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px"
                      >
                        {incidencias.map(item => {
                          return(
                            <Cuadro key={item.id} nombre={item.name} valor={item.value} porciento={calcularPorcentaje(item.value)} />
                          )
                        })}
                        </Box>
                      </Box>
                      :
                      <h2>No hay datos disponibles</h2>
                    }
                    </main>
                  </Panel>
                  <Panel isActive={activeIndex === 2} onShow={() => setActiveIndex(2)}>
                    <br/>
                    <main className="content" style={{
                      width:"80%"
                    }}>
                      <Box
                        gridColumn="span 2"
                        gridRow="span 1"
                        backgroundColor={colors.primary[400]}
                        overflow="auto"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          borderBottom={`4px solid ${colors.primary[500]}`}
                          colors={colors.grey[100]}
                          p="15px"
                        >
                          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                            Indisciplinas recientes
                          </Typography>
                          <Button label="Regresar" handleClick={() => {setActiveIndex(0); setIdTransportista(0)}}/>
                        </Box>
                        {recientes.map(item => (
                        <Box
                          key={'r-'+item.id}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          borderBottom={`4px solid ${colors.primary[500]}`}
                          p="15px"
                          sx={{overflow:"scroll"}}
                        >
                          <Box>
                            <Typography
                              color={colors.greenAccent[500]}
                              variant="h5"
                              fontWeight="600"
                            >
                              {item.nombre}
                            </Typography>
                            <Typography color={colors.grey[100]}>
                              {item.comentario}
                            </Typography>
                          </Box>
                          <Box color={colors.grey[100]}>{item.creado}</Box>
                        </Box>
                        ))}
                      </Box>
                    </main>
                  </Panel>
                </Grid.Col>
              </Grid.Row>
            </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
  </SiteWrapper>
  )
}
export default React.memo(IncidenciasIndisciplinasPorTransportistaPage);