import React, { Component } from "react";
import { Button, Form, Icon, Grid, List } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import Reaccion from "./panelForms/Reaccion";
import { NotificationManager } from 'react-notifications';

class  MovimientoIncidenciasForm extends Component {
  constructor(props) {
    super(props);
    this.state = { id:"",
                   incidencias: { value: 0, label: "Selecciona  tipo de incidencia", data: null },
                   comentario:"",
                   accion: this.props.accion, //'nuevo'// modificar, nuevo
                   disabledSave: true,
                   latitud: "",
                   longitud: "",
                   enviar: false,
                   destinosClientes: [],
                   destinosTransportistas: [],
                   destinosMonitoreo: []
                  };
                  this.enableSave = this.enableSave.bind(this);
                  this.movimientoIncidencia=[];
                  this.incidencias= [];
                  this.privilegio=[];
  }
  componentDidMount =() => {
    console.log("30 MovimientoIncidencia this.props.incidencias");
    console.log(this.props.incidencias);
    this.props.incidencias.map((inc, index)=>{ this.incidencias.push({ value: inc.id, label: inc.nombre, data: inc }); });
    if(this.state.accion === 'nuevo'){
      let latitud= "";
      let longitud= "";
      if(this.props.latitud){
        latitud= this.props.latitud;
      }else{
        latitud= this.props.gpsReciente ? this.props.gpsReciente.latitud: "";
      }
      if(this.props.longitud){
        longitud= this.props.longitud
      }else{
        longitud= this.props.gpsReciente ? this.props.gpsReciente.longitud:"";
      }
        this.movimientoIncidencia={
                                    "idMovimiento"          :this.props.idmovimiento,
                                    "comentario"            :"",
                                    "fechaCreado"           :null,
                                    "atencionUsuario"       :this.props.idusuario,
                                    "atencionFecha"         :null,
                                    "latitud"               :latitud,
                                    "longitud"              :longitud,
                                    "destinosMonitoreo"     :[],
                                    "destinosClientes"      :[],
                                    "destinosTransportistas":[],
                                    "incidencia": {}
                                };
        this.setState({ latitud:  latitud });
        this.setState({ longitud: longitud });
    }else{
      this.setState({incidencias: this.props.elementos.incidencias});
      this.setState({comentario: this.props.elementos.comentario});
    }
  }


  enableSave =() =>{
    if(this.state.incidencias.value > 0 ){
      return false;
    }else{ return true }
  }

  delete() {
    this.props.getfuntion("delete", "", this.props.elementos.id );
  }
  crud_put() {
    this.props.getfuntion("put", this.movimientoIncidencia );
  }
  crud_post() {
    this.movimientoIncidencia.comentario= this.state.comentario;
    this.movimientoIncidencia.incidencia= this.state.incidencias.data;
    //Crea la incidencia y luego envia  correo si asi de sea
    if(!this.props.alerta){
      this.props.getfuntion("post", this.movimientoIncidencia,"","movimientoIncidencias","incidencias", this.props.closeModal, this.state.enviar);
    }else{
      this.props.getfuntion("post", this.movimientoIncidencia,"","movimientoIncidencias","incidencias", this.props.closeModal, this.state.enviar);
    }
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
            if (stateVar === "destinosClientes"){
              this.setState({ destinosClientes: returnVal });
              this.movimientoIncidencia.destinosClientes=returnVal;
            }
            if (stateVar === "destinosTransportistas"){
              this.setState({ destinosTransportistas: returnVal });
              this.movimientoIncidencia.destinosTransportistas=returnVal;
            }
            if (stateVar === "destinosMonitoreo"){
              this.setState({ destinosMonitoreo: returnVal });
              this.movimientoIncidencia.destinosMonitoreo=returnVal;
            }
            break;
        default:
          break;
      }
    }).catch(err => { if (err.response) { console.log(err.response);  }else{console.log("Error desconocido ...." + err.response); } });
  }
  changeIncidencias=(event) =>{
    this.setState({incidencias:event});
    if(event.data.idEtiqueta){
      this.getfuntion("get", [], "", "personas/destinatarios_clientes_incidencias/"+this.props.idmovimiento+"/"+event.data.idEtiqueta, "destinosClientes");
      this.getfuntion("get", [], "", "personas/destinatarios_transportistas_incidencias/"+this.props.idmovimiento+"/"+event.data.idEtiqueta, "destinosTransportistas");
      this.getfuntion("get", [], "", "personas/destinatarios_sky_naviera_incidencias/"+this.props.idmovimiento+"/"+event.data.idEtiqueta, "destinosMonitoreo");
      this.setState({ enviar: true });
    }else{
      this.setState({ destinosClientes: [] });
      this.setState({ destinosTransportistas: [] });
      this.setState({ destinosMonitoreo: [] });
      this.setState({ enviar: false });
    }
  }
  changeComentario =(event) => this.setState({comentario: event.target.value});
  changeEnviar=(e) =>{
    this.setState({ enviar: e.target.checked });
  }

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0) { this.privilegio=privilegios[i]; return true } else { return false }
        break;
      }
    }
  }

  render(){
    return (
      <div>
        { this.state.accion === "modificar" ? <h1> { this.props.elementos.comentario } </h1> : null }
        <Grid.Row>
        <Grid.Col md={12} xl={12}>
          <Grid.Row>
            <Grid.Col md={8} lg={8}>
              <Form.Group label="incidencias">
                <Select value={ this.state.incidencias } onChange={this.changeIncidencias} options={ this.incidencias } />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} lg={2}>
              <Form.Group label="Latitud">
                  <Form.Input name="latitud"  value={this.state.latitud} onChange={this.changeLatitud} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} lg={2}>
              <Form.Group label="Longitud">
                <Form.Input name="longitud"  value={this.state.longitud} onChange={this.changeLongitud} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12}>
              <Form.Group label="Comentario">
              <Form.Textarea name="comentario" type="text" value={ this.state.comentario} onChange={this.changeComentario} maxlength="50"/>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12}>
              <Form.Group label="Enviar correo">
                {this.estaPrivilegioActivo("arroba_incidencia") ?
                  <Form.Switch type="Checkbox" checked={this.state.enviar} onChange={ this.changeEnviar } />
                :
                <Form.Switch type="Checkbox" checked={false} />
                }
              </Form.Group>
              {
                this.state.destinosClientes ?
                <List.Group>
                <h3>{this.state.destinosClientes.length > 0? "Cliente": null }</h3>
                { this.state.destinosClientes.map((cliente, index) =>
                    <List.GroupItem action icon="rss">
                      <div className="row">
                        <div className="col-5"> <Icon name="user"/>{ cliente.nombre+" "+cliente.aPaterno+ " "+ cliente.aMaterno} </div>
                        <div className="col-5"> <Icon name="mail"/>{ cliente.correo } </div>
                      </div>
                    </List.GroupItem>
                  )
                }
                </List.Group>
                :
                null
              }
              {
                this.state.destinosTransportistas ?
                <List.Group>
                <h3>{this.state.destinosTransportistas.length > 0? "Transportista": null }</h3>
                {
                  this.state.destinosTransportistas.map((transp, index) =>
                  <List.GroupItem action icon="rss">
                      <div className="row">
                        <div className="col-5"> <Icon name="user"/>{ transp.nombre+" "+transp.aPaterno+ " "+ transp.aMaterno } </div>
                        <div className="col-5"> <Icon name="mail"/>{ transp.correo } </div>
                      </div>
                  </List.GroupItem>
                  )
                }
                </List.Group>
                :
                null
              }
              {
                this.state.destinosMonitoreo ?
                <List.Group>
                <h3>{this.state.destinosMonitoreo.length > 0? "Otros": null }</h3>
                {
                  this.state.destinosMonitoreo.map((moni, index) =>
                  <List.GroupItem action icon="rss">
                      <div className="row">
                        <div className="col-5"> <Icon name="user"/>{ moni.nombre+" "+moni.aPaterno+ " "+ moni.aMaterno } </div>
                        <div className="col-5"> <Icon name="mail"/>{ moni.correo } </div>
                      </div>
                  </List.GroupItem>
                  )
                }
                </List.Group>
                :
                null
              }
              {this.state.enviar?
                this.state.destinosClientes.length> 0 || this.state.destinosTransportistas.length> 0 || this.state.destinosMonitoreo.length > 0 ?null
                :<List.GroupItem action icon="rss"> Al parecer no se ha configurado la plantilla, o no hay destinatarios, revise su configuración </List.GroupItem>
              :null}
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>

      <div className="float-right">
        <span className="badge">
          <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled= { this.enableSave() } onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() } >
            <span className="badge"><Icon link={true} name="save" />Guardar incidencia</span>
          </Button>
        </span>
          { this.state.accion === "modificar" ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={()=>this.delete()} >
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
          :
          null
          }
        </div>
      </div>
    );
  }
}
export default MovimientoIncidenciasForm;
