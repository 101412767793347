import React, { Component } from "react";
import { List, Button, Icon, Card, Table, Grid, Form } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import DateTimePicker from "react-datetime-picker";
import { timeToDateFill } from "../../../../herramientas/DateFormat";
var user = JSON.parse(sessionStorage.getItem("usuario"));
//Formato de Fecha
function pad(number) {
  if (number < 10) { return '0' + number; }
  return number;
}
function myFormato(date) {
  console.log(date);
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}

class ReaccionTerrestre extends Component {
  constructor(props){
    super(props);
    this.state = {
      reaccionMovimiento: {},
      idPlataforma: 0,
      reaccionObject: [],
      longitud: "",
      supervisor: "",
      situacion: "",
      central: "",
      latitud: "",
      lineaTransporte: "",
      numContenedor: "",
      tipoCaja: "",
      nombreCliente: "",
      noContenedores: "",
      colorContenedor: "",
      cargadoVacio: 0,
      placaRemolque: "",
      economico: "",
      placa: "",
      marca: "",
      modeloTracto: "",
      color: "",
      nombreOperador: "",
      telefonoOperador: "",
      clavenoAmago: "",
      direcOrigen: "",
      direcDestino: "",
      correPista: "",
      kilometro: "",
      sentidoUnidad: "",
      citaCliente: "",
      nombrecoordinador: "",
      telefonoCoordinador: "",
      noSerie: "",
      noMotor: "",
      observaciones: "",
    };
    this.usuariosLogObject = {
      "idBitacoraMonitoreo": 7,
      "idUsuario": 0,
      "idMovimiento": 0,
      //"accionFecha": "",
      "descripcion": "",
      "idPlataformaTipo":""
    };
    this.reaccionObject = {
      "idMovimiento": 0,
      "idPlataforma": 0,
      "supervisor": "",
      "situacion": "",
      "central": "",
      "latitud": "",
      "longitud": "",
      "transportista": "",
      "contenedor": "",
      "clienteNombre": "",
      "cajaTipo": "",
      "contenedorTotal": "",
      "contenedorColor": "",
      "contenedorCargado": 0,
      "remolquePlaca": "",
      "economico": "",
      "placa": "",
      "marca": "",
      "tractoModelo": "",
      "color": "",
      "operadorNombre": "",
      "operadorTelefono": "",
      "claveNoAmago": "",
      "origen": "",
      "destino": "",
      "citaProgramada": "",
      "coordinadorNombre": "",
      "coordinadorTelefono": "",
      "serieNumero": "",
      "motorNumero": "",
      "observaciones": "",
      //"registroFecha": ""
      };

  }


  componentDidMount =() => {
     //console.log(this.props.getMovimiento.idPlataforma.id +"Construyendo Formulario de naviera");
     //console.log(this.props.getMovimiento.plataforma.id +"Construyendo Formulario de terrestre");
     this.setState({citaCliente    : this.state.citaCliente ?     new Date(this.state.citaCliente)    :  "" });
    if(this.props.plataformaTipo == "Naviera"){
      this.setState({idPlataforma: this.props.getMovimiento.idPlataforma.id});
      setTimeout(() => {
        this.getReaccionMovi();

        }, 1000);
    } else if(this.props.plataformaTipo == "Terrestre"){
      this.setState({idPlataforma: this.props.getMovimiento.plataforma.id});
      setTimeout(() => {
        this.getReaccionMoviTerrestre();

        }, 1000);
    }

  }

  getReaccionMovi = () => {
    Crud_Catalogos("reaccionInfoMovimientos/"+this.props.movimiento, "reaccion", "get", "", [], "", this.state.reaccionMovimiento, "", []).then((returnVal) => {
      this.reaccionMovimiento = returnVal;
        this.getfuntion("get", [], "", "gpsestatus/plataforma/"+this.props.getMovimiento.idPlataforma.idGg+"/gps/" + returnVal.idGps, "gpsestatus");
      this.setState({ reaccionMovimiento: returnVal });
      this.setState({ nombreCliente: returnVal.contactoCliente });
      this.setState({ numContenedor: returnVal.contenedor });
      this.setState({ citaCliente: returnVal.horaCita });
      this.setState({ lineaTransporte: returnVal.lineaTransportista });
      this.setState({ noContenedores: returnVal.noContenedores });
      this.setState({ nombreOperador: returnVal.operadorNombre });
      this.setState({ telefonoOperador: returnVal.operadorContacto });
      this.setState({ placaRemolque: returnVal.remolquePlaca });
      this.setState({ tipoCaja: returnVal.tipoCaja });
      this.setState({ color: returnVal.tractorColor });
      this.setState({ marca: returnVal.tractorMarca });
      this.setState({ modeloTracto: returnVal.tractorModelo });
      this.setState({ placa: returnVal.tractorPlaca });
    }).catch(err => {
      if (err.response) {
        console.log(err);
      } else {
        /**
         * Error
         */
      }
    })
  }

  getReaccionMoviTerrestre = () => {
//    Crud_Catalogos("reaccionInfoMovimientosTerrestres/"+this.props.movimiento, "reaccion", "get", "", [], "", this.state.reaccionMovimiento, "", []).then((returnVal) => {
    Crud_Catalogos("reaccionTerrestre/", "agregar2", "get", "", [], "", this.state.reaccionMovimiento, "", []).then((returnVal) => {

      this.reaccionMovimiento = returnVal;
      this.setState({ reaccionMovimiento: returnVal.supervisor });

    }).catch(err => {
      if (err.response) {
        console.log(err);
      } else {
        /**
         * Error
         */
      }
    })
  }



  agregarReaccion = () => {
    this.reaccionObject.idMovimiento = this.props.movimiento;
    //PLATAFORMA
    this.reaccionObject.idPlataforma = this.state.idPlataforma;
    //this.reaccionObject.longitud = this.state.longitud;
    this.reaccionObject.supervisor = this.state.supervisor;
    //situacion
    this.reaccionObject.situacion = this.state.situacion;
    this.reaccionObject.central = this.state.central;
    this.reaccionObject.latitud = this.state.latitud;
    this.reaccionObject.longitud = this.state.longitud;
    this.reaccionObject.transportista = this.state.lineaTransporte;
    this.reaccionObject.contenedor = this.state.numContenedor;
    this.reaccionObject.clienteNombre = this.state.nombreCliente;
    this.reaccionObject.cajaTipo = this.state.tipoCaja;
    this.reaccionObject.contenedorTotal = this.state.noContenedores;
    this.reaccionObject.contenedorColor = this.state.colorContenedor;
    this.reaccionObject.contenedorCargado = this.state.cargadoVacio;
    this.reaccionObject.contenedorTotal = this.state.noContenedores;
    this.reaccionObject.contenedorColor = this.state.colorContenedor;
    this.reaccionObject.contenedorCargado = this.state.cargadoVacio;
    this.reaccionObject.remolquePlaca = this.state.placaRemolque;
    this.reaccionObject.economico = this.state.economico;
    this.reaccionObject.placa = this.state.placa;
    this.reaccionObject.marca = this.state.marca;
    this.reaccionObject.tractoModelo = this.state.modeloTracto;
    this.reaccionObject.color = this.state.color;
    this.reaccionObject.operadorNombre = this.state.nombreOperador;
    this.reaccionObject.operadorTelefono = this.state.telefonoOperador;
    this.reaccionObject.claveNoAmago = this.state.clavenoAmago;
    this.reaccionObject.origen = this.state.direcOrigen;
    this.reaccionObject.destino = this.state.direcDestino;
    this.reaccionObject.citaProgramada = this.state.citaCliente;
    this.reaccionObject.coordinadorNombre = this.state.nombrecoordinador;
    this.reaccionObject.coordinadorTelefono = this.state.telefonoCoordinador;
    this.reaccionObject.serieNumero = this.state.noSerie;
    this.reaccionObject.motorNumero = this.state.noMotor;
    this.reaccionObject.observaciones = this.state.observaciones;
    
/*
    this.reaccionObject.reporte = this.state.reporte;
    this.reaccionObject.latitud = this.state.latitud;
    this.reaccionObject.pista = this.state.correPista;
    */
    this.reaccionObject.kilometro = this.state.kilometro;
    this.reaccionObject.central = this.state.central;
    this.reaccionObject.sentido = this.state.sentidoUnidad;
    //Crud_Catalogos("reaccionTerrestre", "ringo2", "post", "", this.reaccionObject, "", "", this.props.hiddenmodal, "").then((returnVal) => {});
    this.getfuntion("post", this.reaccionObject, "", "reaccionTerrestre", "agregar2");
    //Crud_Catalogos("reaccionTerrestre", "agregar2", "post", "", this.reaccionObject, "", "", this.props.hiddenmodal, "");
    Crud_Catalogos("reaccionTerrestre/agregar2", "agregar2", "post", "", this.reaccionObject, "", "", this.props.hiddenmodal, "").then((returnVal) => {});
    console.log("...mandando al back");
    
    
    this.usuariosLogObject.idBitacoraMonitoreo = 7;
    this.usuariosLogObject.idUsuario = user.id;
    this.usuariosLogObject.idMovimiento = this.reaccionObject.idMovimiento;
    this.usuariosLogObject.idPlataformaTipo = this.props.plataformaTipo == "Naviera"?1:2;
//    this.usuariosLogObject.accionFecha = myFormato(new Date());
    this.usuariosLogObject.descripcion = "Reacción Enviada en Movimiento id: " + this.reaccionObject.idMovimiento + " Data: " + JSON.stringify(this.reaccionObject);
    Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "").then((returnVal) => {});
    //this.window.close();
    //document.getElementsByTagName ('html') [0] .remove ();
    //document.getElementsByTagName ('html') [0] .click ();

    //document.close();
    //window.open('', '_self', ''); 
    //window.close();
    //Funcion
    //document.getElementsByClassName("modal-content")[0] .remove();
    window.open("monitoreo?q=activos", "_self");
    //document.parentNode.elementFromPoint(0, 0).click();
    //document.removeProperty('');
    //window.location.hash = '#html';
    //document.getElementById('root').click();
    //window.parent.click();
    //document.parent.click();
    
  }


  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "gpsestatus") {
              this.setState({ latitud:  returnVal.latitud });
              this.setState({ longitud: returnVal.longitud });
            }
          }
        case "post":
          {
            if (stateVar === "Reaccion") { this.props.hiddenmodal(); }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response);  } else {console.log("Error desconocido ...." + err.response); }
    })
  }

  changelongitud = (event) => this.setState({longitud: event.target.value});
  changesupervisor = (event) => this.setState({supervisor: event.target.value});
  changesituacion = (event) => this.setState({situacion: event.target.value});
  changecentral = (event) => this.setState({central: event.target.value});
  changelatitud = (event) => this.setState({latitud: event.target.value});
  changelineaTransporte = (event) => this.setState({lineaTransporte: event.target.value});
  changenumContenedor = (event) => this.setState({numContenedor: event.target.value});
  changetipoCaja = (event) => this.setState({tipoCaja: event.target.value});
  changenombreCliente = (event) => this.setState({nombreCliente: event.target.value});
  changenoContenedores = (event) => this.setState({noContenedores: event.target.value});
  changecolorContenedor = (event) => this.setState({colorContenedor: event.target.value});
  changecargadoVacio = (event) => this.setState({cargadoVacio: event.target.checked? 1:0});
  changeplacaRemolque = (event) => this.setState({placaRemolque: event.target.value});
  change = (event) => this.setState({economico: event.target.value});
  changeplaca = (event) => this.setState({placa: event.target.value});
  changemarca = (event) => this.setState({marca: event.target.value});
  changemodeloTracto = (event) => this.setState({modeloTracto: event.target.value});
  changecolor = (event) => this.setState({color: event.target.value});
  changenombreOperador = (event) => this.setState({nombreOperador: event.target.value});
  changetelefonoOperador = (event) => this.setState({telefonoOperador: event.target.value});
  changeclavenoAmago = (event) => this.setState({clavenoAmago: event.target.value});
  changedirecOrigen = (event) => this.setState({direcOrigen: event.target.value});
  changedirecDestino = (event) => this.setState({direcDestino: event.target.value});
  changecorrePista = (event) => this.setState({correPista: event.target.value});
  changekilometro = (event) => this.setState({kilometro: event.target.value});
  changesentidoUnidad = (event) => this.setState({sentidoUnidad: event.target.value});
  //changecitaCliente = (event) => this.setState({citaCliente: event.target.value});

  changecitaCliente = (date)  =>{ timeToDateFill(new Date(date).getTime()); this.setState({ citaCliente: date }); }
  changenombrecoordinador = (event) => this.setState({nombrecoordinador: event.target.value});
  changetelefonoCoordinador = (event) => this.setState({telefonoCoordinador: event.target.value});
  changenoSerie = (event) => this.setState({noSerie: event.target.value});
  changenoMotor = (event) => this.setState({noMotor: event.target.value});
  changeobservaciones = (event) => this.setState({observaciones: event.target.value});
  render() {
    var str = JSON.stringify(this.props.getMovimiento);
    var placa=str.substring(str.search("placa")+"placa".length+3);
    placa=placa.substring(0,placa.search("\""));

    return (



      <div>
        <Grid.Row>

        <Grid.Col md={4}>
            <Form.Group label="Central">
              <Form.Input name="central"  type="text" onChange={this.changecentral}/>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

          <Grid.Col md={4}>
            <Form.Group label="Supervisor Responsable">
              <Form.Input name="supervisor"  type="text" onChange={this.changesupervisor} />
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="situacion">
              <Form.Input name="situacion"  type="text"  onChange={this.changesituacion}/>
            </Form.Group>
          </Grid.Col>



        
          <Grid.Col md={4}>
            <Form.Group label="Latitud">
              <Form.Input name="latitud"  type="text" value={this.state.latitud} onChange={this.changelatitud}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Línea de Transporte">
              <Form.Input name="lineaTransporte"  type="text" value={this.state.lineaTransporte } onChange={this.changelineaTransporte}/>
            </Form.Group>
          </Grid.Col>
        
        <Grid.Col md={4}>
            <Form.Group label="Marca">
              <Form.Input name="marca"  type="text" value={this.state.marca} onChange={this.changemarca}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Color  ">
              <Form.Input name="color"  type="text"  value={this.state.color} onChange={this.changecolor}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Placa">
              <Form.Input name="placa"  type="text"  value={placa} onChange={this.changeplaca}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Ecónomico">
              <Form.Input name=""  type="text" onChange={this.change}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Nombre del Operador">
              <Form.Input name="nombreOperador"   value={ this.state.nombreOperador } type="text" onChange={this.changenombreOperador}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Teléfono operdador">
              <Form.Input name="telefonoOperador"  type="text" value={ this.state.telefonoOperador } onChange={this.changetelefonoOperador}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Clave de no amago">
              <Form.Input name="clavenoAmago"  type="text"  onChange={this.changeclavenoAmago}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Dirección Origen">
              <Form.Input name="direcOrigen"  type="text" onChange={this.changedirecOrigen}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Dirección Destino">
              <Form.Input name="direcDestino"  type="text" onChange={this.changedirecDestino}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Kilometro">
              <Form.Input name="kilometro"  type="text" value={this.state.kilometro} onChange={this.changekilometro}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Sentido">
              <Form.Input name="sentido"  type="text" value={this.state.sentido} onChange={this.changesentidoUnidad}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Nombre del coordinador">
              <Form.Input name="nombrecoordinador"  type="text" onChange={this.changenombrecoordinador}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Teléfono del coordinador">
              <Form.Input name="telefonoCoordinador"  type="text" onChange={this.changetelefonoCoordinador}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="No. de serie">
              <Form.Input name="noSerie"  type="text"  onChange={this.changenoSerie}/>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="No. de motor">
              <Form.Input name="noMotor"  type="text" onChange={this.changenoMotor}/>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Observaciones">
              <Form.Input name="observaciones"  type="text" onChange={this.changeobservaciones}/>
            </Form.Group>
          </Grid.Col>
 


        <Grid.Row>



        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={12}>
            <Form.Group >
              <Button.List align="right">
                  <Button onClick={() => {this.agregarReaccion()}}  color="primary">Guardar</Button>
              </Button.List>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>


      </div>
    );
  }
}
export default ReaccionTerrestre;
