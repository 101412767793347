import React, { Component } from "react";
import { Button, Form, Icon,Grid, Table, Card } from "tabler-react";
import { Crud_Catalogos } from "../herramientas/Crud_Catalogos";
import { NotificationManager} from 'react-notifications';
import { mail, password } from '../herramientas/Forms';
let send= false;
class RecuperarPassword extends Component {
  constructor(props) {
    super(props);
              this.state  = {
                             newPassword : '',
                             newPasswordC: '',
                             correo: '',
                             send: false
              };

              this.passwordObj= {
               "token"  :  this.props.token,
               "newPassword"    :  ""
              };

                         
  
  }
  componentDidMount=()=>{
  }


  crud_putPassword() { console.log(this.passwordObj);
    this.getfuntion("put", this.passwordObj, "",  "usuarios/auto_recuperar_password", "contrana");
  }
  enviarCorreo() { 
    if(!send){
      send= true;
      this.setState({send: true});
      this.getfuntion("get", this.passwordObj, "",  "mail/enviar_codigo_de_recuperacion?correo="+this.state.correo, "correo");
    }
  }    
        
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas/plataforma/"+this.state.idPlataforma.value , stateVar = "personas" ) => {
    return Crud_Catalogos(metodo !== "post" && catalogo.includes("personas")? "personas":catalogo , "tracking", metodo, id, obj, "", stateVar  ).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "correo") {
              send= false;
              this.setState({send: false});
              NotificationManager.info(returnVal, "notificación");    
            }
          }
          break;
        case "put":
          {
            if (stateVar === "contrana") {
              if(returnVal.status===200){
                NotificationManager.success(returnVal.message, "Modificación");
                window.location.href="/login";
              }else{
                NotificationManager.error(returnVal.message, "Modificación");
              }
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => {send= false; this.setState({send: false}); console.log(err) });
  }


  changeNewPassword = (event) => {
    this.setState({newPassword:event.target.value});
    this.passwordObj.newPassword=event.target.value;
  }


  render() {
    return (
      <div>
        <h2>{this.props.token? "Recuperar contraseña": "Ingresa tu correco electronico para recibir un link de recuperación,"}</h2>
        <Card>
          <Card.Body>
            { this.props.token?          
              <Grid.Row>         
                <Grid.Col>
                  <Form.Group label="Nuevo Password">
                    <Form.Input name="newPassword" type="password"  value={this.state.newPassword} onChange={this.changeNewPassword} />
                    <div >{this.state.newPassword?password(this.state.newPassword):""}</div>
                  </Form.Group>
                </Grid.Col>          
                <Grid.Col>
                  <Form.Group label="Confirmar Password">
                    <Form.Input name="newPasswordC" type="password"  value={this.state.newPasswordC} onChange={(e)=> this.setState({newPasswordC:e.target.value})} />
                  </Form.Group>
                  <div >{this.state.newPasswordC.length? this.state.newPasswordC !== this.state.newPassword? "Las contraseñas son diferentes":"":"" }</div>
                </Grid.Col>
                <Table.Col>
                      <Button target="_blank" size="sm" RootComponent="a" color="primary"
                      disabled= { this.habilitarBoton()}
                      onClick={() => this.crud_putPassword() }>
                      <span className="badge"><Icon link={true} name="refresh-ccw" />{this.passwordObj.newPasswordC === this.passwordObj.newPassword && this.passwordObj.newPassword.length   ? 'Actualizar Contraseña':'Actualizar contraseña' 
                      }</span>
                      </Button>                 
                </Table.Col>
              
              </Grid.Row>
            :
            <Grid.Row>         
              <Grid.Col>
                <Form.Input name="mail" type="mail"  value={this.state.correo} onChange={(e)=> this.setState({ correo: e.target.value })} placeholder="abc@dominio.ext" />
                {this.state.correo &&!mail(this.state.correo)?<h5>No parece ser una cuenta de correo valida</h5>:null}
              </Grid.Col>
              <Table.Col>
                    <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.enviarCorreo() } disabled={ !mail(this.state.correo) || this.state.send} >
                    <span className="badge"><Icon link={true} name="send" />  </span>
                    </Button>                 
              </Table.Col>
            
            </Grid.Row>
            }
          </Card.Body>
        </Card>   
        
      </div>
    );
  }

  habilitarBoton=()=> {
    if(!password(this.state.newPassword).length && this.state.newPassword === this.state.newPasswordC){
      return false;
    }else{
      return true;
    }
  }


}
export default RecuperarPassword;
