import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card, Dimmer } from "tabler-react";
import { timingSafeEqual } from "crypto";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import ModalLayout from "../../../../components/ModalLayout.react";
import { DestinatariosPersonas, DestinatariosEspeciales } from "../../../../Catalogos/Plataformas/Formularios/correo/index.js";

import Select from 'react-select';
import { Tab } from "react-tabs";
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';

class  Destinatarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
                      id: "",
            hiddenModals: false,
                  accion: this.props.accion, //'nuevo'// modificar, nuevo
           destinatarios: [],
                  nombre: [],
     idTipoConfiguracion: {label: "Seleccione un a opcción", value: 0 },
       tipoConfiguracion: [],
    tipoConfiguracionKey: {},
            editarNombre: "",
  editaridTipoConfigurac: {label: "Seleccione un a opcción", value: 0 },
           editarEstatus: false,
                  search: "",
       transportistasEtq: {},
             clientesEtq: {},
               catalogos: [],
                catalogo: { value: 0,label: "Seleccione un catalogo"},
               readyData: false
                 };
    this.plataformaCorreo=[];
        this.destinatario={
                            "nombre": "",
                            "idTipoConfiguracion": 0,
                            "estatus": true,
                            "idPlataforma": this.props.elementos.id
                          };
  this.editarDestinatario={
                            "idDestinatario": 0,
                            "nombre": "",
                            "idTipoConfiguracion": 0,
                            "estatus": true,
                            "idPlataforma": this.props.elementos.id
                          };
  this.catalogo=[];
  }
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if( stateVar === "destinatarios" ){
                if( returnVal[0] ){
                  this.setState({ destinatarios: returnVal });
                }
                this.setState({readyData: true});
              }
              if( stateVar === "destinatarios_tipo_configuracion" ){
                return returnVal;
              }
              if( stateVar === "catalogos_destinatarios" ){
                let catalogosTemp= [];
                catalogosTemp.push({ value: 0,label: "Seleccione un catalogo"});
                returnVal.map((cat, index)=>{
                  catalogosTemp.push({value: cat.idCatalogo, label: cat.nombre});
                });
                this.setState({catalogos: catalogosTemp});
              }
              if( stateVar === "destinatarios_catalogos" ){
                for(let item of this.state.catalogos){
                  if(item.value  == returnVal.idCatalogo){
                    this.catalogo= item;
                    this.setState({catalogo: item });
                    break;
                  }
                }
              }
            }
            break;
          case "post":
            {
              if( stateVar === "destinatarios" ){
                if(returnVal){
                  let destinatariosTem= this.state.destinatarios;
                  destinatariosTem.push(returnVal);
                  this.setState({ destinatarios: destinatariosTem });
                  NotificationManager.info("Se agrego nuevo destinatario("+returnVal.nombre+")", "Guardado");
                  this.setState({nombre: "" });
                  this.destinatario.nombre= "";
                  this.setState({idTipoConfiguracion: {label: "Seleccione un a opcción", value: 0 } });
                  this.destinatario.idTipoConfiguracion= 0;
                }
              }
              if( stateVar === "destinatarios_catalogos" ){
                for(let item of this.state.catalogos){
                  if(item.value  == returnVal.idCatalogo){
                    this.catalogo= item;
                    this.setState({catalogo: item });
                    NotificationManager.info("Se modifico destinatario con ("+item.label+")", "Guardado");
                    break;
                  }
                }
              }
            }
            break;
          case "put":
            {
              if( stateVar === "destinatarios" ){
                if(returnVal){
                  let destinatariosTem= this.state.destinatarios;
                  destinatariosTem.map((item, index)=>{
                    if( item.idDestinatario === returnVal.idDestinatario){
                      item= returnVal;
                      destinatariosTem[index]= returnVal;
                    }
                  });
                  this.hiddenmodal();
                  this.setState({ destinatarios: destinatariosTem });
                  NotificationManager.info("Se modifico destinatario("+returnVal.nombre+")", "Actualización");
                  this.hiddenmodal();
                }
              }
            }
            break;
          case "delete":
            {
              if( stateVar === "destinatarios_catalogos" ){

              }

              if(stateVar =="etiquetado_masivo_transportistas"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "transportistasEtiquetas/contar/"+this.props.elementos.id,"contarTransportistas");
                NotificationManager.info("Se han removido las etiquetas de todos los contactos de transportistas en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
              }
              if(stateVar == "etiquetado_masivo_clientes"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "clientesEtiquetas/contar/"+this.props.elementos.id,"contarClientes");
                NotificationManager.info("Se han removido las etiquetas de todos los contactos de clientes en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
              }
            }
              break;
          default:
            break;
        }
      }).catch(err =>{ console.log(err); });
  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){

      this.getfuntion("get", "", "", "destinatarios_tipo_configuracion","destinatarios_tipo_configuracion").then((resp) => {
        let tipoconfTem= [];
        let tipoconfkeyTem= {};
        tipoconfTem.push({ value: 0, label: "Seleccione un a opcción" });
        for( let item of resp ){
          tipoconfkeyTem[item.id]= item;
          tipoconfTem.push({ value: item.id, label: item.nombre });
        }
        this.setState({ tipoConfiguracion: tipoconfTem });
        this.setState({ tipoConfiguracionKey: tipoconfkeyTem });
        this.getfuntion("get", "", "", "destinatarios/idplataforma/"+this.props.elementos.id,"destinatarios");
        this.getfuntion("get", "", "", "catalogos_destinatarios","catalogos_destinatarios");
      });

      //this.getfuntion("get", "", "", "transportistasEtiquetas/contar/"+this.props.elementos.id,"contarTransportistas");
      //this.getfuntion("get", "", "", "clientesEtiquetas/contar/"+this.props.elementos.id,"contarClientes");
    }
  }
  crud_put =() =>{
    this.getfuntion("put", this.editarDestinatario, "", "destinatarios","destinatarios")
  }
  crud_post =() =>{
    this.getfuntion("post", this.destinatario, "", "destinatarios","destinatarios")
  }
  crud_put_catalogo =(destinatario) =>{
    if(this.catalogo.value){
      this.getfuntion("delete", "", destinatario.idDestinatario+"/"+this.catalogo.value, "destinatarios_catalogos", "destinatarios_catalogos")
    }
    this.getfuntion("post", {idDestinatario: destinatario.idDestinatario, idCatalogo: this.state.catalogo.value }, "", "destinatarios_catalogos", "destinatarios_catalogos");
    //alert("catalogo");
  }
  etiquetadoMasivo=(destinatario, etiquetaObj)=>{
    switch (destinatario) {
      case "clientes":{//mas adelante qui se validara la configuracion
        this.getfuntion("post", etiquetaObj, "", "personasEtiquetas/etiquetado_masivo_clientes/plataforma/"+this.props.elementos.id +"/etiqueta/"+etiquetaObj.id,"etiquetado_masivo_clientes");
       }break;
      case "transportistas":{
        this.getfuntion("post", etiquetaObj, "", "personasEtiquetas/etiquetado_masivo_transportistas/plataforma/"+this.props.elementos.id +"/etiqueta/"+etiquetaObj.id,"etiquetado_masivo_transportistas");
       }break;

      default:
        break;
    }
  }
  etiquetadoMasivoRemover=(destinatario, etiquetaObj)=>{
    switch (destinatario) {
      case "clientes":{//mas adelante qui se validara la configuracion
        this.getfuntion("delete", etiquetaObj, this.props.elementos.id +"/"+etiquetaObj.id, "personasEtiquetas/etiquetado_masivo_clientes","etiquetado_masivo_clientes");
       }break;
      case "transportistas":{
        this.getfuntion("delete", etiquetaObj, this.props.elementos.id +"/"+etiquetaObj.id, "personasEtiquetas/etiquetado_masivo_transportistas", "etiquetado_masivo_transportistas");
       }break;

      default:
        break;
    }
  }
  changeNombre=(e)=>{
    this.setState({nombre: e.target.value});
    this.destinatario.nombre= e.target.value;
  }
  changeTipoConfiguracion=(e)=>{
    this.setState({idTipoConfiguracion: e });
    this.destinatario.idTipoConfiguracion= e.value;
  }
  changeCatalogo=(e)=>{
    this.setState({catalogo: e });
  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  }
  selectDestino =(indice)=>{
    var destinoTemp= this.state.destinatarios.filter( word => word.nombre.includes(this.state.search.toUpperCase()) ).sort()[indice];
    this.setState({editarNombre: destinoTemp.nombre});
    this.setState({editaridTipoConfigurac: {value: destinoTemp.idTipoConfiguracion, label: this.state.tipoConfiguracionKey[destinoTemp.idTipoConfiguracion].nombre } });
    this.setState({ editarEstatus: destinoTemp.estatus });
    this.editarDestinatario.idDestinatario=       destinoTemp.idDestinatario;
    this.editarDestinatario.nombre=               destinoTemp.nombre;
    this.editarDestinatario.idTipoConfiguracion=  destinoTemp.idTipoConfiguracion;
    this.editarDestinatario.estatus=              destinoTemp.estatus;
    this.editarDestinatario.idPlataforma=         this.props.elementos.id;
    this.getfuntion("get", "", "", "destinatarios_catalogos/iddestinatario/"+destinoTemp.idDestinatario,"destinatarios_catalogos");
  }
  /**
   * Editar
   */
  changeENombre=(e)=>{
    this.setState({editarNombre: e.target.value});
    this.editarDestinatario.nombre= e.target.value;
  }
  changeETipoConfiguracion=(e)=>{
    this.setState({editaridTipoConfigurac: e });
    this.editarDestinatario.idTipoConfiguracion= e.value;
  }
  changeEstatus=(e)=>{
    this.setState({ editarEstatus: e.target.checked });
    this.editarDestinatario.estatus= e.target.checked;
  }
  render(){
    return (
      <div>
        {/**
          *   Configuracion de correo
          */
        }
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Catálogo de destinatarios que reciben notificaciones de incidencias."
                  isCollapsible={ false }
                  //isClosable
                  isCollapsed={ false}
                  //options={ }
                  body={
                    <div>
                      <Grid.Row>
                        <Grid.Col sm={12} md={5}>
                          <Form.Group label="Nombre">
                            <Form.Input type="text" name="nombre" value={this.state.nombre} placeholder="Nombre del destinatario..."  onChange={this.changeNombre} />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={12} md={5}>
                          <Form.Group label="Tipo de configuración">
                            <Select value={this.state.idTipoConfiguracion} onChange={this.changeTipoConfiguracion} options={this.state.tipoConfiguracion} />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={12} md={2}>
                          <Button size="sm" RootComponent="a" color="primary" disabled= { this.state.nombre.length < 3 || this.state.idTipoConfiguracion.value === 0 } onClick= { () => this.crud_post() } >
                              <span className="badge"><Icon link={true} name="save" />Guardar</span>
                          </Button>
                        </Grid.Col>
                      </Grid.Row>
                      <Dimmer active={!this.state.readyData} loader>
                      <Table cards={true} striped={true} responsive={true} className="table-vcenter"
                             headerItems={[{ content: "id"}, { content: "nombre"}, { content: "tipoconfiguración"}, { content: "estatus"}, { content: "editar"}, { content: "configurar"}]} >
                        <Table.Body>

                            {
                              this.state.destinatarios.filter( word => word.nombre.includes(this.state.search.toUpperCase()) ).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento.idDestinatario}</Table.Col>
                                  <Table.Col>{elemento.nombre}</Table.Col>
                                  <Table.Col>{this.state.tipoConfiguracionKey[elemento.idTipoConfiguracion]? this.state.tipoConfiguracionKey[elemento.idTipoConfiguracion].nombre:"loading .." }</Table.Col>
                                  <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1" checked={ elemento.estatus } className={"disabled"} /></Table.Col>
                                  <Table.Col>
                                    <ModalLayout  tamanio = {"80%"} title= {elemento.nombre}
                                                  name= { <Button pill size="sm" outline color="primary" onClick={()=> this.selectDestino(index) }><Icon name="edit"/></Button>}
                                                  hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                                      formulario= {
                                                        <Grid.Row>
                                                          <Grid.Col sm={12} md={4}>
                                                            <Form.Group label="Id">
                                                              <h4>{elemento.idDestinatario}</h4>
                                                            </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col sm={12} md={4}>
                                                            <Form.Group label="Nombre">
                                                              <Form.Input type="text" name="nombre" value={this.state.editarNombre} placeholder="Nombre del destinatario..."  onChange={this.changeENombre} />
                                                            </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col sm={12} md={4}>
                                                            <Form.Group label="Tipo de configuración">
                                                              <Select value={this.state.editaridTipoConfigurac} onChange={this.changeETipoConfiguracion} options={this.state.tipoConfiguracion} />
                                                            </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col sm={12} md={4}>
                                                          <Form.Group label="Estatus">
                                                            <Form.Switch type="Checkbox" name="editarEstatus" value="option1" checked={ this.state.editarEstatus } onChange={this.changeEstatus} />
                                                          </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col>
                                                            <span className="badge float-right">
                                                              <Button size="sm" RootComponent="a" color="primary" disabled= { this.state.editarNombre.length < 3 || this.state.editaridTipoConfigurac.value === 0 }
                                                                      onClick= { () => this.crud_put() } >     <span className="badge"><Icon link={true} name="save" />Guardar</span>
                                                              </Button>
                                                            </span>
                                                          </Grid.Col>
                                                        </Grid.Row>
                                                      }
                                    />
                                  </Table.Col>
                                  <Table.Col>{/**Configuracion manual */}
                                    { this.state.tipoConfiguracionKey[elemento.idTipoConfiguracion]? this.state.tipoConfiguracionKey[elemento.idTipoConfiguracion].nombre == "manual"?
                                        <ModalLayout  tamanio = {"100%"} title={ elemento.nombre }
                                                      name= { <Button pill size="sm" outline color="primary" onClick={()=> this.selectDestino(index) }><Icon name="settings"/></Button>}
                                                      hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                                          formulario= {
                                                            <DestinatariosPersonas elementos={ this.props.elementos } stor= { this.props.stor } destinatario={elemento}/>
                                                          }
                                        />
                                      :null:null
                                    }{/**Configuracion Catalogo */}
                                    { this.state.tipoConfiguracionKey[elemento.idTipoConfiguracion]? this.state.tipoConfiguracionKey[elemento.idTipoConfiguracion].nombre == "catalogo"?
                                        <ModalLayout  tamanio = {"30%"} title={ elemento.nombre }
                                                      name= { <Button pill size="sm" outline color="primary" onClick={()=> this.selectDestino(index) }><Icon name="settings"/></Button>}
                                                      hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                                          formulario= {
                                                            <div>
                                                              <Form.Group label="Catalogo">
                                                                <Select value={this.state.catalogo} onChange={this.changeCatalogo} options={this.state.catalogos} />
                                                              </Form.Group>
                                                              <span className="badge float-right">
                                                                <Button size="sm" RootComponent="a" color="primary" disabled= { this.state.catalogos.value === 0 }
                                                                        onClick= { () => this.crud_put_catalogo(elemento) } >     <span className="badge"><Icon link={true} name="save" />Guardar</span>
                                                                </Button>
                                                              </span>
                                                            </div>
                                                          }
                                        />
                                      :null:null
                                    }{/** Configuracion Destinatarios Especiales */}
                                    { this.state.tipoConfiguracionKey[elemento.idTipoConfiguracion]? this.state.tipoConfiguracionKey[elemento.idTipoConfiguracion].nombre == "especial"?
                                        <ModalLayout  tamanio = {"100%"} title={ elemento.nombre }
                                                      name= { <Button pill size="sm" outline color="primary" onClick={()=> this.selectDestino(index) }><Icon name="settings"/></Button>}
                                                      hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                                          formulario= {
                                                            <DestinatariosEspeciales elementos={ this.props.elementos } stor= { this.props.stor } destinatario={elemento}/>
                                                          }
                                        />
                                      :null:null
                                    }
                                  </Table.Col>
                                </Table.Row>
                              )
                            }
                            {this.state.destinatarios.length === 0?<h3>Aún no hay destinatarios</h3>:null}

                        </Table.Body>
                      </Table>
                    </Dimmer>
                    </div>
                }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default Destinatarios
