import * as conf from "../../config/config";
import axios from 'axios';
//import $ from "jquery";
var user= JSON.parse(sessionStorage.getItem("usuario"));
var movimientos= {};
var cat= {};
export function AlertasT(datos){
    var tokenStore="tok";
      let instance = axios.create();
      instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
      movimientos= {};
      if(datos){
        datos.map((mov, index) =>{
          if(movimientos[mov.id]){
            movimientos[mov.id]= mov;
          }else{
            movimientos[mov.id]= [];
            movimientos[mov.id]= mov;
          }
        });
      }

      return axios.get(conf.api_raiz+"plataformasAlertas/usuario/"+user.id).then(categorias => {
          if( categorias.data ){
            cat= {};
            categorias.data.map((c, index) =>{
              if( c.alertaCategoria ){
                if( cat[c.plataforma] ){
                  if( cat[c.plataforma][c.alertaTipo.id] ){
                    cat[c.plataforma][c.alertaTipo.id]= c;
                  }else{ cat[c.plataforma][c.alertaTipo.id]= c; }
                }else{
                  cat[c.plataforma]={};
                  cat[c.plataforma][c.alertaTipo.id]= c;
                }
              }
            });
          }
          return axios.get(conf.api_raiz+"terrestresAlertas/usuario/"+user.id).then(res => {
            if(res.data){
              var alertas= {};
              var alertasCat= {};
              var alertasCorredores= {};
              res.data.map((alerta, index) =>{
                if(alertas[alerta.idMovimiento]){
                  if( movimientos[alerta.idMovimiento] ){
                    if( movimientos[alerta.idMovimiento].plataforma ){
                      if( cat[movimientos[alerta.idMovimiento].plataforma.id] ){
                        if(alerta.idAlertaTipo){
                          if(cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo ] ){
                            if( cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ){
                              if( alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo] ){
                                alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo].push({
                                  alert: alerta,
                                  color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                  tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                  icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                                  tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                                });
                              }else{
                                alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo]= [];
                                alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo].push({
                                  alert: alerta,
                                  color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                  tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                  icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                                  tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                                });
                              }//categoria
                              if( !alertasCat[cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre] ){
                                alertasCat[cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre]= [];
                              }
                              alertasCat[cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre].push({
                                alert: alerta, 
                                color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                logo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                                tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                              });
                              //corredores                              
                              if( !alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]){
                                alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]= [];
                              }
                              alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre].push({
                                alert: alerta, 
                                color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                logo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                                tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                              });
                            }
                          }else{
                            if(!alertas[alerta.idMovimiento]["fa-bell"] ){
                              alertas[alerta.idMovimiento]= {};
                              alertas[alerta.idMovimiento]["fa-bell"]= [];
                            }
                            alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell", tiempo: '00:00:00' });
                            //categorias
                            if( !alertasCat["Desconocido"] ){
                              alertasCat["Desconocido"]= [];
                            }
                            alertasCat["Desconocido"].push({alert: alerta, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                            //corredores
                            if( !alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]){
                              alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]= [];
                            }
                            alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre].push({alert: alerta, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                          }
                        }
                      }else{
                        if(!alertas[alerta.idMovimiento]["fa-bell"] ){
                              alertas[alerta.idMovimiento]= {};
                              alertas[alerta.idMovimiento]["fa-bell"]= [];
                        }
                        alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell", tiempo: '00:00:00' });
                        //categorias
                        if( !alertasCat["Desconocido"] ){
                          alertasCat["Desconocido"]= [];
                        }
                        alertasCat["Desconocido"].push({alert: alerta, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                        //corredores
                        if( !alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]){
                          alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]= [];
                        }
                        alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre].push({alert: alerta, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                      }
                    }
                  }
                }else{
                  if( movimientos[alerta.idMovimiento] ){
                    if( movimientos[alerta.idMovimiento].plataforma ){
                      if( cat[movimientos[alerta.idMovimiento].plataforma.id] ){
                          if(cat[movimientos[alerta.idMovimiento].plataforma.id][alerta.idAlertaTipo] ){
                            alertas[alerta.idMovimiento]= {};
                            alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ]= [];
                            alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ].push({
                              alert: alerta,
                              color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                              tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                              icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                              tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                            });
                            //categorias
                            if( !alertasCat[cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre] ){
                              alertasCat[cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre]= [];
                            }
                            alertasCat[cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre].push({
                              alert: alerta, 
                              color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                              logo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                              tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                              });
                            //corredores                              
                            if( !alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]){
                              alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]= [];
                            }
                            alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre].push({
                              alert: alerta, 
                              color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                              tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                              logo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                              tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                            });
                          }else{   // nothing
                            alertas[alerta.idMovimiento]= {};
                            alertas[alerta.idMovimiento]["fa-bell"]= [];
                            alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell", tiempo: '00:00:00' });
                            //categorias
                            if( !alertasCat["Desconocido"] ){
                              alertasCat["Desconocido"]= [];
                            }
                            alertasCat["Desconocido"].push({alert: alerta, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                            //corredores
                            if( !alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]){
                              alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]= [];
                            }
                            alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre].push({alert: alerta, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                          }
                      }else{
                        alertas[alerta.idMovimiento]= {};
                        alertas[alerta.idMovimiento]["fa-bell"]= [];
                        alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell",tiempo: '00:00:00' });
                        //categorias
                        if( !alertasCat["Desconocido"] ){
                          alertasCat["Desconocido"]= [];
                        }
                        alertasCat["Desconocido"].push({alert: alerta, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                        //corredores
                        if( !alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]){
                          alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre]= [];
                        }
                        alertasCorredores[movimientos[alerta.idMovimiento].corredor.nombre].push({alert: alerta, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                      }
                    }
                  }   /*fin*/
                }
              });
              //sessionStorage.setItem("AlertasT", JSON.stringify(alertas));
              //console.log(alertas);
              return { alertas: alertas, alertasCat: alertasCat, alertasCorredores: alertasCorredores };
            }
          }).catch(errors => console.log(errors));
        }).catch(errors => console.log(errors));

}

  export default AlertasT;
