import React, { Component } from "react";
import {
  Button,
  Form,
  Icon,
  Grid,
  Table,
  Card,
  Dimmer,
  List,
  Alert,
} from "tabler-react";
import { Crud_Catalogos, Crud_error } from "../../herramientas/Crud_Catalogos";
import { confirmAlert } from "react-confirm-alert";
import BloqueForm from "..//..//Catalogos/Bloques/BloqueForm";
import ModalLayout from "../../components/ModalLayout.react";
import TabsLayout from "../../components/TabsLayout.react";
import ModalLay from "../../components/ModalLay/ModalLay";
/** Arrobas */
import {
  Arroba,
  ArrobaIncidencia,
  ArrobaTerrestre,
  ArrobaIncidenciaTerrestre,
} from "./Arroba";
import Reaccion from "./panelForms/Reaccion";
/** alertas */
import { NotificationManager } from "react-notifications";
import ReaccionTerrestre from "./panelForms/ReaccionTerrestre";
/** Formularios */
import MovimientoIncidencias from "./MovimientoIncidencias";
import {
  Transportista,
  Cliente,
  Cita,
  Actividad,
  Entregas,
  AlertaNForm,
  AlertaTForm,
  AlertaNListar,
  IncidenciasNListar,
  ActualizarHorariosNavieros,
  ActualizarHorariosTerrestres,
  ReactivarMovimientosTerrestre,
  BitacoraMov,
  MovimientoUnidad,
  MovimientoOperador,
  BitacoraPdf,
} from "./panelForms";
import MovimientoIncidenciasTerrestre from "./MovimientoIncidenciasTerrestre";
import IncidenciasTraxion from "./IncidenciasTraxion";
import Exportar from "../../herramientas/Excel/Exportar";
import IncidenciasTraxionExportar from "./IncidenciasTraxionExportar";
import Temporizador from "../../components/Temporizador/Temporizador";
import Checklist  from "./panelForms/Checklist/Checklist";
const url = new URL(window.location.href);
const AnyReactComponent = ({ text }) => <div>{text}</div>;

//Formato de Fecha
function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}
function myFormato(date) {
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}

/**
 * usuario
 */
var user = JSON.parse(sessionStorage.getItem("usuario"));
//import axios from 'axios';

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detalles: (
        <div>
          <h4 className="mt-2">No hay detalles ..</h4>{" "}
        </div>
      ),
      incidencias: [],
      incidenciasAll: [],
      hiddenModals: false,
      movimientoGps: [],
      alertasN: this.props.alertasN ? this.props.alertasN : [],
      alertasT: this.props.alertasT,
      alertasTSinAtender: this.props.alertasTSinAtender,
      idMovimiento: 0,
      movimiento: this.props.getMovimiento,
      currentTap: 0,
      searchAlertas: "",
      searchIncidencias: "",
      detallesCollase: true,
      entregasCollapse: false,
      alertasCollase: true,
      incidenciasCollase: true,
      entregas: [],
      unidad: [],
      incidenciasTerrestre: [],
      incidenTerrestre: [],
      idEntrega: null,
      idEntregaActiva: 0,
      selectedRow: -1,
      destinatarios: {
        destinatarios3: "",
        destinatarios4: "",
        destinatarios5: "",
        destinatarios6: "",
      },
      movimientoFull: null,
      incidenciasTraxionExcel: [],
    };
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
    };
    this.incidencias = [];
    this.incidenciasTerrestre = [];
    this.alertT = [];
    this.columna = [];
    this.privilegio = [];
    this.plataformaT = 0;
    this.currentMovimientoN = [];
    this.currentMovimientoT = [];
    this.camposLogisticosNav = [];
  }
  componentWillMount = () => {};
  componentDidMount = () => {
    if (this.props.plataformaTipo === "Naviera") {
      if (this.props.movimiento) {
        this.setState({
          movimientoGps: this.props.getMovimiento.movimientoGps,
        });
      }
    }
    if (this.props.plataformaTipo === "Terrestre") {
      if (this.props.movimiento) {
        this.setState({
          movimientoGps: this.props.getMovimiento.movimientoGps,
        });
      }
    }
  };
  cargarDetalles = () => {
    let detalles = [];
    if (this.getFuncionalidad("Transportista")) {
      detalles.push({
        title: "Trans",
        icon: "truck",
        panel: (
          <Transportista
            obj={this.props.getMovimiento.idTransportista}
            mov={this.props.getMovimiento}
          />
        ),
        close: false,
      });
    }
    if (this.getFuncionalidad("Cliente")) {
      detalles.push({
        title: "Clien",
        icon: "user",
        panel: <Cliente obj={this.props.getMovimiento.idCliente} />,
        close: false,
      });
    }
    if (this.getFuncionalidad("Detalles")) {
      detalles.push({
        title: "Detalles",
        icon: "watch",
        panel: (
          <Cita
            obj={this.props.getMovimiento}
            camposLogisticosNav={this.camposLogisticosNav}
          />
        ),
        close: false,
      });
    }
    if (this.getFuncionalidad("Actividad")) {
      detalles.push({
        title: "Act",
        icon: "activity",
        panel: (
          <Actividad
            obj={this.props.getMovimiento}
            gpsEstatusNavieros={this.props.gpsEstatusNavieros}
            plataformaTipo={this.props.plataformaTipo}
            estaPrivilegioActivo={this.estaPrivilegioActivo}
            gps={
              this.props.getMovimiento.movimientoGps[0]
                ? this.props.getMovimiento.movimientoGps
                : this.props.MovimientosFull[this.props.getMovimiento.id]
                ? this.props.getMovimientoNavieroById(
                    this.props.MovimientosFull[this.props.getMovimiento.id].full
                  )
                  ? this.props.getMovimientoNavieroById(
                      this.props.MovimientosFull[this.props.getMovimiento.id]
                        .full
                    ).movimientoGps
                  : []
                : []
            }
            abrirmodalGps={
              this.getFuncionalidad("Rastreame") ? this.abrirmodalGps : null
            }
          />
        ),
        close: false,
      });
    }
    this.setState({
      detalles: (
        <TabsLayout
          tabs={detalles}
          selectedTab={this.state.currentTap}
          selectTab={this.selectedTab}
        />
      ),
    });
  };

  componentDidUpdate = () => {
    if (this.props.plataformaTipo === "Naviera") {
      if (
        this.props.getMovimiento.id &&
        this.currentMovimientoN !== this.props.getMovimiento &&
        this.props.plataformaTipo === "Naviera"
      ) {
        //CargaCamposLogisticos
        let movTipoTemp = this.props.getMovimiento.idMovimientoTipo.id;
        if (movTipoTemp === 3) {
          movTipoTemp = 1;
        }
        if (movTipoTemp === 4) {
          movTipoTemp = 2;
        }
        this.getfuntion(
          "get",
          "",
          "",
          "camposLogisticos/plataforma/" +
            this.props.getMovimiento.idPlataforma.id +
            "/mtipo/" +
            movTipoTemp,
          "camposLogisticos"
        ).then((returnVal) => {
          this.camposLogisticosNav = returnVal;
          this.cargarDetalles();
        });

        this.currentMovimientoN = this.props.getMovimiento;
        this.setState({ movimiento: this.props.getMovimiento });
        this.setState({
          destinatarios: {
            destinatarios3: "",
            destinatarios4: "",
            destinatarios5: "",
            destinatarios6: "",
          },
        });
        this.setState({ idMovimiento: this.props.movimiento });
        this.setState({
          detalles: (
            <div>
              <h4 className="mt-2">Cargando ..</h4>{" "}
              <Dimmer active={true} loader></Dimmer>{" "}
            </div>
          ),
        });
        this.setState({
          movimientoGps: this.props.getMovimiento.movimientoGps,
        });

        this.getIncidencias();
        //this.incidencias = this.props.getMovimiento.incidencias;
        //this.setState({ incidencias: this.props.getMovimiento.incidencias });

        if (this.estaPrivilegioActivo("arroba_azul")) {
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_clientes/" +
              this.props.getMovimiento.id +
              "/3",
            "destinatarios3"
          );
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_transportistas/" +
              this.props.getMovimiento.id +
              "/3",
            "destinatarios3"
          );
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_sky_naviera/" +
              this.props.getMovimiento.id +
              "/3",
            "destinatarios3"
          );
        }
        if (this.estaPrivilegioActivo("arroba_roja")) {
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_clientes/" +
              this.props.getMovimiento.id +
              "/4",
            "destinatarios4"
          );
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_transportistas/" +
              this.props.getMovimiento.id +
              "/4",
            "destinatarios4"
          );
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_sky_naviera/" +
              this.props.getMovimiento.id +
              "/4",
            "destinatarios4"
          );
        }
        if (this.estaPrivilegioActivo("arroba_negra")) {
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_clientes/" +
              this.props.getMovimiento.id +
              "/5",
            "destinatarios5"
          );
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_transportistas/" +
              this.props.getMovimiento.id +
              "/5",
            "destinatarios5"
          );
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_sky_naviera/" +
              this.props.getMovimiento.id +
              "/5",
            "destinatarios5"
          );
        }
        if (this.estaPrivilegioActivo("arroba_amarilla")) {
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_clientes/" +
              this.props.getMovimiento.id +
              "/6",
            "destinatarios6"
          );
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_transportistas/" +
              this.props.getMovimiento.id +
              "/6",
            "destinatarios6"
          );
          this.getfuntion(
            "get",
            "",
            "",
            "personas/destinatarios_sky_naviera/" +
              this.props.getMovimiento.id +
              "/6",
            "destinatarios6"
          );
        }

        this.setState({ movimientoFull: null });

        Object.keys(this.props.MovimientosFull).forEach((key, index) => {
          // key == mov full
          if (this.props.getMovimiento.id == key) {
            this.setState({
              movimientoFull: this.props.MovimientosFull[key].full,
            });
          }
          if (
            this.props.MovimientosFull[key].full == this.props.getMovimiento.id
          ) {
            this.setState({ movimientoFull: key });
          }
        });

        this.getfuntion("get", [], "", "incidencias", "incidenciasAll");
      }
    }

    if (this.props.plataformaTipo === "Terrestre") {
      if (
        this.state.idMovimiento !== this.props.movimiento &&
        this.currentMovimientoT !== this.props.getMovimiento
      ) {
        this.currentMovimientoT = this.props.getMovimiento;
        this.setState({
          movimientoGps: this.props.getMovimiento.movimientoGps,
        });
        this.setState({ idMovimiento: this.props.movimiento });
        this.setState({ incidenciasTerrestre: [] });
        if (
          this.props.getMovimiento.plataforma.nombre
            .toUpperCase()
            .includes("TRAXION")
        ) {
          this.getfuntion(
            "get",
            "",
            "",
            "incidencias_traxion/movimiento/" + this.props.getMovimiento.id,
            "incidencias_traxion"
          );
        } else {
          this.getfuntion(
            "get",
            "",
            "",
            "terrestreEntregasIncidencias/movimiento/" +
              this.props.getMovimiento.id,
            "incidenciasTerrestre"
          );
        }
        //this.getfuntion("get", [], "", "movimientoGps/movimiento/" + this.props.movimiento, "movimientoGps");
        if (this.props.movimiento) {
          //this.currentMovimientoN= this.props.getMovimiento;
          this.setState({
            destinatarios: {
              destinatarios3: "",
              destinatarios4: "",
              destinatarios5: "",
              destinatarios6: "",
            },
          });
          if (this.props.plataformaTipo === "Terrestre") {
            this.getfuntion("get", [], "", "incidencias", "incidenciasAll");
            let detalles = [];
            this.setState({ detalles: <div>Cargando ··</div> });
            if (this.getFuncionalidad("Transportista")) {
              detalles.push({
                title: "Trans",
                icon: "truck",
                panel: (
                  <Transportista
                    obj={this.props.getMovimiento.transportista}
                    mov={this.props.getMovimiento}
                  />
                ),
                close: false,
              });
            }
            this.getfuntion(
              "get",
              "",
              "",
              "entregas/movimiento/" + this.props.getMovimiento.id,
              "entregas"
            ).then((returnVal) => {
              if (this.getFuncionalidad("Entregas")) {
                detalles.push({
                  title: "Entregas",
                  icon: "watch",
                  panel: (
                    <Entregas
                      obj={this.props.getMovimiento}
                      setIdEntregaActiva={this.setIdEntregaActiva}
                      entregas={returnVal}
                    />
                  ),
                  close: false,
                });
              }

              this.setState({
                detalles: (
                  <TabsLayout
                    tabs={detalles}
                    selectedTab={this.state.currentTap}
                    selectTab={this.selectedTab}
                  />
                ),
              });
            });
            //unidad ter
            this.getfuntion(
              "get",
              "",
              "",
              "terrestreMovimientoUnidad/movimiento/" +
                this.props.getMovimiento.id,
              "terrestreMovimientoUnidad"
            ).then((returnVal) => {
              this.setState({ unidad: returnVal });
            });
            if (this.getFuncionalidad("Actividad")) {
              detalles.push({
                title: "Checklist",
                icon: "list",
                panel: <Checklist movementId={this.props.movimiento} />,
                close: false,
              });
              detalles.push({
                title: "Actividad",
                icon: "activity",
                panel: (
                  <Actividad
                    obj={this.props.getMovimiento} //obj={this.state.movimiento}
                    //gpsEstatusNavieros={this.props.gpsEstatusNavieros}
                    gpsEstatusTerrestres={this.props.gpsEstatusTerrestres}
                    plataformaTipo={this.props.plataformaTipo}
                    gps={
                      this.props.getMovimiento.movimientoGps
                        ? this.props.getMovimiento.movimientoGps[0]
                          ? this.props.getMovimiento.movimientoGps
                          : []
                        : []
                    }
                    abrirmodalGps={
                      this.getFuncionalidad("Rastreame")
                        ? this.abrirmodalGps
                        : null
                    }
                  />
                ),
                close: false,
              });
            }

            this.setState({ idEntregaActiva: null });
            //Se obtienen  destinatarios por id mov
            if (this.estaPrivilegioActivo("arroba_azul")) {
              this.getfuntion(
                "get",
                "",
                "",
                "personas/destinatarios_transportistas_ter/" +
                  this.props.getMovimiento.id +
                  "/3",
                "destinatarios3"
              );
              this.getfuntion(
                "get",
                "",
                "",
                "personas/destinatarios_sky_terrestre/" +
                  this.props.getMovimiento.id +
                  "/3",
                "destinatarios3"
              );
            }
            if (this.estaPrivilegioActivo("arroba_roja")) {
              this.getfuntion(
                "get",
                "",
                "",
                "personas/destinatarios_transportistas_ter/" +
                  this.props.getMovimiento.id +
                  "/4",
                "destinatarios4"
              );
              this.getfuntion(
                "get",
                "",
                "",
                "personas/destinatarios_sky_terrestre/" +
                  this.props.getMovimiento.id +
                  "/4",
                "destinatarios4"
              );
            }
            if (this.estaPrivilegioActivo("arroba_negra")) {
              this.getfuntion(
                "get",
                "",
                "",
                "personas/destinatarios_transportistas_ter/" +
                  this.props.getMovimiento.id +
                  "/5",
                "destinatarios5"
              );
              this.getfuntion(
                "get",
                "",
                "",
                "personas/destinatarios_sky_terrestre/" +
                  this.props.getMovimiento.id +
                  "/5",
                "destinatarios5"
              );
            }
            if (this.estaPrivilegioActivo("arroba_amarilla")) {
              this.getfuntion(
                "get",
                "",
                "",
                "personas/destinatarios_transportistas_ter/" +
                  this.props.getMovimiento.id +
                  "/6",
                "destinatarios6"
              );
              this.getfuntion(
                "get",
                "",
                "",
                "personas/destinatarios_sky_terrestre/" +
                  this.props.getMovimiento.id +
                  "/6",
                "destinatarios6"
              );
            }
          }
        }
      }
    }

    if (this.state.alertasN !== this.props.alertasN) {
      if (this.props.alertasN) {
        this.setState({ alertasN: this.props.alertasN });
      }
    }
    if (this.state.alertasT !== this.props.alertasT) {
      this.setState({ alertasT: this.props.alertasT });
    }
  };

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          this.privilegio = privilegios[i];
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };
  privilegioDescripcion = (clave) => {
    var privilegios = this.props.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          return privilegios[i].descripcion;
        } else {
          return "No identificado ..!";
        }
        break;
      }
    }
  };

  /**
   * Id entregaActiva
   */
  setIdEntregaActiva = (entreAct) => {
    this.setState({ idEntregaActiva: entreAct });
    /**
     *  get incidencias by idMovmiento
     */
    //this.getfuntion("get", "", "", "terrestreEntregasIncidencias/movimiento/" + entreAct.idMovimiento, "incidenciasTerrestre");
  };

  getIdEntregaActiva = () => {
    return this.state.idEntregaActiva;
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };
  getIncidencias = () => {
    Crud_Catalogos(
      "movimientoIncidencias/movimiento/" + this.props.movimiento,
      "movimientos",
      "get",
      "",
      [],
      "",
      this.state.incidencias,
      "",
      []
    )
      .then((returnVal) => {
        this.incidencias = returnVal;
        this.setState({ incidencias: returnVal });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        } else {
          /**
           * Error
           */
        }
      });
  };

  //stateVar: es el nombre de la variable del State que se va actualizar.
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = "",
    enviar
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        //console.log("353 Panel catalogo: " + catalogo + ", metodo:"+metodo+", stateVar:"+stateVar+ ", obj:"+obj);
        switch (metodo) {
          case "get":
            {
              if (stateVar === "camposLogisticos") {
                return returnVal;
              }
              if (stateVar === "terrestreMovimientoUnidad") {
                return returnVal;
              }
              if (stateVar === "incidenciasAll") {
                this.setState({ incidenciasAll: returnVal });
              }
              if (stateVar === "entregas") {
                if (returnVal) {
                  this.setState({ entregas: returnVal });
                }
                returnVal.map((entre, index) => {
                  //si quiero la EntregaActiva
                  if (entre.estatus == 1) {
                    this.setIdEntregaActiva(entre);
                  }
                  //Si Quiero Todas las Entregas
                  //this.setIdEntregaActiva(entre);
                });
                return returnVal;
              }
              if (stateVar === "incidenciasTerrestre") {
                this.incidenciasTerrestre = returnVal;
                this.setState({ incidenciasTerrestre: returnVal });
              }
              if (stateVar === "incidencias_traxion") {
                this.incidenciasTerrestre = returnVal;
                this.setState({ incidenciasTerrestre: returnVal });
                var dataTemp = [];
                returnVal.map((filas, index) => {
                  Object.keys(filas).forEach((key) => {
                    if (!dataTemp[index]) {
                      dataTemp[index] = {};
                    }
                    if (filas[key] !== null) {
                      if (typeof filas[key] === "object") {
                        //console.log(filas[key]+"=>> "+ key+"("+typeof filas[key]+")")
                        switch (key) {
                          case "monitorista":
                            dataTemp[index][key] =
                              "" +
                              filas[key].nombre +
                              " " +
                              filas[key].aPaterno +
                              " " +
                              filas[key].aMaterno;
                            break;
                          case "movimientoGps":
                            //filas[key].map((gps, indice)=> dataTemp[index]["gps"+indice+1]= gps.gps.nombre);
                            break;
                          default:
                            if (
                              filas[key].nombre != "" &&
                              filas[key].nombre != undefined
                            ) {
                              dataTemp[index][key] = filas[key].nombre;
                            } else {
                              if (
                                filas[key].id != "" &&
                                filas[key].id != undefined
                              ) {
                                dataTemp[index][key] = filas[key].id;
                              } else {
                                dataTemp[index][key] = "Vari(@)s ";
                              }
                            }
                        }
                      } else {
                        dataTemp[index][key] = filas[key];
                      }
                    } else {
                      if (key !== "movimientoGps") {
                        dataTemp[index][key] = "";
                      }
                    }
                  });
                });
                this.setState({ incidenciasTraxionExcel: dataTemp });
              }
              if (stateVar === "urlGps") {
                window.open(
                  returnVal.url,
                  "sharer",
                  "width=900,height=600,scrollbars=NO"
                );
                this.usuariosLogObject.idBitacoraMonitoreo = 5;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = this.props.movimiento;
                this.usuariosLogObject.idPlataformaTipo = this.plataformaT;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.descripcion =
                  "Rastreo de GPS: " + obj.nombre + "(" + obj.idGg + ")";
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject,
                  "",
                  "",
                  this.hiddenmodal,
                  ""
                );
              }
              if (stateVar === "destinatarios3" && returnVal) {
                let dest = this.state.destinatarios;
                returnVal.map((per, index) => {
                  dest.destinatarios3 = dest.destinatarios3 + per.correo + ", ";
                });
                this.setState({ destinatarios: dest });
              }
              if (stateVar === "destinatarios4" && returnVal) {
                let dest = this.state.destinatarios;
                returnVal.map((per, index) => {
                  dest.destinatarios4 = dest.destinatarios4 + per.correo + ", ";
                });
                this.setState({ destinatarios: dest });
              }
              if (stateVar === "destinatarios5" && returnVal) {
                let dest = this.state.destinatarios;
                returnVal.map((per, index) => {
                  dest.destinatarios5 = dest.destinatarios5 + per.correo + ", ";
                });
                this.setState({ destinatarios: dest });
              }
              if (stateVar === "destinatarios6" && returnVal) {
                let dest = this.state.destinatarios;
                returnVal.map((per, index) => {
                  dest.destinatarios6 = dest.destinatarios6 + per.correo + ", ";
                });
                this.setState({ destinatarios: dest });
              }
              if (stateVar === "movPatio") {
                return returnVal;
              }
            }
            break;
          case "post":
            {
              if (stateVar === "incidencias") {
                let destMonitoreo = "";
                let destTransportistas = "";
                let destClientes = "";
                obj.destinosMonitoreo.forEach((key, index) => {
                  destMonitoreo = destMonitoreo + ", " + key.correo;
                });
                obj.destinosTransportistas.forEach((key, index) => {
                  destTransportistas = destTransportistas + ", " + key.correo;
                });
                obj.destinosClientes.forEach((key, index) => {
                  destClientes = destClientes + ", " + key.correo;
                });
                //this.incidencias.unshift(returnVal);
                //this.setState({ incidencias: this.incidencias });
                //NotificationManager.info(returnVal.comentario, "Incidencia Nueva Naviera ("+returnVal.idMovimiento+")");
                this.usuariosLogObject.idBitacoraMonitoreo = 2;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = this.props.movimiento;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.descripcion =
                  returnVal.incidencia.nombre;
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject,
                  "",
                  "",
                  "",
                  ""
                );
                if (enviar && returnVal.id) {
                  ArrobaIncidencia(returnVal).then((arrobaInc) => {
                    NotificationManager.info(
                      arrobaInc,
                      this.privilegioDescripcion("arroba_incidencia")
                    );
                    this.usuariosLogObject.idBitacoraMonitoreo = 6;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = this.props.movimiento;
                    this.usuariosLogObject.idPlataformaTipo = 1;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    this.usuariosLogObject.descripcion =
                      "Notificación de incidencia(" +
                      returnVal.incidencia.nombre +
                      "): " +
                      destMonitoreo +
                      " " +
                      destTransportistas +
                      " " +
                      destClientes +
                      " " +
                      arrobaInc;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      "",
                      ""
                    );
                  });
                }
                if (
                  this.state.movimiento.actualizarFull &&
                  this.state.movimientoFull
                ) {
                  let incidenciaFull = {
                    atencionFecha: null,
                    atencionUsuario: obj.atencionUsuario,
                    comentario: obj.comentario,
                    fechaCreado: null,
                    idMovimiento: this.state.movimientoFull,
                    incidencia: obj.incidencia,
                    latitud: obj.latitud,
                    longitud: obj.longitud,
                  };
                  incidenciaFull = this.getfuntion(
                    "post",
                    incidenciaFull,
                    "",
                    "movimientoIncidencias",
                    "newIncidenciaFull",
                    "",
                    enviar
                  );
                }
              }
              if (stateVar === "newIncidenciaFull") {
                let destMonitoreo = "";
                let destTransportistas = "";
                let destClientes = "";
                obj.destinosMonitoreo.forEach((key, index) => {
                  destMonitoreo = destMonitoreo + ", " + key.correo;
                });
                obj.destinosTransportistas.forEach((key, index) => {
                  destTransportistas = destTransportistas + ", " + key.correo;
                });
                obj.destinosClientes.forEach((key, index) => {
                  destClientes = destClientes + ", " + key.correo;
                });
                NotificationManager.info(
                  returnVal.comentario,
                  "Nueva incidencia movFull (" + returnVal.idMovimiento + ")"
                );
                this.usuariosLogObject.idBitacoraMonitoreo = 2;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.descripcion =
                  returnVal.incidencia.nombre;
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject,
                  "",
                  "",
                  "",
                  ""
                );
                //Envia Arroba y Nueva Incidencia Naviero
                if (enviar && returnVal.id) {
                  ArrobaIncidencia(returnVal).then((arrobaInc) => {
                    NotificationManager.info(
                      arrobaInc,
                      this.privilegioDescripcion("arroba_incidencia")
                    );
                    this.usuariosLogObject.idBitacoraMonitoreo = 6;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = this.props.movimiento;
                    this.usuariosLogObject.idPlataformaTipo = 1;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    this.usuariosLogObject.descripcion =
                      "Notificación de incidencia(" +
                      returnVal.incidencia.nombre +
                      "): " +
                      destMonitoreo +
                      " " +
                      destTransportistas +
                      " " +
                      destClientes +
                      " " +
                      arrobaInc;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      "",
                      ""
                    );
                  });
                }
              }
            }
            break;
          case "put":
            {
              switch (catalogo) {
                case "navierasAlertas":
                  this.usuariosLogObject.idBitacoraMonitoreo = 4;
                  this.usuariosLogObject.idUsuario = user.id;
                  this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                  this.usuariosLogObject.descripcion = returnVal.causa;
                  this.usuariosLogObject.idPlataformaTipo = 1;
                  this.usuariosLogObject.accionFecha = myFormato(new Date());
                  this.props.setAlerta(returnVal, catalogo);
                  Crud_Catalogos(
                    "usuariosLog",
                    this.catalogo,
                    "post",
                    "",
                    this.usuariosLogObject,
                    "",
                    "",
                    this.hiddenmodal,
                    ""
                  );
                  break;
                case "terrestresAlertas":
                  this.usuariosLogObject.idBitacoraMonitoreo = 4;
                  this.usuariosLogObject.idUsuario = user.id;
                  this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                  this.usuariosLogObject.idPlataformaTipo = 2;
                  this.usuariosLogObject.accionFecha = myFormato(new Date());
                  this.usuariosLogObject.descripcion =
                    "Alerta Atendida id: " +
                    returnVal.id +
                    " de Tipo: " +
                    returnVal.triggerName +
                    " del Movimiento id: " +
                    returnVal.idMovimiento;
                  this.props.setAlerta(returnVal, "terrestresAlertas");
                  console.log("520 returnVal");
                  console.log(returnVal);
                  console.log("522 obj");
                  console.log(obj);

                  Crud_Catalogos(
                    "usuariosLog",
                    this.catalogo,
                    "post",
                    "",
                    this.usuariosLogObject,
                    "",
                    "",
                    this.hiddenmodal,
                    ""
                  );
                  break;
                default:
                  break;
              }

              switch (stateVar) {
                case "updateFechas":
                  {
                    this.hiddenmodal();
                    NotificationManager.info(
                      "Se han registrado horarios en campos logisticos",
                      "Actualizacion, mov (" + returnVal.id + ")"
                    );
                    let cambios = this.getCambiosNav(id, returnVal); //id= old Movimiento, retunVal= Actualizado // {bitacora: bitacora , camposmodificados: camposmodificados }
                    if (obj.actualizarFull) {
                      let mFull = this.props.MovimientosFull[
                        this.state.movimiento.id
                      ]
                        ? this.props.getMovimientoNavieroById(
                            this.state.movimientoFull
                          )
                        : this.props.MovimientosFull[this.state.movimientoFull]
                            .movimiento;
                      if (mFull.id) {
                        let campLogistObj = {
                          idOperacionEstatus: obj.idOperacionEstatus,
                          idRuta: obj.idRuta,
                          citaProgramada: obj.citaProgramada,
                          sello: mFull.sello,
                          po: mFull.po,
                          booking: mFull.booking,
                          contenedor: mFull.contenedor,
                          idOtd: obj.idOtd,
                          idCliente: obj.idCliente,
                          actualizarFull: obj.actualizarFull,
                          idMovimientoTipo: obj.idMovimientoTipo,
                          idOperacionTipo: obj.idOperacionTipo,
                          idRuta2: obj.idRuta2,
                          /*Exportacion*/
                          retiroContenedor: obj.retiroContenedor,
                          arriboClienteVacio: obj.arriboClienteVacio,
                          salidaClienteLleno: obj.salidaClienteLleno,
                          arriboTerminalCargado:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.arriboTerminalCargado
                              : mFull.arriboTerminalCargado, //
                          salidaPredioVacio: obj.salidaPredioVacio,
                          citaFechaDespacho: obj.citaFechaDespacho,
                          entradaCiudadPortuaria: obj.entradaCiudadPortuaria,
                          arriboPatioLinea:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.arriboPatioLinea
                              : mFull.arriboPatioLinea, //
                          salidaTerminalVacio:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.salidaTerminalVacio
                              : mFull.salidaTerminalVacio, //
                          /**Importacion */
                          arriboTerminalVacio:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.arriboTerminalVacio
                              : mFull.arriboTerminalVacio, //
                          salidaTerminalLleno:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.salidaTerminalLleno
                              : mFull.salidaTerminalLleno, //
                          arriboClienteLleno: obj.arriboClienteLleno,
                          salidaClienteVacio: obj.salidaClienteVacio,
                          arriboTerminalVacio2: obj.arriboTerminalVacio2,
                          asignacionPiloto: obj.asignacionPiloto,
                          inicioRuta: obj.inicioRuta,
                          despachoPredioCliente: obj.despachoPredioCliente,
                          llegadaFrontera: obj.llegadaFrontera,
                          salidaFrontera: obj.salidaFrontera,
                          arriboAduana: obj.arriboAduana,
                          salidaAduana: obj.salidaAduana,
                        };
                        this.getfuntion(
                          "put",
                          campLogistObj,
                          mFull,
                          "navieraMovimientos/updateFechas/" + mFull.id,
                          "updateFechasFull"
                        );
                      }
                    }
                    this.usuariosLogObject.idBitacoraMonitoreo =
                      cambios.bitacora;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = returnVal.id;
                    this.usuariosLogObject.idPlataformaTipo = 1;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    //this.usuariosLogObject.descripcion = id;//en id vienen los campos modificados
                    //Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject);
                    this.usuariosLogObject.descripcion =
                      cambios.camposmodificados; //en id vienen los campos modificados
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject
                    );
                  }
                  break;
                case "terrestreMovimientos":
                  {
                    NotificationManager.info(
                      "Se Terminara el movimiento",
                      "Actualizacion, mov (" +
                        returnVal.id +
                        ") por Usuario: " +
                        user.id
                    );
                    this.usuariosLogObject.idBitacoraMonitoreo = 5;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = returnVal.id;
                    this.usuariosLogObject.idPlataformaTipo = 2;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    this.usuariosLogObject.descripcion =
                      "Finalizado Movimiento id:" +
                      returnVal.id +
                      " por Usuario id: " +
                      user.id;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      this.hiddenmodal,
                      ""
                    );
                  }
                  break;
                case "delTerrestreMovimientos":
                  {
                    NotificationManager.info(
                      "Se eliminará el movimiento",
                      "Actualizacion, mov (" +
                        returnVal.id +
                        ") por Usuario: " +
                        user.id
                    );
                    this.usuariosLogObject.idBitacoraMonitoreo = 5;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = returnVal.id;
                    this.usuariosLogObject.idPlataformaTipo = 2;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    this.usuariosLogObject.descripcion =
                      "Eliminado Movimiento id:" +
                      returnVal.id +
                      " por Usuario id: " +
                      user.id;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      this.hiddenmodal,
                      ""
                    );
                  }
                  break;
                case "entregas":
                  {
                    let entregasTem = this.state.entregas;
                    entregasTem.map((entre, index) => {
                      if (returnVal.idEntrega == entre.idEntrega) {
                        this.usuariosLogObject.idBitacoraMonitoreo = 3;
                        this.usuariosLogObject.idUsuario = user.id;
                        this.usuariosLogObject.idMovimiento =
                          returnVal.idMovimiento; //obj.id;//returnVal.id;
                        this.usuariosLogObject.idPlataformaTipo = 2;
                        this.usuariosLogObject.accionFecha = myFormato(
                          new Date()
                        );
                        this.usuariosLogObject.descripcion =
                          "Actualizacion Entrega: " +
                          entre.idEntrega +
                          " Movimiento id:" +
                          returnVal.idMovimiento +
                          " Campo: " +
                          entre.campo; //returnVal.id;
                        entre = returnVal;
                        if (returnVal.estatus == 1) {
                          this.setIdEntregaActiva(returnVal);
                        }
                        this.setState({ entregas: entregasTem });
                        NotificationManager.info(
                          "Se modifica entreg@",
                          "Actualizacion, mov (" + returnVal.idMovimiento + ")"
                        );
                      }
                    });
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      this.hiddenmodal,
                      ""
                    );
                  }
                  break;
                case "updateFechasFull":
                  {
                    NotificationManager.info(
                      "Se ha actualizado movimiento en full",
                      "Actualizacion, mov (" + returnVal.id + ")"
                    );
                    let cambios = this.getCambiosNav(id, returnVal); //{bitacora: bitacora , camposmodificados: camposmodificados }
                    this.usuariosLogObject.idBitacoraMonitoreo =
                      cambios.bitacora;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = returnVal.id;
                    this.usuariosLogObject.idPlataformaTipo = 1;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    this.usuariosLogObject.descripcion =
                      cambios.camposmodificados;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject
                    );
                  }
                  break;
                default:
                  break;
              }
            }
            break;
          case "delete":
            {
              switch (stateVar) {
                case "navieraMovimientos":
                  {
                    if (returnVal) {
                      NotificationManager.error(
                        "Se ha eliminado el mov: " +
                          id +
                          ", imposible recuperar",
                        "Borrado"
                      );
                    } else {
                      NotificationManager.error(
                        "No se ha eliminado el mov: " + id + ", error",
                        "Error"
                      );
                    }
                    this.usuariosLogObject.idBitacoraMonitoreo = 1;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = id;
                    this.usuariosLogObject.idPlataformaTipo = 1;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    this.usuariosLogObject.descripcion =
                      "Movimiento " + id + ", eliminado(" + returnVal + ")"; //en id vienen los campos modificados
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject
                    );
                  }
                  break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log(err);
        }
      });
  };

  getCambiosNav = (movimientoOld, MovimientoUpdated) => {
    //1	Agregar movimiento *
    ///2incidencias        *
    //3	Actualizar horario *
    //4	Alertas            *
    ///5rastreame          *
    //6	Envío Arroba       *
    //7	Envío Reacción     *
    ///8ruta               *
    ///9 sello             *
    ///10contenedor        *
    ///11 otd              *
    ///12 cliente          *
    ///13 Actualizar Full  *
    ///14 movimiento tipo **
    ///15 OperacionTipo **
    ///16 Ruta2 **

    let camposmodificados = "";
    let getCampoMod = "";
    let bitacora = 3;
    if (3 === MovimientoUpdated.idOperacionEstatus.id) {
      bitacora = 1;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      camposmodificados = camposmodificados + "Se finaliza Movimiento )";
    } //Finalizado

    if (MovimientoUpdated.idRuta) {
      if (movimientoOld.idRuta.id) {
        if (movimientoOld.idRuta.id != MovimientoUpdated.idRuta.id) {
          bitacora = 8;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idRuta.nombre +
            " a " +
            MovimientoUpdated.idRuta.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idRuta.nombre + ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idOtd) {
      if (movimientoOld.idOtd.id != MovimientoUpdated.idOtd.id) {
        bitacora = 11;
        if (camposmodificados.lenght > 0) {
          camposmodificados = camposmodificados + ",  ";
        }
        camposmodificados = camposmodificados + "( ";
        camposmodificados =
          camposmodificados +
          movimientoOld.idOtd.nombre +
          " a " +
          MovimientoUpdated.idOtd.nombre +
          ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idCliente) {
      if (movimientoOld.idCliente.id) {
        if (movimientoOld.idCliente.id != MovimientoUpdated.idCliente.id) {
          bitacora = 12;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idCliente.nombre +
            " a " +
            MovimientoUpdated.idCliente.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idCliente.nombre + ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idMovimientoTipo) {
      if (movimientoOld.idMovimientoTipo.id) {
        if (
          movimientoOld.idMovimientoTipo.id !=
          MovimientoUpdated.idMovimientoTipo.id
        ) {
          bitacora = 14;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idMovimientoTipo.nombre +
            " a " +
            MovimientoUpdated.idMovimientoTipo.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idMovimientoTipo.nombre + ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idOperacionTipo) {
      if (movimientoOld.idOperacionTipo.id) {
        if (
          movimientoOld.idOperacionTipo.id !=
          MovimientoUpdated.idOperacionTipo.id
        ) {
          bitacora = 15;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idOperacionTipo.nombre +
            " a " +
            MovimientoUpdated.idOperacionTipo.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idOperacionTipo.nombre + ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idRuta2) {
      if (movimientoOld.idRuta2.id) {
        if (movimientoOld.idRuta2.id != MovimientoUpdated.idRuta2.id) {
          bitacora = 16;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idRuta2.nombre +
            " a " +
            MovimientoUpdated.idRuta2.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idRuta2.nombre + ")";
      }
    }

    if (movimientoOld.citaProgramada != MovimientoUpdated.citaProgramada) {
      bitacora = 3;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.citaProgramada) {
        camposmodificados =
          camposmodificados +
          movimientoOld.citaProgramada +
          " a " +
          MovimientoUpdated.citaProgramada +
          ")";
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.citaProgramada + ")";
      }
    }
    if (movimientoOld.sello != MovimientoUpdated.sello) {
      bitacora = 9;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.sello) {
        camposmodificados =
          camposmodificados +
          movimientoOld.sello +
          " a " +
          MovimientoUpdated.sello +
          ")";
      } else {
        camposmodificados = camposmodificados + MovimientoUpdated.sello + ")";
      }
    } //No aplica a full
    if (movimientoOld.po != MovimientoUpdated.po) {
      bitacora = 1;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.po) {
        camposmodificados =
          camposmodificados +
          movimientoOld.po +
          " a " +
          MovimientoUpdated.po +
          ")";
      } else {
        camposmodificados = camposmodificados + MovimientoUpdated.po + ")";
      }
    } //No aplica a full
    if (movimientoOld.booking != MovimientoUpdated.booking) {
      bitacora = 1;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.booking) {
        camposmodificados =
          camposmodificados +
          movimientoOld.booking +
          " a " +
          MovimientoUpdated.booking +
          ")";
      } else {
        camposmodificados = camposmodificados + MovimientoUpdated.booking + ")";
      }
    } //No aplica a full

    if (movimientoOld.contenedor != MovimientoUpdated.contenedor) {
      bitacora = 10;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.contenedor) {
        camposmodificados =
          camposmodificados +
          movimientoOld.contenedor +
          " a " +
          MovimientoUpdated.contenedor +
          ")";
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.contenedor + ")";
      }
    } //No aplica afull
    if (movimientoOld.actualizarFull != MovimientoUpdated.actualizarFull) {
      bitacora = 13;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      camposmodificados =
        camposmodificados +
        movimientoOld.actualizarFull +
        " a " +
        MovimientoUpdated.actualizarFull +
        ")";
    }

    if (movimientoOld.idRuta2 != MovimientoUpdated.idRuta2) {
      bitacora = 16;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.idRuta2) {
        camposmodificados =
          camposmodificados +
          movimientoOld.idRuta2.nombre +
          " a " +
          MovimientoUpdated.idRuta2.nombre +
          ")";
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idRuta2.nombre + ")";
      }
    }
    //this.camposLogisticosNav
    this.camposLogisticosNav.map((cLog, index) => {
      switch (cLog.nombreCampo) {
        /**
         * Importacion
         */
        case "arribo_terminal_vacio":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "arriboTerminalVacio",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "salida_terminal_lleno":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "salidaTerminalLleno",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "arribo_cliente_lleno":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "arriboClienteLleno",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "salida_cliente_vacio":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "salidaClienteVacio",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "arribo_terminal_vacio_2":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "arriboTerminalVacio2",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "asignacion_piloto":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "asignacionPiloto",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "inicio_ruta":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "inicioRuta",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "despacho_predio_cliente":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "despachoPredioCliente",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "llegada_frontera":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "llegadaFrontera",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "salida_frontera":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "salidaFrontera",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "arribo_aduana":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "arriboAduana",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "salida_aduana":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "salidaAduana",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        /**
         * Exportacion
         */
        case "retiro_contenedor":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "retiroContenedor",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "arribo_cliente_vacio":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "arriboClienteVacio",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "salida_cliente_lleno":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "salidaClienteLleno",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "arribo_terminal_cargado":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "arriboTerminalCargado",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "salida_predio_vacio":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "salidaPredioVacio",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "cita_fecha_despacho":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "citaFechaDespacho",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "entrada_ciudad_portuaria":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "entradaCiudadPortuaria",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "arribo_patio_linea":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "arriboPatioLinea",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case "salida_terminal_vacio":
          {
            getCampoMod = this.validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              "salidaTerminalVacio",
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        default:
          break;
      }
    });

    return { bitacora: bitacora, camposmodificados: camposmodificados };
  };
  validarLogisTicos = (
    movimientoOld,
    MovimientoUpdated,
    camposmodificados,
    campo,
    labelLog
  ) => {
    let camposmodificadosTemp = camposmodificados;
    if (movimientoOld[campo] !== MovimientoUpdated[campo]) {
      if (camposmodificadosTemp.lenght > 0) {
        camposmodificadosTemp = camposmodificadosTemp + ",  ";
      }
      camposmodificadosTemp = camposmodificadosTemp + "(  " + labelLog + ": ";
      if (movimientoOld[campo]) {
        camposmodificadosTemp =
          camposmodificadosTemp +
          movimientoOld[campo] +
          " a " +
          MovimientoUpdated[campo] +
          " )";
      } else {
        camposmodificadosTemp =
          camposmodificadosTemp + MovimientoUpdated[campo] + " )";
      }
    }
    return camposmodificadosTemp;
  };

  EnviarArroba = (acumulador) => {
    var objetoLog = {
      id: "",
      idBitacoraMonitoreo: 0,
      idUsuario: user.id,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      idPlataformaTipo: 0,
    };
    objetoLog.idBitacoraMonitoreo = 6;
    objetoLog.accionFecha = myFormato(new Date());
    switch (acumulador) {
      case 3:
        {
          if (this.props.plataformaTipo === "Terrestre") {
            ArrobaTerrestre(this.props.movimiento, acumulador).then(
              (returnVal) => {
                NotificationManager.info(
                  returnVal,
                  this.privilegioDescripcion("arroba_azul")
                );
                objetoLog.idMovimiento = this.props.movimiento;
                objetoLog.idPlataformaTipo = 2;
                objetoLog.descripcion =
                  "Arroba Azul (" +
                  this.state.destinatarios.destinatarios3 +
                  ")";
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  objetoLog,
                  "",
                  "",
                  this.hiddenmodal,
                  ""
                );
              }
            );
          } else {
            Arroba(this.state.movimiento.id, acumulador).then((returnVal) => {
              NotificationManager.info(
                returnVal,
                this.privilegioDescripcion("arroba_azul")
              );
              objetoLog.idMovimiento = this.props.movimiento;
              objetoLog.idPlataformaTipo = 1;
              objetoLog.descripcion =
                "Arroba Azul (" + this.state.destinatarios.destinatarios3 + ")";
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                objetoLog,
                "",
                "",
                this.hiddenmodal,
                ""
              );
              if (
                this.state.movimiento.actualizarFull &&
                this.state.movimientoFull
              ) {
                Arroba(this.state.movimientoFull, acumulador).then(
                  (returnVal) => {
                    NotificationManager.info(
                      "movimiento full(" +
                        this.state.movimientoFull +
                        ") " +
                        returnVal,
                      this.privilegioDescripcion("arroba_azul")
                    );
                    objetoLog.idMovimiento = this.state.movimientoFull;
                    objetoLog.idPlataformaTipo = 1;
                    objetoLog.descripcion =
                      "Arroba Azul (" +
                      this.state.destinatarios.destinatarios3 +
                      ")";
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      objetoLog,
                      "",
                      "",
                      this.hiddenmodal,
                      ""
                    );
                  }
                );
              }
            });
          }
        }
        break;
      case 4:
        {
          if (this.props.plataformaTipo === "Terrestre") {
            ArrobaTerrestre(this.props.movimiento, acumulador).then(
              (returnVal) => {
                NotificationManager.info(
                  returnVal,
                  this.privilegioDescripcion("arroba_roja")
                );
                objetoLog.idMovimiento = this.props.movimiento;
                objetoLog.idPlataformaTipo = 2;
                objetoLog.descripcion =
                  "Arroba roja (" +
                  this.state.destinatarios.destinatarios3 +
                  ")";
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  objetoLog,
                  "",
                  "",
                  this.hiddenmodal,
                  ""
                );
              }
            );
          } else {
            Arroba(this.state.movimiento.id, acumulador).then((returnVal) => {
              NotificationManager.info(
                returnVal,
                this.privilegioDescripcion("arroba_roja")
              );
              objetoLog.idMovimiento = this.props.movimiento;
              objetoLog.idPlataformaTipo = 1;
              objetoLog.descripcion =
                "Arroba Roja (" + this.state.destinatarios.destinatarios4 + ")";
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                objetoLog,
                "",
                "",
                this.hiddenmodal,
                ""
              );
            });
            if (
              this.state.movimiento.actualizarFull &&
              this.state.movimientoFull
            ) {
              Arroba(this.state.movimientoFull, acumulador).then(
                (returnVal) => {
                  NotificationManager.info(
                    "movimiento full(" +
                      this.state.movimientoFull +
                      ") " +
                      returnVal,
                    this.privilegioDescripcion("arroba_roja")
                  );
                  objetoLog.idMovimiento = this.state.movimientoFull;
                  objetoLog.idPlataformaTipo = 1;
                  objetoLog.descripcion =
                    "Arroba Roja (" +
                    this.state.destinatarios.destinatarios4 +
                    ")";
                  Crud_Catalogos(
                    "usuariosLog",
                    this.catalogo,
                    "post",
                    "",
                    objetoLog,
                    "",
                    "",
                    this.hiddenmodal,
                    ""
                  );
                }
              );
            }
          }
        }
        break;
      case 5:
        {
          if (this.props.plataformaTipo === "Terrestre") {
            ArrobaTerrestre(this.props.movimiento, acumulador).then(
              (returnVal) => {
                NotificationManager.info(
                  returnVal,
                  this.privilegioDescripcion("arroba_negra")
                );
                objetoLog.idMovimiento = this.props.movimiento;
                objetoLog.idPlataformaTipo = 2;
                objetoLog.descripcion =
                  "Arroba negra (" +
                  this.state.destinatarios.destinatarios3 +
                  ")";
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  objetoLog,
                  "",
                  "",
                  this.hiddenmodal,
                  ""
                );
              }
            );
          } else {
            Arroba(this.state.movimiento.id, acumulador).then((returnVal) => {
              NotificationManager.info(
                returnVal,
                this.privilegioDescripcion("arroba_negra")
              );
              objetoLog.idMovimiento = this.props.movimiento;
              objetoLog.idPlataformaTipo = 1;
              objetoLog.descripcion =
                "Arroba Negra (" +
                this.state.destinatarios.destinatarios5 +
                ")";
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                objetoLog,
                "",
                "",
                this.hiddenmodal,
                ""
              );
            });
            if (
              this.state.movimiento.actualizarFull &&
              this.state.movimientoFull
            ) {
              Arroba(this.state.movimientoFull, acumulador).then(
                (returnVal) => {
                  NotificationManager.info(
                    "movimiento full(" +
                      this.state.movimientoFull +
                      ") " +
                      returnVal,
                    this.privilegioDescripcion("arroba_negra")
                  );
                  objetoLog.idMovimiento = this.state.movimientoFull;
                  objetoLog.idPlataformaTipo = 1;
                  objetoLog.descripcion =
                    "Arroba Negra (" +
                    this.state.destinatarios.destinatarios5 +
                    ")";
                  Crud_Catalogos(
                    "usuariosLog",
                    this.catalogo,
                    "post",
                    "",
                    objetoLog,
                    "",
                    "",
                    this.hiddenmodal,
                    ""
                  );
                }
              );
            }
          }
        }
        break;
      case 6:
        {
          if (this.props.plataformaTipo !== "Terrestre") {
            Arroba(this.state.movimiento.id, acumulador).then((returnVal) => {
              NotificationManager.info(
                returnVal,
                this.privilegioDescripcion("arroba_amarilla")
              );
              objetoLog.idMovimiento = this.props.movimiento;
              objetoLog.idPlataformaTipo = 1;
              objetoLog.descripcion =
                "Arroba Amarilla (" +
                this.state.destinatarios.destinatarios6 +
                ")";
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                objetoLog,
                "",
                "",
                this.hiddenmodal,
                ""
              );
            });
            if (
              this.state.movimiento.actualizarFull &&
              this.state.movimientoFull
            ) {
              Arroba(this.state.movimientoFull, acumulador).then(
                (returnVal) => {
                  NotificationManager.info(
                    "movimiento full(" +
                      this.state.movimientoFull +
                      ") " +
                      returnVal,
                    this.privilegioDescripcion("arroba_amarilla")
                  );
                  objetoLog.idMovimiento = this.state.movimientoFull;
                  objetoLog.idPlataformaTipo = 1;
                  objetoLog.descripcion =
                    "Arroba Amarilla (" +
                    this.state.destinatarios.destinatarios6 +
                    ")";
                  Crud_Catalogos(
                    "usuariosLog",
                    this.catalogo,
                    "post",
                    "",
                    objetoLog,
                    "",
                    "",
                    this.hiddenmodal,
                    ""
                  );
                }
              );
            }
          }
        }
        break;
      default:
        break;
    }
  };
  searchingAlertas = (event) => {
    this.setState({ searchAlertas: event.target.value });
  };
  searchingIncidencias = (event) => {
    this.setState({ searchIncidencias: event.target.value });
  };
  abrirmodalGps = (gps = "") => {
    let gpsReciente = this.props.gpsReciente[this.props.getMovimiento.id];

    if (gps) {
      gpsReciente = gps;
    }
    let plataformaIdGg = null;
    if (this.props.plataformaTipo === "Naviera") {
      this.plataformaT = 1;
      if (this.state.movimiento.idPlataforma) {
        if (this.state.movimiento.idPlataforma.idGg) {
          plataformaIdGg = this.state.movimiento.idPlataforma.idGg;
        }
      }
    } else {
      //Terrestre
      this.plataformaT = 2;
      if (this.state.movimiento.plataforma) {
        if (this.state.movimiento.plataforma.idGg) {
          plataformaIdGg = this.state.movimiento.plataforma.idGg;
        }
      }
    }
    if (this.props.plataformaTipo === "Naviera") {
      if (plataformaIdGg && gpsReciente) {
        this.getfuntion(
          "get",
          gpsReciente,
          "",
          "navieraMovimientos/plataforma/" +
            plataformaIdGg +
            "/gps/" +
            gpsReciente.idGg,
          "urlGps"
        );
      }
    } else {
      if (plataformaIdGg && gpsReciente) {
        this.getfuntion(
          "get",
          gpsReciente,
          "",
          "terrestreMovimientos/plataforma/" +
            plataformaIdGg +
            "/gps/" +
            gpsReciente.idGg,
          "urlGps"
        );
      }
    }
  };
  getFuncionalidad = (elemento) => {
    let movActivo = this.props.getMovimiento;
    if (movActivo) {
      if (movActivo.idPlataforma) {
        if (this.props.PlataformasPerfiles) {
          if (this.props.PlataformasPerfiles[movActivo.idPlataforma.id]) {
            let funcionalidadesTemp =
              this.props.PlataformasPerfiles[movActivo.idPlataforma.id]
                .funcionalidades;
            if (!funcionalidadesTemp) {
              return false;
            }
            for (let item of funcionalidadesTemp) {
              if (item.nombre === elemento) {
                return true;
              }
            }
          }
        }
      }
      if (movActivo.plataforma) {
        if (this.props.PlataformasPerfiles) {
          if (this.props.PlataformasPerfiles[movActivo.plataforma.id]) {
            let funcionalidadesTemp =
              this.props.PlataformasPerfiles[movActivo.plataforma.id]
                .funcionalidades;
            if (!funcionalidadesTemp) {
              return false;
            }
            for (let item of funcionalidadesTemp) {
              if (item.nombre === elemento) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  };
  eliminarMovimiento = () => {
    confirmAlert({
      title: "Antención",
      message:
        "¿Esta seguro de eliminar el movimiento(" +
        this.state.movimiento.id +
        ") ?, El movmiento se borrara de forma definitiva y no podrá recuperarse",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => {
            this.getfuntion(
              "delete",
              "",
              this.state.movimiento.id,
              "navieraMovimientos",
              "navieraMovimientos"
            );
          },
        },
        {
          label: "Cancelar",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };

  render() {
    //console.log(this.props.getMovimiento);
    return (
      <div className="m-0">
        <Card>
          <Card.Header>
            <Card.Title>Panel de Información</Card.Title>
            <Card.Options>
              <Grid.Col>
                <ModalLayout
                  tamanio={"85%"}
                  title="Reactivar movimiento"
                  name={
                    <Button pill size="sm" outline color="primary">
                      Reactivar Movimiento
                    </Button>
                  }
                  hiddenModal={this.state.hiddenModals}
                  Fclose={this.hiddenmodal}
                  formulario={<ReactivarMovimientosTerrestre />}
                />
              </Grid.Col>

              {this.props.getMovimiento.id ? (
                <div>
                  <div className="row">
                    <div className="col-12">
                      {this.getFuncionalidad("Actualizar") &&
                      this.props.plataformaTipo === "Naviera" &&
                      this.estaPrivilegioActivo("movimientos_borrar") &&
                      this.props.getMovimiento.idOperacionEstatus.id === 1 ? (
                        <span className="badge float-right">
                          <Button
                            target="_blank"
                            size="sm"
                            RootComponent="a"
                            color="danger"
                            onClick={() => this.eliminarMovimiento()}
                          >
                            {" "}
                            <span className="badge">
                              <Icon link={true} name="trash" />
                              Eliminar
                            </span>{" "}
                          </Button>
                        </span>
                      ) : null}

                      {this.getFuncionalidad("Actualizar") &&
                      this.props.plataformaTipo === "Naviera" ? (
                        <span className="badge float-right">
                          <ModalLayout
                            tamanio={1200}
                            title="Actualizar horario"
                            name={
                              <Button pill size="sm" outline color="primary">
                                <Icon name="upload" />
                                <Icon name="clock" />
                              </Button>
                            }
                            hiddenModal={this.state.hiddenModals}
                            Fclose={this.hiddenmodal}
                            formulario={
                              <ActualizarHorariosNavieros
                                accion={"modificar"}
                                idusuario={user.id}
                                movimiento={this.state.movimiento}
                                getfuntion={this.getfuntion}
                                stor={this.props}
                                camposLogisticosNav={this.camposLogisticosNav}
                                rutasEstatusObject={this.props.rutasEstatus}
                                operacionesEstatus={
                                  this.props.operacionesEstatus
                                }
                                closeModal={this.hiddenmodal}
                                clientes_object={this.props.clientes_object}
                                otd_object={this.props.otd_object}
                                full={this.state.movimientoFull}
                              />
                            }
                          />
                        </span>
                      ) : (
                        <div></div>
                      )}
                      {this.getFuncionalidad("Actualizar") &&
                      this.props.plataformaTipo === "Naviera" &&
                      this.props.getMovimiento.idPlataforma ? (
                        <span className="badge float-right">
                          {this.props.getMovimiento.idPlataforma.plataformaTipo
                            .id === 3 ? (
                            <ModalLayout
                              tamanio={1200}
                              title="Operador"
                              name={
                                <Button pill size="sm" outline color="primary">
                                  <Icon name="user" />
                                </Button>
                              }
                              hiddenModal={this.state.hiddenModals}
                              Fclose={this.hiddenmodal}
                              formulario={
                                <MovimientoOperador
                                  accion={"modificar"}
                                  idusuario={user.id}
                                  movimiento={this.state.movimiento}
                                  getfuntion={this.getfuntion}
                                  stor={this.props}
                                  camposLogisticosNav={this.camposLogisticosNav}
                                  rutasEstatusObject={this.props.rutasEstatus}
                                  operacionesEstatus={
                                    this.props.operacionesEstatus
                                  }
                                  closeModal={this.hiddenmodal}
                                  clientes_object={this.props.clientes_object}
                                  otd_object={this.props.otd_object}
                                  full={this.state.movimientoFull}
                                />
                              }
                            />
                          ) : (
                            <div></div>
                          )}
                        </span>
                      ) : (
                        <div></div>
                      )}
                      {this.getFuncionalidad("Actualizar") &&
                      this.props.plataformaTipo === "Naviera" &&
                      this.props.getMovimiento.idPlataforma ? (
                        <span className="badge float-right">
                          {this.props.getMovimiento.idPlataforma.plataformaTipo
                            .id === 3 ? (
                            <ModalLayout
                              tamanio={1200}
                              title="Unidades"
                              name={
                                <Button pill size="sm" outline color="primary">
                                  <Icon name="truck" />
                                </Button>
                              }
                              hiddenModal={this.state.hiddenModals}
                              Fclose={this.hiddenmodal}
                              formulario={
                                <MovimientoUnidad
                                  accion={"modificar"}
                                  idusuario={user.id}
                                  movimiento={this.state.movimiento}
                                  getfuntion={this.getfuntion}
                                  stor={this.props}
                                  camposLogisticosNav={this.camposLogisticosNav}
                                  rutasEstatusObject={this.props.rutasEstatus}
                                  operacionesEstatus={
                                    this.props.operacionesEstatus
                                  }
                                  closeModal={this.hiddenmodal}
                                  clientes_object={this.props.clientes_object}
                                  otd_object={this.props.otd_object}
                                  full={this.state.movimientoFull}
                                />
                              }
                            />
                          ) : (
                            <div></div>
                          )}
                        </span>
                      ) : (
                        <div></div>
                      )}
                      {
                        //this.getFuncionalidad("Actualizar") && this.props.plataformaTipo === "Terrestre" && this.state.idEntregaActiva ?
                      }

                      {true && (
                        <span
                          className="badge"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Bitacora a PDF"}
                        >
                          <ModalLay
                            title={"Bitacora del viaje electrónica: "}
                            name={
                              <label
                                style={{ color: "orange", "font-size": "200%" }}
                              >
                                <Icon name="list" />
                              </label>
                            }
                            formulario={BitacoraPdf}
                            datosForm={{
                              Movimiento: this.props.getMovimiento,
                            }}
                          />{" "}
                        </span>
                      )}

                      {this.getFuncionalidad("Actualizar") &&
                      this.props.plataformaTipo === "Terrestre" ? ( //&&
                        this.state.idEntregaActiva ? (
                          <span className="badge float-right">
                            <ModalLayout
                              tamanio={1200}
                              title="Actualizar horario"
                              name={
                                <Button pill size="sm" outline color="primary">
                                  <Icon name="upload" />
                                  <Icon name="clock" />
                                </Button>
                              }
                              hiddenModal={this.state.hiddenModals}
                              Fclose={this.hiddenmodal}
                              formulario={
                                <ActualizarHorariosTerrestres
                                  accion={"modificar"}
                                  idusuario={user.id} //movimiento={this.state.movimiento}
                                  movimiento={this.props.getMovimiento}
                                  idEntregaActiva={this.state.idEntregaActiva}
                                  idEntrega={this.state.idEntrega}
                                  getfuntion={this.getfuntion}
                                  stor={this.props}
                                  rutasEstatusObject={this.props.rutasEstatus}
                                  operacionesEstatus={
                                    this.props.operacionesEstatus
                                  }
                                  closeModal={this.hiddenmodal}
                                />
                              }
                            />
                          </span>
                        ) : (
                          <div></div>
                        )
                      ) : (
                        <div></div>
                      )}

                      <span className="badge float-right text-dark">
                        {this.state.movimientoFull
                          ? this.state.movimiento.actualizarFull
                            ? "Actualizara al mov: " + this.state.movimientoFull
                            : "En full con: " + this.state.movimientoFull
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={"Bitacora movimineto"}
                        hidden={false}
                      >
                        <ModalLayout
                          title={
                            "Bitacora del Movimiento: " +
                            this.props.getMovimiento.id
                          }
                          name={
                            <label
                              style={{ color: "green", "font-size": "200%" }}
                            >
                              <Icon name="list" />
                            </label>
                          }
                          hiddenModal={this.state.hiddenModals}
                          Fclose={this.hiddenmodal}
                          formulario={
                            <BitacoraMov
                              Tipo={this.props.plataformaTipo}
                              Movimiento={this.props.getMovimiento}
                            />
                          }
                        />
                      </span>
                      {this.getFuncionalidad("Arroba Azul") ? (
                        <span
                          className="badge"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Agregar temporizador"
                        >
                          <ModalLay
                            tamanio={"85%"}
                            title="Agregar temporizador"
                            name={
                              <span
                                style={{
                                  color: "#63e6be",
                                  "font-size": "200%",
                                }}
                              >
                                <i class="fa fa-hourglass" />
                              </span>
                            }
                            formulario={Temporizador}
                            datosForm={{
                              movimiento: this.props.getMovimiento,
                              tiempos: this.props.tiempos,
                              addToArray: this.props.addToArray,
                              usuario: this.props.usuario,
                            }}
                          />
                        </span>
                      ) : null}
                      {url.searchParams.get("q") === "activos"
                        ? this.state.unidad.map((u, index) => (
                            <span
                              className="badge"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                "Video de unidad (" + u.unidad.nombre + ")"
                              }
                              hidden={!u.unidad.idVideo}
                            >
                              <i
                                className="fa fa-video-camera"
                                style={{ color: "green", "font-size": "200%" }}
                                onClick={() =>
                                  window.open(
                                    "https://cctv.skyangel.com.mx/live?token=KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk=&dispositivo=" +
                                      u.unidad.idVideo, //+u.unidad.idVideo,
                                    "sharer",
                                    "width=1200,height=800,scrollbars=NO"
                                  )
                                }
                              ></i>
                            </span>
                          ))
                        : null}
                      {url.searchParams.get("q") === "activos" &&
                      this.getFuncionalidad("Arroba Azul") ? (
                        <span
                          className="badge"
                          onClick={() => {
                            this.EnviarArroba(3);
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={
                            "Estatus: " +
                            this.state.destinatarios.destinatarios3
                          }
                        >
                          <label style={{ color: "blue", "font-size": "200%" }}>
                            <Icon name="at-sign" />
                          </label>
                        </span>
                      ) : null}

                      {url.searchParams.get("q") === "activos" &&
                      this.getFuncionalidad("Rastreame") ? ( //&& this.estaPrivilegioActivo("rastreame")
                        <span
                          className="badge"
                          onClick={() => this.abrirmodalGps()}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="rastreame"
                        >
                          <label
                            style={{ color: "black", "font-size": "200%" }}
                          >
                            <Icon name="map-pin" />
                          </label>
                        </span>
                      ) : null}
                      {url.searchParams.get("q") === "activos" &&
                      this.getFuncionalidad("Reaccion") &&
                      this.estaPrivilegioActivo("reaccion") ? (
                        <span
                          className="badge"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={this.privilegio.descripcion}
                        >
                          <ModalLayout
                            tamanio={1200}
                            title="Agregar Reacción"
                            name={
                              <label
                                style={{ color: "black", "font-size": "200%" }}
                              >
                                <Icon prefix="fa" name="warning" />
                              </label>
                            }
                            hiddenModal={this.state.hiddenModals}
                            Fclose={this.hiddenmodal}
                            formulario={
                              <Reaccion
                                movimiento={this.state.movimiento.id}
                                plataformaTipo={this.props.plataformaTipo}
                                getMovimiento={this.props.getMovimiento}
                                hiddenmodal={this.hiddenmodal}
                              />
                            }
                          />
                        </span>
                      ) : null}

                      {url.searchParams.get("q") === "activos" &&
                      this.getFuncionalidad("Reaccion") &&
                      this.estaPrivilegioActivo("reaccion") ? (
                        <span
                          className="badge"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={this.privilegio.descripcion}
                        >
                          <ModalLayout
                            tamanio={1200}
                            title="Detalle de Movimiento 2.1"
                            name={
                              <label
                                style={{ color: "pink", "font-size": "200%" }}
                              >
                                <Icon prefix="fa" name="warning" />
                              </label>
                            }
                            hiddenModal={this.state.hiddenModals}
                            Fclose={this.hiddenmodal}
                            formulario={
                              <ReaccionTerrestre
                                movimiento={this.state.movimiento.id}
                                plataformaTipo={this.props.plataformaTipo}
                                getMovimiento={this.props.getMovimiento}
                                hiddenmodal={this.hiddenmodal}
                              />
                            }
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </Card.Options>
          </Card.Header>
          <Card.Body>
            {}
            {/** Entregas
               <div className="col-12 bg-primary text-light" ><h4>Entregas<div className="float-right col-1 right"
                   style={{ "background-color": "rgba(6, 0, 0, 0.2)" }}
                   onClick={() => this.setState({ entregasCollapse: !this.state.entregasCollapse })}><Icon name={this.state.entregasCollapse ? "chevron-down" : "chevron-up"} className="float-right" /></div></h4></div>
              <div className="panel panel-default" hidden={this.state.entregasCollapse}>
                
                <div className="col-12 panel-resizable" style={{ height: "75vh" }}>
                  
                  {
                   <Grid.Row>
                   <Grid.Col sm={12} lg={12}>
                    
                  
                     <Card
                       title="Destinos"
                       isCollapsible={false}
                       //isClosable
                       isCollapsed={false}
                       
                       body={
                         <div>
                           <Form.Group>
                             <Table
                               cards={true}
                               striped={true}
                               responsive={true}
                               className="table-vcenter"
                             >
                               <Table.Header>
                                 <Table.ColHeader></Table.ColHeader>
                                 <Table.ColHeader></Table.ColHeader>
                               </Table.Header>
                               <Table.Body>
                                 
                                  <Table.Row>
                                    <Table.Col>Destino1</Table.Col>
                                    <Table.Col>
                                    <ModalLayout
                                        tamanio={"99%"}
                                        title={"Informacion de ETA"}
                                        name={<Button pill size="sm" outline color="primary">Detalles ETA</Button>}
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        formulario=
                                        {
                                          <div>
                                            <Table
                                            cards={true}
                                            striped={true}
                                            responsive={true}
                                            className="table-vcenter">
                                               <Table.Header>
                                               <Table.ColHeader>ID</Table.ColHeader>
                                               <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                               <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                               <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                               <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                               <Table.ColHeader>Fecha</Table.ColHeader>
                                               <Table.ColHeader>Distancia</Table.ColHeader>
                                               <Table.ColHeader>Tiempo</Table.ColHeader>
                                               <Table.ColHeader>ETA</Table.ColHeader>
                                               <Table.ColHeader>Status</Table.ColHeader>
                                               <Table.ColHeader>Tipo</Table.ColHeader>
                                               <Table.ColHeader></Table.ColHeader>
                                               </Table.Header>
                                               <Table.Body>
                                               <Table.Col>ID campo</Table.Col>
                                               <Table.Col>Latitud Origen campo</Table.Col>
                                               <Table.Col>Longitud Origen campo</Table.Col>
                                               <Table.Col>Latitud Destino campo</Table.Col>
                                               <Table.Col>Longitud Destino campo</Table.Col>
                                               <Table.Col>Fecha campo</Table.Col>
                                               <Table.Col>Distancia campo</Table.Col>
                                               <Table.Col>Tiempo campo</Table.Col>
                                               <Table.Col>ETA campo</Table.Col>
                                               <Table.Col>Status campo</Table.Col>
                                               <Table.Col>Tipo campo</Table.Col>
                                               <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                 </Table.Body>
                                            </Table>
                                          </div>
                                        }
                                      />
                                    </Table.Col>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Col>Destino1</Table.Col>
                                    <Table.Col>
                                    <ModalLayout
                                        tamanio={"99%"}
                                        title={"Informacion de ETA"}
                                        name={<Button pill size="sm" outline color="primary">Detalles ETA</Button>}
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        formulario=
                                        {
                                          <div>
                                            <Table
                                            cards={true}
                                            striped={true}
                                            responsive={true}
                                            className="table-vcenter">
                                               <Table.Header>
                                               <Table.ColHeader>ID</Table.ColHeader>
                                               <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                               <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                               <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                               <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                               <Table.ColHeader>Fecha</Table.ColHeader>
                                               <Table.ColHeader>Distancia</Table.ColHeader>
                                               <Table.ColHeader>Tiempo</Table.ColHeader>
                                               <Table.ColHeader>ETA</Table.ColHeader>
                                               <Table.ColHeader>Status</Table.ColHeader>
                                               <Table.ColHeader>Tipo</Table.ColHeader>
                                               <Table.ColHeader></Table.ColHeader>
                                               </Table.Header>
                                               <Table.Body>
                                               <Table.Col>ID campo</Table.Col>
                                               <Table.Col>Latitud Origen campo</Table.Col>
                                               <Table.Col>Longitud Origen campo</Table.Col>
                                               <Table.Col>Latitud Destino campo</Table.Col>
                                               <Table.Col>Longitud Destino campo</Table.Col>
                                               <Table.Col>Fecha campo</Table.Col>
                                               <Table.Col>Distancia campo</Table.Col>
                                               <Table.Col>Tiempo campo</Table.Col>
                                               <Table.Col>ETA campo</Table.Col>
                                               <Table.Col>Status campo</Table.Col>
                                               <Table.Col>Tipo campo</Table.Col>
                                               <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                 </Table.Body>
                                            </Table>
                                          </div>
                                        }
                                      />
                                    </Table.Col>
                                  </Table.Row>
                                 
                                  <Table.Row>
                                    <Table.Col>Destino1</Table.Col>
                                    <Table.Col>
                                    <ModalLayout
                                        tamanio={"99%"}
                                        title={"Informacion de ETA"}
                                        name={<Button pill size="sm" outline color="primary">Detalles ETA</Button>}
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        formulario=
                                        {
                                          <div></div>
                                        }
                                      />
                                    </Table.Col>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Col>Destino1</Table.Col>
                                    <Table.Col>
                                    <ModalLayout
                                        tamanio={"99%"}
                                        title={"Informacion de ETA"}
                                        name={<Button pill size="sm" outline color="primary">Detalles ETA</Button>}
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        formulario=
                                        {
                                          <div>
                                          <Table
                                          cards={true}
                                          striped={true}
                                          responsive={true}
                                          className="table-vcenter">
                                             <Table.Header>
                                             <Table.ColHeader>ID</Table.ColHeader>
                                             <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                             <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                             <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                             <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                             <Table.ColHeader>Fecha</Table.ColHeader>
                                             <Table.ColHeader>Distancia</Table.ColHeader>
                                             <Table.ColHeader>Tiempo</Table.ColHeader>
                                             <Table.ColHeader>ETA</Table.ColHeader>
                                             <Table.ColHeader>Status</Table.ColHeader>
                                             <Table.ColHeader>Tipo</Table.ColHeader>
                                             <Table.ColHeader></Table.ColHeader>
                                             </Table.Header>
                                             <Table.Body>
                                             <Table.Col>ID campo</Table.Col>
                                             <Table.Col>Latitud Origen campo</Table.Col>
                                             <Table.Col>Longitud Origen campo</Table.Col>
                                             <Table.Col>Latitud Destino campo</Table.Col>
                                             <Table.Col>Longitud Destino campo</Table.Col>
                                             <Table.Col>Fecha campo</Table.Col>
                                             <Table.Col>Distancia campo</Table.Col>
                                             <Table.Col>Tiempo campo</Table.Col>
                                             <Table.Col>ETA campo</Table.Col>
                                             <Table.Col>Status campo</Table.Col>
                                             <Table.Col>Tipo campo</Table.Col>
                                             <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                               </Table.Body>
                                          </Table>
                                        </div>
                                        }
                                      />
                                    </Table.Col>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Col>Destino1</Table.Col>
                                    <Table.Col>
                                    <ModalLayout
                                        tamanio={"99%"}
                                        title={"Informacion de ETA"}
                                        name={<Button pill size="sm" outline color="primary">Detalles ETA</Button>}
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        formulario=
                                        {
                                          <div>
                                            <Table
                                            cards={true}
                                            striped={true}
                                            responsive={true}
                                            className="table-vcenter">
                                               <Table.Header>
                                               <Table.ColHeader>ID</Table.ColHeader>
                                               <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                               <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                               <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                               <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                               <Table.ColHeader>Fecha</Table.ColHeader>
                                               <Table.ColHeader>Distancia</Table.ColHeader>
                                               <Table.ColHeader>Tiempo</Table.ColHeader>
                                               <Table.ColHeader>ETA</Table.ColHeader>
                                               <Table.ColHeader>Status</Table.ColHeader>
                                               <Table.ColHeader>Tipo</Table.ColHeader>
                                               <Table.ColHeader></Table.ColHeader>
                                               </Table.Header>
                                               <Table.Body>
                                               <Table.Col>ID campo</Table.Col>
                                               <Table.Col>Latitud Origen campo</Table.Col>
                                               <Table.Col>Longitud Origen campo</Table.Col>
                                               <Table.Col>Latitud Destino campo</Table.Col>
                                               <Table.Col>Longitud Destino campo</Table.Col>
                                               <Table.Col>Fecha campo</Table.Col>
                                               <Table.Col>Distancia campo</Table.Col>
                                               <Table.Col>Tiempo campo</Table.Col>
                                               <Table.Col>ETA campo</Table.Col>
                                               <Table.Col>Status campo</Table.Col>
                                               <Table.Col>Tipo campo</Table.Col>
                                               <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                 </Table.Body>
                                            </Table>
                                          </div>
                                        }
                                      />
                                    </Table.Col>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Col>Destino1</Table.Col>
                                    <Table.Col>
                                    <ModalLayout
                                        tamanio={"99%"}
                                        title={"Informacion de ETA"}
                                        name={<Button pill size="sm" outline color="primary">Detalles ETA</Button>}
                                        hiddenModal={this.state.hiddenModals}
                                        Fclose={this.hiddenmodal}
                                        formulario=
                                        {
                                          <div>
                                            <Table
                                            cards={true}
                                            striped={true}
                                            responsive={true}
                                            className="table-vcenter">
                                               <Table.Header>
                                               <Table.ColHeader>ID</Table.ColHeader>
                                               <Table.ColHeader>Latitud Origen</Table.ColHeader>
                                               <Table.ColHeader>Longitud Origen</Table.ColHeader>
                                               <Table.ColHeader>Latitud Destino</Table.ColHeader>
                                               <Table.ColHeader>Longitud Destino</Table.ColHeader>
                                               <Table.ColHeader>Fecha</Table.ColHeader>
                                               <Table.ColHeader>Distancia</Table.ColHeader>
                                               <Table.ColHeader>Tiempo</Table.ColHeader>
                                               <Table.ColHeader>ETA</Table.ColHeader>
                                               <Table.ColHeader>Status</Table.ColHeader>
                                               <Table.ColHeader>Tipo</Table.ColHeader>
                                               <Table.ColHeader></Table.ColHeader>
                                               </Table.Header>
                                               <Table.Body>
                                               <Table.Col>ID campo</Table.Col>
                                               <Table.Col>Latitud Origen campo</Table.Col>
                                               <Table.Col>Longitud Origen campo</Table.Col>
                                               <Table.Col>Latitud Destino campo</Table.Col>
                                               <Table.Col>Longitud Destino campo</Table.Col>
                                               <Table.Col>Fecha campo</Table.Col>
                                               <Table.Col>Distancia campo</Table.Col>
                                               <Table.Col>Tiempo campo</Table.Col>
                                               <Table.Col>ETA campo</Table.Col>
                                               <Table.Col>Status campo</Table.Col>
                                               <Table.Col>Tipo campo</Table.Col>
                                               <Table.Col><Button pill size="sm" outline color="primary">Ver Mapa</Button></Table.Col>
                                                 </Table.Body>
                                            </Table>
                                          </div>
                                        }
                                      />
                                    </Table.Col>
                                  </Table.Row>


                                  
                                  
                                 
     
                               </Table.Body>
                             </Table>
                           </Form.Group>
                         </div>
                       }
                     />
                   </Grid.Col>
                   </Grid.Row>
                  }
                </div>
              </div>
              **/}
            {/** Detalles**/}
            <div className="col-12 bg-primary text-light">
              <h4>
                Detalles
                <div
                  className="float-right col-1 right"
                  style={{ "background-color": "rgba(6, 0, 0, 0.2)" }}
                  onClick={() =>
                    this.setState({
                      detallesCollase: !this.state.detallesCollase,
                    })
                  }
                >
                  <Icon
                    name={
                      this.state.detallesCollase ? "chevron-down" : "chevron-up"
                    }
                    className="float-right"
                  />
                </div>
              </h4>
            </div>
            <div
              className="panel panel-default"
              hidden={this.state.detallesCollase}
            >
              <div
                className="col-12 panel-resizable"
                style={{ height: "75vh" }}
              >
                {this.state.detalles ? this.state.detalles : "No hay detalles"}
              </div>
            </div>
            {/*
             * Alertas
             */}
            <div>
              {this.props.plataformaTipo === "Naviera" ? (
                <div>
                  <div className="col-12 bg-primary text-light">
                    <h4>
                      Alertas Navieras
                      <div
                        className="float-right col-1 right"
                        style={{ "background-color": "rgba(6, 0, 0, 0.2)" }}
                        onClick={() =>
                          this.setState({
                            alertasCollase: !this.state.alertasCollase,
                          })
                        }
                      >
                        <Icon
                          name={
                            this.state.alertasCollase
                              ? "chevron-down"
                              : "chevron-up"
                          }
                          className="float-right"
                        />
                      </div>
                    </h4>
                  </div>
                  <div
                    className="panel panel-default"
                    hidden={this.state.alertasCollase}
                  >
                    <div
                      className="col-12 panel-resizable"
                      style={{ height: "75vh" }}
                    >
                      <Grid.Row>
                        <Grid.Col sm={8} lg={12}>
                          {this.props.getMovimiento.id ? (
                            <div>
                              <Grid.Row>
                                <Grid.Col sm={7} lg={7}>
                                  {this.state.alertasN.length > 0 ? (
                                    <Form.Input
                                      className="mb-3"
                                      icon="search"
                                      placeholder="Buscar Alertas..."
                                      position="append"
                                      onChange={this.searchingAlertas}
                                    />
                                  ) : null}
                                </Grid.Col>

                                {this.props.plataformaTipo === "Naviera" &&
                                this.getFuncionalidad("Alertas Atendidas") ? (
                                  <Grid.Col sm={2} lg={2}>
                                    <ModalLayout
                                      tamanio={"99%"}
                                      title={
                                        "Movimiento " +
                                        this.props.getMovimiento.id +
                                        " - Alertas"
                                      }
                                      name={
                                        <Button
                                          pill
                                          size="sm"
                                          outline
                                          color="primary"
                                        >
                                          <Icon name="paperclip" />
                                        </Button>
                                      }
                                      hiddenModal={this.state.hiddenModals}
                                      Fclose={this.hiddenmodal}
                                      formulario={
                                        <AlertaNListar
                                          Movimiento={this.props.getMovimiento}
                                        />
                                      }
                                    />
                                  </Grid.Col>
                                ) : (
                                  <div></div>
                                )}
                              </Grid.Row>
                            </div>
                          ) : null}
                        </Grid.Col>
                      </Grid.Row>
                      {this.props.getMovimiento.id &&
                      this.state.alertasN &&
                      this.state.alertasN[this.props.getMovimiento.id] &&
                      this.getFuncionalidad("Alertas No Atendidas") ? (
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                          headerItems={[
                            { content: "ID" },
                            { content: "Tiggername" },
                            { content: "Atendido" },
                          ]}
                        >
                          <Table.Body>
                            {(this.columna = [])}
                            {this.state.alertasN[this.props.getMovimiento.id]
                              ? Object.keys(
                                  this.state.alertasN[
                                    this.props.getMovimiento.id
                                  ]
                                ).forEach((key, indice) =>
                                  this.state.alertasN[
                                    this.props.getMovimiento.id
                                  ][key]
                                    .filter(
                                      (word) =>
                                        !word.alert.atendida &&
                                        word.alert.triggerName
                                          .toUpperCase()
                                          .includes(
                                            this.state.searchAlertas.toUpperCase()
                                          )
                                    )
                                    .sort()
                                    .map((elemento, index) =>
                                      this.columna.push(
                                        <Table.Row>
                                          <Table.Col>
                                            {elemento.alert.id}
                                          </Table.Col>
                                          <Table.Col>
                                            {elemento.alert.triggerName}
                                          </Table.Col>
                                          <Table.Col>
                                            {this.getFuncionalidad(
                                              "Atender Alertas"
                                            ) ? (
                                              <ModalLayout
                                                tamanio={"60%"}
                                                title="Alertas Navieras"
                                                name={
                                                  <Button
                                                    pill
                                                    size="sm"
                                                    outline
                                                    color="primary"
                                                  >
                                                    <div
                                                      className={"fa " + key}
                                                      style={{
                                                        color: elemento.color,
                                                      }}
                                                    ></div>
                                                  </Button>
                                                }
                                                hiddenModal={
                                                  this.state.hiddenModals
                                                }
                                                Fclose={this.hiddenmodal}
                                                formulario={
                                                  <AlertaNForm
                                                    accion={"modificar"}
                                                    usuario={user}
                                                    alerta={elemento.alert}
                                                    getfuntion={this.getfuntion}
                                                    stor={this.props}
                                                    closeModal={
                                                      this.hiddenmodal
                                                    }
                                                    IncidenciasFrom={
                                                      <MovimientoIncidencias
                                                        accion={"nuevo"}
                                                        idusuario={user.id}
                                                        idmovimiento={
                                                          this.state
                                                            .idMovimiento
                                                        }
                                                        incidencias={
                                                          this.state
                                                            .incidenciasAll
                                                        }
                                                        getfuntion={
                                                          this.getfuntion
                                                        }
                                                        stor={this.props}
                                                        plataformaTipo={
                                                          this.props
                                                            .plataformaTipo
                                                        }
                                                        closeModal={
                                                          this.hiddenmodal
                                                        }
                                                        gpsReciente={
                                                          this.props.gpsReciente
                                                            ? this.props
                                                                .gpsReciente[
                                                                this.state
                                                                  .movimiento.id
                                                              ]
                                                            : null
                                                        }
                                                        latitud={
                                                          elemento.alert.latitud
                                                        }
                                                        longitud={
                                                          elemento.alert
                                                            .longitud
                                                        }
                                                        alerta={true}
                                                      />
                                                    }
                                                  />
                                                }
                                              />
                                            ) : (
                                              <div></div>
                                            )}
                                          </Table.Col>
                                        </Table.Row>
                                      )
                                    )
                                )
                              : null}
                          </Table.Body>
                        </Table>
                      ) : (
                        "  No hay alertas Navieras..!!"
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              {this.props.plataformaTipo === "Terrestre" ? (
                <div>
                  <div className="col-12 bg-primary text-light">
                    <h4>
                      Alertas Terrestres
                      <div
                        className="float-right col-1 right"
                        style={{ "background-color": "rgba(6, 0, 0, 0.2)" }}
                        onClick={() =>
                          this.setState({
                            alertasCollase: !this.state.alertasCollase,
                          })
                        }
                      >
                        <Icon
                          name={
                            this.state.alertasCollase
                              ? "chevron-down"
                              : "chevron-up"
                          }
                          className="float-right"
                        />
                      </div>
                    </h4>
                  </div>
                  <div
                    className="panel panel-default"
                    hidden={this.state.alertasCollase}
                  >
                    <div
                      className="col-12 panel-resizable"
                      style={{ height: "75vh" }}
                    >
                      {this.props.getMovimiento.id &&
                      this.state.alertasT &&
                      this.state.alertasT[this.props.getMovimiento.id] ? (
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                          headerItems={[
                            { content: "ID" },
                            { content: "Tiggername" },
                            { content: "Atender" },
                          ]}
                        >
                          <Table.Body>
                            {(this.alertT = [])}
                            {this.state.alertasT[this.props.getMovimiento.id]
                              ? Object.keys(
                                  this.state.alertasT[
                                    this.props.getMovimiento.id
                                  ]
                                ).forEach((key, indice) =>
                                  //this.state.alertasT[this.props.getMovimiento.id][key].filter(word => word.alert.triggerName.toUpperCase().includes(this.state.searchAlertas.toUpperCase())).sort().map((elemento, index) =>
                                  this.state.alertasT[
                                    this.props.getMovimiento.id
                                  ][key]
                                    .filter((word) => word.alert.atendida !== 1)
                                    .sort()
                                    .map((elemento, index) =>
                                      this.alertT.push(
                                        <Table.Row>
                                          <Table.Col>
                                            {elemento.alert.id}
                                          </Table.Col>
                                          <Table.Col>
                                            {elemento.alert.triggerName}
                                          </Table.Col>
                                          <Table.Col>
                                            <ModalLayout
                                              tamanio={"60%"}
                                              title="Atender Alerta Terrestre"
                                              name={
                                                <Button
                                                  pill
                                                  size="sm"
                                                  outline
                                                  color="primary"
                                                >
                                                  <div
                                                    className={
                                                      "fa " + elemento.icono
                                                    }
                                                    style={{
                                                      color: elemento.color,
                                                    }}
                                                  ></div>
                                                </Button>
                                              }
                                              hiddenModal={
                                                this.state.hiddenModals
                                              }
                                              Fclose={this.hiddenmodal}
                                              formulario={
                                                <AlertaTForm
                                                  accion={"modificar"}
                                                  usuario={user}
                                                  alerta={elemento.alert}
                                                  getfuntion={this.getfuntion}
                                                  entregas={this.state.entregas}
                                                  stor={this.props}
                                                  closeModal={this.hiddenmodal}
                                                  IncidenciasFrom={
                                                    this.props.getMovimiento.plataforma.nombre
                                                      .toUpperCase()
                                                      .includes("TRAXION") ? (
                                                      <IncidenciasTraxion
                                                        accion={"nuevo"}
                                                        desde={"Panel"}
                                                        idusuario={user.id}
                                                        idmovimiento={
                                                          this.state
                                                            .idMovimiento
                                                        }
                                                        idplataforma={
                                                          this.state.movimiento
                                                            .plataforma.id
                                                        }
                                                        movimiento={
                                                          this.state.movimiento
                                                        }
                                                        unidad={
                                                          this.state.unidad
                                                        }
                                                        //idEntrega={this.state.idEntrega}
                                                        idEntregaActiva={
                                                          this.state
                                                            .idEntregaActiva
                                                        }
                                                        entregas={
                                                          this.state.entregas
                                                        }
                                                        incidencias={
                                                          this.state
                                                            .incidenciasAll
                                                        }
                                                        getfuntion={
                                                          this.getfuntion
                                                        }
                                                        stor={this.props}
                                                        plataformaTipo={
                                                          this.props
                                                            .plataformaTipo
                                                        }
                                                        gpsReciente={
                                                          this.props.gpsReciente
                                                            ? this.props
                                                                .gpsReciente[
                                                                this.state
                                                                  .movimiento.id
                                                              ]
                                                            : null
                                                        }
                                                        latitud={null}
                                                        longitud={null}
                                                        closeModal={
                                                          this.hiddenmodal
                                                        }
                                                      />
                                                    ) : (
                                                      <MovimientoIncidenciasTerrestre
                                                        accion={"nuevo"}
                                                        desde={"Panel"}
                                                        idusuario={user.id}
                                                        idmovimiento={
                                                          this.state
                                                            .idMovimiento
                                                        }
                                                        idplataforma={
                                                          this.state.movimiento
                                                            .plataforma.id
                                                        }
                                                        movimiento={
                                                          this.state.movimiento
                                                        }
                                                        incidencias={
                                                          this.state
                                                            .incidenciasAll
                                                        }
                                                        getfuntion={
                                                          this.getfuntion
                                                        }
                                                        stor={this.props}
                                                        //idEntrega={this.state.idEntrega}
                                                        idEntregaActiva={
                                                          this.state
                                                            .idEntregaActiva
                                                        }
                                                        entregas={
                                                          this.state.entregas
                                                        }
                                                        plataformaTipo={
                                                          this.props
                                                            .plataformaTipo
                                                        }
                                                        //gpsReciente={this.props.gpsReciente?this.props.gpsReciente[this.state.movimiento.id]:null}
                                                        gpsReciente={
                                                          this.props.gpsReciente
                                                            ? this.props
                                                                .gpsReciente[
                                                                this.props
                                                                  .getMovimiento
                                                                  .id
                                                              ]
                                                            : null
                                                        }
                                                        latitud={
                                                          elemento.alert.latitud
                                                        }
                                                        longitud={
                                                          elemento.alert
                                                            .longitud
                                                        }
                                                        closeModal={
                                                          this.hiddenmodal
                                                        }
                                                      />
                                                    )
                                                  }
                                                />
                                              }
                                            />
                                          </Table.Col>
                                        </Table.Row>
                                      )
                                    )
                                )
                              : null}
                          </Table.Body>
                        </Table>
                      ) : (
                        "  Sin Alertas Terrestres..!!"
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              {!this.props.plataformaTipo ? (
                <div>
                  <div className="col-12 bg-primary text-light">
                    {" "}
                    <h4>
                      Alertas
                      <div
                        className="float-right col-1 right"
                        style={{ "background-color": "rgba(6, 0, 0, 0.2)" }}
                      ></div>
                    </h4>{" "}
                  </div>
                  <h3>No hay alertas..!!</h3>
                </div>
              ) : null}
            </div>
            {/**
             * Incidencias
             */}
            <div className="col-12 bg-primary text-light">
              <h4>
                Incidencias
                <div
                  className="float-right col-1 right"
                  style={{ "background-color": "rgba(6, 0, 0, 0.2)" }}
                  onClick={() =>
                    this.setState({
                      incidenciasCollase: !this.state.incidenciasCollase,
                    })
                  }
                >
                  <Icon
                    name={
                      this.state.incidenciasCollase
                        ? "chevron-down"
                        : "chevron-up"
                    }
                    className="float-right"
                  />
                </div>
              </h4>
            </div>
            <div
              className="panel panel-default"
              hidden={this.state.incidenciasCollase}
            >
              <div
                className="col-12 panel-resizable"
                style={{ height: "75vh" }}
              >
                <Grid.Row>
                  <Grid.Col sm={8} lg={12}>
                    {this.props.getMovimiento.id ? (
                      <div>
                        <Grid.Row>
                          <Grid.Col sm={5} lg={5}>
                            {this.state.incidencias.length > 0 ? (
                              <Form.Input
                                className="mb-3"
                                icon="search"
                                placeholder="Buscar Incidencias..."
                                position="append"
                                onChange={this.searchingIncidencias}
                              />
                            ) : null}
                          </Grid.Col>

                          {this.props.plataformaTipo === "Naviera" &&
                          this.getFuncionalidad("Incidencias Detallada") ? (
                            <Grid.Col sm={2} lg={2}>
                              <ModalLayout
                                tamanio={"90%"}
                                title={
                                  "Detalle de Incidencias del movimiento (" +
                                  this.props.movimiento +
                                  ")"
                                }
                                name={
                                  <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                  >
                                    <Icon name="paperclip" />
                                  </Button>
                                }
                                hiddenModal={this.state.hiddenModals}
                                Fclose={this.hiddenmodal}
                                formulario={
                                  <IncidenciasNListar
                                    incidencias={this.state.incidencias}
                                  />
                                }
                              />
                            </Grid.Col>
                          ) : (
                            <div></div>
                          )}

                          {this.props.plataformaTipo === "Terrestre" &&
                          this.props.getMovimiento.plataforma.nombre
                            .toUpperCase()
                            .includes("TRAXION") ? (
                            <Grid.Col sm={2} lg={2}>
                              <ModalLayout
                                tamanio={"80%"}
                                title="Detalle de Incidencias"
                                name={
                                  <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                  >
                                    <Icon name="paperclip" />
                                    Todas
                                  </Button>
                                }
                                hiddenModal={this.state.hiddenModals}
                                Fclose={this.hiddenmodal}
                                formulario={<IncidenciasTraxionExportar />}
                              />
                            </Grid.Col>
                          ) : (
                            <div></div>
                          )}
                          {this.props.plataformaTipo === "Terrestre" ? (
                            <Grid.Col sm={2} lg={2}>
                              <ModalLayout
                                tamanio={"80%"}
                                title="Detalle de Incidencias"
                                name={
                                  <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                  >
                                    <Icon name="paperclip" />
                                    Mov
                                  </Button>
                                }
                                hiddenModal={this.state.hiddenModals}
                                Fclose={this.hiddenmodal}
                                formulario={
                                  <div>
                                    {this.props.getMovimiento.plataforma.nombre
                                      .toUpperCase()
                                      .includes("TRAXION") ? (
                                      <span
                                        className="float-right ml-2"
                                        style={{ width: "200px" }}
                                      >
                                        <Exportar
                                          catalogo={"Incidencias"}
                                          data={
                                            this.state.incidenciasTraxionExcel
                                          }
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        className="float-right ml-2"
                                        style={{ width: "200px" }}
                                      >
                                        <Exportar
                                          catalogo={"Incidencias"}
                                          data={this.state.incidenciasTerrestre}
                                        />
                                      </span>
                                    )}
                                    <Table
                                      cards={true}
                                      striped={true}
                                      responsive={true}
                                      className="table-vcenter bg-light"
                                      headerItems={[
                                        { content: "ID" },
                                        { content: "Incidencia" },
                                        { content: "Comentario" },
                                        { content: "Creado" },
                                        { content: "Atencion Usuario" },
                                      ]} //[{ content: "ID" }, { content: "Nombre" }]
                                    >
                                      <Table.Body>
                                        {this.props.plataformaTipo ===
                                        "Terrestre"
                                          ? this.state.incidenciasTerrestre
                                              .filter(
                                                (word) =>
                                                  word.comentario != null &&
                                                  word.comentario
                                                    .toUpperCase()
                                                    .includes(
                                                      this.state.searchIncidencias.toUpperCase()
                                                    )
                                              )
                                              .sort()
                                              .map((elemento, index) =>
                                                this.props.getMovimiento.plataforma.nombre
                                                  .toUpperCase()
                                                  .includes("TRAXION") ? (
                                                  <Table.Row>
                                                    <Table.Col>
                                                      {
                                                        elemento.idEntregaIncidencia
                                                      }
                                                    </Table.Col>
                                                    <Table.Col>
                                                      {
                                                        elemento.incidencia
                                                          .nombre
                                                      }
                                                    </Table.Col>
                                                    <Table.Col>
                                                      {elemento.comentario}
                                                    </Table.Col>
                                                    <Table.Col>
                                                      {elemento.fecha}
                                                    </Table.Col>
                                                    <Table.Col>
                                                      {elemento.monitorista
                                                        .nombre +
                                                        " " +
                                                        elemento.monitorista
                                                          .aPaterno +
                                                        " " +
                                                        elemento.monitorista
                                                          .aMaterno}
                                                    </Table.Col>
                                                  </Table.Row>
                                                ) : (
                                                  <Table.Row>
                                                    <Table.Col>
                                                      {
                                                        elemento.idEntregaIncidencia
                                                      }
                                                    </Table.Col>
                                                    <Table.Col>
                                                      {this.getIncidenciaNombre(
                                                        elemento.idIncidencia
                                                      )}
                                                    </Table.Col>
                                                    <Table.Col>
                                                      {elemento.comentario}
                                                    </Table.Col>
                                                    <Table.Col>
                                                      {elemento.creado}
                                                    </Table.Col>
                                                    <Table.Col>
                                                      {elemento.atencionUsuario}
                                                    </Table.Col>
                                                  </Table.Row>
                                                )
                                              )
                                          : null}
                                      </Table.Body>
                                    </Table>
                                  </div>
                                }
                              />
                            </Grid.Col>
                          ) : (
                            <div></div>
                          )}

                          <Grid.Col sm={2} lg={2}>
                            {this.props.plataformaTipo === "Naviera" &&
                            this.getFuncionalidad("Crear Incidencia") ? (
                              <ModalLayout
                                tamanio={800}
                                title="Nueva incidencia"
                                name={
                                  <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                  >
                                    <Icon name="plus" />
                                  </Button>
                                }
                                hiddenModal={this.state.hiddenModals}
                                Fclose={this.hiddenmodal}
                                formulario={
                                  <MovimientoIncidencias
                                    accion={"nuevo"}
                                    idusuario={user.id}
                                    idmovimiento={this.state.idMovimiento}
                                    incidencias={this.state.incidenciasAll}
                                    getfuntion={this.getfuntion}
                                    stor={this.props}
                                    plataformaTipo={this.props.plataformaTipo}
                                    closeModal={this.hiddenmodal}
                                    gpsReciente={
                                      this.props.gpsReciente
                                        ? this.props.gpsReciente[
                                            this.state.movimiento.id
                                          ]
                                        : null
                                    }
                                    latitud={null}
                                    longitud={null}
                                  />
                                }
                              />
                            ) : (
                              <div></div>
                            )}
                            {
                              //this.props.plataformaTipo === "Terrestre" && this.state.idEntregaActiva?
                            }
                            {this.props.plataformaTipo === "Terrestre" &&
                            this.getFuncionalidad("Crear Incidencia") ? (
                              <ModalLayout
                                tamanio={"70%"}
                                title="Nueva incidencia"
                                name={
                                  <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                  >
                                    <Icon name="plus" />
                                  </Button>
                                }
                                hiddenModal={this.state.hiddenModals}
                                Fclose={this.hiddenmodal}
                                formulario={
                                  this.props.getMovimiento.plataforma.nombre
                                    .toUpperCase()
                                    .includes("TRAXION") ? (
                                    <IncidenciasTraxion
                                      accion={"nuevo"}
                                      desde={"Panel"}
                                      idusuario={user.id}
                                      idmovimiento={this.state.idMovimiento}
                                      idplataforma={
                                        this.state.movimiento.plataforma.id
                                      }
                                      movimiento={this.state.movimiento}
                                      unidad={this.state.unidad}
                                      //idEntrega={this.state.idEntrega}
                                      idEntregaActiva={
                                        this.state.idEntregaActiva
                                      }
                                      entregas={this.state.entregas}
                                      incidencias={this.state.incidenciasAll}
                                      getfuntion={this.getfuntion}
                                      stor={this.props}
                                      plataformaTipo={this.props.plataformaTipo}
                                      gpsReciente={
                                        this.props.gpsReciente
                                          ? this.props.gpsReciente[
                                              this.state.movimiento.id
                                            ]
                                          : null
                                      }
                                      latitud={null}
                                      longitud={null}
                                      closeModal={this.hiddenmodal}
                                    />
                                  ) : (
                                    <MovimientoIncidenciasTerrestre
                                      accion={"nuevo"}
                                      desde={"Panel"}
                                      idusuario={user.id}
                                      idmovimiento={this.state.idMovimiento}
                                      idplataforma={
                                        this.state.movimiento.plataforma.id
                                      }
                                      movimiento={this.state.movimiento}
                                      //idEntrega={this.state.idEntrega}
                                      idEntregaActiva={
                                        this.state.idEntregaActiva
                                      }
                                      entregas={this.state.entregas}
                                      incidencias={this.state.incidenciasAll}
                                      getfuntion={this.getfuntion}
                                      stor={this.props}
                                      plataformaTipo={this.props.plataformaTipo}
                                      gpsReciente={
                                        this.props.gpsReciente
                                          ? this.props.gpsReciente[
                                              this.state.movimiento.id
                                            ]
                                          : null
                                      }
                                      latitud={null}
                                      longitud={null}
                                      closeModal={this.hiddenmodal}
                                    />
                                  )
                                }
                              />
                            ) : (
                              <div></div>
                            )}
                          </Grid.Col>
                        </Grid.Row>
                      </div>
                    ) : null}
                  </Grid.Col>
                </Grid.Row>

                {this.state.movimiento &&
                this.state.incidencias.length > 0 &&
                this.props.plataformaTipo === "Naviera" &&
                this.getFuncionalidad("Incidencias Rapida") ? (
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter bg-light"
                    headerItems={[
                      { content: "ID" },
                      { content: "Nombre" },
                      { content: "Comentario" },
                      { content: "Fecha" },
                    ]} //[{ content: "ID" }, { content: "Nombre" }]
                  >
                    <Table.Body>
                      {this.props.plataformaTipo === "Naviera"
                        ? this.state.incidencias
                            .filter((word) =>
                              word.incidencia.nombre
                                .toUpperCase()
                                .includes(
                                  this.state.searchIncidencias.toUpperCase()
                                )
                            )
                            .sort(function (a, b) {
                              if (a["fechaCreado"] === null) {
                                return -1;
                              }
                              if (a["fechaCreado"] < b["fechaCreado"]) {
                                return 1;
                              }
                              if (a["fechaCreado"] > b["fechaCreado"]) {
                                return -1;
                              }
                              return 0;
                            })
                            .map((elemento, index) => (
                              <Table.Row>
                                <Table.Col>{elemento.id}</Table.Col>
                                <Table.Col>
                                  {elemento.incidencia.nombre}
                                </Table.Col>
                                <Table.Col>{elemento.comentario}</Table.Col>
                                <Table.Col>{elemento.fechaCreado}</Table.Col>
                              </Table.Row>
                            ))
                        : null}
                    </Table.Body>
                  </Table>
                ) : null}
                {this.state.movimiento &&
                this.state.incidenciasTerrestre.length > 0 &&
                this.props.plataformaTipo === "Terrestre" ? (
                  !this.props.getMovimiento.plataforma.nombre
                    .toUpperCase()
                    .includes("TRAXION") ? (
                    <Table
                      cards={true}
                      striped={true}
                      responsive={true}
                      className="table-vcenter bg-light"
                      headerItems={[
                        { content: "ID" },
                        { content: "Incidencia" },
                        { content: "Comentario" },
                        { content: "Creado" },
                        { content: "Atencion Fecha" },
                      ]}
                    >
                      <Table.Body>
                        {this.state.incidenciasTerrestre
                          .filter(
                            (word) =>
                              word.comentario != null &&
                              word.comentario
                                .toUpperCase()
                                .includes(
                                  this.state.searchIncidencias.toUpperCase()
                                )
                          )
                          .sort(function (a, b) {
                            if (a["creado"] === null) {
                              return -1;
                            }
                            if (a["creado"] > b["creado"]) {
                              return 1;
                            }
                            if (a["creado"] < b["creado"]) {
                              return -1;
                            }
                            return 0;
                          })
                          .map((elemento, index) => (
                            <Table.Row>
                              <Table.Col>
                                {elemento.idEntregaIncidencia}
                              </Table.Col>
                              <Table.Col>
                                {this.getIncidenciaNombre(
                                  elemento.idIncidencia
                                )}
                              </Table.Col>
                              <Table.Col>{elemento.comentario}</Table.Col>
                              <Table.Col>{elemento.creado}</Table.Col>
                              <Table.Col>{elemento.atencionFecha}</Table.Col>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  ) : (
                    <Table
                      cards={true}
                      striped={true}
                      responsive={true}
                      className="table-vcenter bg-light"
                      headerItems={[
                        { content: "ID" },
                        { content: "Incidencia" },
                        { content: "Comentario" },
                        { content: "Creeado" },
                        { content: "Editar" },
                      ]}
                    >
                      <Table.Body>
                        {this.state.incidenciasTerrestre
                          .filter((word) =>
                            word.comentario
                              .toUpperCase()
                              .includes(
                                this.state.searchIncidencias.toUpperCase()
                              )
                          )
                          .sort(function (a, b) {
                            if (a["fecha"] === null) {
                              return -1;
                            }
                            if (a["fecha"] > b["fecha"]) {
                              return 1;
                            }
                            if (a["fecha"] < b["fecha"]) {
                              return -1;
                            }
                            return 0;
                          })
                          .map((elemento, index) => (
                            <Table.Row>
                              <Table.Col>
                                {elemento.idEntregaIncidencia}
                              </Table.Col>
                              <Table.Col>
                                {elemento.incidencia.nombre}
                              </Table.Col>
                              <Table.Col>{elemento.comentario}</Table.Col>
                              <Table.Col>{elemento.fecha}</Table.Col>
                              <Table.Col>
                                {/*
                                  <ModalLayout
                                    tamanio={"70%"}
                                    title="Editar incidencia"
                                    name={<Button pill size="sm" outline color="primary"><Icon name="edit" /></Button>}
                                    hiddenModal={this.state.hiddenModals}
                                    Fclose={this.hiddenmodal}
                                    formulario= {
                                                  <IncidenciasTraxion
                                                  accion={'modificar'}
                                                  idusuario={user.id}
                                                  idmovimiento={this.state.idMovimiento}
                                                  idplataforma={this.state.movimiento.plataforma.id}
                                                  movimiento={this.state.movimiento}
                                                  unidad= {this.state.unidad}
                                                  idEntregaActiva={this.state.idEntregaActiva}
                                                  entregas={this.state.entregas}
                                                  incidencia={elemento}
                                                  getfuntion={this.getfuntion}
                                                  stor={this.props}
                                                  plataformaTipo={this.props.plataformaTipo}
                                                  gpsReciente={this.props.gpsReciente? this.props.gpsReciente[this.state.movimiento.id]:null}
                                                  latitud= { null }
                                                  longitud= { null }
                                                  closeModal={this.hiddenmodal}
                                                />
                                            }
                                          />*/}
                              </Table.Col>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  )
                ) : null}
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <h3>Nota</h3>
            <p>{this.props.getMovimiento.nota}</p>
            <h3>Operador</h3>
            <p>{this.props.getMovimiento.nombreOperador}</p>
          </Card.Footer>
        </Card>
      </div>
    );
  }
  getIncidenciaNombre = (id) => {
    for (let item of this.state.incidenciasAll) {
      if (id === item.id) {
        return item.nombre;
      }
    }
    return "";
  };
}
export default Panel;
