// @flow
import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";


class  InformeFuncionalidadesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
          id: "" ,
      nombre: "",
 descripcion: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      };

      this.enableSave = this.enableSave.bind(this);
}

  componentDidMount =() => {
    console.log(this.props);
    if(this.state.accion === 'nuevo'){
    }else{
      this.setState({id: this.props.elementos.id});
      this.setState({nombre: this.props.elementos.nombre});
      this.setState({descripcion: this.props.elementos.descripcion});
    }
  }

  enableSave =() =>{
    console.log("Enable button: "+this.state.nombre)
    if(this.state.nombre.length > 0){
      return false;
    }else{ return true }
  }

  changeName =(event) =>{
    this.setState({nombre: event.target.value});
    //console.log("nombre: " + this.state.nombre);
  }
  changeDescripcion =(event) =>{
    this.setState({descripcion: event.target.value});
  }

  delete()    { this.props.getfuntion("delete", "", this.state.id); }
  crud_put()  { this.props.getfuntion("put", { "id":this.state.id, "nombre":this.state.nombre, "descripcion":this.state.descripcion}); }
  crud_post() { this.props.getfuntion("post",{ "nombre":this.state.nombre, "descripcion":this.state.descripcion}); }

  render() {
    return (
<div>
  { this.state.accion === "modificar"? <h1>{ this.props.elementos.nombre }</h1>:null }
  <Grid.Row>
    <Grid.Col md={12} xl={12}>
      <Grid.Row>
        <Grid.Col md={3} lg={3}>
          <Form.Group label="id">
          <Form.Input name="id" disabled type="number" value={this.state.id} />
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={9} lg={9}>
          <Form.Group label="Nombre">
            <Form.Input name="nombre" type="text" value={ this.state.nombre } onChange={this.changeName} maxlength="30"/>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={9} lg={9}>
          <Form.Group label="Descripcion">
            <Form.Input name="descripcion" type="text" value={ this.state.descripcion } onChange={this.changeDescripcion} maxlength="30"/>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
    </Grid.Col>
  </Grid.Row>

  <div className="float-right">
    <span className="badge">
    <Button
      target="_blank" size="sm" RootComponent="a" color="primary"
      disabled={ this.enableSave() }
      onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
      <span className="badge"><Icon link={true} name="save" />Guardar</span>
    </Button>
    </span>
    { this.state.accion === "modificar"? <span className="badge">
    <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick= { () => this.delete()} >
      <span className="badge"><Icon link={true} name="trash" />Borrar</span>
    </Button>
    </span>
    :
    null
    }
  </div>
</div>
    );
  }
}
export default InformeFuncionalidadesForm;
