import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../herramientas/Crud_Catalogos';
import Select from "react-select";
import ModalLayout from "./ModalLayout.react";
import EtaCalculada from '../Monitoristas/Monitoreo/panelForms/EtaCalculada';
import { Exportar } from '../herramientas/Excel';
//import Entregas from "../Monitoristas/Monitoreo/panelForms/Entregas";
import {MonitoreoNavieroForm, MonitoreoTerrestreForm} from "../Monitoristas";
import {  ActualizarHorariosNavieros, ActualizarHorariosTerrestres, Entregas  } from "../Monitoristas/Monitoreo/panelForms";
import DateTimePicker from "react-datetime-picker";
import { timeToDateFill } from "../herramientas/DateFormat";

import { timingSafeEqual } from "crypto";
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class IncompletosTerminados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      elementos: [],
      search: "",
      fecha1: new Date(new Date().getTime()-(1000*60*60*24*7)),
      fecha2: new Date(),
      pagination: [],
      pagina: 1,
      pagesize: 10,
      tableheaderItems: [],
      funcionalidadesCol: [],
      plataforma: { value: 0, label: "Todas ...", search: "" },
      hiddenModals: false,
      movimientoAcumuladores: {},
      entregas:{}
    };
    this.pagination=[];
    this.funcionalidadesCol= [];
    this.elementos = [];
    this.plataformas=[];
    this.movimientoAcumuladores={};
    this.entregas= {};
    this.content = [];
    this.colum = [];
    this.colToFilter= "po";
  }
  componentDidMount = () => {
      this.props.columnas.map((columna, index) => { //////////////////columnas
        if((columna.nombre !== "editar" || this.props.incompletos && columna.nombre == "editar" ) && columna.nombre !== "notificaciones" ){
          if( columna.nombre !== "eta" && columna.nombre !== "gps" && columna.nombre !== "alertas"){
            if(!this.props.incompletos){
              //if(columna.nombre !== "edit"){
                this.content.push({ content:
                  <div onClick={() => {this.sortElements(columna.nombre)} }>
                    {columna.nombre}{this.colToFilter === columna.nombre? <Icon name={this.state.AscDesc?"chevron-up":"chevron-down"}/> : null}
                  </div> });
              //}
            }else{
              this.content.push({ content:
                <div onClick={() => {this.sortElements(columna.nombre)} }>
                  {columna.nombre}{this.colToFilter === columna.nombre? <Icon name={this.state.AscDesc?"chevron-up":"chevron-down"}/> : null}
                </div> });
            }
          }
        }
      });
      if(this.props.plataformaTipo === "Naviera"){
        this.buscar(this.state.plataforma.value);
      }
      if(this.props.plataformaTipo === "Terrestre"){
        console.log("69 incompletosTerminados");
        this.getfuntion("get", "", "", "terrestreMovimientos/terminados/usuario/"+currentUser[0].usuario.id, "Terrestre");
        this.funcionalidadesCol= this.props.columnas;
        this.content.push({ content: "entregas" });
        this.funcionalidadesCol.push({content: "entregas", columna: "entregas", nombre: "entregas"});
      }
      this.setState({ tableheaderItems: this.content });
      this.setState({ funcionalidadesCol: this.funcionalidadesCol });

      this.plataformas.push({ value: 0, label: "Todas ...", search: "" });
      currentUser.map((usr, index) => {
        if(usr.plataforma.plataformaTipo.nombre =='Naviera'){
          this.plataformas.push({ value: usr.plataforma.id, label: usr.plataforma.nombre, search: usr.plataforma.nombre });
        }
      });
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "navieraMovimientos", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "Navieras" && returnVal.content) {
              this.elementos = returnVal.content;
              this.setState({ elementos: returnVal.content });
              this.paginacion(returnVal);
              /**
               * Iniciar Eta acumuladores
               */
              this.AcumuladoresEta();
            }
            if (stateVar === "Terrestre") {
              for( let item of  returnVal ){
                this.getfuntion("get", "", "", "entregas/movimiento/" + item.id, "entregas");
              }
              this.elementos = returnVal;
              this.setState({ elementos: returnVal });
            }
            if (stateVar === "entregas") {
              for( let item of  returnVal ){
                if( !this.entregas[item.idMovimiento] ){ this.entregas[item.idMovimiento]=[] }
                this.entregas[item.idMovimiento].push(item);
              }
              this.setState({ entregas: this.entregas });
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { if (err.response) {console.log(err.response);} else { console.log("Error desconocido ...."); } });
  }
  paginacion=(pag)=>{ this.pagination=pag;  this.pagination.content=[];   this.setState({pagination: this.pagination}); }
  buscar=(plataforma, fecha1, fecha2, pag, size)=>{
    /**
     * params plataforma tipo, usuario
     */
    let plt= plataforma !== null? plataforma: this.state.plataforma.value;
    let f1= fecha1? fecha1: this.state.fecha1;
    let f2= fecha2? fecha2: this.state.fecha2;
    let pagTem= pag? pag: this.state.pagina-1;
    let pagsize= size? size: this.state.pagesize;
    if(this.props.plataformaTipo === "Naviera"){
      if(!this.props.incompletos){
        console.log("135 GET IncompletosTerminados ");
        switch (this.props.mtipo) {
          case 0:
            this.getfuntion("get", "", "", "navieraMovimientos/terminados/usuario/"+currentUser[0].usuario.id+"/plt/"+plt+"/fecha1/"+timeToDateFill(f1.getTime())+"/fecha2/"+timeToDateFill(f2.getTime())+"/pagina/"+pagTem+"/total/"+pagsize, "Navieras");
            break;
          case 1:
            this.getfuntion("get", "", "", "navieraMovimientos/terminados/usuario/"+currentUser[0].usuario.id+"/plt/"+plt+"/mtipo/"+1+"/fecha1/"+timeToDateFill(f1.getTime())+"/fecha2/"+timeToDateFill(f2.getTime())+"/pagina/"+pagTem+"/total/"+pagsize, "Navieras");
            break;
          case 2:
            this.getfuntion("get", "", "", "navieraMovimientos/terminados/usuario/"+currentUser[0].usuario.id+"/plt/"+plt+"/mtipo/"+2+"/fecha1/"+timeToDateFill(f1.getTime())+"/fecha2/"+timeToDateFill(f2.getTime())+"/pagina/"+pagTem+"/total/"+pagsize, "Navieras");
            break;
          default:
            break;
        }
      }else{
        switch (this.props.mtipo) {
          case 0:
            this.getfuntion("get", "", "", "navieraMovimientos/terminadosDatosIncompletos/usuario/"+currentUser[0].usuario.id+"/plt/"+plt+"/fecha1/"+timeToDateFill(f1.getTime())+"/fecha2/"+timeToDateFill(f2.getTime())+"/pagina/"+pagTem+"/total/"+pagsize, "Navieras");
            break;
          case 1:
            this.getfuntion("get", "", "", "navieraMovimientos/terminadosDatosIncompletos/usuario/"+currentUser[0].usuario.id+"/plt/"+plt+"/mtipo/"+1+"/fecha1/"+timeToDateFill(f1.getTime())+"/fecha2/"+timeToDateFill(f2.getTime())+"/pagina/"+pagTem+"/total/"+pagsize, "Navieras");
            break;
          case 2:
            this.getfuntion("get", "", "", "navieraMovimientos/terminadosDatosIncompletos/usuario/"+currentUser[0].usuario.id+"/plt/"+plt+"/mtipo/"+2+"/fecha1/"+timeToDateFill(f1.getTime())+"/fecha2/"+timeToDateFill(f2.getTime())+"/pagina/"+pagTem+"/total/"+pagsize, "Navieras");
            break;
          default:
            break;
        }
      }

      this.funcionalidadesCol= this.props.columnas;
    }
  }
  AcumuladoresEta=() =>{
    //Aqi va el codigo
    this.movimientoAcumuladores={};
    /*
    this.elementos.map((columna, index) => {
      this.movimientoAcumuladores[columna.id]= 0;

      Crud_Catalogos("movimientoAcumuladores/etasMovimiento/"+columna.id, "movimientoAcumuladores", "get").then((returnVal) => {
        this.movimientoAcumuladores[columna.id]= returnVal;
        this.setState({ movimientoAcumuladores: this.movimientoAcumuladores });
        console.log(  returnVal );
    }).catch(err => {
        if (err.response) {
            console.log(err);
        } else {
        }
    });
    });
    */

    //setTimeout(this.AcumuladoresEta,  20000);//1000*60*1
  }
  hiddenmodal = () => { this.setState({ hiddenModals: !this.state.hiddenModals }); }

  sortElements=(columna) =>{
    if(this.colToFilter== columna){  this.setState({ AscDesc: !this.state.AscDesc });  }else{ this.setState({ AscDesc: true }); }
    this.colToFilter= columna;
    var originalArray= this.state.elementos;
    if( this.state.AscDesc ){
      originalArray= originalArray.sort(function(a,b) {
        if (a[columna] > b[columna]) {
          return 1;
        }
        if (a[columna] < b[columna]) {
          return -1;
        }
        return 0;
      });
        //return a[columna]-b[columna] }); //Asecendente
    }else{
      originalArray= originalArray.sort(function(a,b) {
        if (a[columna] < b[columna]) {
          return 1;
        }
        if (a[columna] > b[columna]) {
          return -1;
        }
        return 0;
      });
        //return b[columna]-a[columna] }); //Descendente
    }
    this.setState({ elementos: originalArray });
  }

  changePlataformas = (event) => {
    this.setState({ plataforma: event });
    this.buscar(event.value);
  }
  searching = (event) => {
    this.setState({ search: event.target.value })
  }
  objectFilter =(columna) =>{
    if(columna){
      if( columna.nombre ){ return columna.nombre;  }else{ return columna; }
    }else{return "" }
  }
  isobject = () => {
    if (typeof this.columna === "object") {
      if (this.columna.length > 1) {
        for (let item of this.columna) {
          this.colum.push(<Table.Col>{item["nombre"]}</Table.Col>);
          break;
        }
      }
      else {
        if (this.columna.length === 1) {
          for (let item of this.columna) {
            this.colum.push(<Table.Col>{item["nombre"]}</Table.Col>);
            break;
          }
        }
        else {
          this.colum.push(<Table.Col>{this.columna["nombre"] ? this.columna["nombre"] : this.columna["username"]}</Table.Col>);
        }
      }

    }
  }
  onChangeFecha1 = (date)  => {
    this.setState({ fecha1: date });
    this.buscar(null, date);
  }
  onChangeFecha2 = (date)  => {
    this.setState({ fecha2: date });
    this.buscar(null, null, date);
  }
  render() {
    return (
      <div>
        {
          /**
          *   Elementos de plataformas
          */
        }
        <Card>
          <Card.Header>
            <Card.Options>
              <span className="float-right ml-2" style={{ "width": "25%" }}>
                <Select value={this.state.plataforma} onChange={this.changePlataformas} options={this.plataformas} />
              </span>
              <span className="float-right ml-2" style={{ "width": "35%" }}>
                <DateTimePicker format="y-MM-dd h:mm:ss a" onChange={this.onChangeFecha1}  value={this.state.fecha1} />
              </span>
              <span className="float-right ml-2"> - </span>
              <span className="float-right ml-2" style={{ "width": "35%" }}>
                <DateTimePicker format="y-MM-dd h:mm:ss a" onChange={this.onChangeFecha2}  value={this.state.fecha2} />
              </span>
              <span className="float-right ml-2" style={{ "width": "25%" }}>
                <Form.Input className="lg-12" icon="search" placeholder={this.colToFilter} position="append" onChange={this.searching} />
              </span>
              <span className="float-right ml-2" style={{ "width": "25%" }}>
                <Exportar crud={this.getfuntion} catalogo={"Export Movimientos Terminados "+(this.props.incompletos?"(Datos Incomletos)":"Datos completos")} data={this.state.elementos} />
              </span>
            </Card.Options>
          </Card.Header>
          <Card.Body>
              <Table cards={true} striped={false} responsive={true} className="table-vcenter" headerItems={this.state.tableheaderItems} >
                <Table.Header></Table.Header>

                <Table.Body>
                {this.state.elementos ? //.filter(word => word.po.toUpperCase().includes(this.state.search.toUpperCase()) || word.booking.toUpperCase().includes(this.state.search.toUpperCase()) || word.contenedor.toUpperCase().includes(this.state.search.toUpperCase())).sort()
                    this.state.elementos.filter(word =>
                                          word.idPlataforma.nombre.includes(this.state.plataforma.search) &&
                                          ( !Number.isInteger(word[this.colToFilter])? //Si se filtra texto
                                              this.objectFilter(word[this.colToFilter]).toUpperCase().includes(this.state.search.toUpperCase())
                                              : this.state.search !=""?
                                                parseInt(word[this.colToFilter], 10) === parseInt(this.state.search, 10)? true : false //Si se filtra numero o boolean
                                            : true
                                          )
                                          ).sort().map((elemento, index) =>


                      <Table.Row>
                        {this.colum=[]}
                        {
                          this.state.funcionalidadesCol.map((columna, index) => {
                            if (typeof elemento[columna.columna] === "object" && elemento[columna.columna] !== null) {
                              this.columna = elemento[columna.columna];
                              this.fila = elemento;
                              this.isobject();
                            } else {
                              switch (columna.columna) {
                                case "edit":
                                    if(this.props.plataformaTipo === "Naviera" && this.props.stor){
                                      this.colum.push(
                                        <Table.Col>{/*
                                          <span className="float-left ml-2">
                                            <ModalLayout  title="Modificar"
                                                          name={  <div  className="badge" style={{ color: "green", "font-size": "120%" }} data-toggle="tooltip" data-placement="top" title="Editar movimiento">
                                                                    <span className="fa fa-edit"></span>
                                                                  </div>}
                                                          hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                                                      formulario={
                                                                        <MonitoreoNavieroForm accion="modificar" elementos={elemento} getfuntion={this.getfuntion} stor={this.props.stor} />}
                                            />
                                          </span>*/}
                                          <span className="float-left ml-2">
                                            <ModalLayout tamanio={1200} title="Actualizar horarios"
                                              name={<Button pill size="sm" outline color="primary"><Icon name="upload" /><Icon name="clock" /></Button>}
                                              hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                              formulario={<ActualizarHorariosNavieros
                                                accion={'modificar'}
                                                idusuario={currentUser[0].id}
                                                movimiento={elemento}
                                                getfuntion={this.getfuntion}
                                                stor={this.props.stor}
                                                rutasEstatusObject={this.props.stor.rutasEstatus_object}
                                                operacionesEstatus={this.props.stor.operacionesEstatus_object}
                                                closeModal={this.hiddenmodal}
                                              />}
                                            />
                                          </span>
                                        </Table.Col>
                                      );
                                    }
                                  break;
                                case "alertas":
                                  break;
                                case "entregas":
                                  this.colum.push(
                                    <Table.Col>
                                      <ModalLayout tamanio={"100%"} title="Entregas"
                                        name={ <div className="badge bg-secondary"><Icon name="truck" />
                                        <span>{this.state.entregas[elemento.id]? this.state.entregas[elemento.id].length: 0}</span> </div> }
                                        hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                        formulario={ <Entregas obj={elemento} setIdEntrega={null} /> }
                                      />
                                    </Table.Col>);
                                  break;
                                case "eta":
                                  break;
                                case "gps":
                                    break;
                                default: this.colum.push(<Table.Col>{elemento[columna.columna]}</Table.Col>);
                                  break;
                              }
                            }
                          })
                        }
                      </Table.Row>
                    )
                    : <Table.Row><Table.Col> No hay Movimientos ..! </Table.Col></Table.Row>}
                </Table.Body>

              </Table>
          </Card.Body>
          <Card.Footer>
          <div className="row">
            <div className="col-sm-0 col-lg-2"></div>
            <div className="col-sm-12 col-lg-6">
              <nav aria-label="...">
                <ul className="pagination">
                  <li className="page-item" >
                    <span className="page-link" onClick={()=> this.state.pagination? this.state.pagination.number > 0 ? this.buscar(null, null, null, this.state.pagination.number-1) :null:null}>Anterior</span>
                  </li>
                  { this.getpaginationLinks() }
                  <li className="page-item">
                    <a className="page-link" onClick={()=> this.state.pagination? this.state.pagination.number < this.state.pagination.totalPages ? this.buscar(null, null, null, this.state.pagination.number+1):null:null }>Siguiente</a>
                  </li>
                  <li className="page-item"><h3>
                    <div className="row">
                      <div className="col-4 text-right"> <Form.Input name="pag212" type="number" value={ this.state.pagina}  onKeyDown={this._buscar}  onChange={this._pag} /> </div>
                      <div className="col-8 text-left"> /{ this.state.pagination? this.state.pagination.totalPages? this.state.pagination.totalPages:1:0 }Páginas </div>
                    </div>
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-sm-6 col-lg-2">
              <div className="row">
                  <div className="col-6 text-right"> Total por página </div>
                  <div className="col-6"> <Form.Input name="pagTotal" type="number" value={ this.state.pagesize } onChange={this._pagesize} /> </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2">
              <div className="col-6 text-right"> Total {this.state.pagination? this.state.pagination.totalElements: 0}</div>
            </div>
          </div>
          </Card.Footer>
        </Card>
      </div>
    )
  }
  getpaginationLinks=()=>{
    var links=[];     var numberPag= this.pagination.number;
    if(this.pagination){
      for(let i= numberPag; i<numberPag+5; i++ ){
        if(i === numberPag){  links.push(<li className="page-item active" aria-current="page"> <span className="page-link"> {i+1} <span className="sr-only">(current)</span> </span> </li>);
        }else{
          if(i < this.pagination.totalPages ){ links.push(<li className="page-item"><a className="page-link" onClick={()=> this.buscar(null, null, null, i)}>{i+1}</a></li>); }
        }
      }
    }
    return links;
  }
  _pagesize=(e)=> this.setState({pagesize: e.target.value });
  _buscar=(e)=> { if (e.key === 'Enter') { if(this.pagination.totalPages){ this.buscar(null, null, null, null, e.target.value-1);} } }
  _pag=(event)=> { this.setState({ pagina: event.target.value > this.pagination.totalPages ? this.pagination.totalPages: this.pagination.totalPages? event.target.value: 1 }); }
}

export default IncompletosTerminados
