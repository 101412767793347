import React, { useEffect } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import Select from "react-select";

export const CustomSelect = ({ control, name, rules = {}, text, data, col, required}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <div className={col}>
          <label className={`form-label ${required ? 'required' : ''}`}>{text}</label>
          <Select
            ref={ref}
            placeholder="Selecciona una opción"
            options={data?.map((value) => {
              return {
                value: value.id,
                label: value.nombre,
              };
            })}
            onChange={(value) => {
              onChange({
                id: value.value,
                nombre: value.label,
              });
            }}
          />
          {error && (
            <small clasName="text-muted" style={{ color: "red" }}>
              {error.message || "Error"}
            </small>
          )}
        </div>
      )}
    />
  );
};

export default CustomSelect;
