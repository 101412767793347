import React, { Component } from 'react';
import { Grid, Form, Card, Button, Icon, Table } from "tabler-react";
import ModalLayout from "../../components/ModalLayout.react";

import axios from 'axios';
import './excel.css';
import ActivosForm from './ActivosForm.js';
function subCadena(str, I) {
  //console.log("Str: " + str + " I: " + I);//var res = str.split(-I);
  var res = str.slice(0,I); //console.log("Res: " + res);
  return res;
}
function obtenAtributos() {
  var tempo = window.location.href;
  var itemp = tempo.length;
  var i = itemp; //console.log("tempo: " + tempo + " length: " +  itemp);
  do {  //console.log("Do i: "+ i );
    let stext = tempo.charAt(i);
    if (stext=="/"){  //console.log("stext: " + stext + " i: " + i );
      return subCadena(tempo, i) + "/manuales/";
      break;
    } i=i-1;
  }while(i>0);
  return subCadena(tempo, i) + "/manuales/";
}
var user = JSON.parse(sessionStorage.getItem("usuario"));
let nombreActivos="";
class Terminado extends Component
{
  constructor(props) {
    super(props);
    this.state = {
                catalogo:this.props.catalogo                |"",
                     url:this.props.url										  |"",
                download:this.props.download                |"",
                  filtro:this.props.filtro                  |"",
  //Variables
                  accion:this.props.accion,
									  data:this.props.data										|[],
    };
    this.cita=0;
  }
  componentDidMount(){

  }
  componentWillMount(){//obtenAtributos()
    console.log("228 this.props.catalogo");
    console.log(this.props.catalogo);
    switch (this.props.catalogo) {
      default:
        nombreActivos=this.props.catalogo;
        break;
    }
    this.setState({ url: obtenAtributos() });//this.setState({ url:  "http://localhost:3000/manuales/" });
    this.setState({ download:""+nombreActivos+".pdf" });
  }

render() {
    return (
      <div>
        <ModalLayout
          tamanio={'80%'}
          title="Movimientos Activos"
          name={
            <Button pill size="sm" className="btn-success">Act</Button>
          }
          hiddenModal={this.state.hiddenModals}
          Fclose={this.hiddenmodal}
          formulario={
            <ActivosForm
              accion={'modificar'}
              tipos={'todas'}
              desde={"Monitoreo.react"}
              usuario={user}
            />
          }
        />
      </div>
    );
  }
}

export default Terminado;
