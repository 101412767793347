import React, { Component } from "react";
import { Page, Card, Grid, Form, Button, Dropdown, Table } from "tabler-react";
import Select from "react-select";
import axios from 'axios';
import Modal from 'react-modal';
import * as conf from "../../config/config";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import ModalLayout from "../../components/ModalLayout.react";
import { timeToDateFill } from "../../herramientas/DateFormat";
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));


class UsuarioConsolaForm extends Component {
    constructor() {
        super();
        this.state = {
            ids:[],
            corredores: [],
            tabla_usuario: false,
            usuarios: [],
            nombreUsuario: "",
            active: null,
            tabla_corredores: false,
            plataforma: {value: 0, label:"Seleccione una plataforma"},
            usuariosCorredores: [],
            nombreUsuarioId: "",
            turno: "1",
            nombrePantallaId: "",
            modalIsOpen: false,
            salasMonitoreo: [],
            salaMonitorOcupada: [],
            idusuarioSala: [],
            idSalas: [],
            currentPerfil: null,
            hiddenModals: false,
            navieras: [],
            transportistas:[],
            movimientosTipo: [],
            contenedorTipo: [],

            pcorredores: [],
            pnavieras: [],
            pmovimientoTipos: [],
            pTipoCargas: [],
            pTransportistas:[]
        };

        this.closeModal = this.closeModal.bind(this);
        this.plataformas= [];
    }



    closeModal() {
        this.setState({modalIsOpen: false});
    }
    componentWillMount(){
        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
        currentUser.map((usr, index) => {
          if(usr.plataforma.plataformaTipo.nombre.toUpperCase().includes("NAVIERA") ){
            this.plataformas.push({ value: usr.plataforma.id, label: usr.plataforma.nombre });
          }
        });

        axios.get(conf.api_raiz+"usuariosPantalla").then(res => {
            console.log(res);
            const salaMonitorOcupada = res.data
            this.setState({salaMonitorOcupada})
        });

    }
    hiddenmodal = () => { this.setState({ hiddenModals: !this.state.hiddenModals }); }

    changePlataforma = (event) =>{
        this.setState({ plataforma: event });

        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
        axios.get(conf.api_raiz+"usuarios/plataforma/"+event.value).then(res => {
            const usuarios = res.data
            this.setState({usuarios})
        });
        this.getfuntion("get", "", "", "corredores/plataforma/"+event.value, "corredores");

        axios.get(conf.api_raiz+"salasMonitoreo").then(res => {
            const salasMonitoreo = res.data
            this.setState({salasMonitoreo})
        });

        this.state.salaMonitorOcupada.map((salasMonitorOcu, index) =>
            this.state.idusuarioSala.push(salasMonitorOcu.idUsuario));
        this.state.salaMonitorOcupada.map((salasMonitorOcu, index) =>
            this.state.idSalas.push(salasMonitorOcu.idSalaMonitoreo));

        this.setState({tabla_usuario: true});
        this.setState({tabla_corredores: false});

    }

    editarCorredor(id, nombre){
        this.setState({nombreUsuario: nombre});
        this.setState({nombreUsuarioId: id});
        this.actualizainformacion(id);
        this.setState({tabla_corredores: true});
    }
    actualizainformacion = (id) => {
        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
        this.getfuntion("get", "", "", "usuariosCorredores/usuario/"+id+"/plataforma/"+this.state.plataforma.value, "usuariosCorredores");
    }
    agregaroaliminar(idCorredor){
        var fecha = new Date(); //Fecha actual
        var mes = fecha.getMonth()+1; //obteniendo mes
        var dia = fecha.getDate(); //obteniendo dia
        var ano = fecha.getFullYear(); //obteniendo año
        var hora = fecha.getHours();
        var minuto = fecha.getMinutes();
        var seg = fecha.getSeconds();
        if(dia<10)
            dia='0'+dia; //agrega cero si el menor de 10
        if(mes<10)
            mes='0'+mes;
        let fechaIngresar = ano+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+seg;
        let arrayes = [] ;
        this.state.usuariosCorredores.map((usuarioCorredor, index) =>
            arrayes.push(usuarioCorredor.idCorredor)
        );
        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';

        if (arrayes.indexOf(idCorredor) === -1) {
            axios.post(conf.api_raiz+"usuariosCorredores", {
                    "idUsuario": this.state.nombreUsuarioId,
                    "idCorredor": idCorredor,
                    "idPlataforma": this.state.plataforma.value,
                    "fechaAsignacion": fechaIngresar,
                    "turnoMonitoreo": this.state.turno
                }).then(res => {

                this.actualizainformacion(this.state.nombreUsuarioId);

            });
        }
        if (arrayes.indexOf(idCorredor) > -1){
            axios.delete(conf.api_raiz+"usuariosCorredores/"+this.state.nombreUsuarioId+"/"+idCorredor+"/"+this.state.plataforma.value).then(res => {
                this.actualizainformacion(this.state.nombreUsuarioId);
            });
        }
    }
    agregarPantalla(idPantallaUsuario){
        var fecha = new Date(); //Fecha actual
        var mes = fecha.getMonth()+1; //obteniendo mes
        var dia = fecha.getDate(); //obteniendo dia
        var ano = fecha.getFullYear(); //obteniendo año
        var hora = fecha.getHours();
        var minuto = fecha.getMinutes();
        var seg = fecha.getSeconds();
        if(dia<10)
            dia='0'+dia; //agrega cero si el menor de 10
        if(mes<10)
            mes='0'+mes;
        let fechaIngresar = ano+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+seg;
        let arrayes = [] ;


        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';

        if (arrayes.indexOf(idPantallaUsuario) === -1) {
            axios.post(conf.api_raiz+"usuariosPantalla", {
                    "idUsuario": idPantallaUsuario,
                    "idPlataforma": this.state.plataforma.value,
                    "fechaAsignacion": fechaIngresar
                }).then(res => {
            });
        }
        if (arrayes.indexOf(idPantallaUsuario) > -1){
            axios.delete(conf.api_raiz+"usuariosPantalla/"+idPantallaUsuario+"/"+this.state.plataforma.value).then(res => {
            });
        }
        axios.get(conf.api_raiz+"usuariosPantalla").then(res => {
            const salaMonitorOcupada = res.data
            this.setState({salaMonitorOcupada})
        });
    }
    actualizaUsuarios(){
        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
        axios.get(conf.api_raiz+"usuariosPantalla").then(res => {
            const salaMonitorOcupada = res.data
            this.setState({salaMonitorOcupada})
        });

    }
    salaMonitoreoRegistro(e, idUsuario) {

        var salaid = e.target.value;
        var fecha = new Date(); //Fecha actual
        var mes = fecha.getMonth()+1; //obteniendo mes
        var dia = fecha.getDate(); //obteniendo dia
        var ano = fecha.getFullYear(); //obteniendo año
        var hora = fecha.getHours();
        var minuto = fecha.getMinutes();
        var seg = fecha.getSeconds();
        if(dia<10)
            dia='0'+dia; //agrega cero si el menor de 10
        if(mes<10)
            mes='0'+mes;
        let fechaIngresar = ano+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+seg;

        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';

        axios.get(conf.api_raiz+"usuariosPantalla/"+idUsuario).then(res => {
            if(Object.keys(res.data).length === 0){
                axios.post(conf.api_raiz+"usuariosPantalla", {
                        "idUsuario": idUsuario,
                        "idSalaMonitoreo": salaid,
                        "fechaAsignacion": fechaIngresar
                    }).then(res => {
                        this.actualizaUsuarios();
                });

            }else{
                axios.delete(conf.api_raiz+"usuariosPantalla/"+idUsuario).then(res => {
                    axios.get(conf.api_raiz+"usuariosPantalla").then(res => {
                        const salaMonitorOcupada = res.data;
                        this.setState({salaMonitorOcupada});
                    });
                    if(Object.keys(res.data).length === 0){
                        axios.post(conf.api_raiz+"usuariosPantalla", {
                                "idUsuario": idUsuario,
                                "idSalaMonitoreo": salaid,
                                "fechaAsignacion": fechaIngresar
                            }).then(res => {
                                this.actualizaUsuarios();
                        });

                    }
                });

            }

        });

    }
    myColor(position) {
        let arrayes = [] ;
        this.state.usuariosCorredores.map((usuarioCorredor, index) =>
            arrayes.push(usuarioCorredor.idCorredor)
        );
        for (let index = 0; index < arrayes.length; index++) {
            if (arrayes[index] == position) {
                return "green";
            }
        }
        return "gray";
    }

    myColors(position) {
        let arrayes = [] ;
        this.state.usuariosPantallas.map((usuarioPantalla, index) =>
            arrayes.push(usuarioPantalla.idUsuario)
        );
        for (let index = 0; index < arrayes.length; index++) {
            if (arrayes[index] == position) {
                return "green";
            }
        }
        return "gray";
    }
    tipoTurno = (event) =>{
        this.setState({turno: event.target.value});
    }
    verificarSalaUsuario = (idUsuario, salasMonitor) =>{
        if(this.state.idusuarioSala.indexOf(idUsuario) != -1){
            if(this.state.idSalas[this.state.idusuarioSala.indexOf(idUsuario)] === salasMonitor){
                //console.log("INGRESO ESTOS DATOS-> "+this.state.idSalas[this.state.idusuarioSala.indexOf(idUsuario)]+" salaMonitor"+salasMonitor);
                return true;
            }
        }
    }
    getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
        return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
            /*** filtro de variables  */
            switch (metodo) {
              case "get":
                {
                  if (stateVar === "perfil"){ return returnVal }
                  if (stateVar === "navieras"){
                      this.setState({ navieras: returnVal });
                  }
                  if (stateVar === "movimientosTipo"){
                    this.setState({ movimientosTipo: returnVal });
                  }
                  if (stateVar === "contenedorTipo"){
                    this.setState({ contenedorTipo: returnVal });
                  }
                  if(stateVar === "corredores"){
                    this.setState({ corredores: returnVal });
                  }
                  if (stateVar === "transportistas"){
                    this.setState({ transportistas: returnVal });
                  }
                  if (stateVar === "usuariosCorredores"){
                    this.setState({ pcorredores: returnVal });
                    this.setState({ usuariosCorredores: returnVal });
                  }
                  if (stateVar === "usuarios_naviera"){
                    this.setState({ pnavieras: returnVal });
                  }
                  if (stateVar === "usuarios_transportistas"){
                    this.setState({ pTransportistas: returnVal });
                  }
                  if (stateVar === "usuarios_tipo_movimiento"){
                    this.setState({ pmovimientoTipos: returnVal });
                  }
                  if (stateVar === "usuarios_tipo_carga"){
                    this.setState({ pTipoCargas: returnVal });
                  }
                }
                break;
              case "post":
                {
                  if (stateVar === "usuariosCorredores" && returnVal.idCorredor ){
                    let pcorredoresTemp= this.state.pcorredores;  
                    pcorredoresTemp.push(returnVal);
                    this.setState({ pcorredores: pcorredoresTemp });
                  }
                  if (stateVar === "usuarios_navieras"){
                    let pnavierasTemp= this.state.pnavieras;  
                    pnavierasTemp.push(returnVal);
                    this.setState({ pnavieras: pnavierasTemp });
                  }
                  if (stateVar === "usuarios_tipo_movimiento"){
                    let pmovimientoTiposTemp= this.state.pmovimientoTipos;  
                    pmovimientoTiposTemp.push(returnVal);
                    this.setState({ pmovimientoTipos: pmovimientoTiposTemp });
                  }
                  if (stateVar === "usuarios_tipo_carga"){
                    let pTipoCargasTemp= this.state.pTipoCargas;  
                    pTipoCargasTemp.push(returnVal);
                    this.setState({ pTipoCargas: pTipoCargasTemp });
                  }
                  if (stateVar === "usuarios_transportistas"){
                    let ptransportistasTemp= this.state.pTransportistas;  
                    ptransportistasTemp.push(returnVal);
                    this.setState({ pTransportistas: ptransportistasTemp });
                  }
                }
                break;
              case "delete":
                {
                  if (stateVar === "usuariosCorredores" && returnVal){
                    let varTemp= this.state.pcorredores;                    
                    varTemp.map((elem, index )=>{
                      if(obj.idCorredor === elem.idCorredor ){ varTemp.splice( index, 1);  this.setState({ pcorredores: varTemp }); }
                    });
                  }
                  if (stateVar === "usuarios_navieras" && returnVal){ 
                    let varTemp= this.state.pnavieras;
                    varTemp.map((elem, index )=>{
                      if(obj.idNaviera === elem.idNaviera ){ varTemp.splice( index, 1);  this.setState({ pnavieras: varTemp }); }
                    });
                  }
                  if (stateVar === "usuarios_tipo_movimiento" && returnVal){
                    let varTemp= this.state.pmovimientoTipos;
                    varTemp.map((elem, index )=>{
                      if(obj.idMovimientoTipo === elem.idMovimientoTipo ){ varTemp.splice( index, 1);  this.setState({ pmovimientoTipos: varTemp }); }
                    });
                  }
                  if (stateVar === "usuarios_tipo_carga" && returnVal){  
                    let varTemp= this.state.pTipoCargas;
                    varTemp.map((elem, index )=>{
                      if(obj.idTipoCarga === elem.idTipoCarga ){ varTemp.splice( index, 1);  this.setState({ pTipoCargas: varTemp }); }
                    });
                  }
                  if (stateVar === "usuarios_transportistas" && returnVal){ 
                    let varTemp= this.state.pTransportistas;
                    varTemp.map((elem, index )=>{
                      if(obj.idTransportista === elem.idTransportista ){ varTemp.splice( index, 1);  this.setState({ pTransportistas: varTemp }); }
                    });
                  }
                }
                break;
              default:
                break;
            }
          }).catch(err =>{
              console.log(err);
        })
      }
    buscarConfiguracion=(idUsuario)=>{
        this.getfuntion("get", "", "", "plataformasUsuariosPerfiles/idUsuario/"+idUsuario+"/idPlataforma/"+this.state.plataforma.value, "perfil").then((returnVal) => {
            this.setState({ currentPerfil: returnVal });
            this.editarCorredor(idUsuario, returnVal.usuario.username);
            if(this.state.currentPerfil.perfil){
              switch (this.state.currentPerfil.perfil.movimientosBy) {
                case 1://personalizado
                  {
                    this.getfuntion("get", "", "", "usuarios_navieras/usuario/"+idUsuario+"/plataforma/"+this.state.plataforma.value, "usuarios_naviera");
                    this.getfuntion("get", "", "", "usuarios_tipo_movimiento/usuario/"+idUsuario+"/plataforma/"+this.state.plataforma.value, "usuarios_tipo_movimiento");
                    this.getfuntion("get", "", "", "usuarios_tipo_carga/usuario/"+idUsuario+"/plataforma/"+this.state.plataforma.value,"usuarios_tipo_carga");
                    this.getfuntion("get", "", "", "navieras/plataforma/"+this.state.plataforma.value, "navieras");
                    this.getfuntion("get", "", "", "movimientosTipo/plataforma/"+this.state.plataforma.value, "movimientosTipo");
                    this.getfuntion("get", "", "", "contenedorTipo","contenedorTipo");
                  }
                  break;
                case 2://corredores
                
                break;
                case 3://transportista
                {
                  this.getfuntion("get", "", "", "usuarios_transportistas/usuario/"+idUsuario+"/plataforma/"+this.state.plataforma.value, "usuarios_transportistas");
                  this.getfuntion("get", "", "", "transportistas/plataforma/"+this.state.plataforma.value, "transportistas");
                }
                break;
                default:
                  break;
              }
            }
        });
    }
    changeNaviera=(event)=>{
        let objTemp= {idUsuario: this.state.nombreUsuarioId, idNaviera: parseInt(event.target.value, 10), idPlataforma: this.state.plataforma.value };
      if( event.target.checked ){
        this.getfuntion("post", objTemp, "", "usuarios_navieras","usuarios_navieras");  
      }else{
        this.getfuntion("delete", objTemp, this.state.nombreUsuarioId+"/"+parseInt(event.target.value, 10)+"/"+this.state.plataforma.value, "usuarios_navieras","usuarios_navieras");
      }      
    }
    changeCorredor=(event)=>{
        let objTemp= { "idUsuario": this.state.nombreUsuarioId, "idCorredor": parseInt(event.target.value, 10), "idPlataforma": this.state.plataforma.value, "fechaAsignacion": timeToDateFill(new Date().getTime()), "turnoMonitoreo": 1 };
        if( event.target.checked ){
            this.getfuntion("post", objTemp, "", "usuariosCorredores","usuariosCorredores");  
        }else{
            this.getfuntion("delete", objTemp, this.state.nombreUsuarioId+"/"+parseInt(event.target.value, 10)+"/"+this.state.plataforma.value, "usuariosCorredores","usuariosCorredores");
        } 
    }
    changeMovimientoTipo=(event)=>{
        let objTemp= {idUsuario: this.state.nombreUsuarioId, idMovimientoTipo: parseInt(event.target.value, 10), idPlataforma: this.state.plataforma.value };
      if( event.target.checked ){
        this.getfuntion("post", objTemp, "", "usuarios_tipo_movimiento","usuarios_tipo_movimiento");  
      }else{
        this.getfuntion("delete", objTemp, this.state.nombreUsuarioId+"/"+parseInt(event.target.value, 10)+"/"+this.state.plataforma.value, "usuarios_tipo_movimiento","usuarios_tipo_movimiento");
      }
    }
    changeContenedorTipo=(event)=>{
        let objTemp= {idUsuario: this.state.nombreUsuarioId, idTipoCarga: parseInt(event.target.value, 10), idPlataforma: this.state.plataforma.value };
      if( event.target.checked ){
        this.getfuntion("post", objTemp, "", "usuarios_tipo_carga","usuarios_tipo_carga");
      }else{
        this.getfuntion("delete", objTemp, this.state.nombreUsuarioId+"/"+parseInt(event.target.value, 10)+"/"+this.state.plataforma.value, "usuarios_tipo_carga","usuarios_tipo_carga");
      }
    }
    changeTransportista=(event)=>{
      let objTemp= {idUsuario: this.state.nombreUsuarioId, idTransportista: parseInt(event.target.value, 10), idPlataforma: this.state.plataforma.value };
    if( event.target.checked ){
      this.getfuntion("post", objTemp, "", "usuarios_transportistas","usuarios_transportistas");  
    }else{
      this.getfuntion("delete", objTemp, this.state.nombreUsuarioId+"/"+parseInt(event.target.value, 10)+"/"+this.state.plataforma.value, "usuarios_transportistas","usuarios_transportistas");
    }      
  }
    getNaviera          =(id)=>{ for( let  item of this.state.pnavieras ){        if( item.idNaviera === id ){         return true; } } return false; }
    getCorrdeor         =(id)=>{ for( let  item of this.state.pcorredores ){      if( item.idCorredor === id ){        return true; } } return false; }
    getMovimientoTipo   =(id)=>{ for( let  item of this.state.pmovimientoTipos ){ if( item.idMovimientoTipo === id ){  return true; } } return false; }
    getTipoCarga        =(id)=>{ for( let  item of this.state.pTipoCargas ){      if( item.idTipoCarga === id ){       return true; } } return false; }
    getTransportista    =(id)=>{ for( let  item of this.state.pTransportistas ){  if( item.idTransportista === id ){   return true; } } return false; }
    render() {
        return (
            <Page.Card>
                <Form.Group label="Solo se muestran las plataformas asignadas">
                  <Select value={this.state.plataforma} onChange={this.changePlataforma} options={this.plataformas} />
                </Form.Group>
                { this.state.tabla_usuario ?
                <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                    <Table.Header align="center">
                        <Table.ColHeader>ID</Table.ColHeader>
                        <Table.ColHeader>UserName</Table.ColHeader>
                        <Table.ColHeader>Nombre</Table.ColHeader>
                        <Table.ColHeader>A. Paterno</Table.ColHeader>
                        <Table.ColHeader>A. Materno</Table.ColHeader>
                        <Table.ColHeader>Editar</Table.ColHeader>
                        <Table.ColHeader>Usuario en Sala</Table.ColHeader>

                    </Table.Header>
                    <Table.Body>
                    {this.state.usuarios.map((usuario, index) =>
                        <Table.Row align="center">{ console.log(usuario) }
                            <Table.Col>{usuario.id}</Table.Col>
                            <Table.Col>{usuario.username}</Table.Col>
                            <Table.Col>{usuario.persona.nombre}</Table.Col>
                            <Table.Col>{usuario.persona.aPaterno}</Table.Col>
                            <Table.Col>{usuario.persona.aMaterno}</Table.Col>
                            <Table.Col >
                                <ModalLayout  tamanio={"90%"} title="Actualizar"
                                              name={ <Button onClick={() => this.buscarConfiguracion(usuario.id) } pill color="azure"><i className="fa fa-pencil"></i></Button> }
                                              hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                              formulario={
                                                <div>
                                                 {this.state.currentPerfil?
                                                  <div>
                                                      { this.state.currentPerfil.perfil.movimientosBy === 2 ?//corredores
                                                        <Card>
                                                          <Card.Header>
                                                            <Card.Title>Corredores del usuario {this.state.nombreUsuario} </Card.Title>
                                                            <Card.Options>
                                                                <Form.Group >
                                                                    <Form.Select onChange={this.tipoTurno}>
                                                                    <option value={1}>Matutino</option>
                                                                    <option value={2}>Vespertino</option>
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Card.Options>
                                                          </Card.Header>
                                                          <Card.Body>
                                                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                                                              <Table.Header align="center">
                                                                <Table.ColHeader>ID</Table.ColHeader>
                                                                <Table.ColHeader>Nombre</Table.ColHeader>
                                                                <Table.ColHeader>Nombre Corto</Table.ColHeader>
                                                                <Table.ColHeader>Agregar/editar Corredor </Table.ColHeader>
                                                              </Table.Header>
                                                              <Table.Body>
                                                                {this.state.corredores.map((corredor, index) =>
                                                                  <Table.Row align="center">
                                                                    <Table.Col>{corredor.id}</Table.Col>
                                                                    <Table.Col>{corredor.nombre}</Table.Col>
                                                                    <Table.Col>{corredor.nombreCorto}</Table.Col>
                                                                    <Table.Col>
                                                                      <Button onClick={() => {this.agregaroaliminar(corredor.id)}} pill color={this.myColor(corredor.id)}><i className="fa fa-pencil"></i></Button>
                                                                    </Table.Col>
                                                                  </Table.Row>
                                                                )}
                                                              </Table.Body>
                                                            </Table>
                                                          </Card.Body>
                                                        </Card>
                                                      : "" }
                                                      { this.state.currentPerfil.perfil.movimientosBy === 1 ? //personalizado
                                                        <Card>
                                                          <Card.Body>
                                                              <div className="row">
                                                                  <div className="col">
                                                                    <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                                                                      <Table.Header align="center">
                                                                          <Table.ColHeader>Naviera</Table.ColHeader>
                                                                          <Table.ColHeader>estatus </Table.ColHeader>
                                                                      </Table.Header>
                                                                      <Table.Body>
                                                                        {this.state.navieras.map((nav, index) =>
                                                                          <Table.Row align="center">
                                                                              <Table.Col>{ nav.nombre }</Table.Col>
                                                                              <Table.Col><Form.Switch type="Checkbox" name="toggle" value={nav.id} checked= { this.getNaviera(nav.id) } onChange={ this.changeNaviera} /></Table.Col>
                                                                          </Table.Row>
                                                                        )}
                                                                      </Table.Body>
                                                                    </Table>
                                                                  </div>
                                                                  <div className="col">
                                                                    <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                                                                      <Table.Header align="center">
                                                                          <Table.ColHeader>corredor</Table.ColHeader>
                                                                          <Table.ColHeader>clave</Table.ColHeader>
                                                                          <Table.ColHeader>estatus </Table.ColHeader>
                                                                      </Table.Header>
                                                                      <Table.Body>
                                                                        {this.state.corredores.map((corr, index) =>
                                                                          <Table.Row align="center">
                                                                              <Table.Col>{ corr.nombre }</Table.Col>
                                                                              <Table.Col>{ corr.nombreCorto }</Table.Col>
                                                                              <Table.Col><Form.Switch type="Checkbox" name="toggle" value={corr.id} checked= { this.getCorrdeor(corr.id) } onChange={ this.changeCorredor} /></Table.Col>
                                                                          </Table.Row>
                                                                        )}
                                                                      </Table.Body>
                                                                    </Table>
                                                                  </div>
                                                                  <div className="col">
                                                                    <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                                                                      <Table.Header align="center">
                                                                          <Table.ColHeader>Movimiento Tipo</Table.ColHeader>
                                                                          <Table.ColHeader>estatus </Table.ColHeader>
                                                                      </Table.Header>
                                                                      <Table.Body>
                                                                        {this.state.movimientosTipo.map((mtipo, index) =>
                                                                          <Table.Row align="center">
                                                                              <Table.Col>{ mtipo.nombre }</Table.Col>                                                                            
                                                                              <Table.Col><Form.Switch type="Checkbox" name="toggle" value={mtipo.id} checked= { this.getMovimientoTipo(mtipo.id) } onChange={ this.changeMovimientoTipo} /></Table.Col>
                                                                          </Table.Row>
                                                                        )}
                                                                      </Table.Body>
                                                                    </Table>
                                                                  </div>
                                                                  <div className="col">
                                                                    <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                                                                      <Table.Header align="center">
                                                                          <Table.ColHeader>Tipo de carga</Table.ColHeader>
                                                                          <Table.ColHeader>estatus </Table.ColHeader>
                                                                      </Table.Header>
                                                                      <Table.Body>
                                                                        {this.state.contenedorTipo.map((cTipo, index) =>
                                                                          <Table.Row align="center">
                                                                              <Table.Col>{ cTipo.nombre }</Table.Col>
                                                                              <Table.Col><Form.Switch type="Checkbox" name="toggle" value={cTipo.id} checked= { this.getTipoCarga(cTipo.id) } onChange={ this.changeContenedorTipo} /></Table.Col>
                                                                          </Table.Row>
                                                                        )}
                                                                      </Table.Body>
                                                                    </Table>
                                                                      {/* <Select onChange={this.changeContenedorTipo} options={ this.state.contenedorTipo } placeholder="Tipo de contenedor"/> */}
                                                                  </div>
                                                              </div>
                                                          </Card.Body>
                                                        </Card>
                                                      : ""  }
                                                      { this.state.currentPerfil.perfil.movimientosBy === 3 ? //transportista
                                                        <Card>
                                                          <Card.Body>
                                                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                                                              <Table.Header align="center">
                                                                  <Table.ColHeader>Id</Table.ColHeader>
                                                                  <Table.ColHeader>Transportista  </Table.ColHeader>
                                                                  <Table.ColHeader>estatus </Table.ColHeader>
                                                              </Table.Header>
                                                              <Table.Body>
                                                                {this.state.transportistas.map((t, index) =>
                                                                  <Table.Row align="center">
                                                                      <Table.Col>{ t.id }</Table.Col>
                                                                      <Table.Col>{ t.nombre }</Table.Col>
                                                                      <Table.Col><Form.Switch type="Checkbox" name="toggle" value={t.id} checked= { this.getTransportista(t.id) } onChange={ this.changeTransportista} /></Table.Col>
                                                                  </Table.Row>
                                                                )}
                                                              </Table.Body>
                                                            </Table>                                                            
                                                          </Card.Body>
                                                        </Card>
                                                      : ""  }
                                                  </div>
                                                 :null}
                                                </div>                                                
                                               }
                                />
                            </Table.Col>
                            <Table.Col>
                                <Form.Select onChange={e => this.salaMonitoreoRegistro(e, usuario.id)}>
                                <option value={0}>Seleccione una Opciones</option>
                                    {this.state.salasMonitoreo.map((salasMonitor, index) =>
                                    //this.verificarSalaUsuario(usuario.id, salasMonitor.id),
                                    //console.log("FALSO O VERDADERO "+index)
                                    <option id={salasMonitor.id} value={salasMonitor.id} selected={this.verificarSalaUsuario(usuario.id, salasMonitor.id)}>{salasMonitor.nombre}</option>
                                    )}
                                </Form.Select>
                            </Table.Col>
                        </Table.Row>
                        )}
                    </Table.Body>

                </Table>
                : null}
                <br/>
            </Page.Card>
        );
    }
}

export default UsuarioConsolaForm;
