import React, { Component } from 'react';
import { Crud_Catalogos, Crud_error } from '../Crud_Catalogos';
import './excel.css';
import { ExportCSV } from './ExportCSV.js'
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
var tmpI="";
var tmpH="";
class Exportar extends Component
{
  constructor(props) {
    super(props);
    this.state = {
                catalogo:this.props.catalogo                ||"",
                  filtro:this.props.filtro                  ||"",
                informes:this.props.informes                ||"",
        perfilesInformes:this.props.perfilesInformes        ||"",
									  data:[],
                actuales:this.props.actuales                ||[],

  //Variables
                  accion:this.props.accion,
                fileName:this.props.fileName                ||"",
    };
    //this.cita=0;
    this.data= [];
    //this.actuales=[];
  }
  componentWillMount(){
  }
  componentDidUpdate(){
  }
  componentDidMount(){
    //if (this.props.actuales){
      //this.cargaCatalogo(this.props.catalogo);
    //}else{
      switch (this.props.catalogo) {
        //case "unidadEnsamble":
        //  { this.cargaPerfilesInformes(currentUser[0].perfil.id) };
        //  { this.cargaHabilitados("navieraMovimientos") };//this.props.catalogo) }; //Todos
        //  break;
        case "navieraMovimientos":
          { /*this.cargaPerfilesInformes(currentUser[0].perfil.id) */};
          { /*this.cargaHabilitados("navieraMovimientos") */};//this.props.catalogo) }; //Todos
          { /*this.cargaCatalogo(this.props.catalogo) */};
          break;
        case "terrestreMovimientos":
          { /*this.cargaPerfilesInformes(currentUser[0].perfil.id)*/ };
          { /*this.cargaHabilitados("terrestreMovimientos")*/ };//this.props.catalogo) }; //Todos
          { /*this.cargaCatalogo(this.props.catalogo) */};
          break;
        default:
          //9this.setState({ data:this.props.actuales });
          { this.cargaCatalogo(this.props.catalogo) };
          break;
      }
    //}
    this.setState({ fileName:this.props.catalogo });
  }

  componentDidUpdate=()=>{
    if( this.props.data && this.props.data !== this.data ){
      
      this.data= this.props.data;
      var dataTemp= [];
      /*
      this.data.map((item, index)=>{
        dataTemp.push(item);
        Object.keys(dataTemp).forEach(
          key => {

          });

      })
      */
      var f = 0;
      this.data.map((filas, index) => {
        Object.keys(filas).forEach(key =>
          {
            if(!dataTemp[index]){
              dataTemp[index]={}
            }
            if(key !== "movimientoGps"){
              //dataTemp[index][key]= [];
            }
            if(filas[key] !== null){
              if ( typeof filas[key] === 'object'){
                //console.log(filas[key]+"=>> "+ key+"("+typeof filas[key]+")")
                switch (key) {
                  case "persona":
                    dataTemp[index][key]= "" + filas[key].nombre + " " + filas[key].aPaterno + " " + filas[key].aMaterno;
                    break;
                  case "movimientoGps":
                    filas[key].map((gps, indice)=> dataTemp[index]["gps-"+(indice+1)]= gps.gps.nombre);
                    break;
                  default:
                      if ((filas[key].nombre!="")&&(filas[key].nombre!=undefined)) {
                        dataTemp[index][key]=filas[key].nombre;
                      }else{
                        if ((filas[key].id!="")&&(filas[key].id!=undefined)) {
                          dataTemp[index][key]=filas[key].id;
                        }else{
                          dataTemp[index][key]="Vari(@)s ";
                        }
                      }
                }
              }else{
                  dataTemp[index][key]=filas[key];
              }
            }else{
              if( key !== "movimientoGps" ){
                dataTemp[index][key]="";
              }              
            }
          });/*
      var col=0;
      for (let j in filas){
        col=col+1;
        if ( (filas[j]==null)||(filas[j]=="") ) {
        }else{
              if ( typeof filas[j] === 'object') {
                switch (j) {
                  case "persona":
                      filas[j]= "" + filas[j].nombre + " " + filas[j].aPaterno + " " + filas[j].aMaterno;
                    break;
                  default:
                    if ((filas[j].nombre!="")&&(filas[j].nombre!=undefined)) {
                      filas[j]=filas[j].nombre;
                    }else{
                      if ((filas[j].id!="")&&(filas[j].id!=undefined)) {
                        filas[j]=filas[j].id;
                      }else{
                        filas[j]="Vari(@)s "+j;
                      }
                    }
                }
              }else{
                switch (j) {
                  case "password":
                      filas[j]="***************";
                    break;
                  case "persona":
                      filas[j]="***************";
                    break;
                  default:break;
                }
              }
        }
      } f=f+1; */
    } );
      this.setState({ data: dataTemp});
      //console.log(dataTemp)
    }
  }

  cargaPerfilesInformes(filtro) {
  //Crud_Catalogos("funcionalidades",         "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("perfilesInformes", "", "get", "",[],"" , "", "", [], "", "", "/perfil/"+filtro).then((regresaValores) => {
    this.setState({ perfilesInformes:regresaValores });
    { this.cargaInformes(this.state.perfilesInformes[0].idInformes) };
    }).catch(err => {
      if( err.response ){
         console.log(err);
      }else{
        console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");
      }
    });
  }
  cargaInformes(id) {
  //Crud_Catalogos("funcionalidades",         "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("informes", "", "get", "",[],"" , "", "", [], "", "", "/"+id).then((regresaValores) => {
    this.setState({ informes:regresaValores });
    this.cargaInformesFuncionalidades(this.state.informes.id);
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }
  cargaInformesFuncionalidades(filtro) {
  //Crud_Catalogos("funcionalidades",         "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    //if (filtro > 0){
      Crud_Catalogos("informesFuncionalidades", "", "get", "",[],"" , "", "", [], "", "", "/informe/"+filtro).then((regresaValores) => {
      this.setState({ informesFuncionalidades:regresaValores });
      //console.log("63 this.state.informesFuncionalidades");
      //console.log(this.state.informesFuncionalidades);
      //tmpI = regresaValores;
      { this.cargaCatalogo(this.props.catalogo) };
      }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
    //}
  }

  //Inicio Carga Habilitados
  cargaHabilitados(filtro) {
    //if (filtro >0 ){
        Crud_Catalogos("funcionalidades", "", "get", "", "" , "" , "", "", [], "", "", "/bloque/"+filtro).then((regresaValores) => {
          this.setState({ FHabilitados:regresaValores });
        }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo +" verifique el Nombre del Catalogo");}  });
    //}
  }
  //Fin Carga Habilitados

  //Inicio Carga Catalogo
  cargaCatalogo(Nombre) {
    var nuevoJSON = [];
    var tmpColumnas = [];
    var f = 0;
    if (this.state.actuales.length>0){
    //if (this.state.data.length>0){
      //nuevoJSON=this.state.actuales;
      nuevoJSON=this.state.data;
      nuevoJSON.map(filas => {
        var col=0;
        for (let j in filas){//for (let j in Jsons){
          col=col+1;
          if ( (filas[j]==null)||(filas[j]=="") ) {
          }else{
            //if (seExporta){//Inicio Se Muestra
                if ( typeof filas[j] === 'object') {
                  switch (j) {
                    case "persona":
                        filas[j]= "" + filas[j].nombre + " " + filas[j].aPaterno + " " + filas[j].aMaterno;
                      break;
                    default:
                      if ((filas[j].nombre!="")&&(filas[j].nombre!=undefined)) {
                        filas[j]=filas[j].nombre;
                      }else{
                        if ((filas[j].id!="")&&(filas[j].id!=undefined)) {
                          filas[j]=filas[j].id;
                        }else{
                          filas[j]="Vari(@)s "+j;
                        }
                      }
                  }
                }else{
                  switch (j) {
                    case "password":
                        filas[j]="***************";
                      break;
                    case "persona":
                        filas[j]="***************";
                      break;
                    default:
                  }
                }

            //}//Fin se Muestra
          }
        } f=f+1;
      } );
    }else{
      //console.log("Cargando Datos con Filtro del Catálogo... ");
      Crud_Catalogos(Nombre+this.state.filtro, ""           , "get", "", [],"" , "",     "", []).then( (returnVal) => {
      nuevoJSON=returnVal;
      //nuevoJSON=this.state.data;
      nuevoJSON.map(filas => {
        var col=0;
        for (let j in filas){//for (let j in Jsons){
          col=col+1; //console.log("filas: ");
          if ( (filas[j]==null)||(filas[j]=="") ) {
          }else{
            //if (seExporta){//Inicio Se Muestra
                if ( typeof filas[j] === 'object') {
                  switch (j) {
                    case "persona":
                        filas[j]= "" + filas[j].nombre + " " + filas[j].aPaterno + " " + filas[j].aMaterno;
                      break;
                    default:
                      if ((filas[j].nombre!="")&&(filas[j].nombre!=undefined)) {
                        filas[j]=filas[j].nombre;
                      }else{
                        if ((filas[j].id!="")&&(filas[j].id!=undefined)) {
                          filas[j]=filas[j].id;
                        }else{
                          filas[j]="Vari(@)s "+j;
                        }
                      }
                  }
                }else{
                  switch (j) {
                    case "password":
                        filas[j]="***************";
                      break;
                    case "persona":
                        filas[j]="***************";
                      break;
                    default:
                  }
                }

            //}//Fin se Muestra
          }
        } f=f+1;
      } );
      this.setState({data:nuevoJSON });
      }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+Nombre+" verifique el Nombre del Catalogo");}  });

    }
  }
  //Fin Carga Catalogo

render() {
    return (
        <div><ExportCSV csvData={this.state.data} fileName={this.state.fileName} />{ }</div>
    );
  }
}

export default Exportar;
