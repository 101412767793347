import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card, Dimmer, Alert } from "tabler-react";
import { timingSafeEqual } from "crypto";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import ModalLayout from "../../../../components/ModalLayout.react";
import { DestinatariosEspecialPersonas } from "./";
import Select from 'react-select';
import { Tab } from "react-tabs";
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';

class  DestinatariosEspeciales extends Component {
  constructor(props) {
    super(props);
    this.state = {
                errorMSG: "",
                      id: "",
            hiddenModals: false,
                  accion: this.props.accion, //'nuevo'// modificar, nuevo
           destinatarios: [],
                  search: "",
               readyData: false,
               /**
                * Catalogos
                */
                navieras: [],
             navierasKey: {},
                 naviera: {label: "Seleccione un a opcción", value: 0 },
           editarNaviera: {label: "Seleccione un a opcción", value: 0 },
              corredores: [],
           corredoresKey: {},
                corredor: {label: "Seleccione un a opcción", value: 0 },
          editarCorredor: {label: "Seleccione un a opcción", value: 0 },
         movimientosTipo: [],
      movimientosTipoKey: {},
          movimientoTipo: {label: "Seleccione un a opcción", value: 0 },
    editarMovimientoTipo: {label: "Seleccione un a opcción", value: 0 },
        contenedoresTipo: [],
     contenedoresTipoKey: [],
          contenedorTipo: {label: "Seleccione un a opcción", value: 0 },
    editarContenedorTipo: {label: "Seleccione un a opcción", value: 0 },
               duplicado: false
                 };
    this.plataformaCorreo=[];
        this.destinatario={
                            "nombre": "",
                            "idNaviera": 0,
                            "idContenedorTipo": 0,
                            "idCorredor": 0,
                            "idMovimientotipo": 0,
                            "idDestinatario": this.props.destinatario.idDestinatario,
                            "fecha": null
                          };
  this.editarDestinatario={
                            "idDestinatarioEspecial": 0,
                            "nombre": "",
                            "idNaviera": 0,
                            "idContenedorTipo": 0,
                            "idCorredor": 0,
                            "idMovimientotipo": 0,
                            "idDestinatario": this.props.destinatario.idDestinatario,
                            "fecha": null
                          };
  this.catalogo=[];
  }
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if( stateVar === "destinatarios" ){
                if( returnVal[0] ){
                  this.setState({ destinatarios: returnVal });
                }
                this.setState({readyData: true});
              }
              if( stateVar === "destinatarios_tipo_configuracion" ){
                return returnVal;
              }
              if( stateVar === "navieras" ){ return returnVal; }
              if( stateVar === "corredores" ){ return returnVal; }
              if( stateVar === "movimientosTipo" ){ return returnVal; }
              if( stateVar === "contenedorTipo" ){ return returnVal; }
              if( stateVar === "catalogos_destinatarios" ){
                let catalogosTemp= [];
                catalogosTemp.push({ value: 0,label: "Seleccione un catalogo"});
                returnVal.map((cat, index)=>{
                  catalogosTemp.push({value: cat.idCatalogo, label: cat.nombre});
                });
                this.setState({catalogos: catalogosTemp});
              }
              if( stateVar === "destinatarios_catalogos" ){
                for(let item of this.state.catalogos){
                  if(item.value  == returnVal.idCatalogo){
                    this.catalogo= item;
                    this.setState({catalogo: item });
                    break;
                  }
                }
              }
            }
            break;
          case "post":
            {
              if( stateVar === "destinatarios" ){
                if(returnVal){
                  let destinatariosTem= this.state.destinatarios;
                  destinatariosTem.push(returnVal);
                  this.setState({ destinatarios: destinatariosTem });
                  NotificationManager.info("Se agrego nuevo destinatario("+returnVal.nombre+")", "Guardado");
                  this.destinatario.nombre= "";
                  this.setState({naviera: {label: "Seleccione un a opcción", value: 0 } });
                  this.setState({contenedorTipo: {label: "Seleccione un a opcción", value: 0 } });
                  this.setState({corredor: {label: "Seleccione un a opcción", value: 0 } });
                  this.setState({movimientoTipo: {label: "Seleccione un a opcción", value: 0 } });
                }
              }
              if( stateVar === "destinatarios_catalogos" ){
                for(let item of this.state.catalogos){
                  if(item.value  == returnVal.idCatalogo){
                    this.catalogo= item;
                    this.setState({catalogo: item });
                    NotificationManager.info("Se modifico destinatario con ("+item.label+")", "Guardado");
                    break;
                  }
                }
              }
            }
            break;
          case "put":
            {
              if( stateVar === "destinatarios" ){
                if(returnVal){
                  let destinatariosTem= this.state.destinatarios;
                  destinatariosTem.map((item, index)=>{
                    if( item.idDestinatario === returnVal.idDestinatario){
                      item= returnVal;
                      destinatariosTem[index]= returnVal;
                    }
                  });
                  this.hiddenmodal();
                  this.setState({ destinatarios: destinatariosTem });
                  NotificationManager.info("Se modifico destinatario("+returnVal.nombre+")", "Actualización");
                  this.hiddenmodal();
                }
              }
            }
            break;
          case "delete":
            {
              if( stateVar === "destinatarios_catalogos" ){

              }

              if(stateVar =="etiquetado_masivo_transportistas"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "transportistasEtiquetas/contar/"+this.props.elementos.id,"contarTransportistas");
                NotificationManager.info("Se han removido las etiquetas de todos los contactos de transportistas en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
              }
              if(stateVar == "etiquetado_masivo_clientes"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "clientesEtiquetas/contar/"+this.props.elementos.id,"contarClientes");
                NotificationManager.info("Se han removido las etiquetas de todos los contactos de clientes en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
              }
            }
              break;
          default:
            break;
        }
      }).catch(err =>{ console.log(err); });
  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      /**
       * Cargar catalogos de la plataforma
       */
      //Navieras
      this.getfuntion("get", "", "", "navieras/plataforma/"+this.props.elementos.id,"navieras").then((nav) => {
        let navTem= [];
        let navkeyTem= {};
        navTem.push({ value: 0, label: "Seleccione un a opcción" });
        for( let item of nav ){
          navkeyTem[item.id]= item;
          navTem.push({ value: item.id, label: item.nombre });
        }
        this.setState({ navieras: navTem });
        this.setState({ navierasKey: navkeyTem });
        //Corredores
        this.getfuntion("get", "", "", "corredores/plataforma/"+this.props.elementos.id,"corredores").then((corr) => {
          let corrTem= [];
          let corrkeyTem= {};
          corrTem.push({ value: 0, label: "Seleccione un a opcción" });
          for( let item of corr ){
            corrkeyTem[item.id]= item;
            corrTem.push({ value: item.id, label: item.nombre });
          }
          this.setState({ corredoresKey: corrkeyTem });
          this.setState({ corredores: corrTem});
          //movimientosTipo
          this.getfuntion("get", "", "", "movimientosTipo/plataforma/"+this.props.elementos.id,"movimientosTipo").then((movT) => {
            let momtTem= [];
            let momtkeyTem= {};
            momtTem.push({ value: 0, label: "Seleccione un a opcción" });
            for( let item of movT ){
              momtkeyTem[item.id]= item;
              momtTem.push({ value: item.id, label: item.nombre });
            }
            this.setState({ movimientosTipo: momtTem });
            this.setState({ movimientosTipoKey: momtkeyTem });
            //contenedorTipo
            this.getfuntion("get", "", "", "contenedorTipo","contenedorTipo").then((conT) => {
              let conTem= [];
              let contkeyTem= {};
              conTem.push({ value: 0, label: "Seleccione un a opcción" });
              for( let item of conT ){
                contkeyTem[item.id]= item;
                conTem.push({ value: item.id, label: item.nombre });
              }
              this.setState({ contenedoresTipo: conTem });
              this.setState({ contenedoresTipoKey: contkeyTem });
              this.getfuntion("get", "", "", "destinatarios_especial/iddestinatario/"+this.props.destinatario.idDestinatario,"destinatarios");
            }).catch(err =>{
              if( err.response ){
                let msgTem= this.state.errorMSG;
                msgTem =msgTem.length>0? msgTem+", "+ Crud_error(err.response.status, "contenedorTipo"): msgTem+ Crud_error(err.response.status, "contenedorTipo");
                this.setState({ errorMSG: msgTem });
              } else {
                let msgTem= this.state.errorMSG;
                msgTem =msgTem.length>0? msgTem+", "+ Crud_error(404, "contenedorTipo"): msgTem+ Crud_error(404, "contenedorTipo");
                this.setState({ errorMSG: msgTem });
              }
            });
          }).catch(err =>{
            if( err.response ){
              let msgTem= this.state.errorMSG;
              msgTem =msgTem.length>0? msgTem+", "+ Crud_error(err.response.status, "movimientosTipo/plataforma/"+this.props.elementos.id): msgTem+ Crud_error(err.response.status, "movimientosTipo/plataforma/"+this.props.elementos.id);
              this.setState({ errorMSG: msgTem });
            } else {
              let msgTem= this.state.errorMSG;
              msgTem =msgTem.length>0? msgTem+", "+ Crud_error(404, "movimientosTipo/plataforma/"+this.props.elementos.id): msgTem+ Crud_error(404, "movimientosTipo/plataforma/"+this.props.elementos.id);
              this.setState({ errorMSG: msgTem });
            }
          });
        }).catch(err =>{
          if( err.response ){
            let msgTem= this.state.errorMSG;
            msgTem =msgTem.length>0? msgTem+", "+ Crud_error(err.response.status, "corredores/plataforma/"+this.props.elementos.id): msgTem+ Crud_error(err.response.status, "corredores/plataforma/"+this.props.elementos.id);
            this.setState({ errorMSG: msgTem });
          } else {
            let msgTem= this.state.errorMSG;
            msgTem =msgTem.length>0? msgTem+", "+ Crud_error(404, "corredores/plataforma/"+this.props.elementos.id): msgTem+ Crud_error(404, "corredores/plataforma/"+this.props.elementos.id);
            this.setState({ errorMSG: msgTem });
          }
        });
      }).catch(err =>{
        if( err.response ){
          let msgTem= this.state.errorMSG;
          msgTem =msgTem.length>0? msgTem+", "+ Crud_error(err.response.status, "navieras/plataforma/"+this.props.elementos.id): msgTem+ Crud_error(err.response.status, "navieras/plataforma/"+this.props.elementos.id);
          this.setState({ errorMSG: msgTem });
        } else {
          let msgTem= this.state.errorMSG;
          msgTem =msgTem.length>0? msgTem+", "+ Crud_error(404, "navieras/plataforma/"+this.props.elementos.id): msgTem+ Crud_error(404, "navieras/plataforma/"+this.props.elementos.id);
          this.setState({ errorMSG: msgTem });
        }
      });
    }
  }
  crud_put =() =>{
    this.getfuntion("put", this.editarDestinatario, "", "destinatarios_especial","destinatarios")
  }
  crud_post =() =>{
    this.getfuntion("post", this.destinatario, "", "destinatarios_especial","destinatarios")
  }
  crud_put_catalogo =(destinatario) =>{
    if(this.catalogo.value){
      this.getfuntion("delete", "", destinatario.idDestinatario+"/"+this.catalogo.value, "destinatarios_catalogos", "destinatarios_catalogos")
    }
    this.getfuntion("post", {idDestinatario: destinatario.idDestinatario, idCatalogo: this.state.catalogo.value }, "", "destinatarios_catalogos", "destinatarios_catalogos");
    //alert("catalogo");
  }
  validarDEstinoEspecial=(detEspecial)=>{
    for(let item of this.state.destinatarios){
      if(item.idNaviera == detEspecial.idNaviera && item.idCorredor == detEspecial.idCorredor && item.idContenedorTipo == detEspecial.idContenedorTipo && item.idMovimientotipo == detEspecial.idMovimientotipo ){
        this.setState({duplicado: true});
        this.setState({errorMSG: "Duplicado"});
        return false;
      }
    }
    this.setState({duplicado: false});
    this.setState({errorMSG: ""});
    return true;
  }

  changeNaviera=(e)=>{
    this.setState({naviera: e });
    this.destinatario.idNaviera= e.value;
    this.destinatario.nombre= this.props.destinatario.nombre+" "+e.label;
    this.validarDEstinoEspecial(this.destinatario);
  }
  changeCorredor=(e)=>{
    this.setState({corredor: e });
    this.destinatario.idCorredor= e.value;
    this.validarDEstinoEspecial(this.destinatario);
  }
  changeContenedorTipo=(e)=>{
    this.setState({contenedorTipo: e });
    this.destinatario.idContenedorTipo= e.value;
    this.validarDEstinoEspecial(this.destinatario);
  }
  changeMovimientoTipo=(e)=>{
    this.setState({movimientoTipo: e });
    this.destinatario.idMovimientotipo= e.value;
    this.validarDEstinoEspecial(this.destinatario);
  }
/**
 * Editar
 */
  changeNavieraE=(e)=>{
    this.setState({editarNaviera: e });
    this.editarDestinatario.idNaviera= e.value;
    this.editarDestinatario.nombre= this.props.destinatario.nombre+" "+e.label;
    this.validarDEstinoEspecial(this.editarDestinatario);
  }
  changeCorredorE=(e)=>{
    this.setState({editarCorredor: e });
    this.editarDestinatario.idCorredor= e.value;
    this.validarDEstinoEspecial(this.editarDestinatario);
  }
  changeContenedorTipoE=(e)=>{
    this.setState({editarContenedorTipo: e });
    this.editarDestinatario.idContenedorTipo= e.value;
    this.validarDEstinoEspecial(this.editarDestinatario);
  }
  changeMovimientoTipoE=(e)=>{
    this.setState({editarMovimientoTipo: e });
    this.editarDestinatario.idMovimientotipo= e.value;
    this.validarDEstinoEspecial(this.editarDestinatario);
  }

  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  }
  selectDestino =(indice)=>{
    var destinoTemp= this.state.destinatarios.filter( word => word.nombre.includes(this.state.search.toUpperCase()) ).sort()[indice];
    this.setState({editarContenedorTipo: {value: destinoTemp.idContenedorTipo, label: this.state.contenedoresTipoKey[destinoTemp.idContenedorTipo].nombre } });
    this.setState({editarCorredor: {value: destinoTemp.idCorredor, label: this.state.corredoresKey[destinoTemp.idCorredor].nombre } });
    this.setState({editarMovimientoTipo: {value: destinoTemp.idMovimientotipo, label: this.state.movimientosTipoKey[destinoTemp.idMovimientotipo].nombre } });
    this.setState({editarNaviera: {value: destinoTemp.idNaviera, label: this.state.navierasKey[destinoTemp.idNaviera].nombre } });

    this.editarDestinatario.idContenedorTipo=       destinoTemp.idContenedorTipo;
    this.editarDestinatario.idCorredor=             destinoTemp.idCorredor;
    this.editarDestinatario.idDestinatarioEspecial= destinoTemp.idDestinatarioEspecial;
    this.editarDestinatario.idMovimientotipo=       destinoTemp.idMovimientotipo;
    this.editarDestinatario.idNaviera=              destinoTemp.idNaviera;
    this.editarDestinatario.nombre=                 destinoTemp.nombre;
    //this.getfuntion("get", "", "", "destinatarios_catalogos/iddestinatario/"+destinoTemp.idDestinatario,"destinatarios_catalogos");
  }
  render(){
    return (
      <div>
        {/**
          *   Configuracion de correo
          */
        }
          <div>
            <Grid.Row>
            <Grid.Col md={12} lg={12}>
                <div hidden={this.state.errorMSG.length===0}>
                    <Alert type="danger" icon="alert-octagon">{ this.state.errorMSG }</Alert>
                </div>
            </Grid.Col>
              <Grid.Col>
                <Card
                  title="Catálogo de destinatarios que reciben notificaciones de incidencias."
                  isCollapsible={ false }
                  //isClosable
                  isCollapsed={ false}
                  //options={ }
                  body={
                    <div>
                      <Grid.Row>
                        <Grid.Col sm={12} md={2}>
                          <Form.Group label="Marca">
                            <Select value={this.state.naviera} onChange={this.changeNaviera} options={this.state.navieras} />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={12} md={3}>
                          <Form.Group label="Corredor">
                            <Select value={this.state.corredor} onChange={this.changeCorredor} options={this.state.corredores } />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={12} md={3}>
                          <Form.Group label="Tipo de carga">
                            <Select value={this.state.contenedorTipo} onChange={this.changeContenedorTipo} options={this.state.contenedoresTipo} />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={12} md={3}>
                          <Form.Group label="Tipo de movimiento">
                            <Select value={this.state.movimientoTipo} onChange={this.changeMovimientoTipo} options={this.state.movimientosTipo} />
                          </Form.Group>
                        </Grid.Col>

                        <Grid.Col sm={12} md={1}>
                          <Button size="sm" RootComponent="a" color="primary" disabled= { this.state.duplicado || this.state.naviera.value === 0 || this.state.corredores.value === 0 || this.state.movimientoTipo.value === 0 || this.state.contenedorTipo.value === 0 } onClick= { () => this.crud_post() } >
                              <span className="badge"><Icon link={true} name="save" />Guardar</span>
                          </Button>
                        </Grid.Col>
                      </Grid.Row>
                      <Dimmer active={!this.state.readyData} loader>
                      <Table cards={true} striped={true} responsive={true} className="table-vcenter"
                             headerItems={[{ content: "id"}, { content: "nombre"}, { content: "marca"}, { content: "Tipo de carga"}, { content: "corredor"}, { content: "tipo movimiento"}, { content: "fecha"}, { content: "editar"}, { content: "correos"}]} >
                        <Table.Body>

                            {
                              this.state.destinatarios.filter( word => word.nombre.includes(this.state.search.toUpperCase()) ).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento.idDestinatarioEspecial}</Table.Col>
                                  <Table.Col>{elemento.nombre}</Table.Col>
                                  <Table.Col>{this.state.navierasKey[elemento.idNaviera]? this.state.navierasKey[elemento.idNaviera].nombre: "loading .." }</Table.Col>
                                  <Table.Col>{this.state.contenedoresTipoKey[elemento.idContenedorTipo]?this.state.contenedoresTipoKey[elemento.idContenedorTipo].nombre: "loading .." }</Table.Col>
                                  <Table.Col>{this.state.corredoresKey[elemento.idCorredor]?this.state.corredoresKey[elemento.idCorredor].nombre: "loading .."}</Table.Col>
                                  <Table.Col>{this.state.movimientosTipoKey[elemento.idMovimientotipo]? this.state.movimientosTipoKey[elemento.idMovimientotipo].nombre: "loading .."}</Table.Col>
                                  <Table.Col>{elemento.fecha }</Table.Col>
                                  <Table.Col>
                                    <ModalLayout  tamanio = {"80%"} title= {elemento.nombre}
                                                  name= { <Button pill size="sm" outline color="primary" onClick={()=> this.selectDestino(index) }><Icon name="edit"/></Button>}
                                                  hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                                      formulario= {
                                                        <Grid.Row>
                                                          <Grid.Col sm={12} md={12}>
                                                            <div hidden={this.state.errorMSG.length===0}>
                                                                <Alert type="danger" icon="alert-octagon">{ this.state.errorMSG }</Alert>
                                                            </div>
                                                          </Grid.Col>
                                                          <Grid.Col sm={12} md={4}>
                                                            <Form.Group label="Id">
                                                              <h4>{elemento.idDestinatarioEspecial}</h4>
                                                            </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col sm={12} md={4}>
                                                            <Form.Group label="Nombre">
                                                              <h4>{elemento.nombre}</h4>
                                                            </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col sm={12} md={4}>
                                                            <Form.Group label="Marca">
                                                              <Select value={this.state.editarNaviera} onChange={this.changeNavieraE} options={this.state.navieras} />
                                                            </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col sm={12} md={4}>
                                                            <Form.Group label="Tipo de carga">
                                                              <Select value={this.state.editarContenedorTipo} onChange={this.changeContenedorTipoE } options={this.state.contenedoresTipo} />
                                                            </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col sm={12} md={4}>
                                                            <Form.Group label="Corredor">
                                                              <Select value={this.state.editarCorredor} onChange={this.changeCorredorE} options={this.state.corredores} />
                                                            </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col sm={12} md={4}>
                                                            <Form.Group label="Tipo de movimiento">
                                                              <Select value={this.state.editarMovimientoTipo} onChange={this.changeMovimientoTipoE} options={this.state.movimientosTipo} />
                                                            </Form.Group>
                                                          </Grid.Col>
                                                          <Grid.Col>
                                                            <span className="badge float-right">
                                                              <Button size="sm" RootComponent="a" color="primary" disabled= { this.state.duplicado || this.state.editarNaviera.value === 0 || this.state.editarCorredor.value === 0 || this.state.editarMovimientoTipo.value === 0 || this.state.editarContenedorTipo.value === 0 }
                                                                      onClick= { () => this.crud_put() } >     <span className="badge"><Icon link={true} name="save" />Guardar</span>
                                                              </Button>
                                                            </span>
                                                          </Grid.Col>
                                                        </Grid.Row>
                                                      }
                                    />
                                  </Table.Col>
                                  <Table.Col>{/**Configuracion correos */}

                                    <ModalLayout  tamanio = {"100%"} title={ elemento.nombre }
                                                  name= { <Button pill size="sm" outline color="primary" onClick={()=> this.selectDestino(index) }><Icon name="at-sign"/></Button>}
                                                  hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                                      formulario= {
                                                        <DestinatariosEspecialPersonas elementos={ this.props.elementos } stor= { this.props.stor } destinatario={elemento}/>
                                                      }
                                    />
                                  </Table.Col>
                                </Table.Row>
                              )
                            }
                            {this.state.destinatarios.length === 0?<h3>Aún no hay destinatarios</h3>:null}

                        </Table.Body>
                      </Table>
                    </Dimmer>
                    </div>
                }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default DestinatariosEspeciales
