import React, { Component } from 'react';
import axios from 'axios';
import './excel.css';
function subCadena(str, I) {
  //console.log("Str: " + str + " I: " + I);//var res = str.split(-I);
  var res = str.slice(0,I); //console.log("Res: " + res);
  return res;
}
function obtenAtributos() {
  var tempo = window.location.href;
  var itemp = tempo.length;
  var i = itemp; //console.log("tempo: " + tempo + " length: " +  itemp);
  do {  //console.log("Do i: "+ i );
    let stext = tempo.charAt(i);
    if (stext=="/"){  //console.log("stext: " + stext + " i: " + i );
      return subCadena(tempo, i) + "/manuales/";
      break;
    } i=i-1;
  }while(i>0);
  return subCadena(tempo, i) + "/manuales/";
}
let nombreManual="";
class Manual extends Component
{
  constructor(props) {
    super(props);
    this.state = {
                catalogo:this.props.catalogo                |"",
                     url:this.props.url										  |"",
                download:this.props.download                |"",

  //Variables
                  accion:this.props.accion,
									  data:this.props.data										|[],
    };
    this.cita=0;
  }
  componentDidMount(){
    //console.log("Url: " + this.state.url);
    //console.log("Download: " + this.state.download);
  }
  componentWillMount(){//obtenAtributos()
    switch (this.props.catalogo) {
      case 'navieraMovimientos':
        nombreManual="SkyOne-Carga-Masiva-Multiple-de-Movimientos-Navieros-V001";
        break;
      case 'gps':
        nombreManual="SkyOne-CargaMasivaGPSs";
        break;
      default:
        nombreManual=this.props.catalogo;
        break;
    }
    this.setState({ url: obtenAtributos() });//this.setState({ url:  "http://localhost:3000/manuales/" });
    this.setState({ download:""+nombreManual+".pdf" });
  }

render() {
    return (
      <div className="manual" id="manual">
        <p className="etiqueta" id="etiqueta">
        <center>
          <a download={this.state.download} href={this.state.url+this.state.download} disabled={this.state.url!=""?false:true }>Man</a>
        </center></p>
        <div className="tiptext">Manual de Referencia</div>
      </div>
    );
  }
}

export default Manual;
