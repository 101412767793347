import React, { Component } from "react";
import {  Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
/**
 * Date tools
 */
import { timeToDateFill, fechaValida } from "../../../../herramientas/DateFormat";
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
var user = JSON.parse(sessionStorage.getItem("usuario"));
function myFormato(date) {
  function pad(number) {
    if (number < 10) { return '0' + number; }
    return number;
  }
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}

class  ActualizarHorariosTerrestres extends Component {
  constructor(props) {
    super(props);
        this.state  = {
                        camposLogisticos:[],
                        fechaArribo             : "",
                        fechaSalida             : "",
                        tiempoEstadia           : "",
                        tiempoTraslado          : "",
                        fechaCambio             : "",
                        citaCarga               : "",
                        citaDescarga            : "",
                        inicioRuta              : "",
                        arriboUnidadContenedor  : "",
                        salidaUnidadContenedor  : "",
                        arriboTerminalCargado   : "",
                        arriboDescarga          : "",
                        entregaDocumentos       : "",
                        ingresoBodega           : "",
                        //campo                   : "",
                        ruta                    : { value: 0, label: "Selecciona Estatus de ruta"  },
                        entregas                : []
                      };
        this.movimientoObj = []; 
        this.campo = "";
        this.entrega  = {
          "arriboDescarga":"",
          "arriboTerminalCargado":"",
          "arriboUnidadContenedor":"",
          "campo":"",
          "citaCarga":"",
          "citaDescarga":"",
          "contacto":"",
          "destino":"",
          "direccion": "",
          "entregaDocumentos":"",
          "estatus":0,
          "eta":0,
          "fechaAlta":"",
          "fechaArribo":"",
          "fechaCambio":"",
          "fechaSalida":"",
          "idDestino":1,
          "idEntrega":0,
          "idMovimiento":0,
          "idRuta":0,
          "ingresoBodega":"",
          "inicioRuta":"",
          "latitud":19.3573479,
          "longitud":-99.004777,
          "orden":1,
          "salidaUnidadContenedor":"",
          "tiempoEstadia": null,
          "tiempoTraslado": null
        };
        this.rutasEstatus= [];
  }
  componentWillMount =()=>{
    this.getfuntion("get", "", "", "camposLogisticos/plataforma/"+this.props.movimiento.plataforma.id+"/mtipo/"+this.props.movimiento.movimientoTipo.id,"camposLogisticos");
    this.getfuntion("get", "", "", "entregas/movimiento/" + this.props.movimiento.id, "entregas");
    this.rutasEstatus.push({ value: 0, label: "Selecciona Estatus de ruta"  });
    this.props.rutasEstatusObject.map((elemento, index) => { this.rutasEstatus.push({ value: elemento.id, label: elemento.nombre, data: elemento  }); });
  }
  componentDidMount =() => {
    // console.log(document.cookie);
    

 
    // if(document.cookie===undefined|| document.cookie==""){
    //   console.log("segunda galleta es nula USANDO BD:"+ this.props.idEntregaActiva.idEntrega);

    // } else{
    //   console.log("no nula USANDO COOKIE");
    //   //this.props.idEntregaActiva.idEntrega=document.cookie;
    //   this.props.idEntregaActiva.idEntrega=('; '+document.cookie).split(`; idEntrega=`).pop().split(';')[0];
    //   this.props.idEntregaActiva.destino=('; '+document.cookie).split(`; destino=`).pop().split(';')[0];
    //    this.props.idEntregaActiva.fechaArribo= new Date(  ('; '+document.cookie).split(`; arribo=`).pop().split(';')[0]  );
    //    this.props.idEntregaActiva.fechaSalida= new Date(  ('; '+document.cookie).split(`; salida=`).pop().split(';')[0]  );
    //    document.cookie = "idEntrega=null; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //    document.cookie = "destino=null; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //    document.cookie = "arribo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //    document.cookie = "salida=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   // document.cookie = "cita=null; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // }


     try {
        this.entrega  = this.props.idEntregaActiva;
     }catch(e){
      console.log(e);
    }

    this.movimientoObj        = this.props.movimiento;
    //this.entrega.idMovimiento = this.props.movimiento.id;
     if(this.state.accion !== 'nuevo'){
      for(let item of this.rutasEstatus){
        if(item.value == this.props.idEntregaActiva.idRuta ){
          this.setState({ ruta: item });
        }
      }
      this.setState({tiempoEstadia          : this.props.idEntregaActiva.tiempoEstadia?           this.props.idEntregaActiva.tiempoEstadia                    : "" });
      this.setState({tiempoTraslado         : this.props.idEntregaActiva.tiempoTraslado?          this.props.idEntregaActiva.tiempoTraslado                   : "" });
      this.setState({fechaCambio            : this.props.idEntregaActiva.fechaCambio?             new Date(this.props.idEntregaActiva.fechaCambio)            : "" });
      this.setState({fechaArribo            : this.props.idEntregaActiva.fechaArribo?             new Date(this.props.idEntregaActiva.fechaArribo)            : "" });
      this.setState({fechaSalida            : this.props.idEntregaActiva.fechaSalida?             new Date(this.props.idEntregaActiva.fechaSalida)            : "" });
      this.setState({citaCarga              : this.props.idEntregaActiva.citaCarga?               new Date(this.props.idEntregaActiva.citaCarga)              : "" });
      this.setState({citaDescarga           : this.props.idEntregaActiva.citaDescarga?            new Date(this.props.idEntregaActiva.citaDescarga)           : "" });      
      this.setState({inicioRuta             : this.props.idEntregaActiva.inicioRuta?              new Date(this.props.idEntregaActiva.inicioRuta)             : "" });
      this.setState({arriboUnidadContenedor : this.props.idEntregaActiva.arriboUnidadContenedor?  new Date(this.props.idEntregaActiva.arriboUnidadContenedor) : "" });
      this.setState({salidaUnidadContenedor : this.props.idEntregaActiva.salidaUnidadContenedor?  new Date(this.props.idEntregaActiva.salidaUnidadContenedor) : "" });
      this.setState({arriboTerminalCargado  : this.props.idEntregaActiva.arriboTerminalCargado?   new Date(this.props.idEntregaActiva.arriboTerminalCargado)  : "" });
      this.setState({arriboDescarga         : this.props.idEntregaActiva.arriboDescarga?          new Date(this.props.idEntregaActiva.arriboDescarga)         : "" });
      this.setState({entregaDocumentos      : this.props.idEntregaActiva.entregaDocumentos?       new Date(this.props.idEntregaActiva.entregaDocumentos)      : "" });
      this.setState({ingresoBodega          : this.props.idEntregaActiva.ingresoBodega?           new Date(this.props.idEntregaActiva.ingresoBodega)          : "" });
    }


    

  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":{
          if (stateVar === "camposLogisticos") {
            this.setState({ camposLogisticos: returnVal });
          }
          if (stateVar === "entregas") {
            this.setState({ entregas: returnVal });
          }
        }break;
        case "put":
          if (stateVar === "movimientos") {
            this.usuariosLogObject.idBitacoraMonitoreo = 2;
            this.usuariosLogObject.idUsuario = user.id;
            this.usuariosLogObject.idMovimiento = obj.id;//returnVal.id;
            this.usuariosLogObject.accionFecha = myFormato(new Date());
            this.usuariosLogObject.descripcion = "Actualizacion de Entrega de Movimiento Terrestre id:" + obj.id + " Campo: " + this.state.campo;//returnVal.id;
            Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
          }
          if (stateVar === "entregas") {
            //if(returnVal){
              // console.log("55 entregas:returnVal");
              // console.log(returnVal);
              //this.setState({ estaEnfull: true });
            //}
          }
        break;
        default:
        break;
      }
    }).catch(err => { console.log(err); });
  }

  crud_put =() =>{
    this.props.closeModal();
    this.props.getfuntion("put", this.entrega,  this.campo, "entregas"            , "entregas");
  }

 
  checkDateFields =()=>{
   //document.onunload = function(){alert("Bye now!");}

    this.props.idEntregaActiva.fechaArribo=='1969-12-31 18:00:00'? this.props.idEntregaActiva.fechaArribo=null:console.log(this.props.idEntregaActiva.fechaArribo);
    this.props.idEntregaActiva.fechaSalida=='1969-12-31 18:00:00'? this.props.idEntregaActiva.fechaSalida=null:console.log(this.props.idEntregaActiva.fechaSalida);
    this.props.idEntregaActiva.inicioRuta=='1969-12-31 18:00:00'? this.props.idEntregaActiva.inicioRuta=null:console.log(this.props.idEntregaActiva.inicioRuta);

    // if (this.props.idEntregaActiva.fechaArribo==null) {
    //   alert("La fecha de Arribo no puede ser nula"); 
    //  // this.props.idEntregaActiva.fechaSalida=null;      
    //  // return
    // }
    //if (this.props.idEntregaActiva.fechaSalida==null) {alert("La fecha de Salida no puede ser nula"); return}
    //!! not null
    if(!!this.props.idEntregaActiva.fechaSalida)
       if((this.props.idEntregaActiva.fechaArribo < this.props.idEntregaActiva.fechaSalida)  )
       {
         this.crud_put();
       }
       else{
          alert("La fecha de Salida debe de ser Posterior a la fecha de Arribo"+this.props.idEntregaActiva.fechaSalida);
       }
    else{
      this.crud_put();
      
    }
    //window.location.href='monitoreo?q=activos';


    //console.log(typeof (this.props.idEntregaActiva.fechaArribo));
    //   this.props.idEntregaActiva.fechaArribo=null;        
    //alert(this.props.idEntregaActiva.fechaArribo);
   // this.crud_put();
    

  }
 
  
  terminarMovimiento =()=>{
    // if (this.props.idEntregaActiva.fechaArribo==null) {alert("La fecha de Arribo no puede ser nula"); return}
    // if (this.props.idEntregaActiva.fechaSalida==null) {alert("La fecha de Salida no puede ser nula"); return}
    let opEstatus= this.props.stor.operacionesEstatus;
    for( let item of opEstatus ){
      if(item.nombre.toUpperCase().includes("TERMINADO") ){
        this.movimientoObj.operacionEstatus= item;
      }
    }
    confirmAlert({
      title: 'Atención',
      message: '¿Esta seguro de terminar el movimiento('+this.props.movimiento.id+') ?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { this.props.getfuntion("put",this.movimientoObj, "", "terrestreMovimientos", "terrestreMovimientos", "", this.props.closeModal); }
        },
        {
          label: 'Cancelar',
          onClick: () => {return false}
        }
      ]
    });
  }

  terminarHabilitado =() =>{
    let errorTerminarMov= "";
    let habilitado = 1;
    let entregasFechasVacias = 0;
    
    for(let entrega of this.state.entregas){     
      if(!entrega.canceladaManual && !entrega.canceladaOtm) {                   
        if ( this.entrega.idEntrega === entrega.idEntrega){
          if( (this.entrega.inicioRuta === null || this.entrega.fechaArribo === null || this.entrega.fechaSalida === null) ){
            habilitado = 0;
            entregasFechasVacias++;
          }
        }else{
          if ( !(entrega.inicioRuta && entrega.fechaArribo && entrega.fechaSalida) ){
            habilitado = 0;
            entregasFechasVacias++;        
          } 
        }   
      }         
    }
    if (entregasFechasVacias > 0){
      errorTerminarMov= "el movimiento tiene "+entregasFechasVacias+" entrega"+(entregasFechasVacias>1?"s":"")+" con fechas incompletas";
    }

    if(habilitado === 0){
      return  {estatus: false, mensaje:  errorTerminarMov};
    }else{ return {estatus: true, mensaje:  errorTerminarMov}; }
  }

  borrarMovimiento =()=>{
    let opEstatus= this.props.stor.operacionesEstatus;
    for( let item of opEstatus ){
      if(item.nombre.toUpperCase().includes("ELIMINADO") ){
        this.movimientoObj.operacionEstatus= item;
      }
    }
    confirmAlert({
      title: 'Antención',
      message: '¿Esta seguro de eliminar el movimiento('+this.props.movimiento.id+')?, El movmiento se borrara de forma definitiva y no podrá recuperarse', 
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { this.props.getfuntion("put",this.movimientoObj, "", "terrestreMovimientos", "delTerrestreMovimientos", "", this.props.closeModal);   }
        },
        {
          label: 'Cancelar',
          onClick: () => {return false}
        }
      ]
    });
  }
  changeRuta                    =(event)=>{this.entrega.idRuta=event.value;this.setState({ ruta:event });this.campo=this.campo + " idRuta: " + this.entrega.idRuta;}
  onChangeTiempoEstadia         =(event)=>{this.entrega.tiempoEstadia=event.target.value+":00";this.setState({ tiempoEstadia:event.target.value+":00" });this.campo =  this.campo + " tiempoEstadia: " + event.target.value+":00";}
  onChangeTiempoTraslado        =(event)=>{this.entrega.tiempoTraslado=event.target.value+":00";this.setState({ tiempoTraslado:event.target.value+":00" });this.campo=this.campo + " tiempoTraslado: " + event.target.value+":00";}

  onChangeFechaCambio           =(date) =>{this.entrega.fechaCambio             = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ fechaCambio:             date });this.campo =  this.campo + " fechaCambio: " + timeToDateFill(new Date(date).getTime());}
  onChangeFechaArribo           =(date) =>{this.entrega.fechaArribo             = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ fechaArribo:             date });this.campo =  this.campo + " fechaArribo: " + timeToDateFill(new Date(date).getTime());}
  onChangeFechaSalida           =(date) =>{this.entrega.fechaSalida             = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ fechaSalida:             date });this.campo =  this.campo + " fechaSalida: " + timeToDateFill(new Date(date).getTime());}
  onChangeCitaDescarga          =(date) =>{this.entrega.citaDescarga            = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ citaDescarga:            date});this.campo =  this.campo + " citaDescarga: " + timeToDateFill(new Date(date).getTime());}
  onChangeCitaCarga             =(date) =>{this.entrega.citaCarga               = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ citaCarga:               date});this.campo =  this.campo + " citaCarga: " + timeToDateFill(new Date(date).getTime());}
  onChangeInicioRuta            =(date) =>{this.entrega.inicioRuta              = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ inicioRuta:              date });this.campo =  this.campo + " InicioRuta: " + timeToDateFill(new Date(date).getTime());}
  onChangeArriboUnidadContenedor=(date) =>{this.entrega.arriboUnidadContenedor  = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ arriboUnidadContenedor:  date });this.campo =  this.campo + " arriboUnidadContenedor: " + timeToDateFill(new Date(date).getTime());}
  onChangeSalidaUnidadContenedor=(date) =>{this.entrega.salidaUnidadContenedor  = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ salidaUnidadContenedor:  date });this.campo =  this.campo + " salidaUnidadContenedor: " + timeToDateFill(new Date(date).getTime());}
  onChangeArriboTerminalCargado =(date) =>{this.entrega.arriboTerminalCargado   = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ arriboTerminalCargado:   date });this.campo =  this.campo + " arriboTerminalCargado: " + timeToDateFill(new Date(date).getTime());}
  onChangeArriboDescarga        =(date) =>{this.entrega.arriboDescarga          = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ arriboDescarga:          date });this.campo =  this.campo + " arriboDescarga: " + timeToDateFill(new Date(date).getTime());}
  onChangeEntregaDocumentos     =(date) =>{this.entrega.entregaDocumentos       = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ entregaDocumentos:       date });this.campo =  this.campo + " entregaDocumentos: " + timeToDateFill(new Date(date).getTime());}
  onChangeIngresoBodega         =(date) =>{this.entrega.ingresoBodega           = date? timeToDateFill(new Date(date).getTime()): date;this.setState({ ingresoBodega:           date });this.campo =  this.campo + " ingresoBodega: " + timeToDateFill(new Date(date).getTime());}

  getCamposLogisticos = () =>{
    let camposLogist= [];
    this.state.camposLogisticos.map((cLog, index)=>{
      switch (cLog.nombreCampo) {
        /**
         * Importacion
         */
        case "cita_carga":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.citaCarga }  defaultShow={true}             onChange={this.onChangeCitaCarga} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeCitaCarga(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "cita_descarga":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.citaDescarga } defaultShow={true}           onChange={this.onChangeCitaDescarga}/>  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeCitaDescarga(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "inicio_ruta":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.inicioRuta } defaultShow={true}             onChange={this.onChangeInicioRuta} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeInicioRuta(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "fecha_arribo":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.fechaArribo } defaultShow={true}            onChange={this.onChangeFechaArribo} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeFechaArribo(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "fecha_salida":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.fechaSalida } defaultShow={true}           onChange={this.onChangeFechaSalida} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeFechaSalida(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "arribo_unidad_contenedor":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboUnidadContenedor } defaultShow={true} onChange={this.onChangeArriboUnidadContenedor} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeArriboUnidadContenedor(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "salida_unidad_contenedor":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.salidaUnidadContenedor }  defaultShow={true}onChange={this.onChangeSalidaUnidadContenedor} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeSalidaUnidadContenedor(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "arribo_terminal_cargado":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboTerminalCargado } defaultShow={true}  onChange={this.onChangeArriboTerminalCargado} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeArriboTerminalCargado(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "arribo_descarga":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.arriboDescarga } defaultShow={true}         onChange={this.onChangeArriboDescarga} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeArriboDescarga(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "entrega_documentos":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.entregaDocumentos } defaultShow={true}      onChange={this.onChangeEntregaDocumentos} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeEntregaDocumentos(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;
        case "ingreso_bodega":{
          camposLogist.push(
          <Grid.Col md={4} lg={4}>
            <Form.Group label={cLog.nombre}><DateTimePicker format="y-MM-dd h:mm:ss a"  value={ this.state.ingresoBodega } defaultShow={true}          onChange={this.onChangeIngresoBodega} />  
            <span> </span>
              <Button target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeIngresoBodega(new Date())}
              >
                <span className="badge">
                  <Icon link={true} name="update" />Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>);
        } break;

        default:
          break;
      }
    })
    return camposLogist;
  }
  render(){
    return (
      <div>       
        { this.state.accion === "modificar" ? <h1> { this.props.idEntrega.destino } </h1> : null }
       {/* <Grid.Row>
          <Grid.Col md={4}><Form.Group isRequired label="Estatus ruta"> <Select value={this.state.ruta}     onChange={this.changeRuta}    options={this.rutasEstatus} /> </Form.Group></Grid.Col>
       </Grid.Row> */}
        <Grid.Row>
          { this.getCamposLogisticos() }                   
        </Grid.Row>
        {/* <Grid.Row>
          <Grid.Col md={4} lg={4}>
            <Form.Group label="Fecha Cambio">
              <DateTimePicker format="y-MM-dd h:mm:ss a"  value={this.state.fechaCambio}  onChange={this.onChangeFechaCambio} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4} lg={4}>
            <Form.Group label="Tiempo Estadia">
              <Form.Input type="time" name="estadia"  value={this.state.tiempoEstadia}  onChange={this.onChangeTiempoEstadia} />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4} lg={4}>
            <Form.Group label="Tiempo Traslado">
              <Form.Input type="time" name="traslado"  value={this.state.tiempoTraslado} onChange={this.onChangeTiempoTraslado} />
            </Form.Group>
          </Grid.Col>
        </Grid.Row> */}
        <Grid.Row>
        <Grid.Col>
            <Form.Group className="text-red" label={ (this.entrega.inicioRuta != null && fechaValida(this.entrega.inicioRuta) === false)
              || (this.entrega.fechaArribo != null && fechaValida(this.entrega.fechaArribo) === false)
              || (this.entrega.fechaSalida != null && fechaValida(this.entrega.fechaSalida) === false) 
              ||(this.entrega.citaCarga != null && fechaValida(this.entrega.citaCarga) === false) ? "No puedes actualizar los datos (Botón de Guardar inhabilitado) ya que una fecha esta incorrecta" : null}>              
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <div className="float-right">
          <span className="badge">
              <Button 
                disabled =  { !( (this.entrega.inicioRuta == null || (this.entrega.inicioRuta && fechaValida(this.entrega.inicioRuta) === true )) 
                  && ( this.entrega.fechaArribo == null || (this.entrega.fechaArribo && fechaValida(this.entrega.fechaArribo) === true )) 
                  && ( this.entrega.fechaSalida == null || (this.entrega.fechaSalida && fechaValida(this.entrega.fechaSalida) === true )) 
                  && ( this.entrega.citaCarga == null || (this.entrega.citaCarga && fechaValida(this.entrega.citaCarga) === true )) ) } 
                size="sm" 
                RootComponent="a" 
                color="primary"
                // onClick= { () => this.crud_put() }
                onClick= { () => this.checkDateFields() }
              >
                {/* <span className="badge"><Icon link={true} name="save" />{this.props.idEntregaActiva.idEntrega}</span> */}
                <span className="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button> 
          </span>
          {user.id !== 77 ? (          
            <span className="badge">
              <Button target="_blank" size="sm" disabled = {!this.terminarHabilitado().estatus} RootComponent="a" color="green" onClick={() => this.terminarMovimiento() } > <span className="badge"><Icon link={true} name="edit" />Terminar Movimiento</span> </Button>
              <br/>
              {!this.terminarHabilitado().estatus &&<span className="text-red">No se puede terminar el movimiento porque {this.terminarHabilitado().mensaje}</span>}
            </span>                                                   
          ) : null} 
          {user.id !== 77 ? (
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={() => this.borrarMovimiento() } > <span className="badge"><Icon link={true} name="trash" />Borrar Movimiento</span> </Button>
            </span> 
          ) : null}
        </div>
      </div>
    );
  }
}
export default ActualizarHorariosTerrestres;
