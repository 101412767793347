import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { validarToken } from "./herramientas/JWT";
import {
  AcumuladoresPage,
  AlertasCategoriasPage,
  AlertasTiposPage,
  AdminstracionPage,
  AdminPage,
} from "./pages";
import { BloquesPage, BitacoraMonitoreoPage } from "./pages";
import {
  CategoriaPage,
  ClientesPage,
  ClientesContactosPage,
  OperacionesClientesPage,
  CorredoresPage,
} from "./pages";
import { DestinosPage, ConsignatariosMapaPage } from "./pages";

import {
  Error400,
  Error401,
  Error403,
  Error404,
  Error500,
  Error503,
} from "./pages";
import { EtiquetasPage, EventosPage, EtaConfiguracionesPage } from "./pages";
import { FuncionalidadesPage } from "./pages";

import {
  GpsTipoColocacionPage,
  GPSPage,
  GpsFijosPage,
  GPSHardwarePage,
  GPSEstatusPage,
} from "./pages";

import HomePage from "./HomePage.react";

import {
  IncidenciasTipoPage,
  InformesPage,
  InformesFuncionalidadesPage,
  IncidenciasPage,
  IncidenciasIndisciplinasPorTransportistaPage
} from "./pages";

import LogOut from "./Auth/LogOut";
import { LoginPage } from "./pages";

import {
  MovimientosTipoPage,
  MonitoreoPage,
  MonitoreoImpoPage,
  MonitoreoExpoPage,
  MovimientoGpsPage,
  UnidadesSinRutaPage,
  SkyColdPage,
} from "./pages";
import { NavierasPage, ClientesNavierosPage } from "./pages";
import {
  OperacionesTipoPage,
  OperacionesEstatusPage,
  OperadoresPage,
} from "./pages";

import {
  PersonasPage,
  PerfilesPage,
  PlataformasPage,
  PlataformasTipoPage,
  PrivilegiosPage,
  PatiosPage,
} from "./pages";

import { RutasEstatusPage, RecuperarGpsPage, ReglasPage } from "./pages";

import { SalaPage, TransportistasPage } from "./pages";

import {
  UsuariosPage,
  UnidadesPage,
  UnidadesLtPage,
  UnidadesMarcasPage,
  UsuarioConsolaPage,
  UnidadTipoPage,
  UsuarioOperacionClientePage,
  UnidadEstatusOperacionPage,
  UnidadTipoColocacionPage,
  TableroRetransmisionesPage,
} from "./pages";

/**
 * Usuario
 */
import { ProfilePage } from "./pages";

//import "tabler-react/dist/Tabler.css";
import OtdPage from "./pages/catalogosPages/OtdPage.react";

/**
 * Dashboards
 */
import {
  MovimientosXtransportistaSemanalPage,
  MovimientosActivosXtransportistaOTDPage,
  TableroVisibilidadDhlPage,
  TableroVisibilidadCallCenterDhlPage,
  EstatusPlacaPage,
  EstatusPlacaHistoricoPage,
  EstatusIncidenciaPage,
  EstatusViajePage,
  EstatusVisibilidadPage,
  EstatusGeorutaPage,
  HistoricoKPIPage,
  DashBoardGpsYearPage,
} from "./pages";
/**
 * Fin Dashboards
 */

/**
 * Mapa
 */
import { MapaPage } from "./pages";
/**
 * Fin Mapa
 */

/**
 * Gerenciador
 */
import { GerenciadorPage, GerenciadorLogPage } from "./pages";
/**
 * Fin Gerenciador
 */
import { SeguridadPage, RecuperarPasswordPage } from "./pages";
/**
 * Fin seguridad
 */

/**
 * Hooks
 */
import { ReportesPage } from "./pages";
import { ReportesGeneralesPage } from "./pages";

import { from } from "rxjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

//require ("./Temas/TablerDark.css");
require("./Temas/TablerDefault.css");

const queryClient = new QueryClient();

var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentTema = user
  ? JSON.parse(localStorage.getItem("skyonetema" + user.id))
  : null;
var hoja = document.createElement("style");
if (currentTema) {
  if (currentTema.value) {
    hoja.innerHTML = currentTema.tema;
    document.body.appendChild(hoja);
  }
}
const paginaspublicas = ["RECUPERAR_PASSWORD", "LOGIN", "ACTUALIZAR_PASSWORD"];
let ban = false;
for (let p of paginaspublicas) {
  if (window.location.href.toUpperCase().includes(p)) {
    ban = true;
  }
}
if (!ban) {
  validarToken(sessionStorage.getItem("tok"));
}
type Props = {||};
function App(props: Props): React.Node {
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        
          <Router>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/400" component={Error400} />
              <Route exact path="/401" component={Error401} />
              <Route exact path="/403" component={Error403} />
              <Route exact path="/404" component={Error404} />
              <Route exact path="/500" component={Error500} />
              <Route exact path="/503" component={Error503} />

              <Route exact path="/Admin" component={AdminPage} />
              <Route
                exact
                path="/adminstracion"
                component={AdminstracionPage}
              />
              <Route exact path="/acumuladores" component={AcumuladoresPage} />
              <Route
                exact
                path="/alertasCategorias"
                component={AlertasCategoriasPage}
              />
              <Route exact path="/alertasTipos" component={AlertasTiposPage} />

              <Route exact path="/bloques" component={BloquesPage} />
              <Route
                exact
                path="/bitacoramonitoreo"
                component={BitacoraMonitoreoPage}
              />
              <Route exact path="/categoria" component={CategoriaPage} />
              <Route exact path="/clientes" component={ClientesPage} />
              <Route
                exact
                path="/clientesContactos"
                component={ClientesContactosPage}
              />
              <Route
                exact
                path="/operacionesClientes"
                component={OperacionesClientesPage}
              />

              <Route exact path="/corredores" component={CorredoresPage} />

              <Route exact path="/destinos" component={DestinosPage} />

              <Route exact path="/etiquetas" component={EtiquetasPage} />
              <Route
                exact
                path="/etaConfiguraciones"
                component={EtaConfiguracionesPage}
              />

              <Route
                exact
                path="/funcionalidades"
                component={FuncionalidadesPage}
              />

              <Route exact path="/gps" component={GPSPage} />
              <Route exact path="/gpsFijos" component={GpsFijosPage} />
              <Route exact path="/gpsestatus" component={GPSEstatusPage} />
              <Route
                exact
                path="/gpstipocolocacion"
                component={GpsTipoColocacionPage}
              />
              <Route exact path="/gpsHardware" component={GPSHardwarePage} />

              <Route exact path="/informes" component={InformesPage} />
              <Route
                exact
                path="/informesFuncionalidades"
                component={InformesFuncionalidadesPage}
              />

              <Route
                exact
                path="/incidenciatipo"
                component={IncidenciasTipoPage}
              />
              <Route exact path="/incidencias" component={IncidenciasPage} />

              <Route exact path="/logout" component={LogOut} />
              <Route exact path="/login" component={LoginPage} />

              <Route
                exact
                path="/movimientostipo"
                component={MovimientosTipoPage}
              />

              <Route exact path="/navieras" component={NavierasPage} />
              <Route
                exact
                path="/clientes_navieros"
                component={ClientesNavierosPage}
              />
              <Route exact path="/otd" component={OtdPage} />

              <Route exact path="/perfilusuario" component={ProfilePage} />
              <Route exact path="/personas" component={PersonasPage} />
              <Route exact path="/plataformas" component={PlataformasPage} />
              <Route
                exact
                path="/plataformastipo"
                component={PlataformasTipoPage}
              />
              <Route exact path="/privilegios" component={PrivilegiosPage} />
              <Route exact path="/perfiles" component={PerfilesPage} />

              <Route exact path="/reglas" component={ReglasPage} />
              <Route exact path="/rutasestatus" component={RutasEstatusPage} />
              <Route
                exact
                path="/actualizar_password"
                component={SeguridadPage}
              />
              <Route
                exact
                path="/recuperar_password"
                component={RecuperarPasswordPage}
              />

              <Route
                exact
                path="/transportistas"
                component={TransportistasPage}
              />

              <Route exact path="/monitoreo" component={MonitoreoPage} />
              <Route
                exact
                path="/monitoreoimpo"
                component={MonitoreoImpoPage}
              />
              <Route
                exact
                path="/monitoreoexpo"
                component={MonitoreoExpoPage}
              />

              <Route exact path="/salasMonitoreo" component={SalaPage} />

              <Route exact path="/patios" component={PatiosPage} />

              <Route
                exact
                path="/operacionesEstatus"
                component={OperacionesEstatusPage}
              />
              <Route
                exact
                path="/operacionestipo"
                component={OperacionesTipoPage}
              />
              <Route exact path="/operadores" component={OperadoresPage} />
              <Route exact path="/recuperarGps" component={RecuperarGpsPage} />

              <Route
                exact
                path="/usuarioConsola"
                component={UsuarioConsolaPage}
              />
              <Route
                exact
                path="/usuarioTerrestre"
                component={UsuarioOperacionClientePage}
              />
              <Route exact path="/usuarios" component={UsuariosPage} />
              <Route
                exact
                path="/MovimientoGps"
                component={MovimientoGpsPage}
              />
              <Route
                exact
                path="/tableroRetransmisiones"
                component={TableroRetransmisionesPage}
              />

              <Route exact path="/unidades" component={UnidadesPage} />
              <Route
                exact
                path="/unidadesmarcas"
                component={UnidadesMarcasPage}
              />
              <Route exact path="/unidadTipo" component={UnidadTipoPage} />
              <Route
                exact
                path="/unidadEstatusOperacion"
                component={UnidadEstatusOperacionPage}
              />
              <Route
                exact
                path="/unidadTipoColocacion"
                component={UnidadTipoColocacionPage}
              />
              <Route
                exact
                path="/unidadessinruta"
                component={UnidadesSinRutaPage}
              />
              <Route exact path="/skycold" component={SkyColdPage} />
              <Route
                exact
                path="/consignatarios_mapa"
                component={ConsignatariosMapaPage}
              />
              <Route
                exact
                path="/reportes_por_trasportista_semanal"
                component={MovimientosXtransportistaSemanalPage}
              />
               <Route
                exact
                path="/Unidades_lt"
                component={UnidadesLtPage}
              />
              <Route
                exact
                path="/estatus_placas"
                component={EstatusPlacaPage}
              />
              <Route
                exact
                path="/estatus_placas_hist"
                component={EstatusPlacaHistoricoPage}
              />
              <Route
                exact
                path="/estatus_viajes"
                component={EstatusViajePage}
              />
              <Route
                exact
                path="/estatus_incidencias"
                component={EstatusIncidenciaPage}
              />
              <Route
                exact
                path="/estatus_visibilidad"
                component={EstatusVisibilidadPage}
              />
              <Route
                exact
                path="/estatus_georuta"
                component={EstatusGeorutaPage}
              />
              <Route exact path="/historico_KPI" component={HistoricoKPIPage} />

              <Route
                exact
                path="/otd_movimiento_activos_por_transportista"
                component={MovimientosActivosXtransportistaOTDPage}
              />
              <Route
                exact
                path="/tablero_visibilidad_dhl"
                component={TableroVisibilidadDhlPage}
              />
              <Route
                exact
                path="/tablero_visibilidad_call_center_dhl"
                component={TableroVisibilidadCallCenterDhlPage}
              />

              <Route exact path="/mapa" component={MapaPage} />
              <Route exact path="/gerenciador" component={GerenciadorPage} />
              <Route
                exact
                path="/gerenciador_log"
                component={GerenciadorLogPage}
              />

              <Route exact path="/reportes" component={ReportesPage} />
              <Route
                exact
                path="/reportesGenerales"
                component={ReportesGeneralesPage}
              />
              <Route
                exact
                path="/dashboard_gps_year"
                component={DashBoardGpsYearPage}
              />
              <Route
                exact
                path="/indisciplinas operativas"
                component={IncidenciasIndisciplinasPorTransportistaPage}
              />
              <Route component={Error404} />
            </Switch>
          </Router>
       
      </div>
    </QueryClientProvider>
  );
}

export default App;
