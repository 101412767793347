// @flow
import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";

class  CategoriaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {     id:0,
                   nombre:"",
               menuBloque:0,
                    icono:"",
                   accion:this.props.accion, //'nuevo'// modificar, nuevo
             disabledSave: true,
          crudbutonEnable: true, // Se desabilita cuando se hace click
    };
    this.enableSave = this.enableSave.bind(this);
  }
  isEnable=(Accion)=>{
    let borrar= "categorias_borrar";
    let actualizar= "categorias_editar";
    let agregar= "categorias_agregar";
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "borrar"){ 
        if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }
  componentDidMount =() => {
    if(this.state.accion === 'nuevo'){
    }else{
      this.setState({id:this.props.elementos.id });
      this.setState({nombre:this.props.elementos.nombre });
      this.setState({menuBloque:this.props.elementos.menuBloque });
      this.setState({icono:this.props.elementos.icono });
    }
  }

  enableSave =() =>{
    console.log("Enable button: "+this.state.nombre)
    if(this.state.nombre.length > 0 ){
      return false;
    }else{ return true }
  }

  changeName       =(event) =>  this.setState({nombre: event.target.value});
  changeMenuBloque =(event) => (this.setState({menuBloque: event.target.value})  );
  changeIcono      =(event) =>  this.setState({icono: event.target.value});
  delete()    { this.setState({ crudbutonEnable: false });  this.props.getfuntion("delete", "", this.state.id); }
  crud_put()  { this.setState({ crudbutonEnable: false });  this.props.getfuntion("put", {"id":this.state.id, "nombre":this.state.nombre, "menuBloque":this.state.menuBloque, "icono":this.state.icono } );}
  crud_post() { this.setState({ crudbutonEnable: false });  this.props.getfuntion("post",{"id":0, "nombre": this.state.nombre, "menuBloque":this.state.menuBloque, "icono":this.state.icono }); }
  render(){
    return (
      <div>
        { this.state.accion === "modificar" ? <h1> { this.props.elementos.nombre } </h1> : null }
      <Grid.Row>
        <Grid.Col md={12} xl={12}>
          <Grid.Row>
            <Grid.Col md={3} lg={3}>
              <Form.Group label="id">
                <Form.Input name="id" disabled type="number" value={this.state.id} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={9} lg={9}>
              <Form.Group label="Nombre">
                <Form.Input name="nombre" type="text" value={ this.state.nombre } onChange={this.changeName} maxlength="30" />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={9} lg={9}>
              <Form.Group label="MenuBloque">
                <Form.Input name="menuBloque" type="text" value={ this.state.menuBloque } onChange={this.changeMenuBloque} maxlength="30" />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={9} lg={9}>
              <Form.Group label="Icono">
                <Form.Input name="icono" type="text" value={ this.state.icono } onChange={this.changeIcono} maxlength="30" />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <div className="float-right">
        {this.isEnable("actualizar" || this.isEnable("agregar")) ?
          <span className="badge">
            <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable}
              onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
              <span className="badge"><Icon link={true} name="save" />Guardar</span>
            </Button>
          </span>
        :null}
        {this.state.accion === "modificar" && this.isEnable("borrar")?
          <span className="badge">
            <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()}  disabled={!this.state.crudbutonEnable}>
              <span className="badge"><Icon link={true} name="trash" />Borrar</span>
            </Button>
          </span>
          :
          null
        }
      </div>
      </div>
    );
  }
}
export default CategoriaForm;
