import React, { useState, useEffect } from 'react';
import Select from "react-select";
import SiteWrapper from "../../SiteWrapper.react";
import { Button, Grid, Table, Icon, Form, List } from 'tabler-react';
import { confirmAlert } from 'react-confirm-alert';
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import ModalLayout from "../../components/ModalLayout.react";
import DateTimePicker from "react-datetime-picker";
import { timeToDateFill } from "../../herramientas/DateFormat";
import { NotificationManager } from 'react-notifications';
import { ArrobaIncidenciaTerrestre } from "../../Monitoristas/Monitoreo/Arroba";
import { map } from 'rxjs/operators';
import TableroVisivilidadMensajeria from "../../socket/TableroVisivilidadMensajeria.react";
import { set } from 'd3-collection';
var user = JSON.parse(sessionStorage.getItem("usuario"));
//console.log(user.id, user.username);


let entregas = [];
let indiceEntregas = 0;
let gpsEstatusT = {};
let asignacionCargasTem = [];
let component = null;

const currentUser= JSON.parse(sessionStorage.getItem("currentUser"));

const TableroVisibilidadDhlPage = ({mov}) => {  
  const [ tableroVisibilidad, setTableroVisibilidad ] = useState([]);
  const [ open, setOpen]= useState(true);
  const [ hiddenModals, setHiddenModals ]= useState(true);
  const [ gpsEstatus, setGpsEstatus ] = useState({});
  const [ entregasRelease, setEntregasRelease ] = useState({});
  const [ incidencias, setIncidencias ] = useState({});
  const [ incidencia, setIncidencia ] = useState({});
  const [ comentario, setComentario ] = useState({});
  const [ entregasIncidencias, setEntregasIncidencias ] = useState([]);
  const [ fechaArribo, setFechaArribo ] = useState();
  const [ destinatariosIncidencias, setDestinatariosIncidencias ] = useState([]);
  const [lineaTransp, setLineaTransp] = useState({ value: 0, label: "Todas" });
  const [transportistas, setTransportista] = useState([]);

  const [inciden, setInciden] = useState({ value: 0, label: "Todas" });
  const [incidens, setIncidencs] = useState([]);
  const [cv, setCv] = useState("");
  const [paginacion, setPaginacion] = useState(50);

  const [ asignacionCargas, setAsignacionCargas ] = useState([]);
  const [ asignacionCarga, setAsignacionCarga ] = useState([]);

  const [ asignacionCargaDashboard, setAsignacionCargaDashboard ]  = useState([]);
  const [ asignacionCargaDashboardUsuarios, setAsignacionCargaDashboardUsuarios ]  = useState([]);
  const [ bandera, setBandera] = useState(false);
  const [ usuarios, setUsuarios]= useState({});

  useEffect(() => {
  indiceEntregas = 0;
  if(mov){
    crud("get", "", "", "unidadLt/visibilidadActivo/"+mov, "mov_tablero_visibilidad");
  }
  else if(currentUser[0].perfil.movimientosBy){
    switch (currentUser[0].perfil.movimientosBy) {
      case 3:
          crud("get", "", "", "terrestreMovimientos/cuentas/tablero_visibildad", "mov_tablero_visibilidad");
          break;
      case 4:
        crud("get", "", "", "terrestreMovimientos/asignacionCargas/tablero_visibildad", "mov_tablero_visibilidad");
        crud("get", "", "", "asignacionCargas", "catalogo_asignacion_cargas");  
        autoUdateUserDash();        
        setBandera(true);
        break;
      default:
        alert("Perfil no configurado para este modulo");
        break;
    }
  }else{
    alert("Perfil no configurado");
  }   
    crud("get", "", "", "incidencias/idplataforma/" + 1, "catalogo_incidencias");             
  }, []);
  /*
  const actualizarCuentaEspejo = (id_entrega, cuentaEspejo) => {
    crud("put", cuentaEspejo, id_entrega, "entregas/cuentaEspejo/" + id_entrega + "/" + cuentaEspejo, "cuenta_espejo");
  }
  */

  const guardarArriboEntrega = (registro) => {
    //console.log("entregas/" + registro.id_entrega + "/" + (fechaArribo ? timeToDateFill(new Date(fechaArribo).getTime()): fechaArribo));
    crud("put", {}, "", "entregas/" + registro.id_entrega + "/" + (fechaArribo ? timeToDateFill(new Date(fechaArribo).getTime()): fechaArribo), "actualizar_arribo_entrega");
  }

  const guardarIncidencia = (registro) => {
    crud("post", 
    {
      "idIncidencia"       :incidencia.value,
      "idMovimiento"       :registro.id_movimiento,
      "idEntrega"          :registro.id_entrega,  
      "comentario"         :comentario,
      "creado"             :null,
      "atencionUsuario"    :user.id,
      "atencionFecha"      :null,
      "latitud"            :0,
      "longitud"           :0,
    },
    "",
    "terrestreEntregasIncidencias",
    "incidenciasTerrestre");
  }

  const incidenciasNombre = (idIncidencia) => {
    for(let i of incidencias){      
      if(i.value === idIncidencia){        
        return i.data.nombre; 
      }
    }
    return '';
  }

  const listarIncidencias = (registro) => {
    crud("get", "", "", "terrestreEntregasIncidencias/entrega/" + registro.id_entrega, "incidenciasTerrestre");
  }
  
  const actualizarCuentaEspejo = (control_vehicular, cuentaEspejo) => {
    crud("put", cuentaEspejo, control_vehicular, "terrestreMovimientos/cuentaEspejo/" + control_vehicular + "/" + cuentaEspejo, "cuenta_espejo");
  }

  const eliminarEntregaTableroVisibilidad = (id_entrega, eliminarTablero) => {
    crud("put", eliminarTablero, id_entrega, "entregas/tableroVisibilidad/" + id_entrega + "/" + eliminarTablero, "entrega_tablero_visibilidad");
  }
 

  const obtenerAsignacionCargaNombre = (id) => {  
    //console.log(asignacionCargasTem); 
    for(let item of asignacionCargasTem){
      //console.log(item.value + ", " + id);
      if(item.value === id){
        return item.label;        
      }
    }
    return "";
  }


  const semaforo = () => {
    
    let gpsEstatusTem = gpsEstatusT;
    if(entregas[indiceEntregas].id_gps_fijo){
      crud("get", "", "", "gpsestatus/semaforo/plataforma/" + entregas[indiceEntregas].id_gg_plataforma + "/gps/" + entregas[indiceEntregas].id_gg_gps , "semaforo_colores").then((returnVal) => {
      
        if(gpsEstatusTem[entregas[indiceEntregas].id_gps_fijo]){
          gpsEstatusTem[entregas[indiceEntregas].id_gps_fijo] = returnVal;
        }else {
          gpsEstatusTem[entregas[indiceEntregas].id_gps_fijo] = {};
          gpsEstatusTem[entregas[indiceEntregas].id_gps_fijo] = returnVal;
        }   
        gpsEstatusT = gpsEstatusTem;
        indiceEntregas++;
        if(indiceEntregas < entregas.length){
          //console.log(gpsEstatusTem);           
          semaforo();
        }else{
          setGpsEstatus(gpsEstatusTem);    
          //console.log(gpsEstatusTem);      
        }                  
      }).catch(err => { console.log(err); });
    }else {
      gpsEstatusT = gpsEstatusTem;
      indiceEntregas++; 
      if(indiceEntregas < entregas.length){        
        semaforo();
      }else{
        setGpsEstatus(gpsEstatusTem);
        gpsEstatusT = gpsEstatusTem;
      }
    }
       
  }

  const getNombreEvetos = (evento) =>{
    switch (evento) {
      case 'KT': return 'En Tránsito';    
      case 'KE': return 'ETA';
      case 'KC': return 'Parada no programada';
      case 'KL': return 'Llegada con el cliente';
      case 'KS': return 'Salida del cliente';
      case 'KD': return 'Desvio de ruta';
      case 'EC': return 'Llegada al Centro de Carga';
      case 'SC': return 'Salida del Centro de Carga';
      case 'K3': return 'Arribo a consignatario 2 hrs antes';
    
      default:
        return "";
    }
  }

  const abrirmodalGps = (plataforma, gps) => {
    crud("get", "", "", "terrestreMovimientos/plataforma/"+plataforma+"/gps/" + gps, "urlGps");
  }

  const changeUsuarioAsignacionCarga = (e) => {      
    if(e){
      for(let r1 of e){
        let encontrado = false;
        for(let item of asignacionCarga){
          if(item.value === r1.value ){
            encontrado = true;
          }
        }
        if(!encontrado){        
          crud("post", 
          { "idUsuario":user.id,
            "idAsignacionCarga":r1.value,
            "idPlataforma":1,
            "fechaAsignacion":null}, "", "usuarioAsignacionCargas", "usuario_asignacion_cargas");
        }
      }
    }    

    if(e){
      for(let item of asignacionCarga){      
        let encontrado = false;                
        for(let r1 of e){                   
          if(item.value === r1.value){
            encontrado = true;
          }
        }                      
        if(!encontrado){
          crud("delete", "", user.id + "/" + item.value + "/1", "usuarioAsignacionCargas", "usuario_asignacion_cargas");
        }      
      }
    } else {      
      if(asignacionCarga.length === 1){
        crud("delete", "", user.id + "/" + asignacionCarga[0].value + "/1", "usuarioAsignacionCargas", "usuario_asignacion_cargas");
      }
    }    
  }

  const actualizarUsuarioDash=(e)=>{
    setUsuarios({});
    setUsuarios(e);
  }

  if(bandera === true){
    component = <div>
      <Grid.Row>
      <Grid.Col sm={12} lg={12}>
        <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-dark text-light mt-1">
          <Table.Header>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Col><strong>Asignación de Cargas</strong>
                </Table.Col>
                { asignacionCargas.map((ac, index) => 
                <Table.Col>{ac.label} ({(asignacionCargaDashboard).filter(a => a.nombre_asignacion_carga === ac.label).length})
                  <ul>
                  {asignacionCargaDashboardUsuarios.filter(a => a.id_asignacion_carga === ac.value).map((p, index2) => 
                    <li style={{ color: usuarios[p.id_usuario]?  (new Date().getTime()-usuarios[p.id_usuario].lastUpdate)<= (20000) ?"green":"red":"gray"}}>{  p.nombre }</li>
                  )
                  }
                  </ul>
                </Table.Col>)}
                
            </Table.Row>
          </Table.Body>
        </Table>
      </Grid.Col>
      <Grid.Col>
        <br/><br/>
      </Grid.Col>
    </Grid.Row>

    <Grid.Row>
      <Grid.Col>   
      <div style={ { "width":"450px" } }>           
        <Form.Group label="Asignación de Cargas (Multiple):">
          <Select value={asignacionCarga}  options={asignacionCargas} onChange={(e) => { changeUsuarioAsignacionCarga(e); }} isMulti={true } />                  
        </Form.Group>              
        </div>
      </Grid.Col>                            
    </Grid.Row>
  </div>
  }  

  return (
    <>
    {!mov ?
    <>
       <SiteWrapper/>
       <audio id="alerta12" src="songs/alert.mp3" ></audio>
      <TableroVisivilidadMensajeria 
          topic         = {["/topic/tableroVisibilidad","/topic/tableroAsignacion"]}
          elementos     = {tableroVisibilidad}
          actualizar    = { setTableroVisibilidad }
          usuarios      = { usuarios }
          setUsuarios   = { actualizarUsuarioDash }
      />
      <Grid.Row>
          <Grid.Col>
            <h2> Tablero de Visibilidad y Alertas</h2>
            <h3> Centro de Monitoreo </h3>
          </Grid.Col>          
        </Grid.Row>  

        {component}        
       
        <Grid.Row>
            <Grid.Col>
              <Form.Group label="Seleccione una Linea de Transporte :">
                <Select value={lineaTransp} options={transportistas} onChange={(e) => setLineaTransp(e) } isMulti={false} />
              </Form.Group>
            </Grid.Col>  
            <Grid.Col>
              <Form.Group label="Seleccione una Incidencia :">
                <Select value={inciden} options={incidens} onChange={(e) => setInciden(e) } isMulti={false} />
              </Form.Group>
            </Grid.Col> 
            <Grid.Col>
              <Form.Group label="Buscar por Control Vehicular :">
                <input value={cv} type="text" onChange={(e) => setCv(e.target.value) } />
              </Form.Group>
            </Grid.Col>   
            <Grid.Col>
              <Form.Group label="Registros Mostrados:">
                <input value={paginacion} type="number" onChange={(e) => setPaginacion(e.target.value > 500 ? 500 : e.target.value) } />
              </Form.Group>
            </Grid.Col> 
        </Grid.Row> 
    </>
    :
    ""
    }
      <Grid.Row>
          <Grid.Col>
            <h2> Tablero de Visibilidad</h2>
          </Grid.Col>          
        </Grid.Row>  

        {component}       
        <Grid.Row>           
            <div className="mt-5" >
            {/*Solo se muestran los transportista con movimientos activos*/}
            </div>           
            <Table border={0} cards={true} striped={true}  responsive={true} className="table-vcenter text-dark"
              headerItems={[
                {content:"CV"},
                {content:"Nombre Cuenta"},
                {content:"Nombre Dominio"},
                {content:"Linea de transporte"},
                {content:"Placa"},
                {content:"Rastreame"},
                {content:"Tipo de Vehículo"},
                {content:"Origen"},
                {content:"Destino"},
                {content:"Ordenes para destino"},
                {content:"Despacho"},
                {content:"Contacto de Transportista"},
                {content:"Cita de carga"},
                {content:"Cita de entrega"},
                // {content:"Asignación de Monitoreo"},
                {content:"Estatus de Pulsación"},
                {content:"Estatus de Georuta"},
                {content:"Reporte de Posicionamiento"},                
                {content:"Ultima Eta Calculada"},
                {content:"Incidente Operativo"},
                {content:"Cuenta Espejo"},
                {content:"Cierre"},
                {content:"Reporte de Plataforma"}
              ]}>
              <Table.Header ></Table.Header>
              <Table.Body>
              { tableroVisibilidad.filter(t => lineaTransp.value ? t.nombre_transportista === lineaTransp.value : true).filter(i => inciden.value ? i.ultima_incidencia === inciden.value : true).filter(c => c.control_vehicular.includes(cv)).sort().slice(0, paginacion).map((w, index) =>
                <Table.Row align="center" style={ w.cuenta_espejo === 0 ? { "background-color": "rgba(0, 0, 0, 0)" } : { "background-color": "rgba(243, 233, 19, .30)" }}>
                  <Table.Col><font size="2"> { w.control_vehicular } </font></Table.Col>
                  <Table.Col><font size="2"> { w.nombre_cuenta } </font></Table.Col>
                  <Table.Col><font size="2"> { w.nombre_dominio } </font></Table.Col>
                  <Table.Col><div style={ { "width":"150px" } }><font size="2"> { w.nombre_transportista } </font></div></Table.Col>
                  <Table.Col><div style={ { "color": "#F39C12", "width":"100px" } } ><font size="2">{ w.placa }</font></div></Table.Col>
                  <Table.Col><span className="badge"
                          onClick={() => w.id_gg_plataforma && w.id_gg_gps ? abrirmodalGps(w.id_gg_plataforma, w.id_gg_gps) : alert("Rastreo no disponible")}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="rastreame">
                          <label style={{ "color": "black", "font-size": "200%" }}><Icon name="map-pin" /></label>
                        </span></Table.Col>
                  <Table.Col><div style={ { "width":"100px" } }><font size="2"> { w.tipo_unidad } </font></div></Table.Col>
                  <Table.Col className={w.origen ? "text-secondary" : "text-dart"}><div style={ { "width":"100px" } }><font size="2"> { w.origen } </font></div></Table.Col>                                    
                  <Table.Col><div style={ { "color": "#F95143", "width":"250px"} }> <font size="2" >{ w.nombre_destino }</font></div></Table.Col>
                  <Table.Col><div style={ { "width":"130px" } }>
                    <font size="2">                
                        { entregasRelease[w.id_entrega] ? 
                          <ul list-style-type="disc"> {
                            entregasRelease[w.id_entrega].map((r, indicer) => 
                            <li>{ r.xid }</li>                          
                            ) } 
                          </ul>
                          : 
                          <div></div>                          
                        }                        
                    </font></div>
                  </Table.Col>
                  <Table.Col className={w.inicio_ruta ? "text-primary" : "text-dart"}><div style={ { "width":"130px" } }><font size="2"> { w.inicio_ruta ? w.inicio_ruta : 'NO'  } </font></div></Table.Col>
                  <Table.Col><div style={ { "color": "#212F3D", "width":"130px"} }><font size="2"> { w.nombre_operador } </font></div></Table.Col>
                  <Table.Col className={w.cita_carga ? "text-primary" : "text-dart"}><div style={ { "width":"130px" } }><font size="2"> {w.cita_carga ? w.cita_carga : ''} </font></div></Table.Col>
                  <Table.Col className={w.cita_entrega ? "text-primary" : "text-dart"}><div style={ { "width":"130px" } }><font size="2"> {w.cita_entrega ? w.cita_entrega : ''} </font></div></Table.Col>
                  {/* <Table.Col>
                    <div style={ { "width":"130px" } }>
                      <font size="2" className={"text-primary"}> {w.usuario ? w.usuario: ''} </font>                      
                    </div>
                  </Table.Col>*/}
                  <Table.Col>  
                  <font size="5">    
                      <div size="5" className={"fa fa-circle" } style = {{ color:
                        w.id_gps_fijo ? gpsEstatus[w.id_gps_fijo] ?
                        gpsEstatus[w.id_gps_fijo].semaforo === 1 ? "green" : 
                        gpsEstatus[w.id_gps_fijo].semaforo === 2 ? "yellow" :
                        gpsEstatus[w.id_gps_fijo].semaforo === 3 ? "red" : "rgba(0, 0, 0, .00)" : "rgba(0, 0, 0, .00)" : "rgba(0, 0, 0, .00)"
                        }}>
                      </div>
                    </font>
                  </Table.Col>
                  <Table.Col><font size="2">  </font></Table.Col>
                  <Table.Col>
                    <div style={ { "width":"250px" } }>
                      <font size="2" className={"text-primary"}> { w.ultimo_evento && w.fecha_ultimo_evento ? getNombreEvetos(w.ultimo_evento) + ' | ' + w.fecha_ultimo_evento : ''} </font> <br/>
                      <font size="2" className={"text-pink"}> { w.penultimo_evento && w.fecha_penultimo_evento ? getNombreEvetos(w.penultimo_evento) + ' | ' + w.fecha_penultimo_evento : ''} </font> <br/>
                      <font size="2" className={"text-green"}> { w.antepenultimo_evento && w.fecha_antepenultimo_evento ? getNombreEvetos(w.antepenultimo_evento) + ' | ' + w.fecha_antepenultimo_evento : ''} </font> <br/>
                    </div>
                  </Table.Col>                                    
                  <Table.Col className={(!w.eta) ? "" : (w.eta && w.eta < w.cita_entrega) ? "p-3 mb-2 bg-success text-white max-width" : "p-3 mb-2 bg-danger text-white"}>
                    <div style={ { "color": "#171617", "width":"175px"} } align="left">
                      <font size="2" > Cita: { w.cita_entrega } </font><br/> 
                      <font size="2"> Estimado: {w.eta} </font><br/> 
                      <font size="2"> Tiempo Faltante: {w.tiempo_faltante} </font><br/> 
                      <font size="2">Distancia faltante: { !w.distancia_faltante ? '' : w.distancia_faltante/1000 + ' Km.'  } </font> 
                    </div>
                  </Table.Col>     
                  <Table.Col> 
                    <div style={ { "width":"150px" } }>
                      { !w.ultima_incidencia ? <font></font> : <font size="2" className={"text-pink"}>Ultima incidencia: {w.ultima_incidencia}</font> }
                    </div><br/>             
                    <ModalLayout
                      tamanio={"70%"}
                      title="Nueva incidencia"
                      name={<Button type="button" className="btn btn-dark fa fa-pencil" onClick={ () => listarIncidencias(w)} ></Button>}
                      open={open}                      
                      setOpen={ setOpen }
                      hiddenModal={ hiddenModals }
                      Fclose={ setHiddenModals }
                      formulario={
                        <div align="center">
                          <Grid.Row>
                            <Grid.Col md={12} xl={12}>
                              <Grid.Row>

                                  <Grid.Col md={4} lg={4}>
                                    <Form.Group label="Incidencias"><Select value={ incidencia } onChange={(e) => { 
                                      setIncidencia(e); 
                                      crud("get", [], "", "personas/destinatarios_sky_terrestre_incidencias/"+w.id_movimiento+"/"+e.data.idEtiqueta, "destinatarios_incidencias");                                     
                                    }} options={ incidencias } /></Form.Group>
                                  </Grid.Col>
                                  <Grid.Col md={2} lg={2}>
                                    <Form.Group label="Latitud">
                                        <Form.Input name="latitud"  value="" onChange="" />
                                    </Form.Group>
                                  </Grid.Col>
                                  <Grid.Col md={2} lg={2}>
                                    <Form.Group label="Longitud">
                                      <Form.Input name="longitud"  value="" onChange="" />
                                    </Form.Group>
                                  </Grid.Col>
                                  <Grid.Col md={12}>
                                      <Form.Group label="Comentario">
                                          <Form.Textarea name="comentario" type="text" value="" onChange={(e) => setComentario(e.target.value)} maxlength="50" />
                                      </Form.Group>
                                  </Grid.Col>

                                  <Grid.Col md={12}>
                                    <ul>
                                      {destinatariosIncidencias.map((di, indiceIncidencia) =>  
                                        <li> {di.nombre + " " + di.aPaterno + " " + di.aMaterno + "<" + di.correo + ">"} </li>
                                      )}
                                    </ul>  
                                  </Grid.Col>  

                                  <span className="badge">                                    
                                      <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled="" onClick={ () => guardarIncidencia(w) } >
                                        <span className="badge"><Icon link={true} name="save" />Guardar Incidencia</span>
                                      </Button>                                    
                                  </span>
                              </Grid.Row>

                              <Grid.Row>
                                <Grid.Col><font size="2"><h2></h2></font></Grid.Col>                                  
                              </Grid.Row>
                              <Grid.Row>                                
                                <Grid.Col><font size="1"><h4 align="left">Listado de Incidencias</h4></font></Grid.Col>
                              </Grid.Row>

                              <Grid.Row>
                              <Table border={1} cards={true} striped={false} responsive={true} className="table-vcenter text-dark border border-primary" style={{ "background-color": "#F3F5E9"}}
                                headerItems={[
                                  {content:"Incidencia"},
                                  {content:"Comentario"},
                                  {content:"Fecha registro"}
                                ]}>
                                <Table.Header ></Table.Header>
                                <Table.Body>
                                {entregasIncidencias.map((ei, indice1) => 
                                  <Table.Row align="center">
                                    <Table.Col><font size="2"> { incidenciasNombre( ei.idIncidencia )} </font></Table.Col>
                                    <Table.Col><font size="2"> { ei.comentario } </font></Table.Col>
                                    <Table.Col><font size="2"> { ei.creado } </font></Table.Col>
                                  </Table.Row>
                                )}
                                </Table.Body>
                              </Table>
                              </Grid.Row>
                            </Grid.Col>
                          </Grid.Row>
                        </div>
                      }
                    />                    
                  </Table.Col>                  
                  <Table.Col className={"text-primary"}>
                  <div className="float-right">
                  { w.cuenta_espejo === 0 ? 
                    <span className="badge">
                      <Button target="_blank" size="sm"  RootComponent="a" color="primary" onClick={ () => actualizarCuentaEspejo(w.control_vehicular, 1)}>
                        <span className="badge"><Icon link={true} name="save" /> Marcar </span>
                      </Button>
                    </span>                  
                  : <span className="badge">
                      <Button target="_blank" size="sm"  RootComponent="a" color="gray" onClick={ () => actualizarCuentaEspejo(w.control_vehicular, 0)}>
                        <span className="badge"><Icon link={true} name="save" /> Desmarcar </span>
                      </Button>
                    </span> 
                  }                  
                  </div>
                  <br/><br/><br/>
                  <div>
                  { w.cuenta_espejo === 0 ? <font size="1" className={"text-pink"}> Sin Cuenta Espejo </font> : <font size="2"> Con Cuenta Espejo </font> }
                  </div>
                  </Table.Col>
                  <Table.Col>
                    <div className="float-right">
                      <span className="badge">
                        <Button target="_blank" size="sm"  RootComponent="a" color="primary" onClick={ 
                          
                          () => confirmAlert({
                              title: 'Antención',
                              message: '¿Esta seguro de terminar el monitoreo de la entrega?',
                              buttons: [
                                {
                                  label: 'Aceptar',
                                  onClick: () => { eliminarEntregaTableroVisibilidad(w.id_entrega, 1) }
                                },
                                {
                                  label: 'Cancelar',
                                  onClick: () => {return false}
                                }
                              ]
                          }) 

                          }>
                          <span className="badge"><Icon link={true} name="delete" /> Cerrar </span>
                        </Button>
                    </span>
                    </div>
                  </Table.Col>
                  <Table.Col> 
                  
                  { !w.tablero_visibilidad && w.fecha_arribo ? 
                    <font size="2" className={"text-green"}> Fecha Arribo: {w.fecha_arribo} </font> 
                    : w.tablero_visibilidad ? 
                    <font size="2" className={"text-red"}> Fecha Arribo: {w.fecha_arribo} </font> 
                    : <font size="2"> </font> }
                    <ModalLayout
                      tamanio={"40%"}
                      title="Modificar"
                      name={<Button type="button" className="btn btn-dark fa fa-calendar-check-o" onClick={() => setFechaArribo(w.fecha_arribo)}></Button>}
                      open={open}
                      setOpen={ setOpen }
                      hiddenModal={ hiddenModals }
                      Fclose={ setHiddenModals }
                      formulario={
                        <div align="center">
                          <Grid.Row>
                            <Grid.Col><font size="2"><h2></h2></font></Grid.Col>                                  
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Col><font size="2"><h2></h2></font></Grid.Col>                                  
                          </Grid.Row>
                          <Form.Group label="Arribo de unidad a su destino:">
                            <h5 align="left">Captura la fecha y hora: </h5>                             
                          </Form.Group> 
                          <Grid.Row>
                            <Grid.Col><font size="2"><h2></h2></font></Grid.Col>                                  
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Col align="center">
                              <DateTimePicker format="y-MM-dd h:mm:ss a"  value={ fechaArribo ? new Date(fechaArribo) : null }  onChange={(e) => setFechaArribo(e) } />
                            </Grid.Col>  
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Col><font size="2"><h2></h2></font></Grid.Col>                                  
                          </Grid.Row>                                                                       
                          <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled="" onClick={() => guardarArriboEntrega(w) } >
                            <span className="badge"><Icon link={true} name="save" />Guardar</span>
                          </Button> 
                          <Grid.Row>
                            <Grid.Col><font size="2"><h2></h2></font></Grid.Col>                                  
                          </Grid.Row>
                        </div>
                      }
                    />
                                    
                  </Table.Col>
                </Table.Row>
              )}
              </Table.Body>
            </Table>
        </Grid.Row>           
        <Grid.Row>
          <Grid.Col>
            <h2> </h2>
            <h2> </h2>
          </Grid.Col>
        </Grid.Row>  
        </>
    //</SiteWrapper>    
  );
  



  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    if(stateVar === "mov_tablero_visibilidad" && returnVal) {
                      setTableroVisibilidad(returnVal);
                      entregas = returnVal;
                                            
                      let transportistasTem = [{value:"", label:"todas"}];
                      returnVal.map((r, index) => { 
                        let ban = false;
                        for(let item of transportistasTem){
                          if(item.value === r.nombre_transportista){
                            ban = true;
                          }
                        }
                        if(!ban) {
                          transportistasTem.push( { value:r.nombre_transportista , label:r.nombre_transportista }); 
                        }                        
                      });
                        setTransportista(transportistasTem);

                        let incidencTem = [{value:"", label:"todas"}];
                        returnVal.map((r, index) => { 
                          let band = false;
                          for(let item of incidencTem){
                            if(item.value === r.ultima_incidencia){
                              band = true;
                            }
                          }
                          if(!band) {
                            incidencTem.push( { value:r.ultima_incidencia , label:r.ultima_incidencia }); 
                          }
                        });
                        setIncidencs(incidencTem);
                        if (currentUser[0].perfil.movimientosBy != 1) {
                          setTimeout(() => {
                            semaforo();
                            crud("get", "", "", "entregasRelease/activas", "entregas_release");
                          }, 20000);
                        }else{
                          setTimeout(() => {
                            semaforo();
                            crud("get", "", "", "entregasRelease/activas", "entregas_release");
                          }, 1000);
                        }
                        
                    }
                    if(stateVar === "semaforo_colores" && returnVal) {
                     return returnVal;
                    }
                    if(stateVar === "entregas_release" && returnVal) {
                      let entregasReleaseTem = {};
                      //console.log(returnVal);
                      for(let t of returnVal){
                        if(!entregasReleaseTem[t.idEntrega]){
                          entregasReleaseTem[t.idEntrega] = [];
                        }
                        entregasReleaseTem[t.idEntrega].push(t);
                      }
                      //console.log(entregasReleaseTem);
                      setEntregasRelease(entregasReleaseTem);
                     }
                     if(stateVar === "catalogo_incidencias" && returnVal){
                      let incidenciasTem = [];
                      returnVal.map((i, index) => incidenciasTem.push({value:i.id, label:i.nombre, data:i}));
                      setIncidencias(incidenciasTem); 
                     }
                     if(stateVar === "incidenciasTerrestre" && returnVal){
                      setEntregasIncidencias(returnVal);
                    }
                    if(stateVar === "destinatarios_incidencias" && returnVal){
                      setDestinatariosIncidencias(returnVal);
                    }
                    if(stateVar === "urlGps"){
                      window.open(returnVal.url, 'sharer', 'width=900,height=600,scrollbars=NO');
                    }
                    if(stateVar === "catalogo_asignacion_cargas" && returnVal){
                      asignacionCargasTem = [];
                      returnVal.map((i, index) => asignacionCargasTem.push({value:i.idAsignacionCarga, label:i.nombre, data:i}));
                      setAsignacionCargas(asignacionCargasTem); 
                      crud("get", "", "", "usuarioAsignacionCargas/usuario/" + user.id, "usuario_asignacion_cargas");
                     }
                    if(stateVar === "usuario_asignacion_cargas" && returnVal){                      
                     let asignacionCargaTem = [];
                     returnVal.map((i, index) => asignacionCargaTem.push({value:i.idAsignacionCarga, label:obtenerAsignacionCargaNombre(i.idAsignacionCarga), data:i}));
                     setAsignacionCarga(asignacionCargaTem);
                    } 
                    if(stateVar === "usuario_asignacion_cargas_dashboard" && returnVal){                                           
                      setAsignacionCargaDashboard(returnVal);
                     } 
                     if(stateVar === "usuario_asignacion_cargas_dashboard_usuarios" && returnVal){                                           
                      setAsignacionCargaDashboardUsuarios(returnVal);
                     } 
                } break;
                case "put": {
                  if(stateVar === "cuenta_espejo") {
                    let tableroVisibilidadTem = [...tableroVisibilidad];
                      for(let t = 0; t < tableroVisibilidadTem.length; t++){
                        if(tableroVisibilidadTem[t].control_vehicular === id){
                          tableroVisibilidadTem[t].cuenta_espejo = returnVal ? 1 : 0;
                          setTableroVisibilidad(tableroVisibilidadTem);
                        }
                      }
                  }
                  if(stateVar === "entrega_tablero_visibilidad") {
                    let tableroVisibilidadTem = [...tableroVisibilidad];
                      for(let t = 0; t < tableroVisibilidadTem.length; t++){
                        if(tableroVisibilidadTem[t].id_entrega === id){
                          tableroVisibilidadTem[t].eliminarTablero = returnVal ? 1 : 0;
                          tableroVisibilidadTem.splice(t, 1);
                          setTableroVisibilidad(tableroVisibilidadTem);
                        }
                      }
                  }
                  if(stateVar === "actualizar_arribo_entrega"){
                    NotificationManager.info(returnVal.fechaArribo, "Fecha Arribo, Entrega ");
                  }
                } break; 
                case "post": {
                  if(stateVar === "incidenciasTerrestre"){
                    NotificationManager.info(returnVal.comentario, "Nueva Incidencia, " + incidenciasNombre(returnVal.idIncidencia));
                    setHiddenModals(true);

                    let dest ="";
                      
                    try {
                      destinatariosIncidencias.forEach((key, index)=>{
                        dest = dest.length > 0 ? dest + ", " : dest;
                        dest = dest + key.nombre+" "+key.aPaterno+ " "+ key.aMaterno+"<"+ key.correo+ ">";
                      });
                    }catch(e){
                      console.log(e);
                    }

                    if(dest.length){
                      ArrobaIncidenciaTerrestre(returnVal).then((returnVals) =>{
                        let usuariosLogObject = {
                          "id": 0,
                          "idBitacoraMonitoreo": 0,
                          "idUsuario": 0,
                          "idMovimiento": 0,
                          "idPlataformaTipo": 2,
                          "accionFecha": "",
                          "descripcion": ""
                        };
  
                        NotificationManager.info(returnVals, "Notificación de incidencia" );
                        usuariosLogObject.idBitacoraMonitoreo=6;
                        usuariosLogObject.idUsuario=user.id;
                        usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                        usuariosLogObject.idPlataformaTipo=2;
                        usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                        usuariosLogObject.descripcion = "Arroba Incidencia (" + dest +" ), Detalle: "+ returnVals;
                        Crud_Catalogos("usuariosLog", "tablero_visibilidad", "post", "", usuariosLogObject, "", "", "", "");
                      });
                    }                    
                  }
                  if(stateVar === "usuario_asignacion_cargas" && returnVal){
                    window.location.reload();
                    //getUsersDash();
                    //crud("get", "", "", "usuarioAsignacionCargas/usuario/" + user.id, "usuario_asignacion_cargas");
                  }
                } break;
                case "delete": {
                  if(stateVar === "usuario_asignacion_cargas" && returnVal){
                    window.location.reload();
                    //getUsersDash();
                    //crud("get", "", "", "usuarioAsignacionCargas/usuario/" + user.id, "usuario_asignacion_cargas");
                  }
                } break;
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
  }
  function autoUdateUserDash(){
    getUsersDash();
    setTimeout(() => {
      autoUdateUserDash();
    }, (1000*60*3));//3 min
  }
  function getUsersDash (){
    crud("get", "", "", "usuarioAsignacionCargas/dashboard/", "usuario_asignacion_cargas_dashboard");
    crud("get", "", "", "usuarioAsignacionCargas/dashboardUsuarios/", "usuario_asignacion_cargas_dashboard_usuarios");
  }

}


export default TableroVisibilidadDhlPage;
