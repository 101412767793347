import React, { Component } from "react";
import { Grid, Nav} from "tabler-react";
import SiteWrapper from "./SiteWrapper.react";
import {Crud_Catalogos} from "./herramientas/Crud_Catalogos";
/**
 * SplitterLayout
 */
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { height } from "window-size";
import { Doughnut, Bar, Polar } from 'react-chartjs-2';
//https://www.npmjs.com/package/react-chartjs-2
//http://jerairrest.github.io/react-chartjs-2/
const data = {
	labels: [
		'MXSKYANGEL',
		'MX APM',
		'MX COLOMBIA'
	],
	datasets: [{		
		data: [60, 50, 87],
		backgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		]
	}]
};
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = { operacionesEstatus: [], contadores: [] };
		this.operacionesEstatus = [];
		this.contadores= {};
	  }
	  componentWillMount = () => {
		this.getfuntion("get", [], "", "operacionesEstatus", "operacionesEstatus");
	  }
	  componentDidMount = () => { }

	  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
		Crud_Catalogos(catalogo, "home", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
		  /**
		   * filtro de variables
		   */
		  switch (metodo) {
			case "get":
			  { if (stateVar === "operacionesEstatus") { 
				  if (returnVal) { 
					returnVal.map((operacion, index)=>{
						this.getfuntion("get", [], "", "navieraContador/operacion/"+operacion.id, "contadores");
					});
					  this.setState({ operacionesEstatus: returnVal }); 
					} 
				}
				if (stateVar === "contadores") {
					var dat={
						labels: [ ],
						datasets: [{ data: [], backgroundColor: [ ], hoverBackgroundColor: [], label: []  }]
					};
					returnVal.map((corredor, index)=>{  
						dat.labels.push(corredor.nombre);
						dat.datasets[0].data.push(corredor.count);
						//dat.datasets[0].label.push(corredor.nombre);
						let color= "#000000".replace(/0/g, () => (~~(Math.random() * 16)).toString(16));
						dat.datasets[0].backgroundColor.push(color);
						dat.datasets[0].hoverBackgroundColor.push(color+90);
					});
					if(returnVal[0]){ 
						this.contadores[returnVal[0].operacionEstatus]= {operacion: returnVal[0].operacionEstatus, grafica: dat } 
					}
					this.setState({ contadores: this.contadores });
				}
			  }
			  break;
			default:
			  break;
		  }
		}).catch(err => {
		  if (err.response) {
			console.log(err.response);
		  } else {
			/**
			 * Error
			 */
			console.log("Error desconocido ...." + err);
		  }
		})
	  }
	render() {
		return (
			<SiteWrapper>
				<div className="row" style={{ height: "70vh", textAlign: "center" }}>
					{this.state.operacionesEstatus.map((operacion, index)=> 
						<div className="col-6">				
							<div>
								<h5>{ operacion.nombre }</h5>
							</div>
							<div style={{ height: "80%" }}>
								<Bar
									data={this.state.contadores[operacion.id]? this.state.contadores[operacion.id].grafica: [] }
									width={100}
									height={90}
									options={{ maintainAspectRatio: false }}
								/>
							</div>
						</div>
					)}
					
					
				</div>
			</SiteWrapper>
		  );
	}
  
}
export default Home;
