import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import PerfilesTabs from "../../../components/TabsLayout.react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
var FilaAct=[];
function validaActivo(evento, FHabilitados, columna) {
  let regresa = 0;
  FHabilitados.map( (elementos, index) => {
    if (elementos.idEtiqueta==columna.id)
      {
        regresa = 1;
      }
    }
    )
  FilaAct[columna.id]=regresa;
  return regresa
}
class Etiquetas extends Component {
  constructor(props) {
    super(props);
    this.state = {
                     id:"",
                 nombre:"",
                estatus:false,
      clientesEtiquetas:[],
              Etiquetas:[],
           FHabilitados:[],
                 search:"",
                 accion:this.props.accion, //'nuevo'// modificar, nuevo
                 FArray:[],
             currentTap:0,
    };
    this.Array = [];
  }
  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      //this.etiquetasObject = this.props.elementos;
      this.cargaEtiquetas("");
      this.cargaClientesEtiquetas(this.props.elementos.id);
    } else {
      //this.etiquetasObject = { "id": 0, "nombre": "", "estatus": 0, "etiquetas": {} }
    }
  }
  changeEvento =(evento, clientesId, columna) => {
    let objetoCE = { "idCliente": clientesId, "idEtiqueta":evento+1}
    objetoCE.idEtiqueta=columna.id
    if (FilaAct[columna.id]===0){
    //                               principal   temporal default       id     parametros opcional          state              cerrar modal       si error actualizar
    //              (apiRaiz="",         api="",metodo,id, params,                   storeFunction="",  stateElementos=[], hiddenModal="", arraytoUpdate=[], columna="", url= conf.api_raiz, filtro="" ){
      Crud_Catalogos("clientesEtiquetas", "", "post", "", objetoCE , "" , "", "", [], "", "", "").then((returnVal) => {
        var tempo = this.state.FHabilitados;
        tempo.push(returnVal);
        this.setState({ FHabilitados:tempo });
        tempo=[];
        FilaAct[columna.id]=1
      })
    }else{
      Crud_Catalogos("clientesEtiquetas"+"/"+clientesId, "", "delete", columna.id, "" , "" , "FHabilitados").then((returnVal) => {
        var tempo = this.state.FHabilitados;
        for(let i=0; i< tempo.length; i++){
          if (tempo[i].idEtiqueta===columna.id){
            tempo.splice(i, 1);
            this.setState({ FHabilitados:tempo });
          }
        }
        tempo=[];
        FilaAct[columna.id]=0;
      })
    }
  }
  componentDidUpdate = (nexProps) => {
    if (nexProps.elementos.etiquetas !== this.state.etiquetas) {
      //alert("es igual")
    } else { /*alert(this.state.Etiquetas) */ }
  }
  changeName = (event) => {
    this.perfilesObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  }
  changeEstatus = (event) => {
    event.target.checked ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });
  }

  changeEtiqueta = (etiquetaindex, privId, estatus) => {
    this.perfilEtiquetaObject = {
      "idPerfil": this.state.id,
      "idEtiqueta": privId,
      "nombre": this.props.stor.etiquetas_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[etiquetaindex].nombre
    }
    if (this.EtiquetasArray[etiquetaindex].estatus === 1) { this.etiquetasObject.etiquetas.splice(etiquetaindex, 1) }
    this.EtiquetasArray[etiquetaindex].estatus === 1 ?
      this.props.getfuntion("delete", "", this.EtiquetasArray[etiquetaindex].id, this.perfilObject, "clientesEtiquetas", this.props.stor.perfiles_update)
      :
      this.props.getfuntion("post", this.perfilEtiquetaObject, this.state.id, this.perfilObject, "clientesEtiquetas", this.props.stor.perfiles_update, "etiquetas");
  }


  cargaEtiquetas(filtro) {
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("etiquetas", "", "get", "",[],"" , "", "", [], "", "", "").then((regresaValores) => {
    this.setState({ Etiquetas:regresaValores });
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }
  cargaClientesEtiquetas(filtro) {
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("clientesEtiquetas", "", "get", "",[],"" , "", "", [], "", "", "/"+filtro).then((regresaValores) => {
    //this.setState({ clientesEtiquetas:regresaValores });
    this.setState({ FHabilitados:regresaValores });
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }

  delete() {
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.props.getfuntion("put", {
      "id": this.state.id, "nombre": this.state.nombre,
      "estatus": this.state.estatus
    });
  }
  crud_post() {
    this.props.getfuntion("post", {
      "nombre": this.state.nombre,
      "estatus": this.state.estatus
    });
  }

  checkedEtiqueta = (indice) => {
    return this.etiquetasArray[indice].estatus
  }
  checaretiquetas = (estatus, id) => {
    this.etiquetasArray.push({ estatus: estatus, id: id })
  }
  checarEtiquetaTem = (valor) => {
    this.checarEtiqueta = valor;
  }
  searching = (event) => {
    this.setState({ search: event.target.value })
  }
  cleanArrayList = () => {
    this.etiquetasArray = [];
  }
  selectedTab = (index) => {
    this.setState({ currentTap: index })
  }
  render() {

    return (
      <div>

        {/*
          *etiquetas
          */
        }

        {this.cleanArrayList()}
        {this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="Etiquetas" isCollapsible={false} isCollapsed={false} options={<Form.Group>
                    <Form.Input className="mb-3" icon="search" placeholder="Buscar Etiqueta..." position="append" onChange={this.searching} />
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Descripción</Table.ColHeader>
                            <Table.ColHeader>Asignada</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.state.Etiquetas.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento["id"]}</Table.Col>
                                  <Table.Col>{elemento["nombre"]}</Table.Col>
                                  <Table.Col>{elemento["descripcion"]}</Table.Col>
                                  <Table.Col>
                                    <Form.Switch type="Checkbox" name="toggle" value="option1" className={"disabled"}
                                    checked={ validaActivo(index, this.state.FHabilitados, elemento) }
                                    onChange={() => this.changeEvento(index, this.state.id, elemento)} />
                                  </Table.Col>
                                </Table.Row>
                              )
                            }

                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
          : null}

      </div>
    );
  }
}
export default Etiquetas;
