import React, { useState, useEffect } from 'react';
import { Doughnut, Bar, Polar,Pie } from 'react-chartjs-2';
import SiteWrapper from "../../SiteWrapper.react";
import { Grid, Button, Icon, Form, Table } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import Select from "react-select";

const EstatusIncidenciaPage = () => {
 
  const [ resultado_api, setResultadoApi    ] = useState([]);
  const [chartdata,setCharData]=useState( {
    labels: [],
    datasets: [
      {
        label: "",
        backgroundColor: [ ],
        data: [],
      },
    ],});
  const [estatus,setEstatus]=useState({value:0,label:"Todas"});


 useEffect(() => {
  crud("get", "", "", "graficosVisibilidad/estatusIncidencias/" , "datos_grafica");
},chartdata

);
 

 
  return (
    <SiteWrapper>
      <h2 >Viajes con Incidentes</h2>
       <div className="container mt-3"  style={{width:600}} >
        <Pie
           data={chartdata.datasets?
             chartdata.datasets.data?
                chartdata.datasets.data.length?
             chartdata.filter(r=>estatus.value? r.estatus===estatus.label : true )
             :chartdata :chartdata :chartdata
            }
        options={{
          responsive:true,
          legend: { display: true, position: "left" },
          datalabels: {
            display: true,
            color: "white",
          },
          tooltips: {
            backgroundColor: "#5a6e7f",
          },
        }}
      />
      </div>

<div className="container mt-3">


<Grid.Row>
              <Grid.Col>
                <Form.Group label="Seleccione Estatus :">
                  <Select value={estatus}  options={[
                    {value:0,label:"Todas"},
                    {value:1,label:"GPCarga / Descarga"},
                    {value:2,label:"Accidentado"},
                    {value:3,label:"Descomposturas"},
                    {value:4,label:"Paradas"},
                    {value:5,label:"Estatus Ruta"},
                    {value:6,label:"Ruta"},
                    {value:7,label:"Seguridad"},
                    {value:8,label:"One Home"},
                    {value:9,label:"Transporte"},
                    {value:10,label:"Robo"}, ]
                  
                  } onChange={(e)=>setEstatus(e)} isMulti={false } />
                </Form.Group>
              </Grid.Col>              
        </Grid.Row>
                <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>CV </Table.ColHeader>
                            <Table.ColHeader>Línea Transporte</Table.ColHeader>
                            <Table.ColHeader>Estatus</Table.ColHeader>
                           </Table.Header>
                          
                           <Table.Body>
                           {
                           
                             resultado_api.filter(r=>estatus.value? r.estatus===estatus.label : true ).map((element,index)=>
                             <Table.Row>
                              <Table.Col> {element["cv"]  } </Table.Col> 
                              <Table.Col> {element["lineaTransporte"]  } </Table.Col> 
                              <Table.Col> {element["estatus"]  } </Table.Col>
 
                              </Table.Row>
                             ) 
                           
                           }

                          </Table.Body> 
                        </Table>


</div>
      
    </SiteWrapper>
  );

  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    if (stateVar === "mov_tras_x_semana" && returnVal) {
                      var dat={
                        labels: [ ],
                        datasets: [{ data: [], backgroundColor: [ ], hoverBackgroundColor: [], label: []  }]
                      };
 
                      returnVal.map((t, index)=>{
                        dat.labels.push(t.nombre);

                        dat.datasets[0].data.push(t.totmovsem);
                        //dat.datasets[0].label.push(corredor.nombre);
                        let color= "#000000".replace(/0/g, () => (~~(Math.random() * 16)).toString(16));
                        dat.datasets[0].backgroundColor.push(color);
                        dat.datasets[0].hoverBackgroundColor.push(color+90);
                      });
                     }
 
//mros
                    if( stateVar  === 'datos_grafica' && returnVal){
                         let carga=0;
                         let accidentado=0;
                         let descompostura=0;
                         let parada=0;
                         let estatus_ruta=0;
                         let ruta=0;
                         let seguridad=0;
                         let one_home=0;
                         let transporte=0;
                         let robo=0;
                         setResultadoApi(returnVal);                
                         console.log("returnVal(graph):"+returnVal);
                         console.log(resultado_api);
                         let labels = [];
                         let data = [];
                         returnVal.forEach(element => {
                         if(element.estatus=="GPCarga / Descarga"){carga++; }
                         if(element.estatus=="Accidentado"){accidentado++;}
                         if(element.estatus=="Descomposturas"){descompostura++;}
                         if(element.estatus=="Paradas"){parada++;}
                         if(element.estatus=="Estatus Ruta"){estatus_ruta++;}
                         if(element.estatus=="Ruta"){ruta++;}
                         if(element.estatus=="Seguridad"){seguridad++;}
                         if(element.estatus=="One Home"){one_home++;}
                         if(element.estatus=="Transporte"){transporte++;}
                         if(element.estatus=="Robo"){robo++;}




                       })
                     
                     
                        labels=["GPCarga / Descarga","Accidentado","Descomposturas","Paradas","Estatus Ruta","Ruta","Seguridad","One Home","Transporte","Robo"];
                        data=[carga,accidentado,descompostura,parada,estatus_ruta,ruta,seguridad,one_home,transporte,robo];
                         let customLabels = labels.map((label,index) =>`${label}: ${data[index]}`)
                         setCharData (  {
                        labels: customLabels,
                        datasets: [
                          {
                            label: "Estatus de placas",
                            backgroundColor: [
                              "#83ce83",
                              "#f96a5d",
                              "#6800B4",
                              "#00A6B4",
                              "#83ce83",
                              "#f96a5d",
                              "#6800B4",
                              "#00A6B4",
                              "#83ce83",
                              "#f96a5d",
                              "#6800B4",
                              "#00A6B4",
                            ],
                            data: data,
                          },
                        ],});
                    }


                } break;
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }
}


export default EstatusIncidenciaPage;
