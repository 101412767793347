import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";

class Plataforma extends Component {
  constructor(props) {
    super(props);
    this.state = {
            id: "",
        nombre: "",
         clave: "",
          idGg: "",
    colorFondo: "",
    colorBoton: "",
plataformaTipo: 0,
        accion: this.props.accion, //'nuevo'// modificar, nuevo
      corredorArayList: [],
      crudbutonEnable: true, // Se desabilita cuando se hace click
    };
    this.colum = [];
    this.accordion = [];
    this.enableSave = this.enableSave.bind(this);
    this.plataformasObject = [];
    this.checarCorredor = false;
    this.corredoresArray = [];
    this.plataformaId = 0;
    this.plataformaCorredoresObject = [];
    this.plataformaCorredoreId = 0;
  }
  isEnable=(Accion)=>{
    let borrar= "plataformas_borrar";
    let actualizar= "plataformas_editar";
    let agregar= "plataformas_agregar";
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "borrar"){ 
        if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }
  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ clave: this.props.elementos.clave });
      this.setState({ idGg: this.props.elementos.idGg });
      this.setState({ colorFondo: this.props.elementos.colorFondo });
      this.setState({ colorBoton: this.props.elementos.colorBoton });
      this.setState({ plataformaTipo: this.props.elementos.plataformaTipo.id });
      this.setState({ corredorArayList: this.corredoresArray })
      this.plataformasObject = this.props.elementos;
    } else {
      this.plataformasObject = {
        "id": "",
        "nombre": "",
        "clave": "",
        "idGg": "",
        "colorFondo": "",
        "colorBoton": "",
        "corredores": [],
        "plataformaTipo": {
          "id": "",
          "nombre": ""
        }
      };
    }
  }

  enableSave = () => {
    if (this.state.nombre.length > 0 && this.state.clave.length > 0) {
      return false;
    } else { return true }
  }

  delete()    { this.setState({ crudbutonEnable: false });  this.props.getfuntion("delete", "", this.state.id); }
  crud_put()  { this.setState({ crudbutonEnable: false });  this.props.getfuntion("put", this.plataformasObject); }
  crud_post() { this.setState({ crudbutonEnable: false });  this.props.getfuntion("post", this.plataformasObject); }

  changePlataformaTipo = (event) => {
    this.props.stor.plataformasTipo_object.map((elemento, index) => {
      if (event.target.value == elemento.id) {
        this.plataformasObject.plataformaTipo = elemento;
      }
    }
    );
    this.setState({ plataformaTipo: event.target.value });
  }
  changeName = (event) => {
    this.plataformasObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  }
  changeClave = (event) => {
    this.plataformasObject.clave = event.target.value;
    this.setState({ clave: event.target.value });
  }
  changeIdGg = (event) => {
    this.plataformasObject.idGg = event.target.value;
    this.setState({ idGg: event.target.value });
  }
  changeColorFondo = (event) => {
    this.plataformasObject.colorFondo = event.target.value;
    this.setState({ colorFondo: event.target.value });
  }
  changeColorBoton = (event) => {
    this.plataformasObject.colorBoton = event.target.value;
    this.setState({ colorBoton: event.target.value });
  }

  changeCorredor = (coredorIndex, plataformaId, corredorId) => {
    let corredor = [];
    if (!this.state.corredorArayList[coredorIndex]) { // Add coredor
      for (let item of this.props.stor.corredores_object) {
        if (item["id"] === corredorId) {
          //this.plataformasObject.corredores.push(item);
          corredor = item;
          break;
        }
      }
    } else { //deleted etiqueta
      this.plataformasObject.corredores.map((elemento, index) => {
        if (elemento.idCorredor.id === corredorId) {
          this.plataformaCorredoreId = elemento.id;
          this.plataformasObject.corredores.splice(index, 1);
        }
      })
    }


    this.plataformaCorredoresObject = {
      "idPlataforma": this.plataformasObject,
      "idCorredor": corredor,
      "estatus": 1
    }
    this.state.corredorArayList[coredorIndex] ? this.props.getfuntion("delete", "", this.plataformaCorredoreId, this.plataformasObject, "plataformasCorredores")
      :
      this.props.getfuntion("post", this.plataformaCorredoresObject, "", this.plataformasObject, "plataformasCorredores", "", "corredores");

    this.state.corredorArayList[coredorIndex] ? this.corredoresArray[coredorIndex] = 0 : this.corredoresArray[coredorIndex] = 1

    this.setState({ corredorArayList: this.corredoresArray })
    //console.log(etiqueta+" :: "+ this.state.Etiqueta_arraylist[etiqueta]);

  }

  render() {
    return (
      <div>
        {
          //console.log("corredores a agregar **********************")}{
          //console.log(this.plataformaCorredoresObject = [])
        }
        {this.state.accion === "modificar" ? <h1> {this.props.elementos.nombre} </h1> : null}
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <Card
              title="Plataformas"
              isCollapsible
              //isClosable
              isCollapsed={false}
              body={
                <div>
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Group label="id">
                        <Form.Input name="id" disabled type="number" value={this.state.id} />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Plataforma Tipo">
                        <Form.Select
                          name="plataformatipo"
                          value={this.state.plataformaTipo}
                          onChange={this.changePlataformaTipo}
                        >
                          <option value={0}>{"Selecciona una opción"}</option>
                          {
                            this.props.stor.plataformasTipo_object.map((elemento, index) =>
                              <option value={elemento["id"]}>{elemento["nombre"]}</option>
                            )

                          }
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={6} lg={6}>
                      <Form.Group label="Nombre">
                        <Form.Input name="nombre" type="text" value={this.state.nombre} onChange={this.changeName} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Clave">
                        <Form.Input name="descripcion" type="text" value={this.state.clave} onChange={this.changeClave} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={4} lg={4}>
                      <Form.Group label="IdGg">
                        <Form.Input name="idGg" type="text" value={this.state.idGg} onChange={this.changeIdGg} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <Form.Group label="Color de Fondo">
                        <Form.Input name="colorFondo" type="text" value={this.state.colorFondo} onChange={this.changeColorFondo} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <Form.Group label="Color Boton">
                        <Form.Input name="colorBoton" type="text" value={this.state.colorBoton} onChange={this.changeColorBoton} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>

                  </Grid.Row>
                </div>}
            />
          </Grid.Col>

        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
          <div className="float-right">
            {this.isEnable("actualizar" || this.isEnable("agregar")) ?
              <span className="badge">
                <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable}
                  onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                  <span className="badge"><Icon link={true} name="save" />Guardar</span>
                </Button>
              </span>
            :null}
            {this.state.accion === "modificar" && this.isEnable("borrar")?
              <span className="badge">
                <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()}  disabled={!this.state.crudbutonEnable}>
                  <span className="badge"><Icon link={true} name="trash" />Borrar</span>
                </Button>
              </span>
              :
              null
            }
          </div>
          </Grid.Col>
        </Grid.Row>
        {/**
          *   Elementos de plataformas
          */
        }
      </div>
    )
  }
}

export default Plataforma
