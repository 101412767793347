// @flow
import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { Crud_Catalogos } from '../../../herramientas/Crud_Catalogos';
class Unidades extends Component {// 001UP6
  constructor(props) {
    super(props);
    this.state = {
      //Campos
         nombre:"",
         color: "",
         modelo:"",
         placa:"",
         unidadMarca: { value:"id", label:"Selecciona una Unidad Marca " },
         idVideo: null,
         videoDispositivo:{},
      //Variables
          accion: this.props.accion, //'nuevo'// modificar, nuevo
      Plataforma: { value:"id", label:"Selecciona una Plataforma " },
   Transportista: [],
      UnidadTipo: { value:"id", label:"Selecciona un Tipo de Unidad " },
         unidadEstatus: { value:"id", label:"Selecciona un unidadEstatus " },
  crudbutonEnable: true, // Se desabilita cuando se hace click
      };
      this.UnidadesObj={
        "nombre": "",
        "unidadMarca": null,//objeto
        "placa": "",
        "color": "",
        "modelo": "",
        "plataforma": 1,//objeto
        "unidadEstatus": null,//objeto
        "unidadTipo": null,//objeto
        "gerenciado": false,
        "idVideo": null
      };
      this.unidadMarcas=[];
      this.Plataforma = [];
      this.Transportistas = [];
      this.UnidadTipo = [];
      this.unidadEstatus = [];
}
isEnable=(Accion)=>{
  let borrar= "unidades_borrar";
  let actualizar= "unidades_editar";
  let agregar= "unidades_agregar";
  var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
  for (let i = 0;  i<privilegios.length; i++) {
    if(Accion === "borrar"){
      if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
  }
  return false
}

  componentDidMount =() => {
    /**
     * Objetos
     */
    this.props.stor.plataformas_object.map((elemento, index) =>             this.Plataforma.push({ value:elemento.id, label:elemento.nombre, data: elemento }) );
    this.props.stor.transportistas_object.map((elemento, index) =>          this.Transportistas.push({ value:elemento.id, label:elemento.nombre, data:elemento }) );
    this.props.stor.unidadTipo_object.map((elemento, index) =>              this.UnidadTipo.push({ value:elemento.id, label:elemento.nombre, data:elemento }) );
    this.props.stor.unidadesmarcas_object.map((elemento, index) =>          this.unidadMarcas.push({ value:elemento.id, label:elemento.nombre, data:elemento }) );
    this.props.stor.unidadEstatusOperacion_object.map((elemento, index) =>  this.unidadEstatus.push({ value:elemento.id, label:elemento.nombre, data:elemento }) );    
    if(this.state.accion === 'nuevo'){
    }else{
      console.log(this.props.elementos);
      this.setState({nombre:        this.props.elementos.nombre});
      this.setState({color:         this.props.elementos.color});
      this.setState({modelo:        this.props.elementos.modelo});
      this.setState({idVideo:        this.props.elementos.idVideo});
      this.setState({placa:         this.props.elementos.placa});
      this.setState({ unidadMarca:    this.props.elementos.unidadMarca?   { value: this.props.elementos.unidadMarca.id,   label:this.props.elementos.unidadMarca.nombre }   :null});
      this.setState({ UnidadTipo:     this.props.elementos.unidadTipo?    { value:this.props.elementos.unidadTipo.id,     label:this.props.elementos.unidadTipo.nombre }    :null});
      this.setState({ unidadEstatus:  this.props.elementos.unidadEstatus? {value:this.props.elementos.unidadEstatus.id,   label:this.props.elementos.unidadEstatus.nombre } :null});      
      this.setState({ Plataforma:     this.props.elementos.plataforma?    { value:this.props.elementos.plataforma.id,     label:this.props.elementos.plataforma.nombre }    :null});       
      this.UnidadesObj=this.props.elementos;
      this.getfuntion("get", "", "", "transportistas_unidades/id_unidad/" + this.props.elementos.id, "transportistas_unidades");
      this.getVideoDispositivo(this.props.elementos.idVideo);
    }
  }

  enableSave =() =>{
    if ( this.UnidadesObj.nombre.length > 0 /*&& this.UnidadesObj.plataforma*/ && this.UnidadesObj.unidadTipo ){
      return false;
    }else{ return true }
  }
  getVideoDispositivo=(id)=>{
    delete axios.defaults.headers.common["Authorization"];
    axios.get("https://cctv.skyangel.com.mx:8443/video_back/"+"dispositivos/live/unidades/"+id ).then(res => {
        if(res.data)
          this.setState({ videoDispositivo: res.data });
      });
  }
  changeNombre        =(event) =>{  this.setState({nombre: event.target.value});          this.UnidadesObj.nombre = event.target.value;  }
  changeColor         =(event) =>{  this.setState({color:  event.target.value});          this.UnidadesObj.color = event.target.value;  }
  changeModelo        =(event) =>{  this.setState({modelo: event.target.value});          this.UnidadesObj.modelo = event.target.value;  }
  changeIdVideo       =(event) =>{
      this.setState({idVideo: event.target.value});
      this.UnidadesObj.idVideo = event.target.value;
      this.getVideoDispositivo(event.target.value);
    }
  changePlaca         =(event) =>{  this.setState({placa:  event.target.value});          this.UnidadesObj.placa = event.target.value;  }
  changeUnidadMarca   =(event) =>{  this.setState({unidadMarca: event});                  this.UnidadesObj.unidadMarca= event.data;      }
  changeunidadEstatus = (event) =>{ this.setState({unidadEstatus: event});                this.UnidadesObj.unidadEstatus= event.data;    }
  changePlataforma    =(event) =>{  this.setState({Plataforma: event});                   this.UnidadesObj.plataforma= event.data;       }
  /*
  changeTransportista =(e) =>{
    this.setState({Transportista:event});
    this.UnidadesObj.transportista= event.data;
  }*/
  changeTransportista = (e) => {   
    if(e){
      for(let r1 of e){
        let encontrado = false;
        for(let item of this.state.Transportista){
          if(item.value === r1.value ){
            encontrado = true;
          }
        }
        if(!encontrado){
          this.getfuntion("post",
          {
            "idUnidad": this.props.elementos.id,
            "idTransportista": r1.value
          }, "", "transportistas_unidades","transportistas_unidades");
        }
      }
    }

    if(e){
      for(let item of this.state.Transportista){      
        let encontrado = false;                
        for(let r1 of e){                   
          if(item.value === r1.value){
            encontrado = true;
          }
        }                  
        if(!encontrado){
          this.getfuntion("delete","", item.value + "/" + this.props.elementos.id, "transportistas_unidades","transportistas_unidades");
        }      
      }
    } else {      
      if(this.state.Transportista.length === 1){
        this.getfuntion("delete","", this.state.Transportista[0].value + "/" + this.props.elementos.id, "transportistas_unidades","transportistas_unidades");
      }
    }    
  }
  obtenerTransportistaNombre= (id) => {
    for(let item of this.Transportistas){
      if(item.value === id){
        return item.label;        
      }
    }
    return "";
  }
  changeUnidadTipo    =(event) =>{  this.setState({UnidadTipo:event});                    this.UnidadesObj.unidadTipo = event.data;            }
  
  delete()    { this.setState({ crudbutonEnable: false });  this.props.getfuntion("delete", "",                 this.props.elementos.id); }
  crud_put()  { this.setState({ crudbutonEnable: false });  this.props.getfuntion("put",    this.UnidadesObj, "", "unidades","unidades"); }
  crud_post() { this.setState({ crudbutonEnable: false });  this.props.getfuntion("post",   this.UnidadesObj, "", "unidades","unidades"); }
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "loquequieras", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":{
            if (stateVar === "gpsFijos"){
              let gpsFijosTem=[];
              returnVal.map((elemento, index) => gpsFijosTem.push({ value:elemento.id, label:elemento.nombre, data: elemento }) );
              this.setState({ gpsFijos: gpsFijosTem});
            }
            if(stateVar === "transportistas_unidades" && returnVal){                      
              let transportistasUnidadesTem = [];
              returnVal.map((i, index) => transportistasUnidadesTem.push({value:i.idTransportista, label: this.obtenerTransportistaNombre(i.idTransportista), data:i}));
              this.setState({ Transportista: transportistasUnidadesTem });
              } 
            } break;
          case "post": {
            if(stateVar === "transportistas_unidades" && returnVal){
              NotificationManager.info("Se ha vinculado la LT", "Actualización");
              //window.location.reload();
              this.getfuntion("get", "", "", "transportistas_unidades/id_unidad/" + this.props.elementos.id, "transportistas_unidades");
            }
            } break;
          case "delete": {
            if(stateVar === "transportistas_unidades" && returnVal){
              NotificationManager.error("Se ha removido la LT", "Actualización");
              this.getfuntion("get", "", "", "transportistas_unidades/id_unidad/" + this.props.elementos.id, "transportistas_unidades");
            }
          } break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }
  render() {
    return (
      <div>
        { this.state.accion === "modificar"? <h1>{this.props.elementos.id}({ this.props.elementos.nombre})</h1>:null }
        <Grid.Row>
          <Grid.Col>
            <Grid.Row>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Nombre">
                  <Form.Input name="nombre" type="text" value={ this.state.nombre } onChange={this.changeNombre}/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Color">
                  <Form.Input name="color" type="text" value={ this.state.color } onChange={this.changeColor}/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Modelo">
                  <Form.Input name="modelo" type="text" value={ this.state.modelo } onChange={this.changeModelo}/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Placa">
                  <Form.Input name="placa" type="text" value={ this.state.placa } onChange={this.changePlaca}/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
              <Form.Group label="Marca" >
                  <Select value={this.state.unidadMarca} onChange={this.changeUnidadMarca} options={this.unidadMarcas} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Tipo de Unidad" >
                    <Select value={this.state.UnidadTipo} onChange={this.changeUnidadTipo} options={this.UnidadTipo} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="unidadEstatus" >
                    <Select value={this.state.unidadEstatus} onChange={this.changeunidadEstatus} options={this.unidadEstatus} />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="idVideo (id de unidad en cctv)">
                  <Form.Input name="idVideo" type="text" value={ this.state.idVideo } onChange={this.changeIdVideo}/>
                  <p className="text-primary">{this.state.videoDispositivo.id? this.state.videoDispositivo.nombre: "" }</p>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <br />
            <Grid.Row>
              {/* <Grid.Col md={4} lg={4}>
                <Form.Group label="Plataforma" >
                    <Select value={this.state.Plataforma} onChange={this.changePlataforma} options={this.Plataforma} />
                </Form.Group>
              </Grid.Col> */}
              { this.state.accion !== 'nuevo'?
                <Grid.Col md={12} lg={12}>
                  <Form.Group label="Transportista(Los cambios se aplican de forma automatica, no es necesario dar click en el botón guardar)" >
                      <Select value={this.state.Transportista} onChange={this.changeTransportista} options={this.Transportistas} isMulti={true } /> 
                  </Form.Group>
                </Grid.Col>:null}
            </Grid.Row>      
          </Grid.Col>
        </Grid.Row>
        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                <span className="badge"><Icon link={true} name="save" />Guardar</span>
              </Button>
            </span>
          :null}
          {this.state.accion === "modificar" && this.isEnable("borrar")?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()}  disabled={!this.state.crudbutonEnable}>
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
            :
            null
          }
        </div>
      </div>
    );
  }
}
export default Unidades;
