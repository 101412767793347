import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Catalogo from "../../components/Catalogo";
import {PlataformasForm}  from "../../Catalogos";

import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
function getfuntion (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
  Crud_Catalogos(catalogo, "perfiles", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
    switch (metodo) {
      case "get":
      if (stateVar === "funcionalidades") {
        for( let item of returnVal){
          if(item.nombre == "Plataformas"){ return true; }
        }
        window.history.back();
        window.location.href = "403";
      }  break;
      default:
        break;
    }
  }).catch(err => { console.log("Error desconocido ...."+ err); })
}
function PlataformasPage() {
  getfuntion("get", "", "", "funcionalidades/Administracion/"+currentUser[0].perfil.id, "funcionalidades");
  return (
    <SiteWrapper>
        <Grid.Col sm={12} lg={12}>
         <Catalogo Ctlogo="plataformas" tamanio="100%" FCtlogo= { PlataformasForm }
                   catalogos= {{ 0:"plataformasTipos",
                                 1:"usuarios",
                                 2:"operacionesTipo",
                                 3:"operacionesEstatus",
                                 4:"rutasEstatus",
                                 5:"movimientosTipo",
                                 6:"funcionalidades",
                                 7:"acumuladores",
                                 8:"unidadesMarcas",
                                 9:"unidadTipo",
                                10:"unidadEstatusOperacion",
                                11:"unidadTipoColocacion",
                                12:"gpsHardware",
                                13:"etaConfiguracion",
                                14:"etiquetas",
                                15:"alertasTipos",
                                16:"alertasCategorias",
                                17:"privilegios",
                                18:"incidencias",
                                19:"plataformas"
                               } } />
        </Grid.Col>
    </SiteWrapper>
  );
}
export default PlataformasPage;
