import React, { Component }                      from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import PerfilesTabs                              from "../../../components/TabsLayout.react";
import { Crud_Catalogos, Crud_error }            from '../../../herramientas/Crud_Catalogos';
var FilaAct=[];
function validaActivo(evento, FHabilitados, columna) {
  let regresa = 0;
  if (FHabilitados.length>0){
    FHabilitados.map( (elementos, index) => {
      if (elementos.persona.id==columna.id)
        {
          regresa = 1;
        }
      });
  }
  FilaAct[columna.id]=regresa;
  return regresa
}
class Personas extends Component {
  constructor(props) {
    super(props);
    this.state = {
                     id:"",
                 nombre:"",
                paterno:"",
                materno:"",
                estatus:false,
      clientesContactos:[],
               Personas:[],
           FHabilitados:[],
                 search:"",
                 accion:this.props.accion, //'nuevo'// modificar, nuevo
                 FArray:[],
             currentTap:0,
    };
    this.Array = [];
  }
  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre:this.props.elementos.nombre});//, paterno:this.props.elementos.paterno, materno:this.props.elementos.materno });
      //this.PersonasObject = this.props.elementos;
      this.cargaPersonas("");
      this.cargaTransportistasContactos(this.props.elementos.id);
    } else {
      //this.PersonasObject = { "id": 0, "nombre": "", "estatus": 0, "Personas": {} }
    }
  }

  //this.changeEvento(index, this.state.id, elemento)}
  changeEvento =(evento, transportistaId, columna) => {
    let objetoCE = {
                "transportista":{"id":transportistaId, "nombre":"", "nombreCorto":"", "contactoNombre":"", "telefono1":"", "telefono2":"", "correo":"", "tipo":"" },
                      "persona":{"id":columna.id, "nombre":"", "aPaterno":"", "aMaterno":"", "skyangel":"", "correo":"", "correoEstatus":0 }
                   }
    objetoCE.persona.id=columna.id
    if (FilaAct[columna.id]===0){
    //                               principal   temporal default       id     parametros opcional          state              cerrar modal       si error actualizar
    //              (apiRaiz="",         api="",metodo,id, params,                   storeFunction="",  stateElementos=[], hiddenModal="", arraytoUpdate=[], columna="", url= conf.api_raiz, filtro="" ){
      Crud_Catalogos("transportistasContactos",                                     "", "post", "", objetoCE , "" , "", "", [], "", "", "").then((returnVal) => {
        var tempo = this.state.FHabilitados;
        tempo.push(returnVal);
        this.setState({ FHabilitados:tempo });
        tempo=[];
        FilaAct[columna.id]=1
      });
    }
    else
    {
    //Crud_Catalogos("clientesContactos"+"/borrar", "", "delete", parametrosLlave Compuesta, "" , "" , "FHabilitados")
      Crud_Catalogos("transportistasContactos"+"/borrar", "", "delete", transportistaId+"/"+columna.id, "" , "" , "", "", [], "", "", "")
        .then((returnVal) => {
          var tempo = this.state.FHabilitados;
          for(let i=0; i< tempo.length; i++){
            if (tempo[i].persona.id===columna.id){
              tempo.splice(i, 1);
              this.setState({ FHabilitados:tempo });
            }
          }
          tempo=[];
          FilaAct[columna.id]=0;
        });
    }

  }
  componentDidUpdate = (nexProps) => {
    if (nexProps.elementos.Personas !== this.state.Personas) {
      //alert("es igual")
    } else { /*alert(this.state.personas) */ }
  }
  changeName = (event) => {
    this.perfilesObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  }
  changeEstatus = (event) => {
    event.target.checked ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });
  }

  changePersonas = (etiquetaindex, privId, estatus) => {
    this.perfilEtiquetaObject = {
       "idPerfil": this.state.id,
      "idPersona": privId,
         "nombre": this.props.stor.personas_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[etiquetaindex].nombre
    }
    if (this.PersonasArray[etiquetaindex].estatus === 1) { this.personasObject.personas.splice(etiquetaindex, 1) }
    this.personasArray[etiquetaindex].estatus === 1 ?
      this.props.getfuntion("delete", "", this.personasArray[etiquetaindex].id, this.perfilObject, "transportistasContactos", this.props.stor.perfiles_update)
      :
      this.props.getfuntion("post", this.perfilEtiquetaObject, this.state.id, this.perfilObject, "transportistasContactos", this.props.stor.perfiles_update, "personas");
  }

  cargaPersonas(filtro) {
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("personas", "", "get", "",[],"" , "", "", [], "", "", "").then((regresaValores) => {
    this.setState({ Personas:regresaValores });
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }
  cargaTransportistasContactos(filtro) {
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("transportistasContactos", "", "get", "",[],"" , "", "", [], "", "", "/transportista/"+filtro).then((regresaValores) => {
    //this.setState({ clientesContactos:regresaValores });
    this.setState({ FHabilitados:regresaValores });
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }

  delete()    { this.props.getfuntion("delete", "", this.state.id); }
  crud_put()  { this.props.getfuntion("put", {"id":this.state.id, "nombre":this.state.nombre, "estatus":this.state.estatus }); }
  crud_post() { this.props.getfuntion("post",{"nombre":this.state.nombre, "estatus":this.state.estatus }); }

  checkedPersonas = (indice) => {
    return this.personasArray[indice].estatus
  }
  checarPersonas = (estatus, id) => {
    this.personasArray.push({ estatus:estatus, id:id })
  }
  checarPersonaTem = (valor) => {
    this.checarEtiqueta = valor;
  }
  searching = (event) => {
    this.setState({ search:event.target.value })
  }
  cleanArrayList = () => {
    this.personasArray = [];
  }
  selectedTab = (index) => {
    this.setState({ currentTap:index })
  }
  render() {

    return (
      <div>

        {/*
          *personas
          */
        }

        {this.cleanArrayList()}
        {this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="Personas" isCollapsible={false} isCollapsed={false} options={<Form.Group>
                    <Form.Input className="mb-3" icon="search" placeholder="Buscar Etiqueta..." position="append" onChange={this.searching} />
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.state.Personas.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento["id"]}</Table.Col>
                                  <Table.Col>{elemento["nombre"]+" "+elemento["aPaterno"]+" "+elemento["aMaterno"]}</Table.Col>
                                  <Table.Col>
                                    <Form.Switch type="Checkbox" name="toggle" value="option1" className={"disabled"} checked={ validaActivo(index, this.state.FHabilitados, elemento) } onChange={() => this.changeEvento(index, this.state.id, elemento)} />
                                  </Table.Col>
                                </Table.Row>
                              )
                            }

                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
          : null}
      </div>
    );
  }
}
export default Personas;
