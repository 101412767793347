import React, { useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const BarGraph = ({dataX, dataY}) => {

    return (
      <BarChart
        margin={{ top: 20, bottom: 70, left: 100, right:100 }}
        xAxis={[{ scaleType: 'band', data: dataX }]}
        series={[{ data: dataY }]}
        width={1000}
        height={300}
        sx={{
          "& .MuiChartsAxis-bottom":{
            visibility:"hidden"
          }
        }}
      />
    );
}
export default BarGraph;