import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import {SeguridadForm} from "../../seguridad/";


function SeguridadPage() {
  return (
    <SiteWrapper>
        <Grid.Col sm={12} lg={12}> 
         <SeguridadForm/>
        </Grid.Col>
    </SiteWrapper>
  );
}
export default SeguridadPage;
