import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import TabsLayout  from "../../components/TabsLayout.react";
import {  Unidades, GPSFijos } from "./Formularios";
/**
* Terrestres
 */
//import { Destinos, GpsFijo, TransportistasTerrestres, EtaConfiguracion } from "./Formularios";
import { from } from "rxjs";
class UnidadesForm extends Component {
  constructor(props) {
    super(props);
            this.state = {
                          currentTap:0,
                              accion:this.props.accion, //'nuevo'// modificar,
                               ready:false,
                        };
                          this.tabs =[];
                                  /**
                                   * Tab's
                                   */
                      this.Unidades   = { title:this.props.elementos.nombre, icon:"layout",     panel:<Unidades  accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>, close: false };
                      this.gps        = { title:"GPS",                       icon:"crosshair",  panel:<GPSFijos  accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>, close: false };
  }
  componentDidMount =() => { }

  componentWillMount =() =>{
    if(this.state.accion !== 'nuevo'){
      this.tabs= [  this.Unidades, this.gps ];
    }else{
      this.tabs= [  this.Unidades ];
    }
  }

  selectedTab = (index) =>{ this.setState({ currentTap:index }) }
  render(){
    return (
      <div>
        {/**<Transportista obj={ this.state.movimiento.idTransportista }/> */}
        <TabsLayout tabs={ this.tabs } selectedTab= { this.state.currentTap } selectTab= { this.selectedTab } />
      </div>
    )
  }
}

export default UnidadesForm
