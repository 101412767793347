import React from 'react'
import { Dimmer,Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { NotificationManager} from 'react-notifications';
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class ReactivarMovimientosTerrestre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
         palabra:"",
         dimmerCarga:false,
         tabla:""

    };
  }
  onChangeBuscador=(event)=>{
      this.setState({palabra: event.target.value})
  }
 
  buscarMovimiento=()=>{
    this.setState({dimmerCarga: true});
    Crud_Catalogos("terrestreMovimientos/control_vehicular/"+this.state.palabra,"","get","",[],"","","",[]).then((returnVal)=>{
      this.setState({dimmerCarga: false});
      let filas= returnVal.length?[]: <Table.Row></Table.Row>;
      for( let m of returnVal){
        filas.push(
          <Table.Row>
            <Table.Col>{m.id}</Table.Col>
            <Table.Col>{m.operacionEstatus.nombre }</Table.Col>
            <Table.Col>{m.cartaPorte }</Table.Col>
            <Table.Col>{m.origen}</Table.Col>
            <Table.Col>{m.deRegreso}</Table.Col>
            <Table.Col>{m.custodia}</Table.Col>
            <Table.Col>{this.state.custodiaNombre}</Table.Col>
            <Table.Col>{this.state.checklist}</Table.Col>
            <Table.Col>{this.state.ultimaIncidencia}</Table.Col>
            <Table.Col>
              <Button pill size="sm" fill color="info" onClick={()=>this.reMovimiento(m.id)}>Reactivar</Button>
            </Table.Col>
          </Table.Row>
        );
      }
      this.setState({ tabla: filas});
      }).catch(err =>{
        this.setState({dimmerCarga: false});
         console.log(err);
      });
  }
  
  reMovimiento = (idMovimiento) =>{
   Crud_Catalogos("terrestreMovimientos/reactivacion/"+idMovimiento,"","get","",[],"","","",[]).then((returnVal)=>{
    NotificationManager.info(returnVal, returnVal);
       
    }).catch(err =>{ console.log(err);});
  }
  render() {
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        {
          <div>
              
            <Grid.Row>
              <Grid.Col>
              <Dimmer active={this.state.dimmerCarga} loader>
                <Card title="Reactivacion del movimiento" isCollapsible={false} isCollapsed={false}//isClosable
                  options={<Form.Group>
                      <Grid.Row>
                        <Grid.Col>
                    <span className="float-right">
                      <Form.Input className="mb-3" placeholder="Ingresa CV/Id Movimiento" position="append" onChange={this.onChangeBuscador} />
                    </span>
                    </Grid.Col>
                    <Grid.Col>
                    <Button pill size="sm" outline color="info" onClick={()=>this.buscarMovimiento()}>Buscar</Button>
                    </Grid.Col>
                    </Grid.Row>
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>idMovimiento</Table.ColHeader>
                            <Table.ColHeader>OPERACIÓN ESTATUS</Table.ColHeader>
                            <Table.ColHeader>CARTA PORTE</Table.ColHeader>
                            <Table.ColHeader>ORIGEN</Table.ColHeader>
                            <Table.ColHeader>DE REGRESO</Table.ColHeader>
                            <Table.ColHeader>ID CUSTODIA</Table.ColHeader>
                            <Table.ColHeader>NOMBRE CUSTODIA</Table.ColHeader>
                            <Table.ColHeader>CHECKLIST</Table.ColHeader>
                            <Table.ColHeader>ULTIMA INCIDENCIA</Table.ColHeader>
                            <Table.ColHeader></Table.ColHeader>
                            <Table.ColHeader></Table.ColHeader>
                            <Table.ColHeader></Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                          
                            {
                           this.state.tabla=== "" ?
                          null : this.state.tabla
                            }
                            
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
                  </Dimmer>
              </Grid.Col>

            </Grid.Row>
           
          </div>
          
          }
      </div>
      
    )
  }
}
export default ReactivarMovimientosTerrestre;
