import React, { Component } from "react";
import { Grid, Form, Card, Button, Icon, Table, List } from "tabler-react";
import Select from "react-select";
/**
 * SplitterLayout
 */
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import SiteWrapper from "../../SiteWrapper.react";
import '../navBarItems/MonitoreoPage.react.css';
import * as ACTIONS from '../../store/actions/actions'
import { connect } from 'react-redux';
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";

/**
 * Notificaciones
 */
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadStore from "../../store/LoadStore";
import { concat, from } from "rxjs";
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
let elements
class GerenciadorLogPage extends Component {
  constructor(props) {
    super(props);
      this.state= {
        fecha1:"",
        fecha2:"",
        catalogo:"",
        metodo:"",
        request:"",
        resultado:[],
        id_data:0,
        band:false,
        unidades: [],
        unidad: {}
      }
      this.changeFecha1 = this.changeFecha1.bind(this);
      this.changeFecha2 = this.changeFecha2.bind(this);
      this.changeCatalogo = this.changeCatalogo.bind(this);
      this.changeMetodo=this.changeMetodo.bind(this);
      this.changeRequest=this.changeRequest.bind(this);
  }
  componentDidMount =()=>{
    switch (currentUser[0].perfil.movimientosBy) {
      case 1://Corredores        
        break;
      case 2:
        this.crud("get", "", "", "usuarioOperacionTransportista/usuario/" + currentUser[0].id  , "usuarioTransportista");
        break;
      default:
        break;
    }
  }
  crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
            case "get": {
                if (stateVar === "usuarioTransportista") {
                  if(returnVal){
                    this.crud("get", "", "", "gpsFijos/transportista/" + returnVal[0].idTransportista, "gpsFijos");
                  }
                }
                if (stateVar === "gpsFijos") {
                  if(returnVal){
                    let gpsTemp= [];
                    returnVal.map((g,index)=>{gpsTemp.push({ value: g.imei, label: g.nombre+"("+g.imei+")", data: g }) });
                    this.setState({ unidades: gpsTemp });
                  }
                }
                if( stateVar === "log"){
                  this.setState({ resultado: this.state.resultado.concat(returnVal) });
                }
            } break;
            default:
                break;
        }
  }).catch(err => { console.log(err); });
}
  changeFecha1 (event){
    this.setState({fecha1:event.target.value + " 00:00:00"});
  }
  changeFecha2 (event) {
    this.setState({fecha2: event.target.value + " 00:00:00"});
  }
  changeCatalogo(event){
    this.setState({catalogo: event.target.value});
  }
  changeMetodo(event){
    this.setState({metodo: event.target.value});
  }
  changeRequest(event){
    this.setState({request: event.target.value});
  }
  handleLog=(index)=>{
    this.setState({id_data:index});
  }
  consultar(){
    this.setState({ resultado: [] });
    if(this.state.unidad.value){
      this.crud("get", "", "", "logEnviosGerenciador/obtieneLog/" + this.state.unidad.value  , "log");
    }
  }

  setUnidad =(unidad)=>{
    this.setState({unidad: unidad});
  }
 
  render() {   
    return (
      <>        
      <SiteWrapper>
      <div className="container">
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Seleccione unidad(es)">
              <Select value={this.state.unidad}  options={this.state.unidades} onChange={this.setUnidad} isMulti={false} />
            </Form.Group>
          </Grid.Col>              
          <div className="col col-2 col-md-2">
            <Form.Group label="-">
              <Button pill  outline color="primary" value="consultar" onClick={ ()=> this.consultar()}>Consultar</Button>
            </Form.Group>
          </div>
        </Grid.Row>
      </div>
      <div> 
        <Grid.Col sm={12} lg={12}>
          <div style={{ height: "78vh", "font-size": "smaller"}}>
            <SplitterLayout vertical= {true} percentage= {true}  secondaryInitialSize= {2}>
              <div>
                <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {30}>
                  <div> 
                  <Table cards={false} striped={false} responsive={true} className="table-vcenter"
                    headerItems={[
                        {content:"idEvento"},
                        {content:"imei"},
                        {content:"latitud"},
                        {content:"longitud"},
                        {content:"direccion"},
                        {content:"velocidad"},
                        {content:"respuesta"},
                        {content:"mensaje"},
                        {content:"neconomico"},
                        {content:"ubicacion"},
                        {content:"remoteAddress"},
                        {content:"fechahora"},
                        {content:"fechahoraDT"},
                        {content:"fechahoraReq"},
                        {content:"fechahoraRes"},
                      ]}>
                    <Table.Body>
                      {this.state.resultado.map((t, index) =>
                        <Table.Row align="center" key={index}>
                          <Table.Col> { t.idEvento } </Table.Col>
                          <Table.Col> { t.imei } </Table.Col>
                          <Table.Col> { t.latitud } </Table.Col>
                          <Table.Col> { t.longitud } </Table.Col>
                          <Table.Col> { t.direccion } </Table.Col>
                          <Table.Col> { t.velocidad } </Table.Col>
                          <Table.Col> { t.respuesta } </Table.Col>
                          <Table.Col> { t.mensaje } </Table.Col>
                          <Table.Col> { t.neconomico } </Table.Col>
                          <Table.Col> { t.ubicacion } </Table.Col>
                          <Table.Col> { t.remoteAddress } </Table.Col>
                          <Table.Col> { t.fechahora } </Table.Col>
                          <Table.Col> { t.fechahoraDT } </Table.Col>
                          <Table.Col> { t.fechahoraReq } </Table.Col>
                          <Table.Col> { t.fechahoraRes } </Table.Col>
                        </Table.Row>)}
                    </Table.Body>
                  </Table>
                  </div>
                  <div>
                    <List.Group>
                      <List.GroupItem action icon="truck"> Nombre:  <strong> { this.state.unidad.value? this.state.unidad.data.nombre: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="hash"> Imei:    <strong> { this.state.unidad.value? this.state.unidad.data.imei: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="hash"> Sim:     <strong> { this.state.unidad.value? this.state.unidad.data.sim: "" } </strong> </List.GroupItem>
                      <List.GroupItem action icon="award"> Marca:   <strong> { this.state.unidad.value? this.state.unidad.data.marca: "" } </strong> </List.GroupItem>
                    </List.Group>
                  </div>
                </SplitterLayout>
              </div>
              <div>

                {/*
                  <p>
                    { JSON.stringify(
                                      {
                                        "idUser": 0,
                                        "idEvento": "",
                                        "imei": "868789020812839",
                                        "latitud": "19.4313",
                                        "longitud": "-99.1693",
                                        "direccion": "25.12",
                                        "velocidad": "100",
                                        "respuesta": "$FRRET,868789020812839,_SendMessage*44\r\n",
                                        "mensaje": "$FRCMD,868789020812839,_SendMessage,,1925.8780,N,9910.1580,W,150,53.99568034557235,25.12,011121,175800.00,1",
                                        "neconomico": "UID123456",
                                        "ubicacion": "DHL Express",
                                        "remoteAddress": "187.190.144.152:10770",
                                        "fechahora": "2021-11-01 11:58:00 -06:00",
                                        "fechahoraDT": "2021-11-01 11:58:00 -0600 CST",
                                        "fechahoraReq": "2021-11-11 11:44:03.274951422 -0600 CST m=+52921.691052912",
                                        "fechahoraRes": "2021-11-11 11:44:03.571031311 -0600 CST m=+52921.987132894",
                                        "idLog": 1
                                      })
                    }
                  </p>
                    { JSON.stringify(
                                      {
                                        "idUser": 0,
                                        "idEvento": "",
                                        "imei": "868789020812839",
                                        "latitud": "19.4313",
                                        "longitud": "-99.1693",
                                        "direccion": "25.12",
                                        "velocidad": "100",
                                        "respuesta": "$FRRET,868789020812839,_SendMessage*44\r\n",
                                        "mensaje": "$FRCMD,868789020812839,_SendMessage,,1925.8780,N,9910.1580,W,150,53.99568034557235,25.12,011121,175800.00,1",
                                        "neconomico": "UID123456",
                                        "ubicacion": "DHL Express",
                                        "remoteAddress": "187.190.144.152:10770",
                                        "fechahora": "2021-11-01 11:58:00 -06:00",
                                        "fechahoraDT": "2021-11-01 11:58:00 -0600 CST",
                                        "fechahoraReq": "2021-11-11 11:44:03.274951422 -0600 CST m=+52921.691052912",
                                        "fechahoraRes": "2021-11-11 11:44:03.571031311 -0600 CST m=+52921.987132894",
                                        "idLog": 1
                                      })
                    }
                  <p>

                  </p>
                */}
              </div>
                </SplitterLayout>
          </div>
        </Grid.Col>                 
      </div> 
      </SiteWrapper>
      </> 
    );
  }
}


export default (GerenciadorLogPage);
