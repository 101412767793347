import React, { useState, useEffect } from 'react';
import SiteWrapper from "../../SiteWrapper.react";
import { Grid, Table } from 'tabler-react';
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';

const currentUser= JSON.parse(sessionStorage.getItem("currentUser"));

const MovimientosActivosXtransportistaOTDPage = () => {  
  const [ otds, setOtds ]= useState([]);  
  const [ movTotales, setMovTotales ] = useState(0);
  const [ paradasTotales, setParadasTotales ] = useState(0);
  const [ paradasLineasSuspendidas, setParadasLineasSuspendidas ] = useState(0);
  const [ paradasPlacasBaja, setParadasPlacasBaja ] = useState(0);
  const [ paradasLogisticas, setParadasLogisticas ] = useState(0);
  const [ sinEta, setSinEta ] = useState(0);
  const [ ontime, setOntime ] = useState(0);
  const [ delay, setDelay ] = useState(0);

  useEffect(() => {
    //document.title = `${plataforma.label}`;
    crud("get", "", "", "terrestreMovimientos/otd_mov_act_x_transportista", "mov_activo_trans_otd");
  }, []);
 
   
  return (    
    <SiteWrapper>
      <div className="container mt-3">
        <Grid.Row>
          <Grid.Col>
            <h2> Display OTD </h2>
            <h3> OTD Lineas Transportistas </h3>
          </Grid.Col>
        </Grid.Row>                                
        <Grid.Row>
            <div className="mt-5" >
            Solo se muestran los transportista con movimientos activos
            </div>
            <Table border={1} cards={true} striped={false} responsive={true} className="table-vcenter text-dark border border-primary" style={{ "background-color": "#F3F5E9"}}
              headerItems={[
                {content:"Linea Transportista"},
                {content:"Movimientos"},
                {content:"Paradas Totales"},
                {content:"Paradas Lineas Suspendidas"},
                {content:"Paradas Placas dadas de Baja"},
                {content:"Paradas Logisticas"},
                {content:"Sin ETA"},
                {content:"A Tiempo"},
                {content:"Tarde"},
                {content:"OTD"}
              ]}>
              <Table.Header ></Table.Header>
              <Table.Body>
              { otds.sort().map((w, index) =>
                <Table.Row align="center" style={ { "background-color": "rgba(0, 0, 255, .10)" }}>
                  <Table.Col> { w.lineaTrans } </Table.Col>
                  <Table.Col><font size="5"> { w.movTotal } </font></Table.Col>
                  <Table.Col><div style={ { "color": "#F39C12"} }><font size="5">{ w.entregasTotal }</font></div></Table.Col>
                  <Table.Col className={w.lineasSuspendidad ? "text-secondary" : "text-dart"}><font size="5"> { w.lineasSuspendidad } </font></Table.Col>                                    
                  <Table.Col><div  style={ { "color": "#F95143"} }> <font size="5"> { w.placasBaja } </font></div></Table.Col>
                  <Table.Col className={w.paradasLogisticas ? "text-primary" : "text-dart"}><font size="5"> { w.paradasLogisticas } </font></Table.Col>
                  <Table.Col><div style={ { "color": "#212F3D"} }><font size="5"> { w.sinEta } </font></div></Table.Col>
                  <Table.Col className={w.ontime ? "text-success" : "text-dart"} ><font size="5"> { w.ontime } </font> </Table.Col>
                  <Table.Col className={w.delay ? "text-danger" : "text-dart"} > <font size="5"> { w.delay } </font></Table.Col>                                   
                  <Table.Col className={(Math.floor((100/(w.delay + w.ontime))*w.ontime)) > 97 ? "p-3 mb-2 bg-success text-white" : ((Math.floor((100/(w.delay + w.ontime))*w.ontime)) > 85 && (Math.floor((100/(w.delay + w.ontime))*w.ontime)) <= 96) ? "p-3 mb-2 bg-warning text-dark" : "p-3 mb-2 bg-danger text-white"}><font size="3"> { w.delay || w.ontime ? Math.floor((100/(w.delay + w.ontime))*w.ontime) : 0 } %</font></Table.Col>                                    
                </Table.Row>
              )}
              </Table.Body>
            </Table>
        </Grid.Row>   
        <Grid.Row>
          <Grid.Col>
            <h2> </h2>
            <h2> </h2>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>            
          <Table border={1} cards={true} striped={false} responsive={true} className="table-vcenter border border-success"
            headerItems={[
              {content:"Linea Transportista"},
              {content:"Movimientos"},
              {content:"Paradas Totales"},
              {content:"Paradas Lineas Suspendidas"},
              {content:"Paradas Placas dadas de Baja"},
              {content:"Paradas Logisticas"},
              {content:"Sin ETA"},
              {content:"A Tiempo"},
              {content:"Tarde"},
              {content:"OTD"}
            ]}>
            <Table.Header></Table.Header>
            <Table.Body style={ { "background-color": "rgba(0, 128, 0, 0.10)" }}>                            
              <Table.Row align="center">
                <Table.Col> México Totales </Table.Col>
                <Table.Col><font size="5"> { movTotales } </font></Table.Col>
                <Table.Col><div style={ { "color": "#F39C12"} }><font size="5"> { paradasTotales } </font></div></Table.Col>
                <Table.Col className={paradasLineasSuspendidas ? "text-secondary" : "text-dart"}><font size="5"> { paradasLineasSuspendidas } </font></Table.Col>                                    
                <Table.Col><div  style={ { "color": "#F95143"} }><font size="5"> { paradasPlacasBaja } </font></div></Table.Col>
                <Table.Col className={paradasLogisticas ? "text-primary" : "text-dart"}><font size="5">  { paradasLogisticas } </font></Table.Col>
                <Table.Col><div style={ { "color": "#212F3D"} }><font size="5"> { sinEta } </font></div></Table.Col>
                <Table.Col className={ontime ? "text-success" : "text-dart"} ><font size="5"> { ontime } </font></Table.Col>
                <Table.Col className={delay ? "text-danger" : "text-dart"} > <font size="5"> { delay } </font></Table.Col>                                   
                <Table.Col className={(Math.floor((100/(delay + ontime))*ontime)) > 97 ? "p-3 mb-2 bg-success text-white" : ((Math.floor((100/(delay + ontime))*ontime)) > 85 && (Math.floor((100/(delay + ontime))*ontime)) <= 96) ? "p-3 mb-2 bg-warning text-dark" : "p-3 mb-2 bg-danger text-white"}> {  } </Table.Col>
              </Table.Row>
              <Table.Row align="center">
                <Table.Col> % </Table.Col>
                <Table.Col> { Math.floor((movTotales/movTotales)*100) } %</Table.Col>
                <Table.Col> { Math.floor((paradasTotales/paradasTotales)*100) } % </Table.Col>
                <Table.Col> { Math.floor((paradasLineasSuspendidas/paradasTotales)*100) } %</Table.Col>                                    
                <Table.Col> { Math.floor((paradasPlacasBaja/paradasTotales)*100) } %</Table.Col>
                <Table.Col> { Math.floor((paradasLogisticas/paradasTotales)*100) } %</Table.Col>
                <Table.Col> {  } </Table.Col>
                <Table.Col> { Math.floor((ontime*100)/(ontime + delay)) } %</Table.Col>
                <Table.Col> { Math.floor((delay*100)/(ontime + delay)) } %</Table.Col>                                   
                <Table.Col className={(Math.floor((100/(delay + ontime))*ontime)) > 97 ? "p-3 mb-2 bg-success text-white" : ((Math.floor((100/(delay + ontime))*ontime)) > 85 && (Math.floor((100/(delay + ontime))*ontime)) <= 96) ? "p-3 mb-2 bg-warning text-dark" : "p-3 mb-2 bg-danger text-white"} ><font size="3"> { delay || ontime ? Math.floor((100/(delay + ontime))*ontime) : 0 } %</font></Table.Col>
              </Table.Row>
              <Table.Row align="center">
                <Table.Col> % Logístico</Table.Col>
                <Table.Col> { } </Table.Col>
                <Table.Col> { } </Table.Col>
                <Table.Col> { } </Table.Col>                                    
                <Table.Col> { } </Table.Col>
                <Table.Col> { Math.floor((paradasLogisticas/paradasLogisticas)*100) } %</Table.Col>
                <Table.Col> { Math.floor((sinEta/ paradasLogisticas)*100) } %</Table.Col>
                <Table.Col> { } </Table.Col>
                <Table.Col> { } </Table.Col>                                   
                <Table.Col className={(Math.floor((100/(delay + ontime))*ontime)) > 97 ? "p-3 mb-2 bg-success text-white" : ((Math.floor((100/(delay + ontime))*ontime)) > 85 && (Math.floor((100/(delay + ontime))*ontime)) <= 96) ? "p-3 mb-2 bg-warning text-dark" : "p-3 mb-2 bg-danger text-white"}> {  } </Table.Col>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <h2> </h2>
            <h2> </h2>
          </Grid.Col>
        </Grid.Row>        
      </div>
      <div className="container-fluid row justify-content-md-center">
      <Grid.Row>            
          <Table border={1} cards={false} striped={false} responsive={true} className="table-vcenter border border-white">            
            <Table.Body style={ { "background-color": "rgba(255, 255, 255, 0.10)" }}>                            
              <Table.Row align="left">
                <Table.Col className="p-3 mb-2 bg-success text-white max-width">    </Table.Col>
                <Table.Col> OTD entre 100% y 93% </Table.Col>
              </Table.Row>
              <Table.Row align="left">
                <Table.Col className="p-3 mb-2 bg-warning text-dark">  </Table.Col>
                <Table.Col> OTD entre 92% y 85% </Table.Col>
              </Table.Row>
              <Table.Row align="center">
                <Table.Col className="p-3 mb-2 bg-danger text-white">  </Table.Col>
                <Table.Col className="container-fluid"> OTD igual o menor 84% </Table.Col>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Row>
      </div>
    </SiteWrapper>    
  );

  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    //console.log(returnVal); 
                    if (stateVar === "mov_activo_trans_otd" && returnVal) {
                     let transOTD = {};
                     let movAnterior = 0;
                     let movTotales = 0;
                     let entregasTotales = 0;
                     let entregasLineasSuspendidas = 0;
                     let entregasPlacasBaja = 0;
                     let entregasLogisticas = 0;
                     let sinEta = 0;
                     let delay = 0;
                     let ontime = 0;
                     for(let i of returnVal){
                      if(!transOTD[i.nombre]){
                        transOTD[i.nombre] = {};
                        transOTD[i.nombre] = {lineaTrans : i.nombre,
                                              movTotal : 1, 
                                              entregasTotal : i.entregas_x_movimiento,
                                              lineasSuspendidad : i.id_corredor === 9 ? i.entregas_x_movimiento : 0,
                                              placasBaja : i.id_corredor === 10 ? i.entregas_x_movimiento : 0,
                                              paradasLogisticas : ![9, 10].includes(i.id_corredor) ? i.entregas_x_movimiento : 0,
                                              sinEta : i.ontime_delay === "Sin ETA" ? 1 : 0,
                                              delay : i.ontime_delay === "delay" ? 1 : 0,
                                              ontime : i.ontime_delay === "ontime" ? 1 : 0};
                      } else {
                        transOTD[i.nombre] = {lineaTrans : i.nombre,
                                              movTotal : transOTD[i.nombre].movTotal++,
                                              entregasTotal : transOTD[i.nombre].entregasTotal + i.entregas_x_movimiento,
                                              lineasSuspendidad : i.id_corredor === 9 ? transOTD[i.nombre].lineasSuspendidad + i.entregas_x_movimiento : transOTD[i.nombre].lineasSuspendidad,
                                              placasBaja : i.id_corredor === 10 ? transOTD[i.nombre].placasBaja + i.entregas_x_movimiento : transOTD[i.nombre].placasBaja,
                                              paradasLogisticas : ![9, 10].includes(i.id_corredor) ? transOTD[i.nombre].paradasLogisticas + i.entregas_x_movimiento : transOTD[i.nombre].paradasLogisticas,
                                              sinEta : i.ontime_delay === "Sin ETA" ? transOTD[i.nombre].sinEta++ : transOTD[i.nombre].sinEta,
                                              delay : i.ontime_delay === "delay" ? transOTD[i.nombre].delay++ : transOTD[i.nombre].delay,
                                              ontime : i.ontime_delay === "ontime" ? transOTD[i.nombre].ontime++ : transOTD[i.nombre].ontime };
                      }                      
                      if(i.id_movimiento){
                        let movActual = i.id_movimiento;
                        if(movAnterior != movActual){
                          movTotales++;
                          movAnterior = movActual;
                        }
                      }                      
                      entregasTotales = entregasTotales + i.entregas_x_movimiento;
                      if(i.id_corredor === 9){
                        entregasLineasSuspendidas = entregasLineasSuspendidas + i.entregas_x_movimiento;
                      }
                      if(i.id_corredor === 10){
                        entregasPlacasBaja = entregasPlacasBaja + i.entregas_x_movimiento;
                      }
                      if(i.id_corredor !== 9 || i.id_corredor !== 10){
                        entregasLogisticas = entregasLogisticas + i.entregas_x_movimiento;
                      }
                      if(i.ontime_delay === "Sin ETA"){
                        sinEta++;
                      }
                      if(i.ontime_delay === "delay"){
                        delay++;
                      }
                      if(i.ontime_delay === "ontime"){
                        ontime++;
                      }
                     }
                     //console.log(transOTD);
                     //console.log(transOTDTotal);
                     let otd = [];
                     Object.keys(transOTD).forEach(key => {otd.push(transOTD[key])} );
                     setOtds(otd);
                     setMovTotales(movTotales);
                     setParadasTotales(entregasTotales);
                     setParadasLineasSuspendidas(entregasLineasSuspendidas);
                     setParadasPlacasBaja(entregasPlacasBaja);
                     setParadasLogisticas(entregasLogisticas);
                     setSinEta(sinEta);
                     setOntime(ontime);
                     setDelay(delay);
                    }
                } break;
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }
}


export default MovimientosActivosXtransportistaOTDPage;
