import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import './excel.css';
import './modal.css'
import $ from 'jquery';
import { Crud_Catalogos, Crud_error } from '../Crud_Catalogos';
import { Dimmer } from "tabler-react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Tab, Tabs, TabList, TabPanel } from './tabs-generic/index';
import TablaCatalogo from './tabla-generic/index';

var     id	=0;
var terrores=0;
let sinError=0;
var dia			=0;
var mes			=0;
var anio		=0;
var hora 		=0;
var minuto	=0;
var segundo =0;
var personasvar="";
var clientevar="";
var clientesContactos="";
var fechas	=0;
var horas		=0;
var arrayErr=[];
let Errores	=[];
let FilaErr	=[];
let Cuadros =[];
let TabTemp =[];
let TabPanelTemp=[];

const fdatetime	= 'yyyy-mm-dd HH:mm:ss';
const fdate			= 'yyyy-mm-dd';
const fhora			= 'HH:mm:ss';
const nobis 		= [31,28,31,30,31,30,31,31,30,31,30,31]
const sbiss 		= [31,29,31,30,31,30,31,31,30,31,30,31]

function myModalMT(Muestra) {
  var x = document.getElementById("xlsModalMT");
  x.style.display = Muestra;
}
function bisiesto(year){	return year % 4 == 0 && year % 100 != 0 || year % 400 == 0; }
function pad(number) {  if (number < 10) { return '0' + number; }  return number; }
function myFormato(date) {
function pad(number) {
    if (number < 10) { return '0' + number; }
    return number;
  }
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}
function serieMes(numero, año){
  var cuenta 		=0;	var siguiente	=0;	var index			=0;  cuenta=numero;
  do {
		if(bisiesto(año)){
			siguiente=sbiss[index+1];
			cuenta=cuenta-sbiss[index];
			dia=cuenta;
		}else{
			siguiente=nobis[index+1];
			cuenta=cuenta-nobis[index];
			dia=cuenta;
		}
		index=index+1;
  }	while (cuenta>siguiente);
	mes=index+1;
	dia=cuenta-1;
}
function serieAnio(valor){
  var año   =1900;  var sumadias=0;  var residuo =0;
  do {
    if(bisiesto(año)){
      año = año +1;
      sumadias = sumadias + 366;
    }else{
      año = año +1;
      sumadias = sumadias + 365;
    }
  }while ((sumadias+365)<valor)
  residuo=valor-sumadias;
	anio=año;
  serieMes(residuo, año);
}
function serieHoras(valor){
	var temporal=0;
	var residuo=0;
	temporal = valor;

	temporal = temporal*24;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	hora 			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	minuto			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	segundo			= parseInt(temporal, 10);
}
var user = JSON.parse(sessionStorage.getItem("usuario"));
class ImportarMT extends Component
{
  constructor(props) {
    super(props);
    this.state = {
                catalogo:this.props.catalogo                |"",
                 cliente:this.props.cliente                 |"",
//Tabla Plataformas
          plataformaTipo:this.props.plataformaTipo          |[],

//Tabla Unidades
              plataforma:this.props.plataforma              |[],
          transportistas:this.props.transportistas          |[],
              unidadTipo:this.props.unidadTipo              |[],
                 estatus:this.props.estatus                 |[],
             unidadMarca:this.props.unidadMarca             |[],

                idUnidad:this.props.idUnidad                |[],

//Tabla TerrestreMovimientoUnidad
            idMovimiento:this.props.idMovimiento            |[],
                  unidad:this.props.unidad                  |[],

//Tabla TerrestreMovimientos
                corredor:this.props.corredor                |[],
              plataforma:this.props.plataforma              |[],
           transportista:this.props.transportista           |[],
           operacionTipo:this.props.operacionTipo           |[],
        operacionEstatus:this.props.operacionEstatus        |[],



//Tabla Movimientos
               idPersona:this.props.idPersona               |[],
               idCliente:this.props.idCliente               |[],



//Variables
                  accion:this.props.accion,
                validado:this.props.validado                |false,

//Variables de Datos
									  data:this.props.data										|[],
				    dataDestinos:this.props.dataDestinos			      |[],
				 dataPlataformas:this.props.dataPlataformas			    |[],
			dataTransportistas:this.props.dataTransportistas			|[],
			    dataUnidadTipo:this.props.dataUnidadTipo          |[],
dataUnidadEstatusOperacion:this.props.dataUnidadEstatusOperacion|[],
			      dataUnidades:this.props.dataUnidades            |[],
dataTerrestreMovimientoUnidad:this.props.dataTerrestreMovimientoUnidad|[],
dataTerrestreMovimientos:this.props.dataTerrestreMovimientos|[],

						 dataCliente:this.props.dataCliente					    |[],
						 dataPersona:this.props.dataPersona 						|[],
       nombreTemporalTab:this.props.nombreTemporalTab       |"Elija Pestaña",
    };
    this.cita=0;
    this.cadenadeCarga="";
    this.usuariosLogObject = {
      "idBitacoraMonitoreo":0,
      "idUsuario":0,
      "idMovimiento":0,
      "accionFecha":"",
      "descripcion":"",
      "plataformaTipo":2
    };
  }
  componentDidMount(){
		myModalMT("none");
  }
  componentWillMount(){
    { this.cargaMovimientoTipo() };
    { this.cargaOperacionTipo() };
    { this.cargaOperacionEstatus() };
    { this.cargaCorredor() };
    { this.cargaPlataformas() }
    { this.cargaTransportistas() }
    { this.cargaUnidadTipo() }
    { this.cargaEstatus() }
    { this.cargaUnidades() }
    { this.cargaUnidadesMarcas() }
    switch (this.props.catalogo) {
      case "clientesContactos":
        break;
      default:
        //console.log("Sin Catalogos Dependientes Definidos en: " + this.props.catalogo );
        break;
    }
  }
  //Inicio Tabla Movimientos
  cargaMovimientoTipo() {
    Crud_Catalogos("movimientosTipo", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ movimientoTipo:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" movimientosTipo "+" verifique el Nombre del Catalogo");}  });
  }
  cargaOperacionTipo() {
    Crud_Catalogos("operacionesTipo", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ operacionTipo:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" operacionesTipo "+" verifique el Nombre del Catalogo");}  });
  }
  cargaOperacionEstatus() {
    Crud_Catalogos("operacionesEstatus", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ operacionEstatus:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" operacionesEstatus "+" verifique el Nombre del Catalogo");}  });
  }
  cargaCorredor() {
    Crud_Catalogos("corredores", "", "get", "",[],"", "", "", [], "", "", "/tipoNombre/Terrestre").then((returnVal) => {
    this.setState({ corredor:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" corredores "+" verifique el Nombre del Catalogo");}  });
  }
  //Fin Tabla Movimientos
  cargaUnidadesMarcas() {
    Crud_Catalogos("unidadesMarcas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ unidadesMarcas:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo: unidadesMarcas verifique el Nombre del Catalogo");}  });
  }
  cargaPlataformas() {
    Crud_Catalogos("plataformas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ plataformas:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo: plataformas verifique el Nombre del Catalogo");}  });
  }
  cargaTransportistas() {
    Crud_Catalogos("transportistas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ transportistas:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo: transportistas verifique el Nombre del Catalogo");}  });
  }
  cargaUnidadTipo() {
    Crud_Catalogos("unidadTipo", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ unidadTipo:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo: unidadTipo verifique el Nombre del Catalogo");}  });
  }
  cargaEstatus() {
    Crud_Catalogos("unidadEstatusOperacion", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ unidadEstatusOperacion:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo: unidadEstatusOperacion verifique el Nombre del Catalogo");}  });
  }
  cargaUnidades() {
    Crud_Catalogos("unidades", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ unidades:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo: unidades verifique el Nombre del Catalogo");}  });
  }
validacionTerrestreMovimientos(ObjValidar, j){
    Crud_Catalogos("validacionDatos/terrestre", "", "post", "", ObjValidar,"", "", "", [], "", "", "").then((returnVal) => {
      //Delay en JavaScript
      //await this.timeout(1000);
      this.state.dataTerrestreMovimientos[j].plataforma    = returnVal.plataforma;
      this.state.dataTerrestreMovimientos[j].transportista = returnVal.transportista;
      this.state.dataTerrestreMovimientos[j].operador      = returnVal.operador;
/*
      let tempplataforma=this.state.dataTerrestreMovimientos[j].plataforma;
      let temptransportista=this.state.dataTerrestreMovimientos[j].transportista;
      let tempoperador=this.state.dataTerrestreMovimientos[j].operador;

      if ( tempplataforma.nombre === "" || tempplataforma.nombre === null || tempplataforma.nombre === undefined ){
          terrores=terrores+1;
          FilaErr[j]="Error en la Fila " + (j);
          arrayErr[terrores]="La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j);
          //NotificationManager.warning("La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j) );
          Errores.push( <div>{"La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j) }</div> );
          try{
            var filaenTerrestreMovimientos = document.getElementById("terrestreMovimientosplataforma"+j);
            console.log("413 terrestreMovimientosplataforma" + j);
            filaenTerrestreMovimientos.style.backgroundColor = "orange";
          }catch(e){
            console.log("417 terrestreMovimientosplataforma" + j + " No Aplico color");
          }
      }
      if ( temptransportista.id === null || temptransportista.id === 0)  {
        terrores=terrores+1;
        FilaErr[j]="Error en la Fila " + (j);
        arrayErr[terrores]="El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
        //NotificationManager.warning("El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
        Errores.push( <div>{"El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        try{
          let sumat = j+1;
          var filaenterrestreMovimientostransportista = document.getElementById("terrestreMovimientostransportista"+sumat);
          console.log("470 terrestreMovimientostransportista" + sumat);
          filaenterrestreMovimientostransportista.style.backgroundColor = "orange";
        }catch(e){
          console.log("473 terrestreMovimientostransportista" + j + "+1 No Aplico color");
        }
      }
      if ( temptransportista.nombre === "" || temptransportista.nombre === null ) {
        terrores=terrores+1;
        FilaErr[j]="Error en la Fila " + (j);
        arrayErr[terrores]="El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
        //NotificationManager.warning("El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
        Errores.push( <div>{"El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        try{
          var filaenTerrestreMovimientos = document.getElementById("terrestreMovimientostransportista"+j);
          console.log("428 terrestreMovimientostransportista" + j);
          filaenTerrestreMovimientos.style.backgroundColor = "orange";
        }catch(e){
          console.log("431 terrestreMovimientostransportista" + j + " No Aplico color");
        }
      }
      if ( tempoperador.id === null || tempoperador.id === 0)  {
        terrores=terrores+1;
        FilaErr[j]="Error en la Fila " + (j);
        arrayErr[terrores]="El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
        //NotificationManager.warning("El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
        Errores.push( <div>{"El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        try{
          var filaenTerrestreMovimientos = document.getElementById("terrestreMovimientosoperador"+j);
          console.log("428 terrestreMovimientosoperador" + j);
          filaenTerrestreMovimientos.style.backgroundColor = "orange";
        }catch(e){
          console.log("431 terrestreMovimientosoperador" + j + " No Aplico color");
        }
      }
      if ( tempoperador.nombre === "" || tempoperador.nombre === null ) {
        terrores=terrores+1;
        FilaErr[j]="Error en la Fila " + (j);
        arrayErr[terrores]="El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
        //NotificationManager.warning("El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
        Errores.push( <div>{"El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        try{
          var filaenTerrestreMovimientos = document.getElementById("terrestreMovimientosoperador"+j);
          console.log("428 terrestreMovimientosoperador" + j);
          filaenTerrestreMovimientos.style.backgroundColor = "orange";
        }catch(e){
          console.log("431 terrestreMovimientosoperador" + j + " No Aplico color");
        }
      }
*/
    }).catch(err =>{
      //if( err.response ){
      console.log("528 Error de validacionDatos/terrestre")
      console.log(err);
      }
    );
  }

//Fin Tabla Movimientos
valObjeto(pestana, ObjValidar, j){
  var k=0;
  let Campo="";
  switch (pestana) {
    case "idUnidad":
      k=0;
      Campo=ObjValidar;
      this.state.unidades.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.unidades[k].id===ObjValidar){
            Campo = this.state.unidades[k].id;
            this.state.dataUnidadEnsamble[j].idUnidad = Campo;
          }
        }
        k=k+1;
      });
      break;
    case "unidad":
      k=0;
      Campo=ObjValidar;
      this.state.unidades.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.unidades[k].id===ObjValidar.id){
            Campo = this.state.unidades[k];
          }
        }else{
          if (this.state.unidades[k].nombre===ObjValidar.nombre){
            Campo = this.state.unidades[k];
          }
        }
        k=k+1;
      });
      this.state.dataTerrestreMovimientoUnidad[j].unidad = Campo;
      break;
    case "plataforma":
      k=0;
      this.state.plataformas.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.plataformas[k].id===ObjValidar.id){
            Campo = this.state.plataformas[k];
            this.state.dataUnidades[j].plataforma = Campo;
          }
        }else{
          if (this.state.plataformas[k].nombre===ObjValidar.nombre){
            Campo = this.state.plataformas[k];
            this.state.dataUnidades[j].plataforma = Campo;
          }
        }
        k=k+1;
      });
      break;
    case "transportista":
      k=0;
      this.state.transportistas.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.transportistas[k].id===ObjValidar.id){
            Campo = this.state.transportistas[k];
            this.state.dataUnidades[j].transportista = Campo;
          }
        }else{
          if (this.state.transportistas[k].nombre===ObjValidar.nombre){
            Campo = this.state.transportistas[k];
            this.state.dataUnidades[j].transportista = Campo;
          }
        }
        k=k+1;
      });
      break;
    case "unidadTipo":
      k=0;
      this.state.unidadTipo.map(ids => {
        if(ObjValidar.id!==""){
          if (ids.id===ObjValidar.id){
            Campo = this.state.unidadTipo[k];
            //ObjValidar = Campo;
            this.state.dataUnidades[j].unidadTipo=Campo;
          }
        }else{
          if (ids.nombre===ObjValidar.nombre){
            Campo = this.state.unidadTipo[k];
            //ObjValidar = Campo;
            this.state.dataUnidades[j].unidadTipo=Campo;
          }
        }
        k=k+1;
      });
      break;
    case "estatus":
      k=0;
      this.state.unidadEstatusOperacion.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.unidadEstatusOperacion[k].id===ObjValidar.id){
            Campo = this.state.unidadEstatusOperacion[k];
            this.state.dataUnidades[j].estatus = Campo;
          }
        }else{
          if (this.state.unidadEstatusOperacion[k].nombre===ObjValidar.nombre){
            Campo = this.state.unidadEstatusOperacion[k];
            this.state.dataUnidades[j].estatus = Campo;
          }
        }
        k=k+1;
      });
      break;
    case "operacionTipo":
      k=0;
      this.state.operacionTipo.map(ids => {
        if (this.state.operacionTipo[k].id===ObjValidar.id){
          Campo = this.state.operacionTipo[k];
          this.state.dataTerrestreMovimientos[j].operacionTipo = Campo;
        }
        k=k+1; });
      break;
    case "otra":
      Crud_Catalogos("validacionDatos/plataformas",   "", "post", "", ObjValidar,"", "", "", [], "", "", "").then((returnVal) => {
        this.state.data[j].plataforma    = returnVal.plataforma;
        this.state.data[j].transportista = returnVal.transportista;
        this.state.data[j].operador      = returnVal.operador;

        let tempplataforma=this.state.data[j].plataforma;
        let temptransportista=this.state.data[j].transportista;
        let tempoperador=this.state.data[j].operador;

        if ( tempplataforma.nombre === "" || tempplataforma.nombre === null || tempplataforma.nombre === undefined ){
            terrores=terrores+1;
            FilaErr[j]="Error en la Fila " + (j);
            arrayErr[terrores]="La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j);
            NotificationManager.warning("La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j) );
            Errores.push( <div>{"La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j) }</div> );
        }
        if ( temptransportista.id === null || temptransportista.id === 0)  {
          terrores=terrores+1;
          FilaErr[j]="Error en la Fila " + (j);
          arrayErr[terrores]="El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
          NotificationManager.warning("El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
          Errores.push( <div>{"El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        }
        if ( temptransportista.nombre === "" || temptransportista.nombre === null ) {
          terrores=terrores+1;
          FilaErr[j]="Error en la Fila " + (j);
          arrayErr[terrores]="El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
          NotificationManager.warning("El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
          Errores.push( <div>{"El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        }
        if ( tempoperador.id === null || tempoperador.id === 0)  {
          terrores=terrores+1;
          FilaErr[j]="Error en la Fila " + (j);
          arrayErr[terrores]="El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
          NotificationManager.warning("El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
          Errores.push( <div>{"El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        }
        if ( tempoperador.nombre === "" || tempoperador.nombre === null ) {
          terrores=terrores+1;
          FilaErr[j]="Error en la Fila " + (j);
          arrayErr[terrores]="El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
          NotificationManager.warning("El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
          Errores.push( <div>{"El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        }
      }).catch(err =>{
        //if( err.response ){
        console.log("567 Error de validacionDatos")
        console.log(err);
      } );
      break;
    case "clientesContactos":
      //https://one.skynavieros.com:8080/skyone/validacionDatos/clientesContactos
      //{ "persona":{"id":0,"nombre":"Gerardo","aPaterno":"Altamirano","aMaterno":"Perez","correo":"gerardo.villarreal@skyangel.com.mx"}, "cliente":{"id":0,"nombre":"49 CALLE 18-40, ZONA 12 CIUDAD DE GUATEMALA"} }
      //Crud_Catalogos("validacionDatos/clientesContactos",   "", "post", "", objeto,"", "", "", [], "", "", "").then((returnVal) => {
      Crud_Catalogos("validacionDatos/clientesContactos",   "", "post", "", ObjValidar,"", "", "", [], "", "", "").then( returnVal => {
        console.log("339 returnVal");
        console.log(returnVal);
        //this.state.data[j] =null;
        this.state.data[j].id = 0;
        this.state.data[j].idPersona = returnVal.persona;
        this.state.data[j].idCliente = returnVal.cliente;

        let temppersona=returnVal.persona;//this.state.data[j].idPersona;
        if( temppersona === "" || temppersona === null || temppersona === undefined ){
            terrores=terrores+1;

            FilaErr[j]="Error en la Fila " + (j);
            arrayErr[terrores]="(N) La persona No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La persona No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) La persona No Existe! "+ObjValidar.persona.nombre + " "+ObjValidar.persona.aPaterno+" "+ObjValidar.persona.aMaterno+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            var elem = document.getElementById("FilaclientesContactos"+j);
            console.log("353 FilaclientesContactos" + j);
            elem.style.backgroundColor = "Red";

        }

        let tempcliente=returnVal.cliente;//this.state.data[j].idCliente;
        if( tempcliente === "" || tempcliente === null || tempcliente === undefined ){
            terrores=terrores+1;
            this.state.data[j].idCliente = ObjValidar.cliente;
            FilaErr[j]="Error en la Fila " + (j);
            arrayErr[terrores]="(N) El Cliente NO existe " + ", Verifique en Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) El Cliente NO existe " + ", Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) El Cliente NO Existe " + ObjValidar.cliente.nombre + ", Verifique en Excel la Fila: " + (j+1) }</div> );
            var elem = document.getElementById("FilaclientesContactos"+j);
            console.log("374 FilaclientesContactos" + j);
            elem.style.backgroundColor = "Red";

        }

      }).catch(err =>{
        console.log("343 Error de validacionDatos: "+ err);
      } );
      break;
    case "plataformaTipo":
      if (ObjValidar.id>0){
      //if ( Number.isInteger(ObjValidar.id) ){
        switch ( ObjValidar.id ) {
          case 1:
            ObjValidar.nombre="Naviera";
            break;
          case 2:
            ObjValidar.nombre="Terrestre";
            break;
          case 3:
            ObjValidar.nombre="Terrestre naviera";
            break;
          default:
            break;
        }
      }
      else
      {
        switch (ObjValidar.nombre) {
          case "Naviera":
            ObjValidar.id=1;
            break;
          case "Terrestre":
            ObjValidar.id=2;
            break;
          case "Terrestre naviera":
            ObjValidar.id=3;
            break;
          default:
            break;
        }
      }
      break;
    case "movimientoTipo":
      k=0;
      this.state.movimientosTipo.map(ids => {
        if(ObjValidar.id!==""){
          if (ids.id===ObjValidar.id){
            Campo = this.state.movimientosTipo[k];
          }
        }else{
          if (ids.nombre===ObjValidar.nombre){
            Campo = this.state.movimientosTipo[k];
          }
        }
        k=k+1;
      });
      this.state.dataTerrestreMovimientos[j].movimientoTipo = Campo;
      break;
    default:
      break;
  }
}
valYaExiste(Catalogo, Po, I, Campo){
        let iDs = "";
        Crud_Catalogos("navieraMovimientos/po/"+Po,"", "get",  "", "",     "" , "",     "", []).then((returnVal) => {
          returnVal.map(ids => {
            iDs= "id: " + ids.id
            //console.log(ids.id);
            terrores=terrores+1;
          });
        }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  	    console.log("No Existe el Catalogo Definido");
}

completaValidacion(data, pestana){
  console.log("Inicia Completar Validacion "+pestana);
  switch (pestana) {
    case 'terrestreMovimientos':
      var j = 0;
      data.map(datos =>{
        var corredor ={"id":"", "nombre":"" };
        var operacionEstatus ={"id":"", "nombre":"" };
        var operacionTipo ={"id":"", "nombre":"" };
        var movimientoTipo ={"id":"", "nombre":"" };
        var plataforma = {"id":"", "nombre":"" };
        var transportista = { "id":"", "nombre":"" };
        var operador = {"id":"", "nombre":"" };
        var objeto = { plataforma, transportista, operador };
        Object.keys(datos).forEach(num =>{
              switch (num) {//Inicia Switch num
                //Obejetos en Cascada
                case 'plataforma':
                  if (Number.isInteger(datos[num])){
                    plataforma.id=datos[num];
                  }else{
                    plataforma.nombre=datos[num];
                  }
                  this.state.dataTerrestreMovimientos[j].plataforma=plataforma;
                  break;
                case 'transportista':
                  if (Number.isInteger(datos[num])){
                    transportista.id=datos[num];
                  }else{
                    transportista.nombre=datos[num];
                  }
                  this.state.dataTerrestreMovimientos[j].transportista=transportista;
                  break;
                case 'operador':
                  if (Number.isInteger(datos[num])){
                    operador.id=datos[num];
                  }else{
                    operador.nombre=datos[num];
                  }
                  this.state.dataTerrestreMovimientos[j].operador=operador;
                  break;
                //Objetos Independientes
                case 'corredor':
                  if (Number.isInteger(datos[num])){
                    corredor.id=datos[num];
                    this.state.corredor.map(ids => {
                      if(corredor.id!==""){
                        if (ids.id===corredor.id){
                          data[j].corredor=ids;
                        }
                      }else{
                        if (ids.nombre===corredor.nombre){
                          data[j].corredor=ids;
                        }
                      }
                    });
                  }else{
                    corredor.nombre=datos[num];
                    this.state.corredor.map(ids => {
                      if(corredor.id!==""){
                        if (ids.id===corredor.id){
                          data[j].corredor=ids;
                        }
                      }else{
                        if (ids.nombre===corredor.nombre){
                          data[j].corredor=ids;
                        }
                      }
                    });
                  }
                  break;
                case 'operacionEstatus':
                  if (Number.isInteger(datos[num])){
                    operacionEstatus.id=datos[num];
                    this.state.operacionEstatus.map(ids => {
                      if(operacionEstatus.id!==""){
                        if (ids.id===operacionEstatus.id){
                          data[j].operacionEstatus=ids;
                        }
                      }else{
                        if (ids.nombre===operacionEstatus.nombre){
                          data[j].operacionEstatus=ids;
                        }
                      }
                    });
                  }else{
                    operacionEstatus.nombre=datos[num];
                    this.state.operacionEstatus.map(ids => {
                      if(operacionEstatus.id!==""){
                        if (ids.id===operacionEstatus.id){
                          data[j].operacionEstatus=ids;
                        }
                      }else{
                        if (ids.nombre===operacionEstatus.nombre){
                          data[j].operacionEstatus=ids;
                        }
                      }
                    });
                  }
                  break;
                case 'operacionTipo':
                  if (Number.isInteger(datos[num])){
                    operacionTipo.id=datos[num];
                    this.state.operacionTipo.map(ids => {
                      if(operacionTipo.id!==""){
                        if (ids.id===operacionTipo.id){
                          data[j].operacionTipo=ids;
                        }
                      }else{
                        if (ids.nombre===operacionTipo.nombre){
                          data[j].operacionTipo=ids;
                        }
                      }
                    });
                  }else{
                    operacionTipo.nombre=datos[num];
                    this.state.operacionTipo.map(ids => {
                      if(operacionTipo.id!==""){
                        if (ids.id===operacionTipo.id){
                          data[j].operacionTipo=ids;
                        }
                      }else{
                        if (ids.nombre===operacionTipo.nombre){
                          data[j].operacionTipo=ids;
                        }
                      }
                    });
                  }
                  break;
                case 'movimientoTipo':
                  if (Number.isInteger(datos[num])){
                    movimientoTipo.id=datos[num];
                    this.state.movimientoTipo.map(ids => {
                      if(movimientoTipo.id!==""){
                        if (ids.id===movimientoTipo.id){
                          data[j].movimientoTipo=ids;
                        }
                      }else{
                        if (ids.nombre===movimientoTipo.nombre){
                          data[j].movimientoTipo=ids;
                        }
                      }
                    });
                  }else{
                    movimientoTipo.nombre=datos[num];
                    this.state.movimientoTipo.map(ids => {
                      if(movimientoTipo.id!==""){
                        if (ids.id===movimientoTipo.id){
                          data[j].movimientoTipo=ids;
                        }
                      }else{
                        if (ids.nombre===movimientoTipo.nombre){
                          data[j].movimientoTipo=ids;
                        }
                      }
                    });
                  }
                  break;
                default:
                  console.log("No encontro Columna Válida para este Campo");
                break;
              }//Fin Switch num
          }
        );
        this.validacionTerrestreMovimientos(objeto, j);
        var temporal="";
        j=j+1;
      });
      break;//Fin Completa terrestreMovimientos
  }
  this.setState({dataTerrestreMovimientos: data });
}
validaArchivo(pestana) {
    //switch (this.props.catalogo) {
    switch (pestana) {
      case 'terrestreMovimientoUnidad':
        var j = 0;
        this.state.dataTerrestreMovimientoUnidad.map(datos =>{
          var idMovimiento = "";
          var unidad = {"id":"", "nombre":"" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'idMovimiento':
                    if (Number.isInteger(datos[num])){
                      idMovimiento=datos[num];
                      this.valObjeto("idMovimiento", idMovimiento, j);
                    }
                    break;
                  case 'unidad':
                    if (Number.isInteger(datos[num])){
                      unidad.id=datos[num];
                      this.valObjeto("unidad", unidad, j);
                    }else{
                      unidad.nombre=datos[num];
                      this.valObjeto("unidad", unidad, j);
                    }
                    break;
                  default:
                    break;
                }//Fin Switch num
            }
          );
					//Validacion de Campos No Null
					var temporalidMovimiento="";
					var temporalNom="";
					//Validacion del Campo plataforma.id
					temporalidMovimiento=this.state.dataTerrestreMovimientoUnidad[j].idMovimiento;
          /*
					if ( (temporalidMovimiento==="")||(temporalidMovimiento===null)||(temporalidMovimiento===undefined)||(temporalidMovimiento===0)||(temporalidMovimiento==='empty string') ){
						console.log("No puede ir Vacío el Campo 'idMovimiento' en TerrestreMovimientoUnidad");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La idMovimiento: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) El idMovimiento No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) El idMovimiento No Existe! "+this.state.TerrestreMovimientoUnidad[j].idMovimiento+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              var elem = document.getElementById("unidadesplataforma"+suma);
              console.log("915 unidadesplataforma"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
          */
					//Fin Validacion del Campo plataforma.id
          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Pestaña "+pestana+" Validada!"}</div> );
        //Errores.push( <div id={j}>{this.state.validado?"Validación Finalizada":terrores}</div> );
        console.log("Finaliza Validacion de Archivo "+pestana);
        console.log(this.state.dataTerrestreMovimientoUnidad);
				break;//Fin Case terrestreMovimientoUnidad
      case 'plataformas':
        var j = 0;
        this.state.dataPlataformas.map(datos =>{
          var plataformaTipo = {"id":"", "nombre":"" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'plataformaTipo':
                    if (Number.isInteger(datos[num])){
                      plataformaTipo.id=datos[num];
                      this.valObjeto("plataformaTipo", plataformaTipo, j);
                    }else{
                      plataformaTipo.nombre=datos[num];
                      this.valObjeto("plataformaTipo", plataformaTipo, j);
                    }
                    this.state.dataPlataformas[j].plataformaTipo=plataformaTipo;
                    break;
                  default:
                  break;
                }//Fin Switch num
            }
          );
					//Validacion de Campos No Null
					var temporalId="";
					var temporalNom="";
					//Validacion del Campo plataformaTipo.id
          /*
					temporalId=this.state.dataPlataformas[j].plataformaTipo.id;
					if ( (temporalId==="")||(temporalId===null)||(temporalId===undefined)||(temporalId===0)||(temporalId==='empty string') ){
						console.log("No puede ir Vacío el Campo 'plataformaTipo.id'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La plataformaTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La plataformaTipo No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) La plataformaTipo No Existe! "+this.state.dataPlataformas[j].plataformaTipo.nombre+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              var elem = document.getElementById("plataformasplataformaTipo1");
              console.log("619 plataformasplataformaTipo"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataformaTipo.id
          //Validacion del Campo plataformaTipo.nombre
					temporalNom=this.state.dataPlataformas[j].plataformaTipo.nombre;
					if ( (temporalNom==="")||(temporalNom===null)||(temporalNom===undefined)||(temporalNom===0)||(temporalNom==='empty string') ){
            console.log("No puede ir Vacío el Campo 'plataformaTipo'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La plataformaTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La plataformaTipo No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) La plataformaTipo No Existe! "+this.state.dataPlataformas[j].plataformaTipo.id+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              //var elem = document.getElementById("plataformasplataformaTipo1");
              var elem = document.getElementById("plataformasplataformaTipo1");
              //if (elem === null){
              //  elem = elem.InnerHtml;
              //}
              //if(typeof elem !== null && elem !== 'undefined' ) {
                //let res= document.getElementById("result").innerHTML;
                //let res=elem.innerHTML;
                //res.style.bgcolor = "Red";
              //}
              console.log("636 plataformasplataformaTipo"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataformaTipo.nombre
          */
          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Pestaña "+pestana+" Validada!"}</div> );
        //Errores.push( <div id={j}>{this.state.validado?"Validación Finalizada":terrores}</div> );
        console.log("Finaliza Validacion de Archivo "+pestana);
        console.log(this.state.dataPlataformas);
				break;//Fin Case plataforma
      case 'unidades':
        var j = 0;
        this.state.dataUnidades.map(datos =>{
          var plataforma = {"id":"", "nombre":"" };
          var transportista = {"id":"", "nombre":"" };
          var unidadTipo = {"id":"", "nombre":"" };
          var estatus = {"id":"", "nombre":"" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'plataforma':
                    if (Number.isInteger(datos[num])){
                      plataforma.id=datos[num];
                      this.valObjeto("plataforma", plataforma, j);
                    }else{
                      plataforma.nombre=datos[num];
                      this.valObjeto("plataforma", plataforma, j);
                    }
                    //this.state.dataUnidades[j].plataforma=plataforma;
                    break;
                  case 'transportista':
                    if (Number.isInteger(datos[num])){
                      transportista.id=datos[num];
                      this.valObjeto("transportista", transportista, j);
                    }else{
                      transportista.nombre=datos[num];
                      this.valObjeto("transportista", transportista, j);
                    }
                    //this.state.dataUnidades[j].transportista=transportista;
                    break;
                  case 'unidadTipo':
                    if (Number.isInteger(datos[num])){
                      unidadTipo.id=datos[num];
                      this.valObjeto("unidadTipo", unidadTipo, j);
                    }else{
                      unidadTipo.nombre=datos[num];
                      this.valObjeto("unidadTipo", unidadTipo, j);
                    }
                    //this.state.dataUnidades[j].unidadTipo=unidadTipo;
                    break;
                  case 'estatus':
                    if (Number.isInteger(datos[num])){
                      estatus.id=datos[num];
                      this.valObjeto("estatus", estatus, j);
                    }else{
                      estatus.nombre=datos[num];
                      this.valObjeto("estatus", estatus, j);
                    }
                    //this.state.dataUnidades[j].estatus=estatus;
                    break;
                  default:
                  break;
                }//Fin Switch num
            }
          );
					//Validacion de Campos No Null
					var temporalId="";
					var temporalNom="";
					//Validacion del Campo plataforma.id
          /*
					temporalId=this.state.dataUnidades[j].plataforma.id;
					if ( (temporalId==="")||(temporalId===null)||(temporalId===undefined)||(temporalId===0)||(temporalId==='empty string') ){
						console.log("No puede ir Vacío el Campo 'plataforma.id' en Unidades");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La plataforma: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) La plataforma No Existe! "+this.state.dataUnidades[j].plataforma.nombre+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              var elem = document.getElementById("unidadesplataforma"+suma);
              console.log("897 unidadesplataforma"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataforma.id
          //Validacion del Campo plataforma.nombre
					temporalNom=this.state.dataUnidades[j].plataforma.nombre;
					if ( (temporalNom==="")||(temporalNom===null)||(temporalNom===undefined)||(temporalNom===0)||(temporalNom==='empty string') ){
            console.log("No puede ir Vacío el Campo 'plataforma'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La plataforma: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) La plataforma No Existe! "+this.state.dataUnidades[j].plataforma.id+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              //var elem = document.getElementById("plataformasplataforma1");
              var elem = document.getElementById("unidadesplataforma"+suma);
              console.log("914 unidadesplataforma"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataforma.nombre
          */
          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Pestaña "+pestana+" Validada!"}</div> );
        //Errores.push( <div id={j}>{this.state.validado?"Validación Finalizada":terrores}</div> );
        console.log("Finaliza Validacion de Archivo "+pestana);
        console.log(this.state.dataUnidades);
				break;//Fin Case Unidades

      case 'unidadEnsamble':
        var j = 0;
        this.state.dataUnidadEnsamble.map(datos =>{
          var idUnidad ="";
          var elemento = {"id":"", "nombre":"" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'idUnidad':
                    if (Number.isInteger(datos[num])){
                      idUnidad=datos[num];
                      this.valObjeto("idUnidad", idUnidad, j);
                    }
                    break;
                  case 'elemento':
                    if (Number.isInteger(datos[num])){
                      elemento.id=datos[num];
                      this.valObjeto("elemento", elemento, j);
                    }else{
                      elemento.nombre=datos[num];
                      this.valObjeto("elemento", elemento, j);
                    }
                    break;
                  default:
                  break;
                }//Fin Switch num
            }
          );
					//Validacion de Campos No Null
					var temporalId="";
					var temporalNom="";
					//Validacion del Campo plataforma.id
					temporalId=this.state.dataUnidadEnsamble[j].idUnidad;
					if ( (temporalId==="")||(temporalId===null)||(temporalId===undefined)||(temporalId===0)||(temporalId==='empty string') ){
						console.log("No puede ir Vacío el Campo 'idUnidad' en UnidadEnsamble");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La idUnidad: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La idUnidad No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) El idUnidad No Existe! "+this.state.dataUnidadEnsamble[j].idUnidad+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              var elem = document.getElementById("unidadEnsambleidUnidad"+suma);
              console.log("1280 unidadEnsambleidUnidad"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataforma.id
          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Pestaña "+pestana+" Validada!"}</div> );
        //Errores.push( <div id={j}>{this.state.validado?"Validación Finalizada":terrores}</div> );
        console.log("1291 this.state.dataUnidadEnsamble");
        console.log(this.state.dataUnidadEnsamble);
        console.log("Finaliza Validacion de Archivo "+pestana);
				break;//Fin Case Unidades
      default:
				NotificationManager.warning="No esta Defina una Carga Masiva para la HOJA de EXCEL "+pestana;
        break;
    }
  }
clickTab(nombreTab){
  this.setState({nombreTemporalTab: nombreTab });
  this.cadenadeCarga= nombreTab;
  console.log("1360 nombreTab: "+nombreTab);
  console.log(this.state.dataTerrestreMovimientos);
}

	cargarDatos = (carga, errores) => {
		console.log("1313 Cargar Datos Correctos e:" + errores);
    console.log("1314 " + this.cadenadeCarga);
    let w=0;
    let errResponse=[];
    switch (this.cadenadeCarga) {
      case "destinos":
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="destinos";
              console.log("1375 destinos");
            }
            for (let i = 0; i < this.state.dataDestinos.length; i++) {
              let nDe = 0;
              let nEn = 0;

              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataDestinos[i],"" ,"","", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataDestinos.length;
                console.log("1384 returnVal");
                console.log(returnVal);
                if (returnVal.id>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  this.state.dataDestinos[i]=returnVal;

                  console.log("1390 this.state.dataDestinos["+i+"]");
                  console.log(this.state.dataDestinos[i]);

                  //Refresca Colores en la Tabla Destinos
                  var filaenDestinos = document.getElementById("Filadestinos"+i);
                  console.log("1395 Filadestinos" + i);
                  filaenDestinos.style.backgroundColor = "green";
                }
              }).catch(err =>{
                console.log("1399 Filadestinos" + i+ " err: " + err);
                console.log("1400 Filadestinos" + i);
                //try {
                  var filaenDestinos = document.getElementById("Filadestinos"+i);
                  filaenDestinos.style.backgroundColor = "orange";
                //}
                console.log("1405 Error: " + err);
              });
            }
        break;
      case "terrestreMovimientoUnidad":
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="terrestreMovimientoUnidad";
              console.log("1323 terrestreMovimientoUnidad");
            }
            for (let i = 0; i < this.state.dataTerrestreMovimientoUnidad.length; i++) {
              let nDe = 0;
              let nEn = 0;
              console.log("1493 Se va a Realizar la Carga de ["+i+"]");
              console.log("1494 this.state.dataTerrestreMovimientoUnidad[i]");
              console.log(this.state.dataTerrestreMovimientoUnidad[i]);
              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataTerrestreMovimientoUnidad[i],"" , "",     "", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataTerrestreMovimientoUnidad.length;

                console.log("1497 returnVal");
                console.log(returnVal);
                if (returnVal.idMovimiento>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  this.state.dataTerrestreMovimientoUnidad[i]=returnVal;
                  console.log("1338 this.state.dataTerrestreMovimientoUnidad["+i+"]");
                  console.log(this.state.dataTerrestreMovimientoUnidad[i]);

                  //Refresca Colores en la Tabla terrestreMovimientoUnidad
                  var filaenTerrestreMovimientoUnidad = document.getElementById("FilaterrestreMovimientoUnidad"+i);
                  console.log("929 FilaterrestreMovimientoUnidad" + i);
                  filaenTerrestreMovimientoUnidad.style.backgroundColor = "green";

                  try{
                    let sumat = i+1;
                    var columnaenterrestreMovimientoUnidadunidad = document.getElementById("terrestreMovimientoUnidadunidad"+sumat);
                    console.log("1409 terrestreMovimientoUnidadunidad"+sumat);
                    columnaenterrestreMovimientoUnidadunidad.style.backgroundColor = "green";
                  }catch(e){
                    console.log("1516 this.state.dataTerrestreMovimientoUnidad["+i+"]");
                    console.log(this.state.dataTerrestreMovimientoUnidad[i]);
                    console.log("1518 No se Aplico el Color unidadEnsambleelemento" + i);
                  }
                }
              }).catch(err =>{
                console.log("1416 FilaterrestreMovimientoUnidad" + i);
                //try {
                  var filaenTerrestreMovimientoUnidad = document.getElementById("FilaterrestreMovimientoUnidad"+i);
                  filaenTerrestreMovimientoUnidad.style.backgroundColor = "orange";
                //}
                console.log("1356 Error: " + err);
              });
            }
        break;
      case "unidades":
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="unidades";
              console.log("990 unidades");
            }
            for (let i = 0; i < this.state.dataUnidades.length; i++) {
              let nDe = 0;
              let nEn = 0;
              console.log("995 this.state.dataUnidades["+i+"]");
              console.log(this.state.dataUnidades[i]);

              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataUnidades[i],"" , "",     "", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataUnidades.length;
                if (returnVal.id>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  this.state.dataUnidades[i]=returnVal;

                  console.log("1005 this.state.dataUnidades["+i+"]");
                  console.log(this.state.dataUnidades[i]);
                  var plataformaId = "";
                  var plataformaNombre = "";

                  console.log("1010 El id de Unidades es: "+returnVal.id);
                  this.state.dataUnidades[i]=returnVal;

                  plataformaNombre=this.state.dataPlataformas[i].nombre;
                  plataformaId=this.state.dataPlataformas[i].id;

                  //Refresca Colores en la dos Tablas Principal unidadEnsamble y
                  //TerrestreMovimientoUnidad
                  this.state.dataTerrestreMovimientoUnidad.map( (dato, j) => {
                      if (dato.plataforma.id==plataformaId){
                        dato.plataforma = returnVal;
                        console.log("1021 La Unidad en TerrestreMovimientoUnidad es la: " + j);
                        var filaenTabla = document.getElementById("terrestreMovimientoUnidadunidad"+j);
                        console.log("1023 terrestreMovimientoUnidadunidad" + j);
                        filaenTabla.style.backgroundColor = "#ffffff";
                      }
                  });

                  //terrestreMovimientos
                  this.state.dataUnidadEnsamble.map( (dato, j) => {
                      if (dato.plataforma.id==plataformaId){
                        dato.plataforma = returnVal;
                        console.log("922 La Unidad en UnidadEnsamble es la: " + j);
                        var filaenTabla = document.getElementById("unidadEnsambleidUnidad"+j);
                        console.log("924 unidadEnsambleidUnidad" + j);
                        filaenTabla.style.backgroundColor = "#ffffff";
                      }
                  });
                  var filaenUnidad = document.getElementById("Filaunidades"+i);
                  console.log("929 Filaunidades" + i);
                  filaenUnidad.style.backgroundColor = "green";
                }
              }).catch(err =>{
                console.log("1043 Error: " + err);
                var filaenUnidads = document.getElementById("Filaunidades"+i);
                console.log("1045 Filaunidades" + i);
                filaenUnidads.style.backgroundColor = "orange";
              });

            }
        break;
      case "plataformas":
            console.log("486 this.state.dataPlataformas");
            console.log(this.state.dataPlataformas);
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="plataformas";
              console.log("886 plataformas");
            }
            for (let i = 0; i < this.state.dataPlataformas.length; i++) {
              let nDe = 0;
              let nEn = 0;
              console.log("891 this.state.dataPlataformas["+i+"]");
              console.log(this.state.dataPlataformas[i]);

              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataPlataformas[i],"" , "",     "", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataPlataformas.length;
                if (returnVal.id>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  this.state.dataPlataformas[i]=returnVal;

                  console.log("901 this.state.dataPlataformas["+i+"]");
                  console.log(this.state.dataPlataformas[i]);
                  var plataformaId = "";
                  var plataformaNombre = "";

                  console.log("915 El id de Plataforma es: "+returnVal.id);
                  this.state.dataPlataformas[i]=returnVal;

                  plataformaNombre=this.state.dataPlataformas[i].nombre;
                  plataformaId=this.state.dataPlataformas[i].id;
                  //Refresca Colores en la dos Tablas Principal terrestreMovimientos
                  //unidades
                  this.state.dataUnidades.map( (dato, j) => {
                      if (dato.plataforma.id==plataformaId){
                        dato.plataforma = returnVal;
                        console.log("922 La Plataforma en Unidades es la: " + j);
                        var filaenTabla = document.getElementById("unidadesplataforma"+j);
                        console.log("924 unidadesplataforma" + j);
                        filaenTabla.style.backgroundColor = "#ffffff";
                      }
                  });

                  //terrestreMovimientos
                  this.state.dataTerrestreMovimientos.map( (dato, j) => {
                      if (dato.plataforma.id==plataformaId){
                        dato.plataforma = returnVal;
                        console.log("922 La Plataforma en terrestreMovimientos es la: " + j);
                        var filaenTabla = document.getElementById("terrestreMovimientosplataforma"+j);
                        console.log("924 terrestreMovimientosplataforma" + j);
                        filaenTabla.style.backgroundColor = "#ffffff";
                      }
                  });
                  var filaenPlataformas = document.getElementById("Filaplataformas"+i);
                  console.log("929 Filaplataformas" + i);
                  filaenPlataformas.style.backgroundColor = "green";
                }
              }).catch(err =>{
                console.log("536 Error: " + err);
                var filaenPlataformas = document.getElementById("Filaplataformas"+i);
                console.log("935 Filaplataformas" + i);
                filaenPlataformas.style.backgroundColor = "orange";
              });

            }
        break;
      case "terrestreMovimientos":
            console.log("1642 this.state.dataTerrestreMovimientos");
            console.log(this.state.dataTerrestreMovimientos);
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="terrestreMovimientos";
              console.log("1646 dataTerrestreMovimientos");
            }
            for (let i = 0; i < this.state.dataTerrestreMovimientos.length; i++) {
              let nDe = 0;
              let nEn = 0;
              //console.log("1651 this.state.dataTerrestreMovimientos["+i+"]");
              //console.log(this.state.dataTerrestreMovimientos[i]);

              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataTerrestreMovimientos[i],"" , "",     "", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataTerrestreMovimientos.length;
                if (returnVal.id>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  this.state.dataTerrestreMovimientos[i]=returnVal;

                  console.log("1661 this.state.dataTerrestreMovimientos["+i+"]");
                  console.log(this.state.dataTerrestreMovimientos[i]);
                  var plataformaNombre = "";
                  var plataformaId="";

                  console.log("1624 El id de TerrestreMovimientos es: "+returnVal.id);
                  //console.log("1625 this.state.dataTerrestreMovimientoUnidad");
                  //console.log(this.state.dataTerrestreMovimientoUnidad);
                  plataformaNombre=this.state.dataTerrestreMovimientos[i].nombre;
                  plataformaId=this.state.dataTerrestreMovimientos[i].id;
                  //Refresca Colores en la Tabla Principal "terrestreMovimientosUnidad"
                  // OJO No siempre existen dataTerrestreMovimientoUnidad por lo que si no se valida va a generar el Error y
                  //No va a pintar el color Verde...
                  /*
                  this.state.dataTerrestreMovimientoUnidad.map( (dato, j) => {
                    if (dato.nombre==plataformaNombre){
                          console.log("1674 La plataformaNombre en TerrestreMovimientosUnidad es la: " + j);
                          dato.plataforma = returnVal;
                          var filaenTabla = document.getElementById("FilaterrestreMovimientosUnidad"+j);
                          console.log("1677 FilaterrestreMovimientosUnidad" + j);
                          filaenTabla.style.backgroundColor = "#ffffff";
                    }
                  });
                  */
                  try {
                    var filaenTerrestreMovimientos = document.getElementById("FilaterrestreMovimientos"+i);
                    console.log("1037 FilaterrestreMovimientos" + i);
                    filaenTerrestreMovimientos.style.backgroundColor = "green";
                  }catch(e){
                    console.log("Error al Aplicar Color");
                  }
                }
              }).catch(err =>{
                console.log("1041 Error: " + err);
                var filaenPersona = document.getElementById("FilaterrestreMovimientos"+i);
                console.log("1043 FilaterrestreMovimientos" + i);
                filaenPersona.style.backgroundColor = "orange";
              });

            }
        break;
      case "cliente":
        console.log("550 cliente");
        console.log(this.state.dataCliente);
        if (this.cadenadeCarga===null){
          this.cadenadeCarga="cliente";
          console.log("550 cliente");
        }
        for (let i = 0; i < this.state.dataCliente.length; i++) {
          let nDe = 0;
          let nEn = 0;
          console.log("dataCliente");
          console.log(this.state.dataCliente[i]);
          Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataCliente[i],"" , "",     "", []).then((returnVal) => {
            nEn = i+1;
            nDe = this.state.dataCliente.length;
            if (returnVal.id>0){
              //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
              this.state.dataCliente[i]=returnVal;
              console.log("561   this.state.dataCliente["+i+"]");
              console.log(this.state.dataCliente[i]);
              var cteNombre="";

              console.log("565 El id del Clientes es: "+returnVal.id);
              cteNombre=this.state.dataCliente[i].nombre;

              //Refresca Colores en la Tabla Principal "clientesContactos"
              carga.map( (dato, k) => {
                console.log("569 dato "+k);
                console.log(dato);
                if ( dato.idCliente.nombre===cteNombre ){
                  console.log("584 El Cliente en clientesContactos es la: " + k);
                  dato.idCliente = returnVal;
                  var filaenTabla = document.getElementById("FilaclientesContactos"+k);
                  console.log("587 FilaclientesContactos" + k);
                  filaenTabla.style.backgroundColor = "#ffffff";
                }
              });
              var filaenCliente = document.getElementById("Filacliente"+i);
              console.log("578 Filacliente" + i);
              filaenCliente.style.backgroundColor = "green";
            }
          }).catch(err =>{
            console.log("585 Error: " + err);
            var filaenCliente = document.getElementById("Filacliente"+i);
            console.log("584 Filacliente" + i);
            filaenCliente.style.backgroundColor = "orange";
          });
        }
        break;
      case "clientesContactos":
        if (this.cadenadeCarga===null){
          this.cadenadeCarga="clientesContactos";
          console.log("625 clientesContactos");
        }
        for (let i = 0; i < carga.length; i++) {
          let nDe = 0;
          let nEn = 0;

            Crud_Catalogos(this.props.catalogo, this.catalogo, "post", "", carga[i],"" , "",     "", []).then((returnVal) => {
              nEn = i+1;
              nDe = carga.length;
              if (returnVal.id>0){
                console.log("returnVal.id>0");
                carga[i]=returnVal;
                var filaenClientesContactos = document.getElementById("FilaclientesContactos"+i);
                console.log("578 FilaclientesContactos" + i);
                filaenClientesContactos.style.backgroundColor = "green";
                console.log("Carga Masiva en Tabla: " + this.props.catalogo + " Carga de "+nEn+ " de " + nDe+ " Registros! ");
                //console.log("Carga Finalizada! ");
                //NotificationManager.information("Carga Masiva en Tabla: " + this.props.catalogo," Carga de "+nEn+ " de " + nDe+ " Registros! ");
                //myModalMT("none");
              }
            }).catch(err =>{
              console.log("612 Error: " + err);
              var filaenCliente = document.getElementById("FilaclientesContactos"+i);
              console.log("614 FilaclientesContactos" + i);
              filaenCliente.style.backgroundColor = "orange";
            });

        }
        break;
      case "personas":
            console.log("486 this.state.dataPersona");
            console.log(this.state.dataPersona);
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="personas";
              console.log("490 personas");
            }
            for (let i = 0; i < this.state.dataPersona.length; i++) {
              let nDe = 0;
              let nEn = 0;
              console.log("495 this.state.dataPersona["+i+"]");
              console.log(this.state.dataPersona[i]);

              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataPersona[i],"" , "",     "", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataPersona.length;
                if (returnVal.id>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  this.state.dataPersona[i]=returnVal;

                  console.log("505 this.state.dataPersona["+i+"]");
                  console.log(this.state.dataPersona[i]);
                  var psnaNombre = "";
                  var psnaPaterno = "";
                  var psnaMaterno = "";

                  console.log("513 El id de Persona es: "+returnVal.id);
                  psnaNombre=this.state.dataPersona[i].nombre;
                  psnaPaterno=this.state.dataPersona[i].aPaterno;
                  psnaMaterno=this.state.dataPersona[i].aMaterno;

                  //Refresca Colores en la Tabla Principal "clientesContactos"
                  carga.map( (dato, j) => {
                    if (dato.nombre==psnaNombre){
                      if (dato.aPaterno==psnaPaterno){
                        if (dato.aMaterno==psnaMaterno){
                          console.log("536 La persona en clientesContactos es la: " + j);
                          dato.idPersona = returnVal;
                          var filaenTabla = document.getElementById("FilaclientesContactos"+j);
                          console.log("539 FilaclientesContactos" + j);
                          filaenTabla.style.backgroundColor = "#ffffff";
                        }
                      }
                    }
                  });
                  var filaenPersona = document.getElementById("Filapersonas"+i);
                  console.log("537 Filapersonas" + i);
                  filaenPersona.style.backgroundColor = "green";
                }
              }).catch(err =>{
                console.log("536 Error: " + err);
                var filaenPersona = document.getElementById("Filapersonas"+i);
                console.log("538 Filapersonas" + i);
                filaenPersona.style.backgroundColor = "orange";
              });

            }
        break;
      default:
    }
	}
  onImportExcel = file => {
    const { files } = file.target;
    Cuadros=[];
    TabTemp=[];
    TabPanelTemp=[];
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        for (const sheet in workbook.Sheets) {
          switch (sheet) {
            case "destinos":
              console.log("1891 Destinos");
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataDestinos: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                TabTemp.push(<Tab id={"destinos"} onClick={() => this.clickTab("destinos") }>destinos</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"destinos"} contenido={this.state.dataDestinos}/></TabPanel>);
              }
              break;
            case "transportistas":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataTransportistas: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                TabTemp.push(<Tab id={"transportistas"} onClick={() => this.clickTab("transportistas") }>transportistas</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"transportistas"} contenido={this.state.dataTransportistas}/></TabPanel>);
              }
              break;
            case "unidadTipo":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataUnidadTipo: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                TabTemp.push(<Tab id={"unidadTipo"} onClick={() => this.clickTab("unidadTipo") }>unidadTipo</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"unidadTipo"} contenido={this.state.dataUnidadTipo}/></TabPanel>);
              }
              break;
            case "unidadEstatusOperacion":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataUnidadEstatusOperacion: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                TabTemp.push(<Tab id={"unidadEstatusOperacion"} onClick={() => this.clickTab("unidadEstatusOperacion") }>unidadEstatusOperacion</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"unidadEstatusOperacion"} contenido={this.state.dataUnidadEstatusOperacion}/></TabPanel>);
              }
              break;

            case "plataformas":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataPlataformas: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                { this.validaArchivo('plataformas') }
                TabTemp.push(<Tab id={"plataformas"} onClick={() => this.clickTab("plataformas") }>plataformas</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"plataformas"} contenido={this.state.dataPlataformas}/></TabPanel>);
              }
              break;
            case "unidades":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataUnidades: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                { this.validaArchivo('unidades') }
                TabTemp.push(<Tab id={"unidades"} onClick={() => this.clickTab("unidades") }>unidades</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"unidades"} contenido={this.state.dataUnidades}/></TabPanel>);
              }
              break;
            case "terrestreMovimientos":
              console.log("1990 Carga terrestreMovimientos");
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataTerrestreMovimientos: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]) });
                { this.completaValidacion(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]), 'terrestreMovimientos') }
                //{ this.validaArchivo('terrestreMovimientos') }
                TabTemp.push(<Tab id={"terrestreMovimientos"} onClick={() => this.clickTab("terrestreMovimientos") }>terrestreMovimientos</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"terrestreMovimientos"} contenido={this.state.dataTerrestreMovimientos}/></TabPanel>);
              }
              console.log("1998 Fin terrestreMovimientos");
              break;
            case "terrestreMovimientoUnidad":
              console.log("2001 Carga terrestreMovimientosUnidad");
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataTerrestreMovimientoUnidad: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                { this.validaArchivo('terrestreMovimientoUnidad') }
                TabTemp.push(<Tab id={"terrestreMovimientoUnidad"} onClick={() => this.clickTab("terrestreMovimientoUnidad") }>terrestreMovimientoUnidad</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"terrestreMovimientoUnidad"} contenido={this.state.dataTerrestreMovimientoUnidad}/></TabPanel>);
              }
              break;
              console.log("2009 Fin terrestreMovimientosUnidad");
            //Eliminar Solo están de Ejemplo
            case "personas":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataPersona: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                TabTemp.push(<Tab id={"personas"} onClick={() => this.clickTab("personas") }>personas</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"personas"} contenido={this.state.dataPersona}/></TabPanel>);
              }
              break;
            case "cliente":
              //console.log("Clientes");
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({dataCliente: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]) });
                TabTemp.push(<Tab id={"cliente"} onClick={() => this.clickTab("cliente") } >cliente</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"cliente"} contenido={this.state.dataCliente}/></TabPanel>);
              }
              break;
            case "clientesContactos":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                console.log("707 clientesContactos");
                console.log(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                this.setState({ data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]) });
                TabTemp.push(<Tab id={"clientesContactos"} onClick={() => this.clickTab("clientesContactos") } >clientesContactos</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"clientesContactos"} contenido={this.state.data}/></TabPanel>);
              }
              //{ this.validaArchivo() }
              break;
            //Fin de los que se van a Eliminar

            case "datos":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                console.log("717 terrestreMovimientos");
                console.log(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                this.setState({ data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]) });
                this.setState({dataTerrestreMovimientos: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                { this.validaArchivo('terrestreMovimientos') }
                TabTemp.push(<Tab id={"terrestreMovimientos"} onClick={() => this.clickTab("terrestreMovimientos") }>terrestreMovimientos</Tab>);
                //TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"terrestreMovimientos"} contenido={this.state.dataTerrestreMovimientos}/></TabPanel>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"terrestreMovimientos"} contenido={this.state.data}/></TabPanel>);
              }
              //{ this.validaArchivo('terrestreMovimientos') }
              break;
            default:
              if (workbook.Sheets.hasOwnProperty(sheet)){
                //this.setState({dataDestinos: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                TabTemp.push(<Tab id={sheet} onClick={() => this.clickTab(sheet) }>{sheet}</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"destinos"} contenido={"Hoja de Excel No Valida"}
                                  /></TabPanel>);
              }
              break;
              console.log("Hoja No Válida!: "+sheet);
          }
          //if (workbook.Sheets.hasOwnProperty(sheet)) {
          //  this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
          //}
        }
        Cuadros.push
        (
          <Tabs forceRenderTabPanel={true} className={"div1"}>
                <TabList>
                  {TabTemp}
                </TabList>
                  {TabPanelTemp}
          </Tabs>
        )

        this.setState({validado:true });
        console.log('Carga de Archivo Exitosa！');
        id=0;
        terrores=0;
				sinError=0;
      } catch (e) {
        console.log("Error! "+e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
		myModalMT("block");
    document.getElementById("cerrar").focus();
}

render() {
    return (
      <div className="excelMT" id="excelMT">
        <p className="etiquetaMT" id="etiquetaMT"><a href="#miModalMT">Imp</a></p>
        <input type='file' id="file" className="file" accept='.xlsx, .xls' onChange={this.onImportExcel} />

				<div id="xlsModalMT" className="xlsModalMT">
					<div className="modal-contenidoMT">
					<center><h2>Confirme su Carga</h2></center>
					<div className="container-350">
						<div className="scroll-col col-sm-12">
						{
              <div>
                { Cuadros }
              </div>
						}
						</div>

					</div>

					<br></br>
					<center>
						<button id="cerrar" className="azul" onClick={()=>myModalMT('none')} >Cerrar o Corregir Archivo de Excel</button>
						<spam>   </spam>
						<button id="cargar" className="azul" onClick={()=>this.cargarDatos(this.state.data, terrores)} >Cargar {this.state.nombreTemporalTab}</button>
					</center>
					</div>
				</div>
      </div>
    );
  }
}
export default ImportarMT;
