import React, { useState, useEffect } from 'react';
import { Doughnut, Bar, Polar } from 'react-chartjs-2';
//


import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import { Grid, Button, Icon, Form, Table } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { Exportar } from '../../herramientas/Excel';
import { data } from 'jquery';
import { timeToDatefull } from "../../herramientas/DateFormat";
import { set } from 'd3-collection';

const options = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
};

const currentUser= JSON.parse(sessionStorage.getItem("currentUser"));


const MovimientosSeguridad = () => {
  
  const [ year, setYear]= useState(new Date().getFullYear() );
  const [ week, setWeek]= useState(new Date().getWeekNumber() );
  const [ otds, setOtds]= useState( [] );
  const [ transportistas, setTransportistas]= useState([]);
  const [ transportista, setTransportista]= useState({});
  const [ detalle, setDetalle]= useState("r_otd");

  const [ fecha1, setFecha1 ] = useState();
  const [ fecha2, setFecha2 ] = useState();
  const [ datos, setDatos ] = useState([]);
  const [ datosTabla, setDatosTabla ] = useState([]);
  const [ datosTransportistas, setDatosTransportistas ] = useState([]);
  const [ mensaje, setMensaje] = useState();

  useEffect(() => {
    //document.title = `${plataforma.label}`;
  });
  const getData=(e)=>{
    if( !(fecha1 && fecha2) ){ return; }
    if( new Date(fecha1).getTime() > new Date(fecha2).getTime() ){ return; }
    crud("get", [], "", "estadisticasSeguridad/get_by_incidencia_tipo/16/"+fecha1+" 00:00:00/"+fecha2+" 23:59:59", "data");
  }

  const changeWeek=(e)=>{
    setWeek(e.target.value);
  }
  const changueDetalle =(det, valor)=>{
    setDetalle(det);
    if(det === 'incidencia'){
      setTransportista({});
      crud("get", "", "", "estadisticasSeguridad/get_by_transportista/" + valor.incidencia_id + "/" + fecha1 +" 00:00:00/"+ fecha2 + " 23:59:59", "datosTransportistas");
    }
  }
  const setTrans=(e)=>{
    setTransportista(e);
    setDetalle('r_movimiento');
  }

  const changeFecha1 =(e)=>{
    if(fecha2){
      if( (new Date(fecha2).getTime() - new Date(e.target.value).getTime()) > (1000*60*60*24*31) ){ 
        setMensaje("El periodo no debe ser mayor de 31 días 1");
        return; 
      }
    }
    setMensaje("");
    setFecha1(e.target.value);
  }

  const changeFecha2 =(e)=>{
    if(fecha1){
      if( (new Date(e.target.value).getTime() - new Date(fecha1).getTime()) > (1000*60*60*24*31) ){
         setMensaje("El periodo no debe ser mayor de 31 días 2");
         return; 
        }
    }
    setMensaje("");
    setFecha2(e.target.value);
  }


  return (
    <SiteWrapper>
      <div className="container mt-3">
        <Grid.Row>
          <Grid.Col>
            <h2>	Estadísticas: Incidencias especiales </h2>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Fecha de inicio">
                  <input type="date" id="start" name="trip-start" onChange={ changeFecha1 } value={fecha1} ></input>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Fecha fin">
                  <input type="date" id="start" name="trip-start" onChange={ changeFecha2 } value={fecha2} ></input>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label=".">
                  <Button pill size="sm" outline color="primary" onClick={ ()=> getData() } ><Icon name="refresh-cw" /></Button>
                </Form.Group>
              </Grid.Col>
              
        </Grid.Row>
        <Grid.Row>
        <p>{ mensaje }</p>
        </Grid.Row>
        
        <Grid.Row>
          <Grid.Col>
            <div style={{ height: "80%" }} className="text-center">
              <h2>	Tipos de incidencia </h2>
              <Bar
                data={datos}
                width={100}
                height={90}
                options={options}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
        { detalle !== "incidencia" ?
          <Grid.Row>
            <div className="mt-5">
              Texto a cambiar
            </div>
            <Table cards={false} striped={false} responsive={true} className="table-vcenter"
              headerItems={[
                {content:"Tipo de incidencia"},
                {content:"Total"},
                {content:"LINK"},
              ]}>
                            <Table.Header></Table.Header>
                            <Table.Body>
                            { datosTabla.sort().map((item, index) =>
                                <Table.Row align="center" style={ { "background-color": "rgba(95, 231, 50, 0.30)" }}>
                                    <Table.Col> { item.incidencia_nombre } </Table.Col>
                                    <Table.Col> { item.total } </Table.Col>
                                    <Table.Col> <Button pill size="sm" outline color="primary" onClick={ ()=> changueDetalle('incidencia', item) } ><Icon name="list" /></Button> </Table.Col>
                                </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
          </Grid.Row>

        :null}

        { detalle === "incidencia" ?
          <Grid.Row>
            <div className="mt-5">
              Incidencias (Transportistas)
            </div>
            <Table cards={false} striped={false} responsive={true} className="table-vcenter"
              headerItems={[
                {content:"Transportistas"},
                {content:"Total"},
                {content:"LINK"},
              ]}>
                            <Table.Header></Table.Header>
                            <Table.Body>
                            { datosTransportistas.sort().map((item, index) =>
                                <Table.Row align="center" style={ { "background-color": "rgba(95, 231, 50, 0.30)" }}>
                                    <Table.Col> { item.transportista_nombre } </Table.Col>
                                    <Table.Col> { item.total } </Table.Col>
                                    <Table.Col> <Button pill size="sm" outline color="primary" onClick={ ()=> changueDetalle('incidencia', item) } ><Icon name="list" /></Button> </Table.Col>
                                </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
          </Grid.Row>

        :null}


        { detalle === "r_semana" || detalle === "r_movimiento"?
          <Grid.Row>
            <Grid.Col sm={12} lg={12}>
              <div className="mt-5">
                Detalle de la semana <Button pill size="sm" outline color="primary" onClick={ ()=> setDetalle('r_otd') } ><Icon name="arrow-left" /></Button>
              </div>
            </Grid.Col>
            <Grid.Col>              
              <Table cards={false} striped={false} responsive={true} className="table-vcenter"
                headerItems={[
                  {content:"Semana"},
                  {content:"Transportista"},
                  {content:"onTime"},
                  {content:"Delay"},
                  {content:"Total Paradas"},
                  {content:"OTD"},
                ]}>
                  <Table.Header></Table.Header>
                  <Table.Body>
                  { transportistas.sort().map((w, index) =>
                      <Table.Row align="center" style={ { "background-color": "rgba(95, 231, 50, 0.30)" }}>
                          <Table.Col> { w.semana } </Table.Col>
                          <Table.Col> { w.nombre } </Table.Col>
                          <Table.Col> { w.ontime } </Table.Col>
                          <Table.Col> { w.delay } </Table.Col>
                          <Table.Col> { w.totentregas } </Table.Col>
                          <Table.Col> { w.totentregas ? Math.floor((100/w.totentregas)*w.ontime): 0 } % </Table.Col>
                          <Table.Col> <Button pill size="sm" outline color="primary" onClick={ ()=> setTrans(w) } ><Icon name="list" /></Button> </Table.Col>
                      </Table.Row>
                      )}
                  </Table.Body>
              </Table>
            </Grid.Col>
            {detalle === "r_movimiento"?
              <Grid.Col>              
                <Table cards={false} striped={false} responsive={true} className="table-vcenter">
                  <Table.Header></Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Col> Transportista: </Table.Col><Table.Col> { transportista.nombre? transportista.nombre: "" } </Table.Col>
                    </Table.Row>
                    <Table.Row>  
                      <Table.Col> Contacto: </Table.Col><Table.Col> { transportista.nombre? transportista.contacto_nombre: "" } </Table.Col>
                    </Table.Row>
                    <Table.Row>  
                      <Table.Col> Tel 1: </Table.Col><Table.Col> { transportista.nombre? transportista.telefono1: "" } </Table.Col>
                    </Table.Row>
                    <Table.Row>  
                      <Table.Col> Tel 2: </Table.Col><Table.Col> { transportista.nombre? transportista.telefono2: "" } </Table.Col>
                    </Table.Row>
                    <Table.Row>  
                      <Table.Col> Correo: </Table.Col><Table.Col> { transportista.nombre? transportista.correo: "" } </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Col>
            :null}
          </Grid.Row>
        :null}
        
      </div>
    </SiteWrapper>
  );

  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    if (stateVar === "data" && returnVal) {
                      var dat={
                        labels: [ ],
                        datasets: [{ data: [], backgroundColor: [ ], hoverBackgroundColor: [], label: []  }]
                      };

                      returnVal.map((t, index)=>{
                        dat.labels.push(t.incidencia_nombre);

                        dat.datasets[0].data.push(t.total);
                        //dat.datasets[0].label.push(corredor.nombre);
                        let color= "#000000".replace(/0/g, () => (~~(Math.random() * 16)).toString(16));
                        dat.datasets[0].backgroundColor.push(color);
                        dat.datasets[0].hoverBackgroundColor.push(color+90);
                      });
                      setDatosTabla(returnVal);
                      setDatos(dat);
                    }
                    if( stateVar  === 'datosTransportistas' && returnVal){
                      setDatosTransportistas(returnVal);
                    }
                } break;
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }/*
    Date.prototype.getWeekNumber = function(){
      var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
      var dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
    };*/
}


export default MovimientosSeguridad;
