import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import ModalLayout from "../../../components/ModalLayout.react";
import Select from "react-select";
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class Cliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
//Campos de la Entity
              id:"",
          nombre:"",
     nombreCorto:"",
       domicilio:"",
        contacto:"",
        telefono:"",
       extension:"",
            nota:"",
             vip:0,
         latitud:0,
        longitud:0,
         estatus:0,
idPlataformaTipo: { value: 0, label: "Selecciona tipo de plataforma" },
idMovimientoTipo: { value: 0 , label: "Selecione tipo de movmiento" },
idCampoLogistico: { value: 0, label:" Seleccione un campoLogistico"},
camposLogisticos: [],
 movimientosTipo: [],
      idGeocerca: "",
            cron: "00:00:00",
              wt: "00:00:00",
 crudbutonEnable: true, // Se desabilita cuando se hace click
    hiddenModals: false,
          accion: this.props.accion, //'nuevo'// modificar, nuevo
          configuracionesClienteConsolidado: []
    };
  this.idPlataformaTipos= [ { value: 0, label: "Selecciona tipo de plataforma" },
                            { value: 1, label: "Naviera" },
                            { value: 2, label: "Terrestre"}
                          ];
  this.plataformaId=0;
  this.clienteObject={ "id":0, "nombre":"", "nombreCorto":"", "domicilio":"", "contacto":"",
            "telefono":"", "extension":"", "nota":"", "vip":"", "latitud":"", "ongitud":"",
             "estatus":0 , "idPlataformaTipo": 0, "idCampoLogistico": null, "idGeocerca": "", "cron": "00:00:00", "wt": "12:00:00" }
  this.configuracionClienteConsolidado= { "idCliente":  this.props.elementos? this.props.elementos.id: null, "idMovimientoTipo": 0, "idCampoLogistico": 0 };
  this.configuracion= {};
  }
  isEnable=(Accion)=>{
    let borrar= "clientes_borrar";
    let actualizar= "clientes_editar";
    let agregar= "clientes_agregar";
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "borrar"){ 
        if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }

  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.clienteObject=this.props.elementos
      this.setState({ id:           this.props.elementos.id? this.props.elementos.id:"" });
      this.setState({ nombre:       this.props.elementos.nombre? this.props.elementos.nombre:"" });
      this.setState({ nombreCorto:  this.props.elementos.nombreCorto? this.props.elementos.nombreCorto:"" });
      this.setState({ domicilio:    this.props.elementos.domicilio? this.props.elementos.domicilio:"" });
      this.setState({ contacto:     this.props.elementos.contacto? this.props.elementos.contacto:"" });
      this.setState({ telefono:     this.props.elementos.telefono? this.props.elementos.telefono:"" });
      this.setState({ extension:    this.props.elementos.extension? this.props.elementos.extension:"" });
      this.setState({ nota:         this.props.elementos.nota? this.props.elementos.nota:"" });
      this.setState({ vip:          this.props.elementos.vip? this.props.elementos.vip:"" });
      this.setState({ latitud:      this.props.elementos.latitud? this.props.elementos.latitud:"" });
      this.setState({ longitud:     this.props.elementos.longitud? this.props.elementos.longitud:"" });
      this.setState({ estatus:      this.props.elementos.estatus? this.props.elementos.estatus:"" });
      this.setState({ idGeocerca:   this.props.elementos.idGeocerca? this.props.elementos.idGeocerca:"" });
      this.setState({ cron:         this.props.elementos.cron? this.props.elementos.cron: "00:00:00" });
      this.setState({ wt:           this.props.elementos.wt? this.props.elementos.wt: "12:00:00" });
      for(let item of this.idPlataformaTipos ){
        if( item.value === this.props.elementos.idPlataformaTipo){
          this.setState({ idPlataformaTipo: item });
          this.clienteObject.idPlataformaTipo= item.value;
        }   
      }
      this.getfuntion("get", "", "", "camposLogisticos", "camposLogisticos");
      this.getfuntion("get", "", "", "movimientosTipo","movimientosTipo");
    } else {
      if( this.props.plataforma ){
        if( this.props.plataforma.plataformaTipo ){
          if(this.props.plataforma.plataformaTipo.id === 1 || this.props.plataforma.plataformaTipo.id === 2 ){
            this.setState({ idPlataformaTipo: { value: this.props.plataforma.plataformaTipo.id, label: this.props.plataforma.plataformaTipo.nombre } });   
            this.clienteObject.idPlataformaTipo= this.props.plataforma.plataformaTipo.id;
          }
          if(this.props.plataforma.plataformaTipo.id === 3 ){
            this.setState({ idPlataformaTipo: { value: 1, label: "Naviera" } });   
            this.clienteObject.idPlataformaTipo= 1;
          }     
          
        }
      }      
      //this.clienteObject = {"id":0, "nombre":"", "nombreCorto":"", "domicilio":"", "contacto":"", "telefono":"", "extension":"", "nota":"", "vip":"", "latitud":"", "ongitud":"", "estatus":""};
    }
    //this.getfuntion("get", "", "", "camposLogisticos", "camposLogisticos");
  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas", stateVar = "personas", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "camposLogisticos" && returnVal) {
              let calogs= [{ value: null, label:"Seleccione un campoLogistico"}];
              for(let item of returnVal){
                calogs.push({ value: item.id, label: item.nombre, data: item });
              }
              this.camposLogisticos= calogs;
              this.setState({ idCampoLogistico: { value: 0, label: "Seleccione un tipo de movimiento"} });
            }

            if (stateVar === "movimientosTipo"){ this.movimientoTipos = [];
              returnVal.map((mt, index)=>this.movimientoTipos.push({ value: mt.id, label: mt.nombre, data: mt }) );
              this.setState({ movimientosTipo: this.movimientoTipos  });
              if(this.props.accion == 'nuevo'){this.setState({ idMovimientoTipo: { value: 0 , label: "Selecciona un Tipo de movimiento" }});}
              this.getfuntion("get", "", "", "configuracionClienteConsolidado/cliente/"+this.props.elementos.id, "configuracion");
            }
            if (stateVar === "configuracion"){                
              this.setState({ configuracionesClienteConsolidado: returnVal });
            }
          }
          break;
        case "post":
          {
            if( stateVar === "configuracion" ){
              NotificationManager.info("Guardado ..", "Guardado");
              let confTemp = this.state.configuracionesClienteConsolidado;
              confTemp.push(returnVal);
              this.setState({ configuracionesClienteConsolidado: confTemp });
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "configuracion"){
              let confTemp = this.state.configuracionesClienteConsolidado;
              confTemp.map((item, index)=>{
                if( item.idCliente+"/"+item.idMovimientoTipo+"/"+item.idCampoLogistico === id ){
                  if(returnVal){
                    confTemp.splice(index, 1);
                    NotificationManager.info("Borrado ..", "Guardado");
                  }else{
                    NotificationManager.info("No se ha podido borrar ..", "Error");
                  }                    
                  this.setState({ configuracionesClienteConsolidado: confTemp });
                }
              });              
            }              
          }
            break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }

  enableSave = () => {
    if (this.state.nombre.length > 0 && this.state.nombreCorto.length > 0 && this.state.domicilio.length > 0 && this.state.idPlataformaTipo.value > 0 ) {
      return false;
    } else { return true }
  }

  delete()    { this.props.getfuntion("delete", "", this.state.id);   this.setState({ crudbutonEnable: false }); }
  crud_put()  { this.props.getfuntion("put"   , this.clienteObject);  this.setState({ crudbutonEnable: false }); }
  crud_post() { this.props.getfuntion("post"  , this.clienteObject);  this.setState({ crudbutonEnable: false }); }
  changeNombre = (event) => {
    this.clienteObject.nombre = event.target.value;
    this.setState({ nombre:event.target.value });
  }
  changeNombreCorto = (event) => {
    this.clienteObject.nombreCorto = event.target.value;
    this.setState({ nombreCorto:event.target.value });
  }
  changeDomicilio = (event) => {
    this.clienteObject.domicilio = event.target.value;
    this.setState({ domicilio:event.target.value });
  }
  changeContacto = (event) => {
    this.clienteObject.contacto = event.target.value;
    this.setState({ contacto:event.target.value });
  }
  changeTelefono = (event) => {
    this.clienteObject.telefono = event.target.value;
    this.setState({ telefono:event.target.value });
  }
  changeExtension = (event) => {
    this.clienteObject.extension = event.target.value;
    this.setState({ extension:event.target.value });
  }
  changeLatitud = (event) => {
    this.clienteObject.latitud = event.target.value;
    this.setState({ latitud:event.target.value });
  }
  changeLongitud = (event) => {
    this.clienteObject.longitud = event.target.value;
    this.setState({ longitud:event.target.value });
  }
  changeNota = (event) => {
    this.clienteObject.nota = event.target.value;
    this.setState({ nota: event.target.value });
  }
  changeIdGeocerca = (event) => {
    this.clienteObject.idGeocerca = event.target.value;
    this.setState({ idGeocerca: event.target.value });
  }
  changeVip = (event) => {
    this.clienteObject.vip =  event.target.checked? 1:0;
    this.setState({ vip: event.target.checked? 1:0 });
  }
  changeEstatus = (event) => {
    this.state.estatus === 0? this.setState({ estatus:1 }):this.setState({ estatus:0 });
    this.state.estatus === 0? this.clienteObject.estatus=1:this.clienteObject.estatus=0;
  }

  changeCron=(e) =>{
    this.clienteObject.cron= e.target.value;
    this.setState({ cron: e.target.value });
  }
  changeWt=(e) =>{
    this.clienteObject.wt= e.target.value;
    this.setState({ wt: e.target.value });
  }

  changePlataformaTipo=(e) =>{
    this.clienteObject.idPlataformaTipo= e.value;
    this.setState({ idPlataformaTipo: e });
  }
  changeMovimientoTipo = (event) => {
    this.setState({ idMovimientoTipo: event });
    this.configuracionClienteConsolidado.idMovimientoTipo= event.value;
    let idMotipoCampoLog= 0;
    if( event.value === 1 || event.value === 3 ){ idMotipoCampoLog= 1 }
    if( event.value === 2 || event.value === 4 ){ idMotipoCampoLog= 2 }
    let camposMapeados= "arribo_cliente_lleno, arribo_cliente_vacio, fecha_arribo, salida_cliente_vacio, salida_cliente_lleno, fecha_salida, arribo_patio_linea, arribo_terminal_vacio_2, arribo_terminal_cargado";
    let calogs= [{ value: 0, label:"Seleccione un campoLogistico"}];
    for(let item of this.camposLogisticos){
      if(item.data){ 
        if( item.data.idMovimientoTipo === idMotipoCampoLog && camposMapeados.includes(item.data.nombreCampo) ){ console.log( item.data );
          calogs.push(item);
        }
      }
    }
    this.setState({ camposLogisticos: calogs });
  }
  changeCampoLogistico=(e) =>{
    this.setState({ idCampoLogistico: e });
    this.configuracionClienteConsolidado.idCampoLogistico=  e.value;
  }
  getMovimientoTipoLabel=(id)=>{
    for(let item of this.state.movimientosTipo){ if( item.value === id ){ return item.label; } }
    return "";
  }
  getCampoLogisticoLabel=(id)=>{
    for(let item of this.camposLogisticos){ if( item.value === id ){ return item.label; } }
    return "";    
  }
  validar=()=>{
    let invalido= false;
    this.state.configuracionesClienteConsolidado.map((conf, index)=>{
      if( this.state.idMovimientoTipo.value === conf.idMovimientoTipo || //this.state.idCampoLogistico.value === conf.idCampoLogistico ||
          this.state.idMovimientoTipo.value === 0 || this.state.idCampoLogistico.value === 0){
        invalido= true;
      }
    });
    return invalido;
  }
  saveConfiguracionPlantillaConsolidado=() =>{
    this.getfuntion("post", this.configuracionClienteConsolidado, "", "configuracionClienteConsolidado", "configuracion");
  }
  borrarConfiguracionClienteConsolidado=(idCliente, idmTipo, idCamLog) =>{
    this.getfuntion("delete", "", idCliente+"/"+idmTipo+"/"+idCamLog, "configuracionClienteConsolidado", "configuracion");
  }
  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? <h1> {this.props.elementos.nombre} </h1> : null}
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <Card title="Cliente" isCollapsible isCollapsed={false} body={
                <div>
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Group label="id">
                        <Form.Input name="id" disabled type="number" value={this.state.id} />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="nombre">
                        <Form.Input name="nombre" type="text" value={this.state.nombre} onChange={this.changeNombre} maxlength="40"/>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="nombreCorto">
                        <Form.Input name="nombreCorto" type="text" value={this.state.nombreCorto} onChange={this.changeNombreCorto} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Col md={6} lg={6}>
                      <Form.Group label="Domicilio">
                        <Form.Input name="domicilio" type="text" value={this.state.domicilio} onChange={this.changeDomicilio} maxlength="40" />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Contacto">
                        <Form.Input name="contacto" type="text" value={this.state.contacto} onChange={this.changeContacto} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Telefono">
                        <Form.Input name="telefono" type="text" value={this.state.telefono} onChange={this.changeTelefono} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Col md={6} lg={3}>
                      <Form.Group label="Extension">
                        <Form.Input name="extension" type="text" value={this.state.extension} onChange={this.changeExtension} maxlength="10" />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Latitud">
                        <Form.Input name="latitud" type="text" value={this.state.latitud} onChange={this.changeLatitud} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Longitud">
                        <Form.Input name="longitud" type="text" value={this.state.longitud} onChange={this.changeLongitud} maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Col md={4} lg={4}>
                      <Form.Group label="Nota">
                        <Form.Input name="nota" type="text" value={this.state.nota} onChange={this.changeNota} maxlength="10" />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Vip">
                        <Form.Switch type="Checkbox" name="vip"  checked={this.state.vip} onChange={this.changeVip} />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Estatus">
                        <Form.Switch type="Checkbox" name="toggle" value={this.state.estatus} checked={this.state.estatus} onChange={this.changeEstatus} />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Tipo de plataforma">
                        <Select  isDisabled={false} value={ this.state.idPlataformaTipo }onChange={ this.changePlataformaTipo } options={ this.idPlataformaTipos } />
                      </Form.Group>
                    </Grid.Col>                    
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      { this.props.elementos?
                      <Form.Group label="Para detener envio de consolidado">
                        <ModalLayout tamanio = {"60%"} title= "Campo logistico" name= {
                            <span className="badge"><Button pill size="sm" outline color="primary"><Icon name="edit"/></Button></span>
                          } hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                            formulario= {
                              <div>
                                <Grid.Row>
                                  <Grid.Col sm={5} md={5}>
                                    <Form.Group label="Tipo de movimiento">
                                      <Select
                                        value={this.state.idMovimientoTipo}
                                        onChange={this.changeMovimientoTipo}
                                        options={this.state.movimientosTipo}
                                        //isDisabled={ !this.state.isConfig }
                                      />
                                    </Form.Group>
                                  </Grid.Col>
                                  <Grid.Col sm={5} md={5}>
                                    <Form.Group label="Campo logistico">
                                      <Select
                                        value={this.state.idCampoLogistico}
                                        onChange={this.changeCampoLogistico}
                                        options={ this.state.camposLogisticos } isLoading={this.state.camposLogisticos.length == 0? true:false} 
                                        //isDisabled={ !this.state.isConfig }
                                      />
                                    </Form.Group>
                                  </Grid.Col>
                                  <Grid.Col sm={2} md={2}>
                                    <span className="badge float-left">
                                      <Button pill  size="sm" outline color="primary" onClick={ ()=> this.saveConfiguracionPlantillaConsolidado() } disabled={ this.validar() }>
                                        <Icon name="loader"/>Guardar
                                      </Button>
                                    </span>
                                  </Grid.Col>
                                  </Grid.Row>
                                <Table cards={false} striped={false} responsive={true} className="table-vcenter"
                                  headerItems={ [{content: "Tipo movimiento"}, {content: "Campo logistico"}, {content: "Acción"}]} style={{color:"black", "font-size": "80%"}} >
                                  <Table.Header></Table.Header>
                                  <Table.Body>
                                    { this.state.configuracionesClienteConsolidado.map((conf, index)=>
                                      <Table.Row>
                                        <Table.Col> { this.getMovimientoTipoLabel(conf.idMovimientoTipo) } </Table.Col>
                                        <Table.Col> { this.getCampoLogisticoLabel(conf.idCampoLogistico) }</Table.Col>
                                        <Table.Col>
                                          <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={() => this.borrarConfiguracionClienteConsolidado(conf.idCliente, conf.idMovimientoTipo, conf.idCampoLogistico) } > <span className="badge"><Icon link={true} name="trash" /></span> </Button>
                                        </Table.Col>
                                      </Table.Row>)
                                      }
                                  </Table.Body>
                                </Table>
                              </div>
                            }
                        />
                      </Form.Group>
                      :null}
                    </Grid.Col> 
                    <Grid.Col>
                      <Form.Group label="Id Geocerca (Ingresar como esta en gpsgate)">
                        <Form.Input name="idgeocerca" type="text" value={this.state.idGeocerca} onChange={this.changeIdGeocerca} placeholder="ID-000-KKK" maxlength="20" />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Para envio de consolidado establecer el tiempo">
                        <Form.MaskedInput
                          placeholder="HH:MM:SS"
                          value={this.state.cron}
                          onChange={ this.changeCron }
                          mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Waiting time">
                        <Form.MaskedInput
                          placeholder="HH:MM:SS"
                          value={this.state.wt}
                          onChange={ this.changeW }
                          mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
                        />
                      </Form.Group>
                    </Grid.Col>                                     
                  </Grid.Row>

                </div>}
            />
          </Grid.Col>

        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <div className="float-right">
              {this.isEnable("actualizar" || this.isEnable("agregar")) ?
                <span className="badge">
                  <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable}
                    onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                    <span className="badge"><Icon link={true} name="save" />Guardar</span>
                  </Button>
                </span>
              :null}
              {this.state.accion === "modificar" && this.isEnable("borrar")?
                <span className="badge">
                  <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()}  disabled={!this.state.crudbutonEnable}>
                    <span className="badge"><Icon link={true} name="trash" />Borrar</span>
                  </Button>
                </span>
                :
                null
              }
            </div>
          </Grid.Col>
        </Grid.Row>
        {/**
          *   Elementos de plataformas
          */
        }
      </div>
    )
  }
}

export default Cliente
