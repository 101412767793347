import React, { useState, useEffect, useRef } from 'react'
import { Grid, Card, Table, Form } from "tabler-react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import Dnds from '../../components/dnd/dnd';
import { UnidadesLt } from "../dashboards/UnidadesLtPage.react";

export const GestionarUnidadTransportista = (unidad) => {
  const [unidadDesc, setUnidadDescripcion] = useState([]);
  const [unidadT, setUnidadT] = useState([]);
  const [nombre, setNombre] = useState("todos");
  const [transportistas, setTransportistas] = useState([]);

  useEffect(()=>{
    obtenerUnidad();
    obtenerTransportistasAsignados();
    buscarTransportista(nombre);
  },[])

  useEffect(()=>{
    if (nombre.length > 3) {
      buscarTransportista(nombre);
    }
    if(nombre === ""){ 
      buscarTransportista("todos");
     }
  },[nombre])

  const obtenerUnidad = async () => {
    let desc = [...unidadDesc];
      await getfuntion("get", [], "", "unidadLt/" + unidad.unidad, "unidades_linea_transportista", "").then((dsc) => {
        setUnidadDescripcion(dsc);
    });
  }

  const obtenerTransportistasAsignados = async() => {
    let transAsig = [...unidadT];
        await getfuntion("get", [], "", "transportistas/unidad/" + unidad.unidad, "transportistas", "").then((ut) => {
            ut.forEach(u => {
                transAsig.push(u);
            });
        });
        setUnidadT(transAsig);
  }

  const buscarTransportista = async(nombreTransportista) => {
    let search = [];
    await getfuntion("get", [], "", "transportistas/search/" + nombreTransportista, "transportistas", "").then((name) => {
      name.forEach(n => {
          search.push(n);
      });
    });
    setTransportistas(search);
  }
  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            switch (stateVar) {

              default: return returnVal;
            }
          }
        default: return returnVal;
      }
    })
  }
  //Una vez obtenidos los datos de la base a través del backend generamos los respectivos arreglos de objetos con los datos solicitados por el componente Dnd 
  let transportistasArr = [];
  Object.values(transportistas).map(tp => 
    transportistasArr.push({id: tp.id , texto: tp.nombre})
  )

  let transportistasAsignadoArr = [];
  Object.values(unidadT).map(uat => 
    transportistasAsignadoArr.push({id: uat.id, texto: uat.nombre})
  )

  //eliminar elementos de una lista para no repetir elementos en la otra. En este caso son transportistas y transportistas asignados
  transportistasAsignadoArr.forEach(ta => {
  let pos = transportistasArr.findIndex(t => t.id === ta.id);
  transportistasArr.splice(pos,1);
  });

  //Una vez generados los arreglos de cada lista se asignan en objetos a la variable "columnas". La propiedad "id" en realidad es el encabezado de la columna
  //pero debido a las especificaciones en los componentes de react-beatiful-dnd así debe ir, como texto.
  const columnas = {
    "transportistas asignados": {
      id: 'transportistas asignados',
      list: transportistasAsignadoArr
    },
    transportistas: {
      id: 'transportistas',
      list: transportistasArr
    }
  }
  //El siguiente objeto contiene los id's específicos del catalogo en la base donde se enlazan los items de las listas en las columnas. 
  //Estos contienen los campos del objeto enviado al backend. El componente drag&drop solo soporta 2 columnas para propósitos de la plataforma.
  const catalogoIds = {
    id1: "idUnidad",
    id2: "idTransportista"
  }

  // El arreglo siguiente contiene la configuración de las tablas que van a ser afectadas por las acciones del drag & drop. 
  // La primera posición debe coincidir con el encabezado de la columna.
  // La segunda posición es el API al que se van a dirigir los cambios en la base de datos.
  // La tercera posición es un boolean que define si la columna tendra un efecto en la base como resultado de arrastrar objetos de esta.
  // La cuarta es el nombre del catalogo en el backend.
  // La quinta contiene el identificador del elemento que va a ser afectado por los cambios generados en la columna.
  const tablas = new Array();
  tablas[0] = new Array("transportistas" , "transportistas" , false, "transportistas", "");
  tablas[1] = new Array("transportistas asignados" , "transportistas_unidades/catut" , true, "unidades_linea_transportista", unidad.unidad);

  return(
    <div className="container">
      <Grid.Col sm={12} lg={12} style={{textAlign: "center"}}>
        <label style={{paddingRight: "3%", fontSize: "25px"}}>Unidad: {unidadDesc.unidad}</label>
        <label style={{paddingRight: "3%", fontSize: "25px"}}>Placa: {unidadDesc.placa}</label>
        <label style={{paddingRight: "3%", fontSize: "25px"}}>Estatus: {unidadDesc.estatus}</label>  
      </Grid.Col>
      <br/>
      <Grid.Col>
        <Form.Input name="transportista" type="text" label="Buscar transportista:"
          onKeyUp={(e)=>{
            setNombre(e.target.value)
            }}
        />
      </Grid.Col>
      <br/>
      <Grid.Col>
      <h3 style={{ textAlign: "center" }}>Arrastre los elementos de las listas para asignar o quitar transportistas a la unidad</h3>
        <Dnds col={columnas} tablas={tablas} cat={catalogoIds}/>
      </Grid.Col>
  </div>
  )
}
export default React.memo(GestionarUnidadTransportista);