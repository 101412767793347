import React, { Component } from "react";
import { Button, Form, Icon, Grid, List } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { ArrobaIncidenciaTerrestre } from "./Arroba";
import { NotificationManager } from 'react-notifications';
import ModalLayout from "../../components/ModalLayout.react";
import { ActualizarHorariosTerrestres } from "../../Monitoristas/Monitoreo/panelForms";
import Select from "react-select";
//import DateTimePicker from "react-datetime-picker";
import { timeToDateFill } from "../../herramientas/DateFormat";
var user = JSON.parse(sessionStorage.getItem("usuario"));
class IncidenciasTraxion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incidencias         : [],
            entregas            : [],
            Entrega             : { value: 1, label: "SIN ENTREGA ASIGNADA", indice:0 },
            incidencia          : { value: 0, label: "Elige Tipo de Incidencia", data: null },
            entrega             : this.props.entrega            ||this.props.entrega,

            comentario          : this.props.comentario         ||"",
            latitud             : this.props.latitud            ||"",
            longitud            : this.props.longitud           ||"",

            enviar              : false,
            destinosTransportistas: [],
            destinosMonitoreo   : [],
            accion              : this.props.accion, //'nuevo'// modificar, nuevo
            disabledSave        : true,
            hiddenModals        : false,

            unidad              :"",
            grupos              : [],
            //gerentes            : [],
            cliente             : "",
            ruta                : [],
            inicioRuta          : null,
            destino             : "",
            tracto              : "",
            caja                : "",
            grupo               : { value: null, label: "Seleccione una opción" },
            //gerente             : { value: null, label: "Seleccione una opción" },
            //base                : "",
            responsable         : "",
            zona                : "",
            operativo           : "",
            conclusion          : "",
            accionSuvi          : "",
            controlador         : "",
        };
        this.Entregas =[];
        this.enableSave = this.enableSave.bind(this);
        this.incidenciasTerrestre=[];
        this.acumulador= 1; //1 es de incidencias
        this.incidencias =  [];
        this.usuariosLogObject = {
          "idBitacoraMonitoreo": 0,
          "idUsuario": 0,
          "idMovimiento": 0,
          "idPlataformaTipo": 2,
          "accionFecha": "",
          "descripcion": ""
        };
        this.incidencia={
          "idEntregaIncidencia": null,//
          "idMovimiento": this.props.movimiento? this.props.movimiento.id: null,//
          "idEntrega": null,//
          "incidencia": null,//
          "comentario": "",//
          "latitud": null,//***
          "longitud": null,//**
          "monitorista": user.persona? user.persona : null,
          "cliente": "",//
          "ruta": null,//
          "inicioRuta": null,//
          "origen": this.props.movimiento.origen? this.props.movimiento.origen: "",
          "destino": "",//
          "tracto": "",//
          "caja": "",
          "operador": this.props.movimiento.operador? this.props.movimiento.operador.nombre: "",
          "noEmpleado": this.props.movimiento.operador? this.props.movimiento.operador.noEmpleado? this.props.movimiento.operador.noEmpleado: "": "",
          "grupo": this.props.movimiento.grupo? this.props.movimiento.grupo: "",
          "gerente": this.props.movimiento.gerente? this.props.movimiento.gerente: "",
          "base": this.props.movimiento.base? this.props.movimiento.base: "",
          "responsable": "",
          "zona": "",
          "operativo": "",
          "conclusion": "",
          "accionSuvi": "",
          "controlador": "",
          "semana": new Date().getWeekNumber(),
          "fecha": null
        };
    }
    enableSave = () => {
        if (this.state.comentario.length > 10 && this.state.Entrega.value ) {
          return false;
        } else {
          return true
        }
    }
    componentWillUpdate = ()=>{
    }
    componentWillMount =() =>{
      this.getfuntion("get", "", "", "entregas/movimiento/"+this.props.idmovimiento,"entregas");
      this.getfuntion("get", [], "", "incidencias/idplataforma/"+this.props.idplataforma, "incidenciasAll");
      this.getfuntion("get", "", "", "camposLogisticos/plataforma/"+this.props.idplataforma,"camposLogisticos");

      //this.getfuntion("get", "", "", "traxion_grupos","traxion_grupos");
      //this.getfuntion("get", "", "", "traxion_gerentes","traxion_gerentes");
      this.incidencias.push({ value: 0, label: "Elige Tipo de Incidencia", data: null });
    }
    componentDidMount = () => {
      if (this.props.entregas){
        this.props.entregas.map((entre, index) => {
            this.Entregas.push({ value: entre.idEntrega, label: entre.direccion, data: entre });
            if(entre.estatus === 1){
              this.setState({ Entrega: { value: entre.idEntrega, label: entre.direccion, data: entre } });
              this.incidencia.idEntrega= entre.idEntrega;//Entrega
              this.setState({ cliente: entre.destino });//Destino
              this.incidencia.cliente= entre.destino;
              if(this.props.stor.rutasEstatus){
                this.props.stor.rutasEstatus.map((rut, indice)=>{//ruta
                  if(entre.idRuta){
                    if(entre.idRuta === rut.id ){
                      this.setState({ ruta: rut });
                      this.incidencia.ruta= rut;
                    }
                  }
                });
              }else{
                this.props.stor.rutasEstatus_object.map((rut, indice)=>{//ruta
                  if(entre.idRuta){
                    if(entre.idRuta === rut.id ){
                      this.setState({ ruta: rut });
                      this.incidencia.ruta= rut;
                    }
                  }
                });
              }
              this.setState({ inicioRuta: entre.inicioRuta });//inicio de ruta
              this.incidencia.inicioRuta= entre.inicioRuta;
              this.incidencia.destino= entre.direccion; //destino
            }
        });
      }else{
        this.getfuntion("get", "", "", "entregas/movimiento/" + this.props.idmovimiento, "entregas").then((returnVal) => {
          returnVal.map((entre, index) => {
              this.Entregas.push({ value: entre.idEntrega, label: entre.direccion, data: entre });
              if(entre.estatus === 1){
                this.setState({ Entrega: { value: entre.idEntrega, label: entre.direccion, data: entre } });
                this.incidencia.idEntrega= entre.idEntrega;//Entrega
                this.setState({ cliente: entre.destino });
                this.incidencia.cliente= entre.destino;
                if(this.props.stor.rutasEstatus){
                  this.props.stor.rutasEstatus.map((rut, indice)=>{//ruta
                    if(entre.idRuta){
                      if(entre.idRuta === rut.id ){
                        this.setState({ ruta: rut });
                        this.incidencia.ruta= rut;
                      }
                    }
                  });
                }else{
                  this.props.stor.rutasEstatus_object.map((rut, indice)=>{//ruta
                    if(entre.idRuta){
                      if(entre.idRuta === rut.id ){
                        this.setState({ ruta: rut });
                        this.incidencia.ruta= rut;
                      }
                    }
                  });
                }
                this.setState({ inicioRuta: entre.inicioRuta });//inicio de ruta
                this.incidencia.inicioRuta= entre.inicioRuta;
                this.incidencia.destino= entre.direccion; //destino
              }
          });
        });
      }
      this.setState({ entregas: this.Entregas });

      //uinidad
      let unidadTemp= "";
      if( this.props.unidad ){
        this.props.unidad.map((u,index)=> unidadTemp.length>0 && index < this.props.unidad.length ? unidadTemp= unidadTemp+", "+u.unidad.nombre: unidadTemp= u.unidad.nombre );
        this.setState({tracto: unidadTemp });
        this.incidencia.tracto= unidadTemp; //tracto
        this.setState({ unidad: unidadTemp });
      }else{
        this.getfuntion("get", "", "", "terrestreMovimientoUnidad/movimiento/"+ this.props.idmovimiento, "unidad").then((returnVal) => {
          returnVal.map((u,index)=> unidadTemp.length>0 && index < returnVal.length ? unidadTemp= unidadTemp+", "+u.unidad.nombre: unidadTemp= u.unidad.nombre );
          this.setState({tracto: unidadTemp });
          this.incidencia.tracto= unidadTemp; //tracto
          this.setState({ unidad: unidadTemp });
        });
      }

      if (this.state.accion === 'nuevo') {
        let latitud= "";
        let longitud= "";
        if(this.props.latitud){
          latitud= this.props.latitud;
        }else{
          latitud= this.props.gpsReciente ? this.props.gpsReciente.latitud: "";
          //console.log(this.props.movimiento.movimientoGps);
        }
        if(this.props.longitud){
          longitud= this.props.longitud
        }else{
          longitud= this.props.gpsReciente ? this.props.gpsReciente.longitud:"";
        }
        this.setState({ latitud:  latitud });
        this.setState({ longitud: longitud });
        this.incidencia.latitud= latitud;
        this.incidencia.longitud= longitud;
      } else {
        this.incidencia= this.props.incidencia;
      }
      if(this.props.movimiento){

      }
   }
   hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
   }
   getfuntion = (metodo="get", obj=[], id="", catalogo=""        , stateVar= "",           hiddenModl = "", enviar= false) => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "put":{
            if (stateVar === "entregas") {
              this.hiddenmodal();
              NotificationManager.info(returnVal.destino, "Terrestre, Actualización de Horario ("+returnVal.idEntrega+")");
              for(let item of this.Entregas){
                if( item.idEntrega === returnVal.idEntrega ){
                  item= returnVal;
                }
              }
              this.setState({ entregas: this.Entregas });
            }
          }
          break;
        case "get":
          {
            if (stateVar === "incidenciasAll") {
              returnVal.map((incidencia, index) => {
                if(this.state.accion === "modificar"){ this.setState({incidencia: { value: incidencia.id, label: incidencia.nombre, incidencia: null } }); }
                this.incidencias.push({ value: incidencia.id, label: incidencia.nombre, data: incidencia });
              });
              this.setState({incidencias: this.incidencias});
            }/*
            if (stateVar === "traxion_grupos") {
              let gruposTemp= [];
              returnVal.map((grp, index)=> gruposTemp.push({ value: grp.id, label: grp.grupo+"-"+grp.base+"-"+grp.gerente, data: grp }) );
              this.setState({ grupos: gruposTemp });
            }*/
            /*
            if (stateVar === "traxion_gerentes") {
              let gerentesTemp= [];
              returnVal.map((grt, index)=> gerentesTemp.push({ value: grt.id, label: grt.nombre, data: grt }) );
              this.setState({ gerentes: gerentesTemp });
            }*/
            if (stateVar === "destinosTransportistas"){
              this.setState({ destinosTransportistas: returnVal });
            }
            if (stateVar === "destinatarios_sky_terrestre"){
              this.setState({ destinosMonitoreo: returnVal });
            }
            if (stateVar === "entregas")         {
              if (returnVal) {
                this.setState({ entregas:returnVal });
              }
              return returnVal;
            }
            if(stateVar === "camposLogisticos"){
              this.setState({ camposLogisticos:returnVal });
            }
            if(stateVar === "unidad"){
              return returnVal;
            }
          }
          break;
        case "post":
          {
            if (stateVar === "incidencias_traxion") {
              let destMonitoreo     ="";
              let destTransportistas="";
              this.props.closeModal();
              try {
                this.state.destinosMonitoreo.forEach((key, index)=>{
                  destMonitoreo= destMonitoreo.length > 0 ? destMonitoreo + ", " : destMonitoreo;
                  destMonitoreo= destMonitoreo + key.nombre+" "+key.aPaterno+ " "+ key.aMaterno+"<"+ key.correo+ ">";
                });
              }catch(e){
                console.log(e);
              }
              try {
                this.state.destinosTransportistas.forEach((key, index)=>{
                  destTransportistas= destTransportistas.length > 0 ? destTransportistas + ", " : destTransportistas;
                  destTransportistas=destTransportistas + key.nombre+" "+key.aPaterno+ " "+ key.aMaterno+"<"+ key.correo+ ">";
                });
              }catch(e){
                console.log(e);
              }
              NotificationManager.info(returnVal.comentario, "Terrestre, Incidencia Nueva ("+returnVal.idEntrega+")");
              //console.log( "enviar && returnVal.idEntrega: "+enviar+", "+ returnVal.idEntrega)
              if(enviar && returnVal.idEntrega){
                ArrobaIncidenciaTerrestre(returnVal).then((returnVals) =>{
                  NotificationManager.info(returnVals, "Notificación de incidencia" );
                  this.usuariosLogObject.idBitacoraMonitoreo=6;
                  this.usuariosLogObject.idUsuario=user.id;
                  this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                  this.usuariosLogObject.idPlataformaTipo=2;
                  this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                  this.usuariosLogObject.descripcion = "Arroba Incidencia (" + destMonitoreo + destTransportistas+" ), Detalle: "+ returnVals;
                  Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", "", "");
                });
              }else{
                if(enviar && !returnVal.idEntrega){
                  NotificationManager.error("No se puede enviar notificación", "Incidencia Nueva");
                }
              }
              //this.hiddenmodal();
              this.usuariosLogObject.idBitacoraMonitoreo = 11;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
              this.usuariosLogObject.idPlataformaTipo = 2;
              this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
              this.usuariosLogObject.descripcion = "idIncidencia: "+returnVal.idIncidencia + ", comentario: "+returnVal.comentario;
              Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", "", "");
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err); });
  }

    changeEntregas=(event) =>{
      this.setState({ Entrega: event });
      this.incidencia.idEntrega= event.data.idEntrega;//Entrega
      this.setState({ cliente: event.data.destino });
      this.incidencia.cliente= event.data.destino;
      if(this.props.stor.rutasEstatus){
        this.props.stor.rutasEstatus.map((rut, indice)=>{//ruta
          if(event.data){
            if(event.data.idRuta){
              if(event.data.idRuta === rut.id ){
                this.setState({ ruta: rut });
                this.incidencia.ruta= rut;
              }
            }
          }
        });
      }else{
        this.props.stor.rutasEstatus_object.map((rut, indice)=>{//ruta
          if(event.data){
            if(event.data.idRuta){
              if(event.data.idRuta === rut.id ){
                this.setState({ ruta: rut });
                this.incidencia.ruta= rut;
              }
            }
          }
        });
      }
      this.setState({ inicioRuta: event.data.inicioRuta });//inicio de ruta
      this.incidencia.inicioRuta= event.data.inicioRuta;
      this.incidencia.destino= event.data.direccion; //destino
    }

    crud_post() {
      console.log(this.incidencia);
      this.getfuntion("post",this.incidencia, "", "incidencias_traxion", "incidencias_traxion", this.props.closeModal, this.state.enviar);

    }
    changeIncidencias = (event) =>{
        this.setState({ incidencia: event });
        this.incidencia.incidencia= event.data;
        if(event.data.idEtiqueta){
          this.getfuntion("get", [], "", "personas/destinatarios_transportistas_ter/"+this.props.idmovimiento+"/"+event.data.idEtiqueta, "destinosTransportistas");
          this.getfuntion("get", [], "", "personas/destinatarios_sky_terrestre_incidencias/"+this.props.idmovimiento+"/"+event.data.idEtiqueta, "destinatarios_sky_terrestre");
          this.setState({ enviar: true });
        }else{
          this.setState({ destinosTransportistas: [] });
          this.setState({ destinosMonitoreo: [] });
          this.setState({ enviar: false });
        }
    }
    changeComentario = (event) =>{
      this.incidencia.comentario= event.target.value;
      this.setState({ comentario: event.target.value });
    }
    changeLatitud=(e)=>{
      this.setState({ latitud: e.target.value });
      this.incidencia.latitud= e.target.value;
    }
    changeLongitud=(e)=>{
      this.setState({ longitud: e.target.value });
      this.incidencia.longitud= e.target.value;
    }
    changeTracto=(e)=>{
      this.setState({ tracto: e.target.value });
      this.incidencia.tracto= e.target.value;
    }
    changeCaja=(e)=>{
      this.setState({ caja: e.target.value });
      this.incidencia.caja= e.target.value;
    }/*
    changeGrupo=(e)=>{
      this.setState({ grupo: e });
      this.incidencia.grupo= e.data;
    }

    changeGerente=(e)=>{
      this.setState({ gerente: e });
      this.incidencia.gerente= e.data;
    }
    changeBase=(e)=>{
      this.setState({ base: e.target.value });
      this.incidencia.base= e.target.value;
    }*/
    changeResponsable=(e)=>{
      this.setState({ responsable: e.target.value });
      this.incidencia.responsable= e.target.value;
    }
    changeZona=(e)=>{
      this.setState({ zona: e.target.value });
      this.incidencia.zona= e.target.value;
    }
    changeOperativo=(e)=>{
      this.setState({ operativo: e.target.value });
      this.incidencia.operativo= e.target.value;
    }
    changeConclusion=(e)=>{
      this.setState({ conclusion: e.target.value });
      this.incidencia.conclusion= e.target.value;
    }
    changeAccionSuvi=(e)=>{
      this.setState({ accionSuvi: e.target.value });
      this.incidencia.accionSuvi= e.target.value;
    }
    changeControlador=(e)=>{
      this.setState({ controlador: e.target.value });
      this.incidencia.controlador= e.target.value;
    }
    changeEnviar=(e) =>{
        this.setState({ enviar: e.target.checked });
      }


    render() {
        return (
            <div><h2>Incidencias Traxión <span>, Semana: {new Date().getWeekNumber()}</span></h2>
                {this.state.accion === "modificar" ? <h1> {this.props.incidencia.comentario} </h1> : null}
                <Grid.Row>
                    <Grid.Col md={12} xl={12}>
                        <Grid.Row>

                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Incidencias"><Select value={this.state.incidencia} onChange={this.changeIncidencias} options={this.state.incidencias} /></Form.Group>
                            </Grid.Col>
                            <Grid.Col md={2} lg={2}>
                              <Form.Group label="Latitud">
                                  <Form.Input name="latitud"  value={this.state.latitud} onChange={this.changeLatitud} />
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={2} lg={2}>
                              <Form.Group label="Longitud">
                                <Form.Input name="longitud"  value={this.state.longitud} onChange={this.changeLongitud} />
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={12}>
                                <Form.Group label="Acción Skyangel">
                                    <Form.Textarea name="comentario" type="text" value={this.state.comentario} onChange={this.changeComentario} maxlength="50" />
                                </Form.Group>
                            </Grid.Col>

                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Col>
                            <h4>Monitorista: { user.persona? user.persona.nombre+" "+user.persona.aPaterno+" "+ user.persona.aMaterno: ""}</h4>
                          </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col md={4} lg={4}>
                              <h4><strong>Cliente: </strong> <label>{this.state.Entrega.data? this.state.Entrega.data.destino: ""}</label></h4>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                            <h4><strong>Estatus: </strong> <label>{this.state.ruta.nombre? this.state.ruta.nombre: ""}</label></h4>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                            <h4><strong>Fecha y hora de salida: </strong> <label>{this.state.Entrega.data? this.state.Entrega.data.inicioRuta: ""}</label></h4>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                            <h4><strong>Origen: </strong> <label>{this.props.movimiento.origen? this.props.movimiento.origen: ""}</label></h4>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                            <h4><strong>Destino: </strong> <label>{this.state.Entrega.data? this.state.Entrega.data.direccion: ""}</label></h4>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Tracto">
                                <Form.Input name="tracto"  value={this.state.tracto} onChange={this.changeTracto} />
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Caja">
                                <Form.Input name="caja"  value={this.state.caja} onChange={this.changeCaja } />
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                             <h4><strong>Operador: </strong> <label>{this.props.movimiento.operador? this.props.movimiento.operador.nombre: ""}</label></h4>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              < h4><strong>No. Empleado: </strong> <label>{this.props.movimiento.operador? this.props.movimiento.operador.noEmpleado: ""}</label></h4>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              < h4><strong>Grupo: </strong> <label>{this.props.movimiento.grupo? this.props.movimiento.grupo: ""}</label></h4>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              < h4><strong>Base: </strong> <label>{this.props.movimiento.base? this.props.movimiento.base: ""}</label></h4>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              < h4><strong>Gerente: </strong> <label>{this.props.movimiento.gerente? this.props.movimiento.gerente: ""}</label></h4>
                            </Grid.Col>

                        </Grid.Row>
                        <Grid.Row>{/*
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Grupo"><Select value={this.state.grupo} onChange={this.changeGrupo} options={this.state.grupos} /></Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                            <Form.Group label="Gerente"><Select value={this.state.gerente } onChange={this.changeGerente} options={this.state.gerentes} /></Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Base"> <Form.Input name="base" type="text"    value={this.state.base}         onChange={this.changeBase} /> </Form.Group>
                            </Grid.Col>*/}
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Responsable"> <Form.Input name="respon" type="text"    value={this.state.responsable}         onChange={this.changeResponsable} /> </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Zona"> <Form.Input name="zona" type="text"    value={this.state.zona}         onChange={this.changeZona} /> </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Operativo"> <Form.Input name="operativo" type="text"    value={this.state.operativo}         onChange={this.changeOperativo} /> </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Conclución"> <Form.Input name="conclusion" type="text"    value={this.state.conclusion}         onChange={this.changeConclusion} /> </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Accion Suvi"> <Form.Input name="accionSuvi" type="text"    value={this.state.accionSuvi}         onChange={this.changeAccionSuvi} /> </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={4} lg={4}>
                              <Form.Group label="Controlador"> <Form.Input name="controlador" type="text"    value={this.state.controlador}         onChange={this.changeControlador} /> </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>

                    <Grid.Col md={12}>
              <Form.Group label="Enviar correo">
                {this.estaPrivilegioActivo("arroba_incidencia") ?
                  <Form.Switch type="Checkbox" checked={this.state.enviar} onChange={ this.changeEnviar } />
                :
                <Form.Switch type="Checkbox" checked={false} />
                }
              </Form.Group>
              {
                this.state.destinosTransportistas ?
                <List.Group>
                <h3>{this.state.destinosTransportistas.length > 0? "Transportista": null }</h3>
                { this.state.destinosTransportistas.map((transp, index) =>
                    <List.GroupItem action icon="rss">
                      <div className="row">
                        <div className="col-5"> <Icon name="user"/>{ transp.nombre+" "+transp.aPaterno+ " "+ transp.aMaterno } </div>
                        <div className="col-5"> <Icon name="mail"/>{ transp.correo } </div>
                      </div>
                    </List.GroupItem>
                  )
                }
                </List.Group>
                :
                null
              }
              {
                this.state.destinosMonitoreo ?
                <List.Group>
                <h3>{this.state.destinosMonitoreo.length > 0? "Monitoreo": null }</h3>
                {
                  this.state.destinosMonitoreo.map((moni, index) =>
                  <List.GroupItem action icon="rss">
                      <div className="row">
                        <div className="col-5"> <Icon name="user"/>{ moni.nombre+" "+moni.aPaterno+ " "+ moni.aMaterno } </div>
                        <div className="col-5"> <Icon name="mail"/>{ moni.correo } </div>
                      </div>
                  </List.GroupItem>
                  )
                }
                </List.Group>
                :
                null
              }
              {
                this.state.enviar?
                this.state.destinosTransportistas.length> 0 || this.state.destinosMonitoreo.length > 0 ? null
                :<List.GroupItem action icon="rss"> Al parecer no se ha configurado la plantilla, o no hay destinatarios, revise su configuración </List.GroupItem>
                :null
              }
            </Grid.Col>
                </Grid.Row>
                  <span className="float-left" style={{ width: "300px" }}>
                    <Form.Group label="Entrega">
                      <Select value={this.state.Entrega} onChange={this.changeEntregas} options={this.Entregas} />
                    </Form.Group>
                  </span>
                  { this.state.Entrega.data?
                    <span className="float-left">
                      <Form.Group label="Actualizar Horarios">
                        <ModalLayout
                          tamanio={"40%"}
                          title="Actualizar horario"
                          name={<Button pill size="sm" outline color="primary"><Icon name="upload" /><Icon name="clock" /></Button>}
                          hiddenModal={this.state.hiddenModals}
                          Fclose={this.hiddenmodal}
                          formulario={<ActualizarHorariosTerrestres
                            accion={'modificar'}
                            idusuario={user.id}
                            movimiento={this.props.movimiento}
                            idEntregaActiva={this.state.Entrega.data}
                            getfuntion={this.getfuntion}
                            stor={this.props.stor}
                            rutasEstatusObject={this.props.stor.rutasEstatus_object}
                            operacionesEstatus={this.props.stor.operacionesEstatus_object}
                            closeModal={this.hiddenmodal}
                          />}
                        />
                      </Form.Group>
                    </span>
                  :null}


                <div className="float-right">
                    <span className="badge">
                      {this.props.desde!='AlertasListar'?
                        <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave()} onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                          <span className="badge"><Icon link={true} name="save" />Guardar Incidencia</span>
                        </Button>:<div></div>
                      }
                    </span>
                  {
                    this.state.accion==="modificar"?
                    <span className="badge">
                      <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()} >
                        <span className="badge"><Icon link={true} name="trash" />Borrar</span>
                      </Button>
                    </span>
                    :null
                  }
                </div>
            </div>
        );
    }
     /**
     * Privilegio activo
     */
    estaPrivilegioActivo = (clave) => {
      //console.log(clave);
      let privilegios="";//this.props.stor.privilegios_object;
      if(this.props.stor.privilegios_object){
        privilegios= this.props.stor.privilegios_object;
      }else{
        privilegios= this.props.stor.stor.privilegios_object;
      }
      var perfil = JSON.parse(sessionStorage.getItem("perfil"));
      for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
          if (perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0) { this.privilegio=privilegios[i]; return true } else { return false }
          break;
      }
      }
  }
}
export default IncidenciasTraxion;
