import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import Select from "react-select";
import { Exportar } from '../../herramientas/Excel';
import { timeToDateFill, timeToDatefull } from "../../herramientas/DateFormat";

var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
Date.prototype.getWeekNumber = function(){
  var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
};
//case "monitorista":
//dataTemp[index][key]= "" + filas[key].nombre + " " + filas[key].aPaterno + " " + filas[key].aMaterno;
//break;

class IncidenciasTraxionExportar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementos: [],
      fecha1: timeToDatefull( new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*7)).getTime() ),
      fecha2: timeToDatefull(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() ),
      encabezados: []
    };
    this.plataformas=[];
  }
  componentDidMount = () => {      
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "navieraMovimientos", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "incidencias" && returnVal) {//12929
                //this.setState({ elementos: this.returnVal });
                let encabezadosTemp=[];
                Object.keys(returnVal[0]).forEach(key => encabezadosTemp.push({ content: key }) );
                this.setState({encabezados: encabezadosTemp});

                var dataTemp= [];
                returnVal.map((filas, index) => {
                    Object.keys(filas).forEach(key =>{
                        if(!dataTemp[index]){
                          dataTemp[index]={}
                        }
                        if(filas[key] !== null){
                          if ( typeof filas[key] === 'object'){
                            //console.log(filas[key]+"=>> "+ key+"("+typeof filas[key]+")")
                            switch (key) {
                              case "monitorista":
                                dataTemp[index][key]= "" + filas[key].nombre + " " + filas[key].aPaterno + " " + filas[key].aMaterno;
                                break;
                              case "movimientoGps":
                                filas[key].map((gps, indice)=> dataTemp[index]["gps-"+(indice+1)]= gps.gps.nombre);
                                break;
                              case "incidencias":
                                //dataTemp[index]["No incidencias"]= filas.incidencias.length;
                                //filas[key].map((inc, indice)=> dataTemp[index]["incidencia-"+(indice+1)]= inc.fechaCreado+": "+inc.incidencia.nombre+", tipo: "+inc.incidencia.incidenciaTipo.nombre+", comentario:"+inc.comentario );
                                //dataTemp[index][key]= filas[key];
                                break;
                              case "idCliente":
                                dataTemp[index][key]= filas[key].nombre;
                                dataTemp[index]["Dirección cliente"]= filas[key].domicilio;
                                break;
                              default:
                                  if ((filas[key].nombre!="")&&(filas[key].nombre!=undefined)) {
                                    dataTemp[index][key]=filas[key].nombre;
                                  }else{
                                    if ((filas[key].id!="")&&(filas[key].id!=undefined)) {
                                      dataTemp[index][key]=filas[key].id;
                                    }else{
                                      dataTemp[index][key]="Vari(@)s ";
                                    }
                                  }
                            }
                          }else{
                            dataTemp[index][key]=filas[key];
                          }
                        }else{
                          dataTemp[index][key]="";
                        }
                    });
                });
                this.setState({ elementos: dataTemp });
            }           
          }
          break;
        default:
          break;
      }
    }).catch(err => { alert(" Ha ocurrido un error precione en actualizar nuevamente"); if (err.response) {console.log(err.response);} else { console.log(err); } });
  }
  buscar=()=>{
    this.setState({ elementos: [] });
    this.getfuntion("get", "", "", "incidencias_traxion/usuario/"+currentUser[0].usuario.id+"/fecha1/"+this.state.fecha1+" 00:00:00/fecha2/"+this.state.fecha2+" 23:59:59", "incidencias");  
  }
  onChangeFecha1    = (date)  =>   this.setState({ fecha1: date.target.value });
  onChangeFecha2    = (date)  =>   this.setState({ fecha2: date.target.value });

  render() {
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        <Card>
          <Card.Header>
            <Card.Options>
              <span className="float-right ml-2" style={{ "width": "35%" }}>
                <input type="date" id="start" name="trip-start" value={ this.state.fecha1 } onChange={ this.onChangeFecha1 }></input>
              </span>
              <span className="float-right ml-2"> - </span>
              <span className="float-right ml-2" style={{ "width": "35%" }}>
                <input type="date" id="start" name="trip-start" value={ this.state.fecha2 } onChange={ this.onChangeFecha2 }></input>
              </span>
              <span className="float-right ml-2" style={{ "width": "25%" }}>
                <Button pill size="sm" outline color="primary" onClick={ ()=> this.buscar() } ><Icon name="refresh-cw" /></Button>
              </span>              
              <span className="float-right ml-2" style={{ "width": "25%" }} hidden={this.state.elementos.length === 0 }>
                <Exportar crud={this.getfuntion} catalogo={"Movimientos Terminados"} data={this.state.elementos} />
              </span>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <h3>Reporte de incidencias</h3>
              <div className="progress">              
              <div className="progress-bar" role="progressbar" style={{"width": ((100/this.state.totalElements )* this.state.elementos.length)+"%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{Math.round((100/this.state.totalElements )* this.state.elementos.length)+"% - "+this.state.elementos.length}</div>
            </div> 
            <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-light"
              headerItems={this.state.encabezados}
            >
              <Table.Body>
                {this.state.elementos.map((elemento, index) =>
                    <Table.Row>
                      { this.state.encabezados.map((col, inc2)=> <Table.Col>{elemento[col.content]}</Table.Col> ) }                      
                    </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Card.Body>
          <Card.Footer>
          </Card.Footer>
        </Card>
      </div>
    )
  }
}
export default IncidenciasTraxionExportar
