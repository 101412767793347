
import React, { Component } from "react";
import * as conf from "../config/config";
import SockJsClient from 'react-stomp';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from 'axios';
import Show from "./Show";

let instance = axios.create();
const username= 'WN8z55WbAzF?bgy!BW5S9VB9$N#$fmUPx!fDf3=ZJft3mY3c@b';
const password= 'DzuJVh%k&Msvr@=k2a?zZz#4CuZ55awXSYkVm?_a#+a@xQ!pDn';
var user= JSON.parse(sessionStorage.getItem("usuario"));
var cat= {};
var movimientos= {};
class PanelMensajeria extends Component {
  constructor(props) {
    super(props);
    this.state = {
        udateM: true
    }
    this.mensaje=[];
    this.postOrigin= false;
    this.logged= false;
  }
  componentWillMount =() =>{
    instance.defaults.headers.common['Authorization'] = 'Basic '+window.btoa(username+":"+password);
    axios.get(conf.socket+"basicauth").then((response)=>{}).catch(err => {
      console.log("Error: " + err);
      axios.get(conf.socket+"basicauth").then((response)=>{}).catch(err => {
        console.log("Error: " + err);
      });
    });
  }
  componentDidMount =() => {

  }

  componentDidUpdate =() =>{
      if(this.props.metodo !== "get"  && this.props.stor.updateStatusMensaje_Object && this.props.mensaje.data ){
        console.log(this.props.mensaje);
        this.sendMessage(JSON.stringify(this.props.mensaje), this.props.mensaje.data)
      }
  }
  sendMessage = (msg, data) => {
    this.props.stor.updateStatusMensaje(false);
    this.postOrigin= true;
      this.clientRef.sendMessage("/topic/"+data.idMovimiento, msg);
  }

  udate=(mensaje) => {
    document.getElementById('alerta12').play();
    /**
     * "sistema"
     */
    if(mensaje.vista == "sistema"){
      if(mensaje.data){
        if(mensaje.data.refresh){
          let randomN= Math.floor(Math.random() * (mensaje.data.refresh - 10)) + 10;
          setTimeout(function() {
            window.location.reload();
          }, (randomN*1000) );
          NotificationManager.warning(mensaje.mensaje+ randomN+" Seg.", "Sistemas" );
        }
      }
    }
    /**
     *  Alertas Terrestres
     */
    if(mensaje.vista == "terrestresAlertas" ){
      cat= this.props.categorias? this.props.categorias: {} ;
      var stop= false;
      var alertasT= this.props.elementosT? this.props.elementosT: [];
      var alertCat= this.props.alertasTCat;
      var alertCorr= this.props.alertasTCorr;
      if( Object.keys(alertasT).length && mensaje.accion !== "post" ){
        Object.keys( alertasT ).forEach(
          (key, indice1) =>{
              Object.keys( alertasT[key] ).forEach(
                (key2, indice2) =>{
                  movimientos= {};
                  if(this.props.movimientosT){
                    this.props.movimientosT.map((mov, index) =>{
                      if(movimientos[mov.id]){
                        movimientos[mov.id].push(mov);
                      }else{
                        movimientos[mov.id]= [];
                        movimientos[mov.id]= mov;
                      }
                    });
                  }
                  if(alertasT[key][key2].length){
                    alertasT[key][key2].map((alerta, index) =>{
                      if (mensaje.accion === "put" && alerta.alert.id === mensaje.data.id){
                        alertasT[key][key2][index].alert= mensaje.data;
                        //categorias
                        if( alertCat[alertasT[key][key2][index].tipo] ){
                          for( let item of alertCat[alertasT[key][key2][index].tipo]){
                            if(item.alert.id === mensaje.data.id){
                              item.alert= mensaje.data;
                            }
                          }
                        }
                        //corredores
                        if(movimientos[mensaje.data.idMovimiento]){
                          for( let item of alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre]){
                            if(item.alert.id === mensaje.data.id){
                              item.alert= mensaje.data;
                            }
                          }
                        }
                        this.props.updateT(alertasT, alertCat, alertCorr);
                        return true;
                      }
                      if(mensaje.accion === "delete" && alerta.alert.id === mensaje.data.id){
                        alertasT[key][key2][index].alert= mensaje.data
                        if( alertCat[alertasT[key][key2][index].tipo] ){
                        for( let item of alertCat[alertasT[key][key2][index].tipo]){
                          if(item.alert.id === mensaje.data.id){
                            item.alert= mensaje.data;
                          }
                        }
                      }
                      //corredores
                      if(movimientos[mensaje.data.idMovimiento]){
                        for( let item of alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre]){
                          if(item.alert.id === mensaje.data.id){
                            item.alert= mensaje.data;
                          }
                        }
                      }
                      this.props.updateT(alertasT, alertCat, alertCorr);
                      }
                      if( mensaje.accion === "post" ){
                        if( cat[movimientos[key].plataforma.id] ){
                          if( cat[movimientos[key].plataforma.id][mensaje.data.idAlertaTipo] ){
                            if (key == mensaje.data.idMovimiento && key2 == cat[movimientos[key].plataforma.id][mensaje.data.idAlertaTipo].alertaCategoria.logo){
                              if( !alertasT[key] ){ alertasT[key]= {}; }
                              if(!alertasT[key][key2]){ alertasT[key][key2]= []; }
                                alertasT[key][key2].push({
                                  alert: mensaje.data,
                                  color: cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.color,
                                  tipo: cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre,
                                  icono: key2,
                                  tiempo: cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.tiempoAtencion
                                });
                                //categorias
                                if( !alertCat[cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre] ){
                                  alertCat[cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre]= [];
                                }
                                alertCat[cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre].push({
                                  alert: mensaje.data,
                                  color: cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.color,
                                  logo:  cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.logo,
                                  tiempo:cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.tiempoAtencion
                                  });
                                //corredores
                                if( !alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre] ){
                                  alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre]= [];
                                }
                                alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre].push({
                                  alert: mensaje.data,
                                  color: cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.color,
                                  logo:  cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.logo,
                                  tiempo:cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.tiempoAtencion
                                });
                                
                                this.props.updateT(alertasT, alertCat, alertCorr);
                              this.postOrigin= true;
                            }else{ }
                          }else{
                            if(alertasT[mensaje.data.idMovimiento]){
                              if(!alertasT[mensaje.data.idMovimiento]["fa-bell"]){ alertasT[mensaje.data.idMovimiento]["fa-bell"]= []; }
                            }else{
                              alertasT[mensaje.data.idMovimiento]={};
                              alertasT[mensaje.data.idMovimiento]["fa-bell"]= [];
                            }

                            
                            //if(!alertasT[mensaje.data.idMovimiento]["fa-bell"]){ alertasT[mensaje.data.idMovimiento]["fa-bell"]= []; }
                            if(!stop){
                              stop= true;
                              alertasT[mensaje.data.idMovimiento]["fa-bell"].push({alert: mensaje.data, color: "blue", tipo: "Desconocido", icono: "fa-bell", tiempo: "00:00:00" });
                              //categorias
                              if( !alertCat["Desconocido"] ){
                                alertCat["Desconocido"]= [];
                              }
                              alertCat["Desconocido"].push({alert: mensaje.data, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                              this.props.update(alertasT, alertCat);
                              //Corredores
                              if( !alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre] ){
                                alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre]= [];
                              }
                              alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre].push({alert: mensaje.data, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                              
                              this.props.updateT(alertasT, alertCat, alertCorr);
                            }
                          }
                        }else{
                          if(alertasT[mensaje.data.idMovimiento]){
                            if(!alertasT[mensaje.data.idMovimiento]["fa-bell"]){ alertasT[mensaje.data.idMovimiento]["fa-bell"]= []; }
                          }else{
                            alertasT[mensaje.data.idMovimiento]={};
                            alertasT[mensaje.data.idMovimiento]["fa-bell"]= [];
                          }
                          if(!stop){
                            stop= true;
                            alertasT[mensaje.data.idMovimiento]["fa-bell"].push({alert: mensaje.data, color: "blue", tipo: "Desconocido", icono: "fa-bell", tiempo: "00:00:00" });
                            if( !alertCat["Desconocido"] ){
                              alertCat["Desconocido"]= [];
                            }
                            alertCat["Desconocido"].push({alert: mensaje.data, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                            //Corredores
                            if( !alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre] ){
                              alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre]= [];
                            }
                            alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre].push({alert: mensaje.data, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
                            
                            this.props.updateT(alertasT, alertCat, alertCorr);
                          }
                        }

                      }
                    });
                  }else{//Despues de atender todas las alertas
                    if( mensaje.accion === "post" ){
                      if (key == mensaje.data.idMovimiento && key2 == cat[movimientos[key].plataforma.id][mensaje.data.idAlertaTipo].alertaCategoria.logo){
                        alertasT[key][key2].push({  alert: mensaje.data,
                                                    color: cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.color,
                                                    tipo: cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre,
                                                    icono: key2,
                                                    tiempo: cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.tiempoAtencion
                                                  });
                        //categorias
                        if( !alertCat[cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre] ){
                          alertCat[cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre]= [];
                        }
                        alertCat[cat[ movimientos[key].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre].push({
                          alert: mensaje.data,
                          color: cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.color,
                          logo:  cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.logo,
                          tiempo:cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.tiempoAtencion
                          });
                        //corredores
                        if( !alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre] ){
                          alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre]= [];
                        }
                        alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre].push({
                          alert: mensaje.data,
                          color: cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.color,
                          logo:  cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.logo,
                          tiempo:cat[ movimientos[key].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.tiempoAtencion
                        });
                        
                        this.props.updateT(alertasT, alertCat, alertCorr);
                      }
                    }
                  }
                });

          });
      }else{
        movimientos= {};
        if(this.props.movimientosT){
          this.props.movimientosT.map((mov, index) =>{
            if(movimientos[mov.id]){
              movimientos[mov.id].push(mov);
            }else{
              movimientos[mov.id]= [];
              movimientos[mov.id]= mov;
            }
          });
        }
        if( mensaje.accion === "post" ){
          alertasT[mensaje.data.idMovimiento]= {};
          if( cat[movimientos[mensaje.data.idMovimiento].plataforma.id] ){
            if( cat[movimientos[mensaje.data.idMovimiento].plataforma.id][mensaje.data.idAlertaTipo] ){
              alertasT[mensaje.data.idMovimiento][ cat[movimientos[mensaje.data.idMovimiento].plataforma.id][mensaje.data.idAlertaTipo].alertaCategoria.logo ]= [];
              alertasT[mensaje.data.idMovimiento][ cat[movimientos[mensaje.data.idMovimiento].plataforma.id][mensaje.data.idAlertaTipo].alertaCategoria.logo ].push({  alert: mensaje.data,
                                                                color: cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.color,
                                                                tipo: cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre,
                                                                icono: cat[movimientos[mensaje.data.idMovimiento].plataforma.id][mensaje.data.idAlertaTipo].alertaCategoria.logo,
                                                                tiempo: cat[movimientos[mensaje.data.idMovimiento].plataforma.id][mensaje.data.idAlertaTipo].alertaCategoria.tiempoAtencion
                                                              });
              if( !alertCat[cat[movimientos[mensaje.data.idMovimiento].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre] ){
                alertCat[cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre]= [];
              }
              alertCat[cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][ mensaje.data.idAlertaTipo].alertaCategoria.nombre].push({
                alert: mensaje.data,
                color: cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.color,
                logo:  cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.logo,
                tiempo:cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.tiempoAtencion
                });
                //corredores
                if( !alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre] ){
                  alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre]= [];
                }
                alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre].push({
                  alert: mensaje.data,
                  color: cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.color,
                  logo:  cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.logo,
                  tiempo:cat[ movimientos[mensaje.data.idMovimiento].plataforma.id ][mensaje.data.idAlertaTipo].alertaCategoria.tiempoAtencion
                });
                
                this.props.updateT(alertasT, alertCat, alertCorr);
            }else{
              alertasT[mensaje.data.idMovimiento]["fa-bell"]= [];
              alertasT[mensaje.data.idMovimiento]["fa-bell"].push({alert: mensaje.data, color: "blue", tipo: "Desconocido", icono: "fa-bell", tiempo: "00:00:00" });
              if( !alertCat["Desconocido"] ){
                alertCat["Desconocido"]= [];
              }
              alertCat["Desconocido"].push({alert: mensaje.data, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
              //Corredores
              if( !alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre] ){
                alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre]= [];
              }
              alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre].push({alert: mensaje.data, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
              
              this.props.updateT(alertasT, alertCat, alertCorr);
            }
          }else{
            alertasT[mensaje.data.idMovimiento]["fa-bell"]= [];
            alertasT[mensaje.data.idMovimiento]["fa-bell"].push({alert: mensaje.data, color: "blue", tipo: "Desconocido", icono: "fa-bell", tiempo: "00:00:00" });
            if( !alertCat["Desconocido"] ){
              alertCat["Desconocido"]= [];
            }
            alertCat["Desconocido"].push({alert: mensaje.data, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
            //Corredores
            if( !alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre] ){
              alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre]= [];
            }
            alertCorr[movimientos[mensaje.data.idMovimiento].corredor.nombre].push({alert: mensaje.data, color: "blue", logo: "fa-bell", tiempo: '00:00:00' });
            
            this.props.updateT(alertasT, alertCat, alertCorr);
          }
        }
      }
      //this.props.updateT(alertasT);
      this.postOrigin= false;
    }
    /**
     *  Acumuladores Etas
     */
    if(mensaje.vista == "movimientoAcumuladores" && this.props.navieras ){
      if (mensaje.accion == "post"){
        let acumuladoresTemp= this.props.etasN;
        if( !acumuladoresTemp[mensaje.data.idMovimiento] ){ acumuladoresTemp[mensaje.data.idMovimiento]={} }
        acumuladoresTemp[mensaje.data.idMovimiento][mensaje.data.idAcumulador.id]= mensaje.data;
        if( this.props.updateEtas !== "" ){ this.props.updateEtas(acumuladoresTemp); }
      }
      this.postOrigin= false;
    }
  }
  shouldSendMessage =(ban) => {
    this.setState({ udateM: ban });
  }

  eServidor = () => {
    axios.get(conf.socket+"basicauth").then((response)=>{
      var request = new XMLHttpRequest();
      request.open('GET', conf.socket+'suscribe', true);
      request.send();
      //console.log(request.status);
      if (request.status == 0) {
        console.log("No Esta disponible el Servidor de Mensajes");
        return false;
      }else{
        return true;
      }

    }).catch(err => {
      console.log("Error: " + err);
    });

  }

  render() {
    return (
      <div>
        <SockJsClient
            url={ conf.socket+'suscribe/' }
            topics={this.props.topic}
            onMessage={(msg) => {
              this.udate(msg);
              if(msg.vista !== "sistema"){/*
                NotificationManager.info(msg.mensaje,
                  msg.accion === "put"?"Modificación":
                  msg.accion === "post" ? "Nuevo":
                  msg.accion === "delete" ? "Alerta id:"+ msg.data.id +" Atendida":"Acción", 200);*/
              }
            }}
            ref={ (client) => { this.clientRef = client }}/>
    </div>
    );
  }
}
export default PanelMensajeria;
