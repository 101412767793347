import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card, Dimmer } from "tabler-react";
import { timingSafeEqual } from "crypto";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { Tab } from "react-tabs";
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';
let puntero= 0; //Puntero etiquetar todos
class  EtiquetadoMasivo extends Component {
  constructor(props) {
    super(props);
    this.state = { 
                      id: "",
                  accion: this.props.accion, //'nuevo'// modificar, nuevo
               etiquetas: [],
             etiquetando: false,
                  search: "",
       transportistasEtq: {},
             clientesEtq: {},
             destinatEtq: {},
             destinatarios: [],
    destinatariosCatalogos: {},
                  headers:[]
                 };
    this.plataformaCorreo=[];
    this.valid={user: false, password: false, correo: false};
  }

  obtenercolumnaNombre=(dest)=>{
    return  <div className="bg-light">
                        <span className="badge text-primary"
                              onClick= { !this.state.etiquetando? () =>{
                                            switch (dest.idTipoConfiguracion) {
                                              case 1:{
                                                        if(this.state.destinatariosCatalogos[dest.idDestinatario]){
                                                          if(this.state.destinatariosCatalogos[dest.idDestinatario].idCatalogo == 1){
                                                              return  this.etiquetadoMasivoTodos("clientes");
                                                          }
                                                          if(this.state.destinatariosCatalogos[dest.idDestinatario].idCatalogo == 2){
                                                            return  this.etiquetadoMasivoTodos("transportistas");
                                                          }
                                                        }
                                                }break;
                                              case 2: return  this.etiquetadoMasivoTodos("especial", dest);
                                              case 3: return  this.etiquetadoMasivoTodos("manual", dest);
                                              default:
                                                break;
                                            }
                                        }: console.log("")
                               }
                              data-toggle="tooltip" data-placement="top" title={"Etiquetar todos los contactos de "+dest.nombre+", con todas las etiquetas"}
                        >
                          <h5><Icon name="tag"/></h5>
                        </span>
                        <span className="badge ml-2 text-danger"
                              onClick= { !this.state.etiquetando? () =>{
                                                switch (dest.idTipoConfiguracion) {
                                                  case 1:{
                                                            if(this.state.destinatariosCatalogos[dest.idDestinatario]){
                                                              if(this.state.destinatariosCatalogos[dest.idDestinatario].idCatalogo == 1){
                                                                  return  this.etiquetadoMasivoRemoverTodos("clientes");
                                                              }
                                                              if(this.state.destinatariosCatalogos[dest.idDestinatario].idCatalogo == 2){
                                                                return  this.etiquetadoMasivoRemoverTodos("transportistas");
                                                              }
                                                            }
                                                    }break;
                                                  case 2: return  this.etiquetadoMasivoRemoverTodos("especial", dest);
                                                  case 3: return  this.etiquetadoMasivoRemoverTodos("manual", dest);
                                                  default:
                                                    break;
                                                }
                                            }: console.log("")
                                    }
                              data-toggle="tooltip" data-placement="top" title={"Quitar todas las etiqueta de todos los contactos de "+dest.nombre }
                        >
                          <h5><Icon name="trash"/></h5>
                        </span>{dest.nombre}
                      </div>;                      
  }
  etiquetadoMasivoTodos =(destinatario, dest)=>{
    if( this.state.etiquetas.length > 0 && !this.state.etiquetando){
      this.setState({ etiquetando: true });
      this.etiquetadoMasivoTodosA(destinatario, dest);   
    }else{
      alert("Ocupado, espere porfavor");
    }
  }
  etiquetadoMasivoTodosA =(destinatario, dest)=>{     
    this.etiquetadoMasivo( destinatario, this.state.etiquetas[puntero],  dest).then((returnVal) => {
      puntero++;        
      if( puntero < this.state.etiquetas.length ){ 
        this.etiquetadoMasivoTodosA(destinatario, dest);
      }else{
        puntero= 0;
        this.setState({ etiquetando: false });
      }        
    });
  }
  etiquetadoMasivoRemoverTodos =(destinatario, dest)=>{
    if( this.state.etiquetas.length > 0 && !this.state.etiquetando){
      this.setState({ etiquetando: true });
      this.etiquetadoMasivoRemoverTodosA(destinatario, dest);   
    }else{
      alert("Ocupado, espere porfavor");
    }
  }
  etiquetadoMasivoRemoverTodosA =(destinatario, dest)=>{
    this.etiquetadoMasivoRemover( destinatario, this.state.etiquetas[puntero],  dest).then((returnVal) => {
      puntero++;
      if( puntero < this.state.etiquetas.length ){
        this.etiquetadoMasivoRemoverTodosA(destinatario, dest);
      }else{
        puntero= 0;
        this.setState({ etiquetando: false });
      }       
    });
  }

  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if( stateVar === "etiquetas" ){
                if( returnVal[0] ){
                  let tagsTem=[]
                  for(let item of returnVal){
                    if( item.nombre !== "Arroba Incidencia" ){ tagsTem.push(item); }
                  }
                  this.setState({ etiquetas: tagsTem }); 
                }
              }
              if( stateVar === "destinatarios" ){
                if( returnVal[0] ){
                  let headers= [];
                  headers.push({ content: "etiqueta" });
                  for(let item of returnVal){
                    headers.push({ content: this.obtenercolumnaNombre(item) });
                  }
                  this.setState({ headers: headers });
                  this.setState({ destinatarios: returnVal }); 
                }
              }
              if( stateVar === "destinatariosCatalogos" ){
                if( returnVal[0] ){
                  let destCat= {};
                  for(let item of returnVal){
                    destCat[item.idDestinatario]= item;
                  }
                  this.setState({ destinatariosCatalogos: destCat });
                }
              }         
              if(stateVar == "contarTransportistas"){
                if(returnVal){
                  let etiquetasTemp={};
                  for(let item of returnVal){
                    etiquetasTemp[item['id_etiqueta']]= item;
                  }
                  this.setState({transportistasEtq: etiquetasTemp});
                }else{
                  this.setState({transportistasEtq: {} });
                }
              }
              if(stateVar == "contarClientes"){
                if(returnVal){
                  let etiquetasTemp={};
                  for(let item of returnVal){
                    etiquetasTemp[item['id_etiqueta']]= item;
                  }
                  this.setState({clientesEtq: etiquetasTemp});
                }else{
                  this.setState({clientesEtq: {} });
                }
              }
              if(stateVar == "contarDestinatarios"){
                if(returnVal){
                  let etiquetasTemp={};
                  for(let item of returnVal){
                    if( !etiquetasTemp[item['id_etiqueta']] ){
                      etiquetasTemp[item['id_etiqueta']]= {};
                      etiquetasTemp[item['id_etiqueta']][item['id_destinatario']]= item;
                    }else{
                        etiquetasTemp[item['id_etiqueta']][item['id_destinatario']]= item;
                    }
                  }
                  this.setState({destinatEtq: etiquetasTemp});
                }else{
                  this.setState({destinatEtq: {} });
                }
              }              
            }
            break;
          case "post":
            {
              if(stateVar =="etiquetado_masivo_transportistas"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "transportistasEtiquetas/contar/"+this.props.elementos.id,"contarTransportistas");
                if( !this.state.etiquetando ){
                  NotificationManager.info("Se han etiquetado todos los contactos de transportistas en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
                }                
                return returnVal;
              }
              if(stateVar == "etiquetado_masivo_clientes"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "clientesEtiquetas/contar/"+this.props.elementos.id,"contarClientes");
                if( !this.state.etiquetando ){
                  NotificationManager.info("Se han etiquetado todos los contactos de clientes en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
                }
                return returnVal;
              }
              if(stateVar == "etiquetado_masivo_destinatario"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "destinatarios/contar_etiquetas/"+this.props.elementos.id,"contarDestinatarios");
                if( !this.state.etiquetando ){
                  NotificationManager.info("Se han etiquetado todos los contactos en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
                }
                return returnVal;
              }              
            }
            break;
          case "put":
            {
            }
            break;
          case "delete":
            {
              if(stateVar =="etiquetado_masivo_transportistas"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "transportistasEtiquetas/contar/"+this.props.elementos.id,"contarTransportistas");
                if( !this.state.etiquetando ){
                  NotificationManager.info("Se han removido las etiquetas de todos los contactos de transportistas en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
                }
                return returnVal;
              }
              if(stateVar == "etiquetado_masivo_clientes"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "clientesEtiquetas/contar/"+this.props.elementos.id,"contarClientes");
                if( !this.state.etiquetando ){
                  NotificationManager.info("Se han removido las etiquetas de todos los contactos de clientes en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
                }
                return returnVal;
              }
              if(stateVar == "etiquetado_masivo_destinatario"){ //personasEtiquetas/etiquetado_masivo_transportistas/plataforma/2/etiqueta/2
                this.getfuntion("get", "", "", "destinatarios/contar_etiquetas/"+this.props.elementos.id,"contarDestinatarios");
                if( !this.state.etiquetando ){
                  NotificationManager.info("Se han removido las etiquetas de todos los contactos en esta plataforma con la etiqueta: "+obj.nombre, "Guardado");
                }
                return returnVal;
              }
            }
              break;
          default:
            break;
        }
      }).catch(err =>{ console.log(err); });
  }
  componentDidMount =() => {    
    if(this.state.accion !== 'nuevo'){
      this.getfuntion("get", "", "", "destinatarios/idplataforma/"+this.props.elementos.id,"destinatarios");
      this.getfuntion("get", "", "", "destinatarios_catalogos/idplataforma/"+this.props.elementos.id,"destinatariosCatalogos");
      this.getfuntion("get", "", "", "etiquetas","etiquetas");
      this.getfuntion("get", "", "", "transportistasEtiquetas/contar/"+this.props.elementos.id,"contarTransportistas");
      //this.getfuntion("get", "", "", "clientesEtiquetas/contar/"+this.props.elementos.id,"contarClientes");
      this.getfuntion("get", "", "", "destinatarios/contar_etiquetas/"+this.props.elementos.id,"contarDestinatarios");
    }
  }
  changeUsuario =(event) =>{
  }
  crud_put =() =>{  
    this.getfuntion("put", this.plataformaCorreo, "", "correoPlataformas","correoPlataformas")
  }
  crud_post =() =>{
    this.getfuntion("post", this.plataformaCorreo, "", "correoPlataformas","correoPlataformas")
  }
  
  etiquetadoMasivo=(destinatario, etiquetaObj, dest)=>{
    switch (destinatario) {
      case "clientes":        return this.getfuntion("post", etiquetaObj, "", "personasEtiquetas/etiquetado_masivo_clientes/plataforma/"+this.props.elementos.id +"/etiqueta/"+etiquetaObj.id,"etiquetado_masivo_clientes");
      case "transportistas":  return this.getfuntion("post", etiquetaObj, "", "personasEtiquetas/etiquetado_masivo_transportistas/plataforma/"+this.props.elementos.id +"/etiqueta/"+etiquetaObj.id,"etiquetado_masivo_transportistas");
      case "manual":          return this.getfuntion("post", etiquetaObj, "", "personasEtiquetas/etiquetado_masivo_destinatario/destinatario/"+dest.idDestinatario +"/etiqueta/"+etiquetaObj.id,"etiquetado_masivo_destinatario");
      case "especial":        return this.getfuntion("post", etiquetaObj, "", "personasEtiquetas/etiquetado_masivo_destinatario/destinatario/"+dest.idDestinatario +"/etiqueta/"+etiquetaObj.id,"etiquetado_masivo_destinatario");
      default:
        break;
    }
  }
  etiquetadoMasivoRemover=(destinatario, etiquetaObj, dest)=>{
    switch (destinatario) {
      case "clientes":        return  this.getfuntion("delete", etiquetaObj, this.props.elementos.id +"/"+etiquetaObj.id, "personasEtiquetas/etiquetado_masivo_clientes","etiquetado_masivo_clientes");
      case "transportistas":  return  this.getfuntion("delete", etiquetaObj, this.props.elementos.id +"/"+etiquetaObj.id, "personasEtiquetas/etiquetado_masivo_transportistas", "etiquetado_masivo_transportistas");
      case "manual":          return  this.getfuntion("delete", etiquetaObj, dest.idDestinatario +"/"+etiquetaObj.id, "personasEtiquetas/etiquetado_masivo_destinatario", "etiquetado_masivo_destinatario");
      case "especial":        return  this.getfuntion("delete", etiquetaObj, dest.idDestinatario +"/"+etiquetaObj.id, "personasEtiquetas/etiquetado_masivo_destinatario", "etiquetado_masivo_destinatario");
      default:
        break;
    }
  }

  obtenercolumna=(dest, etiqueta)=>{
    switch (dest.idTipoConfiguracion) {
      case 2:{
                if(this.state.destinatariosCatalogos[dest.idDestinatario]){
                  if(this.state.destinatariosCatalogos[dest.idDestinatario].idCatalogo == 1){
                    return  <div>
                              <span className={"badge text-"+ (this.state.transportistasEtq[etiqueta.id]?this.state.transportistasEtq[etiqueta.id].cont> 0? "primary":"secondary": "secondary")}
                                                    onClick= { () => this.etiquetadoMasivo("transportistas", etiqueta) }
                                                    data-toggle="tooltip" data-placement="top" title={"Etiquetar todos los trasportistas con la etiqueta: "+ etiqueta.nombre}
                                              >
                                                <h5><Icon name="tag"/>{ this.state.transportistasEtq[etiqueta.id]?this.state.transportistasEtq[etiqueta.id].cont: null }</h5>
                                              </span>
                                              <span className={"badge ml-2 text-"+ (this.state.transportistasEtq[etiqueta.id]?this.state.transportistasEtq[etiqueta.id].cont> 0? "danger":"secondary": "secondary")} 
                                                    hidden={ this.state.transportistasEtq[etiqueta.id]? this.state.transportistasEtq[etiqueta.id].cont === 0: true }
                                                    onClick= { () => this.etiquetadoMasivoRemover("transportistas", etiqueta) }
                                                    data-toggle="tooltip" data-placement="top" title={"Quitar la Etiqueta "+etiqueta.nombre+" de todos los contactos de transportistas" }
                                              >
                                                <h5><Icon name="trash"/></h5>
                                              </span>
                            </div>
                  }
                }
        }break;
      case 1:
        return  <div>
                  <span className={"badge text-"+ (this.state.destinatEtq[etiqueta.id]?this.state.destinatEtq[etiqueta.id][dest.idDestinatario]? this.state.destinatEtq[etiqueta.id][dest.idDestinatario].cont> 0? "primary":"secondary": "secondary": "secondary")}
                        onClick= { () => this.etiquetadoMasivo("manual", etiqueta, dest) }
                        data-toggle="tooltip" data-placement="top" title={"Etiquetar todos los contactos de "+dest.nombre+", con:"+ etiqueta.nombre}
                  >
                    <h5><Icon name="tag"/>{ this.state.destinatEtq?
                                              this.state.destinatEtq[etiqueta.id]?
                                                this.state.destinatEtq[etiqueta.id][dest.idDestinatario]? this.state.destinatEtq[etiqueta.id][dest.idDestinatario].cont: null
                                              : null
                                            :null
                                          }</h5>
                  </span>
                  <span className="badge ml-2 text-danger" hidden={ this.state.destinatEtq?
                                              this.state.destinatEtq[etiqueta.id]?
                                                this.state.destinatEtq[etiqueta.id][dest.idDestinatario]? this.state.destinatEtq[etiqueta.id][dest.idDestinatario].cont === 0: true
                                              : true
                                            :true
                                          }
                        onClick= { () => this.etiquetadoMasivoRemover("manual", etiqueta, dest) }
                        data-toggle="tooltip" data-placement="top" title={"Quitar la Etiqueta "+etiqueta.nombre+" de todos los contactos de "+dest.nombre }
                  >
                    <h5><Icon name="trash"/></h5>
                  </span>
                </div>;
      default:
        break;
    }

  }
  render(){
    return (
      <div>
        {/**
          *   Configuracion de correo
          */
        }
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Configuración de destinatarios"
                  isCollapsible={ false }
                  //isClosable
                  isCollapsed={ false}
                  //options={ }
                  body={
                    <Dimmer active={this.state.etiquetas.length === 0 } loader>
                      { this.state.etiquetando?
                        <div  className="progress-bar text-dark" role="progressbar" 
                              style={{"width": ((100/this.state.etiquetas.length )* puntero)+"%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                          { this.state.etiquetas[puntero].nombre+" - "+Math.round((100/this.state.etiquetas.length )* puntero)+"%" }
                        </div>
                        :null
                      }
                      <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={this.state.headers} >
                        <Table.Body>
                          
                            { 
                              this.state.etiquetas.filter( word => word.nombre.includes(this.state.search.toUpperCase()) ).sort().map((elemento, index) =>
                                <Table.Row>                                  
                                  <Table.Col>{elemento.nombre}</Table.Col>
                                  { this.state.destinatarios.map((dest, index)=> <Table.Col>{this.obtenercolumna(dest, elemento) }</Table.Col> )}
                                </Table.Row>
                              )
                            }
                          
                        </Table.Body>
                      </Table>
                    </Dimmer>
                  }
                />
              </Grid.Col>
            
            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default EtiquetadoMasivo
