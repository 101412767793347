import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import Select from "react-select";
import ModalLayout from "../../../components/ModalLayout.react";
import { PersonasForm } from "../../../Catalogos";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';
import { password } from "../../../herramientas/Forms";

var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
class  Reporte extends Component {
  constructor(props) {
    super(props);
              this.state  = {
                              currentTap  : 0,
                                      id  : 0,
                               persona    : {value:0, label: "Selecione una persona", data: null},
                                username  : "",
                                password  : "",
                               Cpassword  : "",
                                msmPAsss  : "",
                                 estatus  : 0,
                                  accion  : this.props.accion, //'nuevo'// modificar, nuevo
                             newPassword  : false,
                         crudbutonEnable  : true, // Se desabilita cuando se hace click
                              idPlataforma: { value: 1, label: "Default", data: {id: 1 } },
                              hiddenModal : false,
                           cambiarEstatus : false,
              };
      this.usuarioObject  = [];
      this.personas= [];
      this.plataformas=[];
  }
  isEnable=(Accion)=>{
    let borrar= "usuarios_borrar";
    let actualizar= "usuarios_editar";
    let agregar= "usuarios_agregar";

    let actualizarPersona= "personas_editar";
    let agregarPersona= "personas_agregar";
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(Accion === "actualizarPersona"){if( privilegios[i].clave == actualizarPersona){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregarPersona"){if( privilegios[i].clave == agregarPersona){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}

      if(Accion === "borrar"){ 
        if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "actualizar"){if( privilegios[i].clave == actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if(Accion === "agregar"){if( privilegios[i].clave == agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
    }
    return false
  }

  componentDidMount = () => {    
    this.plataformas.push({ value: 1, label: "Default", data: {id: 1 } });
    currentUser.map((elemento, index) => {
        this.plataformas.push({ value: elemento.plataforma.id, label: elemento.plataforma.nombre, data: elemento.plataforma});
    });
    if (this.state.accion !== 'nuevo') {
      let p= this.props.elementos.persona;
      this.personas.push({value: p.id, label: p["nombre"]+" "+p["aPaterno"]+" "+p["aMaterno"], data: p });
      this.setState({ persona: {value: p.id, label: p["nombre"]+" "+p["aPaterno"]+" "+p["aMaterno"], data: p }});
      this.setState({ id: this.props.elementos.id });
      this.setState({ username: this.props.elementos.username });
      //this.setState({ password: this.props.elementos.password });
      this.setState({ estatus: this.props.elementos.estatus });
      this.usuarioObject=this.props.elementos;
    }else{
      this.props.stor.personas_object.map((p, index) =>{
        this.personas.push({value: p.id, label: p["nombre"]+" "+p["aPaterno"]+" "+p["aMaterno"], data: p });        
      });      
      this.usuarioObject=
        {
          "username": "",
          "password": "",
          "estatus": 1,
          "persona": {
          }
        };
      }
  }
  changePlataformas = (event) => {
    this.setState({ idPlataforma: event });
  }
  selectedTab = (index) =>{
    this.setState({ currentTap:index })
  }
  changePersona = (event) => {
    this.setState({ persona: event });
    this.usuarioObject.persona= event.data;
  }
  changeUsername = (event) => {
    this.usuarioObject.username= event.target.value;
    this.setState({ username: event.target.value });
  }
  changePassword = (event) => { 
       this.usuarioObject.password= event.target.value;
       this.setState({ password: event.target.value });       
  }
  changeCpassword = (event) => {
    if(this.state.password !== event.target.value && this.state.password !== ""){
    }
    this.setState({ Cpassword: event.target.value });
  }
  newPassword = (event) => {
    this.setState({ newPassword: !this.state.newPassword });
    this.setState({ password: "" });
    this.setState({ Cpassword: "" });
  }
  changeEstatus =(event) => {
    this.state.estatus === 0 ? this.setState({estatus: 1}): this.setState({estatus: 0});
    this.state.estatus === 0 ? this.usuarioObject.estatus= 1: this.usuarioObject.estatus= 0;
  }

  delete() { this.props.getfuntion("delete", "", this.state.id);    this.setState({ crudbutonEnable: false }); }
  crud_put() { this.props.getfuntion("put", this.usuarioObject);    this.setState({ crudbutonEnable: false }); }
  crud_post() { this.props.getfuntion("post", this.usuarioObject);  this.setState({ crudbutonEnable: false }); }
  crud_putPassword() {
    //this.props.getfuntion("put", "", this.usuarioObject, "plataformasUsuariosPerfiles");
    this.props.getfuntion("put", this.usuarioObject, "", this.usuarioObject, "usuarios/password");
  }
  hiddenmodal = () => {
    this.setState({ hiddenModal: !this.state.hiddenModal });
  }//Solo personas
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas/plataforma/"+this.state.idPlataforma.value , stateVar = "personas", hiddenModl = this.hiddenmodal() ) => {
    return Crud_Catalogos(metodo !== "post" && catalogo.includes("personas")? "personas":catalogo , "tracking", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          break;
        case "post":
          {
            if (stateVar === "personas") { 
              if(returnVal.id){
                this.personas.unshift({value: returnVal.id, label: returnVal["nombre"]+" "+returnVal["aPaterno"]+" "+returnVal["aMaterno"], data: returnVal }); 
                this.usuarioObject.persona= returnVal;
                this.setState({ persona: {value: returnVal.id, label: returnVal["nombre"]+" "+returnVal["aPaterno"]+" "+returnVal["aMaterno"], data: returnVal }});                                
              }
            }
          }
          break;
        case "put":
          {
            if (stateVar === "personas") { 
              if(returnVal.id){
                NotificationManager.info("Se han agregado las modificaciones de ( "+returnVal.nombre+" ), al catlogo general", "Modificación");
                this.personas.map((p, index)=>{
                  if(returnVal.id === p.id){ this.personas[index]= returnVal; }
                });
                this.setState({ persona: {value: returnVal.id, label: returnVal["nombre"]+" "+returnVal["aPaterno"]+" "+returnVal["aMaterno"], data: returnVal }});                                         
              }
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  render(){
    return (
      <div>

        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              {/*
                <Grid.Col md={4} lg={4}>
                  <Form.Group label="De la plataforma se toma la plantilla para validación de correo">
                    <Select value={this.state.idPlataforma} onChange={this.changePlataformas} options={this.plataformas} /></Form.Group>
                </Grid.Col>
              */}
              <Grid.Col md={3} lg={3}>
                <Grid.Row>
                  <Grid.Col>
                    <Form.Group label="Persona">
                      <Select value={this.state.persona} onChange={this.changePersona} options={this.personas} />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    { this.state.idPlataforma.value>0 && (this.isEnable("actualizarPersona") || this.isEnable("agregarPersona") )?
                      <Grid.Col>
                        {this.isEnable("agregarPersona")?
                          <span className="float-right">
                            <ModalLayout tamanio="85%" title={"Nuevo"}
                                        name={<span className="fa fa-plus ml-2" data-toggle="tooltip" data-placement="top" title={"Nueva persona(Antes de crear  valida que no exista)"}></span>} hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                        formulario={<PersonasForm accion="nuevo" plataforma={this.state.idPlataforma.data} elementos={this.state.persona.data} getfuntion={this.getfuntion} stor={this.props.stor} />}
                            />
                          </span>
                        :null}
                        {this.isEnable("actualizarPersona")?
                          <span className="float-right">
                            { this.state.persona.value>0 ?
                              <ModalLayout tamanio="85%" title="Modificar"
                                          name={<span className="fa fa-edit" data-toggle="tooltip" data-placement="top" title={"Modificar ("+ this.state.persona.label+ ")"}></span>} hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                          formulario={<PersonasForm accion="modificar" plataforma={this.state.idPlataforma.data} elementos={this.state.persona.data} getfuntion={this.getfuntion} stor={this.props.stor} />}
                              />
                            :null}
                          </span>
                        :null}
                      </Grid.Col>
                    :null}
                  </Grid.Col>                  
                </Grid.Row>
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="username">
                  <Form.Input name="username" type="text" value={this.state.username} onChange={this.changeUsername} />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            {this.state.accion === "modificar"? <hr/>:null}
            <Grid.Row>
              {this.state.accion === "modificar" && !this.state.cambiarEstatus ?<h4 className="text-primary" onClick={()=> this.setState({ cambiarEstatus: !this.state.cambiarEstatus})}>Cambiar estatus</h4>:<h4></h4>}
              {this.state.accion === "modificar" && this.state.cambiarEstatus ?
                <Table.Col>
                  <Form.Group>
                    <Form.Switch name="estatus" label={this.state.estatus? "Activo": "Inactivo( El Usuario no podra iniciar sessión)"}
                      value={this.state.estatus} checked={this.state.estatus} onChange={this.changeEstatus} />
                  </Form.Group>
                  <span className="float-right ml-1">
                    <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={()=> this.setState({ cambiarEstatus: !this.state.cambiarEstatus})} >
                      <span className="badge"><Icon link={true} name="x" />Cancelar</span>
                    </Button>
                  </span>
                  <span className="float-right">
                    <Button target="_blank" size="sm" RootComponent="a" color="primary"
                      onClick={() => this.crud_put() }
                      >
                      <span className="badge"><Icon link={true} name="refresh-ccw" />Actualizar</span>
                    </Button>
                  </span>
                </Table.Col>
              : null}
            </Grid.Row>
            {this.state.accion === "modificar"? <hr/>:null}
            <Grid.Row>
              {this.state.accion === "modificar" && !this.state.newPassword?
                <Table.Col>
                  <Form.Group>
                    <h4 className="text-primary" onClick={()=> this.newPassword()}>Reestablecer contraseña</h4>
                  </Form.Group>                  
                </Table.Col>
              : null}
            </Grid.Row>
            <Grid.Row>
              { this.state.newPassword || this.state.accion !== "modificar"?
                <Grid.Col md={ 4 } lg={ 4 }>
                  <Form.Group label="password">              
                    <Form.Input name="password" type="password" disabled={ this.state.accion !== 'nuevo' && !this.state.newPassword } value={this.state.password} onChange={this.changePassword} />
                    <div className="text-danger"  >{this.state.password.length? password(this.state.password): ""}</div>
                  </Form.Group>
                </Grid.Col>
                :null}
              { this.state.newPassword?
                <Grid.Col md={ 4 } lg={ 4 }>
                  <Form.Group label="confirmar password">
                    <Form.Input name="passwordC" type="password" disabled={ this.state.accion !== 'nuevo' && !this.state.newPassword ||  password(this.state.password).length || !this.state.password.length } value={this.state.Cpassword} onChange={this.changeCpassword} />
                  </Form.Group>
                </Grid.Col>
                : null
              }
              { this.state.newPassword?
                <span className="float-right">
                  <Button target="_blank" size="sm" RootComponent="a" color="primary"
                    disabled= { this.state.Cpassword === this.state.password && this.state.password.length>0 && this.state.Cpassword.length> 0 ? false: true }
                    onClick={() => this.crud_putPassword() }
                    >
                    <span className="badge"><Icon link={true} name="refresh-ccw" />Actualizar</span>
                  </Button>
                </span>
                : null
              }
              { this.state.newPassword?
                <span className="float-right ml-1">
                  <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={()=> this.newPassword()} >
                    <span className="badge"><Icon link={true} name="x" />Cancelar</span>
                  </Button>
                </span>
              :null}
            </Grid.Row>
          </Grid.Col>          
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("agregar") || this.isEnable("actualizar") ?
            <span className="badge">
              <Button
                target="_blank" size="sm" RootComponent="a" color="primary" disabled={(this.state.persona.value > 0 ? this.state.username.length > 0 ? this.state.password.length > 0 ? !this.state.newPassword ? false : true : true : true: true) || !this.state.crudbutonEnable}
                onClick={this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                <span className="badge"><Icon link={true} name="save" />Guardar</span>
              </Button>
            </span>
          :"null"}
          {this.state.accion === "modificar" && this.isEnable("borrar") ?
            <span className="badge">
              <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick={() => this.delete()}  disabled={!this.state.crudbutonEnable}>
                <span className="badge"><Icon link={true} name="trash" />Borrar</span>
              </Button>
            </span>
            :null
          }
        </div>

      </div>
    )
  }
}

export default Reporte

